import PropTypes from 'prop-types';

import { FormProvider, useForm } from 'react-hook-form';

import { useStateMachine } from 'little-state-machine';
import { updateGiftCardsRedeem } from '../../../store/actions';

// Components
import GiftCardAmountToSelect from './GiftCardAmountToSelect';
import QuantityInput from '../../forms/fields/QuantityInput';
import GiftCardRedeem from './GiftCardRedeem';

// Helpers
import { calcPointsTotal, amountSelected } from '../../../helpers/pointsHelpers';
import { handleGaEcommImpression, getGiftCardOrderMapping } from '../../../helpers/handleGoogleAnalyticsHelper';
import { gaExternalSku } from '../../../helpers/giftCardsHelper';

function GiftCardForm({ customData }) {
  const {
    actions,
    state: {
      points: { added },
      giftCards: { redeem, selectedGiftCardIndex },
    },
  } = useStateMachine({ updateGiftCardsRedeem });
  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = async () => {
    actions.updateGiftCardsRedeem({ step: 2 });
    const redemptionPoints = calcPointsTotal(added) * redeem.quantity;
    const amount = amountSelected(added);
    const externalSku = gaExternalSku(added, redeem?.selectedFormat, redeem?.giftCardDetails);
    handleGaEcommImpression(
      'gc_begin_checkout',
      getGiftCardOrderMapping(
        redemptionPoints,
        redeem,
        amount,
        '',
        'giftcard_checkout',
        'Gift Card Checkout',
        selectedGiftCardIndex,
        amount,
        externalSku
      )
    );
  };

  const handleQuantityChange = (quantity) => {
    actions.updateGiftCardsRedeem({ quantity });
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0 md:w-full" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <GiftCardAmountToSelect customData={customData} />
        <QuantityInput customData={customData} handleQuantityChange={handleQuantityChange} />
        <GiftCardRedeem customData={customData} />
      </form>
    </FormProvider>
  );
}

GiftCardForm.propTypes = {
  customData: PropTypes.object,
};

export default GiftCardForm;
