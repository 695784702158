export function searchAccordionStrings(accordionData = [], searchWord) {
  const filteredAccordion = {};
  accordionData.forEach((arrayItem, sectionIndex) => {
    if (arrayItem.title.toUpperCase().includes(searchWord.toUpperCase())) {
      filteredAccordion[sectionIndex] = {
        title: arrayItem.title,
      };
    }

    let foundQuestions = false;
    for (const faqItem of arrayItem.accordionList) {
      if (
        faqItem.fields.content.toUpperCase().includes(searchWord.toUpperCase()) ||
        faqItem.fields.title.toUpperCase().includes(searchWord.toUpperCase())
      ) {
        foundQuestions = true;
        if (!filteredAccordion[sectionIndex]) {
          filteredAccordion[sectionIndex] = {
            title: arrayItem.title,
          };
        }

        if (!filteredAccordion[sectionIndex].accordionList) {
          filteredAccordion[sectionIndex].accordionList = [];
        }

        filteredAccordion[sectionIndex].accordionList.push({
          fields: {
            title: faqItem.fields.title,
            content: faqItem.fields.content,
          },
        });
      }
    }

    if (!foundQuestions && filteredAccordion[sectionIndex]) {
      if (!filteredAccordion[sectionIndex].accordionList) {
        filteredAccordion[sectionIndex].accordionList = [];
      }

      for (const faqItem of arrayItem.accordionList) {
        filteredAccordion[sectionIndex].accordionList.push({
          fields: {
            title: faqItem.fields.title,
            content: faqItem.fields.content,
          },
        });
      }
    }
  });

  return filteredAccordion;
}
