import PropTypes from 'prop-types';

// Components
import Portal from '../../../../common/Portal/Portal';
import PartialModalBody from './PartialModalBody';

// Styles
import ModalLayout from '../../../../layouts/ModalLayout';

export default function ConfirmPartialModal({ customData, currentItemId, toggle }) {
  return (
    <Portal>
      <div>
        <ModalLayout toggle={toggle} type="modal-confirmation" hideCloseIcon>
          <PartialModalBody toggle={toggle} customData={customData} currentItemId={currentItemId} />
        </ModalLayout>
      </div>
    </Portal>
  );
}

ConfirmPartialModal.propTypes = { customData: PropTypes.object, currentItemId: PropTypes.string, toggle: PropTypes.func };
