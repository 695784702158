import { useEffect } from 'react';
import router from 'next/router';
import PropTypes from 'prop-types';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import SendMyCardContainerGuest from '../forms/send-my-card/SendMyCardContainerGuest';
import WrapperLayout from '../layouts/WrapperLayout';

const SendMyCardPage = ({ module, customData }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  useEffect(() => {
    if (isLoggedIn) {
      router.push('/account/card');
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding" isGray mobileNoPadding>
        <SendMyCardContainerGuest fields={fields} customData={customData} />
      </WrapperLayout>
    );
  }

  return null;
};

SendMyCardPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;

  try {
    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['Contact', 'General', 'message', 'Address'];

    return {
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default SendMyCardPage;

SendMyCardPage.propTypes = { module: PropTypes.object, customData: PropTypes.object };
