import PropTypes from 'prop-types';

import ExternalImage from '../Images/ExternalImage';

function BrandLogo({ url, title }) {
  return <ExternalImage url={url} title={title} fill className="object-contain object-center" />;
}

BrandLogo.propTypes = { url: PropTypes.string, title: PropTypes.string };

export default BrandLogo;
