import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

// Components
import RegisterStep2FormLayoutGK from '../../layouts/RegisterStep2FormLayoutGK';
import Loader from '../../common/Loader/Loader';
import Tooltip from '../../common/Tooltip/Tooltip';
import FormInput from '../fields/FormInput';
import FormPhoneInput from '../fields/FormPhoneInput';
import FormSelect from '../fields/FormSelect';
import FormCheckbox from '../fields/FormCheckbox';
import CanadaPostSearch from '../fields/CanadaPostSearch';
import DateOfBirthField from '../fields/DateOfBirthField';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

// Hooks
import useGenders from '../../hooks/account/useGenders';
import useProvinces from '../../hooks/account/useProvinces';

// Helpers
import { displayFieldErrorMessage, regexEnFrChars } from '../../../helpers/formHelpers';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';

// Styles
import { LoyaltySceneCheckboxLabel } from '../FormStyles';

function Step2(props) {
  const {
    firstNameLabel,
    lastNameLabel,
    genderHeader,
    genderLabel,
    genderDefaultOption,
    genderTooltip,
    dOBHeader,
    dOBTooltip,
    addressLookupHeader,
    addressLookupTooltip,
    phoneHeader,
    phoneLabel,
    phonetooltip,
    sMSCheckboxLabel,
    promoHeader,
    promoLabel,
    sceneCardCheckboxLabel,
    sceneCardHeader,
    sceneCardText,
    languageCode,
    errorsList,
    tag180,
    tag182,
  } = props;

  const { state } = useStateMachine();

  useEffect(() => {
    if (tag180) {
      const dataLayerTitle = {
        event: 'unified_screen_name',
        page_title: state?.user?.isGhostKitActivationEnabled ? tag180 : tag182,
      };
      handleGAEventsBase(dataLayerTitle);
    }
  }, [tag180]);

  const { isLoading: gendersLoading, genders } = useGenders(languageCode);
  const { isLoading: provincesLoading, provinces } = useProvinces(languageCode);

  // TODO: Revisit condition to simplify this?
  const genderStartList = genders;
  const genderOptions = genderStartList && genderStartList.map((a) => ({ value: a.code, label: a.name }));

  const loading = gendersLoading || provincesLoading;
  const gaEvent = (e) => {
    const dataLayer = {
      event: 'sign_up_form_field',
      category: 'Registration Events',
      action: 'Form Field Click',
      label: camelCaseToSentence(e.target.name),
      sign_up_step: `Step 2${state?.user?.isGhostKitActivationEnabled ? ' GK' : ''}`,
      sign_up_form_field: camelCaseToSentence(e.target.name),
    };
    handleGAEventsBase(dataLayer);
  };
  return (
    <RegisterStep2FormLayoutGK {...props}>
      <FormInput
        onFocus={gaEvent}
        id="firstName"
        fieldType="text"
        name="firstName"
        label={firstNameLabel}
        defaultVal={state.user.firstName}
        maxLength={64}
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Invalid'),
            value: regexEnFrChars,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
            value: 2,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
            value: 50,
          },
        }}
      />
      <FormInput
        onFocus={gaEvent}
        id="lastName"
        fieldType="text"
        name="lastName"
        label={lastNameLabel}
        defaultVal={state.user.lastName}
        maxLength={64}
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Invalid'),
            value: regexEnFrChars,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
            value: 2,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
            value: 50,
          },
        }}
      />
      <div className="mb-8" />
      <Tooltip dataForId="genderHeaderTip" title={genderHeader} message={genderTooltip} />

      {loading ? (
        <Loader />
      ) : (
        <div className="reg-gender-field">
          <FormSelect
            gaFlow={`register${state?.user?.isGhostKitActivationEnabled ? 'GK' : ''}`}
            name="gender"
            label={genderLabel}
            options={genderOptions}
            defaultOption={genderDefaultOption}
            placeholder={genderDefaultOption}
            errorMessage={displayFieldErrorMessage(errorsList, 'Customer.BirthDate.Invalid')}
            rules={{
              required: {
                message: displayFieldErrorMessage(errorsList, 'Customer.Gender.Required'),
                value: true,
              },
            }}
          />
        </div>
      )}
      <div className="mb-8" />
      <Tooltip dataForId="dOBHeaderTip" title={dOBHeader} message={dOBTooltip} />

      <DateOfBirthField {...props} gaFlow={`register${state?.user?.isGhostKitActivationEnabled ? 'GK' : ''}`} />
      <Tooltip dataForId="addressLookupHeaderTip" title={addressLookupHeader} message={addressLookupTooltip} />

      {loading ? (
        <Loader />
      ) : (
        <CanadaPostSearch
          {...props}
          provinces={provinces}
          culture={languageCode}
          gaFlow={`register${state?.user?.isGhostKitActivationEnabled ? 'GK' : ''}`}
        />
      )}
      <div className="mb-8" />
      <Tooltip dataForId="phoneHeaderTip" title={phoneHeader} message={phonetooltip} />

      <FormPhoneInput
        onFocus={gaEvent}
        id="primaryPhoneNumber"
        name="primaryPhoneNumber"
        label={phoneLabel}
        defaultVal={state.user.phone}
        errorMessage={displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Invalid')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Required'),
            value: true,
          },
        }}
      />

      <div className="field-flex">
        <FormCheckbox
          onClick={gaEvent}
          id="sms"
          fieldType="checkbox"
          label={sMSCheckboxLabel}
          name="sms"
          defaultChecked={state.user.sms}
          alignStart
          rules={{
            required: {
              message: '',
              value: false,
            },
          }}
        />
      </div>
      <div className="mb-8" />
      <p className="font-bold mt-8 mb-2">{promoHeader}</p>
      <FormInput
        onFocus={gaEvent}
        id="promo"
        fieldType="text"
        name="promo"
        label={promoLabel}
        defaultVal={state.user.promo}
        rules={{
          required: {
            message: '',
            value: false,
          },
        }}
      />
      <div className="mb-8" />
      {!state?.user?.isGhostKitActivationEnabled && (
        <>
          <p className="font-bold mt-8 mb-2">{sceneCardHeader}</p>
          <LoyaltySceneCheckboxLabel>{sceneCardText}</LoyaltySceneCheckboxLabel>
          <div className="field-flex">
            <FormCheckbox
              onClick={gaEvent}
              id="needLoyaltyCard"
              fieldType="checkbox"
              label={sceneCardCheckboxLabel}
              name="needLoyaltyCard"
              defaultChecked={state.user.needLoyaltyCard}
              rules={{
                required: {
                  message: '',
                  value: false,
                },
              }}
            />
          </div>
        </>
      )}

      <div className="field-flex mt-8">
        <CaptchaDisclaimer />
      </div>
    </RegisterStep2FormLayoutGK>
  );
}

Step2.propTypes = {
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  genderHeader: PropTypes.string,
  genderLabel: PropTypes.string,
  genderDefaultOption: PropTypes.string,
  genderTooltip: PropTypes.string,
  dOBHeader: PropTypes.string,
  dOBTooltip: PropTypes.string,
  addressLookupHeader: PropTypes.string,
  addressLookupTooltip: PropTypes.string,
  phoneHeader: PropTypes.string,
  phoneLabel: PropTypes.string,
  phonetooltip: PropTypes.string,
  sMSCheckboxLabel: PropTypes.string,
  promoHeader: PropTypes.string,
  promoLabel: PropTypes.string,
  sceneCardCheckboxLabel: PropTypes.string,
  sceneCardHeader: PropTypes.string,
  sceneCardText: PropTypes.string,
  languageCode: PropTypes.string,
  errorsList: PropTypes.array,
  tag180: PropTypes.string,
  tag182: PropTypes.string,
};

export default Step2;
