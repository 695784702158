import styled, { css } from 'styled-components';

export const AddressBlock = styled.div`
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    font-size: ${(props) => props.theme.fontBody3} !important;
    margin-bottom: 0;
  }
`;
export const ShippingName = styled.p`
  color: ${(props) => props.theme.brandBlack} !important;
  margin-bottom: 5px !important;

  ${(props) =>
    props.switcher &&
    css`
      font-weight: bold;
    `};
`;

export const AddressDetailContent = styled.div`
  p {
    ${(props) =>
      props.switcher &&
      css`
        color: ${props.theme.lightGrayColor4};
      `};
  }
`;
