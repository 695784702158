// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import RecentOrdersList from '../Orders/RecentOrders/RecentOrdersList';

const RecentOrders = ({ module, customData, languageCode }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper">
        {customData && (
          <RecentOrdersList
            limit={fields.maximumOrders}
            filterByDays={fields.filterByDays}
            customData={customData}
            title={fields.title}
            languageCode={languageCode}
            viewAllLink={fields.viewAllLink}
            viewAllLabel={fields.viewAllLabel}
          />
        )}
      </WrapperLayout>
    );
  }

  return <div className="flex justify-center">{/* No account for now */}</div>;
};

RecentOrders.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const rawAwardsTypes = await api.getContentList({ referenceName: 'awardtypes', languageCode });

    const awardsTypes =
      rawAwardsTypes &&
      rawAwardsTypes.map((a) => ({
        code: a?.fields?.code || null,
        image: a?.fields?.image || null,
        name: a?.fields?.name || null,
      }));

    const contentItemList = await api.getContentList({
      referenceName: 'recentorderscontent',
      languageCode,
    });
    const contentItem = contentItemList && contentItemList[0];
    return {
      awardsTypes: awardsTypes || null,
      ordersContent: contentItem.fields,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
export default RecentOrders;
