import styled from 'styled-components';

export const SectionTitle = styled.h1`
  font-weight: bold;
  font-size: ${(props) => props.theme.headingMedium};
  line-height: ${(props) => props.theme.lineHeight2};
  text-align: center;
  margin-bottom: 2rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingMedSmall};
  }
`;
export const Section = styled.div`
  padding: 3.5rem 0;

  @media screen and (min-width: 641px) {
    padding: 3.5rem 0;
  }
  .primary-button {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
  }
`;
