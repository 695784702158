/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateNavigationAction(state, payload) {
  return {
    ...state,
    navigation: {
      ...state.navigation,
      ...payload,
    },
  };
}
