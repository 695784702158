import PropTypes from 'prop-types';

import { DetailsSection, DetailsTitle } from '../DetailsStyles';

export default function DetailsOrderNumber({ orderNumberLabel, orderNumber }) {
  if (orderNumberLabel && orderNumber) {
    return (
      <DetailsSection>
        <DetailsTitle className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{orderNumberLabel}</DetailsTitle>

        <p>{orderNumber}</p>
      </DetailsSection>
    );
  }
  return null;
}

DetailsOrderNumber.propTypes = {
  orderNumberLabel: PropTypes.string,
  orderNumber: PropTypes.number,
};
