/* eslint-disable react/destructuring-assignment */

import { useStateMachine } from 'little-state-machine';

// Components
import RegisterStep2FormLayout from '../../layouts/RegisterStep2FormLayout';
import Loader from '../../common/Loader/Loader';
import Tooltip from '../../common/Tooltip/Tooltip';
import FormInput from '../fields/FormInput';
import FormPhoneInput from '../fields/FormPhoneInput';
import FormSelect from '../fields/FormSelect';
import FormCheckbox from '../fields/FormCheckbox';
import CanadaPostSearch from '../fields/CanadaPostSearch';
import DateOfBirthField from '../fields/DateOfBirthField';

import useGenders from '../../hooks/account/useGenders';
import useProvinces from '../../hooks/account/useProvinces';

import { displayFieldErrorMessage, regexEnFrChars } from '../../../helpers/formHelpers';

import { LoyaltySceneCheckboxLabel } from '../FormStyles';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

function Step2Component(props) {
  const {
    firstNameLabel,
    lastNameLabel,
    genderHeader,
    genderLabel,
    genderDefaultOption,
    genderTooltip,
    dOBHeader,
    dOBTooltip,
    addressLookupHeader,
    addressLookupTooltip,
    phoneHeader,
    phoneLabel,
    phonetooltip,
    sMSCheckboxLabel,
    promoHeader,
    promoLabel,
    sceneCardCheckboxLabel,
    sceneCardHeader,
    sceneCardText,
    languageCode,
    errorsList,
  } = props;

  const { state } = useStateMachine();

  const { isLoading: gendersLoading, genders } = useGenders(languageCode);
  const { isLoading: provincesLoading, provinces } = useProvinces(languageCode);

  // TODO: Revisit condition to simplify this?
  const genderStartList = genders;
  const genderOptions = genderStartList && genderStartList.map((a) => ({ value: a.code, label: a.name }));

  const loading = gendersLoading || provincesLoading;

  return (
    <RegisterStep2FormLayout {...props}>
      <FormInput
        id="firstName"
        fieldType="text"
        name="firstName"
        label={firstNameLabel}
        defaultVal={state.user.firstName}
        maxLength="64"
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Invalid'),
            value: regexEnFrChars,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
            value: 2,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
            value: 50,
          },
        }}
      />

      <FormInput
        id="lastName"
        fieldType="text"
        name="lastName"
        label={lastNameLabel}
        defaultVal={state.user.lastName}
        maxLength="64"
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Invalid'),
            value: regexEnFrChars,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
            value: 2,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
            value: 50,
          },
        }}
      />

      <Tooltip dataForId="genderHeaderTip" title={genderHeader} message={genderTooltip} />

      {loading ? (
        <Loader />
      ) : (
        <div className="reg-gender-field">
          <FormSelect
            name="gender"
            label={genderLabel}
            options={genderOptions}
            defaultOption={genderDefaultOption}
            placeholder={genderDefaultOption}
            errorMessage={displayFieldErrorMessage(errorsList, 'Customer.BirthDate.Invalid')}
            rules={{
              required: {
                message: displayFieldErrorMessage(errorsList, 'Customer.Gender.Required'),
                value: true,
              },
            }}
          />
        </div>
      )}

      <Tooltip dataForId="dOBHeaderTip" title={dOBHeader} message={dOBTooltip} />

      <DateOfBirthField {...props} />

      <Tooltip dataForId="addressLookupHeaderTip" title={addressLookupHeader} message={addressLookupTooltip} />

      {loading ? <Loader /> : <CanadaPostSearch {...props} provinces={provinces} culture={props.languageCode} />}

      <Tooltip dataForId="phoneHeaderTip" title={phoneHeader} message={phonetooltip} />

      <FormPhoneInput
        id="primaryPhoneNumber"
        name="primaryPhoneNumber"
        label={phoneLabel}
        defaultVal={state.user.phone}
        errorMessage={displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Invalid')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Required'),
            value: true,
          },
        }}
      />

      <div className="field-flex">
        <FormCheckbox
          id="sms"
          fieldType="checkbox"
          label={sMSCheckboxLabel}
          name="sms"
          defaultChecked={state.user.sms}
          alignStart
          rules={{
            required: {
              message: '',
              value: false,
            },
          }}
        />
      </div>

      <p className="font-bold mt-8 mb-2">{promoHeader}</p>
      <FormInput
        id="promo"
        fieldType="text"
        name="promo"
        label={promoLabel}
        defaultVal={state.user.promo}
        rules={{
          required: {
            message: '',
            value: false,
          },
        }}
      />
      <p className="font-bold mt-8 mb-2">{sceneCardHeader}</p>
      <LoyaltySceneCheckboxLabel>{sceneCardText}</LoyaltySceneCheckboxLabel>
      <div className="field-flex">
        <FormCheckbox
          id="needLoyaltyCard"
          fieldType="checkbox"
          label={sceneCardCheckboxLabel}
          name="needLoyaltyCard"
          defaultChecked={state.user.needLoyaltyCard}
          rules={{
            required: {
              message: '',
              value: false,
            },
          }}
        />
      </div>
      <div className="field-flex mt-8">
        <CaptchaDisclaimer />
      </div>
    </RegisterStep2FormLayout>
  );
}

export default Step2Component;
