import PropTypes from 'prop-types';
// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import BankCardsSlider from './BankCardsSlider';
import LinkComponent from '../Links/LinkComponent';

const BankCardsSliderWrapper = ({ bankSlider, languageCode }) => {
  // get module fields
  const {
    title,
    message,
    disableCardsLink,
    cardLinkLabel,
    disableCta,
    cTALink,
    cTALinkLabel,
    cTALinkLabelLoading,
    slides,
    gaCategory,
  } = bankSlider;
  return (
    <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
      {title && <h2 className="headline-medium block mb-3 md:mb-6 text-center">{title}</h2>}
      {message && <p className="sub-title text-center">{message}</p>}

      <BankCardsSlider
        slides={slides}
        disableCardsLink={disableCardsLink}
        cardLinkLabel={cardLinkLabel}
        gaCategory={gaCategory}
      />
      {!disableCta && cTALink?.href && cTALinkLabel && (
        <LinkComponent
          href={cTALink.href}
          target={cTALink.target || '_self'}
          style={{ fontSize: '1rem', maxWidth: 'inherit' }}
          buttonLoadingText={cTALinkLabelLoading || cTALinkLabel}
          buttonStyle="primary-button"
          ariaLabel={cTALink.text || cTALinkLabel}
          className="mobile-w-full text-center md:pt-16 pt-10"
          locale={languageCode}
        >
          {cTALinkLabel}
        </LinkComponent>
      )}
    </WrapperLayout>
  );
};
BankCardsSliderWrapper.propTypes = { languageCode: PropTypes.string, bankSlider: PropTypes.object };
export default BankCardsSliderWrapper;
