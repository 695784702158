import { useState, useEffect } from 'react';

export default function useScrollDetection() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    function handleScroll(e) {
      if (!scrolling) setScrolling(true);
    }
    function handleScrollEnd(e) {
      if (scrolling) setScrolling(false);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scrollend', handleScrollEnd);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scrollend', handleScrollEnd);
    };
  }, [window.scrollY]);

  return {
    scrolling,
  };
}
