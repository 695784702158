import PropTypes from 'prop-types';

import { AgilityImage } from '@agility/nextjs';

function ExternalImage({ url, title = '', width, height, sizes = null, quality = null, className = null, fill = false }) {
  if (url && fill) {
    return <AgilityImage src={url} alt={title} fill className={className} sizes={sizes} quality={quality} />;
  }
  if (url && width && height && !fill) {
    return (
      <AgilityImage src={url} alt={title} width={width} height={height} className={className} sizes={sizes} quality={quality} />
    );
  }
  return null;
}

ExternalImage.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.bool,
  sizes: PropTypes.string,
  quality: PropTypes.number,
  className: PropTypes.string,
};

export default ExternalImage;
