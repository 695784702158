import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

// Global States
import { useStateMachine } from 'little-state-machine';
import { updatePoints } from '../../../../store/actions';

// Helpers
import { formatDate, todayDate } from '../../../../helpers/dateHelpers';

import FilterModalFooter from './FilterModalFooter';
import FilterModalHeader from './FilterModalHeader';
import FilterModalBody from './FilterModalBody';
import { FilterModalWrapper } from '../ModalStyles';

export default function FilterModal({ customData, toggle, type, children, handleSubmit, handleFormReset }) {
  const { filterTitle, applyFiltersLabel, clearAllLabel, resetFiltersLabel } = customData?.pageContent || customData;
  const [applyFilter, setApplyFilter] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const { actions } = useStateMachine({ updatePoints });

  const handleApplyFilter = async () => {
    // If the filter is connected to a form, we submit when filters applied
    if (typeof handleSubmit === 'function') {
      await handleSubmit();
      setApplyFilter(true);
    } else {
      setApplyFilter(true);
    }
  };

  const handleResetFilters = () => {
    setResetFilter(!resetFilter);
  };

  const handleClickReset = () => {
    switch (type) {
      case 'points':
        actions.updatePoints({
          type: [customData?.typesList[0].code],
          category: [customData?.categoriesList[0]?.code],
          cardType: [customData?.cardsList[0]?.code],
          fromDate: formatDate('1900-01-02', `YYYY-MM-DD`),
          toDate: todayDate,
          reset: true,
          selectedFromDate: '',
          selectedToDate: '',
        });
        break;

      case 'offers':
        if (handleFormReset && typeof handleFormReset === 'function') {
          handleFormReset();
        }
        break;

      default:
        break;
    }

    handleResetFilters();
  };

  useEffect(() => {
    if (applyFilter) {
      toggle();
    }
  }, [applyFilter]);

  return (
    <FocusTrap
      focusTrapOptions={{
        initialFocus: false,
        tabbableOptions: {
          displayCheck: 'none',
        },
      }}
    >
      <FilterModalWrapper>
        <FilterModalHeader filterTitle={filterTitle} toggle={toggle} />
        <FilterModalBody>{children(applyFilter, resetFilter)}</FilterModalBody>
        <FilterModalFooter
          clearAllLabel={clearAllLabel || resetFiltersLabel}
          handleClickReset={handleClickReset}
          applyFiltersLabel={applyFiltersLabel}
          handleApplyFilter={handleApplyFilter}
        />
      </FilterModalWrapper>
    </FocusTrap>
  );
}

FilterModal.propTypes = {
  customData: PropTypes.object,
  toggle: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleFormReset: PropTypes.func,
};
