// State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import SpendPointsList from '../common/List/SpendPointsList';

const SpendPointsV2 = ({ module, customData }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <section id="SpendPointsSection">
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          {fields?.title && <h2 className="headline-medium block mb-3 md:mb-6 text-center">{fields?.title}</h2>}
          {fields?.message && <p className="sub-title text-center">{fields?.message}</p>}

          <SpendPointsList list={customData?.contentGrid} />
        </WrapperLayout>
      </section>
    );
  }
  return null;
};
SpendPointsV2.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;

  try {
    // get the CreateAccountForm
    const contentItemList = await api.getContentList({
      referenceName: 'spendpointsgridv2',
      languageCode,
    });
    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      contentGrid:
        contentItemList.map((item) => ({
          title: item.fields.title || null,
          description: item.fields.description || null,
          anchorName: item.fields.anchorName || null,
          image: item.fields.image || null,
          appImage: item.fields.appImage || null,
        })) || null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
export default SpendPointsV2;
