import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: inline-block;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: ${(props) => (props.status === 'Shipped' ? props.theme.uiSuccessGreen : props.theme.lightGrayColor4)};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  border-radius: 3px;
`;
