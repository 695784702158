/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

// Global State
import updatePoints from '../../../store/actions/updatePointsAction';

// Components
import PointsHistoryList from './PointsHistoryList';

// Helpers
import { formatDate, todayDate } from '../../../helpers/dateHelpers';

export default function PointsHistory({ customData, transactionIconTypes, accountSegments, awardsTypes, languageCode }) {
  const { categoriesList, cardsList, typesList } = customData;

  // Global State
  const { actions } = useStateMachine({ updatePoints });

  useEffect(() => {
    actions.updatePoints({
      type: [typesList[0].code],
      category: [categoriesList[0].code],
      cardType: [cardsList[0].code],
      fromDate: formatDate('1900-01-02', `YYYY-MM-DD`),
      toDate: todayDate,
      reset: false,
    });
  }, []);

  return (
    <PointsHistoryList
      customData={customData}
      transactionIconTypes={transactionIconTypes}
      languageCode={languageCode}
      accountSegments={accountSegments}
      awardsTypes={awardsTypes}
    />
  );
}

PointsHistory.propTypes = {
  customData: PropTypes.object,
  transactionIconTypes: PropTypes.array,
  accountSegments: PropTypes.array,
  awardsTypes: PropTypes.array,
  languageCode: PropTypes.string,
};
