/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateOffersFilterAction(state, payload) {
  return {
    ...state,
    offers: {
      ...state.offers,
      filter: { ...state.offers.filter, ...payload },
    },
  };
}
