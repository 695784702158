import PropTypes from 'prop-types';

import { CardIcon } from '../../../Points/Shop/PointsShopStyles';
import { DetailsSection, SectionTitle } from '../DetailsStyles';

export default function DetailsAccount({ yourAccountLabel, accountSegment, cards }) {
  const card = cards && cards.find((cardItem) => cardItem?.accountSegmentCode === accountSegment?.code);

  if (card) {
    return (
      <>
        {yourAccountLabel && <SectionTitle>{yourAccountLabel}</SectionTitle>}

        <DetailsSection>
          <CardIcon cardImage={card.cardImage}>
            <p>{card.cardTitle}</p>
          </CardIcon>
        </DetailsSection>
      </>
    );
  }
  return null;
}

DetailsAccount.propTypes = {
  yourAccountLabel: PropTypes.string,
  accountSegment: PropTypes.object,
  cards: PropTypes.array,
};
