import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import FAQSectionContent from '../site-pages/faq/FAQSectionContent';

const SharedFAQSection = ({ module, customData, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles="faqSection">
        <FAQSectionContent customData={customData} fields={fields} languageCode={languageCode} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedFAQSection.propTypes = {
  module: PropTypes.object.isRequired,
};

SharedFAQSection.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'faqsection',
      languageCode,
    });

    const faqData = [];
    const faqCategories = contentItemList;
    for (const category of faqCategories) {
      const faqItems = await api.getContentList({
        referenceName: category.fields.categoryQAList.referencename,
        languageCode,
      });
      faqItems.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

      const section = {
        contentID: category.contentID,
        title: category.fields.title,
        accordionList: faqItems.map((value) => ({
          contentID: value.contentID,
          fields: {
            title: value.fields.question,
            content: value.fields.answer,
          },
        })),
      };

      faqData.push(section);
    }

    return { faqData };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default SharedFAQSection;
