import { useEffect } from 'react';

// Components
import RegisterStepGKActivationFormLayout from '../../layouts/RegisterStepGKActivationFormLayout';
import FormNumberInput from '../fields/FormNumberInput';
import Tooltip from '../../common/Tooltip/Tooltip';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';

const StepGhostKitActivation = (props) => {
  const { sceneCardNumberLabel, activationCodeLabel, activationCodeTooltip, errorsList, codeLimit, tag175 } = props;

  useEffect(() => {
    if (tag175) {
      const dataLayerTitle = {
        event: 'unified_screen_name',
        page_title: tag175,
      };
      handleGAEventsBase(dataLayerTitle);
    }
  }, [tag175]);

  const onFocus = (e) => {
    const fieldName = e.target.name === 'sceneNumber' ? 'Card Number' : camelCaseToSentence(e.target.name);
    const dataLayer = {
      event: 'sign_up_form_field',
      category: 'Registration Events',
      action: 'Form Field Click',
      label: fieldName,
      sign_up_step: 'Step 1a GK',
      sign_up_form_field: fieldName,
    };
    handleGAEventsBase(dataLayer);
  };

  const maxActivationCode = Number(codeLimit) || 5;
  return (
    <RegisterStepGKActivationFormLayout {...props}>
      <FormNumberInput
        onFocus={onFocus}
        id="sceneNumber"
        fieldType="number"
        name="sceneNumber"
        maxAllowedInput={16}
        label={sceneCardNumberLabel}
        defaultVal="604646" // TODO: should we add field to agility?
        maxLength={16}
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKCardNumber.Invalid'),
            value: /^[0-9]*$/,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKCardNumber.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKCardNumber.Length'),
            value: 16,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKCardNumber.Length'),
            value: 16,
          },
        }}
      />
      <div className="mb-8" />
      <Tooltip
        dataForIdTip="activationCodeTip"
        dataForId="activationCode"
        title={activationCodeLabel}
        message={activationCodeTooltip}
        placement="inputLabel"
      />
      <FormNumberInput
        onFocus={onFocus}
        id="activationCode"
        fieldType="number"
        name="activationCode"
        maxLength={maxActivationCode}
        maxAllowedInput={maxActivationCode}
        rules={{
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKActivationCode.Invalid'),
            value: /^[0-9]*$/,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKActivationCode.Required'),
            value: true,
          },
          minLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKActivationCode.Length'),
            value: maxActivationCode,
          },
          maxLength: {
            message: displayFieldErrorMessage(errorsList, 'Customer.GKActivationCode.Length'),
            value: maxActivationCode,
          },
        }}
      />

      <div className="field-flex mt-8">
        <CaptchaDisclaimer />
      </div>
    </RegisterStepGKActivationFormLayout>
  );
};
export default StepGhostKitActivation;
