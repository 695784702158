/* eslint-disable react/no-danger */
import { renderHTML } from '@agility/nextjs';
import { useStateMachine } from 'little-state-machine';

const CaptchaDisclaimer = (props) => {
  const { state } = useStateMachine();
  if (state?.labels && process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
    return <div className="captcha-text" dangerouslySetInnerHTML={renderHTML(state.labels.recaptchaTOS)} />;
  }

  return null;
};

export default CaptchaDisclaimer;
