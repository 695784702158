import PropTypes from 'prop-types';
// Components
import BrandLogo from '../../common/Icons/BrandLogo';
import { Container, ImageCircle, PartnerTitle } from './PinStyles';

export default function PinPartnerTitle({ fields }) {
  const { partnerTitle, partnerLogo } = fields;

  if (partnerLogo?.fields?.logoSolidBG?.url && partnerTitle) {
    return (
      <Container>
        <ImageCircle className="rounded-full">
          <BrandLogo title="" url={partnerLogo?.fields?.logoSolidBG?.url} />
        </ImageCircle>

        <PartnerTitle>{partnerTitle}</PartnerTitle>
      </Container>
    );
  }
  return null;
}

PinPartnerTitle.propTypes = { fields: PropTypes.object };
