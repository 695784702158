import { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useStateMachine } from 'little-state-machine';
// helper Function
import { handleKeyDown } from '../../../helpers/utils';
import {
  getRewardPageButtonClickMapping,
  handleGaEcommImpression,
  getEventPageButtonClickMapping,
} from '../../../helpers/handleGoogleAnalyticsHelper';

import { StyledLink } from './LinkStyles';

const LinkComponent = ({
  href,
  target,
  children,
  buttonLoadingText,
  buttonStyle,
  onClick,
  disabled,
  submitting,
  style,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaExpanded,
  className,
  bannersNum,
  locale,
  item,
  card,
  sectionTitle,
  index,
}) => {
  const [loading, setLoading] = useState(false);
  const { state } = useStateMachine();

  const handleClick = () => {
    setLoading(!loading);
    if (item) {
      if (card === 'reward') {
        handleGaEcommImpression('rewards_add_to_cart', getRewardPageButtonClickMapping(item, index, sectionTitle));
      }
      if (card === 'event') {
        handleGaEcommImpression('events_add_to_cart', getEventPageButtonClickMapping(item, index, sectionTitle));
      }
    }

    const interval = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearInterval(interval);
  };

  if (href) {
    return (
      <StyledLink className={className} bannersNum={bannersNum}>
        <Link
          href={href}
          locale={locale}
          tabIndex="0"
          className={`w-full inline-block ${buttonStyle || ''} 
          ${submitting || loading ? 'loading' : ''} 
          ${disabled ? 'disabled' : ''}`}
          target={target}
          onClick={onClick || handleClick}
          onKeyDown={(e) => handleKeyDown(e, onClick || handleClick)}
          style={style}
          role="link"
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-controls={ariaControls}
          aria-expanded={ariaExpanded}
        >
          <span>{submitting || loading ? buttonLoadingText || state.labels.loadingLabel : children}</span>
        </Link>
      </StyledLink>
    );
  }
  return null;
};

LinkComponent.propTypes = {
  children: PropTypes.node,
  target: PropTypes.string,
  href: PropTypes.string,
  buttonLoadingText: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  style: PropTypes.object,
  ariaLabelledBy: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.string,
  className: PropTypes.string,
  bannersNum: PropTypes.number,
  ariaLabel: PropTypes.string,
  locale: PropTypes.string,
  item: PropTypes.object,
  card: PropTypes.string,
  sectionTitle: PropTypes.string,
  index: PropTypes.number,
};
export default LinkComponent;
