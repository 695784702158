// // Hooks
import Script from 'next/script';
import { useEffect, useState } from 'react';

const showZendesk = process.env.NEXT_PUBLIC_ZENDESK_GLOBAL_DISABLED === 'false';
const src = showZendesk ? process.env.NEXT_PUBLIC_ZENDESK_URI : null;

export default function ZendeskScript({ languageCode }) {
  // track the local  value of the language code
  // only update it if the prev ver is diff than the current
  const [currLang, setCurrLang] = useState(languageCode);

  useEffect(() => {
    if (languageCode && currLang !== languageCode) {
      setCurrLang(languageCode);
    }

    if (src && showZendesk && languageCode && window.zE) {
      window.zE('messenger:set', 'cookies', false);
      // update language on widget
      window.zE('messenger:set', 'locale', languageCode);
      window.zE('messenger:set', 'cookies', true);
      // re-launch widget
      window.zE('messenger', 'show');
    }
  }, [languageCode, showZendesk, src]);

  if (!languageCode) return null;
  if (!showZendesk) return null;
  if (!src) return null;

  return <Script src={src} id="ze-snippet" />;
}
