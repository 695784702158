import PropTypes from 'prop-types';
import Image from 'next/image';

// Components
import ButtonSimple from '../common/Button/ButtonSimple';
import CustomButtonIcon from '../common/Button/CustomButtonIcon';

import { EditButtonContainer } from './FormStyles';
import { handleKeyDown } from '../../helpers/utils';

export default function EditButton({ editLabel, disableEdit, fieldName, handleEditClick, isEditing, closeLabel, ariaControls }) {
  return (
    <EditButtonContainer
      className={`absolute top-0 right-0 md:top-auto md:right-auto md:relative ${
        disableEdit ? 'md:w-52' : 'md:w-16'
      } md:text-right`}
    >
      {disableEdit ? null : (
        <div
          className="cursor-pointer flex-initial font-bold"
          role="button"
          tabIndex="0"
          onClick={handleEditClick}
          onKeyDown={(e) => handleKeyDown(e, handleEditClick)}
        >
          {isEditing ? (
            <CustomButtonIcon
              className="cursor-pointer flex-initial font-bold"
              onClick={handleEditClick}
              style={{ border: 0 }}
              ariaControls={ariaControls}
              ariaExpanded="true"
              ariaLabel={closeLabel}
              role="button"
            >
              <Image src="/assets/close-x.svg" alt={closeLabel} height={15} width={15} className="mx-auto" />
            </CustomButtonIcon>
          ) : (
            <ButtonSimple
              ariaLabel={`${fieldName || ''} - ${editLabel}`}
              ariaControls={ariaControls}
              ariaExpanded="false"
              role="button"
            >
              {editLabel}
            </ButtonSimple>
          )}
        </div>
      )}
    </EditButtonContainer>
  );
}
EditButton.propTypes = {
  editLabel: PropTypes.string,
  disableEdit: PropTypes.bool,
  fieldName: PropTypes.string,
  handleEditClick: PropTypes.func,
  isEditing: PropTypes.bool,
  closeLabel: PropTypes.string,
  ariaControls: PropTypes.string,
};
