import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import { generateAddressStringFrom } from '../../../../helpers/stringHelpers';
import { convertToSlug } from '../../../../helpers/utils';

import { Row, Title, FieldContent, StyledAddressValue, DescriptionText } from '../../FormStyles';

// Components
import Loader from '../../../common/Loader/Loader';
import AddNewAddresss from '../AddAddress/AddNewAddresss';
import EditButton from '../../EditButton';
import Address from '../Address/Address';

export default function ProfileAddressField(props) {
  const {
    fieldName,
    fieldNameExpanded,
    message,
    disableEdit,
    fieldAddressName,
    fieldShippingName,
    provinces,
    shippingAddresses,
    loading,
    languageCode,
  } = props;

  const [isEditing, setEditing] = useState(false);
  const [shippingList, setAddressList] = useState(shippingAddresses);

  useEffect(() => {
    setAddressList(shippingAddresses);
  }, [shippingAddresses]);

  const handleEditClick = () => {
    setEditing(!isEditing);
  };

  return (
    <Row className="mb-0.5">
      <FocusTrap
        active={isEditing}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none',
          },
          initialFocus: false,
          allowOutsideClick: true,
        }}
      >
        <div className="md:flex justify-between items-baseline content-center relative">
          <div className="w-52 ProfileFieldWrapper">
            <Title className="mb-1 md:mb-0 font-bold mr-4">{isEditing ? fieldNameExpanded : fieldName}</Title>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                {disableEdit || !isEditing ? (
                  shippingList &&
                  shippingList
                    .filter((addy) => addy.addressType === 'Scene')
                    .map((address) => (
                      <StyledAddressValue key={address.uniqueId} className="px-0 md:p-1">
                        {generateAddressStringFrom(address)}
                      </StyledAddressValue>
                    ))
                ) : (
                  <FieldContent className="py-2 md:p-2">
                    {message && <DescriptionText className="block mb-6 text-md">{message}</DescriptionText>}

                    {shippingList && (
                      <div className="profile-address-wrapper mb-8">
                        {shippingList && <p className="block mb-2 font-normal text-sm address-field">{fieldAddressName}</p>}

                        {shippingList &&
                          shippingList
                            .filter((addy) => addy.addressType === 'Scene')
                            .map((address) => (
                              <Address
                                key={address.uniqueId}
                                {...props}
                                setAddressList={setAddressList}
                                disableNameEdit
                                provinces={provinces}
                                address={address}
                              />
                            ))}
                      </div>
                    )}

                    {shippingList && (
                      <div className="shipping-address-wrapper mb-4">
                        {shippingList.length > 1 && (
                          <p className="block mb-2 font-normal text-sm address-field">{fieldShippingName}</p>
                        )}

                        {shippingList &&
                          shippingList
                            .filter((addy) => addy.addressType === 'Shipping')
                            ?.sort((a, b) => b.primary - a.primary)
                            .map((address) => (
                              <Address
                                key={address.uniqueId}
                                {...props}
                                setAddressList={setAddressList}
                                provinces={provinces}
                                address={address}
                              />
                            ))}
                      </div>
                    )}

                    {!disableEdit && (
                      <AddNewAddresss
                        {...props}
                        setAddressList={setAddressList}
                        isEditing={isEditing}
                        provinces={provinces}
                        languageCode={languageCode}
                      />
                    )}
                  </FieldContent>
                )}
              </div>

              <EditButton
                {...props}
                handleEditClick={handleEditClick}
                isEditing={isEditing}
                ariaControls={`profile-${convertToSlug(fieldName)}`}
              />
            </>
          )}
        </div>
      </FocusTrap>
    </Row>
  );
}
ProfileAddressField.propTypes = {
  fieldName: PropTypes.string,
  message: PropTypes.string,
  fieldNameExpanded: PropTypes.string,
  disableEdit: PropTypes.bool,
  loading: PropTypes.bool,
  fieldAddressName: PropTypes.string,
  fieldShippingName: PropTypes.string,
  provinces: PropTypes.array,
  shippingAddresses: PropTypes.array,
  languageCode: PropTypes.string,
};
