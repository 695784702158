import PropTypes from 'prop-types';

import { InnerDetails, SectionTitle } from '../DetailsStyles';

import { postalSpace } from '../../../../helpers/utils';

export default function DetailsShippingAddress({ shippingAddress, shippingAddressLabel }) {
  const { firstName, lastName, addressLine1, city, province, postalCode } = shippingAddress;

  if (shippingAddress && addressLine1 && city && province && postalCode) {
    return (
      <>
        {shippingAddressLabel && <SectionTitle>{shippingAddressLabel}</SectionTitle>}
        <InnerDetails>
          {firstName && lastName && (
            <p className="shipping-add">
              {firstName} {lastName}
            </p>
          )}
          <p className="shipping-add">{addressLine1}</p>
          <p className="shipping-add">{`${city}, ${province}, ${postalSpace(postalCode)}`}</p>
        </InnerDetails>
      </>
    );
  }
  return null;
}

DetailsShippingAddress.propTypes = {
  shippingAddress: PropTypes.object,
  shippingAddressLabel: PropTypes.string,
};
