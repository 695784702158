import PropTypes from 'prop-types';

import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';
import TermsContent from '../../common/Terms/TermsContent';

function ConfirmTermsAndConditions({ termsConditionsMessage, termsConditionsLabel }) {
  if (termsConditionsMessage) {
    return (
      <div>
        <SectionDetails termsAndConditions>
          <SectionTitle>{termsConditionsLabel}</SectionTitle>
          <DetailsInfo>
            <TermsContent termsBlob={termsConditionsMessage} />
          </DetailsInfo>
        </SectionDetails>
      </div>
    );
  }
  return null;
}

ConfirmTermsAndConditions.propTypes = {
  termsConditionsMessage: PropTypes.string.isRequired,
  termsConditionsLabel: PropTypes.string.isRequired,
};

export default ConfirmTermsAndConditions;
