import PropTypes from 'prop-types';

import MissingPoints from './MissingPoints/MissingPoints';

import { PointsHistoryMobileHeader } from './PointsHistoryStyles';

export default function PointsMobileHeader({ customData = [] }) {
  return (
    <div className="block sm:hidden">
      <PointsHistoryMobileHeader>
        <MissingPoints customData={customData} iconOnly />
      </PointsHistoryMobileHeader>
    </div>
  );
}

PointsMobileHeader.propTypes = {
  customData: PropTypes.object.isRequired,
};
