import PropTypes from 'prop-types';

import ClientOnlyPortal from '../common/Portal/ClientOnlyPortal';
import OnboardingContentDetails from './OnboardingContentDetails';

export default function OnboardingContent({ contents, handleClick, gotItLabel, closeLabel, isRewards1Onboarded }) {
  if (contents) {
    return (
      <>
        {contents
          .filter((onboardFilter) => {
            if (!isRewards1Onboarded) {
              return onboardFilter.fields.code !== 'rewards2';
            }
            return onboardFilter.fields.code !== 'rewards1';
          })
          .map((content) => (
            <ClientOnlyPortal key={content.contentID} selector={`#portal-${content.fields.code}`}>
              <OnboardingContentDetails
                content={content}
                handleClick={handleClick}
                gotItLabel={gotItLabel}
                closeLabel={closeLabel}
              />
            </ClientOnlyPortal>
          ))}
      </>
    );
  }
  return null;
}

OnboardingContent.propTypes = {
  contents: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
  gotItLabel: PropTypes.string.isRequired,
  closeLabel: PropTypes.string.isRequired,
  isRewards1Onboarded: PropTypes.string,
};
