import { CustomStyledButtonIcon, CustomStyledInner } from './ButtonStyles';

const CustomButtonIcon = ({
  type,
  buttonType,
  children,
  onClick,
  disabled,
  style,
  className,
  role,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaHaspopup,
  id,
  tabIndex,
  ariaExpanded = null,
}) => (
  <CustomStyledButtonIcon
    type={type}
    className={`${className ? `${className} ` : ''}w-full inline-block`}
    onClick={onClick}
    disabled={disabled}
    style={style}
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    aria-controls={ariaControls}
    aria-haspopup={ariaHaspopup}
    role={role}
    id={id}
    tabIndex={tabIndex}
    aria-expanded={ariaExpanded}
  >
    <CustomStyledInner buttonType={buttonType}>{children}</CustomStyledInner>
  </CustomStyledButtonIcon>
);

export default CustomButtonIcon;
