import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../Images/ExternalImage';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

import { StyledList, ImageCircle } from './ListCircleImageStyles';

export default function ListCircleImages({ data, languageCode }) {
  // redeem_filter_click
  const handleClick = (title) => {
    if (title) {
      const dataLayer = {
        event: 'redeem_filter_click',
        eventCategory: 'Reward',
        eventAction: 'Filter Click',
        eventLabel: title,
        category: title,
      };

      handleGAEventsBase(dataLayer);
    }
  };
  if (data && data.length) {
    return (
      <StyledList className="flex flex-wrap p-0 m-0 content-start" role="list">
        {data.map((item, index) => (
          <li key={index} className="list-none">
            <div className="relative text-center">
              <Link
                href={item.link}
                locale={languageCode}
                className="w-full h-full cursor-pointer no-underline"
                onClick={() => {
                  handleClick(item.title);
                }}
              >
                <ImageCircle className="rounded-full mx-auto category-circle-tile" aria-hidden="true">
                  <ExternalImage url={item.imageSrc} fill className="object-cover object-center" />
                </ImageCircle>
                <span className="category-tile-title">{item.title}</span>
              </Link>
            </div>
          </li>
        ))}
      </StyledList>
    );
  }
  return null;
}

ListCircleImages.propTypes = {
  data: PropTypes.array.isRequired,
  languageCode: PropTypes.string.isRequired,
};
