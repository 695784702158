import { createPortal } from 'react-dom';

import useHasMount from '../../hooks/utils/useHasMount';

export default function Portal({ children }) {
  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  return hasMounted ? createPortal(children, document.querySelector('#portal')) : null;
}
