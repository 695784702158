import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import WrapperLayout from '../layouts/WrapperLayout.js';
import GuestSignInButton from './GuestSignInButton';
import { Section, SectionTitle } from './GuestSignInComponentStyles';

export default function GuestSignInComponent({ fields, languageCode }) {
  const {
    state: {
      user: { customer },
    },
  } = useStateMachine();

  return (
    <div className="signin-pattern-bg min-half-screen">
      <WrapperLayout className="global-content-wrapper" mobileIsWhite>
        <Section>
          <div className="form-container bg-white text-base relative md:shadow-xl w-full max-w-xl mx-auto">
            <div className="px-0 py-6 sm:py-12 sm:px-28 text-center">
              {fields.title && <SectionTitle>{fields.title}</SectionTitle>}
              {fields.message && <p>{fields.message}</p>}
              {fields.signinLabel && fields.signinLabelLoading && (
                <GuestSignInButton fields={fields} languageCode={languageCode} loading={customer.data} />
              )}
            </div>
          </div>
        </Section>
      </WrapperLayout>
    </div>
  );
}

GuestSignInComponent.propTypes = {
  fields: PropTypes.object,
  languageCode: PropTypes.string,
};
