/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

import {
  handleGaEcommImpression,
  getRewardTileViewMapping,
  getEventTileViewMapping,
} from '../../../helpers/handleGoogleAnalyticsHelper';

import LinkComponent from '../Links/LinkComponent';
import TermsComponent from '../Terms/TermsComponent';

import { ModalPopupContainer, ModalPopupHeader, ModalPopupBody } from './ModalStylesV2';

export default function InnerCardModal({ item, card, sectionTitle, index }) {
  const {
    brandInfo,
    brand,
    title,
    pointsConversionLabel,
    webTermsAndConditions,
    cta,
    cTALink,
    detailsMessage,
    description,
    howToSpendMessage,
    dateTimeLocation,
    additionalCallout,
  } = item;
  const {
    state: {
      labels: { detailsLabel, howToSpendLabel, termsAndConditionsLabel, loadingLabel },
    },
  } = useStateMachine();

  useEffect(() => {
    if (card === 'reward') {
      handleGaEcommImpression('rewards_view_item', getRewardTileViewMapping(item, `card-${index}`, sectionTitle));
    }
    if (card === 'event') {
      handleGaEcommImpression('event_view_item', getEventTileViewMapping(item, `card-${index}`, sectionTitle));
    }
  }, [item]);

  return (
    <ModalPopupContainer>
      <ModalPopupHeader>
        {(brandInfo || brand) && (
          <h1 className="brand-title" id="modal-label">
            {brandInfo ? brandInfo.fields.title : brand?.title}
          </h1>
        )}
        {title && <h2 className="card-title">{title}</h2>}

        {pointsConversionLabel && <p className="offer-sub font-bold">{pointsConversionLabel}</p>}
        {dateTimeLocation && <p className="offer-sub font-bold">{dateTimeLocation}</p>}
      </ModalPopupHeader>
      <ModalPopupBody>
        {(detailsMessage || description) && (
          <p>
            <strong>{detailsLabel} </strong>
            {detailsMessage || description}
          </p>
        )}
        {howToSpendMessage && (
          <p>
            <strong>{howToSpendLabel} </strong>
            {howToSpendMessage}
          </p>
        )}
        {additionalCallout && <p dangerouslySetInnerHTML={{ __html: brandInfo.fields.additionalCallout }} />}
        {(cta || cTALink) && (
          <LinkComponent
            href={cta?.href || cTALink?.href}
            target={cta?.target || cTALink?.target}
            style={{ fontSize: '1rem', maxWidth: 'inherit' }}
            buttonLoadingText={loadingLabel}
            buttonStyle="primary-button"
            item={item}
            card={card}
            sectionTitle={sectionTitle}
            index={index}
          >
            {cta?.text || cTALink?.text}
          </LinkComponent>
        )}
      </ModalPopupBody>
      {webTermsAndConditions && (
        <TermsComponent termsBlob={webTermsAndConditions} scrollable termsAndConditionsLabel={termsAndConditionsLabel} />
      )}
    </ModalPopupContainer>
  );
}

InnerCardModal.propTypes = {
  item: PropTypes.object,
  card: PropTypes.any,
  sectionTitle: PropTypes.string,
  index: PropTypes.number,
};
