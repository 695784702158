/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import LogoContainer from './LogoContainer';
import { LogoDescriptionContainer, Item } from './LogoDescriptionSectionStyles';
import { isEmpty } from '../../../helpers/utils';

export default function LogoDescriptionSection({ fields }) {
  if (!isEmpty(fields)) {
    const data = [];

    if (fields.logo1 && fields.cTALink1) {
      data.push({
        image: fields.logo1,
        link: fields.cTALink1,
      });
    }
    if (fields.logo2 && fields.cTALink2) {
      data.push({
        image: fields.logo2,
        link: fields.cTALink2,
      });
    }
    if (fields.logo3 && fields.cTALink3) {
      data.push({
        image: fields.logo3,
        link: fields.cTALink3,
      });
    }
    return (
      <LogoDescriptionContainer>
        <div className="global-content-wrapper">
          <Item>
            <section className={data.length > 1 ? 'extra-padding' : ''}>
              <LogoContainer data={data} />
            </section>

            <div>
              <h2>{fields.title}</h2>
              <div dangerouslySetInnerHTML={renderHTML(fields.message)} />
            </div>
          </Item>
        </div>
      </LogoDescriptionContainer>
    );
  }
  return null;
}

LogoDescriptionSection.propTypes = { fields: PropTypes.object };
