import PropTypes from 'prop-types';

import Image from 'next/image';
import ButtonIcon from '../../../common/Button/ButtonIcon';
import Button from '../../../common/Button/Button';
import ButtonSimple from '../../../common/Button/ButtonSimple';

import { ModalWrapper } from './DeleteAddressStyles';

export default function DeleteAddress(props) {
  const {
    removeAddressModalHeader,
    removeAddressModalMessage,
    yesLabel,
    noLabel,
    closeLabel,
    handleAddressDelete,
    setDeleteModal,
    deleteModal,
  } = props;

  if (deleteModal) {
    return (
      <>
        <span className="overlay" />
        <ModalWrapper className="p-8 bg-white shadow-xl">
          <div className="relative modal-header flex w-full">
            <h4 className="text-lg font-bold mb-0">{removeAddressModalHeader}</h4>
            <ButtonIcon
              ariaLabel={closeLabel}
              onClick={() => setDeleteModal(!deleteModal)}
              role="button"
              className="absolute top-0 right-0"
            >
              <Image src="/assets/close-x.svg" alt={closeLabel} title={closeLabel} height={15} width={15} />
            </ButtonIcon>
          </div>

          <p className="mt-8">{removeAddressModalMessage}</p>

          <div className="flex w-full flex-col justify-center mt-6">
            <Button
              type="button"
              ariaLabel={yesLabel}
              className="w-full"
              onClick={handleAddressDelete}
              buttonStyle="primary-button"
            >
              {yesLabel}
            </Button>
            <ButtonSimple
              type="button"
              ariaLabel={noLabel}
              className="mt-4 w-auto mx-auto"
              onClick={() => setDeleteModal(!deleteModal)}
              buttonStyle="secondary-button"
              role="button"
            >
              {noLabel}
            </ButtonSimple>
          </div>
        </ModalWrapper>
      </>
    );
  }
  return null;
}

DeleteAddress.propTypes = {
  removeAddressModalHeader: PropTypes.string,
  removeAddressModalMessage: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  handleAddressDelete: PropTypes.func,
  setDeleteModal: PropTypes.func,
  deleteModal: PropTypes.bool,
};
