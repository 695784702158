import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../../../store/actions';

// Components
import FormSave from '../../FormSave';
import EditButton from '../../EditButton';
import SettingsSMSFieldContent from './SettingsSMSFieldContent';

// Services
import { savePreferences } from '../../../../pages/api/services';

// Utils
import { convertToSlug } from '../../../../helpers/utils';
import { savingSMSSettingsValues, setDisableSMSField } from '../../../../helpers/settingsHelper';
import { handleGAEvents, stringConcatHelper } from '../../../../helpers/handleGoogleAnalyticsHelper';

// Styles
import { Row, Title } from '../../FormStyles';

export default function SettingsSMS({ customData, errorsList, refetch }) {
  const { textMessageLabel, saveLabel, saveLabelLoading, cancelLabel, phoneSuccessMessage } = customData;

  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const {
    actions,
    state: {
      user: { SMS, customer, preferencesData },
      session: { accessToken },
    },
  } = useStateMachine({ updateUser, updateNotification });

  const primaryPhoneExists = Boolean(customer.data?.contacts[0]?.primaryPhoneNumber);
  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const handleEditClick = () => {
    setEditing(!isEditing);
  };

  const onSubmit = (data) => {
    const gaLabel = stringConcatHelper('Text Messages - ', data.SMS === undefined || data.SMS === false ? 'false' : 'true');
    handleGAEvents(gaLabel, 'Account', 'Text Messages Toggle Click', 'account_settings_text_messages_toggle');
    setSubmitting(true);

    savePreferences(accessToken, savingSMSSettingsValues(data))
      .then((res) => {
        if (res.data) {
          refetch();
          actions.updateNotification({
            message: phoneSuccessMessage,
            status: 'success',
          });

          actions.updateUser({
            SMS: res.data?.data,
          });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: errorsList && errorsList[0].message,
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <Row className="mb-1">
        <form className="relative px-3 py-3 pt-0 pb-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="md:flex justify-between content-center items-start">
            <div className="w-52 SettingFieldWrapper">
              <Title className="mb-4 md:mb-0 font-bold mr-4 flex-initial">{textMessageLabel}</Title>
            </div>
            <div className="w-full">
              <SettingsSMSFieldContent customData={customData} isEditing={isEditing} errorsList={errorsList} smsSavedData={SMS} />

              {isEditing && (
                <FormSave
                  buttonText={saveLabel}
                  buttonLoadingText={saveLabelLoading}
                  submitting={submitting}
                  cancelLabel={cancelLabel}
                  isEditing={isEditing}
                  updateEditClick={handleEditClick}
                  isDisabled={Boolean(!primaryPhoneExists) || setDisableSMSField(preferencesData, SMS)}
                />
              )}
            </div>

            <EditButton
              {...customData}
              handleEditClick={handleEditClick}
              isEditing={isEditing}
              ariaControls={convertToSlug(textMessageLabel)}
            />
          </div>
        </form>
      </Row>
    </FormProvider>
  );
}
