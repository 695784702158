import PropTypes from 'prop-types';
import ExternalImage from '../Images/ExternalImage';
import {
  CardItem,
  CardImage,
  CardContents,
  CardHeader,
  CardBody,
  CardLogo,
  CardCustomerName,
  Barcode,
  Points,
  PointsLabel,
  PointsContainer,
  MyCardWrapper,
} from './MyCardStyles';
import { formatCardString } from '../../../helpers/stringHelpers';

export default function MyCard({ customData, customerData }) {
  const { barcodeLabel, cardBackgroundImage, logo } = customData.MyCardForm;

  return (
    <MyCardWrapper>
      <CardItem className="w-full flex">
        <CardContents className="card-content-wrapper">
          <CardHeader className="w-full">
            <CardImage className="relative overflow-hidden bg-black">
              {cardBackgroundImage && (
                <ExternalImage url={cardBackgroundImage?.url} fill className="object-cover object-center" />
              )}
              <CardLogo>{logo && <ExternalImage url={logo.url} width={78} height={24} />}</CardLogo>
              <CardCustomerName>
                {customerData?.customer?.data?.firstName} {customerData?.customer?.data?.lastName}
              </CardCustomerName>
              <PointsContainer>
                <Points>
                  {customerData?.balance} <PointsLabel>points</PointsLabel>
                </Points>
              </PointsContainer>
            </CardImage>
          </CardHeader>
          <CardBody>
            <Barcode>
              {customerData?.barcodeImageBase64 && (
                <ExternalImage url={customerData.barcodeImageBase64} height={62} width={228} title={barcodeLabel} />
              )}
              <div className="m-2">{formatCardString(customerData?.customer?.data?.sceneCardNumber)}</div>
            </Barcode>
          </CardBody>
        </CardContents>
      </CardItem>
    </MyCardWrapper>
  );
}

MyCard.propTypes = { customData: PropTypes.object, customerData: PropTypes.object };
