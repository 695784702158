import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
// Components
import Title from '../../common/Headings/Title';

// Styles
import { DetailsWrapper } from '../../Points/Shop/PointsShopStyles';

// Components
import ConfirmationItems from './ConfirmationItems';
import ConfirmationEmail from './ConfirmationEmail';
import ConfirmTermsAndConditions from './ConfirmTermsAndConditions';
import ConfirmPointsSummary from './ConfirmPointsSummary';
import ConfirmationShippingAddress from './ConfirmationShippingAddress';

function GiftCardDetailsConfirmation({ customData, handleSubmit, submitting, orderRequested, languageCode }) {
  const {
    confirmHeader,
    pts,
    itemsLabel,
    checkoutSummaryLabel,
    summaryPointsLabel,
    summaryBalanceLabel,
    summaryOrderTotalLabel,
    confirmRedeemedLabel,
    termsConditionsLabel,
    termsConditionsMessage,
    emailAddressLabel,
    qtyLabel,
    confirmButtonLabel,
    confirmButtonLabelLoading,
    shippingAddressLabel,
    addNewAddressLink,
    digitalCardLabel,
    physicalCardLabel,
    title,
  } = customData.pageContent;
  const {
    state: {
      giftCards: {
        redeem: { selectedFormat },
      },
    },
  } = useStateMachine();
  const formMethods = useForm();

  const onSubmit = async (data) => {
    handleSubmit();
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <>
      {confirmHeader && <Title customStyles="confirmPage">{confirmHeader}</Title>}
      <DetailsWrapper page="gc">
        <ConfirmationItems
          itemsLabel={itemsLabel}
          qtyLabel={qtyLabel}
          title={title}
          digitalCardLabel={digitalCardLabel}
          physicalCardLabel={physicalCardLabel}
        />
        <ConfirmationEmail emailAddressLabel={emailAddressLabel} />
        {selectedFormat === 'Physical' && (
          <ConfirmationShippingAddress
            shippingAddressLabel={shippingAddressLabel}
            addNewAddressLink={addNewAddressLink}
            customData={customData.pageContent}
            errorsList={customData.errorsList}
            languageCode={languageCode}
            profileContent={customData.profileContent}
          />
        )}
        <ConfirmTermsAndConditions termsConditionsLabel={termsConditionsLabel} termsConditionsMessage={termsConditionsMessage} />
        <FormProvider {...formMethods}>
          <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
            <ConfirmPointsSummary
              summaryOrderTotalLabel={summaryOrderTotalLabel}
              summaryPointsLabel={summaryPointsLabel}
              summaryBalanceLabel={summaryBalanceLabel}
              confirmRedeemedLabel={confirmRedeemedLabel}
              checkoutSummaryLabel={checkoutSummaryLabel}
              submitting={submitting}
              orderRequested={orderRequested}
              pts={pts}
              confirmButtonLabel={confirmButtonLabel}
              confirmButtonLabelLoading={confirmButtonLabelLoading}
            />
          </form>
        </FormProvider>
      </DetailsWrapper>
    </>
  );
}

GiftCardDetailsConfirmation.propTypes = {
  customData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleErrors: PropTypes.func,
  submitting: PropTypes.bool,
  orderRequested: PropTypes.bool,
  languageCode: PropTypes.string,
};
export default GiftCardDetailsConfirmation;
