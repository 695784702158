import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 0.75rem 0.75rem 4.5rem;
  @media screen and (max-width: 640px) {
    padding: 1.125rem;
  }
`;
export const ModalIcon = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  margin: 0 auto 2.5rem;

  @media screen and (min-width: 641px) {
    width: 5rem;
    height: 5rem;
    margin: 1.25rem auto 1.5rem;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: ${(props) => props.theme.headingSmall};
    line-height: ${(props) => props.theme.lineHeight3};
    margin: 0.75rem auto 1.5rem;
    @media screen and (min-width: 641px) {
      margin: 0 auto 1.5rem;
    }
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2.5rem;
  color: ${(props) => props.theme.uiDarkGrey};
  strong {
    color: ${(props) => props.theme.uiCharcoal};
  }
  p {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    color: ${(props) => props.theme.uiDarkGrey};
    strong {
      color: ${(props) => props.theme.uiCharcoal};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 640px) {
    position: absolute;
    bottom: 1.125rem;
    left: 1.125rem;
    right: 1.125rem;
    margin: 0;
    width: unset;
    background-color: ${(props) => props.theme.whiteColor};
  }
`;

// ProvincialModal
export const ProvinceModalContainer = styled.div`
  padding: 1.625rem 1.625rem 4.5rem;
  @media screen and (max-width: 640px) {
    padding: 0;
    position: absolute;
    bottom: 1.125rem;
    left: 1.125rem;
    right: 1.125rem;
    top: 1.125rem;
  }
`;
export const ProvincialModalHeaderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: ${(props) => props.theme.headingMedium};
    line-height: ${(props) => props.theme.lineHeight2};
    font-weight: 700;

    &.hide-on-mobile {
      margin: 1.5rem auto 3.5rem;
      @media screen and (max-width: 640px) {
        display: none;
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        margin: 1.5rem auto 3.125rem;
        font-size: ${(props) => props.theme.headingSmall};
      }
    }
    &.hide-on-desktop {
      margin: 1rem auto 1.5rem;
      font-size: ${(props) => props.theme.fontBody1};
      @media screen and (min-width: 641px) {
        display: none;
      }
    }
  }
`;

export const ProvincialModalBodyContainer = styled.div`
  width: 100%;
  line-height: ${(props) => props.theme.lineHeight2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;

  button.secondary-button-black {
    padding: 1rem 2rem !important;
  }

  button.cancel-button {
    margin: 1rem;
  }
`;

export const ProvinceMobileLabelWrapper = styled.div`
  @media screen and (min-width: 641px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 0 auto 2rem;
    p {
      margin-bottom: 0;
      text-align: center;
      font-size: ${(props) => props.theme.fontBody2};
      color: ${(props) => props.theme.uiDarkGrey};
      line-height: ${(props) => props.theme.lineHeight4};

      strong {
        color: ${(props) => props.theme.uiBlack};
        display: block;
      }
    }
  }
`;

export const ProvinceDropDownWrapper = styled.div`
  @media screen and (min-width: 641px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    width: 100%;
  }
`;
export const ProvinceItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 1.875rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.125rem;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;
export const ProvinceMobileButtonContainer = styled.div`
  display: flex;

  width: 100%;
  @media screen and (max-width: 640px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: unset;
    background-color: ${(props) => props.theme.whiteColor};
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// modal-info rewards, events
export const ModalPopupContainer = styled.div`
  margin: auto;
  max-width: 26.75rem;
`;

export const ModalPopupHeader = styled.div`
  .brand-title {
    font-size: ${(props) => props.theme.fontBody2};
    color: ${(props) => props.theme.lightGrayColor4};
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
  }
  .card-title {
    font-size: ${(props) => props.theme.headingSmall};
    color: ${(props) => props.theme.brandBlack};
    margin-bottom: 0.5rem;
  }
  .offer-sub {
    color: ${(props) => props.theme.uiPurple};
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 1.5rem;
  }
`;
export const ModalPopupBody = styled.div`
  position: relative;
  width: 100%;
  line-height: ${(props) => props.theme.lineHeight3};
  font-size: ${(props) => props.theme.fontBody2};

  p {
    font-size: ${(props) => props.theme.fontBody2};
    margin-bottom: 1.5rem;
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
      margin-bottom: 1rem;
    }
  }

  .expiry-date {
    color: ${(props) => props.theme.lightGrayColor4};
  }
  .primary-button {
    margin-left: 0;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 640px) {
      width: 100%;
      text-align: center;
    }
  }
`;
