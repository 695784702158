import styled from 'styled-components';

export const InlineMessageBanner = styled.div`
  height: ${(props) => props.theme.inlineMsgMobile};
  @media screen and (min-width: 1440px) {
    height: ${(props) => props.theme.inlineMsgDesktop};
  }
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  z-index: 99;
  color: ${(props) => props.theme.brandBlack};

  @media screen and (max-width: 640px) {
    padding: 1rem 0;
  }
  background: ${(props) => props.theme.uiLightBlue};

  button.n-close {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 0;
    margin: 0 0 0 1.25rem;
    text-align: center;
    @media screen and (max-width: 640px) {
      margin: 0 0 0 0.75rem;

      img {
        width: 0.75rem;
        height: 0.75rem;
        min-width: 0.75rem;
        min-height: 0.75rem;
      }
    }
  }
`;
export const Content = styled.section`
  position: relative;
  padding-left: 2.75rem;
  &:before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/assets/info.svg');
    background-repeat: no-repeat;
    background-size: 1.5rem;
  }

  @media screen and (max-width: 640px) {
    padding-left: 1.875rem;

    &:before {
      top: 1px;
      width: 1.125rem;
      height: 1.125rem;
      background-size: 1.125rem;
    }
  }
`;

export const BannerHeader = styled.h2`
  font-size: ${(props) => props.theme.fontBody1};
  font-weight: 700;
  margin-bottom: 0;
  line-height: ${(props) => props.theme.lineHeight3};
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
`;

export const BannerMessage = styled.div`
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight3};

  p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody3};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;
