import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Helpers
import { formatPoints, calcPointsTotal } from '../../../helpers/pointsHelpers';

function ConfirmationSummaryBalance({ summaryBalanceLabel, pts, locale }) {
  const {
    state: {
      transactions: { balance },
      points: { added },
      giftCards: { redeem },
    },
  } = useStateMachine();

  const total = calcPointsTotal(added) * redeem.quantity;
  const remainingPoints = balance - total;

  if (remainingPoints > 0) {
    return (
      <div className="flex justify-between">
        {summaryBalanceLabel && <p className="font-bold">{summaryBalanceLabel}</p>}

        <p className="font-bold">
          {formatPoints(remainingPoints, locale)} {pts}
        </p>
      </div>
    );
  }

  return (
    <div className="flex justify-between">
      {summaryBalanceLabel && <p className="font-bold">{summaryBalanceLabel}</p>}

      <p className="font-bold">
        {formatPoints(balance, locale)} {pts}
      </p>
    </div>
  );
}

ConfirmationSummaryBalance.propTypes = {
  summaryBalanceLabel: PropTypes.string.isRequired,
  pts: PropTypes.string.isRequired,
};

export default ConfirmationSummaryBalance;
