import { formatDynamicLabel } from './stringHelpers';

export function getQueryParam(param, url) {
  const rx = new RegExp(`[?&]${param}=([^&]+).*$`);
  const returnVal = url.match(rx);

  return returnVal === null ? '' : returnVal[1];
}

export function getUrlEncodedQueryParam(param) {
  const urlString = window.location.href;
  const paramQuery = new URL(urlString).searchParams.get(param);

  return encodeURIComponent(paramQuery);
}

export function deriveParamsObj(paramsObj, param) {
  const [key, value] = param.split('=');

  paramsObj[key] = value;

  return paramsObj;
}

export function paramsReducer(params, initialObj = {}) {
  return params.split('&').reduce(deriveParamsObj, initialObj);
}

/**
 *
 * @param {String} uri
 * @returns Params object with signature {id: value}
 * used for marketing links where the uri has a hash for the id and
 * query params for the encrypted user token
 * EX: "CARA00875?q=6oOHHx55Yvs2j9"
 */

/**
 * Used on marketing links that have a leading #/ABC?q=adasdas&oid=223234
 *
 * @param {String} uri
 * @returns a params object with the 1st key as id
 *
 *
 */
export function parseURIHashWithQuery(uri) {
  // Take out the first value which represents the id and the query string
  const [id, query] = uri.split('?');

  return paramsReducer(query, { id });
}

/**
 *
 * @param {String} uri
 * @returns simple object where the { param: value }
 */
export function parseURIHash(uri) {
  if (!uri.includes('?') && !uri.includes('&')) {
    const [key, value] = uri?.split('=');

    return { [key]: value };
  }

  return paramsReducer(uri, {});
}

/**
 *
 * @param {string} url
 * @returns the url if no query params on the hash uri OR a ParamsObject with the ID as the key
 */
export function parseHashParams(url) {
  if (!url) return url;

  if (url.includes('state=')) {
    return parseURIHash(url);
  }

  if (!url || !url.includes('?')) return url;

  return parseURIHashWithQuery(url);
}

export function validateParamsPresent({ params, url }) {
  // parse out the param values, check if there are any empty values (ie includes true)
  // if missing values, we return false (ie not valid)
  // if (!params.length) return null;

  return !params?.map((param) => getQueryParam(param, url)).includes('');
}

export function updateURI(uri) {
  if (window.history) {
    window.history.replaceState({ ...window.history.state, as: uri, url: uri }, '', uri);
  }
}

// function to remove query params from a URL
export function removeURLParameter(url, parameter) {
  // better to use location.search if we have a location/link object
  const urlparts = url.split('?');

  if (urlparts.length >= 2) {
    const prefix = `${parameter}=`;
    const params = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = params.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (params[i].lastIndexOf(prefix, 0) !== -1) {
        params.splice(i, 1);
      }
    }

    return urlparts[0] + (params.length > 0 ? `?${params.join('&')}` : '');
  }
  return url;
}

// function to add/update query params
export function insertParam(key, value) {
  if (window.history.pushState && !window.location.search.includes(`${key}=${value}`)) {
    // let newurl = window.location.protocol + "//" + window.location.host + search.pathname + '?myNewUrlQuery=1';
    let currentUrlWithOutHash = window.location.origin + window.location.pathname + window.location.search;
    const { hash } = window.location;
    // remove any param for the same key
    currentUrlWithOutHash = removeURLParameter(currentUrlWithOutHash, key);

    // figure out if we need to add the param with a ? or a &
    let queryStart;
    if (currentUrlWithOutHash.indexOf('?') !== -1) {
      queryStart = '&';
    } else {
      queryStart = '?';
    }

    // next JS slug path url
    if (queryStart === '?') {
      return `${window.location.pathname + queryStart + key}=${value}${hash}`;
    }
    return `${window.location.pathname + window.location.search + queryStart + key}=${value}${hash}`;
  }
}

export function getQueryStringParams(query) {
  // eslint-disable-next-line prettier/prettier
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
        // eslint-disable-next-line prettier/prettier
    }, {}
    )
    : {};
}

export function derivePathFrom(url = '') {
  const splitChar = url.indexOf('#') >= 0 ? '#' : '?';
  return url.split(splitChar)[0];
}

export function isValidAgilityURI({ uri, sitemap }) {
  return sitemap.includes(derivePathFrom(uri));
}

export function isActiveTab(href, path) {
  if (!href.includes('?')) {
    return href === path;
  }
  const menuLink = href.substring(0, href.indexOf('?'));

  return menuLink === path;
}

// Breadcrumbs
export function deriveBreadcrumbs({ url, title, customData, brandName, parts }) {
  if (!customData || !customData[url] || parts.length < 3) return { breadcrumbs: null, breadcrumbPage: null };

  let breadcrumbPage = '';
  let tempPath = url;
  const homelink = { path: '/', menuText: customData['/home'].menuText };
  const breadcrumbs = [];

  if (title) {
    if (title.indexOf('##BRANDNAME##') < 0) {
      breadcrumbPage = title;
    } else if (brandName) {
      breadcrumbPage = formatDynamicLabel(title, '##BRANDNAME##', brandName);
    }
  } else {
    breadcrumbPage = customData[tempPath].menuText;
  }

  for (let i = 0; i < parts.length - 2; i++) {
    tempPath = tempPath.substring(0, tempPath.lastIndexOf('/'));
    // check if redirect not null
    const breadcrumbsPath = customData[tempPath].redirect ? customData[tempPath].redirect.url : customData[tempPath].path;

    breadcrumbs.unshift({ path: breadcrumbsPath, menuText: customData[tempPath].menuText });
  }

  breadcrumbs.unshift(homelink);

  return { breadcrumbs, breadcrumbPage };
}
