/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateSessionAction(state, payload) {
  return {
    ...state,
    session: {
      ...state.session,
      ...payload,
    },
  };
}
