import styled, { css } from 'styled-components';

export const SortDropdownWrapper = styled.div`
  position: relative;
  max-width: 50%;
  min-width: 10.5rem;
  margin-right: 0.5rem;
  @media screen and (min-width: 641px) {
    flex: 0 0 25%;
    max-width: 100%;
    margin-right: 2rem;
  }

  label {
    ${(props) =>
      props.page === 'loadable' &&
      css`
        padding-left: 0;
      `};
  }
`;
