import styled from 'styled-components';

export const ErrorContentsWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.whiteColor};
  padding-top: 7.5rem;
  padding-bottom: 12rem;
  @media screen and (max-width: 640px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

export const ErrorPageIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ErrorPageHeading = styled.h1`
  color: ${(props) => props.theme.whiteColor};
  font-size: ${(props) => props.theme.headingMedium};
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingSmall};
  }
  margin-bottom: 1rem;
  text-align: center;
`;

export const ErrorPageDescription = styled.p`
  color: ${(props) => props.theme.whiteColor};
  font-size: ${(props) => props.theme.fontBody1};
  line-height: ${(props) => props.theme.lineHeight3};
  text-align: center;
  margin-bottom: 2rem;
  max-width: 42rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
`;

export const ErrorCTA = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 641px) {
    flex-direction: row;

    .error-page-btn {
      margin-bottom: 0;
    }
  }

  .error-faq-btn {
    margin-top: 0;
    margin-bottom: 1rem;

    @media screen and (min-width: 641px) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  .error-home-btn {
    padding: 1rem 2rem;
    @media screen and (min-width: 641px) {
      margin-left: 1rem;
    }
  }
`;
