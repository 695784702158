import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import DigitalCardSection from './DigitalCardSection';
import PhysicalCardSection from './PhysicalCardSection';
import Title from '../../common/Headings/Title';
import Loader from '../../common/Loader/Loader';
import MyCard from '../../common/Cards/MyCard';

// Styles

import { MyCardSection, Divider, Disclaimer } from './RequestCardStyles';

export default function SendMyCardContainer({ customData }) {
  const { title, requestCardTitle, disclaimer, disableCard, disableDigital, disablePhysical } = customData.MyCardForm;
  const {
    state: {
      transactions: { balance },
      user: { customer, barcodeImageBase64 },
    },
  } = useStateMachine();
  const customerData = {
    balance,
    customer,
    barcodeImageBase64,
  };

  if (customData && customerData?.customer?.data) {
    return (
      <>
        {disableCard !== 'true' && (
          <MyCardSection>
            {title && <Title customStyles="myCardPage">{title}</Title>}
            <MyCard customData={customData} customerData={customerData} />
          </MyCardSection>
        )}
        {(disableDigital !== 'true' || disablePhysical !== 'true') && (
          <MyCardSection>
            {requestCardTitle && <Title customStyles="myCardPage">{requestCardTitle}</Title>}

            <div className="bg-white my-6">
              {disableDigital !== 'true' && <DigitalCardSection customerData={customerData} customData={customData} />}
              {disableDigital !== 'true' && disablePhysical !== 'true' && (
                <Divider>
                  <hr />
                </Divider>
              )}
              {disablePhysical !== 'true' && <PhysicalCardSection customerData={customerData} customData={customData} />}
            </div>
          </MyCardSection>
        )}

        <Disclaimer dangerouslySetInnerHTML={renderHTML(disclaimer)} customStyles="myCardForm" />
      </>
    );
  }

  return <Loader />;
}

SendMyCardContainer.propTypes = { customData: PropTypes.object };
