import styled from 'styled-components';

export const Section = styled.section`
  max-width: 36rem;
  min-height: 100vh;
  height: 100vh;

  @media screen and (max-width: 640px) {
    background-color: ${(props) => props.theme.uiBlack};
  }
  margin: auto;
  margin-top: -${(props) => props.theme.headerMobile};
  @media screen and (min-width: 1440px) {
    margin-top: -${(props) => props.theme.headerDesktop};
    max-width: 40rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  h1 {
    line-height: ${(props) => props.theme.lineHeight3};
    font-size: ${(props) => props.theme.headingMedium};
    color: ${(props) => props.theme.uiWhite};
    margin-bottom: 1.5rem;
    @media screen and (max-width: 640px) {
      margin-bottom: 1.125rem;
    }
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      margin-bottom: 1.25rem;
    }
  }
  p {
    color: ${(props) => props.theme.uiWhite};
    font-size: ${(props) => props.theme.fontBody1};
  }
`;
