import styled from 'styled-components';

export const AddressSwitcherItem = styled.div`
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    font-size: ${(props) => props.theme.fontBody3} !important;
    margin-bottom: 0;
  }
`;
