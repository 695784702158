import PropTypes from 'prop-types';

import Link from 'next/link';
import Image from 'next/image';
import ExternalImage from '../../../Images/ExternalImage';
import SignInHeaderLink from '../../../../signin/SignInHeaderLink';
import ButtonIcon from '../../../Button/ButtonIcon';
import Loader from '../../../Loader/Loader';

import { Divider, Icon } from '../HeaderStyles';

import { handleGAEvents } from '../../../../../helpers/handleGoogleAnalyticsHelper';

export default function HeaderAccount({
  accountIcon,
  accountLabel,
  accountLinks,
  loadingLabel,
  signOutLabel,
  signInIcon,
  signInLabel,
  openAccount,
  setOpenAccount,
  setOpenLanguage,
  data,
  languageCode,
}) {
  const handleOpenAccount = (menuItem) => {
    handleGAEvents(menuItem, 'Account', 'Account Nav Item Click', 'account_menu_click');
    setOpenAccount(!openAccount);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        setOpenAccount(false);
      } else {
        setOpenAccount(true);
      }
    }
  };

  return (
    <>
      <ButtonIcon
        className="header-link"
        onClick={() => {
          setOpenLanguage(false);
          setOpenAccount(!openAccount);
        }}
        onKeyDown={handleKeyDown}
        type="button"
        ariaHaspopup="true"
        ariaControls="menuAccount"
        ariaLabel={accountLabel}
        ariaExpanded={openAccount}
      >
        {data && data.firstName ? data.firstName : <Loader width="auto" reverse text={loadingLabel} />}
        {accountIcon?.url && (
          <Icon className="mx-2">
            <ExternalImage url={accountIcon?.url} width={18} height={18} />
          </Icon>
        )}

        <Icon>
          <Image
            src={`${openAccount ? '/assets/chevron-up-white.svg' : '/assets/chevron-down-white.svg'}`}
            alt=""
            role="presentation"
            height={15}
            width={15}
            aria-hidden
          />
        </Icon>
      </ButtonIcon>

      {openAccount && (
        <div
          id="menuAccount"
          className="flyout-menu flyout-menu-account right-0"
          aria-hidden={!openAccount}
          aria-expanded={openAccount}
        >
          {accountLinks.map((navitem, index) => (
            <Link
              href={navitem.fields.page.href}
              key={index}
              as={navitem.fields.page.href}
              role="link"
              tabIndex="0"
              onClick={() => {
                handleOpenAccount(navitem.fields.title);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleOpenAccount(navitem.fields.title);
                }
              }}
            >
              {navitem.fields.title}
            </Link>
          ))}
          <Divider role="separator" />

          <SignInHeaderLink
            languageCode={languageCode}
            signOutLabel={signOutLabel}
            signInIcon={signInIcon.url}
            signInLabel={signInLabel}
            loadingLabel={loadingLabel}
            setOpenAccount={setOpenAccount}
          />
        </div>
      )}
    </>
  );
}
HeaderAccount.propTypes = {
  accountIcon: PropTypes.object,
  accountLabel: PropTypes.string,
  accountLinks: PropTypes.array,
  loadingLabel: PropTypes.string,
  signOutLabel: PropTypes.string,
  signInIcon: PropTypes.object,
  signInLabel: PropTypes.string,
  openAccount: PropTypes.bool,
  setOpenAccount: PropTypes.func,
  setOpenLanguage: PropTypes.func,
  data: PropTypes.object,
  languageCode: PropTypes.string,
};
