import styled from 'styled-components';

export const StyledBanner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
`;

export const TwoColumnTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
`;
export const TwoColumnText = styled.p`
  color: ${(props) => props.theme.lightGrayColor4};
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight3};
  margin-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    font-size: ${(props) => props.theme.fontBody1};
    margin-bottom: 2.5rem;
  }
`;

export const BannerImage = styled.figure`
  position: relative;
  width: 50%;
  @media screen and (max-width: 640px) {
    display: none;
  }
  &.app-image {
    display: none;
    @media screen and (max-width: 640px) {
      display: block;
      width: 100%;
      min-height: 14rem;
      max-width: 24rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`;
