import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import EarnRedeem from '../common/Sections/EarnRedeem';

const SharedEarnRewardsSection = ({ module }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return <EarnRedeem fields={fields} />;
  }

  return null;
};

SharedEarnRewardsSection.propTypes = {
  module: PropTypes.object.isRequired,
};

export default SharedEarnRewardsSection;
