import styled from 'styled-components';

import { formatDynamicLabel } from '../../../helpers/stringHelpers';

const StepBlock = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 1.5rem;
  flex-direction: column;
  p {
    color: ${(props) => props.theme.stepLabel};
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    _padding: 0.5rem 1rem;
    margin: 0 auto;
  }
`;

const StepIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 13.5rem;
  height: 1.5rem;
  margin: 0.25rem auto;
  .step-wrapper {
    width: 2.5rem;
    height: 1.5rem;
    padding: 0.5rem;
  }
  .step {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 auto;
    display: flex;
    background: ${(props) => props.theme.uiGrey03};
    border-radius: 0.5rem;

    &.current-step {
      width: 1.5rem;
      height: 0.5rem;

      background: ${(props) => props.theme.uiPurple};
      border-radius: 0.5rem;
    }
  }
`;
export default function Step({ stepLabel, currentStep, totalSteps }) {
  let steps = null;
  if (currentStep && totalSteps) {
    steps = new Array(totalSteps);
    for (let i = 0; i < totalSteps; i++) {
      steps[i] = i === currentStep - 1 ? 'step current-step' : 'step';
    }
  }

  return (
    <StepBlock>
      <p>{formatDynamicLabel(formatDynamicLabel(stepLabel, '##STEP##', currentStep), '##TOTALSTEPS##', totalSteps)}</p>

      <StepIndicator>
        {steps &&
          steps.map((item, index) => (
            <div key={index} className="step-wrapper">
              <span className={item} />
            </div>
          ))}
      </StepIndicator>
    </StepBlock>
  );
}
