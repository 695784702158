import styled from 'styled-components';

export const DetailsHeader = styled.div`
  margin: 0 auto 1rem;

  @media screen and (min-width: 768px) {
    margin: 0 auto 2rem;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto 2.5rem;
  }
`;
export const SectionTitle = styled.h2`
  color: ${(props) => props.theme.uiCharcoal};
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontLegal};
  margin-top: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.fontBody3};
    padding: 0;
  }
`;
export const DetailsTitle = styled.h2`
  font-size: ${(props) => props.theme.fontBody2};
  margin: 0;
`;
export const InnerDetails = styled.section`
  padding: 1rem;
  background-color: ${(props) => props.theme.whiteColor};
  margin-bottom: 2px;

  .order-name {
    margin-bottom: 0.5rem;
    padding-right: 15%;
  }

  .order-id {
    color: ${(props) => props.theme.uiGrey04};
  }

  .shipping-add {
    margin-bottom: 0.25rem;
  }
`;
export const DetailsWrapper = styled.div`
  margin-bottom: 4.625rem;
`;
export const DetailsSection = styled.section`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 1rem 1.125rem;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 641px) {
    padding: 1rem 1.5rem;
  }

  h4 {
    font-weight: bold;
  }

  p {
    margin: 0;
  }
`;
