import PropTypes from 'prop-types';
import Link from 'next/link';

// State
import { useStateMachine } from 'little-state-machine';
// Components
import WrapperLayout from '../layouts/WrapperLayout';
import PartnersList from '../common/List/PartnersList';

const OurPartners = ({ module, customData, languageCode }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <section id="StellarPartnersSection">
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          {fields?.title && <h2 className="headline-medium block mb-3 md:mb-6 text-center">{fields.title}</h2>}
          {fields?.message && <p className="sub-title text-center">{fields.message}</p>}
          <PartnersList list={customData?.partners} fields={fields} />
          {fields?.link && fields?.link?.href && fields?.linkLabel && (
            <Link
              href={fields?.link?.href}
              as={fields?.link?.href}
              locale={languageCode}
              target={fields?.link?.target || '_self'}
              className="text-button-primary"
            >
              {fields?.linkLabel}
            </Link>
          )}
        </WrapperLayout>
      </section>
    );
  }
  return null;
};

OurPartners.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const brands = await api.getContentList({
      referenceName: 'brands',
      languageCode,
    });

    const partnerstable = await api.getContentList({
      referenceName: 'partnerstablev2',
      languageCode,
      // depth: 2,
    });
    partnerstable.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    const partners = [];
    for (const item of partnerstable) {
      if (item.fields?.logos_ValueField) {
        const selectedLogos = item.fields?.logos_ValueField.includes(',')
          ? item.fields?.logos_ValueField.split(',')
          : [item.fields?.logos_ValueField];
        const logos = [];
        for (const logo of selectedLogos) {
          const brand = brands.filter((b) => b.contentID.toString() === logo);

          logos.push(brand[0]);
        }

        partners.push({
          earn: item.fields.earn || null,
          redeem: item.fields.redeem || null,
          logos,
          disclaimer: item.fields.disclaimer || null,
          anchorName: item.fields.anchorName || null,
        });
      }
    }

    return {
      partners,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

OurPartners.propTypes = { module: PropTypes.object, customData: PropTypes.object, languageCode: PropTypes.string };
export default OurPartners;
