import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import FocusTrap from 'focus-trap-react';

import { b2cChangePhone } from '../../../../b2c/b2cFunctions';

// Utils
import { convertToSlug } from '../../../../helpers/utils';

// Components
import Loader from '../../../common/Loader/Loader';
import EditButton from '../../EditButton';
import EditMSALButtons from './EditMSALButtons';

// Styles
import { Row, Title, FieldContent, DescriptionText } from '../../FormStyles';

import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProfilePhone(props) {
  const { fieldName, cancelLabel, phoneMessage, changePhoneNumberLabel, loading, languageCode, customerData } = props;

  const [isEditing, setEditing] = useState(false);
  const { instance } = useMsal();
  /* const currentAccounts = instance.getAllAccounts();
  const profilePhone = currentAccounts[0]?.idTokenClaims.extension_SCENE_PhoneNumber; */

  const handleEditClick = () => {
    if (!isEditing)
      handleGAEvents('Profile - Change Phone Number', 'Account', 'Change Phone Number Click', 'profile_phone_number_click');
    setEditing(!isEditing);
  };

  const handleB2Ccall = () => {
    b2cChangePhone(instance, languageCode);
  };

  return (
    <Row className="mb-0.5 relative">
      <FocusTrap
        active={isEditing}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none',
          },
          initialFocus: false,
          allowOutsideClick: true,
        }}
      >
        <div className="relative p-0">
          <div className="md:flex justify-between items-baseline content-center relative">
            <div className="w-52 ProfileFieldWrapper">
              <Title className="mb-1 md:mb-0 font-bold mr-4 flex-initial">{fieldName}</Title>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                  <FieldContent className="md:p-1">{customerData && <p className="mb-0">{customerData}</p>}</FieldContent>
                  {isEditing && phoneMessage && <DescriptionText className="md:px-2 mb-4">{phoneMessage}</DescriptionText>}

                  {isEditing && (
                    <EditMSALButtons
                      handleB2Ccall={handleB2Ccall}
                      buttonText={changePhoneNumberLabel}
                      cancelLabel={cancelLabel}
                      isEditing={isEditing}
                      updateEditClick={handleEditClick}
                    />
                  )}
                </div>

                <EditButton
                  {...props}
                  handleEditClick={handleEditClick}
                  isEditing={isEditing}
                  ariaControls={`profile-${convertToSlug(fieldName)}`}
                />
              </>
            )}
          </div>
        </div>
      </FocusTrap>
    </Row>
  );
}
