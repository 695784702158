export const generateBrandListFrom = (fields) => [
  {
    heading: fields?.logoSet1Label || null,
    logos: fields?.logoSet1Logos || null,
  },
  {
    heading: fields?.logoSet2Label || null,
    logos: fields?.logoSet2Logos || null,
  },
  {
    heading: fields?.logoSet3Label || null,
    logos: fields?.logoSet3Logos || null,
  },
  {
    heading: fields?.logoSet4Label || null,
    logos: fields?.logoSet4Logos || null,
  },
];
