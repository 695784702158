/* eslint-disable no-shadow */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-ca';

import { useStateMachine } from 'little-state-machine';
import { updateTransactionsAwards } from '../../../../../store/actions';

// Components
import Loader from '../../../../common/Loader/Loader';
import Table from '../../../../common/Table/Table';
import NoTransactions from '../../Transactions/NoTransactions';

// V2
import PointsListRow from './PointsListRow';
import MobilePointsListRow from './MobilePointsListRow';

// Hooks
import useAward from '../../../../hooks/points/shop/useAward';
import useBYOT from '../../../../hooks/points/shop/useBYOT';

// Helpers
import { hasAvailableAwards } from '../../../../../helpers/pointsHelpers';
import { todayDate, formatDate } from '../../../../../helpers/dateHelpers';
import { checkArrayForItems } from '../../../../../helpers/utils';

// Styles
import { GroupedTransaction } from '../../PointsShopStyles';

/**
 *
 * We fetcht the BYOT and PFC transactions fore both
 * set loader based on the page viewed
 *
 * TODO:: decouple cart and setCart from props, use global state
 *
 * @param {*} param0
 * @returns  {a list of check box fields based on page and viewport}
 */

export default function TransactionsBYOT({ customData = {}, languageCode = '' }) {
  const { redeemLabel, dateLabel, transactionLabel, amountLabel, pointsLabel } = customData || {};

  const {
    actions,
    state: {
      transactions,
      session: { accessToken },
    },
  } = useStateMachine({ updateTransactionsAwards });

  const tableHeader = [redeemLabel, dateLabel, transactionLabel, amountLabel, pointsLabel];
  const tableMobileHeader = [redeemLabel, transactionLabel, pointsLabel];

  const { data: byotDataAward } = useAward(transactions?.selectedCard?.accountSegmentCode, 'BYOT');

  const {
    data: byotAward,
    isLoading,
    error,
  } = useBYOT(
    accessToken,
    transactions?.selectedCard?.id,
    transactions?.selectedPeriod?.firstDate || '2021-01-01',
    transactions?.selectedPeriod?.secondDate || todayDate
  );

  useEffect(() => {
    if (!isLoading) {
      actions.updateTransactionsAwards({
        byot: byotAward?.transactions,
        awardProduct: byotDataAward,
      });
    }
  }, [isLoading, transactions?.selectedCard, transactions?.selectedPeriod, byotAward?.transactions]);

  if (isLoading) return <Loader />;

  return (
    <>
      {/* TABLET AND DESKTOP */}
      <div className="hidden sm:block byot-table-container">
        <Table columns={tableHeader}>
          {transactions?.cards &&
            checkArrayForItems(transactions?.cards) &&
            transactions?.cards.map((card, index) => (
              <div
                key={index}
                style={{
                  display: card.cardTitle !== transactions?.selectedCard.cardTitle ? 'none' : '',
                }}
              >
                {hasAvailableAwards(transactions?.awards?.byot) ? (
                  transactions?.awards?.byot &&
                  transactions?.awards?.byot.map((transaction) => (
                    <PointsListRow
                      key={transaction.id}
                      fieldId={`redeem-${transaction.id}`}
                      fieldType="checkbox"
                      name={`redeem-${transaction.id}`}
                      transaction={transaction} // TODO: remove this and pull from global
                      customData={customData}
                      languageCode={languageCode}
                      ignoreError
                    />
                  ))
                ) : (
                  <NoTransactions customData={customData} />
                )}
              </div>
            ))}
        </Table>
      </div>

      {/* MOBILE POINTS TABLE  */}
      <div className="block sm:hidden byot-table-container">
        <Table columns={tableMobileHeader}>
          {transactions?.cards &&
            checkArrayForItems(transactions?.cards) &&
            transactions?.cards.map((card, index) => (
              <div
                key={index}
                style={{
                  display: card?.cardTitle !== transactions?.selectedCard?.cardTitle ? 'none' : '',
                }}
              >
                {hasAvailableAwards(transactions?.awards?.byot) ? (
                  [
                    ...new Map(
                      transactions?.awards?.byot &&
                        transactions?.awards?.byot.map((o) => [moment(o.pointDate).format('MMM, DD'), o])
                    ).values(),
                  ].map((it, index) => (
                    <GroupedTransaction key={index}>
                      <p className="my-2 mobile-transaction-date px-4 sm:px-0">
                        {formatDate(it.pointDate, 'MMM. DD, YYYY', languageCode)}
                      </p>

                      {transactions?.awards?.byot
                        .filter(
                          (filterDate) =>
                            moment(filterDate.pointDate).format('MMM, DD') === moment(it.pointDate).format('MMM, DD')
                        )
                        .map((transaction, index) => (
                          <MobilePointsListRow
                            key={index}
                            fieldId={`redeem-${transaction.id}`}
                            fieldType="checkbox"
                            name={`redeem-${transaction.id}`}
                            transaction={transaction} // TODO: remove this and pull from global
                            customData={customData}
                            languageCode={languageCode}
                            ignoreError
                          />
                        ))}
                    </GroupedTransaction>
                  ))
                ) : (
                  <NoTransactions customData={customData} />
                )}
              </div>
            ))}
        </Table>
      </div>
    </>
  );
}

TransactionsBYOT.propTypes = { customData: PropTypes.object, languageCode: PropTypes.string };
