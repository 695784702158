import PropTypes from 'prop-types';
import Image from 'next/image';

import LocaleSwitcher from '../../../LocaleSwitcher';
import ButtonIcon from '../../../Button/ButtonIcon';

import { HeaderColumn, Icon, Section } from '../HeaderStyles';

export default function HeaderLanguage({
  englishAbbreviatedLabel,
  frenchAbbreviatedLabel,
  englishLabel,
  frenchLabel,
  languageCode,
  refLanguage,
  setOpenAccount,
  setOpenLanguage,
  openLanguage,
  ariaLabelSelectedLanguage,
}) {
  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        setOpenLanguage(false);
      } else {
        setOpenLanguage(true);
      }
    }
  };
  return (
    <HeaderColumn className="tabletHide flex-grow-0 language-margin">
      <Section ref={refLanguage}>
        <ButtonIcon
          className="header-link-bold"
          onClick={() => {
            setOpenAccount(false);
            setOpenLanguage(!openLanguage);
          }}
          onKeyDown={handleKeyDown}
          type="button"
          ariaHaspopup="true"
          ariaControls="menuLanguage"
          ariaExpanded={openLanguage}
          ariaLabel={ariaLabelSelectedLanguage}
          id="language-picker-description"
        >
          {languageCode === 'en-ca' ? englishAbbreviatedLabel : frenchAbbreviatedLabel}{' '}
          <Icon className="ml-2">
            <Image
              src={`${openLanguage ? '/assets/chevron-up-white.svg' : '/assets/chevron-down-white.svg'}`}
              alt=""
              role="presentation"
              height={15}
              width={15}
              aria-hidden
            />
          </Icon>
        </ButtonIcon>

        {openLanguage && (
          // eslint-disable-next-line jsx-a11y/role-supports-aria-props
          <div
            id="menuLanguage"
            className="flyout-menu flyout-menu-lang right-0"
            role="radiogroup"
            aria-describedby="language-picker-description"
            aria-hidden={!openLanguage}
            aria-expanded={openLanguage}
          >
            <LocaleSwitcher
              locale={languageCode}
              setOpenLanguage={setOpenLanguage}
              englishAbbreviatedLabel={englishAbbreviatedLabel}
              frenchAbbreviatedLabel={frenchAbbreviatedLabel}
              englishLabel={englishLabel}
              frenchLabel={frenchLabel}
            />
          </div>
        )}
      </Section>
    </HeaderColumn>
  );
}

HeaderLanguage.propTypes = {
  englishAbbreviatedLabel: PropTypes.string,
  frenchAbbreviatedLabel: PropTypes.string,
  englishLabel: PropTypes.string,
  frenchLabel: PropTypes.string,
  languageCode: PropTypes.string,
  refLanguage: PropTypes.object,
  setOpenAccount: PropTypes.func,
  setOpenLanguage: PropTypes.func,
  openLanguage: PropTypes.bool,
  ariaLabelSelectedLanguage: PropTypes.string,
};
