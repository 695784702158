import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Components
import { Section, CardCarouselWrapper } from './BannerStyles';
import OfferCard from '../Cards/OfferCard';

// Helpers
import { cardSliderSettings } from '../../../helpers/bannersHelper';

const OffersSlider = ({ list: offersList, toggle, customData, listName }) => {
  const offersNum = offersList && offersList.length;
  const settings = cardSliderSettings(offersNum);

  return (
    <Section>
      <CardCarouselWrapper offersNum={offersNum}>
        <Slider {...settings}>
          {offersNum &&
            offersList.length &&
            offersList.map((item, index) => (
              <OfferCard
                id={`card-${index}`}
                code={item.offerCode}
                currentItemId={item.offerId}
                key={`${listName.replace(/\s/g, '')}-${index}`}
                content={item}
                offersNum={offersNum}
                offer={item}
                toggle={toggle}
                customData={customData}
                list={offersNum}
                listName={listName}
              />
            ))}
        </Slider>
      </CardCarouselWrapper>
    </Section>
  );
};

export default OffersSlider;

OffersSlider.propTypes = {
  list: PropTypes.array,
  toggle: PropTypes.func,
  customData: PropTypes.object,
  listName: PropTypes.string,
};
