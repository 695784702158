/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const WrapperLayoutContainer = styled.div`
  ${(props) =>
    props.customStyles === 'topOfPage' &&
    css`
      @media screen and (max-width: 640px) {
        padding-top: 3rem;
        .global-content-wrapper {
          padding: 0;
        }
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2rem;
      }

      @media screen and (min-width: 1024px) {
        padding-top: 5rem;
      }
    `}

  ${(props) =>
    props.customStyles === 'defaultPadding' &&
    css`
      padding-top: 2rem;
      padding-bottom: 2rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }

      @media screen and (min-width: 1024px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    `}

    ${(props) =>
    props.customStyles === 'gridSection' &&
    css`
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }

      @media screen and (min-width: 1024px) {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
      }
    `}

    ${(props) =>
    props.customStyles === 'faqSection' &&
    css`
      padding-top: 3rem;
      padding-bottom: 3rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }

      @media screen and (min-width: 1024px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }
    `}
   ${(props) =>
    props.customStyles === 'pointsHeader' &&
    css`
      padding-top: 1.25rem;
      padding-bottom: 2rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }

      @media screen and (min-width: 1024px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    `}

    ${(props) =>
    props.customStyles === 'pointsHistory' &&
    css`
      padding-top: 2.5rem;
      padding-bottom: 5rem;
      @media screen and (max-width: 1023px) {
        padding-top: 1.5rem;
        padding-bottom: 3.5rem;
      }
    `}

    ${(props) =>
    props.customStyles === 'registerPage' &&
    css`
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      @media screen and (max-width: 1023px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'giftcardsSlider' &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 4rem;
      @media screen and (max-width: 1023px) {
        padding-top: 2rem;
        padding-bottom: 2.5rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'featureBannerWithTitle' &&
    css`
      padding-bottom: 4rem;
      @media screen and (max-width: 1023px) {
        padding-bottom: 2.5rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'giftcardsGrid' &&
    css`
      padding-top: 4rem;
      padding-bottom: 4rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 3.25rem;
        padding-bottom: 2.5rem;
      }

      @media screen and (max-width: 640px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }
    `}
  ${(props) =>
    props.customStyles === 'offersPage' &&
    css`
      padding-top: 3.5rem;
      padding-bottom: 4rem;
      @media screen and (max-width: 1023px) {
        padding-top: 2rem;
        padding-bottom: 2.5rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'brandStores' &&
    css`
      padding-top: 3.5rem;
      padding-bottom: 4rem;
      @media screen and (max-width: 1023px) {
        padding-top: 2rem;
        padding-bottom: 2.5rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'spotlights' &&
    css`
      padding-top: 2rem;
      padding-bottom: 2.5rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2.5rem;
        padding-bottom: 3.5rem;
      }
      @media screen and (min-width: 1024px) {
        padding-top: 3.5rem;
        padding-bottom: 3.75rem;
      }
    `}
  ${(props) =>
    props.customStyles === 'offersGeneralOffers' &&
    css`
      padding-top: 4.5rem;
      padding-bottom: 4rem;
      @media screen and (max-width: 990px) {
        padding-top: 1.5rem;
        padding-bottom: 2.5rem;
      }
    `}
    ${(props) =>
    props.customStyles === 'offersStaticBannerSection' &&
    css`
      padding: 2.25rem 0;
      @media screen and (max-width: 990px) {
        padding: 1.625rem 0 1.875rem 0;
      }
    `}     
    ${(props) =>
    props.customStyles === 'offersGeneralWithStaticBanner' &&
    css`
      padding-top: 0;
      padding-bottom: 4rem;
      @media screen and (max-width: 990px) {
        padding-bottom: 2.5rem;
      }
    `} 
    ${(props) =>
    props.customStyles === 'offersGeneralMoreOffers' &&
    css`
      padding-top: 4rem;
      padding-bottom: 6rem;
      @media screen and (max-width: 990px) {
        padding-top: 2rem;
        padding-bottom: 3.5rem;
      }
    `}

  ${(props) =>
    props.customStyles === 'offersHomePage' &&
    css`
      padding-top: 6.875rem;
      padding-bottom: 5rem;

      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2.5rem;
        padding-bottom: 3.5rem;
      }
      @media screen and (max-width: 640px) {
        padding-top: 2rem;
        padding-bottom: 2.5rem;
      }
    `}
 ${(props) =>
    props.customStyles === 'redeemCategories' &&
    css`
      padding-top: 2.75rem;
      padding-bottom: 5rem;

      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 1.5rem;
        padding-bottom: 2.5rem;
      }
      @media screen and (max-width: 640px) {
        padding-top: 0.5rem;
        padding-bottom: 2rem;

        padding-left: 0.25rem;
        padding-right: 0.25rem;

        @media screen and (max-width: 640px) {
          .global-content-wrapper {
            padding: 0;
          }
        }
      }
    `}

${(props) =>
    props.customStyles === 'trendingRewards' &&
    css`
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media screen and (min-width: 1024px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    `}

  ${(props) =>
    props.fullMinHeight &&
    css`
      min-height: calc(100vh - 120px);
    `}

  ${(props) =>
    props.mobileNoPadding &&
    css`
      @media screen and (max-width: 640px) {
        .global-content-wrapper {
          padding: 0;
        }
      }
    `}

  ${(props) =>
    props.isGray &&
    css`
      background-color: ${(props) => props.theme.uiGrey01};
    `}

  ${(props) =>
    props.isBlack &&
    css`
      background-color: ${(props) => props.theme.uiBlack};
      color: ${(props) => props.theme.uiWhite};
    `}

  ${(props) =>
    props.isLightBlack &&
    css`
      background-color: ${(props) => props.theme.uiCharcoal};
    `}

  ${(props) =>
    props.isWhite &&
    css`
      background-color: ${(props) => props.theme.uiGrey01};

      @media screen and (min-width: 641px) {
        background-color: ${(props) => props.theme.uiWhite};
      }
    `}

  ${(props) =>
    props.mobileIsWhite &&
    css`
      @media screen and (max-width: 640px) {
        background-color: ${(props) => props.theme.uiWhite} !important;
      }
    `}
`;
