import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import RewardsSlider from '../common/Banners/RewardsSlider';
import TitleWithLogo from '../common/Headings/TitleWithLogo';
import L3HeaderBanner from '../common/Banners/L3HeaderBanner';

const BrandRewards = ({ dynamicPageItem, module, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const { title, logoSolidBG, code, bannerWeb } = dynamicPageItem.fields;

  if (isLoggedIn) {
    return (
      <>
        {bannerWeb?.url && <L3HeaderBanner bgImage={bannerWeb?.url} />}
        <WrapperLayout className="global-content-wrapper md:mb-36 mb-14">
          <TitleWithLogo logo={logoSolidBG?.url}>{title}</TitleWithLogo>

          <RewardsSlider
            category={module.fields.category.fields.code}
            brand={code}
            languageCode={languageCode}
            sectionTitle={module.fields.howToSpendLabel}
            openDetailsModalLabel={module.fields.openDetailsModalLabel}
            isGrid
          />
        </WrapperLayout>
      </>
    );
  }
  return null;
};

BrandRewards.propTypes = { module: PropTypes.object, dynamicPageItem: PropTypes.object, languageCode: PropTypes.string };

export default BrandRewards;
