import PropTypes from 'prop-types';

// Components
import CardFilter from '../../../Filter/CardFilter';
import CardDropdown from '../../../Filter/CardDropdown';

// V2
import Summary from '../Summary';
import TransactionList from '../TransactionList';

// Hooks
import useModal from '../../../../hooks/utils/useModal';

// Styles
import { StyledTransactions, StyledAwardsList } from '../../PointsShopStyles';

function ShopPFC({ page, onSubmit, onErrors, formMethods, customData, languageCode }) {
  const { cardLabel, selectCardLabel } = customData;
  const { isShowing, toggle, currentItemId } = useModal();

  return (
    <StyledAwardsList className="pfc-awards-list">
      <form className="relative p-0 lg:w-full" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div>
          <StyledTransactions className="flex-1 mb-4 md:mb-0" page={page}>
            <CardFilter page={page}>
              <div className="flex-1 mb-4 md:mb-0">
                <CardDropdown toggle={toggle} name="card-filter" label={cardLabel || selectCardLabel} />
              </div>
            </CardFilter>

            <TransactionList
              page={page}
              customData={customData}
              isShowing={isShowing}
              toggle={toggle}
              currentItemId={currentItemId}
              languageCode={languageCode}
            />
          </StyledTransactions>

          <Summary page={page} customData={customData} />
        </div>
      </form>
    </StyledAwardsList>
  );
}

ShopPFC.propTypes = {
  page: PropTypes.string,
  onSubmit: PropTypes.func,
  onErrors: PropTypes.func,
  formMethods: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};

export default ShopPFC;
