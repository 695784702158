import styled from 'styled-components';

export const LoadAllOffersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  color: ${(props) => props.theme.whiteColor};
  background-color: ${(props) => props.theme.brandBlack};
  align-items: flex-end;
  @media screen and (max-width: 640px) {
    margin-top: 1.313rem;
    width: 100%;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-top: 0;
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: calc(50% - 0.938rem);
  }
  @media screen and (min-width: 1440px) {
    padding: 0.75rem 2rem;
  }

  > .primary-button {
    padding: 0.5rem 1rem;
    margin-top: 0;
    margin-bottom: 0;
    width: auto !important;
    min-width: 8rem;
    line-height: ${(props) => props.theme.lineHeight3};
    font-size: ${(props) => props.theme.fontLegal};
  }

  p {
    color: ${(props) => props.theme.whiteColor};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
    &.load-all-heading {
      font-size: ${(props) => props.theme.fontBody3};
    }

    &.load-all-subheading {
      font-size: ${(props) => props.theme.fontLegal};
    }
  }
`;
