import PropTypes from 'prop-types';

// Global states
import { useStateMachine } from 'little-state-machine';

// Components
import PointsFilterList from './PointsFilterList';
import PointsDateFilter from './PointsDateFilter/PointsDateFilter';

export default function PointsHistoryFilter({ applyFilter, resetFilter, customData, languageCode }) {
  const { typesList, cardsList, categoriesList, transactionCategoryLabel, transactionTypeLabel, cardTypeLabel } = customData;
  const {
    state: {
      points: { cardType, category, type },
    },
  } = useStateMachine();

  return (
    <div>
      <PointsFilterList
        selectedFilter={type}
        applyFilter={applyFilter}
        list={typesList}
        title={transactionTypeLabel}
        referenceCode="type"
      />
      <PointsFilterList
        selectedFilter={category}
        applyFilter={applyFilter}
        list={categoriesList}
        title={transactionCategoryLabel}
        referenceCode="category"
      />
      <PointsFilterList
        selectedFilter={cardType}
        applyFilter={applyFilter}
        list={cardsList}
        title={cardTypeLabel}
        referenceCode="cardType"
      />
      <PointsDateFilter
        selectedFilter={type}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        customData={customData}
        languageCode={languageCode}
      />
    </div>
  );
}

PointsHistoryFilter.propTypes = {
  customData: PropTypes.object.isRequired,
  applyFilter: PropTypes.bool,
  resetFilter: PropTypes.bool,
  languageCode: PropTypes.string,
};
