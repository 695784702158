/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import MultipleFieldsLayout from '../../layouts/MultipleFieldsLayout';
import { updateUser } from '../../../store/actions';

import { calculate_age, subtractYears } from '../../../helpers/utils';

import { StyledInput } from '../FormStyles';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

function BirthYearInput({ name, label, defaultVal, fieldType, minLength, placeholder, rules, errorsList, gaFlow }) {
  const { actions } = useStateMachine({ updateUser });
  // eslint-disable-next-line no-unused-vars
  const [inputVal, setInputVal] = useState('');

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, inputVal || defaultVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];

  const currentYear = new Date().getFullYear();
  const dobMonth = getValues('dobMonth') && (0 + getValues('dobMonth')).slice(-2);
  const dobDay = getValues('dobDay') && (0 + getValues('dobDay')).slice(-2);

  return (
    <MultipleFieldsLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <StyledInput
        onFocus={() => {
          if (gaFlow === 'register' || gaFlow === 'registerGK') {
            const dataLayer = {
              event: 'sign_up_form_field',
              category: 'Registration Events',
              action: 'Form Field Click',
              label: name,
              sign_up_step: `Step 2${gaFlow === 'registerGK' ? ' GK' : ''}`,
              sign_up_form_field: name,
            };
            handleGAEventsBase(dataLayer);
          }
        }}
        name={name}
        type={fieldType || 'number'}
        aria-invalid={!!error}
        aria-labelledby={`form-${name}`}
        aria-required
        minLength={minLength}
        maxLength={4}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
        }}
        placeholder={placeholder}
        defaultValue={defaultVal}
        {...register(name, {
          ...rules,
          validate: async (value) => {
            const age = await calculate_age(`${value}/${dobMonth}/${dobDay}`);
            if (age < 14) {
              return errorsList.filter((item) => item.code === 'Customer.BirthDate.MinAge').map((item) => item.message)[0];
            }
          },
          min: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MinYear').map((item) => item.message)[0],
            value: subtractYears(105),
          },
          max: {
            message: errorsList
              .filter((item) => item.code === 'Customer.BirthDate.MaxCurrentYear')
              .map((item) => item.message)[0],
            value: currentYear,
          },
          minLength: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MinYear').map((item) => item.message)[0],
            value: 4,
          },
          maxLength: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MaxYear').map((item) => item.message)[0],
            value: 4,
          },
        })}
        className={`${(error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''}`}
        isError={Boolean(error)}
      />
    </MultipleFieldsLayout>
  );
}

export default BirthYearInput;

BirthYearInput.defaultProps = {
  gaFlow: '',
};

BirthYearInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  errorsList: PropTypes.array,
  gaFlow: PropTypes.string,
};
