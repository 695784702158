import React from 'react';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';

import { InlineMessageBanner, Content, BannerHeader, BannerMessage } from './InlineMessageStyles';
import ButtonIcon from '../Button/ButtonIcon';

export default function InlineMessageContent({ content, handleClick, closeLabel }) {
  if (content?.title && content?.message) {
    return (
      <InlineMessageBanner role="alert" aria-atomic="true" aria-live="polite">
        <div className="global-content-wrapper">
          <div className="flex justify-between items-start">
            <Content>
              <BannerHeader id="inlinemsg-header">{content.title}</BannerHeader>
              <BannerMessage dangerouslySetInnerHTML={renderHTML(content.message)} id="inlinemsg-link" />
            </Content>

            <ButtonIcon
              id="inlinemsg-x"
              className="cursor-pointer n-close"
              onClick={() => handleClick()}
              style={{ border: 0 }}
              ariaLabel={closeLabel}
              role="button"
            >
              <Image src="/assets/close-x.svg" height={20} width={20} title={closeLabel} alt={closeLabel} />
            </ButtonIcon>
          </div>
        </div>
      </InlineMessageBanner>
    );
  }
  return null;
}
