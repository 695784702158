import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import RewardsSlider from '../common/Banners/RewardsSlider';

const CategoryRewards = ({ module, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper md:mb-36 mb-14">
        <RewardsSlider
          category={module.fields.category.fields.code}
          brand="ALL"
          slides={module.fields.maximumSlides}
          languageCode={languageCode}
          sectionTitle={module.fields.title}
          openDetailsModalLabel={module.fields.openDetailsModalLabel}
          isGrid
        />
      </WrapperLayout>
    );
  }
  return null;
};

CategoryRewards.propTypes = { module: PropTypes.object, languageCode: PropTypes.string };

export default CategoryRewards;
