import styled from 'styled-components';

export const SendMyCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  color: ${(props) => props.theme.brandBlack};
  background-color: ${(props) => props.theme.whiteColor};
  margin-top: 1rem;
  margin-bottom: 33rem;
  width: 100%;

  @media screen and (max-width: 520px) {
    padding: 1rem 1rem 0.5rem 1rem;
    margin-top: 0;
    margin-bottom: 3.438rem;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    > .primary-button {
      padding: 1rem 2rem;
      margin: 12px 12px 12px 0px;
    }

    p {
      color: ${(props) => props.theme.brandBlack};
      margin: auto 16px 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.subheading {
        font-size: ${(props) => props.theme.fontLegal};
      }
    }
  }

  @media screen and (min-width: 768px) {
  }

  > .primary-button {
    padding: 1rem 2rem;
    width: auto !important;
  }

  p {
    color: ${(props) => props.theme.brandBlack};
    margin: auto;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.subheading {
      font-size: ${(props) => props.theme.fontLegal};
    }
  }
`;

export const StyleMyCard = styled.div`
  margin-right: 4.875rem;

  @media screen and (max-width: 520px) {
    margin-right: 0rem;
  }
`;

export const PageTitle = styled.h1`
  line-height: ${(props) => props.theme.lineHeight2};
  font-size: ${(props) => props.theme.headingLarge};
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingMedSmall};
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    margin-top: 1rem;
    margin-bottom: 1.375rem;
  }
  @media screen and (min-width: 641px) and (max-width: 990px) {
    font-size: ${(props) => props.theme.headingMedium};
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;
export const Row = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 1.5rem 2.25rem;

  @media screen and (max-width: 640px) {
    padding: 1.5rem 1.125rem;
  }
`;

export const SectionTitle = styled.h2`
  line-height: ${(props) => props.theme.lineHeight2};
  font-size: ${(props) => props.theme.headingExtraSmall};
  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.headingSmall};
  }
  margin-bottom: 1.5rem;
`;

export const BottomMessage = styled.div`
  p {
    line-height: ${(props) => props.theme.lineHeight3};
    font-size: ${(props) => props.theme.fontBody1};
  }

  ul li {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
  }
`;
