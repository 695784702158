/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs/utils';

import { TermsContentStyles } from './TermsContentStyles';

export default function TermsContent({ termsLabel, termsBlob }) {
  return (
    <TermsContentStyles>
      {termsLabel && <h4>{termsLabel}</h4>}
      <div className="terms-content-inner" tabIndex="0">
        <div dangerouslySetInnerHTML={renderHTML(termsBlob)} />
      </div>
    </TermsContentStyles>
  );
}

TermsContent.propTypes = {
  termsLabel: PropTypes.string,
  termsBlob: PropTypes.string.isRequired,
};
