import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../store/actions';
import updateStepAction from '../../store/actions/updateStepAction';

import { newCustomer } from '../../pages/api/services';
import { generateErrorMessage } from '../../helpers/errorsHelper';

import FormHeader from '../forms/registration/FormHeader';
import FormFooter from '../forms/registration/FormFooter';

// Hooks
import useRegisterGAFormEvents from '../hooks/services/useRegisterGAFormEvents';
import useReCaptcha from '../hooks/services/useReCaptcha';

// Helpers
import { handleGAEvents, handleGaSignupPageError, sendRegisterOptIn } from '../../helpers/handleGoogleAnalyticsHelper';

export default function RegisterStep2FormLayout({ children, ...props }) {
  const {
    title,
    step2Label,
    backButtonLabel,
    nameHeader,
    finishLabel,
    finishLabelLoading,
    signInLabel,
    languageCode,
    errorsList,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const { state, actions } = useStateMachine({
    updateUser,
    updateStepAction,
    updateNotification,
  });

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  useRegisterGAFormEvents(formMethods.formState?.touchedFields);

  const { getCaptchaToken } = useReCaptcha('register_user_step_2');

  const onSubmit = async (data) => {
    let result;
    setSubmitting(true);

    if (process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
      result = await getCaptchaToken();
    }

    const reqBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
      gender: data.gender,
      culture: languageCode,
      SMSOptIn: data.sms,
      addresses: [
        {
          customerAddressId: 0,
          countryCode: 'CA',
          provinceCode: data.provinceCode,
          city: data.city,
          postalCode: data.postalCode,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          firstName: data.firstName,
          lastName: data.lastName,
          primaryPhoneNumber: data.primaryPhoneNumber.replace(/-/g, ''),
        },
      ],
      contacts: [
        {
          email: state.user.email,
          primaryPhoneNumber: data.primaryPhoneNumber.replace(/-/g, ''),
        },
      ],
      password: state.user.password,
      needLoyaltyCard: data.needLoyaltyCard,
    };

    actions.updateUser(reqBody);

    newCustomer(reqBody, { token: result?.token, ip: result?.ip, session: result?.session })
      .then(async (res) => {
        if (res.data) {
          actions.updateStepAction({ step: 3 });
          formMethods.reset();
          handleGAEvents('Finish', 'Registration Events', 'Step 2', 'sign_up_button_finish');
          sendRegisterOptIn(data);
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          let statusType = 'error';
          const validationError =
            error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();
          if (validationError.includes('Contact.Mobile.Unique')) {
            statusType = 'info';
            actions.updateNotification({
              message: generateErrorMessage(['Contact.Mobile.Unique'], errorsList),
              status: statusType,
            });
          } else {
            actions.updateNotification({
              message: generateErrorMessage(
                error?.response?.data?.validationErrors
                  ? Object.keys(error.response.data.validationErrors)
                  : ['General.BadRequest'],
                errorsList
              ),
              status: statusType,
            });
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
    handleGaSignupPageError(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="px-4 py-6 sm:py-12 sm:px-14">
          <FormHeader step={step2Label} title={title} backButton={backButtonLabel} />

          <p className="input-header">{nameHeader}</p>

          {children}
        </div>

        <FormFooter
          buttonText={finishLabel}
          buttonLoadingText={finishLabelLoading}
          linkText={signInLabel}
          submitting={submitting}
          languageCode={languageCode}
        />
      </form>
    </FormProvider>
  );
}
