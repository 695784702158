import styled from 'styled-components';

export const StyledBannerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    background-color: ${(props) => props.theme.uiBlack};
  }
  @media screen and (min-width: 1024px) {
    min-height: 38.75rem;
    padding: 5rem 0;
  }
`;

export const BgImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;
  }
`;

export const StyledBanner = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.uiBlack};
  color: ${(props) => props.theme.uiWhite};
  @media screen and (max-width: 640px) {
    padding: 2.5rem 0;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding: 3rem 0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    padding: 3rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 5rem;
  }
`;

export const Item = styled.div`
  width: 100%;

  h3 {
    font-size: ${(props) => props.theme.headingExtraSmall};

    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody2};
      margin-bottom: 0.75rem;
    }
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      margin-bottom: 1rem;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 1.25rem;
    }
  }

  p {
    color: ${(props) => props.theme.uiWhite};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
    @media screen and (min-width: 1024px) {
      font-size: ${(props) => props.theme.fontBody1};
    }
  }
`;
export const Indicator = styled.div`
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  display: inline-block;
  margin: 2.5rem auto 1.25rem auto;

  border: 2px solid ${(props) => props.theme.uiWhite};
  border-radius: 3rem;
  font-size: ${(props) => props.theme.fontBody1};
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin: 2.55rem auto 1.688rem auto;
  }
  @media screen and (min-width: 1024px) {
    margin: 3.75rem auto 1.688rem auto;
  }
`;
