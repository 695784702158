import { useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { FormProvider, useForm } from 'react-hook-form';
import ButtonSimple from '../../../common/Button/ButtonSimple';
import AddAddressModal from './AddAddressModal';

import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';
import { disableScrolling } from '../../../../helpers/behavourHelper';

export default function AddNewAddresss(props) {
  const { isEditing, addNewAddressLabel, coloredLink } = props;

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = (e) => {
    setShowModal(!showModal);
    formMethods.reset();
    handleGAEvents('Profile - Address - Add New Address', 'Account', 'Add Address Click', 'profile_add_address_click');

    disableScrolling();
  };

  return (
    <Transition
      in={isEditing}
      timeout={{
        appear: 0,
        enter: 0,
        exit: 0,
      }}
      unmountOnExit
    >
      {(state) => (
        <div className={`anim_slide-y anim_slide-y-${state}`}>
          <FormProvider {...formMethods}>
            <div className="flex">
              <div className={`flex-grow md:p-2 mt-4 ${coloredLink ? 'mb-8 md:mb-12' : ''}`}>
                <ButtonSimple
                  onClick={handleButtonClick}
                  ariaLabel={addNewAddressLabel}
                  role="button"
                  style={{ fontSize: '16px' }}
                  className={`${coloredLink ? 'secondary-button' : ''}`}
                >
                  {addNewAddressLabel}
                </ButtonSimple>
              </div>
            </div>

            {showModal && (
              <AddAddressModal formMethods={formMethods} setShowModal={setShowModal} showModal={showModal} {...props} />
            )}
          </FormProvider>
        </div>
      )}
    </Transition>
  );
}

AddAddressModal.propTypes = { isEditing: PropTypes.bool, addNewAddressLabel: PropTypes.string, coloredLink: PropTypes.bool };
