// Components
import GuestSettingsTopics from './GuestSettingsTopics';
import SettingsEmailCommunicationCheckbox from './SettingsEmailCommunicationCheckbox';

// Utils
import { disableFieldPending } from '../../../../helpers/settingsHelper';

// Styles
import { DescriptionText, FieldContent } from '../../FormStyles';

export default function GuestSettingsEmailFieldContent({
  emailTopics,
  emailOptInLabel,
  emailPreferencesMessage,
  guestPreferencesData,
}) {
  return (
    <FieldContent>
      <DescriptionText className="block mb-0">{emailPreferencesMessage}</DescriptionText>

      <div>
        <GuestSettingsTopics
          emailTopics={emailTopics}
          disableOptIn={disableFieldPending(guestPreferencesData)}
          guestPreferencesData={guestPreferencesData}
        />

        <SettingsEmailCommunicationCheckbox
          fieldType="checkbox"
          label={emailOptInLabel}
          name="EmailOptin"
          alignStart
          isDisabled={disableFieldPending(guestPreferencesData)}
          preferencesData={guestPreferencesData}
        />
      </div>
    </FieldContent>
  );
}
