import PropTypes from 'prop-types';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import JoinScene from '../common/Sections/JoinScene';

const GuestJoinSceneSection = ({ module, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (!isLoggedIn && fields?.isAnonymous === 'true') {
    return (
      <section id="JoinScene">
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          <JoinScene fields={fields} languageCode={languageCode} />
        </WrapperLayout>
      </section>
    );
  }
  return null;
};

export default GuestJoinSceneSection;

GuestJoinSceneSection.propTypes = {
  module: PropTypes.object.isRequired,
};
