import PropTypes from 'prop-types';

// Components
import About from '../common/Sections/About';

const AboutPage = ({ module }) => {
  // get module fields
  const { fields } = module;

  return <About fields={fields} />;
};

AboutPage.propTypes = { module: PropTypes.object };
export default AboutPage;
