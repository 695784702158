/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import LogoList from './LogoList';

// Styles

import { BrandListWrapper } from '../Sections/ComingSoonLocationsStyles';

function BrandList({ brandList, comingSoon }) {
  if (brandList && brandList.length) {
    return brandList.map((brand, index) => (
      <div key={index}>
        {brand?.logos && brand?.logos.length && (
          <BrandListWrapper comingSoon={comingSoon} firstItem={index === 0}>
            {brand?.heading && <h4 className="brand-heading" dangerouslySetInnerHTML={renderHTML(brand.heading)} />}
            <LogoList logoList={brand?.logos} />
          </BrandListWrapper>
        )}
      </div>
    ));
  }

  return null;
}

BrandList.propTypes = { brandList: PropTypes.array, comingSoon: PropTypes.bool };

export default BrandList;
