import PropTypes from 'prop-types';

import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import { updateOffers } from '../../store/actions';

// Components
import HomepageOffers from '../common/Banners/HomepageOffers';

const HomepageOffersBanner = ({ module, customData, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine({ updateOffers });
  const slides = module.fields?.maximumSlides ? parseInt(module.fields?.maximumSlides) : 0;
  if (isLoggedIn && slides > 0) {
    return <HomepageOffers customData={customData} title={module.fields.title} slides={slides} languageCode={languageCode} />;
  }
  return null;
};

HomepageOffersBanner.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'offerspagecontentv2',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.offersCategories) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersCategories',
        sortIDField: 'offersCategories_SortIdField',
      });
    }

    if (contentItem.fields.offersSort) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersSort',
        sortIDField: 'offersSort_SortIdField',
      });
    }

    if (contentItem.fields.offersTags) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersTags',
      });
    }

    return {
      pageContent: contentItem.fields,
      offersCategories:
        contentItem.fields.offersCategories.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      offersSort:
        contentItem.fields.offersSort.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      offersTags:
        contentItem.fields.offersTags.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

HomepageOffersBanner.propTypes = {
  module: PropTypes.object.isRequired,
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
};

export default HomepageOffersBanner;
