import PropTypes from 'prop-types';

export default function FieldLayout({ children, addressModal }) {
  return <div className={`${addressModal ? 'mb-0' : 'mb-4'}`}>{children}</div>;
}

FieldLayout.propTypes = {
  children: PropTypes.array,
  addressModal: PropTypes.bool,
};
