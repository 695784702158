import PropTypes from 'prop-types';

import { timeOfDayGreeting } from '../../../helpers/dateHelpers';
import { GreetingHeading } from './GreetingStyles';
import Loader from '../Loader/Loader';

export default function GreetingTitle({ customData, customer }) {
  const { greetingAfternoonLabel, greetingEveningLabel, greetingMorningLabel, greetingLabel } = customData;

  if (!customer?.data) return <Loader reverse />;

  return (
    <GreetingHeading>
      <span>{timeOfDayGreeting(greetingAfternoonLabel, greetingEveningLabel, greetingMorningLabel, greetingLabel)}</span>{' '}
      {customer.data?.firstName}
    </GreetingHeading>
  );
}

GreetingTitle.propTypes = {
  customData: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};
