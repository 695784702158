/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { Controller, useFormContext } from 'react-hook-form';

import updatePoints from '../../../store/actions/updatePointsAction';

// Helpers
import { calcPointsNeeded, calcPointsTotal, formatPoints } from '../../../helpers/pointsHelpers';
import { formatDate } from '../../../helpers/dateHelpers';
import { formatToCurrency } from '../../../helpers/utils';

import { Row, TableRowItem } from '../../common/Table/TableStyles';
import { Wrapper } from '../Shop/PointsShopStyles';

import PointsRowError from './PointsRowError';
import { handleCreditRedeemEvent } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function PointsListRow({ name, fieldType, rules, isDisabled, transaction, customData, languageCode }) {
  const { notEnoughPointsLabel, needMorePointsButtonLabel } = customData || {};
  const {
    state: {
      points: { added },
      transactions: { balance },
    },
  } = useStateMachine();

  const { id, pointDate, transactionDescription, transactionAmount, points } = transaction || {};
  const { actions } = useStateMachine({ updatePoints });
  const [checkbox, setCheckbox] = useState(false);

  const formContext = useFormContext();
  // eslint-disable-next-line no-unused-vars
  const { control, setValue, formState, getValues, clearErrors } = formContext;

  useEffect(() => {
    setValue(name, checkbox, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox]);

  const checkboxData = {
    id,
    name,
    transactionDescription,
    transactionAmount,
    points,
  };

  const total = calcPointsTotal(added);
  const pointsNeeded = calcPointsNeeded(added, balance);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;

  const handleChange = (e, error) => {
    setCheckbox(e.target.checked);
    if (!error) {
      setValue(name, e.target.checked);
    } else {
      setValue(name, false);
    }

    if (e.target.checked) {
      actions.updatePoints({ added: [...added, checkboxData] });
      handleCreditRedeemEvent(
        `${transactionDescription} ${transactionAmount}$ | ${points}pts`,
        'Points for Travel',
        'Points For Travel Select Amount',
        'select_content',
        id,
        'Points for Travel'
      );
    } else {
      let hardCopy = [...added];
      hardCopy = hardCopy.filter((cartItem) => cartItem.name !== name);

      actions.updatePoints({ added: hardCopy });
    }
  };

  return (
    <Wrapper role="rowgroup">
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: async (value) => {
            if (notEnoughPoints && value) {
              return notEnoughPointsLabel;
            }
          },
        }}
        render={({ field: { onBlur, name, value }, fieldState: { error } }) => (
          <>
            <Row id={`transaction-row-${id}`} role="row" className="row-list" error={error}>
              <TableRowItem checkboxName center role="cell" className="table-checkbox">
                <input
                  name={name}
                  className="points-checkbox"
                  id={`transaction-checkbox-${id}`}
                  type={fieldType}
                  aria-invalid={!!error}
                  role="checkbox"
                  aria-checked={!!value}
                  aria-labelledby={`transaction-${id}-option-${name}`}
                  aria-disabled={isDisabled}
                  disabled={isDisabled}
                  onBlur={onBlur}
                  checked={!!value}
                  onChange={(e) => handleChange(e, error)}
                />
              </TableRowItem>
              <TableRowItem role="cell">{formatDate(pointDate, 'MMM, DD', languageCode)}</TableRowItem>
              <TableRowItem role="cell" className="font-bold">
                <label id={`transaction-${id}-option-${name}`} htmlFor={name} style={{ margin: 0 }}>
                  {transactionDescription}
                </label>
              </TableRowItem>
              <TableRowItem role="cell">{formatToCurrency(transactionAmount, languageCode)}</TableRowItem>
              <TableRowItem role="cell">{formatPoints(points, languageCode)}</TableRowItem>
            </Row>

            {error && (
              <PointsRowError
                notEnoughPointsLabel={notEnoughPointsLabel}
                needMorePointsButtonLabel={needMorePointsButtonLabel}
                pointsNeeded={pointsNeeded}
                error={error}
              />
            )}
          </>
        )}
      />
    </Wrapper>
  );
}
PointsListRow.propTypes = {
  name: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  isDisabled: PropTypes.bool,
  transaction: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
