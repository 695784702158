import { useStateMachine } from 'little-state-machine';
import { useEffect, useState } from 'react';

export default function useOfferDetailParams() {
  const {
    state: {
      navigation: { isReady, hashParams, query },
    },
  } = useStateMachine();

  const [token, setToken] = useState(null);
  const [code, setCode] = useState(null);

  // TODO : optimize the token setting for any edge case that could cause extra render
  useEffect(() => {
    if (isReady) {
      if (query && query.id && query.q) {
        setCode(query.id);
        setToken(query.q);
      } else if (query && query.offer) {
        setCode(query.offer);
        setToken(null);
      } else if (hashParams && hashParams.q && hashParams.id) {
        setCode(hashParams.id);
        setToken(hashParams.q);
      }
    }
  }, [isReady, query, hashParams]);

  return { code, token };
}
