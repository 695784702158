import { useStateMachine } from 'little-state-machine';
import moment from 'moment';
import 'moment/locale/en-ca';
import { useEffect, useState } from 'react';
import { updateTransactions } from '../../../store/actions';
import useCustomerPoints from '../account/useCustomerPoints';

export default function usePoints(refreshInterval = 5) {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [timeOfLastFetch, setTimeOfLastFetch] = useState(null);

  const {
    actions,
    state: {
      navigation: { isReady },
    },
  } = useStateMachine({ updateTransactions });

  const { isLoading, points, refetch } = useCustomerPoints();

  function checkTimeToRefetchElapsed() {
    const currentTime = moment();
    const diffMins = currentTime.diff(timeOfLastFetch, 'minutes');

    if (diffMins >= (refreshInterval || 5)) {
      refetch();
    }
  }

  useEffect(() => {
    if (timeOfLastFetch) {
      checkTimeToRefetchElapsed();
    }
  }, [timeOfLastFetch]);

  useEffect(() => {
    if (shouldUpdate) {
      const timestamp = moment();
      setTimeOfLastFetch(timestamp);
      if (points) {
        const balance = parseInt(points);
        actions.updateTransactions({ balance });
      }
    }
  }, [shouldUpdate, points]);

  // control when we set the points update based on the data fetch
  useEffect(() => {
    if (isReady && !isLoading && points) {
      setShouldUpdate(true);
    }
  }, [isLoading, points, isReady]);
}
