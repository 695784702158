import OrderItem from './OrderItem';

// Helper
import { checkArrayForItems } from '../../../helpers/utils';

export default function OrderItems({ page, awardsTypes, ordersContent, isRecentOrdersList, sectionTitle, languageCode }) {
  if (page && page?.items && checkArrayForItems(page?.items)) {
    return page?.items.map((item) => (
      <OrderItem
        key={item?.orderNumber}
        item={item}
        sectionTitle={sectionTitle}
        award={
          awardsTypes &&
          awardsTypes.filter((award) => award && award?.code?.toLowerCase() === item?.items[0]?.awardType?.toLowerCase())
        }
        itemLabel={
          item?.items && item?.items.length !== 0 && item?.items[0].quantity > 1
            ? ordersContent.itemsLabel
            : ordersContent.itemLabel
        }
        isRecentOrdersList={isRecentOrdersList}
        orderDetailsLink={ordersContent.orderDetailsLink.href}
        languageCode={languageCode}
      />
    ));
  }
  return null;
}
