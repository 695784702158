import styled, { css } from 'styled-components';

export const ErrorWrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.simplified ? css`0.5rem 1rem 0.5rem 0` : `0.5rem 1rem`)};
  background-color: ${(props) => (props.simplified ? css`transparent` : props.theme.errorBgColor)};

  &.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  @media screen and (max-width: 767px) {
    p {
      ${(props) =>
        props.simplified &&
        css`
          padding-bottom: 1rem;
        `};
    }
  }

  p {
    font-size: ${(props) => props.theme.fontBody3} !important;
    padding-left: 2rem;
    color: ${(props) => props.theme.uiErrorRed} !important;
    margin: 0;
    background-image: url('/assets/error.svg');
    background-repeat: no-repeat;
    background-position: left 3px;
    line-height: ${(props) => props.theme.lineHeight3} !important;

    @media screen and (min-width: 768px) {
      ${(props) =>
        !props.simplified &&
        css`
          line-height: 1.75;
        `};
    }
  }
`;

export const StyledError = styled.div`
  background-color: ${(props) => props.theme.lightRedColor};
  border-left: 3px solid ${(props) => props.theme.uiErrorRed};
  padding: 10px 15px;

  p {
    margin-bottom: 0;
  }
`;
