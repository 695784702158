/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import Link from 'next/link';

// Components

import FeaturedCardsList from '../../common/List/FeaturedCardsList';

import { SectionTitle, FeaturedCardsWrapper, LinkWrapper } from '../OfferStyles';
// Helpers
import { checkArrayForItems } from '../../../helpers/utils';

function FeaturedCards({ featuredCards, featuredCardsList, selectedFilters }) {
  const { featuredCardsTitle, seeAllCardsLink } = featuredCards;
  if (selectedFilters === 'banking' && checkArrayForItems(featuredCardsList)) {
    return (
      <FeaturedCardsWrapper>
        {featuredCardsTitle && (
          <SectionTitle>
            {featuredCardsTitle} <span>({featuredCardsList?.length || 0})</span>
          </SectionTitle>
        )}
        <FeaturedCardsList featuredCardsList={featuredCardsList} />

        {seeAllCardsLink?.href && seeAllCardsLink?.text && (
          <LinkWrapper>
            <Link href={seeAllCardsLink?.href} role="presentation" className="font-bold leading-6">
              {seeAllCardsLink?.text}
            </Link>
          </LinkWrapper>
        )}
      </FeaturedCardsWrapper>
    );
  }

  return null;
}

FeaturedCards.propTypes = {
  featuredCardsList: PropTypes.array,
  featuredCards: PropTypes.object,
  selectedFilters: PropTypes.string,
};

export default FeaturedCards;
