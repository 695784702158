import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  display: block;
  &.mobileHide {
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
`;
export const StyledList = styled.ul`
  padding: 0;
  margin: 1.5rem auto 0;
  list-style: none;
  display: flex;

  display: grid;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 640px) {
    grid-gap: 20px;
    grid-auto-flow: column;
    grid-auto-columns: calc(50% - 10px * 2);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(0.75 * 10px);
    margin-bottom: calc(-0.25 * 10px);

    /* &:after {
      content: '';
      width: 10px;
    } */
  }

  @media screen and (min-width: 641px) {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }

  li {
    width: 100%;
    _flex-basis: 20%;
    align-content: flex-start;
    list-style: none;
    text-align: center;

    a {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      text-decoration: none;
      padding: 1.25rem 0;
      align-items: center;
      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:focus {
        outline: 3px solid ${(props) => props.theme.uiPurple};
      }

      span {
        display: block;
      }
    }
  }
`;

export const SliderSection = styled.div`
  ol,
  ul {
    padding-left: 0;
    margin: 1.5rem auto 0;

    li {
      padding: 10px;
      width: 100%;
      list-style: none;

      a {
        display: block;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        font-size: ${(props) => props.theme.fontBody3};

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        &:focus {
          outline: 3px solid ${(props) => props.theme.uiPurple};
        }

        span {
          display: block;
        }
      }
    }
  }
`;

export const BrandTileWrap = styled.div`
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.isSlider &&
    css`
      width: 7.25rem;
      height: 5.25rem;
    `}
  ${(props) =>
    !props.isSlider &&
    css`
      @media screen and (max-width: 991px) {
        width: 7.25rem;
        height: 5.25rem;
      }
      width: 10.875rem;
      height: 8rem;
    `}
`;
