import ExternalImage from '../Images/ExternalImage';

import { StyledListNOC, ImageCircleNOC } from './ContentGridStyles';

export default function ContentGrid4Columns({ data }) {
  if (data) {
    return (
      <StyledListNOC>
        {data.map((item, index) => (
          <li key={index} className="list-none  text-center">
            <ImageCircleNOC className="mx-auto category-circle-tile">
              {item.fields?.image?.url && (
                <ExternalImage className="object-center object-cover" url={item.fields?.image?.url} width={80} height={80} />
              )}
            </ImageCircleNOC>
            <h3 className="headline-extra-small">{item.fields.title}</h3>
          </li>
        ))}
      </StyledListNOC>
    );
  }
  return null;
}
