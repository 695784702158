import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../../store/actions';
import ErrorMessage from '../../../common/Errors/ErrorMessage';

import { CheckboxWrapper, StyledCheckbox } from '../../FormStyles';

function SettingsEmailCheckbox({
  className,
  name,
  label,
  fieldType,
  rules,
  extraText,
  isDisabled,
  defaultChecked,
  alignStart,
  disableOptIn,
}) {
  const { actions } = useStateMachine({ updateUser });
  // eslint-disable-next-line no-unused-vars
  const [checkbox, setCheckbox] = useState(false);

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, checkbox || defaultChecked, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const handleClick = (e) => {
    if (!disableOptIn && getValues(name) === false) {
      setValue('EmailOptin', true);
    }

    actions.updateUser({ authPreferences: [e.target.id] });
  };

  return (
    <>
      <CheckboxWrapper className={`${isDisabled ? 'isDisabled' : ''}`} alignStart={alignStart}>
        <StyledCheckbox
          settingEmail
          name={name}
          id={name}
          type={fieldType}
          aria-invalid={!!error}
          role="checkbox"
          aria-labelledby={`form-${name}`}
          aria-checked={!!defaultChecked}
          aria-disabled={isDisabled}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          className={getValues(name) ? 'pressed' : ''}
          {...register(name, { ...rules })}
          isError={Boolean(isError)}
          onClick={handleClick}
        />
        <div>
          {Boolean(label) && (
            <label id={`form-${name}`} htmlFor={name} className={`${isDisabled ? 'isDisabled' : ''} ${className || ''}`}>
              {label}
            </label>
          )}
          {extraText && <p>{extraText}</p>}
        </div>
      </CheckboxWrapper>
      {isError && <ErrorMessage error={error} />}
    </>
  );
}

SettingsEmailCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  extraText: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disableOptIn: PropTypes.bool,
  alignStart: PropTypes.bool,
};
export default SettingsEmailCheckbox;
