import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import styled from 'styled-components';

import TransactionError from '../../common/Errors/TransactionError';
import AddedList from './AddedList';
import Button from '../../common/Button/Button';

import { handleGAEvents, stringConcatHelper, spendPointsForCreditEcomm } from '../../../helpers/handleGoogleAnalyticsHelper';

import { formatPoints, calcPointsNeeded, calcPointsTotal, amountSelected, calcTotalDollar } from '../../../helpers/pointsHelpers';

import { SummaryWrapper, AddedItems, SummaryRow, TotalBalance, SummaryInner, SummaryHeader } from './PointsShopStyles';

/**
 *
 * TODO:: balance prop should come from global state, which is going to either be stored on the selectred card or its own key.
 *
 *
 * card is loaded from the cardselect
 * -> we grab the balance and display under remaining points
 * ---> opt 1 --> we track the current balance in local state (global card bal and do math depending on the checkboxes)
 * ---> opt 2 --> rename transactions.balance --> remainingPoints --> and we use that to track what the user has redeemed
 *
 *Criteria--> the remaining points should +/- based on the checked redemtions (total in this component)
 *
 *
 *
 * @param {*} param0
 * @returns
 */

const SummaryHeading = styled.h2`
  display: block;
  margin-top: 0.7rem;
  margin-left: 0;
  margin-bottom: 1.5rem;
  margin-right: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody2};
`;

export default function Summary({ customData, page }) {
  const {
    state: {
      points: { added },
      transactions: { balance, awards },
      points: { step },
      language: { locale },
    },
  } = useStateMachine();

  const {
    summaryLabel,
    selectedTransactionsLabel,
    continueButtonLabel,
    summaryTotalLabel,
    summaryRemainingPointsLabel,
    notEnoughPointsLabel,
    pts,
  } = customData;

  const total = calcPointsTotal(added);
  const pointsNeeded = calcPointsNeeded(added, balance);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;
  const cartHasItems = added.length;
  const totalDollar = calcTotalDollar(added);
  let disableButton = false;
  if (pointsNeeded + balance === 0) {
    disableButton = true;
  } else if (balance - pointsNeeded < balance) {
    disableButton = true;
  } else {
    disableButton = false;
  }

  const gaLabel = stringConcatHelper(total, ' | Continue Click');

  const handleChange = (e) => {
    handleGAEvents(gaLabel, 'Redeem', 'Continue Points For Credit Click', 'redeem_credit_continue_click');

    try {
      if (page === 'pfc') {
        const awardProductCodePFC = added
          ? added
              .filter((award) => award.id === awards.awardProduct.id)
              .map((items) => items.code)
              .toString()
          : '';
        spendPointsForCreditEcomm(
          'p4c_add_to_cart',
          'Points For Credit',
          total,
          `${amountSelected(added)}`,
          awardProductCodePFC,
          'redeem_credit_continue_click',
          'Points For Credit Add To Cart',
          totalDollar
        );
      } else if (page === 'byot') {
        const awardProductCodeBYOT = awards
          ? awards.awardProduct.filter((award) => award.id === added.id).map((items) => items.code)
          : '';
        spendPointsForCreditEcomm(
          'byot_add_to_cart',
          'Points For Travel',
          total,
          total,
          awardProductCodeBYOT[0],
          'redeem_travel_continue_click',
          'Points For Travel Add To Cart',
          totalDollar
        );
      }
    } catch {
      //
    }
  };

  return (
    <SummaryWrapper className="flex-1" page={page} hasBYOTTransactions={awards?.byot?.length > 0}>
      <SummaryInner cartHasItems={cartHasItems}>
        {page === 'byot' && summaryLabel && (
          <SummaryHeader className="hidden md:block" cartHasItems={cartHasItems}>
            {summaryLabel && <SummaryHeading className="font-bold">{summaryLabel}</SummaryHeading>}
            <p className="font-bold selected-transactions">
              {selectedTransactionsLabel.charAt(0).toUpperCase() + selectedTransactionsLabel.slice(1)} ({cartHasItems})
            </p>
          </SummaryHeader>
        )}

        {page === 'byot' && selectedTransactionsLabel && (
          <AddedItems className="hidden md:block">
            <AddedList customData={customData} />
          </AddedItems>
        )}

        <TotalBalance cartHasItems={cartHasItems}>
          <SummaryRow>
            <p className={`transition-all font-bold${page === 'pfc' && notEnoughPoints ? ' error-red' : ''}`}>
              {summaryTotalLabel || 'Redeem Total'}
            </p>
            <p className={`points-total transition-all${page === 'pfc' && notEnoughPoints ? ' error-red' : ''}`}>
              {total ? `${formatPoints(total, locale)} ${pts || 'pts'}` : 0}
            </p>
          </SummaryRow>
          {page === 'byot' && (
            <SummaryRow className="mb-0">
              {summaryRemainingPointsLabel && <p className="font-bold">{summaryRemainingPointsLabel}</p>}
              <p className="font-bold md:font-normal">{balance ? formatPoints(remainingPoints, locale) : 0}</p>
            </SummaryRow>
          )}
        </TotalBalance>

        <Button
          type="submit"
          buttonText={continueButtonLabel}
          ariaLabel={continueButtonLabel}
          disabled={Boolean(disableButton)}
          className={`${step > 1 ? 'w-full' : ''}`}
          style={{ marginTop: '1rem', width: '100%' }}
          onClick={handleChange}
        >
          {continueButtonLabel}
        </Button>

        {page === 'pfc' && notEnoughPoints && <TransactionError error={{ message: notEnoughPointsLabel }} simplified />}
      </SummaryInner>
    </SummaryWrapper>
  );
}

Summary.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
};
