import PropTypes from 'prop-types';
import Image from 'next/image';

import { DatePickerInputField, StyledDatePickerInput, DatePickerIcon } from './DatePickerInputStyles';

export default function DatePickerInput({ name, value, handleKeyDown, placeholder, ...props }) {
  const { openCalendar } = props;

  return (
    <DatePickerInputField>
      <StyledDatePickerInput
        type="text"
        value={value}
        readOnly
        aria-labelledby={`filter-${name}`}
        onClick={openCalendar}
        onFocus={openCalendar}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      />

      <DatePickerIcon>
        <Image src="/assets/calendar-icon.svg" alt={name} width={29} height={20} />
      </DatePickerIcon>
    </DatePickerInputField>
  );
}

DatePickerInput.propTypes = {
  name: PropTypes.string,
  handleKeyDown: PropTypes.func,
  openCalendar: PropTypes.func,
  props: PropTypes.object,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};
