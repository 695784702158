import styled from 'styled-components';

export const StyledBannerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    background-color: ${(props) => props.theme.uiBlack};
    border-top: 3px solid #ffffff;
  }
  @media screen and (min-width: 641px) {
    padding: 5rem 0;
  }
`;

export const BgImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: 641px) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;
  }
`;

export const StyledBanner = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.uiBlack};
  color: ${(props) => props.theme.uiWhite};
  padding: 0 0 2.5rem;

  @media screen and (min-width: 641px) {
    padding: 0 3.75rem 5rem;
  }
`;
export const StyledBannerContent = styled.div`
  text-align: center;
  max-width: 50rem;
  h4 {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody1};
    }
  }
`;

export const LocationsHeader = styled.div`
  border-top: 1px solid ${(props) => props.theme.uiWhite};
  width: 100%;

  margin: auto;
  h3 {
    margin: 2rem 0;
    font-size: ${(props) => props.theme.fontBody1};
    @media screen and (min-width: 641px) and (max-width: 990px) {
      font-size: ${(props) => props.theme.headingExtraSmall};
    }
    @media screen and (min-width: 991px) {
      font-size: ${(props) => props.theme.headingSmall};
      margin: 2.5rem 0;
    }
  }
`;

export const SubHeader = styled.div`
  margin: 2.5rem auto;
  line-height: ${(props) => props.theme.lineHeight3};
  width: 100%;
  text-align: center;
  max-width: 50rem;
  font-size: ${(props) => props.theme.headingExtraSmall};
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody1};
    margin: 2rem auto;
  }
  p {
    color: ${(props) => props.theme.uiWhite};
    font-size: ${(props) => props.theme.headingExtraSmall};
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody1};
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;

export const ComingSoonMessage = styled.div`
  border-top: 1px solid ${(props) => props.theme.uiWhite};
  width: 100%;
  max-width: 62.5rem;
  margin: 2.5rem auto 0;
  @media screen and (max-width: 640px) {
    margin: 2rem auto 0;
  }
  h3 {
    text-align: center;
    margin-top: 2.5rem;
    font-weight: 700;
    line-height: ${(props) => props.theme.lineHeight2};
    @media screen and (max-width: 640px) {
      margin-top: 2rem;
    }
    font-size: ${(props) => props.theme.fontBody1};
    @media screen and (min-width: 641px) and (max-width: 990px) {
      font-size: ${(props) => props.theme.headingExtraSmall};
    }
    @media screen and (min-width: 991px) {
      font-size: ${(props) => props.theme.headingSmall};
    }
  }
`;

export const TileContainer = styled.div`
  position: relative;
  margin: 0.4rem;
  overflow: hidden;
  border-radius: 100%;
  display: inline-flex;
  width: 5.25rem;
  height: 5.25rem;
  @media screen and (max-width: 640px) {
    width: 3.75rem;
    height: 3.75rem;
  }
`;

export const LegalMessage = styled.div`
  margin: 1.5rem 0 5rem 0;
  @media screen and (max-width: 640px) {
    margin: 1.5rem 0 2.5rem 0;
  }
  p {
    font-size: ${(props) => props.theme.fontBody1};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;
