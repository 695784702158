import { useEffect } from 'react';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateLayout } from '../../store/actions';

// Components
import ErrorController from '../common/Errors/ErrorController';
import WrapperLayout from '../layouts/WrapperLayout';

export default function ErrorPage({ module: { fields } }) {
  const {
    actions,
    state: {
      session: { isLoggedIn },
      layout: { isDark },
    },
  } = useStateMachine({ updateLayout });

  useEffect(() => {
    if (isDark && isLoggedIn) {
      actions.updateLayout({ isDark: false });
    }
  }, [isDark]);

  return (
    <WrapperLayout isBlack className="global-content-wrapper">
      <ErrorController fields={fields} statusCode={404} />
    </WrapperLayout>
  );
}
