import PropTypes from 'prop-types';
import Link from 'next/link';

import { DividerVertical, HeaderColumn } from '../HeaderStyles';

import SignInHeaderLink from '../../../../signin/SignInHeaderLink';

// Helpers
import { handleKeyDown } from '../../../../../helpers/utils';
import { handleGAEvents } from '../../../../../helpers/handleGoogleAnalyticsHelper';

export default function HeaderLoggedOutRegister({ isLoggedIn, header, languageCode }) {
  const { registerLink, signOutLabel, signInIcon, signInLabel, loadingLabel } = header;

  const handleClick = () => {
    handleGAEvents('Register for Scene+', 'Registration Events', 'Register for Scene Click', 'sign_up_start');
  };

  if (!isLoggedIn) {
    return (
      <HeaderColumn className="flex-grow-0 headerRegisterColumn mobileHide">
        <Link
          href={registerLink.href}
          locale={languageCode}
          className="register"
          onClick={handleClick}
          onKeyDown={(e) => handleKeyDown(e, handleClick)}
          role="link"
          tabIndex="0"
        >
          {registerLink.text}
        </Link>
        <DividerVertical role="separator">|</DividerVertical>
        <SignInHeaderLink
          linkStyle="header-link-signin"
          languageCode={languageCode}
          signOutLabel={signOutLabel}
          signInIcon={signInIcon.url}
          signInLabel={signInLabel}
          loadingLabel={loadingLabel}
        />
      </HeaderColumn>
    );
  }
  return null;
}

HeaderLoggedOutRegister.propTypes = { isLoggedIn: PropTypes.bool, header: PropTypes.object, languageCode: PropTypes.string };
