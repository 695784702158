import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../store/actions';
import updateStepAction from '../../store/actions/updateStepAction';

// Services
import { newCustomer, newCustomerGKEnrollment } from '../../pages/api/services';

// Components
import FormHeader from '../forms/registration-gk/FormHeader';
import FormFooter from '../forms/registration-gk/FormFooter';

// Hooks
import useStorage from '../hooks/navigation/useStorage';

// Helpers
import { generateErrorMessage } from '../../helpers/errorsHelper';
import { formatBirthDay } from '../../helpers/dateHelpers';
import { handleGAEventsBase, handleGaSignupPageError, sendRegisterOptIn } from '../../helpers/handleGoogleAnalyticsHelper';

export default function RegisterStep2FormLayoutGK({ children, ...props }) {
  const { title, stepLabel, nameHeader, finishLabel, finishLabelLoading, gKBackLabel, languageCode, errorsList } = props;

  const [submitting, setSubmitting] = useState(false);
  const { state, actions } = useStateMachine({
    updateUser,
    updateStepAction,
    updateNotification,
  });

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { getItem } = useStorage();
  //  useRegisterGAFormEvents(formMethods.formState?.touchedFields, 'Step 2 GK');

  const enrollWithGK = (reqBody, options) => {
    actions.updateUser(reqBody);

    newCustomerGKEnrollment(reqBody, options)
      .then(async (res) => {
        if (res.data) {
          actions.updateStepAction({ step: 4 });
          formMethods.reset();
          sendRegisterOptIn(res.data);
          const dataLayer = {
            event: 'sign_up_button_finish',
            category: 'Registration Events',
            action: 'Step 2 GK',
            label: 'Finish',
            sign_up_step: 'Step 2 GK',
          };
          handleGAEventsBase(dataLayer);
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          const validationError =
            error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();
          if (validationError !== null && validationError.includes('Contact.Mobile.Unique')) {
            actions.updateNotification({
              message: generateErrorMessage(['Contact.Mobile.Unique'], errorsList),
              status: 'info',
            });
          } else {
            actions.updateNotification({
              message: generateErrorMessage(
                error?.response?.data?.validationErrors ? validationError.split(',') : ['General.BadRequest'],
                errorsList
              ),
              status: 'error',
            });
          }

          const dataLayer = {
            event: 'sign_up_error_customer_enrollment_GK',
            category: 'Registration Events',
            action: 'Error',
            label: validationError || 'NotAvailable',
            error_type: validationError || 'General.ValidationErrors.NotAvailable',
          };
          handleGAEventsBase(dataLayer);

          setSubmitting(false);
        }
      });
  };

  const enrollWithoutGK = (reqBody, options) => {
    newCustomer(reqBody, options)
      .then(async (res) => {
        if (res.data) {
          const cardNumber = res?.data?.data?.sceneCardNumber;
          actions.updateUser({
            ...reqBody,
            step: 4,
            sceneNumber: cardNumber,
          });
          formMethods.reset();

          sendRegisterOptIn(res.data);
          const dataLayer = {
            event: 'sign_up_button_finish',
            category: 'Registration Events',
            action: 'Step 2',
            label: 'Finish',
            sign_up_step: 'Step 2',
          };
          handleGAEventsBase(dataLayer);
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          const validationError =
            error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();
          if (validationError !== null && validationError.includes('Contact.Mobile.Unique')) {
            actions.updateNotification({
              message: generateErrorMessage(['Contact.Mobile.Unique'], errorsList),
              status: 'info',
            });
          } else {
            actions.updateNotification({
              message: generateErrorMessage(
                error?.response?.data?.validationErrors ? validationError.split(',') : ['General.BadRequest'],
                errorsList
              ),
              status: 'error',
            });
          }

          const dataLayer = {
            event: 'sign_up_error_customer_enrollment',
            category: 'Registration Events',
            action: 'Error',
            label: validationError || 'NotAvailable',
            error_type: validationError || 'General.ValidationErrors.NotAvailable',
          };
          handleGAEventsBase(dataLayer);

          setSubmitting(false);
        }
      });
  };

  const handleEnrollment = (reqBody) => {
    const session = getItem('session');
    const options = { session };

    if (state.user.isGhostKitActivationEnabled) {
      enrollWithGK(
        {
          ...reqBody,
          cardNumber: state.user.sceneNumber,
        },
        options
      );
    } else {
      enrollWithoutGK(reqBody, options);
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    const reqBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: formatBirthDay(data.dobYear, data.dobMonth, data.dobDay),
      gender: data.gender,
      culture: languageCode,
      SMSOptIn: data.sms,
      addresses: [
        {
          customerAddressId: 0,
          countryCode: 'CA',
          provinceCode: data.provinceCode,
          city: data.city,
          postalCode: data.postalCode,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          firstName: data.firstName,
          lastName: data.lastName,
          primaryPhoneNumber: data.primaryPhoneNumber.replace(/-/g, ''),
        },
      ],
      contacts: [
        {
          email: state.user.email,
          primaryPhoneNumber: data.primaryPhoneNumber.replace(/-/g, ''),
        },
      ],
      password: state.user.password,
      needLoyaltyCard: data.needLoyaltyCard,
    };

    handleEnrollment(reqBody);
  };

  const currentStep = state.user.isGhostKitActivationEnabled ? 2 : 1;
  const totalSteps = state.user.isGhostKitActivationEnabled ? 3 : 2;
  const onErrors = (errors) => {
    handleGaSignupPageError(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="px-4 sm:py-12 md:mb-4 sm:px-14">
          <FormHeader stepLabel={stepLabel} currentStep={currentStep} totalSteps={totalSteps} title={title} />
          <p className="input-header">{nameHeader}</p>
          {children}
        </div>

        <FormFooter
          buttonText={finishLabel}
          buttonLoadingText={finishLabelLoading}
          linkText={gKBackLabel}
          submitting={submitting}
          languageCode={languageCode}
        />
      </form>
    </FormProvider>
  );
}
