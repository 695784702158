import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getSpotlights } from '../../../pages/api/services';

export default function useSpotlights(languageCode) {
  const {
    state: {
      session: { isLoggedIn, accessToken },
      location,
    },
  } = useStateMachine();

  const reqBody = {
    filters: {
      category: [''],
      tag: [''],
    },
    sort: '',
    pagination: {
      page: 1,
      limit: 2,
    },
  };

  const { isLoading, error, data, refetch } = useQuery(['fetchSpotlights', languageCode, location?.province], async () => {
    if (isLoggedIn && accessToken && location?.province) {
      try {
        const res = await getSpotlights(reqBody, languageCode, accessToken, location.province);

        return res.data.data;
      } catch (error) {
        console.error(error);
      }
    }
  });

  return { isLoading, error, spotlights: data, refetch };
}
