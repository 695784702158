/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import TravelServiceContent from '../site-pages/travel-service/TravelServiceContent';
import { LastUpdated, Divider, TravelIntro } from '../site-pages/IntroStyles';

import Disclaimer from '../site-pages/Disclaimer';

const TravelServiceSection = ({ module, customData }) => {
  // get module fields
  const { fields } = module;

  return (
    <WrapperLayout className="global-content-wrapper">
      <TravelIntro>
        {fields.lastUpdatedLabel && (
          <>
            <LastUpdated>{fields.lastUpdatedLabel}</LastUpdated>
            <Divider />
          </>
        )}

        <h2 className="headline-extra-small" dangerouslySetInnerHTML={renderHTML(fields.title)} />
      </TravelIntro>
      <TravelServiceContent data={customData.termsList} />
      <Disclaimer message={fields.disclaimerMessage} backToTopMessage={fields.backToTopLabel} />
    </WrapperLayout>
  );
};

TravelServiceSection.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'travelservicetermslist',
      languageCode,
    });

    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      termsList: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default TravelServiceSection;

TravelServiceSection.propTypes = { module: PropTypes.object, customData: PropTypes.object };
