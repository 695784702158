/* eslint-disable no-unused-vars */
export const shippingSelectStyles = {
  container: () => ({
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#FFFFFF',
  }),
  control: (styles, { data, isDisabled, isFocused, isSelected, errors }) => ({
    ...styles,
    alignItems: 'flex-start',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    fontSize: '0.875rem',
    padding: '1rem 0',
    minHeight: '120px',
    borderColor: isFocused ? '#9B43FF' : '#858585',
    boxShadow: isFocused ? '#9B43FF' : '#858585',
    borderWidth: isFocused ? '3px' : '1px',
    '&:hover': {
      borderColor: '#9B43FF',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    position: 'relative',
    zIndex: 1,
    fontSize: '0.875rem',
    backgroundColor: '#FFFFFF !important',
    color: '#000000',
    width: '100%',
    lineHeight: '1.60',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    border: isFocused ? '1px solid #FFFFFF' : '',
    borderColor: isFocused ? '#6400D6' : '',
    /* Borders look fuzzy without the white top left and right borders */
    borderTop: isFocused ? '1px solid #6400D6' : '1px solid #FFFFFF',
    borderLeft: isFocused ? '1px solid #6400D6' : '1px solid #FFFFFF',
    borderRight: isFocused ? '1px solid #6400D6' : '1px solid #FFFFFF',
    margin: '0',
    padding: '1rem 1rem 0',
    '&:after': {
      content: "''",
      position: 'relative',
      display: 'block',
      width: '100%',
      margin: '0.781rem 0 0',
      borderBottom: '1px solid #EEEDED',
    },
    '&:hover': {
      border: '1px solid #9B43FF !important',
    },
  }),
  menu: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    margin: 0,
    borderRadius: 0,
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    position: 'initial',
    height: '100%',
    overflow: 'inherit',
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    lineHeight: '1.25',
    overflow: 'inherit',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    width: '3rem',
    backgroundImage: 'url(/assets/chevron-down.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    svg: {
      display: 'none',
    },
  }),
  indicatorsContainer: (prevStyle, state) =>
    state.isMulti
      ? {
          ...prevStyle,
          display: 'none',
        }
      : null,
};

export const customErrorStyles = {
  ...shippingSelectStyles,
};
