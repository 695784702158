import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { handleLocaleUrl } from '../../helpers/localeHelpers';

export default function LocaleSwitcher({ setOpenLanguage, locale, englishLabel, frenchLabel }) {
  const router = useRouter();
  const [lang, setLang] = useState(locale);

  const hide = () => setOpenLanguage && setOpenLanguage(false);
  const show = () => setOpenLanguage && setOpenLanguage(true);

  const handleClick = () => {
    setOpenLanguage(false);
    setLang(!lang);

    if (router && router?.asPath?.indexOf('#state=') !== -1) {
      router.push('/');
    }
  };

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      hide();
    }

    if (e.keyCode === 13) {
      handleClick();
    }
  };

  if (setOpenLanguage) {
    return (
      <>
        <Link
          href={handleLocaleUrl(router)}
          locale="en-ca"
          role="link"
          className={`${locale === 'en-ca' ? 'selected-locale' : ''}`}
          onClick={() => handleClick('en-ca')}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <>
            <span className="mr-2">{englishLabel}</span>
            {locale === 'en-ca' && (
              <Image src="/assets/selected-locale.svg" height={15} width={15} alt="" role="presentation" className="inline" />
            )}
          </>
        </Link>

        <Link
          href={handleLocaleUrl(router)}
          locale="fr-ca"
          role="link"
          className={`${locale === 'fr-ca' ? 'selected-locale' : ''}`}
          onClick={() => handleClick('fr-ca')}
          onFocus={show}
          onBlur={hide}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <>
            <span className="mr-2">{frenchLabel}</span>
            {locale === 'fr-ca' && (
              <Image src="/assets/selected-locale.svg" height={15} width={15} alt="" role="presentation" className="inline" />
            )}
          </>
        </Link>
      </>
    );
  }

  return null;
}
