import styled, { css } from 'styled-components';

export const TermsContentStyles = styled.div`
  margin: 0 auto;
  padding-top: 1.5rem;
  display: block;
  background-color: ${(props) => props.theme.whiteColor};
  border-top: 1px solid ${(props) => props.theme.lightGrayColor2};
  strong {
    font-size: ${(props) => props.theme.fontLegal};
    color: ${(props) => props.theme.lightGrayColor4};
  }
  p {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontLegal};
  }
  h4 {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontBody3};
    font-weight: bold;
    margin-bottom: 1rem;
    display: inline-block;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid ${(props) => props.theme.uiPurple};
  }
`;

export const TermsContentInner = styled.div`
  padding-right: 1rem;
  width: 100%;
  font-size: ${(props) => props.theme.fontLegal};

  ${(props) =>
    props.scrollable &&
    css`
      overflow-y: auto;
      height: 200px;

      @media screen and (min-width: 768px) {
        height: 100px;
      }
    `}

  div {
    color: ${(props) => props.theme.lightGrayColor4};
    line-height: ${(props) => props.theme.lineHeight3};
    ol,
    ul,
    p {
      font-size: ${(props) => props.theme.fontLegal};
      color: ${(props) => props.theme.lightGrayColor4};
      line-height: ${(props) => props.theme.lineHeight3};

      strong {
        display: block;
        color: ${(props) => props.theme.lightGrayColor4};
      }
    }
  }
`;
