/* eslint-disable camelcase */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { insertParam, updateURI } from '../../../helpers/navigationHelpers';
import ExternalImage from '../../common/Images/ExternalImage';

// Global State

// Hooks
import useIntersectionObserverGa from '../../hooks/utils/useIntersectionObserverGa';
import useScrollDetection from '../../hooks/utils/useScrollDetection';

// Helpers
import { handleKeyDown } from '../../../helpers/utils';
import { handleGAEventsBase, getOfferCardMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';

import { OfferBrandTitle, OfferBonusPoints, OfferDescription, OfferItemWrapper, IconColumn, DetailsColumn } from '../OfferStyles';

export default function OfferItem({ content, icons, toggle, listName, id }) {
  const { brand, subTitle, title } = content.cmsContent;

  const {
    state: { location },
  } = useStateMachine();
  const { scrolling } = useScrollDetection();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const threshold = [0.8];

  // GA props
  const eventCategory = 'Partner Offers';
  const list_name = listName;
  const tile_position = parseInt(id?.split('-')[1]) + 1;
  const prov_segment = location?.province;
  const offer = content;

  useEffect(() => {
    if (enabled && Object.keys(content).length && isIntersecting) {
      const event = 'offer_view_item_list';
      const alt_event = 'offers_impression';
      const eventAction = 'Offer Impressions';

      const dataLayer = getOfferCardMappedObject(
        offer,
        event,
        alt_event,
        eventCategory,
        eventAction,
        list_name,
        tile_position,
        prov_segment
      );

      handleGAEventsBase(dataLayer);
      setEnabled(false);
    }
  }, [isIntersecting]);

  const handleTileClick = () => {
    const event = 'offer_select_item';
    const alt_event = 'offers_tile_click';
    const eventAction = 'Offer Click';

    const dataLayer = getOfferCardMappedObject(
      offer,
      event,
      alt_event,
      eventCategory,
      eventAction,
      list_name,
      tile_position,
      prov_segment
    );

    handleGAEventsBase(dataLayer);
  };

  const handleClick = (e) => {
    e.preventDefault();
    handleTileClick();
    updateURI(insertParam('offer', offer.offerCode));
    toggle(content.offerId);
  };

  const categoryIcon = icons
    .filter((icon) => icon.fields.code === content.category[0])
    .map((iconImage) => iconImage.fields.image);

  function onIntersect(val) {
    if (isIntersecting !== val) setIsIntersecting(val);
  }

  const targetRef = useRef(null);
  useIntersectionObserverGa({
    target: targetRef,
    onIntersect,
    scrolling,
    enabled,
    threshold,
  });

  return (
    <div
      role="button"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
      tabIndex="0"
      onKeyDown={(e) => handleKeyDown(e, handleClick)}
      ref={targetRef}
    >
      <OfferItemWrapper>
        <IconColumn>
          {categoryIcon[0] && <ExternalImage url={categoryIcon[0].url} title={content.category[0]} height={58} width={58} />}
        </IconColumn>
        <DetailsColumn>
          {brand && <OfferBrandTitle>{brand.title}</OfferBrandTitle>}
          {title && <OfferBonusPoints>{title}</OfferBonusPoints>}
          {subTitle && <OfferDescription>{subTitle}</OfferDescription>}
        </DetailsColumn>
      </OfferItemWrapper>
    </div>
  );
}
OfferItem.propTypes = {
  content: PropTypes.object.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icons: PropTypes.array,
  toggle: PropTypes.func,
  listName: PropTypes.string,
  id: PropTypes.string,
};
