import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

// Hooks
import { updateOrder } from '../../../store/actions';

// Components
import DetailListItems from './DetailsSections/DetailListItems';
import Title from '../Headings/Title';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { DetailsWrapper, DetailsHeader } from './DetailsStyles';

import {
  DetailsTotal,
  DetailsShippingMethod,
  DetailsShippingAddress,
  DetailsAccount,
  DetailsEmail,
  DetailsOrderDate,
  DetailsOrderNumber,
} from './DetailsSections';

export default function Details({ customData, sitemap, awardsTypes }) {
  const {
    orderNumberLabel,
    orderDateLabel,
    shippingAddressLabel,
    shippingMethod,
    itemLabel,
    itemsLabel,
    totalLabel,
    ptsLabel,
    emailAddressLabel,
    yourAccountLabel,
    viewCardLabel,
  } = customData;
  const {
    state: {
      user: { customer },
      language: { locale },
      order: { selectedOrder },
      transactions: { cards },
    },
  } = useStateMachine({ updateOrder });

  const { merchant, orderDate, orderNumber, shippingAddress, items, accountSegment } = selectedOrder;

  // in cases for what to show for specific detail type
  const isPFC = merchant === 'Points For Credit';
  const isBYOT = merchant === 'Book Your Own Travel';

  const customTitle = awardsTypes.filter((header) => header?.code === items[0]?.awardType).map((headerName) => headerName?.name);

  if (customer && selectedOrder?.orderId && items && items.length) {
    return (
      <>
        <div className="py-4">{sitemap && customTitle && <Breadcrumbs customData={sitemap} title={customTitle} />}</div>
        <DetailsWrapper>
          {customTitle && (
            <DetailsHeader>
              <Title>{customTitle}</Title>
            </DetailsHeader>
          )}

          <DetailsOrderNumber orderNumberLabel={orderNumberLabel} orderNumber={orderNumber} />

          <DetailsOrderDate orderDateLabel={orderDateLabel} orderDate={orderDate} locale={locale} />

          {!isPFC && !isBYOT && (
            <DetailsEmail emailAddressLabel={emailAddressLabel} email={selectedOrder?.shippingAddress?.email} />
          )}

          <DetailsAccount accountSegment={accountSegment} cards={cards} yourAccountLabel={yourAccountLabel} />

          {shippingAddress && (
            <DetailsShippingAddress shippingAddress={shippingAddress} shippingAddressLabel={shippingAddressLabel} />
          )}

          <DetailsShippingMethod selectedOrder={selectedOrder} shippingMethod={shippingMethod} />

          <DetailListItems
            items={items}
            ptsLabel={ptsLabel}
            itemsLabel={itemsLabel}
            itemLabel={itemLabel}
            viewCardLabel={viewCardLabel}
            locale={locale}
          />

          <DetailsTotal items={items} totalLabel={totalLabel} ptsLabel={ptsLabel} locale={locale} />
        </DetailsWrapper>
      </>
    );
  }
  return null;
}

Details.propTypes = {
  customData: PropTypes.object,
};
