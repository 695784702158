import styled, { css } from 'styled-components';

export const Footer = styled.footer`
  background-color: ${(props) => props.theme.uiCharcoal};
  color: ${(props) => props.theme.whiteColor};

  h2 {
    font-size: ${(props) => props.theme.fontBody2};
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  p,
  a {
    color: ${(props) => props.theme.whiteColor};
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: ${(props) => props.theme.fontBody2};
  }

  .footer-link {
    > a {
      display: inline-block;
    }
  }
`;
export const SiteFooterWrapTop = styled.div`
  padding: 5rem 0;
  @media screen and (max-width: 767px) {
    padding: 2rem 0 1.5rem 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding: 3rem 0;
  }
  .footer-site-logo {
    width: 3rem;
    height: 3rem;
    margin-right: 3.5rem;
    margin-bottom: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    @media screen and (max-width: 1439px) {
      width: 2rem;
      height: 2rem;
      margin-right: 2rem;
    }

    &.mobile-footer-logo {
      @media screen and (max-width: 767px) {
        display: block;
      }
      display: none;
    }
    &.tablet-desktop {
      display: block;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
`;
export const SiteFooterWrapBottom = styled.div`
  font-size: ${(props) => props.theme.fontBody3};
  background-color: ${(props) => props.theme.uiBlack};

  .footer-secondary-links {
    margin-left: 2.5rem;
    @media screen and (max-width: 767px) {
      margin: 0 0.375rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-left: 1rem;
      &:first-of-type {
        margin-left: 0;
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      margin-left: 1.5rem;
    }

    a {
      @media screen and (max-width: 1023px) {
        font-size: ${(props) => props.theme.fontBody3};
        line-height: ${(props) => props.theme.lineHeight3};
      }
    }
  }

  .footer-bottom-inner-column {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      &.bottom-links {
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        margin: 1.5rem auto 0;
        flex-wrap: wrap;
      }
    }
  }

  .copyright-desktop {
    display: none;
    @media screen and (min-width: 1440px) {
      display: block;
      margin-left: 2.5rem;
      line-height: ${(props) => props.theme.lineHeight3};
    }
  }

  .copyright-mobile {
    display: none;
    text-align: right;
    padding-bottom: 1.5rem;
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (max-width: 1439px) {
      font-size: ${(props) => props.theme.fontLegal};
      display: block;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 1rem;
    }
  }
`;
export const Section = styled.section`
  position: relative;
  min-width: 11rem;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: block;
    h2 {
      display: block;
      margin-bottom: 0.5rem;
    }
    div {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
`;
export const UnAuthSection = styled.section`
  position: relative;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.lightGrayColor4};

  @media screen and (min-width: 768px) {
    padding-bottom: 0;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2.primary-footer-header {
    font-size: ${(props) => props.theme.headingSmall};
    line-height: ${(props) => props.theme.lineHeight2};
    margin-bottom: 0.25rem;
    @media screen and (max-width: 10234px) {
      margin-bottom: 1rem;
    }
  }
  p {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 1.5rem;
    @media screen and (min-width: 1024px) {
      margin-bottom: 2.125rem;
    }
  }
`;
export const FooterColumn = styled.div`
  ${(props) =>
    props.isLoggedIn &&
    css`
      @media screen and (max-width: 767px) {
        flex: 0 0 50%;
      }
    `};

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
  }
`;
export const JoinCTA = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.uiPurple};
  padding: 0 3.5rem 0 1rem;
  min-height: 2.75rem;
  min-width: 12.5rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody3};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${(props) => props.theme.uiWhite};
    background-image: url('/assets/arrow-right-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 44px;
    height: 44px;
    z-index: 1;
  }

  a {
    margin: 0;
  }
`;
export const FooterColumnWrapper = styled.div`
  display: flex;
  ${(props) =>
    props.isLoggedIn &&
    css`
      @media screen and (max-width: 767px) {
        flex-direction: row;
        flex: 1;
        border-bottom: 1px solid ${props.theme.lightGrayColor4};
        margin-bottom: 2rem;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        flex-direction: column;
        border-bottom: 0;
        width: 80%;
      }
    `};

  ${(props) =>
    !props.isLoggedIn &&
    css`
      @media screen and (max-width: 1023px) {
        flex-direction: column;
        border-bottom: 0;
        width: 80%;
      }
    `};

  flex-direction: row;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const FooterColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  &.isTablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      display: block;
      h2 {
        display: block;
        margin-bottom: 0.5rem;
      }
      div {
        display: inline-block;
        margin-right: 1.5rem;
      }
      border-top: 1px solid #757575;
      padding-top: 1.75rem;
      margin: 1.25rem 0 0 0;
    }
  }

  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  _flex: 0 0 50%;
  margin: 0 2rem;
  @media screen and (min-width: 1044px) {
    margin: 0 3.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 1.5rem 0;
  }
  &.bottom-divider {
    @media screen and (max-width: 767px) {
      margin: 2rem 0 2rem 0;
      border-bottom: 1px solid #757575;
      padding-bottom: 1.52rem;
    }
  }
`;
export const FooterColumnSocial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .social-links {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 8.313rem;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-right: ${(props) => (props.isLoggedIn && props.languageCode === 'en-ca' ? '5.5rem' : '2.5rem')};
    }
  }

  .app-store-links {
    display: flex;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      flex-direction: column;
    }

    .image-wrapper {
      position: relative;
      height: 2.5rem;
      max-width: 8.5rem;
      a {
        display: block;
        height: 2.5rem;
        width: inherit;
      }

      img {
        min-height: 2.5rem;
        height: 2.5rem;
        width: 100%;
      }
      &:first-of-type {
        margin-right: 1.25rem;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    align-items: baseline;
    justify-content: flex-start;
  }
`;
