/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs/utils';

import { TermsContentStyles, TermsContentInner } from './TermsStyles';

export default function TermsComponent({ termsBlob, scrollable, termsAndConditionsLabel }) {
  if (termsBlob) {
    return (
      <TermsContentStyles>
        <div>
          {termsAndConditionsLabel && <strong>{termsAndConditionsLabel}</strong>}
          <TermsContentInner scrollable={scrollable}>
            <div dangerouslySetInnerHTML={renderHTML(termsBlob)} />
          </TermsContentInner>
        </div>
      </TermsContentStyles>
    );
  }
  return null;
}

TermsComponent.propTypes = {
  termsAndConditionsLabel: PropTypes.string,
  termsBlob: PropTypes.string.isRequired,
  scrollable: PropTypes.bool,
};
