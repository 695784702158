import Title from '../common/Headings/Title';

import useHasMount from '../hooks/utils/useHasMount';
import { WrapperLayoutContainer } from './WrapperLayoutStyles';

export default function WrapperLayout({
  children,
  title,
  className,
  isGray,
  isBlack,
  isWhite,
  isLightBlack,
  mobileIsWhite,
  mobileNoPadding,
  fullMinHeight,
  customStyles = false,
}) {
  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  return (
    <WrapperLayoutContainer
      fullMinHeight={fullMinHeight}
      isGray={isGray}
      isBlack={isBlack}
      isWhite={isWhite}
      isLightBlack={isLightBlack}
      mobileIsWhite={mobileIsWhite}
      mobileNoPadding={mobileNoPadding}
      customStyles={customStyles}
      className="wrapper-layout-container"
    >
      <div className={className}>
        {title && <Title>{title}</Title>}

        {children && <div className="wrapper-layout-content">{children}</div>}
      </div>
    </WrapperLayoutContainer>
  );
}
