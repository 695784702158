import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import ListCircleImages from '../common/List/ListCircleImages';
import WrapperLayout from '../layouts/WrapperLayout';

// Helpers
import { deriveListByProvince } from '../../helpers/utils';

const RedeemCategoriesByProvince = ({ customData, languageCode, module: { fields } }) => {
  const {
    state: {
      location,
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn && fields?.isLoggedIn === 'true' && customData?.categories) {
    const categories = deriveListByProvince(customData?.categories, location?.province);
    return (
      <WrapperLayout className="global-content-wrapper" isBlack customStyles="redeemCategories">
        <ListCircleImages data={categories} languageCode={languageCode} />
      </WrapperLayout>
    );
  }
  return null;
};

RedeemCategoriesByProvince.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'redeemcategoriescontentv2',
      languageCode,
    });

    let contentItem = contentItemList[0];
    contentItem = await expandLinkedList({
      agility,
      contentItem,
      languageCode,
      fieldName: 'listingRedeemCategories',
      sortIDField: 'listingRedeemCategories_SortIdField',
    });

    return {
      categories:
        (contentItem.fields.listingRedeemCategories &&
          contentItem.fields.listingRedeemCategories.map((item) => ({
            title: item.fields.title,
            imageSrc: item.fields.categoryImage.url,
            link: item.fields.categoryLink.href,
            linkText: item.fields.categoryLink.text,
            provinces: item.fields.provinces_TextField || '',
          }))) ||
        null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

RedeemCategoriesByProvince.propTypes = { customData: PropTypes.object, languageCode: PropTypes.string, module: PropTypes.object };

export default RedeemCategoriesByProvince;
