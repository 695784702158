import { useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import { getCookie, setCookie } from 'cookies-next';

const fallbackMaxAge = 60 * 60 * 24 * 365;

export default function useReactGA(expiresOn) {
  const { asPath } = useRouter();

  // Check user's choice from cookie
  const userChoice = getCookie('enable_ga');

  const setUserChoice = (choice) => {
    const options = { secure: true };

    if (choice === 'reject' && expiresOn !== 'never') {
      options.maxAge = expiresOn || fallbackMaxAge;
    }

    setCookie('enable_ga', choice, options);
  };

  useEffect(() => {
    if (userChoice === 'accept') {
      if (!window?.GA_TAG_MANAGER_INITIALIZED) {
        TagManager.initialize({
          gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
          auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
          preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
        });
        window.GA_TAG_MANAGER_INITIALIZED = true;
      }

      if (!window?.GA_INITIALIZED) {
        ReactGA.initialize(process.env.NEXT_PUBLIC_GA_UNIVERSAL);
        window.GA_INITIALIZED = true;
      }

      if (window?.GA_TAG_MANAGER_INITIALIZED && window?.GA_INITIALIZED) {
        ReactGA.set({ page: asPath });
        ReactGA.pageview(asPath);
      }
    }
  }, [asPath, userChoice]);

  return {
    userChoice,
    setUserChoice,
  };
}
