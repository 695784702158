import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components

import DownloadApp from '../common/Sections/DownloadApp';

const SharedDownloadAppSection = ({ module, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return <DownloadApp fields={fields} languageCode={languageCode} />;
  }

  return null;
};

SharedDownloadAppSection.propTypes = { module: PropTypes.object, languageCode: PropTypes.string };

export default SharedDownloadAppSection;
