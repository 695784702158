import { useQuery } from 'react-query';
import { getGenders } from '../../../pages/api/services';

export default function useGenders(languageCode) {
  const { isLoading, error, data } = useQuery(
    ['fetchGenders', languageCode],
    async () => {
      try {
        if (languageCode) {
          const res = await getGenders(languageCode);
          return res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    { staleTime: 50 * (60 * 1000), cacheTime: 60 * (60 * 1000) }
  );

  return { isLoading, error, genders: data };
}
