import { useStateMachine } from 'little-state-machine';

// Components

import GoBackButton from '../GoBackButton';
import StepIndicator from './StepIndicator';

import { FormHeaderWrapper, FormRegisterTitle } from '../FormStyles';

export default function FormHeader({ step, title, backButton }) {
  const { state } = useStateMachine();

  if (step) {
    return (
      <FormHeaderWrapper>
        {state.user.step > 1 && <GoBackButton backButton={backButton} />}
        <StepIndicator step={step} />

        {title && <FormRegisterTitle>{title}</FormRegisterTitle>}
      </FormHeaderWrapper>
    );
  }
  return null;
}
