// State
import { useStateMachine } from 'little-state-machine';

import { LoaderWrapper, LoadingRing } from './LoaderStyles';

export default function Loader({ className, reverse, hideText = false, id = null, ...props }) {
  const {
    state: { labels },
  } = useStateMachine();

  const { text, width } = props;

  return (
    <LoaderWrapper className={className} style={{ width: width || '100%' }} reverse={reverse} data-testid="loader-wrapper">
      <LoadingRing reverse={reverse} data-testid="loading-ring">
        <div />
        <div />
        <div />
        <div />
      </LoadingRing>
      {!hideText && <span id={id}>{text || labels?.loadingLabel}</span>}
    </LoaderWrapper>
  );
}
