/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

function AccordionBody({ page, content, languageCode }) {
  if (page === 'FAQ') {
    let labelA = 'A: ';

    if (languageCode === 'fr-ca') {
      labelA = 'A : ';
    }
    return (
      <div className="relative">
        <p className="a-label font-bold absolute">{labelA}</p>
        <div className="text-indent" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}

AccordionBody.propTypes = {
  page: PropTypes.string,
  content: PropTypes.string,
  languageCode: PropTypes.string,
};

export default AccordionBody;
