import PropTypes from 'prop-types';

import { StatusWrapper } from './StatusStyles';

export default function ShippingStatus({ status }) {
  return <StatusWrapper status={status}>{status}</StatusWrapper>;
}

ShippingStatus.propTypes = {
  status: PropTypes.string,
};
