import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getAnnouncementDetail } from '../../../pages/api/services';

export default function useAnnouncementDetail(languageCode, code, linkedType) {
  const {
    state: {
      session: { isLoggedIn, accessToken },
    },
  } = useStateMachine();

  const { isLoading, error, data } = useQuery(
    ['fetchAnnouncementDetail', accessToken, languageCode, code],
    async () => {
      if (isLoggedIn && accessToken) {
        try {
          const res = await getAnnouncementDetail(accessToken, languageCode, code);

          return res.data.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    {
      // Disable query if linkedType is anything else other than Announcement
      enabled: linkedType === 'Announcement',
    }
  );

  return { isLoading, error, announcementDetail: data };
}
