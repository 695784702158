import { useEffect } from 'react';
import PropTypes from 'prop-types';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import OffersModal from '../../Offers/OffersModal';

// Hooks
import useOfferDetails from '../../hooks/offers/useOfferDetails';
import useOfferDetailParams from '../../hooks/offers/useOfferDetailsParams';
import useModal from '../../hooks/utils/useModal';

export default function OfferDetailsModal({ fields, languageCode }) {
  const {
    state: {
      navigation: { page, prevPage },
    },
  } = useStateMachine();

  const { isShowing, toggle } = useModal();
  const { code, token } = useOfferDetailParams();
  const { isLoading, error, offerDetails, refetch } = useOfferDetails(code, languageCode, token);

  // Conditions when we should show the modal
  useEffect(() => {
    if (
      prevPage.includes('offers#/') ||
      (page.toLowerCase().includes('home?id=ofr') && page.toLowerCase().includes('&q=')) ||
      page.toLowerCase().includes('offer=')
    ) {
      toggle();
    }
  }, [prevPage, page]);

  useEffect(() => {
    if (code && token && isShowing && !offerDetails) {
      refetch();
    }
  }, [code, token, isShowing, offerDetails]);

  return (
    <OffersModal
      toggle={toggle}
      isShowing={isShowing}
      offer={offerDetails}
      languageCode={languageCode}
      customData={fields}
      isLoading={isLoading}
      listName="Guest offer"
    />
  );
}

OfferDetailsModal.propTypes = {
  fields: PropTypes.object,
  languageCode: PropTypes.string,
};
