/* eslint-disable camelcase */
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useRouter } from 'next/router';
import { ssoLogout } from '../../b2c/b2cFunctions';
import Loader from '../common/Loader/Loader';

const SingleSignon = (props) => {
  const { instance } = useMsal();
  const router = useRouter();
  const { redirect_uri, domain_hint } = router.query;

  useEffect(() => {
    async function preSsoLogout() {
      await ssoLogout(instance);
    }

    if (domain_hint && redirect_uri) {
      preSsoLogout();
    }
  }, [redirect_uri, domain_hint]);

  return <Loader />;
};

export default SingleSignon;
