import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ImageCircle = styled.div`
  background-color: ${(props) => props.theme.uiWhite};
  position: relative;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
  margin-right: 1.25rem;
  @media screen and (max-width: 640px) {
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;
  }
`;

export const PartnerTitle = styled.h2`
  color: ${(props) => props.theme.uiBlack};
  font-size: ${(props) => props.theme.headingMedium};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight2};
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingSmall};
    margin-top: 1.25rem;
  }
`;
