import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
// Components
import ExternalImage from '../Images/ExternalImage';

// Hooks
import useDragDetection from '../../hooks/utils/useDragDetection';
import useScrollDetection from '../../hooks/utils/useScrollDetection';
import useIntersectionObserverGa from '../../hooks/utils/useIntersectionObserverGa';
// Helpers
import { handleKeyDown } from '../../../helpers/utils';
import { handleGAEventsBase, getFeaturedCardMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';
import { ItemWrapper, ItemIcon, ItemContent } from './FeaturedCardsStyles';

export default function FeaturedCard({ item, id }) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const threshold = [0.8];

  const { handleMouseDown, dragging } = useDragDetection();
  const { scrolling } = useScrollDetection();
  const { card, image, message, link } = item.fields;

  // Used for GA events
  const label = card.trim();
  const position = id;
  // eslint-disable-next-line camelcase
  const card_type = card.trim();
  const category = 'Spotlights';
  useEffect(() => {
    if (enabled && item && isIntersecting) {
      const event = 'fc_card_impression';
      // eslint-disable-next-line camelcase
      const alt_event = 'spotlight_banking_card_impression';
      const action = 'Spotlight Impressions';
      const dataLayer = getFeaturedCardMappedObject(event, alt_event, category, action, label, position, card_type);
      handleGAEventsBase(dataLayer);
      setEnabled(false);
    }
  }, [isIntersecting]);
  const handleClick = () => {
    if (link?.href) {
      window.open(link?.href, link?.target || '_blank');
      const event = 'fc_card_click';
      // eslint-disable-next-line camelcase
      const alt_event = 'spotlight_banking_card_click';
      const action = 'Spotlight Click';
      const dataLayer = getFeaturedCardMappedObject(event, alt_event, category, action, label, position, card_type);
      handleGAEventsBase(dataLayer);
    }
  };
  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    if (link?.href) {
      handleClick();
    }
  };

  function onIntersect(val) {
    if (isIntersecting !== val) setIsIntersecting(val);
  }

  const targetRef = useRef(null);
  useIntersectionObserverGa({
    target: targetRef,
    onIntersect,
    scrolling,
    enabled,
    threshold,
  });
  if (item) {
    return (
      <div id={`fc-${id}`} ref={targetRef}>
        <ItemWrapper
          {...(link?.href && {
            role: 'link',
            onClickCapture: handleChildClick,
            onMouseDownCapture: handleMouseDown,
            onKeyDown: (e) => handleKeyDown(e, handleClick),
            tabIndex: '0',
            'aria-label': `${card.trim()}`,
          })}
        >
          {image && (
            <ItemIcon>
              <ExternalImage url={image.url} height={44} width={70} />
            </ItemIcon>
          )}
          <ItemContent>
            {card && <h3 dangerouslySetInnerHTML={renderHTML(card)} />}
            {message && <div dangerouslySetInnerHTML={renderHTML(message)} />}
          </ItemContent>
        </ItemWrapper>
      </div>
    );
  }
  return null;
}

FeaturedCard.propTypes = {
  item: PropTypes.object,
  id: PropTypes.string,
};
