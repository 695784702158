import { ErrorWrapper } from './ErrorStyles';

export default function ErrorMessage({ error }) {
  if (
    error &&
    (error.type === 'required' ||
      error.type === 'pattern' ||
      error.type === 'min' ||
      error.type === 'max' ||
      error.type === 'minLength' ||
      error.type === 'maxLength' ||
      error.type === 'validate')
  ) {
    return (
      <ErrorWrapper role="alert" simplified>
        <p>{error.message}</p>
      </ErrorWrapper>
    );
  }
  return null;
}
