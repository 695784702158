import ExternalImage from '../Images/ExternalImage';

import { StyledListHome, ImageCircle, RewardTypeTitle } from './ContentGridStyles';

export default function ContentGrid3Columns({ data }) {
  if (data) {
    return (
      <StyledListHome>
        {data.map((item, index) => (
          <li key={index} className="list-none  text-center">
            <ImageCircle className="mx-auto category-circle-tile">
              {item.fields?.image?.url && (
                <ExternalImage className="object-center object-cover" url={item.fields?.image?.url} width={80} height={80} />
              )}
            </ImageCircle>
            <RewardTypeTitle className="headline-extra-small">{item.fields.title}</RewardTypeTitle>
            <p>{item.fields.description}</p>
          </li>
        ))}
      </StyledListHome>
    );
  }
  return null;
}
