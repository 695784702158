import styled from 'styled-components';

export const DatePickerDateSelectedStyle = styled.div`
  margin-bottom: 1rem;
  width: auto;
  display: inline-block;

  .points-filter-date {
    background-color: ${(props) => props.theme.whiteColor};
  }

  .rmdp-container {
    width: 100%;
  }
  .rmdp-input {
    border: 1px solid ${(props) => props.theme.lightGrayColor4};
    padding: 1.5rem 1rem;
    border-radius: 0;
    width: 100%;
  }
  .icon-tabler {
    right: 1rem !important;
  }
  .rmdp-arrow {
    border: solid ${(props) => props.theme.uiPurple};
    border-width: 2px;
    display: inline-block;
    height: 3px;
    margin-top: 5px;
    padding: 2px;
    width: 3px;
  }
  .rmdp-arrow:hover {
    background-color: ${(props) => props.theme.uiPurple};
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 3px;
    margin-top: 5px;
    padding: 2px;
    width: 3px;
  }
  .rmdp-week-day {
    color: ${(props) => props.theme.uiPurple};
    cursor: default;
    font-size: ${(props) => props.theme.fontBody3};
    font-weight: 500;
  }
  .rmdp-arrow-container:hover {
    background-color: ${(props) => props.theme.uiPurple};
    box-shadow: 0 0 3px ${(props) => props.theme.uiPurple};
  }
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    border: ${(props) => props.theme.uiPurple} solid 2px;
    background-color: white;
    color: black;
  }
  .rmdp-ym .rmdp-day span {
    border-radius: 0;
  }
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: ${(props) => props.theme.uiPurple};

    &:hover {
      color: ${(props) => props.theme.whiteColor};
      background-color: ${(props) => props.theme.uiPurple};
    }
  }

  .rmdp-header,
  .rmdp-month-name {
    font-size: ${(props) => props.theme.fontBody2};
  }

  .rmdp-day,
  .rmdp-week-day {
    width: 40px;
    height: 40px;

    span {
      font-size: ${(props) => props.theme.fontBody2};
    }

    &.rmdp-disabled {
      button {
        color: ${(props) => props.theme.lightGrayColor3} !important;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .rmdp-day.rmdp-today span {
    background-color: transparent;
  }

  .rmdp-year-picker,
  .rmdp-month-picker {
    .rmdp-today {
      span {
        border: 2px solid ${(props) => props.theme.uiGrey04};
        background-color: ${(props) => props.theme.uiGrey04};
        color: ${(props) => props.theme.whiteColor} !important;
        &:hover {
          color: ${(props) => props.theme.uiBlack} !important;
        }
      }
      &.rmdp-disabled {
        span {
          &:hover {
            color: ${(props) => props.theme.whiteColor} !important;
          }
        }
      }
    }
    .rmdp-selected {
      span {
        border: 2px solid ${(props) => props.theme.uiPurple};
        color: ${(props) => props.theme.whiteColor} !important;
        &:hover {
          color: ${(props) => props.theme.whiteColor} !important;
        }
      }
    }
  }
`;
