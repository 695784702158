const frenchLocale = {
  name: 'fr_ca_locale',
  months: [
    ['janvier', 'janv'],
    ['février', 'févr'],
    ['mars', 'mars'],
    ['avril', 'avril'],
    ['mai', 'mai'],
    ['juin', 'juin'],
    ['juillet', 'juil'],
    ['aout', 'août'],
    ['septembre', 'sept'],
    ['octobre', 'oct'],
    ['novembre', 'nov'],
    ['décembre', 'déc'],
  ],
  weekDays: [
    ['Samedi', 'Sam'],
    ['Dimanche', 'Dim'],
    ['Lundi', 'Lun'],
    ['Mardi', 'Mar'],
    ['Mercredi', 'Mer'],
    ['Jeudi', 'Jeu'],
    ['Vendredi', 'Ven'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['a.m.', 'a.m.'],
    ['p.m.', 'p.m.'],
  ],
};

export default frenchLocale;
