import { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import TabTitle from './TabTitle';
import TabContent from './TabContent';

// Helpers
import { getNewTabsArray } from '../../../helpers/tabbedHelper';

export default function TabbedComponent({ tabs, anchorId, isLarge }) {
  const [active, setActive] = useState(0);
  const tabsLength = tabs.length;
  const handleKeyDown = (e) => {
    if (e.keyCode === 39 || e.keyCode === 40) {
      if (active < tabsLength - 1) {
        document.getElementById(`tab-${active + 1}`).focus();
        setActive(active + 1);
      }
    }

    if (e.keyCode === 37 || e.keyCode === 38) {
      if (active > 0) {
        document.getElementById(`tab-${active - 1}`).focus();
        setActive(active - 1);
      }
    }
  };

  const handleClick = (e) => {
    const index = parseInt(e.target.value, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <div className="tabbed-container" id={anchorId}>
      <TabTitle
        tabs={getNewTabsArray(tabs)}
        handleClick={handleClick}
        handleKeyDown={handleKeyDown}
        active={active}
        isLarge={isLarge}
      />

      <TabContent tabs={getNewTabsArray(tabs)} active={active} isLarge={isLarge} />
    </div>
  );
}

TabbedComponent.defaultProps = {
  isLarge: false,
};

TabbedComponent.propTypes = {
  tabs: PropTypes.array.isRequired,
  anchorId: PropTypes.string,
  isLarge: PropTypes.bool,
};
