import { useEffect } from 'react';
import PropTypes from 'prop-types';

// State
import { useStateMachine } from 'little-state-machine';
import useSpotlights from '../hooks/announcements/useSpotlights';
import { updateOffers } from '../../store/actions';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import Loader from '../common/Loader/Loader';
import { FeaturedOffersTab, LoadableOffersTab, GeneralOffersTab } from './OfferTabs';

// Hooks

import useOffersSorting from '../hooks/offers/useOffersSorting';

export default function OffersTabs({ customData, languageCode, partners, bankSlider, featuredCards }) {
  const {
    actions,
    state: {
      offers: { isLoading, selectedFilters, sortBy, spotlights },
    },
  } = useStateMachine({ updateOffers });

  const { offersSort } = customData.pageContent || customData;

  const { optionsList, offerOptions } = useOffersSorting(offersSort);
  const { spotlights: spotlightsData, isLoading: spotlightsLoading, error, refetch } = useSpotlights(languageCode);

  useEffect(() => {
    if (!spotlightsLoading && spotlightsData && !spotlights.length) {
      actions.updateOffers(spotlightsData);
      refetch();
    }
  }, [spotlights, spotlightsLoading]);

  if ((isLoading && !optionsList && !offerOptions && !sortBy?.value) || !selectedFilters) {
    return (
      <WrapperLayout className="global-content-wrapper mb-8">
        <Loader />
      </WrapperLayout>
    );
  }

  switch (selectedFilters) {
    case 'featured':
      return (
        <FeaturedOffersTab customData={customData} languageCode={languageCode} partners={partners} bankSlider={bankSlider} />
      );
    case 'loadable':
      return <LoadableOffersTab customData={customData} languageCode={languageCode} offerOptions={offerOptions} />;

    default:
      return (
        <GeneralOffersTab
          customData={customData}
          languageCode={languageCode}
          offerOptions={offerOptions}
          featuredCards={featuredCards}
        />
      );
  }
}

OffersTabs.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  partners: PropTypes.object,
  bankSlider: PropTypes.object,
  featuredCards: PropTypes.object,
};
