import PropTypes from 'prop-types';

import Loader from '../../../common/Loader/Loader';
import GuestSettingsEmailCheckbox from './GuestSettingsEmailCheckbox';

import { TopicListStyles } from '../../FormStyles';

import { checkArrayForItems } from '../../../../helpers/utils';
import { setDefaultCheckboxSettings } from '../../../../helpers/settingsHelper';

export default function GuestSettingsTopics({ emailTopics, disableOptIn, guestPreferencesData }) {
  if (!checkArrayForItems(guestPreferencesData)) return <Loader />;

  return (
    <TopicListStyles>
      {emailTopics &&
        emailTopics.map((topic) => (
          <GuestSettingsEmailCheckbox
            key={topic.contentID}
            className="mb-4"
            fieldType="checkbox"
            label={topic.fields.name}
            name={topic.fields.code.toUpperCase()}
            defaultChecked={setDefaultCheckboxSettings(topic, guestPreferencesData)}
            disableOptIn={disableOptIn}
          />
        ))}
    </TopicListStyles>
  );
}

GuestSettingsTopics.propTypes = {
  emailTopics: PropTypes.array,
  disableOptIn: PropTypes.bool,
  guestPreferencesData: PropTypes.array,
};
