/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateInlineMessage(state, payload) {
  return {
    ...state,
    inlineMessage: {
      ...state.inlineMessage,
      ...payload,
    },
  };
}
