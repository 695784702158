/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { CheckboxWrapper, StyledCheckbox } from '../../FormStyles';
import { enableEmailField, getAllKeysFromList } from '../../../../helpers/settingsHelper';

function SettingsEmailCommunicationCheckbox({
  className,
  name,
  label,
  fieldType,
  rules,
  extraText,
  isDisabled,
  defaultChecked,
  alignStart,
  preferencesData,
}) {
  const [checkbox, setCheckbox] = useState(false);

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, setValue, getValues } = formContext;

  // Detect the state of each checkbox and evaluate whether all is checked or not
  const topicValuesList = getAllKeysFromList(preferencesData);
  const topicValue = topicValuesList && topicValuesList.map((item) => getValues(item));
  const topicValueBool = topicValue.every((val) => !val);

  useEffect(() => {
    setValue(name, checkbox || defaultChecked, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox, defaultChecked]);

  useEffect(() => {
    if (enableEmailField(preferencesData)) {
      setValue(name, true);
    }

    // Set value to false if all checkboxes are ticked off, else keep value ticked
    if (topicValueBool) {
      setValue(name, false);
    } else {
      setValue(name, true);
    }
  }, [topicValue]);

  const handleClick = () => {
    if (preferencesData) {
      if (getValues(name) === false || getValues(name) === undefined) {
        preferencesData.forEach((preferences) => setValue(preferences.code, true));
      } else {
        preferencesData.forEach((preferences) => setValue(preferences.code, false));
      }
    }
  };

  return (
    <CheckboxWrapper className={`${isDisabled ? 'isDisabled' : ''}`} alignStart={alignStart}>
      <StyledCheckbox
        settingEmail
        name={name}
        id={name}
        type={fieldType}
        role="checkbox"
        aria-labelledby={`form-${name}`}
        aria-checked={!!defaultChecked}
        aria-disabled={isDisabled}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
        className={getValues(name) ? 'pressed' : ''}
        {...register(name, { ...rules })}
        onClick={handleClick}
      />
      <div>
        {Boolean(label) && (
          <label id={`form-${name}`} htmlFor={name} className={`${isDisabled ? 'isDisabled' : ''} ${className}`}>
            {label}
          </label>
        )}
        {extraText && <p>{extraText}</p>}
      </div>
    </CheckboxWrapper>
  );
}

SettingsEmailCommunicationCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  extraText: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  alignStart: PropTypes.bool,
  preferencesData: PropTypes.array,
};

export default SettingsEmailCommunicationCheckbox;
