import ExternalImage from '../Images/ExternalImage';
import { IconColumn } from './LogoDescriptionSectionStyles';

export default function LogoContainer({ data }) {
  if (data.length) {
    return data.map((item, index) => (
      <IconColumn key={index} className={data.length === 1 ? 'single-logo' : 'multiple-logos'}>
        <a href={item.link.href} target="_blank" aria-label={item.link.text} rel="noopener noreferrer">
          <ExternalImage url={item.image.url} height={data.length === 1 ? 160 : 120} width={data.length === 1 ? 160 : 120} />
        </a>
      </IconColumn>
    ));
  }

  return null;
}
