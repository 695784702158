/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';

import { CheckboxWrapper, StyledCheckbox } from '../FormStyles';

function FormSMSCheckbox({
  name,
  label,
  className,
  fieldType,
  rules,
  extraText,
  isDisabled,
  defaultChecked,
  alignStart,
  status,
}) {
  const { actions, state } = useStateMachine({ updateUser });
  const [checkbox, setCheckbox] = useState(false);

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, control, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, checkbox || defaultChecked, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: checkbox });
  }, [checkbox]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  return (
    <>
      <CheckboxWrapper
        textMessagesCheckbox="true"
        className={`${isDisabled ? 'isDisabled' : ''}`}
        alignStart={alignStart}
        status={status}
      >
        <StyledCheckbox
          name={name}
          id={name}
          type={fieldType}
          aria-invalid={!!error}
          role="checkbox"
          aria-labelledby={`form-${name}`}
          aria-checked={defaultChecked ? 'true' : 'false'}
          aria-disabled={isDisabled}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          className={getValues(name) ? 'pressed' : ''}
          {...register(name, { ...rules })}
          isError={Boolean(isError)}
        />
        <div>
          {Boolean(label) && (
            <label id={`form-${name}`} htmlFor={name} className={`${isDisabled ? 'isDisabled' : ''} ${className || ''}`}>
              {label}
            </label>
          )}
          {extraText && <p>{extraText}</p>}
        </div>
      </CheckboxWrapper>
      {isError && <ErrorMessage error={error} />}
    </>
  );
}

FormSMSCheckbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  extraText: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  alignStart: PropTypes.bool,
  status: PropTypes.string,
  name: PropTypes.string,
};

export default FormSMSCheckbox;
