import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Hooks
import useScript from '../hooks/utils/useScript';

const Zendesk = ({ module }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const showZendesk =
    ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) &&
    process.env.NEXT_PUBLIC_ZENDESK_DISABLED === 'false';

  // Mount Zendesk when we are able to show FAQ Sections
  const src = showZendesk ? process.env.NEXT_PUBLIC_ZENDESK_URI : null;
  const options = { id: 'ze-snippet' };

  // we can use the status to determine when to show the FAQ
  useScript(src, options);

  return null;
};

Zendesk.propTypes = { module: PropTypes.object };

export default Zendesk;
