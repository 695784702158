import styled from 'styled-components';

export const OptionsButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  width: 48px;
  height: 48px;
  padding: 0;
  overflow: hidden;
  margin-right: 0.6rem;

  &:last-of-type {
    margin-right: 0;
  }
`;
export const AddressModalHeader = styled.div`
  padding: 1.5rem 0;
`;
