import { getUniqueListBy, addSortingIndexToObjList, sortByKey } from './utils';

export function generateCategoriesFrom(categoryList = []) {
  if (categoryList.length) {
    return categoryList.map((c) => c.fields.code);
  }
}

export function generateFilteredListFrom(giftCardList = [], filter) {
  if (giftCardList.length && filter) {
    return giftCardList.filter(({ cmsContent }) => {
      if (filter === 'FEATURED') {
        return cmsContent.featured;
      }
      return cmsContent.category ? cmsContent.category.code === filter : null;
    });
  }
}

export function deriveFiltersArrayFrom(filtersMap) {
  const derivedFiltersArray = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in filtersMap) {
    if (Object.hasOwnProperty.call(filtersMap, key)) {
      const element = filtersMap[key];
      derivedFiltersArray.push(element);
    }
  }

  return derivedFiltersArray.filter((filter) => filter.selected);
}

export function deriveListFrom(filteredList, giftCardsList, maxNum) {
  let offersNum;
  let list;
  if (filteredList) {
    offersNum = giftCardsList.length > maxNum ? maxNum : giftCardsList.length;
    list = filteredList?.slice(0, offersNum);

    return { offersNum, list };
  }

  offersNum = giftCardsList.length > maxNum ? maxNum : giftCardsList.length;
  list = giftCardsList?.slice(0, offersNum);
  return { offersNum, list };
}

export function generatePaginatedGiftCardListFrom(giftCardsData = { pages: [{}] }) {
  const gcPageList = giftCardsData && giftCardsData?.pages?.map((page) => page?.giftCards);

  let aggregatedList;

  // check if we have more than one page
  if (gcPageList?.length > 1) {
    aggregatedList = gcPageList.flat();
  } else if (gcPageList?.length === 1) {
    aggregatedList = gcPageList[0];
  } else {
    aggregatedList = [];
  }

  // add agility sequence to each gcard
  if (aggregatedList?.length) {
    return addSortingIndexToObjList(aggregatedList);
  }

  // dont show any giftcards when pages = 0
  return aggregatedList;
}

export function aggregateFilteredListFrom(giftCardList = [], filtersMap, giftCardsData) {
  // consolidate the pages into one array

  const aggregatedList = generatePaginatedGiftCardListFrom(giftCardsData);

  if (giftCardList.length && Object.keys(filtersMap).length) {
    const filtersArray = deriveFiltersArrayFrom(filtersMap);

    // only create a subset list if filters are chosen, otherwise early return with no sublist
    if (filtersArray.length) {
      const aggregateFiltersArray = [];

      filtersArray.forEach((filter) => {
        aggregateFiltersArray.push(generateFilteredListFrom(aggregatedList, filter.code));
      });

      return sortByKey(getUniqueListBy(aggregateFiltersArray.flat(), 'brandId'), 'sequence');
    }

    return null;
  }
}

export function findSelectedGiftCard(giftCardList = [], brandId) {
  if (giftCardList.length) {
    return giftCardList.find((card) => card.brandId === brandId);
  }
}

export function deriveTransactionsFrom(giftCards = []) {
  // Only proceed with looping if there are giftcard options available
  if (giftCards.length) {
    return giftCards.map((giftCard) => ({
      dollarValue: giftCard.attributes[0].option.value.replace('$', ''),
      name: giftCard.name,
      points: giftCard.value,
      code: giftCard.attributes[0].option.code,
    }));
  }

  return [];
}
export function findAvailableGiftCards(arr) {
  let availableGiftCards = [];
  if (arr.length) {
    arr.forEach((element) => {
      availableGiftCards = [...availableGiftCards, ...element.giftCards];
    });
  }
  return availableGiftCards;
}

export function deriveCardTypeOptionsFrom({ format = '', giftCardDetails = {} }) {
  let availableGiftCards = [];

  if (giftCardDetails.brandId) {
    if (format === 'E-Card' && giftCardDetails.digital?.length) {
      availableGiftCards = findAvailableGiftCards(giftCardDetails.digital);
    } else if (format === 'Physical' && giftCardDetails.physical?.length) {
      availableGiftCards = findAvailableGiftCards(giftCardDetails.physical);
    }
  }
  return availableGiftCards;
}

export function determinePointsOptionsFrom({ format = '', giftCardDetails }) {
  let availableGiftCards = [];
  if (format === 'E-Card' && giftCardDetails?.digital?.length) {
    availableGiftCards = findAvailableGiftCards(giftCardDetails.digital);
  } else if (format === 'Physical' && giftCardDetails?.physical?.length) {
    availableGiftCards = findAvailableGiftCards(giftCardDetails.physical);
  }

  return deriveTransactionsFrom(availableGiftCards);
}

export function isFormatDisabled({ format, giftCardDetails }) {
  if (giftCardDetails.brandId) {
    if (format === 'E-Card') {
      return !giftCardDetails.digital.length;
    }

    return !giftCardDetails[format.toLowerCase()].length;
  }
}

export function deriveFormatFrom(digitalButtonLabel, physicalButtonLabel, format) {
  if (format === 'E-Card') {
    return digitalButtonLabel;
  }
  if (format === 'Physical') {
    return physicalButtonLabel;
  }
}

export function giftCardTypeLabelFrom(format = '', digitalCardLabel, physicalCardLabel) {
  if (format === 'E-Card') {
    return digitalCardLabel;
  }
  if (format === 'Physical') {
    return physicalCardLabel;
  }
  return format;
}

export function buildGiftCardOrderBodyFrom({ added = [], format = '', giftCard = {}, email = '', quantity, address }) {
  if (added.length && format && giftCard.brandId && email && quantity) {
    const giftCardType = deriveCardTypeOptionsFrom({ format, giftCardDetails: giftCard });
    const { points, transactionName, position } = added[0];
    const { productId, sku, locale } = giftCardType[position];
    const shippingAddress =
      format === 'Physical' && address
        ? {
            line1: address.addressLine1,
            line2: address.addressLine2,
            line3: '',
            city: address.city,
            province: address.provinceCode,
            country: address.countryCode,
            postalCode: address.postalCode,
          }
        : null;

    return {
      customerEmail: email,
      language: locale,
      items: [
        {
          Amount: points,
          Description: transactionName,
          ProductId: productId,
          Sku: sku,
          Quantity: quantity,
        },
      ],
      shippingAddress,
    };
  }
  throw new Error('Missing required order details');
}

export function primaryShippingAddress(addresses = []) {
  const primaryAddress = addresses && addresses.find((addressList) => addressList.primary === true);

  return primaryAddress;
}

export function gaExternalSku(added = [], selectedFormat, giftCardDetails) {
  if (added?.length) {
    const { position } = added[0];
    const giftCardType = deriveCardTypeOptionsFrom({ format: selectedFormat, giftCardDetails });
    if (giftCardType.length > position) {
      const [sku0] = giftCardType[position].sku.split('-');

      return sku0;
    }
  }

  return null;
}
