import { useState, useEffect } from 'react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useStateMachine } from 'little-state-machine';

// Global State
import { updateCustomer, updateUser, updateTransactions, updateNavigation, updateSession, clear } from '../../../store/actions';

// Services
import { getAccounts, getCustomer, getBarcode, getCustomerPoints } from '../../../pages/api/services';

// Helpers
import { setSessionManager } from '../../../helpers/sessionHelpers';
import { generateTransactionCards } from '../../../helpers/pointsHelpers';
import { handleGALoginEvent } from '../../../helpers/handleGoogleAnalyticsHelper';
import useStorage from '../navigation/useStorage';

export default function useAuthentication() {
  const { setItem, getItem } = useStorage();
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = useState(null);
  const { actions, state } = useStateMachine({
    updateUser,
    updateCustomer,
    updateTransactions,
    updateNavigation,
    clear,
    updateSession,
  });

  const setCustomer = async (accessToken) => {
    try {
      if (accessToken) {
        const res = await getCustomer(accessToken);
        const { customer } = res.data.data;

        actions.updateCustomer({ data: customer });
      }
    } catch (error) {
      // TODO: Handle error or ignore?
      console.error('Failed to load Customer:', error);
    }
  };

  const setAccounts = async ({ accessToken, CAChallengeIsMfa, idpUserId, correlationId, idp }) => {
    try {
      const res = await getAccounts(accessToken);

      const customerAccounts = res.data.data?.filter((a) => a.bns !== null);

      const cards = generateTransactionCards(customerAccounts);

      // Emit userLogin GA event
      if (res?.data?.data[0]?.uniqueId) {
        const eventData = {
          userData: { uuid: res?.data?.data[0]?.uniqueId, idpUserId },
          correlationId,
          CAChallengeIsMfa,
          idp: idp ? 'scotia' : 'local',
        };
        handleGALoginEvent('userLogin', eventData);
      }

      actions.updateTransactions({
        cards,
        selectedCard: cards.length ? cards[0] : {},
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setPointsBalance = async (accessToken) => {
    try {
      if (accessToken) {
        const res = await getCustomerPoints(accessToken);
        const balance = res.data.data.customer.points;
        actions.updateTransactions({ balance });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setBarcode = async (accessToken) => {
    try {
      const res = await getBarcode(accessToken);

      const barcodeImageBase64 = res.data.data?.barcode;
      actions.updateUser({ barcodeImageBase64 });
    } catch (error) {
      console.error(error);
    }
  };

  const isSignInRequest = () => {
    const hasStateParam = state.navigation.hashParams?.state;
    if (typeof hasStateParam !== 'undefined') {
      return true;
    }

    return false;
  };

  const handleAuthenticateUser = () => {
    // const ssoInProgress = localStorage.getItem('sso_in_progress') === 'true';
    // if (ssoInProgress) {
    //   localStorage.removeItem('sso_in_progress');
    //   b2cSignInPromptLogin(instance);
    // }
    // else {
    if (inProgress === 'login' || inProgress === 'logout') {
      actions.updateSession({ signInProgress: true });
    } else {
      actions.updateSession({ signInProgress: false });
    }

    if (!token && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: [`${process.env.NEXT_PUBLIC_SCOPE_URL}/User.Read`],
        account: accounts[0],
      };
      const timeoutInprogress = getItem('timeout_in_progress') === 'true';
      const signInRequest = isSignInRequest();
      // only acquire or renew token of its a sign in request and time out is not in progress
      if (!timeoutInprogress || signInRequest) {
        // TODO: CONSIDER HANDLE PROMISE REDIRECT INSTEAD
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            const {
              accessToken,
              correlationId,
              idTokenClaims: { CAChallengeIsMfa, idp },
              uniqueId: idpUserId,
            } = accessTokenResponse;

            // only set the token if it has been changed or not logged in
            if (accessToken !== state?.session?.accessToken) {
              setBarcode(accessToken);
              setAccounts({ accessToken, CAChallengeIsMfa: !!CAChallengeIsMfa, correlationId, idpUserId, idp: !!idp });
              setCustomer(accessToken);
              setPointsBalance(accessToken);
              setToken(accessToken);

              setSessionManager(accessTokenResponse, actions.updateSession);
            }
          })
          .catch((error) => {
            if (
              error instanceof InteractionRequiredAuthError ||
              // error.errorCode === 'no_tokens_found' ||
              error.errorCode === 'silent_sso_error' ||
              error.errorCode === 'consent_required' ||
              error.errorCode === 'interaction_required' ||
              error.errorCode === 'login_required'
              // || error.errorCode === 'no_account_error'   // TODO: include this if we want to redirect to login page after a logout
            ) {
              // handle the logout with a state reset
              // if (error.errorCode === 'no_account_error') {
              //   actions.clear();
              // }
              instance.acquireTokenRedirect(accessTokenRequest);

              // TODO: Revisit this or remove it??
              // instance.acquireTokenRedirect(accessTokenRequest).then(function (accessTokenResponse) {
              //   // Acquire token interactive success
              //   let accessToken = accessTokenResponse.accessToken;

              //   setToken(accessToken)
              //   actions.updateUser({ accessToken: accessToken });
              // }).catch(function (error) {
              //   // Acquire token interactive failure
              //   console.error(error);
              // });
            }

            // TODO: temporary??? we should clear state when user logs out
            if (error.errorCode === 'no_account_error') {
              actions.clear(); // TODO: this doesnt make sense at the moment. no account error occurs when not logged into b2c ie: no acnt for msal lib to pull
            }

            actions.updateNavigation({ isReady: true });
            console.error('authentication error: ', error);
          });
      }
    }
    // }

    // once we have loaded accounts and confirmed the token is fetched,
    // we will save this as logged in
    if (accounts.length > 0 && token) {
      actions.updateSession({ isLoggedIn: accounts.length > 0 && Boolean(token) });
      actions.updateNavigation({ isReady: true });
      setItem('timeout_in_progress', false);
    }
  };

  useEffect(handleAuthenticateUser, [instance, accounts, inProgress, token]);

  return token;
}
