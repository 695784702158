import PropTypes from 'prop-types';

// Hooks
import useModal from '../../hooks/utils/useModal';

// Components
import PortalModal from '../../common/Modals/PortalModal';
import FilterModal from '../../common/Modals/FilterModal/FilterModal';
import PointsHistoryFilterButton from './PointsFilter/PointsFilterButtons/PointsHistoryFilterButton';

import { RecentTransactionsHeaderWrapper, GridTitle } from './PointsHistoryStyles';
import PointsHistoryFilter from './PointsFilter/PointsHistoryFilter';

export default function RecentTransactionsHeader({ customData, languageCode }) {
  const { filterLabel, recentTransactionsLabel } = customData;

  const { isShowing, toggle } = useModal();

  const handleClick = () => {
    toggle();
  };

  return (
    <div>
      <RecentTransactionsHeaderWrapper>
        <div className="recent-transactions-bar">
          {recentTransactionsLabel && <GridTitle>{recentTransactionsLabel}</GridTitle>}

          <PointsHistoryFilterButton filterLabel={filterLabel} isShowing handleClick={handleClick} />
        </div>
      </RecentTransactionsHeaderWrapper>

      <PortalModal isShowing={isShowing} toggle={toggle} style={{ padding: 0 }}>
        <FilterModal toggle={toggle} customData={customData} type="points">
          {(applyFilter, resetFilter) => (
            <PointsHistoryFilter
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              customData={customData}
              languageCode={languageCode}
            />
          )}
        </FilterModal>
      </PortalModal>
    </div>
  );
}

RecentTransactionsHeader.propTypes = { customData: PropTypes.object, languageCode: PropTypes.string };
