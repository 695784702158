import { useEffect, useState } from 'react';
import { sortedOffersList } from '../../../helpers/offersHelpers';

export default function useSortedOffersList(offersList, sortBy) {
  const [sortedList, setSortedList] = useState(null);

  useEffect(() => {
    if (offersList && offersList.length && sortBy?.value) {
      setSortedList(sortedOffersList(offersList, sortBy.value));
    } else {
      setSortedList(null);
    }
  }, [sortBy, offersList]);

  return sortedList;
}
