import { StyledButtonSimple } from './ButtonStyles';

const ButtonSimple = ({
  type,
  children,
  onClick,
  disabled,
  style,
  className,
  ariaLabel,
  role,
  ariaLabelledBy,
  ariaControls,
  ariaExpanded,
}) => (
  <StyledButtonSimple
    type={type}
    className={`${className ? `${className} ` : ''}w-full inline-block`}
    onClick={onClick}
    disabled={disabled}
    style={style}
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    aria-controls={ariaControls}
    aria-expanded={ariaExpanded}
    role={role}
  >
    {children}
  </StyledButtonSimple>
);

export default ButtonSimple;
