import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

// State
import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';
import { clear, updateSession } from '../../../store/actions';
// B2c
import { b2cSignInWithRedirectUrl } from '../../../b2c/b2cFunctions';
import { setSessionManager } from '../../../helpers/sessionHelpers';
import useStorage from '../navigation/useStorage';

export default function useSessionTimeout(languageCode) {
  const { instance, accounts } = useMsal();
  const { actions, state } = useStateMachine({ clear, updateSession });
  const { setItem, getItem } = useStorage();
  const router = useRouter();

  const handleSessionState = () => {
    setItem('from_url', router.asPath);
    setItem('timeout_in_progress', true);
  };

  const handleTimeout = () => {
    // Reset application Global state
    actions.clear();

    if (typeof window !== 'undefined') {
      handleSessionState();
      b2cSignInWithRedirectUrl(instance, languageCode, `${process.env.NEXT_PUBLIC_REDIRECT_URI}`);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleTokenRefresh = async () => {
    const accessTokenRequest = {
      scopes: [`${process.env.NEXT_PUBLIC_SCOPE_URL}/User.Read`],
      account: accounts[0],
    };

    try {
      const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      const { accessToken } = tokenResponse;

      if (accessToken !== state.session.accessToken) {
        setSessionManager(tokenResponse, actions.updateSession);
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Initiate the b2c login redirect w/ a redirectURI set as origin and fallback to interaction when silent call fails
        return handleTimeout();
      }
    }
  };

  useEffect(() => {
    // only when user is logged in do we want to check for expired token
    const timeoutInProgress = getItem('timeout_in_progress') === 'true';
    if (accounts?.length && !timeoutInProgress) {
      const [
        {
          idTokenClaims: { exp },
        },
      ] = accounts;

      const unixEpcohInMiliseconds = exp * 1000;
      const expiryDate = new Date(unixEpcohInMiliseconds);
      const sessionTimedOut = expiryDate < Date.now();

      if (sessionTimedOut) {
        // handleTokenRefresh();   #TODO enable with KMSI
        handleTimeout();
      }
    }
  });
}
