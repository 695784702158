/* eslint-disable jsx-a11y/interactive-supports-focus */

import PropTypes from 'prop-types';

import Link from 'next/link';
import { useStateMachine } from 'little-state-machine';

import { updateOrder } from '../../../store/actions';
import useOrders from '../../hooks/orders/useOrders';

import { todayDate, fromDateByNumDay } from '../../../helpers/dateHelpers';
import { handleGAEvents } from '../../../helpers/handleGoogleAnalyticsHelper';

import EmptyOrder from '../EmptyOrder';
import Loader from '../../common/Loader/Loader';

import { RecentOrdersListStyle, RecentOrdersHeader, RecentOrdersContainer } from '../OrderStyles';
import OrderItems from '../OrdersList/OrderItems';

export default function RecentOrdersList({ customData, limit, filterByDays, title, languageCode, viewAllLink, viewAllLabel }) {
  // Global State
  const {
    state: {
      session: { accessToken },
    },
  } = useStateMachine({ updateOrder });

  const { awardsTypes, ordersContent } = customData;

  const { data, isLoading } = useOrders(accessToken, fromDateByNumDay(todayDate, filterByDays), todayDate, limit);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleGAEvents('View all orders', 'Redeem', 'Order History Click', 'redeem_order_history_click');
    }
  };

  if (isLoading) return <Loader />;

  return (
    <RecentOrdersContainer>
      <RecentOrdersHeader>
        {title && <h2>{title}</h2>}
        {data &&
        data?.pages &&
        data?.pages?.length &&
        data?.pages[0]?.items &&
        data?.pages[0]?.items?.length &&
        viewAllLabel &&
        viewAllLink?.href ? (
          <Link
            href={viewAllLink.href}
            locale={languageCode}
            aria-label={viewAllLink.text}
            role="link"
            onClick={() => {
              handleGAEvents('View all orders', 'Redeem', 'Order History Click', 'redeem_order_history_click');
            }}
            onKeyDown={handleKeyDown}
          >
            {viewAllLabel}
          </Link>
        ) : null}
      </RecentOrdersHeader>

      {data &&
      data?.pages &&
      data?.pages?.length &&
      data?.pages[0]?.items &&
      data?.pages[0]?.items?.length &&
      data?.pages?.length ? (
        data?.pages.map((page, index) => (
          <div key={index}>
            {page?.items?.length > 0 && (
              <RecentOrdersListStyle>
                <OrderItems
                  key={index}
                  page={page}
                  sectionTitle={title}
                  awardsTypes={awardsTypes}
                  ordersContent={ordersContent}
                  languageCode={languageCode}
                  isRecentOrdersList
                />
              </RecentOrdersListStyle>
            )}
          </div>
        ))
      ) : (
        <EmptyOrder ordersContent={ordersContent} recentOrders />
      )}
    </RecentOrdersContainer>
  );
}

RecentOrdersList.propTypes = {
  customData: PropTypes.object,
  limit: PropTypes.string,
  filterByDays: PropTypes.string,
  title: PropTypes.string,
  languageCode: PropTypes.string,
  viewAllLink: PropTypes.object,
  viewAllLabel: PropTypes.string,
};
