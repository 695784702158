import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions } from '../../../store/actions';

import Loader from '../../common/Loader/Loader';
import { CardOption } from '../Shop/PointsShopStyles';
import { selectStyles } from '../../forms/fields/selectStyles';

// selectedCard
export default function CardDropdown({ toggle, name, label }) {
  const {
    actions,
    state: {
      transactions: { cards, selectedCard },
    },
  } = useStateMachine({ updateTransactions });

  const optionsReady = cards.length && selectedCard.id;

  // TODO: build off of selected Card--> there can only be one label.
  const cardDefaultLabel =
    optionsReady &&
    cards
      .filter((option) => option.cardTitle === selectedCard.cardTitle)
      .map((a) => ({
        value: a.id,
        label: (
          <CardOption cardImage={a.cardImage} role="img" aria-label={a.cardTitle}>
            {a.cardTitle}
          </CardOption>
        ),
      }))[0];

  // the remaining objects int he cards array
  const cardOptions =
    optionsReady &&
    cards
      .filter((option) => option.cardTitle !== selectedCard.cardTitle)
      .map((a) => ({
        value: a.id,
        label: <CardOption cardImage={a.cardImage}>{a.cardTitle}</CardOption>,
      }));

  const [selectedLabel, setSelectedLabel] = useState(cardDefaultLabel);

  useEffect(() => {
    // Once the modal updates the new card selected, we recieve that here
    // then we need to tell the select to show the right label
    if (selectedCard.id) {
      setSelectedLabel({
        value: selectedCard.id,
        label: <CardOption cardImage={selectedCard.cardImage}>{selectedCard.cardTitle}</CardOption>,
      });
    }
  }, [selectedCard]);

  const handleChange = ({ value }) => {
    // when user switches card, we update the cardId to that one
    if (value !== selectedLabel.value) {
      toggle(value);
    }
  };

  return (
    <div>
      {Boolean(label) && (
        <label id={`dropdown-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      {optionsReady ? (
        <Select
          name={name}
          aria-required
          aria-labelledby={`dropdown-${name}`}
          classNamePrefix="orders-filter-select"
          isSearchable={false}
          options={cardOptions}
          value={selectedLabel}
          onChange={handleChange}
          styles={selectStyles}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}

CardDropdown.propTypes = { toggle: PropTypes.func, name: PropTypes.string, label: PropTypes.string };
