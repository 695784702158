/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

import { StyledInput, FormInputLabel } from '../FormStyles';

function FormInput({
  isDisabled,
  isReadOnly,
  name,
  label,
  defaultVal,
  fieldType,
  minLength,
  maxLength,
  placeholder,
  rules,
  addressModal,
  onFocus,
}) {
  const { actions } = useStateMachine({ updateUser });
  const [inputVal, setInputVal] = useState('');
  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, control, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, inputVal || defaultVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const parseFieldValue = (value, fieldName) => {
    if (typeof value === 'string') {
      const removeLeadingSpaces = value ? value.replace(/^\s*/gm, '') : '';

      setValue(fieldName, removeLeadingSpaces, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(fieldName, value);
    }
  };

  return (
    <FieldLayout addressModal={addressModal}>
      {Boolean(label) && (
        <FormInputLabel addressModal={addressModal} className="mt-3" id={`form-${name}`} htmlFor={name}>
          {label}
        </FormInputLabel>
      )}
      <StyledInput
        onFocus={onFocus}
        name={name}
        id={name}
        aria-invalid={!!error}
        aria-labelledby={`form-${name}`}
        aria-disabled={isDisabled}
        aria-readonly={isReadOnly}
        type={fieldType || 'text'}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultVal}
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...register(name, {
          ...rules,
          onChange: (e) => {
            parseFieldValue(e.target.value, name);
          },
        })}
        className={`${isDisabled || isReadOnly ? 'field-is-disabled' : ''}${
          (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? ' field-has-error' : ''
        }`}
        isError={isError}
      />
      {isError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormInput.defaultProps = { addressModal: false };
FormInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  addressModal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  placeholder: PropTypes.string,
  errorsList: PropTypes.array,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  rules: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default FormInput;
