import styled, { css } from 'styled-components';

export const CloseIconStyles = styled.div`
  display: flex;
  justify-content: flex-end;

  ${(props) =>
    props.type === 'pos-right' &&
    css`
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    `};
`;
