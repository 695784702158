import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import { StyledTable } from './TableStyles';

export default function Table({ columns, children }) {
  return (
    <StyledTable role="table">
      <TableHeader columns={columns} />
      <div className="Table-row" role="table">
        {children}
      </div>
    </StyledTable>
  );
}

Table.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.array,
};
