import styled, { css } from 'styled-components';

export const Header = styled.header`
  background-color: ${(props) => (props.isDark ? props.theme.uiBlack : props.theme.uiCharcoal)};
`;
export const SkipToContent = styled.a`
  background-color: ${(props) => props.theme.uiDarkPurple};
  cursor: pointer;
  position: absolute;
  left: -1000px;
  color: ${(props) => props.theme.whiteColor};
  font-size: ${(props) => props.theme.fontBody2};
  font-weight: normal;
  text-align: center;
  padding: 0.5rem;
  z-index: 999999;
  border: 0;
  outline: none;
  span {
    padding: 0.5rem;
    display: inline-block;
    border: 1px solid ${(props) => props.theme.whiteColor};
  }
  &:focus {
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    outline: none;
  }
`;
export const BackdropFilter = styled.div`
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 55;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
  }
`;

export const SiteHeaderWrap = styled.div`
  height: ${(props) => props.theme.headerMobile};
  color: ${(props) => props.theme.whiteColor};
  align-content: flex-end;
  padding-bottom: 1rem;

  a {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: normal;
    margin-right: 2.5rem;

    &.active {
      font-size: 1.3rem;
      font-weight: bold;
    }
    &.selected-locale {
      font-weight: bold;
    }
    &.register {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1439px) {
    justify-content: space-between;
  }

  .header-logo {
    width: 7.5rem;
    height: 100%;
    margin: 0 6rem 0.5rem 0;
    @media screen and (max-width: 1439px) {
      width: 5.313rem;
      margin: 0 0.5rem 0.5rem 0;
    }

    a {
      width: 100%;
      height: 2rem;
      margin-right: 0;
      align-self: flex-end;
    }
  }

  .language-margin {
    margin: 0 0 0 0.75rem;
    line-height: ${(props) => props.theme.lineHeight3};
  }
  a,
  button {
    padding: 0.125rem 0;
    &:hover {
      _color: ${(props) => props.theme.lightGrayColor3};
    }
    &:focus {
      outline: 1px solid ${(props) => props.theme.uiPurple};
      outline-offset: 0.5rem;
    }
  }

  button.header-link-humburger {
    padding: 0;
    background-color: transparent;
  }

  max-width: 90rem;
  @media screen and (max-width: 640px) {
    padding: 0 1.125rem 1rem 1.125rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1439) {
    padding: 0 2.125rem 1rem 2.125rem;
  }

  @media screen and (min-width: 1440px) {
    height: ${(props) => props.theme.headerDesktop};
    padding: 0 7.25rem 1.5rem 7.25rem;
  }
`;

export const ProvincialSegmentWrapper = styled.div`
  display: flex;
  span {
    margin-right: 0;
  }
`;
export const AccountPointsWrapper = styled.div`
  display: flex;

  a {
    margin-right: 0;
  }

  .pts {
    font-size: ${(props) => props.theme.fontLegal};
    border-radius: 2px;
    padding: 0.25rem;
    text-transform: uppercase;
    margin-left: 0.25rem;
    font-weight: bold;
    background-color: ${(props) => props.theme.uiPurple};
    vertical-align: middle;
  }
`;

export const HeaderPointsColumn = styled.div`
  display: block;
  padding: 0.125rem 0;
  .pts {
    display: inline-block;
    background-color: ${(props) => props.theme.uiPurple};
    margin-left: 0.25rem;
    padding: 0.2rem;
    font-size: 0.785rem;
    line-height: ${(props) => props.theme.lineHeight3};
    font-weight: bold;
  }
`;

export const Section = styled.section`
  position: relative;
  width: auto;

  .flyout-menu {
    position: absolute;
    z-index: 45;
    top: 3rem;

    background-color: ${(props) => props.theme.uiCharcoal};
    border-top: 1px solid ${(props) => props.theme.uiGrey04};
    color: ${(props) => props.theme.whiteColor};
    display: flex;
    flex-direction: column;
    width: 12.5rem;
    min-width: max-content;
    align-items: start;
    padding: 1.5rem 2rem 2.5rem 2rem;

    @media screen and (min-width: 1440px) {
      width: 17.5rem;
    }
    &.flyout-menu-account {
      padding: 1.5rem 2rem;
    }

    &.flyout-menu-lang {
      width: 11rem;
      padding: 1.5rem 2rem 2rem 2rem;
    }
    a {
      margin-right: 0;
      display: block;
      font-size: ${(props) => props.theme.fontBody1};
      padding: 0.5rem 0 1rem 0;
      line-height: ${(props) => props.theme.lineHeight3};
    }
    button {
      font-size: ${(props) => props.theme.fontBody1};
      font-weight: normal;
      text-align: left;
      padding: 1rem 0;
      &.header-link-bold {
        font-weight: bold;
      }
      &.header-link-map {
        @media screen and (max-width: 1439px) {
          padding: 1.5rem 0 0.5rem 0;
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    &.tabletHide {
      display: none;
    }
  }

  button {
    position: relative;
    align-items: center;
    justify-content: center;
    ${(props) => (props.isLoggedIn ? 'padding-right: 1.5rem' : '')};
    display: flex;
    margin: 0;

    .account-avatar {
      margin-left: 0.5rem;
    }
  }

  .toggle-lang-show {
    padding: 0 !important;
    margin-top: 1rem;
  }

  &.mobile-lang-switch-section {
    margin-bottom: 6em;

    button {
      font-weight: bold;
    }
  }
`;
export const Divider = styled.hr`
  display: block;
  border-top: 1px solid ${(props) => props.theme.uiGrey04};
  width: 100%;
  padding: 0.25rem 0;
`;
export const DividerVertical = styled.div`
  color: ${(props) => props.theme.whiteColor};
  margin: 0 1.5rem;
  padding: 0.125rem 0;
  font-size: ${(props) => props.theme.fontBody3};
  font-weight: normal;
  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.introduction};
  }
  @media screen and (max-width: 1439px) {
    &.tabletHide {
      display: none;
    }
  }
`;

export const HeaderColumnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0;
  line-height: ${(props) => props.theme.lineHeight3};

  &.headerAccountColumn {
    margin-right: 2rem;
    @media screen and (max-width: 1439px) {
      margin-right: 1rem;
    }
  }
  &.headerRegisterColumn {
    margin-right: 2rem;
  }

  @media screen and (max-width: 1439px) {
    &.tabletHide {
      display: none;
    }
  }
  @media screen and (max-width: 640px) {
    &.mobileHide {
      display: none;
    }
  }
`;
export const Icon = styled.div`
  position: relative;
  display: flex;
  min-width: 1.5rem;
  &.map {
    margin-right: 0.5rem;
    @media screen and (max-width: 1439px) {
      margin-right: 1rem;
    }
  }
`;
export const MobileSection = styled.div`
  display: block;

  z-index: 60;
  @media screen and (min-width: 1440px) {
    display: none;
  }

  #menuHumburger {
    width: 17.5rem;
    min-width: max-content;
    top: -1.5rem;
    right: -2.125rem;
    flex-direction: column;

    @media screen and (min-width: 1440px) {
      top: -3.5rem;
    }

    @media screen and (max-width: 1439px) {
      overflow-y: scroll;
      height: 100vh;
      padding-bottom: 4em;
    }
    &.has-inline-message {
      @media screen and (max-width: 1439px) {
        height: calc(100vh - ${(props) => props.theme.inlineMsgMobile}) !important;
      }
    }
    p {
      color: ${(props) => props.theme.whiteColor};
      font-size: ${(props) => props.theme.introduction};
      font-weight: normal;
    }
    strong {
      font-weight: bold;
      line-height: 2rem;
      display: block;
    }

    small {
      font-size: ${(props) => props.theme.fontBody3};
    }

    a,
    button {
      display: flex;
      margin-right: 0;
      justify-content: start;
      text-align: left;
    }

    button.header-link-humburger-close {
      align-self: flex-end;
      width: 1.5rem;
      height: 1.5rem;
      margin-bottom: 1.313rem;
    }

    button.account-avatar {
      img {
        margin-left: 0.5rem;
      }
    }
    #menuLanguageM {
      position: relative;
      top: 0.55rem;
      padding: 1rem 0;
    }
  }
`;
export const HeaderLogoOnlyWrapper = styled.div`
  width: 100%;
  height: 4.5rem;
  position: relative;
  padding: 1.313rem;
  background-color: ${(props) => props.theme.uiCharcoal};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  ${(props) =>
    !props.hideSpacing &&
    css`
      margin-bottom: -4.5rem;
    `};

  @media screen and (min-width: 1440px) {
    height: 7.5rem;
    padding: 2rem 1.5rem 0.625rem 1.5rem;
    ${(props) =>
      !props.hideSpacing &&
      css`
        margin-bottom: -7.5rem;
      `};
  }
`;
