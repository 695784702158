/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useStateMachine } from 'little-state-machine';
import { updateNavigation } from '../../../store/actions';

import useQuery from './useQuery';
import useRouterEvents from './useRouterEvents';
import useHashParams from './useHashParams';
import useGuestRedirect from './useGuestRedirect';

export default function useNavigation({ languageCode, onStart, onStop }) {
  const {
    state: { navigation },
    actions,
  } = useStateMachine({ updateNavigation });

  const [initialPage, setInitialPage] = useState('');
  const router = useRouter();
  const query = useQuery();
  const hashParams = useHashParams();
  useGuestRedirect(languageCode);

  // Cleanup Navigation Tracker from global state
  useEffect(
    () => () =>
      actions.updateNavigation({
        prevPage: '',
        page: '',
        query: null,
        redirectUri: '',
        hashParams: null,
      }),
    []
  );

  // Setup navigation on initial load
  useEffect(() => {
    if (initialPage) {
      // wait for the intial page to be loaded into context
      actions.updateNavigation({ page: initialPage });
    }
  }, [initialPage]);

  // Capture the 1st page we land on, save it as current
  useEffect(() => {
    // on initial load of app (external link or manually changing the url)
    if (!navigation.prevPage && !navigation.page) {
      setInitialPage(router.asPath);
    }
  }, [router]);

  // Load Query from Router into Global state
  useEffect(() => {
    if (query) {
      actions.updateNavigation({ query });
    } else {
      actions.updateNavigation({ query: null });
    }
  }, [query]);

  // Record Hash params when present
  useEffect(() => {
    if (hashParams) {
      actions.updateNavigation({ hashParams: { ...router.query, ...hashParams } });
    } else {
      actions.updateNavigation({ hashParams: null });
    }
  }, [hashParams]);

  function handleStart(url) {
    // before the route change, capture the correct order of the urls

    actions.updateNavigation({
      page: url,
      prevPage: router.asPath,
    });

    if (onStart && typeof onStart === 'function') {
      onStart();
    }
  }

  function handleStop(url) {
    // Check the new url for query params
    if (url !== navigation?.prevPage) {
      actions.updateNavigation({ query });
    }

    if (onStop && typeof onStop === 'function') {
      onStop();
    }
  }

  useRouterEvents({ handleStart, handleStop });

  // Allow hook to return values if we need to look into the data outside of global state
  return {
    isReady: router.isReady,
    query,
    hashParams,
  };
}
