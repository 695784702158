import PropTypes from 'prop-types';
import Link from 'next/link';

// Components
import ExternalImage from '../../common/Images/ExternalImage';

// Styles
import { StaticBannerContent, StaticBannerFooter } from '../OfferStyles';

export default function OffersStaticBanner({ selectedBanner }) {
  if (selectedBanner) {
    const { icon, message, ctaLink, category, ctaLabel } = selectedBanner;
    return (
      <StaticBannerContent>
        <div className="banner-content">
          <div className="image-container">
            <ExternalImage url={icon?.url} alt="" fill className="object-contain object-center" quality={80} />
          </div>
          <h3 className="banner-title">{message}</h3>
        </div>

        <StaticBannerFooter>
          <Link
            href={ctaLink?.href}
            role="button"
            aria-label={ctaLink?.text || ctaLabel}
            target={ctaLink?.target || '_self'}
            className={`offer-static-banner-${category}`}
          >
            <div className="link-text" style={{ height: '100%' }}>
              {ctaLabel}
              <div className="link-icon-container" />
            </div>
          </Link>
        </StaticBannerFooter>
      </StaticBannerContent>
    );
  }
  return null;
}
OffersStaticBanner.propTypes = {
  selectedBanner: PropTypes.object,
};
