import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getRewards } from '../../../pages/api/services';

// TODO: Update to global state
// move filter, languageCode, access token calls to pull from global state to control
// how and when we pull datat from the backend
export default function useRewards(filters, languageCode) {
  const {
    state: {
      session: { isLoggedIn, accessToken },
      location,
    },
  } = useStateMachine();

  const { isLoading, error, data } = useQuery(
    ['getRewards', filters, languageCode, location?.province],
    async () => {
      try {
        if (isLoggedIn && accessToken && languageCode && filters && location?.province) {
          // console.log('--------filters', filters.filters.category, filters.filters.brand);
          const res = await getRewards(filters, languageCode, accessToken, location.province);
          return res.data.data.rewards;
        }
      } catch (error) {
        console.error(error);
      }
    },
    { staleTime: 5 * 60 * 1000 }
  );

  return { isLoading, error, rewardsData: data };
}
