import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Filter } from './FilterListStyles';
import { convertFiltersArrayToObj } from '../../../helpers/utils';

/**
 * @component
 *
 * @prop {array} filters array of objects that must have the shape {name: <string>, code:<string>}
 * @prop {function} handleFilterClicked callback handler from parent that will control the side effect of using the filter
 *
 */
export default function MultiFilterSelectable({ filters, handleFilterClicked, firstSelected }) {
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    // initialize the first filter in our list as the default selectedFilter
    const name = filters[0].code;
    let initialFilters;

    if (firstSelected) {
      initialFilters = {
        ...convertFiltersArrayToObj(filters, 'code'),
        [name]: { selected: true, code: filters[0].code },
      };
    } else {
      initialFilters = {
        ...convertFiltersArrayToObj(filters, 'code'),
      };
    }

    setSelectedFilters(initialFilters);
  }, []);

  const handleFilterSelected = (e) => {
    e.preventDefault();

    const { id } = e.target;

    setSelectedFilters((prevState) => {
      // find the existing filter from local state
      const filter = prevState[id];

      // update its selection but leave the code property alone
      return {
        ...prevState,
        [id]: { ...filter, selected: !filter.selected },
      };
    });
  };

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleFilterSelected(e);
    }
  };

  // Only trigger the parent update only after the local state has completed updating
  useEffect(() => {
    const selectedFiltersLoaded = Object.keys(selectedFilters).length;

    if (selectedFiltersLoaded) {
      // update the Parent with the selection
      handleFilterClicked(selectedFilters);
    }
  }, [selectedFilters]);

  return (
    <ul className="flex flex-wrap list-none pl-0">
      {filters.map((category, index) => (
        <Filter
          key={index}
          tabIndex="0"
          aria-label={`${selectedFilters[category.code]?.selected ? 'selected' : ''} ${category.name} `}
          id={category.code}
          className={`mx-3 my-2 ${selectedFilters[category.code]?.selected ? 'selected' : ''}`}
          onClick={handleFilterSelected}
          onKeyDown={handleKeyDown}
          role="listitem"
          name={category.name}
        >
          {category.name}
        </Filter>
      ))}
    </ul>
  );
}

MultiFilterSelectable.propTypes = {
  firstSelected: PropTypes.bool,
  filters: PropTypes.array.isRequired,
  handleFilterClicked: PropTypes.func.isRequired,
};
