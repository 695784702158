import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 1.5rem;
  background-color: ${(props) => props.theme.whiteColor};

  @media screen and (min-width: 768px) {
    margin: 0;
    padding: 1rem;
    background-color: ${(props) => (props.page === 'byot' ? props.theme.whiteColor : 'transparent')};
  }

  .card-column {
    position: relative;
    z-index: 9;

    @media screen and (min-width: 768px) {
      max-width: 50%;
      margin-right: 25px;
      z-index: inherit;
    }
  }

  .period-column {
    @media screen and (min-width: 768px) {
      max-width: 50%;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`;

export default function CardFilter({ children, page }) {
  return (
    <Container className={`md:flex md:px-0${page === 'byot' ? ' px-0' : ''}`} page={page}>
      {children}
    </Container>
  );
}

CardFilter.propTypes = {
  children: PropTypes.any,
  page: PropTypes.string,
};
