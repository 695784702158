import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button/Button';

const LoadMoreTransactions = (props, ref) => {
  const { hasNextPage, fetchNextPage, isFetchingNextPage, pages, customData } = props;
  const { moreTransactionsLabel, moreTransactionsLabelLoading } = customData;

  if (hasNextPage && pages && pages?.length && pages?.length > 0 && pages[0]?.totalItemCount > 18) {
    return (
      <Button
        ref={ref}
        className="button-large"
        buttonStyle="secondary-button-outline-purple"
        style={{ width: '100%', marginTop: '1rem' }}
        onClick={() => fetchNextPage()}
      >
        {isFetchingNextPage ? moreTransactionsLabelLoading : moreTransactionsLabel}
      </Button>
    );
  }
  return null;
};

const forwardedRef = forwardRef(LoadMoreTransactions);

LoadMoreTransactions.propTypes = {
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  pages: PropTypes.array,
  customData: PropTypes.object.isRequired,
};

export default forwardedRef;
