/* eslint-disable jsx-a11y/interactive-supports-focus */

import PropTypes from 'prop-types';
import Link from 'next/link';

import { FooterColumn, JoinCTA, Section, UnAuthSection } from '../../FooterStyles';
import FooterInfoLinks from './FooterInfoLinks';

// Helpers
import { handleGAEvents } from '../../../../../helpers/handleGoogleAnalyticsHelper';

export default function FooterLogoQuickLinks({ footer, isLoggedIn }) {
  const { anonymousHeader, anonymousMessage, joinLink, quickLinksHeader, quickLinks } = footer.fields;
  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleGAEvents('Register for Scene+', 'Registration Events', 'Register for Scene Click', 'sign_up_start');
    }
  };

  return (
    <>
      <FooterColumn isLoggedIn={isLoggedIn}>
        {!isLoggedIn ? (
          <UnAuthSection>
            <div>
              <h2 className="primary-footer-header">{anonymousHeader}</h2>
              <p>{anonymousMessage}</p>
            </div>

            <Link
              href={joinLink.href}
              as={joinLink.href}
              target={joinLink.target}
              role="link"
              onClick={() => {
                handleGAEvents('Register for Scene+', 'Registration Events', 'Register for Scene Click', 'sign_up_start');
              }}
              onKeyDown={handleKeyDown}
            >
              <JoinCTA>{joinLink.text}</JoinCTA>
            </Link>
          </UnAuthSection>
        ) : (
          <Section>
            <h2>{quickLinksHeader}</h2>
            {quickLinks &&
              quickLinks.map((navitem, index) => (
                <div key={index} className="footer-link">
                  <Link
                    href={navitem.fields.page.href}
                    as={navitem.fields.page.href}
                    className="block text-base leading-6 font-medium text-secondary-500 hover:text-primary-500 border-transparent border-b-2 hover:border-primary-500 hover:border-b-primary hover:border-b-2 focus:text-primary-500 transition duration-300"
                  >
                    {navitem.fields.title}
                  </Link>
                </div>
              ))}
          </Section>
        )}
      </FooterColumn>
      <FooterInfoLinks footer={footer} isLoggedIn={isLoggedIn} isTablet="isTablet" />
    </>
  );
}

FooterLogoQuickLinks.propTypes = { footer: PropTypes.object, isLoggedIn: PropTypes.bool };
