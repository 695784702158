import PropTypes from 'prop-types';
import Image from 'next/image';

import Button from '../../../common/Button/Button';

import { MissingPointsStyle } from '../PointsHistoryStyles';

// Hooks
import useModal from '../../../hooks/utils/useModal';
import MissingPointsModal from './MissingPointsModal';

export default function MissingPoints({ customData, iconOnly }) {
  const { missingPointsLink, missingPointsMessage, missingPointsLabel } = customData;
  const { isShowing, toggle } = useModal();

  if (iconOnly) {
    return (
      <MissingPointsStyle>
        <Button
          type="button"
          className="missing-points-btn"
          ariaLabel={missingPointsLabel}
          aria-expanded={isShowing}
          onClick={() => toggle()}
        >
          <Image src="/assets/help-icon.svg" alt={missingPointsLink.text} width={22} height={22} />
        </Button>

        <MissingPointsModal isShowing={isShowing} toggle={toggle} customData={customData} />
      </MissingPointsStyle>
    );
  }

  return (
    <MissingPointsStyle>
      <Button
        type="button"
        className="missing-points-btn"
        ariaLabel={missingPointsLabel}
        aria-expanded={isShowing}
        onClick={() => toggle()}
      >
        <div className="missing-points-inner">
          <div className="missing-points-text">
            {missingPointsLabel && <p className="hidden sm:block">{missingPointsLabel}</p>}
            <Image src="/assets/help-icon.svg" alt={missingPointsLink.text} width={20} height={20} />
          </div>

          <div className="hidden sm:block">
            <Image src="/assets/chevron-right.svg" alt={missingPointsLink.text} width={20} height={20} />
          </div>
        </div>
      </Button>

      {missingPointsMessage && <p className="note hidden sm:block">{missingPointsMessage}</p>}

      <MissingPointsModal isShowing={isShowing} toggle={toggle} customData={customData} />
    </MissingPointsStyle>
  );
}

MissingPoints.propTypes = {
  customData: PropTypes.object,
  iconOnly: PropTypes.bool,
};
