import GeneralGiftCard from '../common/Cards/GeneralGiftCard';

export default function GiftCardListItem({ list, fields, isCategoryPage, itemListName }) {
  if (list && list.length > 0) {
    return (
      <>
        {list.map((item, index) => (
          <div className="gc-grid-item" key={index}>
            <GeneralGiftCard
              id={`cardgrid-${index}`}
              offersNum={list && list.length}
              primaryImage={item.primaryImage}
              brandName={item.brandName}
              minValue={item.minValue}
              maxValue={item.maxValue}
              ptsLabel={fields.ptsLabel}
              brandId={item.brandId}
              link={fields.giftCardDetailsLink}
              gcList={list}
              isCategoryPage={isCategoryPage}
              isGrid={Boolean(fields.isSlider !== 'true')}
              itemListName={itemListName}
            />
          </div>
        ))}
      </>
    );
  }

  return null;
}
