import { DetailsSection, SectionTitle } from '../DetailsStyles';

export default function DetailsEmail({ emailAddressLabel, email }) {
  if (emailAddressLabel && email) {
    return (
      <>
        <SectionTitle>{emailAddressLabel}</SectionTitle>
        <DetailsSection>
          <p>{email}</p>
        </DetailsSection>
      </>
    );
  }
  return null;
}
