import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

// Hooks
import useCorrelationId from '../../hooks/utils/useCorrelationId';
import useUserReq from '../../hooks/navigation/useUserReq';
import useGlobalLabels from '../../hooks/utils/useGlobalLabels';
import useLanguageCode from '../../hooks/account/useLanguageCode';

// Components
import InlineMessage from '../InlineMessage/InlineMessage';
import NotificationBar from '../Notification/NotificationBar';

// Styles
import { StickyHeadWrapper } from './StickHeadStyles';

export default function StickHead({ children, req, languageCode, globalLabels, inlineMsgList, closeLabel }) {
  const {
    state: { notification },
  } = useStateMachine();

  useUserReq(req);
  useGlobalLabels(globalLabels[0].fields, languageCode);
  useLanguageCode(languageCode);
  useCorrelationId();
  return (
    <StickyHeadWrapper className="stick-head-wrapper">
      <InlineMessage userIM={inlineMsgList} closeLabel={closeLabel} />
      {children}

      {notification && notification.message && <NotificationBar />}
    </StickyHeadWrapper>
  );
}

StickHead.propTypes = {
  children: PropTypes.element,
  req: PropTypes.object,
  languageCode: PropTypes.string,
  globalLabels: PropTypes.array,
};
