import Head from 'next/head';
import ReactHtmlParser from 'react-html-parser';

// helpers
import { deriveMetaImageByLocale } from '../../helpers/utils.js';

const Helmet = ({ title, description, keywords, metaHTML, languageCode }) => {
  // setup and parse additional header markup
  let additionalHeaderMarkup = null;

  if (metaHTML) {
    additionalHeaderMarkup = ReactHtmlParser(metaHTML);
  }
  const metaImage = deriveMetaImageByLocale(languageCode);

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href="https://cdn-ca.aglty.io/project-harmony/icons/web-assets/favicon-48x48.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://cdn-ca.aglty.io/project-harmony/icons/web-assets/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://cdn-ca.aglty.io/project-harmony/icons/web-assets/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://cdn-ca.aglty.io/project-harmony/icons/web-assets/favicon-16x16.png"
      />
      <link rel="shortcut icon" href="https://cdn-ca.aglty.io/project-harmony/icons/web-assets/favicon.ico" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="generator" content="Agility CMS" />
      <meta name="agility_timestamp" content={new Date().toLocaleString()} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={languageCode} />
      <meta property="og:locale:alternate" content={languageCode === 'fr-ca' ? 'en-ca' : 'fr-ca'} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={window.location.href} />
      {!additionalHeaderMarkup && <meta property="og:image" content={metaImage} />}
      {!additionalHeaderMarkup && <meta name="twitter:image" content={metaImage} />}
      {additionalHeaderMarkup}

      <link
        rel="stylesheet"
        type="text/css"
        href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=gx93-ct39-nw38-wa66"
      />
    </Head>
  );
};

export default Helmet;
