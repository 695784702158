import styled, { css } from 'styled-components';

export const ButtonDay = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.uiBlack};
  font-weight: normal;
  padding: 0;
  height: 100%;

  ${(props) =>
    props.isSameToday &&
    css`
      color: ${props.theme.whiteColor};
      background-color: ${props.theme.uiBlack};
    `};

  ${(props) =>
    props.isSameDate &&
    css`
      color: ${props.theme.whiteColor};
    `};

  ${(props) =>
    props.isWeekend &&
    css`
      color: ${props.theme.lightGrayColor4};
    `}

  ${(props) =>
    props.isSameDate &&
    props.isWeekend &&
    css`
      color: ${props.theme.whiteColor};
    `};

  &:focus {
    border-radius: 50%;
  }
`;
