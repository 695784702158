import Lottie from 'lottie-react';

// Styles
import { LoaderWrapper } from './PageLoaderStyles';
// SVG
import * as animationData from './PageLoader.json';

export default function LottieLoader({ loadingLabel }) {
  return (
    <LoaderWrapper>
      <div id="lottie" data-testid="lottie">
        <Lottie animationData={animationData} />
      </div>

      {loadingLabel && <p>{loadingLabel}</p>}
    </LoaderWrapper>
  );
}
