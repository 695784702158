import { useMsal } from '@azure/msal-react';

import { b2cSignIn } from '../../../b2c/b2cFunctions';

import Button from '../../common/Button/Button';

import { FormFooterContainer, FormFooterInner } from '../FormStyles';
import ButtonSimple from '../../common/Button/ButtonSimple';

export default function FormFooter({ buttonText, buttonLoadingText, linkText, submitting, languageCode }) {
  const { instance } = useMsal();

  return (
    <FormFooterContainer>
      <FormFooterInner className="w-full flex justify-center self-center px-4 py-6 sm:px-14 justify-between">
        <div className="self-center">
          <ButtonSimple type="button" onClick={() => b2cSignIn(instance, languageCode)} role="button" aria-label={linkText}>
            {linkText}
          </ButtonSimple>
        </div>
        <div>
          <Button
            type="submit"
            className="register-primary-button"
            buttonLoadingText={buttonLoadingText}
            submitting={submitting}
            role="button"
            aria-label={buttonText}
          >
            {buttonText}
          </Button>
        </div>
      </FormFooterInner>
    </FormFooterContainer>
  );
}
