import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';
import useIntersectionObserver from '../hooks/utils/useIntersectionObserver';
import { updateGiftCards } from '../../store/actions';

import FilterList from '../common/Filters/FilterList';
import WrapperLayout from '../layouts/WrapperLayout';
import GiftCardList from '../GiftCards/GiftCardList';

// migrate to a hook on its own
import useGiftCards from '../hooks/giftCards/useGiftCards';
import useGiftCardCategories from '../hooks/giftCards/useGiftCardCategories';
import { aggregateFilteredListFrom } from '../../helpers/giftCardsHelper';

export default function GiftCardsPage({ module, customData, languageCode }) {
  const { resultsLabel } = module.fields;
  const {
    actions,
    state: {
      session: { isLoggedIn },
      giftCards: { giftCardsList, giftcardCategories, selectedFilters },
    },
  } = useStateMachine({ updateGiftCards });

  useGiftCardCategories(customData.giftcardCategories, languageCode);

  const {
    giftCards: giftCardsData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useGiftCards(languageCode);

  const allGiftcards = giftCardsData && giftCardsData?.pages.map((page) => page?.giftCards)[0];

  useEffect(() => {
    if (giftCardsData && !isLoading) {
      actions.updateGiftCards({
        giftCardsList: allGiftcards,
        isLoading,
      });

      // only refetch the first page
      refetch({ refetchPage: (page, index) => index === 0 });
    }

    return () => {
      actions.updateGiftCards({
        giftCardsList: [],
        isLoading: true,
        selectedFilters: {},
      });
    };
  }, [giftCardsData]);

  const handleFilterClicked = (filters) => actions.updateGiftCards({ selectedFilters: filters });
  const filteredList = aggregateFilteredListFrom(giftCardsList, selectedFilters, giftCardsData);

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !filteredList && hasNextPage,
  });

  if (isLoggedIn && giftcardCategories.length) {
    return (
      <WrapperLayout className="global-content-wrapper pb-20" data-testid="giftcards-page">
        <FilterList filters={giftcardCategories} handleFilterClicked={handleFilterClicked} multiSelectable />

        {!filteredList && hasNextPage && <div ref={loadMoreRef}>{isFetchingNextPage && ''}</div>}

        <GiftCardList
          resultsLabel={resultsLabel}
          fields={module.fields}
          languageCode={languageCode}
          filteredList={filteredList}
          pages={giftCardsData && giftCardsData?.pages}
        />
      </WrapperLayout>
    );
  }

  return null;
}

GiftCardsPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'giftcardspagecontent',
      languageCode,
    });

    let contentItem = contentItemList && contentItemList[0];
    let giftCardsCategoriesList = null;

    if (contentItem.fields.giftCardsCategoriesList) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'giftCardsCategoriesList',
        sortIDField: 'giftCardsCategoriesList_SortIdField',
      });

      giftCardsCategoriesList = contentItem.fields.giftCardsCategoriesList.map((m) => ({
        code: m.fields.code,
        name: m.fields.name,
      }));
    }

    return {
      giftcardCategories: giftCardsCategoriesList || null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

GiftCardsPage.propTypes = {
  module: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
