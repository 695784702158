import styled from 'styled-components';
import ExternalImage from '../Images/ExternalImage';

const SectionBanner = styled.div`
  position: relative;
  overflow: hidden;
  height: 18rem;
  max-height: 18rem;
  width: 100%;

  @media screen and (max-width: 767px) {
    height: 9.7rem;
    max-height: 9.7rem;
  }
`;

export default function L3HeaderBanner({ bgImage }) {
  if (bgImage) {
    return (
      <SectionBanner className="relative w-full">
        <ExternalImage url={bgImage} fill className="object-cover object-center" />
      </SectionBanner>
    );
  }

  return null;
}
