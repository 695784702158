import styled from 'styled-components';

export const Section = styled.section`
  height: 12.5rem;
  width: 100vw;
  display: flex;
  position: relative;
  margin: 3.75rem 0;
  left: calc(-50vw + 50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  background: ${(props) => props.theme.uiGrey01};
  @media screen and (max-width: 640px) {
    margin: 3rem 0;
  }
  .image-wrapper {
    margin-bottom: 1rem;
  }
`;
