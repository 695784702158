/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';

// Hooks
import useHashParams from './useHashParams';
import useStorage from './useStorage';

// Helpers
import { isValidAgilityURI } from '../../../helpers/navigationHelpers';

export default function useStorageRedirect(sitemap) {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const router = useRouter();

  const hashParams = useHashParams();
  const { getItem, removeItem } = useStorage();

  useEffect(() => {
    // only handle internal redirects after login complete
    if (hashParams?.state && isLoggedIn) {
      const fromURL = getItem('from_url');

      // only allow user to be redirected to a valid agility base path
      if (isValidAgilityURI({ uri: fromURL, sitemap })) {
        removeItem('from_url');
        // If we want to preserve the root#state=value url in history, use router.push
        router.replace(fromURL);
      }
    }
  }, [hashParams?.state, isLoggedIn]);
}
