import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';

// Components
import ShippingAddressSwitcher from '../../forms/profile/ShippingAddressSwitcher/ShippingAddressSwitcher';
import AddNewAddresss from '../../forms/profile/AddAddress/AddNewAddresss';
import Loader from '../../common/Loader/Loader';

import useCustomerAddress from '../../hooks/account/useCustomerAddress';
import useProvinces from '../../hooks/account/useProvinces';

// Helpers
import { arrayEquals } from '../../../helpers/utils';

function ConfirmationShippingAddress({
  shippingAddressLabel,
  addNewAddressLink,
  errorsList,
  customData,
  languageCode,
  profileContent,
  ...props
}) {
  const {
    actions,
    state: {
      user: { shippingAddresses },
      giftCards: {
        redeem: { selectedFormat },
      },
    },
  } = useStateMachine({ updateUser });

  const { isLoading: addressesLoading, customerAddresses, refetch } = useCustomerAddress();
  const { provinces } = useProvinces(languageCode);
  const [refetchAddressList, setRefetchAddressList] = useState(false);
  const [shippingList, setAddressList] = useState(customerAddresses);

  useEffect(() => {
    if (!addressesLoading && customerAddresses?.length) {
      if (!arrayEquals(customerAddresses, shippingAddresses)) {
        actions.updateUser({ shippingAddresses: customerAddresses });
      }
    }
  }, [customerAddresses]);

  useEffect(() => {
    if (shippingAddresses?.length && !arrayEquals(shippingList, shippingAddresses)) {
      setAddressList(shippingAddresses);
    }
  }, [shippingAddresses]);

  useEffect(() => {
    if (refetchAddressList) {
      refetch();
      setRefetchAddressList(false);
    }
  }, [refetchAddressList]);

  const isLoading = !Array.isArray(shippingList) || !shippingList?.length;
  if (selectedFormat !== 'Physical') return null;

  return (
    <SectionDetails>
      {shippingAddressLabel && <SectionTitle>{shippingAddressLabel}</SectionTitle>}
      <DetailsInfo>
        {isLoading && <Loader width="auto" />}
        {!isLoading && (
          <>
            <ShippingAddressSwitcher
              errorsList={errorsList}
              customData={customData}
              shippingList={shippingList}
              profileContent={profileContent}
              setRefetchAddressList={setRefetchAddressList}
            />

            <AddNewAddresss
              customData={customData}
              {...props}
              isEditing
              addNewAddressLabel={addNewAddressLink}
              setAddressList={setAddressList}
              provinces={provinces}
              errorsList={errorsList}
              languageCode={languageCode}
              profileContent={profileContent}
              {...profileContent}
              coloredLink
              page="giftcard"
            />
          </>
        )}
      </DetailsInfo>
    </SectionDetails>
  );
}

ConfirmationShippingAddress.propTypes = {
  shippingAddressLabel: PropTypes.string.isRequired,
  addNewAddressLink: PropTypes.string.isRequired,

  errorsList: PropTypes.array,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  profileContent: PropTypes.object,
};

export default ConfirmationShippingAddress;
