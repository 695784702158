import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updateUser } from '../../../../store/actions';

// Components
import FormSave from '../../FormSave';
import EditButton from '../../EditButton';
import SettingsEmailFieldContent from './SettingsEmailFieldContent';

// Services
import { savePreferences } from '../../../../pages/api/services';

// Utils
import { savingEmailSettingsValues } from '../../../../helpers/settingsHelper';
import { convertToSlug } from '../../../../helpers/utils';
import { handleGAEvents, stringConcatHelper, getEmailOptLabel } from '../../../../helpers/handleGoogleAnalyticsHelper';

// Styles
import { Row, Title } from '../../FormStyles';

export default function SettingsEmail({ customData, errorsList }) {
  const {
    emailExpandedMessage,
    emailPreferencesMessage,
    emailTopics,
    emailPreferencesLabel,
    emailOptInLabel,
    saveLabel,
    saveLabelLoading,
    cancelLabel,
    emailSuccessMessage,
  } = customData;

  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const {
    actions,
    state: {
      session: { accessToken },
    },
  } = useStateMachine({ updateNotification, updateUser });

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const handleEditClick = () => {
    setEditing(!isEditing);
  };

  const onSubmit = (data) => {
    // TODO: This GA code NEEDS to be fixed
    const gaLabel = stringConcatHelper(
      'Email Notifications - ',
      data.EmailOptin === undefined || data.EmailOptin === false ? 'false' : 'true'
    );
    handleGAEvents(gaLabel, 'Account', 'Email Notifications Toggle Click', 'account_settings_email_notif_toggle');

    handleGAEvents(getEmailOptLabel(data), 'Account', 'Email Notifications Toggle Click', 'account_settings_email_notif_toggle');
    setSubmitting(true);

    savePreferences(accessToken, savingEmailSettingsValues(data))
      .then((res) => {
        if (res.data) {
          actions.updateNotification({
            message: emailSuccessMessage,
            status: 'success',
          });

          actions.updateUser({
            preferences: savingEmailSettingsValues(data),
            emailOptInChecked: data.EmailOptin,
          });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: errorsList && errorsList[0].message,
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <Row className="mb-0.5 pt-1">
        <form className="relative pr-3 pl-3 pt-0 pb-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="md:flex justify-between content-center items-start">
            <div className="w-52 SettingFieldWrapper">
              <Title className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{emailPreferencesLabel}</Title>
            </div>
            <div className="w-full">
              <SettingsEmailFieldContent
                isEditing={isEditing}
                emailExpandedMessage={emailExpandedMessage}
                emailPreferencesMessage={emailPreferencesMessage}
                emailTopics={emailTopics}
                emailOptInLabel={emailOptInLabel}
              />

              {isEditing && (
                <FormSave
                  buttonText={saveLabel}
                  buttonLoadingText={saveLabelLoading}
                  submitting={submitting}
                  cancelLabel={cancelLabel}
                  isEditing={isEditing}
                  updateEditClick={handleEditClick}
                />
              )}
            </div>

            <EditButton
              {...customData}
              handleEditClick={handleEditClick}
              isEditing={isEditing}
              ariaControls={convertToSlug(emailPreferencesLabel)}
            />
          </div>
        </form>
      </Row>
    </FormProvider>
  );
}
