import { postalSpace } from '../../../../helpers/utils';
import EditAddress from '../EditAddress/EditAddress';
import PrimaryAddress from '../PrimaryAddress';
import AddressDetails from './AddressDetails';

import { AddressBlock } from './AddressStyles';

export default function Address(props) {
  const {
    disableNameEdit,
    provinces,
    address,
    setAddressList,
    primaryShippingAddressLabel,
    profileAddressLabel,
    shippingAddressLabel,
  } = props;

  return (
    <AddressBlock className="flex w-full py-4 px-6 mb-2 justify-between items-start content-start rounded">
      <PrimaryAddress
        address={address}
        primaryShippingAddressLabel={primaryShippingAddressLabel}
        profileAddressLabel={profileAddressLabel}
        shippingAddressLabel={shippingAddressLabel}
      />
      <AddressDetails address={address} />
      <div className="address-edit w-10 flex justify-end content-center">
        <EditAddress
          {...props}
          setAddressList={setAddressList}
          disableNameEdit={disableNameEdit}
          fName={address?.firstName}
          lName={address?.lastName}
          shippingPrimary={address?.primary}
          addressLine1={address?.addressLine1}
          addressLine2={address?.addressLine2}
          city={address?.city}
          provinceCode={address?.provinceCode}
          postalCode={postalSpace(address?.postalCode)}
          addressId={address?.uniqueId}
          primaryPhoneNumber={address?.phoneLocalNumber || address?.primaryPhoneNumber}
          provinces={provinces}
        />
      </div>
    </AddressBlock>
  );
}
