import PropTypes from 'prop-types';
// Components
import SceneUnavailableSection from '../common/Sections/SceneUnavailableSection';

const SceneUnavailable = ({ module }) => {
  // get module fields
  const { fields } = module;
  return <SceneUnavailableSection fields={fields} />;
};
SceneUnavailable.propTypes = { module: PropTypes.object };
export default SceneUnavailable;
