import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import JuicerFeed from '../common/Sections/JuicerFeed';

const SharedJuicerFeedSection = ({ module }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return <JuicerFeed fields={fields} />;
  }

  return null;
};

SharedJuicerFeedSection.propTypes = { module: PropTypes.object };

export default SharedJuicerFeedSection;
