import styled, { css } from 'styled-components';

export const SectionTitle = styled.h2`
  color: ${(props) => props.theme.brandBlack};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight3};

  span {
    color: ${(props) => props.theme.lightGrayColor4};
    font-weight: normal;
  }
`;
export const SectionSubtitle = styled.div`
  p {
    line-height: ${(props) => props.theme.lineHeight3};
  }
`;
export const MoreOffersWrapper = styled.div`
  display: grid;
  grid-gap: 1.875rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    + div > button.view-more {
      display: none;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;
export const OfferItemWrapper = styled.div`
  height: 122px;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
`;
export const IconColumn = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.uiBlack};
  position: relative;
  overflow: hidden;
`;
export const PartnerIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.uiGrey01};

  @media screen and (min-width: 375px) {
    width: 55px;
    height: 55px;
  }

  @media screen and (min-width: 641px) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: 768px) {
    width: 7vw;
    height: 7vw;
  }
`;
export const DetailsColumn = styled.div`
  position: relative;
  flex: 1;
`;
export const OfferBrandTitle = styled.p`
  font-size: ${(props) => props.theme.fontLegal};
  font-weight: bold;
  color: ${(props) => props.theme.lightGrayColor4};
  margin-bottom: 0.5rem;
`;
export const OfferBonusPoints = styled.h3`
  font-size: ${(props) => props.theme.fontBody2};
  font-weight: bold;
  margin-bottom: 0.35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const OfferDescription = styled.p`
  font-size: ${(props) => props.theme.fontLegal};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const OfferDistance = styled.span`
  font-size: ${(props) => props.theme.fontLegal};
  color: ${(props) => props.theme.lightGrayColor4};
  position: absolute;
  top: 0;
  right: 0;
`;
export const OfferPartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5rem 0;

  @media screen and (min-width: 641px) {
    flex-direction: row;
    align-items: center;
    padding: 4rem 0;
  }

  .partners-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 1rem;
    background-color: ${(props) => props.theme.whiteColor};

    p {
      @media screen and (min-width: 1024px) {
        margin: 0.75rem 0 2.75rem 0;
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        margin: 0.25rem 0 2.25rem 0;
      }
      @media screen and (min-width: 641px) {
        font-size: ${(props) => props.theme.fontBody2};
        line-height: ${(props) => props.theme.lineHeight3};
        min-width: 17.5rem;
      }
    }
  }
  .partners-link {
    font-weight: bold;

    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
`;

export const OfferActionsWrapper = styled.section`
  margin-bottom: 2.25rem;
  flex-direction: row;
  @media screen and (max-width: 640px) {
    margin-bottom: 1.5rem;
    flex-direction: column;
  }
  @media screen and (max-width: 1439px) {
    ${(props) =>
      props.page !== 'loadable' &&
      css`
        flex-direction: column;
      `};
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-bottom: 2rem;
    flex-direction: column;
    ${(props) =>
      props.page === 'loadable' &&
      css`
        flex-wrap: wrap;
      `};
  }
`;

export const FilterButtonsWrapper = styled.div`
  display: flex;
  align-items: end;
  ${(props) =>
    props.loadAllOffers === true &&
    css`
      width: 31%;
      justify-content: flex-end;
    `};
  ${(props) =>
    props.loadAllOffers !== true &&
    css`
      width: 100%;
      justify-content: space-between;
    `};

  @media screen and (max-width: 1023px) {
    margin-top: 1.313rem;
    width: 100%;
    justify-content: space-between;
  }
`;

export const FilterButtonsWrapper2 = styled.div`
  display: flex;
  align-items: end;
  ${(props) =>
    props.hideOfferActions === true &&
    css`
      display: none;
    `};
  ${(props) =>
    props.hasBanner === true &&
    css`
      width: 31%;
      justify-content: flex-end;
    `};
  @media screen and (max-width: 1439px) {
    margin-top: 1.313rem;
    width: 100%;
    justify-content: space-between;
  }
`;
export const NoOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.875rem 1rem;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  margin-top: 0.25rem;
  @media screen and (max-width: 640px) {
    padding: 2.875rem 1rem;
    margin-top: 0;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 991px) {
    width: 36.75rem;
  }

  p {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
    strong {
      display: block;
      font-size: ${(props) => props.theme.fontBody1};
      margin-bottom: 0.25rem;
    }
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
      strong {
        font-size: ${(props) => props.theme.fontBody2};
      }
    }
  }
`;

export const StaticBannerContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1440px) {
    max-width: 56rem;
    margin-right: 2rem;
    flex-direction: row;
  }
  @media screen and (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => props.theme.uiBlack};
  .banner-content {
    display: flex;
    flex-direction: row;

    align-items: center;
    @media screen and (max-width: 640px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 990px) {
      padding: 1.25rem;
    }
    @media screen and (min-width: 991px) and (max-width: 1430px) {
      padding: 1.313rem;
    }
    @media screen and (min-width: 1440px) {
      padding: 0.75rem;
    }
    .image-container {
      margin-right: 1.125rem;
      @media screen and (min-width: 641px) {
        margin-right: 0.875rem;
      }
      flex-shrink: 0;
      width: 4.438rem;
      height: 2.375rem;
      position: relative;
    }
    h3.banner-title {
      margin-bottom: 0;

      color: ${(props) => props.theme.uiWhite};
      font-family: 'Bebas Neue', sans-serif;
      line-height: 0.85;
      letter-spacing: 0.025rem;
      text-transform: uppercase;
      font-size: ${(props) => props.theme.headingMedSmall};

      font-weight: normal;
      display: block;
    }
  }
`;

export const StaticBannerFooter = styled.div`
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  height: 3.875rem;
  justify-content: flex-end;

  @media screen and (max-width: 1439px) {
    height: 2.75rem;
    align-self: flex-end;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.uiButtonRedBorder};
  }
  .link-text {
    color: ${(props) => props.theme.whiteColor};
    display: flex;
    align-items: center;
    position: relative;
    font-size: ${(props) => props.theme.fontBody3};
  }
  .link-icon-container {
    position: relative;
    height: 100%;
    width: 3.875rem;
    @media screen and (max-width: 1439px) {
      width: 2.75rem;
    }
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-left: 1rem;
    background-color: ${(props) => props.theme.uiButtonRed};

    transition: background-color 0.25s ease;
    &:after {
      content: url(/assets/arrow-right-white-2.svg);

      color: white;
      position: absolute;
      top: 1.55rem;
      right: 1.55rem;
      @media screen and (max-width: 1439px) {
        top: 1rem;
        right: 1rem;
      }
      transition: all 0.25s ease;
    }
  }
  &:hover,
  &:focus {
    .link-icon-container {
      background-color: ${(props) => props.theme.uiErrorRed};
      &:after {
        right: 0.75rem;
      }
    }
  }
`;

export const FeaturedCardsWrapper = styled.div`
  padding-top: 3.25rem;
  padding-bottom: 3.625rem;

  @media screen and (max-width: 990px) {
    padding-top: 2.5rem;
  }
`;
export const LinkWrapper = styled.div`
  padding: 0.875rem 0;
`;
