/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

import { Section } from './Heading2WithHtmlMessageStyles';

export default function Heading2WithHtmlMessage({ heading, message }) {
  if (heading && message) {
    return (
      <Section>
        <h2 className="mb-2">{heading}</h2>
        <div dangerouslySetInnerHTML={renderHTML(message)} />
      </Section>
    );
  }
  return null;
}

Heading2WithHtmlMessage.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
};
