import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Hooks
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import useHasMount from '../hooks/utils/useHasMount';

// Components
import PointsBalanceInfo from '../Points/PointsHistory/PointsBalanceInfo';
import PointsHistory from '../Points/PointsHistory/PointsHistory';
import WrapperLayout from '../layouts/WrapperLayout';
import PointsMobileHeader from '../Points/PointsHistory/PointsMobileHeader';

import MissingPoints from '../Points/PointsHistory/MissingPoints/MissingPoints';

const PointsPage = ({ customData, languageCode }) => {
  const { pageContent, transactionIconTypes, accountSegments, awardsTypes } = customData;

  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn) {
    return (
      <>
        <section className="bottom-shadow">
          <WrapperLayout className="global-content-wrapper" customStyles="pointsHeader">
            <PointsMobileHeader customData={pageContent} />
            <div className="flex">
              <PointsBalanceInfo customData={pageContent} languageCode={languageCode} />
              <div className="hidden sm:block lg:hidden ">
                <MissingPoints customData={pageContent} />
              </div>
            </div>
          </WrapperLayout>
        </section>

        <WrapperLayout className="global-content-wrapper min-half-screen" customStyles="pointsHistory">
          <PointsHistory
            customData={pageContent}
            transactionIconTypes={transactionIconTypes}
            accountSegments={accountSegments}
            awardsTypes={awardsTypes}
            languageCode={languageCode}
          />
        </WrapperLayout>
      </>
    );
  }
  return <div className="flex justify-center">{/* No account for now */}</div>;
};

PointsPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'pointspagecontent',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];

    const rawAwardsTypes = await api.getContentList({
      referenceName: 'awardtypes',
      languageCode,
    });
    const awardsTypes =
      rawAwardsTypes &&
      rawAwardsTypes.map((a) => ({
        code: a.fields.code,
        name: a.fields.name,
      }));

    const rawAccountSegments = await api.getContentList({
      referenceName: 'accountsegments',
      languageCode,
    });
    const accountSegments =
      rawAccountSegments &&
      rawAccountSegments.map((a) => ({
        code: a.fields.code,
        name: a.fields.name,
      }));

    const rawTransactionIconTypes = await api.getContentList({
      referenceName: 'transactionicontypes',
      languageCode,
    });
    const transactionIconTypes =
      rawTransactionIconTypes &&
      rawTransactionIconTypes.map((a) => ({
        code: a.fields.code,
        image: a.fields.image,
      }));

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.typesList) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'typesList',
        sortIDField: 'typesList_SortIdField',
      });
      const typesList = contentItem.fields.typesList.map((m) => ({
        code: m.fields.code,
        name: m.fields.name,
      }));
      contentItem.fields.typesList = typesList;
    }

    if (contentItem.fields.categoriesList) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'categoriesList',
        sortIDField: 'categoriesList_SortIdField',
      });
      const categoriesList = contentItem.fields.categoriesList.map((m) => ({
        code: m.fields.code,
        name: m.fields.name,
      }));
      contentItem.fields.categoriesList = categoriesList;
    }
    if (contentItem.fields.cardsList) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'cardsList',
        sortIDField: 'cardsList_SortIdField',
      });
      const cardsList = contentItem.fields.cardsList.map((m) => ({
        code: m.fields.code,
        name: m.fields.name,
      }));
      contentItem.fields.cardsList = cardsList;
    }

    return {
      pageContent: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      awardsTypes: awardsTypes || null,
      accountSegments: accountSegments || null,
      transactionIconTypes: transactionIconTypes || null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default PointsPage;
