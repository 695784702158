export default function updateLanguageAction(state, payload) {
  // console.log('state:', state.language);
  // console.log('payload:', payload);
  return {
    ...state,
    language: {
      ...state.language,
      ...payload,
    },
  };
}
