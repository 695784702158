import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import MultipleFieldsLayout from '../../layouts/MultipleFieldsLayout';
import { updateUser } from '../../../store/actions';

import { validateMaxMonthDays } from '../../../helpers/formHelpers';

import { StyledInput } from '../FormStyles';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

function BirthDayInput({
  name,
  label,
  defaultVal,
  fieldType,
  minLength,
  maxLength,
  placeholder,
  rules,
  errorsList,
  gaFlow = '',
}) {
  const { actions } = useStateMachine({ updateUser });
  // eslint-disable-next-line no-unused-vars
  const [inputVal, setInputVal] = useState('');

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, inputVal || defaultVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];

  // get values for field validation
  const formYear = getValues('dobYear');
  const formMonth = getValues('dobMonth');

  return (
    <MultipleFieldsLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <StyledInput
        onFocus={() => {
          if (gaFlow === 'register' || gaFlow === 'registerGK') {
            const dataLayer = {
              event: 'sign_up_form_field',
              category: 'Registration Events',
              action: 'Form Field Click',
              label: name,
              sign_up_step: `Step 2${gaFlow === 'registerGK' ? ' GK' : ''}`,
              sign_up_form_field: name,
            };
            handleGAEventsBase(dataLayer);
          }
        }}
        id={name}
        name={name}
        type={fieldType || 'number'}
        aria-invalid={!!error}
        aria-labelledby={`form-${name}`}
        aria-required
        minLength={minLength}
        maxLength={maxLength || 2}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
        }}
        placeholder={placeholder}
        defaultValue={defaultVal}
        {...register(name, {
          ...rules,
          min: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MinDay').map((item) => item.message)[0],
            value: 1,
          },
          max: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MaxDayOfMonth').map((item) => item.message)[0],
            value: validateMaxMonthDays(formMonth, formYear),
          },
          maxLength: {
            message: errorsList.filter((item) => item.code === 'Customer.BirthDate.MaxDay').map((item) => item.message)[0],
            value: 2,
          },
        })}
        className={`${(error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''}`}
        isError={Boolean(error)}
      />
    </MultipleFieldsLayout>
  );
}

BirthDayInput.defaultProps = {
  gaFlow: '',
};

BirthDayInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  placeholder: PropTypes.string,
  errorsList: PropTypes.array,
  rules: PropTypes.object,
  gaFlow: PropTypes.string,
};

export default BirthDayInput;
