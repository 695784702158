import PropTypes from 'prop-types';

import CloseIcon from '../../Icons/CloseIcon';
import { FilterModalPopupHeader } from '../ModalStyles';

function FilterModalHeader({ filterTitle, toggle }) {
  return (
    <FilterModalPopupHeader className="py-4 flex justify-center text-center">
      {filterTitle && <h2 id="modal-label">{filterTitle}</h2>}

      <CloseIcon toggle={toggle} type="pos-right" width={16.8} height={16.8} />
    </FilterModalPopupHeader>
  );
}

FilterModalHeader.propTypes = { filterTitle: PropTypes.string, toggle: PropTypes.func };

export default FilterModalHeader;
