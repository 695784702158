/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import ExternalImage from '../Images/ExternalImage';

import {
  StyledBannerWrapper,
  BgImageWrapper,
  StyledBanner,
  StyledBannerContent,
  LocationsHeader,
  SubHeader,
  LegalMessage,
  ComingSoonMessage,
} from './EarnRedeemStyles';
// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import BrandList from '../List/BrandList';

// Helpers
import { isEmpty } from '../../../helpers/utils';
import { generateBrandListFrom } from '../../../helpers/brandsHelpers';

export default function EarnRedeem({ fields }) {
  const locationsList = generateBrandListFrom(fields);

  // if (!isEmpty(fields) && locationsList) {
  if (!isEmpty(fields) && locationsList) {
    return (
      <>
        <StyledBannerWrapper>
          <BgImageWrapper>
            {fields.image?.url && <ExternalImage url={fields.image?.url} fill className="object-cover object-center" />}
          </BgImageWrapper>
          <div className="global-content-wrapper">
            <StyledBanner>
              <StyledBannerContent>
                {fields.title && <h2 className="headline-medium block mt-10 md:mt-20 mb-8 md:mb-10">{fields.title}</h2>}
                {fields.message && <SubHeader dangerouslySetInnerHTML={renderHTML(fields.message)} />}
                {fields.locationsHeader && (
                  <LocationsHeader>
                    <h3>{fields.locationsHeader}</h3>
                  </LocationsHeader>
                )}
                <BrandList brandList={locationsList} />
              </StyledBannerContent>
              {fields.comingSoonMessage && (
                <ComingSoonMessage>
                  <h3>{fields.comingSoonMessage}</h3>
                </ComingSoonMessage>
              )}
            </StyledBanner>
          </div>
        </StyledBannerWrapper>

        {fields.legalMessage && (
          <WrapperLayout className="global-content-wrapper">
            <LegalMessage dangerouslySetInnerHTML={renderHTML(fields.legalMessage)} />
          </WrapperLayout>
        )}
      </>
    );
  }
  return null;
}
EarnRedeem.propTypes = {
  fields: PropTypes.object,
};
