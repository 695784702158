import { useFormContext } from 'react-hook-form';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions, updatePoints } from '../../../store/actions';

import Button from '../Button/Button';
import ButtonSimple from '../Button/ButtonSimple';
import CloseIcon from '../Icons/CloseIcon';
import { findSelectedCard } from '../../../helpers/filterHelpers';

// Styles
import { ModalContainer, ModalHeader, ModalBody, ButtonContainer, ModalPopupContainer } from './ModalStylesV2';

export default function TransactionModal({ customData, toggle, currentItemId }) {
  const { actions, state } = useStateMachine({ updateTransactions, updatePoints });
  const {
    transactions: { cards },
  } = state;
  const { switchTitle, switchMessage, switchNoLabel, switchYesLabel } = customData;

  const formContext = useFormContext();
  const { reset } = formContext;

  const handleChange = () => {
    // if they hit yes to continue, update the global selected card to the one that matches the select's id value

    const acceptedCard = findSelectedCard(currentItemId, cards);
    actions.updateTransactions({ selectedCard: acceptedCard, awards: { byot: [], pfc: [] } });
    actions.updatePoints({ added: [] });

    // setCardImage(acceptedCard.cardImage);

    reset(); // resets the form ???
    toggle(); // closes modal
  };

  return (
    <ModalContainer>
      <CloseIcon toggle={toggle} />
      <ModalPopupContainer>
        <ModalHeader>
          <h2 id="modal-label">{switchTitle}</h2>
        </ModalHeader>
        {switchMessage && <ModalBody>{switchMessage}</ModalBody>}
        {switchYesLabel && (
          <ButtonContainer>
            <Button type="button" buttonText={switchYesLabel} ariaLabelledBy={switchYesLabel} onClick={handleChange}>
              {switchYesLabel}
            </Button>

            <ButtonSimple
              type="button"
              className="cursor-pointer font-bold mt-4"
              ariaLabelledBy={switchNoLabel}
              onClick={() =>
                // revert the selection back to previous selectedCard
                toggle()
              }
            >
              {switchNoLabel}
            </ButtonSimple>
          </ButtonContainer>
        )}
      </ModalPopupContainer>
    </ModalContainer>
  );
}
