/* eslint-disable react/no-danger */

import { renderHTML } from '@agility/nextjs';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import Loader from '../../common/Loader/Loader';
import SendMyCardGuestForm from './SendMyCardGuestForm';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';
import PortalModal from '../../common/Modals/PortalModal';
import SuccessModal from '../../common/Modals/SuccessModal';

// Styles
import { Disclaimer } from '../FormStyles';
import { Row, PageTitle, SectionTitle, BottomMessage } from './SendMyCardStyles';

// Hooks
import useModal from '../../hooks/utils/useModal';

export default function SendMyCardContainerGuest({ fields, customData }) {
  const { title, bottomMessage, disclaimer, introMessage, getCardLabel, successMessage, successHeader, successDoneLabel } =
    fields;
  const {
    state: {
      user: { guestEmail },
    },
  } = useStateMachine();

  const dynamicData = {
    buttonLabel: successDoneLabel,
    successHeader,
    successMessage,
    hasReplaceContent: true,
    replaceRegex: '##EMAIL##',
    replaceValue: `<strong>${guestEmail || null}</strong>`,
  };

  const { isShowing, toggle } = useModal();

  if (customData && fields) {
    return (
      <section>
        {title && <PageTitle>{title}</PageTitle>}
        <Row>
          {getCardLabel && <SectionTitle className="mb-4 font-bold">{getCardLabel}</SectionTitle>}
          {introMessage && <p className="text-lg max-w-screen-md"> {introMessage} </p>}

          <SendMyCardGuestForm toggle={toggle} fields={fields} customData={customData} />
          <hr className="md:my-6 my-4" />
          {bottomMessage && (
            <BottomMessage dangerouslySetInnerHTML={renderHTML(bottomMessage)} className="max-w-screen-md pt-6" />
          )}

          <CaptchaDisclaimer />
        </Row>
        <Disclaimer dangerouslySetInnerHTML={renderHTML(disclaimer)} customStyles="sendMyCardGuestForm" />

        <PortalModal isShowing={isShowing} toggle={toggle} type="modal-confirmation">
          <SuccessModal toggle={toggle} dynamicData={dynamicData} />
        </PortalModal>
      </section>
    );
  }

  return <Loader />;
}

SendMyCardContainerGuest.propTypes = { fields: PropTypes.object, customData: PropTypes.object };
