import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updateTransactions } from '../../../store/actions';

// Components
import Button from '../../common/Button/Button';
// import Tooltip from '../../common/Tooltip/Tooltip';
import ErrorMessage from '../../common/Errors/ErrorMessage';

// Services
import { applyPin } from '../../../pages/api/services';

// Styles
import { StyledInput } from '../FormStyles';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';
import { generateErrorMessage } from '../../../helpers/errorsHelper';
import { totalPointsToLocalString } from '../../../helpers/pointsHelpers';

import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

// Hooks
import useReCaptcha from '../../hooks/services/useReCaptcha';

import useModal from '../../hooks/utils/useModal';
import SuccessModal from '../../common/Modals/SuccessModal';
import PortalModal from '../../common/Modals/PortalModal';

export default function PinForm({ customData, brand = 'NA' }) {
  const { isShowing, toggle } = useModal();
  const {
    errorsList,
    pinForm: {
      buttonLabel,
      buttonLoadingLabel,
      pinLabel,
      placeholder,
      successHeader,
      successMessage,
      successButtonLabel,
      errorTimeout,
    },
  } = customData;

  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);
  const [promoPoints, setPromoPoints] = useState(false);

  const duration = parseInt(errorTimeout) * 1000 || 3000; // timeout from Agility in milliseconds

  const {
    state: {
      session: { accessToken },
      language: { locale },
    },
    actions,
  } = useStateMachine({ updateTransactions, updateNotification });
  const { getCaptchaToken } = useReCaptcha('pin');
  const dynamicData = {
    buttonLabel: successButtonLabel,
    successHeader,
    successMessage,
    hasReplaceContent: true,
    replaceRegex: '##POINTS##',
    replaceValue: `<span class='no-wrap'> ${promoPoints} </span>`,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let result;
    setSubmitting(true);
    data.pin = data.pin.toUpperCase();
    const label = data.pin;
    const dataLayer = {
      event: 'promo_code_apply',
      category: 'Account',
      action: 'Promo Code Apply Click',
      label,
      partner: brand,
    };

    handleGAEventsBase(dataLayer);
    if (process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
      result = await getCaptchaToken();
    }

    if (result?.token || process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'true') {
      applyPin(data, { token: result?.token, ip: result?.ip, session: result?.session }, accessToken)
        .then((res) => {
          if (res?.data?.bonusPoints) {
            const pointsEarned = res?.data?.bonusPoints;
            setPromoPoints(totalPointsToLocalString(res?.data?.bonusPoints, locale));

            actions.updateTransactions({
              balance: parseInt(res?.data?.pointsBalance),
            });
            toggle();
            const dataLayerSuccess = {
              event: 'promo_code_success',
              category: 'Account',
              action: 'Promo Code Success',
              label, // This value is the promo code entered by the user
              points_earned: pointsEarned, // The points value that was applied
            };

            handleGAEventsBase(dataLayerSuccess);
            const dataLayerPopup = {
              event: 'promo_code_popup_success',
              category: 'Account',
              action: 'Promo Code Applied Pop Up Impression',
              label: 'Promo Code Applied Pop Up',
            };
            handleGAEventsBase(dataLayerPopup);
          }

          if (res?.validationErrors) {
            actions.updateNotification({
              message: generateErrorMessage(
                res?.validationErrors !== null ? Object.keys(res?.validationErrors) : ['General.BadRequest'],
                customData?.errorsList
              ),
              status: 'error',
              duration,
            });

            const dataLayerError = {
              event: 'promo_code_error',
              category: 'Account',
              action: 'Promo Code Error',
              label: Object.keys(res?.validationErrors).toString() || 'NotAvailable',
            };

            handleGAEventsBase(dataLayerError);
          }
        })
        .catch((error) => {
          if (error?.response !== null && error?.response?.data !== null) {
            actions.updateNotification({
              message: generateErrorMessage(
                error?.response?.data?.validationErrors
                  ? Object.keys(error.response.data.validationErrors)
                  : ['General.BadRequest'],
                customData?.errorsList
              ),
              status: 'error',
            });
          }
          console.error(error);
          const dataLayerError = {
            event: 'promo_code_error',
            category: 'Account',
            action: 'Promo Code Error',
            label: Object.keys(error.response?.data?.validationErrors).toString() || 'General.BadRequest',
          };

          handleGAEventsBase(dataLayerError);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      // TODO: right now do nothing if captcha fails
      console.error('ReCaptcha failed');
      const dataLayerError = {
        event: 'promo_code_error',
        category: 'Account',
        action: 'Promo Code Error',
        label: 'ReCaptcha failed',
      };

      handleGAEventsBase(dataLayerError);
      setSubmitting(false);
    }
  };

  return (
    <>
      <form className="p-0" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <label className="inputLabel" htmlFor="pin">
          {pinLabel}
        </label>

        <StyledInput
          name="pin"
          id="pin"
          aria-label="pin"
          type="text"
          maxLength={10}
          placeholder={placeholder}
          aria-required
          pinInput
          onFocus={() => {
            const dataLayer = {
              event: 'promo_code_field',
              category: 'Account',
              action: 'Promo Code Field Click',
              label: pinLabel,
            };
            handleGAEventsBase(dataLayer);
          }}
          className={`pin sm:max-w-sm mb-4 block ${errors?.pin ? ' field-has-error' : ''}`}
          {...register('pin', {
            required: {
              message: displayFieldErrorMessage(errorsList, 'Pin.Code.Required'),
              value: true,
            },
            minLength: {
              message: errorsList.filter((item) => item.code === 'Pin.Code.Length').map((item) => item.message)[0],
              value: 7,
            },
            maxLength: {
              message: errorsList.filter((item) => item.code === 'Pin.Code.Length').map((item) => item.message)[0],
              value: 10,
            },
            pattern: {
              message: displayFieldErrorMessage(errorsList, 'Pin.Code.Pattern'),
              // eslint-disable-next-line no-useless-escape

              value: /^[0-9a-zA-Z]+$/,
            },
          })}
        />
        {errors && <ErrorMessage error={errors?.pin} />}

        <div className="py-6">
          <Button
            type="submit"
            role="button"
            aria-label={buttonLabel}
            buttonLoadingText={buttonLoadingLabel}
            disabled={submitting}
            style={{ width: 'auto' }}
          >
            {submitting ? buttonLoadingLabel : buttonLabel}
          </Button>
        </div>
      </form>
      <PortalModal isShowing={isShowing} toggle={toggle} type="modal-confirmation">
        <SuccessModal toggle={toggle} dynamicData={dynamicData} />
      </PortalModal>
    </>
  );
}
PinForm.propTypes = {
  customData: PropTypes.object.isRequired,
  brand: PropTypes.string,
};
