import PropTypes from 'prop-types';

import { Transition } from 'react-transition-group';

// Components
import Button from '../common/Button/Button';
import ButtonSimple from '../common/Button/ButtonSimple';

// Styles
import { FormSaveWrapper } from './FormStyles';

const duration = 300;

const defaultStyle = {
  maxHeight: 0,
  transition: `max-height ${duration}ms ease-in`,
  overflow: 'hidden',
};

const transitionStyles = {
  entering: {
    display: 'block',
    maxHeight: '700px',
    height: 'auto',
  },
  entered: {
    display: 'block',
    maxHeight: '700px',
    height: 'auto',
  },
  exiting: {
    maxHeight: '0',
    transition: `max-height 120ms ease-in`,
  },
  exited: { maxHeight: '0' },
};

export default function FormSave(props) {
  const {
    layout,
    buttonText,
    cancelLabel,
    buttonLoadingText,
    isEditing,
    updateEditClick,
    updatePopupClose,
    submitting,
    isDisabled,
  } = props;

  return (
    <Transition
      in={isEditing}
      timeout={{
        appear: 500,
        enter: 300,
        exit: 300,
      }}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <FormSaveWrapper
            className={`flex mt-4 pb-4 ${layout === 'fullwidth' ? ' flex-col items-center' : ' items-center'}`}
            layout={layout}
          >
            <Button
              type="submit"
              buttonText={buttonText}
              buttonLoadingText={buttonLoadingText}
              submitting={submitting}
              ariaLabel={buttonText}
              disabled={isDisabled}
              style={{
                width: layout === 'fullwidth' ? '100%' : '',
                marginRight: layout === 'fullwidth' ? '' : '2rem',
              }}
            >
              {submitting ? buttonLoadingText : buttonText}
            </Button>

            {cancelLabel && (
              <ButtonSimple
                className="cursor-pointer font-bold"
                ariaLabel={cancelLabel}
                role="button"
                onClick={() => (updatePopupClose ? updatePopupClose(false) : updateEditClick(false))}
              >
                {cancelLabel}
              </ButtonSimple>
            )}
          </FormSaveWrapper>
        </div>
      )}
    </Transition>
  );
}

FormSave.propTypes = {
  layout: PropTypes.string,
  buttonText: PropTypes.string,
  cancelLabel: PropTypes.string,
  buttonLoadingText: PropTypes.string,
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  updateEditClick: PropTypes.func,
  updatePopupClose: PropTypes.func,
};
