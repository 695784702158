/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

// State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

export default function useOfferOptions(optionsList) {
  const {
    actions,
    state: {
      offers: { sortBy },
    },
  } = useStateMachine({ updateOffers });

  const [offersOptions, setOffersOptions] = useState(null);

  useEffect(() => {
    if (optionsList) {
      setOffersOptions(
        optionsList.filter((option) => option.code !== sortBy?.value).map((a) => ({ value: a.code, label: a.name }))
      );
    }
  }, [optionsList, sortBy]);

  useEffect(() => {
    if (!sortBy.value && offersOptions && offersOptions.length === optionsList.length) {
      actions.updateOffers({
        sortBy: offersOptions[0],
      });
    }
  }, [offersOptions, sortBy, optionsList]);

  return offersOptions;
}
