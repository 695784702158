import PropTypes from 'prop-types';
import { ProvincialModalHeaderContainer } from '../ModalStylesV2';

function ProvincialModalHeader({ title, titleModal }) {
  return (
    <ProvincialModalHeaderContainer id="modal-label">
      {title && <h2 className="hide-on-mobile">{title}</h2>}
      {titleModal && <h2 className="hide-on-desktop">{titleModal}</h2>}
    </ProvincialModalHeaderContainer>
  );
}

ProvincialModalHeader.propTypes = { title: PropTypes.string };

export default ProvincialModalHeader;
