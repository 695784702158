import styled from 'styled-components';

export const StyledBanner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  p {
    color: ${(props) => props.theme.lightGrayColor4};
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    margin: 0.75rem auto 2rem;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    p {
      font-size: ${(props) => props.theme.fontBody1};
      margin: 1.5rem auto 2rem;
    }
  }
`;
