import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateGiftCardsRedeem, updatePoints } from '../../store/actions';

// Styles
import Title from '../common/Headings/Title';

// Components
import GiftCardForm from './GiftCardForm';
import GiftCardFormat from './GiftCardFormat';

// Helpers
import { formatDynamicLabel } from '../../helpers/stringHelpers';

function GiftCardSelection({ customData }) {
  const { title, formatLabel, digitalButtonLabel, physicalButtonLabel } = customData;
  const {
    actions,
    state: {
      giftCards: { redeem },
      points: { added },
    },
  } = useStateMachine({ updateGiftCardsRedeem, updatePoints });

  useEffect(() => {
    if (added.length) {
      actions.updatePoints({ added: [] });

      document.querySelectorAll('input[type=radio]').forEach((e) => {
        if (e.checked) {
          e.checked = false;
        }
      });
    }
  }, [redeem.selectedFormat]);

  const handleFormatClicked = (format) => {
    actions.updateGiftCardsRedeem({ selectedFormat: format });
  };

  return (
    <div className="relative p-0 md:w-full">
      {title && (
        <Title className="title-headline-large my-4">
          {formatDynamicLabel(title, '##BRANDNAME##', redeem.giftCardDetails.brandName)}
        </Title>
      )}

      {/* TODO PULL FROM STORE */}
      <GiftCardFormat
        handleFormatClicked={handleFormatClicked}
        formatLabel={formatLabel}
        digitalButtonLabel={digitalButtonLabel}
        physicalButtonLabel={physicalButtonLabel}
      />

      <GiftCardForm customData={customData} />
    </div>
  );
}

GiftCardSelection.propTypes = {
  customData: PropTypes.object,
};

export default GiftCardSelection;
