import { useEffect, useState } from 'react';

// State
import { useStateMachine } from 'little-state-machine';

// PropTypes
import {
  fieldsPropTypes,
  filteredListPropTypes,
  resultsLabelPropTypes,
  pagesPropTypes,
  itemListNamePropTypes,
} from '../PropTypeValues/giftCardPropTypes';

// Components
import GiftCardsLoader from './GiftCardsLoader';
import GiftCardsSlider from '../common/Banners/GiftCardsSlider';
import GiftCardListItem from './GiftCardListItem';

// Style
import { ResultCount } from './GiftCardStyles';
import { Section, GridWrapper } from '../common/Banners/BannerStyles';

// Helpers
import { handleGaListByType } from '../../helpers/handleGoogleAnalyticsHelper';

function GiftCardList({ fields, filteredList, resultsLabel, pages, itemListName }) {
  const {
    state: {
      giftCards: { giftCardsList, isLoading },
    },
  } = useStateMachine();

  const [list, setList] = useState([]);
  const isCategoryPage = Boolean(fields && fields?.category_TextField);
  const totalItems = pages && pages[0]?.totalItemCount;

  useEffect(() => {
    if (filteredList) {
      setList(filteredList);
    } else {
      setList(giftCardsList);
    }

    return () => setList([]);
  });

  useEffect(() => {
    if (list?.length && fields?.isSlider !== 'true') {
      handleGaListByType(
        'gc_view_item_list',
        list,
        'rewards_giftcard_impression',
        'Gift Card Impression',
        itemListName,
        '',
        'giftcards'
      );
    }
  }, [list.length]);

  if (isLoading) {
    return <GiftCardsLoader />;
  }
  if (list.length) {
    if (fields.isSlider === 'true') {
      return <GiftCardsSlider fields={fields} filteredList={list} itemListName={itemListName} />;
    }

    return (
      <>
        {resultsLabel && (
          <ResultCount>
            {resultsLabel} {filteredList ? list.length : pages && totalItems}
          </ResultCount>
        )}
        <Section>
          <GridWrapper>
            <div className="gc-grid-row">
              {(filteredList || isCategoryPage) && (
                <GiftCardListItem list={list} fields={fields} isCategoryPage={isCategoryPage} itemListName={itemListName} />
              )}

              {!filteredList &&
                pages &&
                pages.map((page, index) => (
                  <GiftCardListItem
                    key={index}
                    list={page?.giftCards}
                    fields={fields}
                    isCategoryPage={isCategoryPage}
                    itemListName={itemListName}
                  />
                ))}
            </div>
          </GridWrapper>
        </Section>
      </>
    );
  }

  return null;
}

GiftCardList.propTypes = {
  fields: fieldsPropTypes,
  filteredList: filteredListPropTypes,
  resultsLabel: resultsLabelPropTypes,
  pages: pagesPropTypes,
  itemListName: itemListNamePropTypes,
};
GiftCardList.defaultProps = { filteredList: null };
export default GiftCardList;
