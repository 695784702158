import PropTypes from 'prop-types';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import FeatureBanner from '../../common/Banners/FeatureBanner';

function FeaturedOffers({ customData, featuredOffer, languageCode }) {
  return (
    <WrapperLayout className="global-content-wrapper mt-4 sm:mt-0">
      <FeatureBanner page="Offers" customData={customData} banners={featuredOffer.slice(0, 1)} languageCode={languageCode} />
    </WrapperLayout>
  );
}

FeaturedOffers.propTypes = { customData: PropTypes.object, featuredOffer: PropTypes.object, languageCode: PropTypes.string };

export default FeaturedOffers;
