import PropTypes from 'prop-types';

// Styles
import { FilterModalPopupFooter } from '../ModalStyles';
import FilterActions from './FilterActions';

function FilterModalFooter({ clearAllLabel, handleClickReset, applyFiltersLabel, handleApplyFilter }) {
  return (
    <FilterModalPopupFooter>
      <FilterActions
        clearAllLabel={clearAllLabel}
        handleClickReset={handleClickReset}
        applyFiltersLabel={applyFiltersLabel}
        handleApplyFilter={handleApplyFilter}
      />
    </FilterModalPopupFooter>
  );
}

FilterModalFooter.propTypes = {
  clearAllLabel: PropTypes.string,
  handleApplyFilter: PropTypes.func,
  handleClickReset: PropTypes.func,
  applyFiltersLabel: PropTypes.string,
};

export default FilterModalFooter;
