import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// Global state
import { useStateMachine } from 'little-state-machine';
import { updateInlineMessage } from '../../../store/actions';

import InlineMessageContent from './InlineMessageContent';

// Hooks
import useStorage from '../../hooks/navigation/useStorage';

// Helpers
import { deriveAuthMsg, derivePublicMsg } from '../../../helpers/inlineMessageHelpers';

export default function InlineMessage({ userIM, closeLabel }) {
  const {
    actions,
    state: { location, inlineMessage, language },
  } = useStateMachine({ updateInlineMessage });

  const [content, setContent] = useState(null);
  // const [locale, setLocale] = useState(language?.locale);

  const { getItem, setItem } = useStorage();
  const inactiveVersions = getItem('inactiveVersions') ? JSON.parse(getItem('inactiveVersions')) : [];
  const inactiveContent = getItem('inactiveContent') ? JSON.parse(getItem('inactiveContent')) : [];

  // ON MOUNT

  useEffect(() => {
    if (!inlineMessage) {
      // 1st load (we have no data in LSM
      actions.updateInlineMessage({ pub: null, auth: null, isActive: false });
    }
  }, []);

  // Language Switch
  useEffect(() => {
    if (location?.province) {
      const authMsg = deriveAuthMsg(userIM, location?.province, inactiveVersions);

      if (authMsg) {
        if (inactiveContent.includes(authMsg.contentID)) {
          actions.updateInlineMessage({ isActive: false });
          inactiveVersions.push(authMsg.version);
          setItem('inactiveVersions', JSON.stringify(inactiveVersions));
          setContent(null);
        } else {
          setContent(authMsg);
        }
      }
    } else {
      const publicIM = derivePublicMsg(userIM, inactiveVersions);
      if (publicIM) {
        if (inactiveContent.includes(publicIM.contentID)) {
          actions.updateInlineMessage({ isActive: false });
          inactiveVersions.push(publicIM.version);
          setItem('inactiveVersions', JSON.stringify(inactiveVersions));
          setContent(null);
        } else {
          setContent(publicIM);
        }
      }
    }
  }, [language?.locale]);

  // Public version
  useEffect(() => {
    const publicIM = derivePublicMsg(userIM, inactiveVersions);
    if (publicIM) {
      if (!inlineMessage?.pub || inlineMessage?.pub !== publicIM?.version) {
        actions.updateInlineMessage({ pub: publicIM?.version, isActive: true });
        setContent(publicIM);
      } else if (!content) {
        setContent(publicIM);
      }
    } else {
      actions.updateInlineMessage({ pub: null, isActive: false });
      setContent(null);
    }
  }, [inlineMessage?.pub]);

  // Auth version
  useEffect(() => {
    if (location?.province) {
      const authMsg = deriveAuthMsg(userIM, location?.province, inactiveVersions);
      if (authMsg) {
        if (!inlineMessage?.auth || inlineMessage?.auth !== authMsg?.version) {
          actions.updateInlineMessage({ auth: authMsg?.version, isActive: true });
          setContent(authMsg);
        } else if (!content) {
          setContent(authMsg);
        }
      } else {
        actions.updateInlineMessage({ auth: null, isActive: false });
        setContent(null);
      }
    }
  }, [location?.province]);

  const handleCloseClick = () => {
    actions.updateInlineMessage({ isActive: false });
    inactiveVersions.push(content.version);
    inactiveContent.push(content.contentID);
    setItem('inactiveVersions', JSON.stringify(inactiveVersions));
    setItem('inactiveContent', JSON.stringify(inactiveContent));

    setContent(null);
  };

  if (inlineMessage?.isActive) {
    return <InlineMessageContent content={content} handleClick={handleCloseClick} closeLabel={closeLabel} />;
  }

  return null;
}

InlineMessage.propTypes = {
  userIM: PropTypes.array,
  closeLabel: PropTypes.string,
};
