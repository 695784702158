import PropTypes from 'prop-types';

export const fieldsPropTypes = PropTypes.shape({
  title: PropTypes.string,
  category: PropTypes.array,
  allGiftCardsLink: PropTypes.object,
  isSlider: PropTypes.string,
  maximumSlides: PropTypes.string,
  ptsLabel: PropTypes.string,
  category_TextField: PropTypes.string,
  category_ValueField: PropTypes.string,
});

export const languageCodePropTypes = PropTypes.string;

export const filteredListPropTypes = PropTypes.array;

export const resultsLabelPropTypes = PropTypes.string;

export const itemListNamePropTypes = PropTypes.string;

export const pagesPropTypes = PropTypes.array;
