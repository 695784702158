/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

import { validateFieldWithRegex, validateFieldError } from '../../../helpers/formHelpers';

import { ShowPasswordWrapper, StyledPasswordInput } from '../FormStyles';
import PasswordRules from '../PasswordRules';

function FormPasswordInput({
  pwHeader,
  passwordRules,
  name,
  label,
  showLabel,
  hideLabel,
  fieldType,
  minLength,
  maxLength,
  rules,
  errorMessage,
  onClick,
  onFocus,
  onKeyDown,
  showPasswordRules,
}) {
  const { actions, state } = useStateMachine({ updateUser });
  const [inputVal, setInputVal] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const formContext = useFormContext();
  const { register, control, setValue, getValues, formState } = formContext;

  useEffect(() => {
    setValue(name, inputVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];
  const isError = validateFieldError(formState, name);
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setShowPassword(!showPassword);
    }
  };
  return (
    <>
      <FieldLayout>
        {Boolean(label) && (
          <label id={`form-${name}`} htmlFor={name}>
            {label}
          </label>
        )}
        <div className="relative">
          <StyledPasswordInput
            onFocus={onFocus}
            onClick={onClick}
            onKeyDown={onKeyDown}
            name={name}
            id={name}
            aria-invalid={!!isError}
            aria-labelledby={`form-${name}`}
            type={showPassword ? 'text' : fieldType}
            minLength={minLength || ''}
            maxLength={maxLength || ''}
            defaultValue={state.user?.[name]}
            {...register(name, {
              ...rules,
              validate: async (value) => (validateFieldWithRegex(value) === 'Invalid password' ? errorMessage : null),
            })}
            className={`${
              (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''
            }`}
            isError={Boolean(isError)}
          />
          {isError && <ErrorMessage error={error} />}

          <ShowPasswordWrapper
            role="button"
            onClick={() => setShowPassword(!showPassword)}
            onKeyDown={handleKeyDown}
            alt={showPassword ? hideLabel : showLabel}
            tabIndex="0"
          >
            {showPassword ? (
              <Image src="/assets/eye-icon-cross.svg" width={30} height={30} alt={hideLabel} className="mx-2" />
            ) : (
              <Image src="/assets/eye-icon.svg" width={30} height={20} alt={showLabel} className="mx-2" />
            )}
          </ShowPasswordWrapper>
        </div>
      </FieldLayout>

      {showPasswordRules && error && (
        <PasswordRules name={name} passwordRules={passwordRules} pwHeader={pwHeader} getValues={getValues} />
      )}
    </>
  );
}

FormPasswordInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  addressModal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  placeholder: PropTypes.string,
  errorsList: PropTypes.array,
  minLength: PropTypes.number,
  maxLength: PropTypes.string,
  rules: PropTypes.object,
  showPasswordRules: PropTypes.bool,
  pwHeader: PropTypes.string,
  passwordRules: PropTypes.array,
  showLabel: PropTypes.string,
  hideLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default FormPasswordInput;
