import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import { DescriptionText, FieldContent } from '../../FormStyles';
import FormPhoneInput from '../../fields/FormPhoneInput';
import FormSMSCheckbox from '../../fields/FormSMSCheckbox';
import Loader from '../../../common/Loader/Loader';

// Utils
import { setDisableSMSField, setSMSDefaultChecked, setSMSValues } from '../../../../helpers/settingsHelper';
import { convertToSlug } from '../../../../helpers/utils';

export default function SettingsSMSFieldContent({ customData, isEditing, errorsList, smsSavedData }) {
  const { textMessage, textMessageLabel, phoneNumberLabel, phoneOptInLabel } = customData;

  const {
    state: {
      user: { customer, preferencesData },
    },
  } = useStateMachine();

  const primaryPhoneExists = Boolean(customer.data?.contacts[0]?.primaryPhoneNumber);

  if (!customer.data || !preferencesData) return <Loader />;

  return (
    <FieldContent>
      {textMessage && (
        <DescriptionText sms className="block mb-0">
          {textMessage}
        </DescriptionText>
      )}

      {isEditing && (
        <div className="mt-4">
          <FormPhoneInput
            id="primaryPhoneNumber"
            name="primaryPhoneNumber"
            label={phoneNumberLabel}
            defaultVal={customer.data?.contacts[0]?.primaryPhoneNumber}
            placeholder={customer.data?.contacts[0]?.primaryPhoneNumber}
            isDisabled
            isReadOnly
            errorMessage={
              errorsList.filter((item) => item.code === 'Contact.PrimaryPhoneNumber.Invalid').map((item) => item.message)[0]
            }
          />

          <FormSMSCheckbox
            id={convertToSlug(textMessageLabel)}
            fieldType="checkbox"
            label={phoneOptInLabel}
            name="SMS"
            defaultChecked={setSMSDefaultChecked(preferencesData, smsSavedData)}
            alignStart
            status={setSMSValues(preferencesData)}
            isDisabled={Boolean(!primaryPhoneExists) || setDisableSMSField(preferencesData, smsSavedData)}
          />
        </div>
      )}
    </FieldContent>
  );
}

SettingsSMSFieldContent.propTypes = {
  customData: PropTypes.object,
  isEditing: PropTypes.bool,
  errorsList: PropTypes.array,
  smsSavedData: PropTypes.bool,
};
