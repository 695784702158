/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';

import Button from '../Button/Button';
import CloseIcon from '../Icons/CloseIcon';

import { formatDynamicLabel } from '../../../helpers/stringHelpers';

// Styles
import { ModalContainer, ModalIcon, ModalHeader, ModalBody, ButtonContainer, ModalPopupContainer } from './ModalStylesV2';

export default function SuccessModal({ toggle, dynamicData }) {
  const { successDoneLabel, buttonLabel, successHeader, successMessage, hasReplaceContent, replaceRegex, replaceValue } =
    dynamicData;

  return (
    <ModalContainer>
      <CloseIcon toggle={toggle} />
      <ModalPopupContainer>
        <ModalHeader>
          <ModalIcon>
            <Image src="/assets/checkmark-circle.svg" alt="" height={80} width={80} role="presentation" />
          </ModalIcon>
          {successHeader && <h2 id="modal-label">{successHeader}</h2>}
        </ModalHeader>
        {successMessage && (
          <ModalBody>
            {!hasReplaceContent ? (
              <p>{successMessage}</p>
            ) : (
              <p dangerouslySetInnerHTML={renderHTML(formatDynamicLabel(successMessage, replaceRegex, replaceValue))} />
            )}
          </ModalBody>
        )}

        {(buttonLabel || successDoneLabel) && (
          <ButtonContainer>
            <Button type="button" ariaLabel={buttonLabel || successDoneLabel} role="button" onClick={() => toggle()}>
              {buttonLabel || successDoneLabel}
            </Button>
          </ButtonContainer>
        )}
      </ModalPopupContainer>
    </ModalContainer>
  );
}

SuccessModal.propTypes = {
  toggle: PropTypes.func,
  dynamicData: PropTypes.object,
};
