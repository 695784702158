/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const ProfileSection = styled.section`
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 5rem;
  }

  @media screen and (min-width: 641px) {
    margin-bottom: 3.5rem;
    &:last-of-type {
      margin-bottom: 7.5rem;
    }
  }
`;

export const Section = styled.section`
  margin-bottom: 2.5rem;
  ${(props) =>
    props.profilePage &&
    css`
      margin-bottom: 3.5rem;
    `}
`;

export const FormInputLabel = styled.label`
  margin-top: 0.75rem;

  ${(props) =>
    props.addressModal &&
    css`
      margin-top: 1.5rem !important;
    `};
`;
export const SectionTitle = styled.h2`
  color: ${(props) => props.theme.textGray};
  text-transform: Capitalize;
  font-size: ${(props) => props.theme.fontBody2};
  padding: 0px !important;
  margin-left: 1.125rem;

  ${(props) =>
    props.profilePage &&
    css`
      text-transform: none;

      @media screen and (min-width: 641px) {
        margin-left: 0;
      }
    `}
`;
export const ProfileFieldWrapper = styled.div`
  margin-right: 4rem !important;
`;

export const Row = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 1.5rem 1.125rem;

  @media screen and (min-width: 768px) {
    padding: 0.9rem 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media screen and (max-width: 640px) {
    form {
      padding: 0;
    }
  }
`;

export const PinRow = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 2.25rem;

  @media screen and (max-width: 640px) {
    padding: 1.5rem 1.125rem;
    form {
      padding: 0;
    }
  }
`;

export const Title = styled.label`
  font-size: ${(props) => props.theme.fontBody2};

  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody3};
  }
`;
export const SettingFieldWrapper = styled.div`
  margin-right: 4rem !important;
`;
export const FieldContent = styled.div`
  padding-right: 10%;

  @media screen and (min-width: 768px) {
    padding-right: 0;
  }

  .profile-password-field {
    color: ${(props) => props.theme.lightGrayColor4};
  }

  .address-field {
    color: ${(props) => props.theme.uiBlack};
  }

  .gender-field {
    padding-bottom: 2rem;
  }

  label {
    font-size: ${(props) => props.theme.fontBody3};
    line-height: 1.35rem;
    color: ${(props) => props.theme.uiCharcoal};
  }

  p {
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight2};
    color: ${(props) => props.theme.lightGrayColor4};

    @media screen and (min-width: 768px) {
      padding-right: 0;
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
`;
export const DescriptionText = styled.p`
  font-size: ${(props) => props.theme.fontBody3};
  color: ${(props) => props.theme.lightGrayColor4};
  padding-right: 10%;

  ${(props) =>
    props.settings &&
    css`
      line-height: ${(props) => props.theme.lineHeight3} !important;
    `}

  ${(props) =>
    props.sms &&
    css`
      line-height: ${(props) => props.theme.lineHeight3} !important;
    `}

  @media screen and (min-width: 768px) {
    padding-right: 0;
    font-size: ${(props) => props.theme.fontBody2};
  }
`;
export const StyledAddressValue = styled.p`
  color: ${(props) => props.theme.lightGrayColor4};
  margin: 0;
  font-size: ${(props) => props.theme.fontBody2};
  padding-right: 10%;

  @media screen and (min-width: 641px) {
    padding-right: 0;
  }
  @media screen and (max-width: 640px) {
    padding-right: 2rem;
    font-size: ${(props) => props.theme.fontBody3};
    line-height: 1.313rem;
    word-break: break-word;
    width: 288px;
  }
`;
export const PostalCodeContainer = styled.div`
  ${(props) => (props.addressModal ? 'margin-right: -1rem !important' : '')};
`;
export const StyledInput = styled.input`
  background: transparent;
  color: ${(props) => props.theme.brandBlack};
  padding: 0.5rem 0.8rem;
  margin: 0;
  border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.brandBlack)};
  outline: none;
  box-sizing: border-box;
  border-width: 1px;
  height: 50px;

  ${(props) =>
    props.passwordInput &&
    `
    height: 20px;
    font-family: Poppins;
    font-size: 1.75rem;
    line-height: 1.313rem;
    font-weight: bolder;
    letter-spacing: 0.001em;
  `}

  ${(props) =>
    props.noStyling &&
    css`
      padding: 0 !important;
      border: 0 !important;
    `}
    ${(props) =>
    props.pinInput &&
    css`
      text-transform: uppercase;

      ::-webkit-input-placeholder {
        /* WebKit browsers */
        text-transform: none;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        text-transform: none;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        text-transform: none;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        text-transform: none;
      }
      ::placeholder {
        /* Recent browsers */
        text-transform: none;
      }
    `}
  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.uiPurple)};
  }

  &.field-is-disabled {
    background-color: ${(props) => props.theme.uiGrey02};
    border-color: ${(props) => props.theme.uiGrey02};

    &:hover,
    &:focus {
      border-width: 1px !important;
      border-color: ${(props) => props.theme.uiGrey02};
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: ${(props) => props.theme.lineHeight3};
  margin-bottom: 1rem;

  ${(props) => (props.textMessagesCheckbox ? 'margin-top: 32px !important' : '')};

  ${(props) =>
    props.alignStart &&
    css`
      align-items: flex-start;

      input {
        margin-top: 0.25rem;
      }
    `}

  p {
    margin-bottom: 0 !important;
    font-size: ${(props) => props.theme.fontBody3};
  }

  &.isDisabled {
    opacity: 0.6;

    input[type='checkbox'] {
      background-color: ${(props) => props.theme.lightGrayColor6} !important;
      border-color: ${(props) => props.theme.lightGrayColor6} !important;
    }
  }

  label {
    margin: 0;
  }

  ${(props) =>
    (props.status === 'OPT-IN-PENDING' || props.status === 'OPT-OUT-PENDING') &&
    css`
      opacity: 1 !important;

      label {
        opacity: 0.6;
        color: inherit !important;
      }
    `};

  ${(props) =>
    props.status === 'OPT-OUT-PENDING' &&
    css`
      input[type='checkbox'][disabled] {
        background-color: ${props.theme.uiGrey02} !important;
        border-color: ${props.theme.uiGrey02} !important;
      }
    `};
`;

export const FormCheckboxExtraText = styled.div`
  margin-left: 0.625rem;
  p {
    line-height: ${(props) => props.theme.lineHeight3};
  }
`;

export const StyledCheckbox = styled.input`
  outline: none;
  box-sizing: border-box;
  border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.brandBlack)} !important;

  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    outline-offset: 0;
    border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.uiPurple)};
  }

  &.field-is-disabled {
    background-color: ${(props) => props.theme.uiGrey02};
    border-color: ${(props) => props.theme.uiGrey02};

    &:hover,
    &:focus {
      border-width: 1px;
      border-color: ${(props) => props.theme.uiGrey02};
    }
  }

  ${(props) =>
    props.defaultChecked &&
    props.disabled &&
    css`
      background-image: url('/assets/checkmark-disabled.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
    `};
`;
export const SavedValue = styled.p`
  color: ${(props) => props.theme.lightGrayColor4};
  margin: 0;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontBody2};

  @media screen and (max-width: 767px) {
    padding: 0 !important;
    font-size: ${(props) => props.theme.fontBody3};
  }
`;
export const CantEdit = styled.span`
  width: 100%;
  text-align: right;
  color: ${(props) => props.theme.lightGrayColor4};
  font-size: ${(props) => props.theme.fontBody3};
`;
export const EditButtonContainer = styled.div`
  max-width: 3rem;
  width: 100%;
  text-align: center;
  right: 0;

  @media screen and (min-width: 641px) {
    max-width: 6rem;
  }
`;
export const TopicListStyles = styled.div`
  margin: 1rem auto;
  padding: 1rem 0;
  border-top: 1px solid ${(props) => props.theme.uiGrey02};
  border-bottom: 1px solid ${(props) => props.theme.uiGrey02};

  label {
    color: ${(props) => props.theme.uiCharcoal};
  }
`;
export const PhoneNumberLabel = styled.label`
  margin-top: ${(props) => (props.addressModal ? '1.5rem' : '0.75rem')} !important;
`;

export const StyledReadonlyInput = styled.input`
  width: 100%;
  border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.brandBlack)};
  outline: none;
  box-sizing: border-box;
  border-width: 1px;
  height: 50px;
  font-weight: bold;
  padding: 26px;
  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.uiPurple)};
  }

  &.field-is-disabled {
    background-color: ${(props) => props.theme.uiGrey02};
    border-color: ${(props) => props.theme.uiGrey02};

    &:hover,
    &:focus {
      border-width: 1px;
      border-color: ${(props) => props.theme.uiGrey02};
    }
  }
`;
export const LineSpacer = styled.div`
  border-bottom: 1px solid #e2e8ee;
  margin: 24px auto;
`;
export const CopyTextReadonlyWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${(props) => props.theme.uiPurple};
  }
  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    border: 1px solid ${(props) => props.theme.uiPurple};
  }
`;

export const ShowPasswordWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.875rem;
  z-index: 1;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${(props) => props.theme.uiPurple};
  }
  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    border: 1px solid ${(props) => props.theme.uiPurple};
  }
`;
export const StyledPasswordInput = styled.input`
  border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.brandBlack)};
  outline: none;
  box-sizing: border-box;
  border-width: 1px;
  height: 50px;

  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.uiPurple)};
  }

  &.field-is-disabled {
    background-color: ${(props) => props.theme.uiGrey02};
    border-color: ${(props) => props.theme.uiGrey02};

    &:hover,
    &:focus {
      border-width: 1px;
      border-color: ${(props) => props.theme.uiGrey02};
    }
  }
`;
export const PasswordRulesWrapper = styled.div`
  margin-top: 1rem;

  h2 {
    text-align: left !important;
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontBody3};
    font-weight: bold;
  }
  p {
    font-weight: bold;
    color: black;
    font-size: ${(props) => props.theme.fontBody3};
    margin-bottom: 0;
  }
`;
export const PasswordRulesList = styled.ul`
  position: relative;
  list-style: none;
  padding-left: 0;
  margin: 0.75rem auto 1rem;

  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
    font-size: ${(props) => props.theme.fontBody3};
    color: ${(props) => props.theme.lightGrayColor4};

    &:before {
      position: absolute;
      left: 0;
      top: 2px;
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 9999px;
      background-color: ${(props) => props.theme.lightGrayColor4};
    }
  }
  li.valid:before {
    background-color: transparent;
    background-image: url('/assets/green-checkmark-ellipse.svg');
  }
  li.invalid:before {
    background-color: transparent;
    background-image: url('/assets/red-x-ellipse.svg');
  }
`;
export const LoyaltySceneCheckboxLabel = styled.div`
  color: ${(props) => props.theme.lightGrayColor4};
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  font-size: ${(props) => props.theme.fontBody3};
  line-height: ${(props) => props.theme.lineHeight3};
`;
export const FormFooterContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.whiteColor};

  @media screen and (min-width: 641px) {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.11);
  }
  @media screen and (max-width: 640px) {
    height: 7.5rem;
  }
`;

export const FormFooterInner = styled.div`
  a {
    font-weight: bold;
    text-decoration: underline;
  }
  padding: 2rem 1rem;
  @media screen and (max-width: 640px) {
    box-shadow: 0px 2px 10px rgba(0, 34, 91, 0.11);
    background-color: ${(props) => props.theme.whiteColor};
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
`;
export const FormHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 2rem;
  position: relative;
  max-width: 23.75rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 640px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 767px) {
    max-width: 21.25rem;
  }
`;
export const ResultContainer = styled.div`
  display: flex;
  align-content: center;
  text-align: center;
`;
export const TitleWithIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  h2 {
    position: relative;
    margin-bottom: 0 !important;
    margin-right: 10px;
    top: 6px;
  }
`;

export const FormRegisterTitle = styled.h1`
  font-weight: bold;
  font-size: ${(props) => props.theme.headingMedium};
  line-height: 2.5rem;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.headingMedSmall};
    line-height: 2.2rem;
  }
`;
export const AgreeField = styled.div`
  margin-top: 1rem;
`;

export const GKActivation = styled.div`
  background-color: #f5f5f5;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  margin: 2rem 0;
  padding: 1.5rem 8rem 0.5rem 1.5rem;
  min-height: 8.125rem;
  @media screen and (max-width: 640px) {
    padding: 1rem 5.625rem 0.5rem 1rem;
  }
  box-sizing: border-box;
  .input-header {
    line-height: ${(props) => props.theme.lineHeight3};
    margin: 0;
    display: block;
  }
  .gk-tooltip {
    @media screen and (min-width: 641px) {
      max-width: 100%;
    }
  }
`;

export const Disclaimer = styled.div`

  ${(props) => (props.isSetting ? 'margin: 2rem 0 7.5rem ' : 'margin: 2rem 0')} !important;

  p {
    color: ${(props) => props.theme.grayColor4};
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
  }

  @media screen and (max-width: 640px){
    ${(props) => (props.mobileSetting ? 'margin: 1.5rem 0 3.688rem' : 'margin: 0')} !important;

    font-size: ${(props) => props.theme.fontLegal};
    }
  }

  ${(props) =>
    props.customStyles === 'pinForm' &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 6rem;
      @media screen and (min-width: 641px) and (max-width: 990px) {
        padding-top: 1rem;
        padding-bottom: 8rem;
      }
      @media screen and (max-width: 640px) {
        padding-top: 1.5rem;
        padding-bottom: 5rem;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
      }
    `}

  ${(props) =>
    props.customStyles === 'sendMyCardGuestForm' &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 6rem;
      @media screen and (min-width: 641px) and (max-width: 990px) {
        padding-top: 1rem;
        padding-bottom: 8rem;
      }
      @media screen and (max-width: 640px) {
        padding-top: 1.5rem;
        padding-bottom: 5rem;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
      }
    `}
   
    ${(props) =>
      props.customStyles === 'myCardForm' &&
      css`
        margin: 0;
        padding-top: 1.5rem;
        padding-bottom: 7.5rem;

        @media screen and (max-width: 640px) {
          padding-bottom: 5rem;
          padding-left: 1.125rem;
          padding-right: 1.125rem;
        }
      `}
  ${(props) =>
    props.customStyles === 'unsubscribe' &&
    css`
      margin: 0 !important;
      padding-top: 2.5rem;
      padding-bottom: 1rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
      }
      @media screen and (max-width: 640px) {
        padding-top: 1.5rem;
        padding-bottom: 1rem;
      }
    `}
`;

export const GuestSettingsButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;

  .primary-button {
    width: auto !important;
  }
`;

export const FormSaveWrapper = styled.div`
  @media screen and (max-width: 767px) {
    .primary-button {
      padding: 1rem;
      margin-top: 0;
      margin-bottom: 2px;
      width: auto !important;
      min-width: 150px;
    }
  }

  ${(props) =>
    props.layout === 'fullwidth' &&
    css`
      .primary-button {
        width: 100% !important;
        margin-bottom:1.5rem;
      `}
`;
