// Global State
import { useStateMachine } from 'little-state-machine';

// Hooks
import SpotlightAnnouncementBanner from '../common/Banners/SpotlightAnnouncementBanner';

const Spotlights = ({ module, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return <SpotlightAnnouncementBanner languageCode={languageCode} customData={module.fields} />;
  }
  return null;
};

export default Spotlights;
