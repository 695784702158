import { useQuery } from 'react-query';
import { useStateMachine } from 'little-state-machine';
import { getOfferDetails, getOfferDetailsPublic } from '../../../pages/api/services';
import { urlEncodeString } from '../../../helpers/utils';

export default function useOfferDetails(offerId, languageCode, encUniqId = null) {
  const {
    state: {
      session: { isLoggedIn, accessToken },
      navigation: { isReady },
    },
  } = useStateMachine();

  const { isLoading, error, data, refetch } = useQuery(
    ['getOfferDetails', offerId, languageCode, encUniqId, accessToken],
    async () => {
      try {
        if (offerId && languageCode) {
          // Public Offer Details
          if (isReady && encUniqId && !isLoggedIn) {
            const decodedUniqId = urlEncodeString(encUniqId);
            const res = await getOfferDetailsPublic(offerId, languageCode, decodedUniqId);
            return res?.data?.data;
          }

          if (isReady && isLoggedIn && accessToken) {
            const res = await getOfferDetails(offerId, languageCode, accessToken);

            return res?.data?.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  );

  return { isLoading, error, offerDetails: data, refetch };
}
