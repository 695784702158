import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';
import ConfirmationQuantity from './ConfirmationQuantity';
import { amountSelected } from '../../../helpers/pointsHelpers';
import { giftCardTypeLabelFrom } from '../../../helpers/giftCardsHelper';
import { formatDynamicLabel } from '../../../helpers/stringHelpers';

function ConfirmationItems({ itemsLabel, qtyLabel, title, digitalCardLabel, physicalCardLabel }) {
  const {
    state: {
      points: { added },
      giftCards: { selectedGiftCard, redeem },
    },
  } = useStateMachine();

  function itemLabel() {
    return `${formatDynamicLabel(
      title,
      '##BRANDNAME##',
      selectedGiftCard?.brandName || redeem.giftCardDetails?.brandName
    )} - ${amountSelected(added)}
      (${giftCardTypeLabelFrom(redeem.selectedFormat, digitalCardLabel, physicalCardLabel)})`;
  }

  return (
    <SectionDetails items>
      <SectionTitle className="flex-1">{itemsLabel.toUpperCase()}</SectionTitle>

      <DetailsInfo className="flex-1" items>
        <DetailsInfo>{itemLabel()}</DetailsInfo>
        <DetailsInfo>
          <ConfirmationQuantity qtyLabel={qtyLabel} quantity={redeem.quantity} />
        </DetailsInfo>
      </DetailsInfo>
    </SectionDetails>
  );
}

ConfirmationItems.propTypes = {
  itemsLabel: PropTypes.string.isRequired,
  qtyLabel: PropTypes.string.isRequired,
};
export default ConfirmationItems;
