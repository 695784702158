import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../store/actions';

// Components
import OnboardingHeader from './OnboardingHeader';
import OnboardingFooter from './OnboardingFooter';

// Styles
import { OnboardingBody, OnboardingStyles } from './OnboardingStyles';

// Utils
import { handleKeyDown } from '../../helpers/utils';

export default function OnboardingContentDetails({ content, gotItLabel, closeLabel, handleClick }) {
  const { actions } = useStateMachine({ updateUser });

  const handleOnboardingClick = (event) => {
    const {
      target: { title },
    } = event;

    event.preventDefault();

    const isValidTargetEl = title === gotItLabel || title === closeLabel;
    if (isValidTargetEl) {
      handleClick(event);
    } else {
      event.stopPropagation();
    }
    actions.updateUser({ onBoardMessageActive: false });
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <section
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="onboarding-title"
      className="relative"
      onClick={handleOnboardingClick}
      onKeyDown={(event) => handleKeyDown(event, handleOnboardingClick)}
      style={{ cursor: 'default' }}
    >
      <span className="overlay onboarding-overlay" />
      <OnboardingStyles onboardingPage={content.fields.code}>
        <OnboardingHeader title={content.fields.title} closeLabel={closeLabel} />
        <OnboardingBody>
          <p>{content.fields.message}</p>
        </OnboardingBody>
        <OnboardingFooter gotItLabel={gotItLabel} />
      </OnboardingStyles>
    </section>
  );
}

OnboardingContentDetails.propTypes = {
  content: PropTypes.object,
  gotItLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  handleClick: PropTypes.func,
};
