import styled from 'styled-components';

export const ListWrapper = styled.div`
  position: relative;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 1.25rem;
  column-gap: 1.25rem;
  @media screen and (min-width: 641px) and (max-width: 1199px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
`;

export const TileContainer = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 0.5rem;
  overflow: hidden;
  border-radius: 100%;
  display: inline-flex;
  background-color: ${(props) => props.theme.uiGrey01};
  width: 3.75rem;
  height: 3.75rem;
  @media screen and (min-width: 991px) {
    width: 5rem;
    height: 5rem;
  }
`;
