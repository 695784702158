/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormProvider, useForm } from 'react-hook-form';

// State Machine
import { useStateMachine } from 'little-state-machine';
import { updateOffersFilter } from '../../../store/actions';

// Components
import PortalModal from '../../common/Modals/PortalModal';
import FilterModal from '../../common/Modals/FilterModal/FilterModal';
import FilterButton from '../../common/Button/FilterButton/FilterButton';
import OffersFilterBody from './OffersFilterBody';

// Hooks
import useModal from '../../hooks/utils/useModal';

// Helpers
import {
  deriveOffersFiltersByProvince,
  deriveBrandsFiltersByCategory,
  categoriesString,
  checkListForItems,
} from '../../../helpers/offersHelpers';
import { updateFilterFormState } from '../../../helpers/filterHelpers';
import { removeEmptyValues } from '../../../helpers/utils';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';

export default function OffersFilter({ customData, page, disable }) {
  // track the last submitted data
  const [submittedData, setSubmittedData] = useState({});
  const [submittedChips, setSubmittedChips] = useState({});
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filterActive, setFilterActive] = useState(false);

  const { filterLabel } = customData?.pageContent || customData;
  const { state, actions } = useStateMachine({ updateOffersFilter });
  const { isShowing, toggle } = useModal();

  // React Hook Form required default field value of undefined when form is initialized
  const defaultValues = {
    category: filteredCategories.map(() => undefined),
    brands: filteredBrands.map(() => undefined),
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });

  const handleClick = () => {
    toggle();
    const dataLayer = {
      event: 'offer_filter_click',
      eventCategory: 'Earn',
      eventAction: 'Filter Click',
      eventLabel: 'Offer Filter',
      category: camelCaseToSentence(page),
    };

    handleGAEventsBase(dataLayer);
  };

  const onSubmit = async (data) => {
    const selectedFilters = { category: removeEmptyValues(data?.category), brands: removeEmptyValues(data?.brands) };

    setSubmittedData(data);
    setSubmittedChips(state?.offers?.filter?.chips);
    actions.updateOffersFilter({ ...selectedFilters });

    if (checkListForItems(selectedFilters?.category) || checkListForItems(selectedFilters?.brands)) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }

    if (checkListForItems(selectedFilters?.category)) {
      const categories = state?.offers?.filter?.chips.category.filter((item) => item !== undefined).map((item) => item?.name);

      const dataLayerCategory = {
        event: 'category_filter_field',
        eventCategory: 'Earn',
        eventAction: 'Category Filter Selection',
        eventLabel: categories.toString(),
        category: categories.toString(),
      };

      handleGAEventsBase(dataLayerCategory);
    }
    if (checkListForItems(selectedFilters?.brands)) {
      const brands = state?.offers?.filter?.chips.brands.filter((item) => item !== undefined).map((item) => item?.name);
      const dataLayerBrands = {
        event: 'brand_filter_field',
        eventCategory: 'Earn',
        eventAction: 'Brand Filter Selection',
        eventLabel: brands.toString(),
        brand: brands.toString(),
      };

      handleGAEventsBase(dataLayerBrands);
    }
  };

  const onErrors = (errors) => {
    console.error('applied filters failed', errors);
  };

  const closeModal = () => {
    if (!formMethods.formState.isSubmitted) {
      if (Object.keys(submittedData)?.length) {
        formMethods.reset({ ...submittedData });
      } else {
        formMethods.reset({ ...defaultValues });
      }

      actions.updateOffersFilter({ chips: submittedChips });
      toggle();
      return;
    }

    toggle();
  };

  const handleFormReset = () => {
    formMethods.reset({ category: [], brands: [] });
  };

  const updateFormFields = (code, type) => {
    let newFormFieldState;

    if (type === 'category') {
      newFormFieldState = updateFilterFormState(state?.offers?.filter?.chips?.category, code);
    }

    if (type === 'brands') {
      newFormFieldState = updateFilterFormState(state?.offers?.filter?.chips?.brands, code);
    }

    if (newFormFieldState) {
      formMethods.setValue(type, newFormFieldState);
    }
  };

  useEffect(() => () => actions.updateOffersFilter({ category: ['ALL'], brands: [], chips: {} }), []);

  useEffect(() => {
    if (formMethods.formState.isSubmitSuccessful) {
      formMethods.reset({ ...submittedData });
    }
  }, [formMethods.formState.isSubmitSuccessful]);

  useEffect(() => {
    if (state?.location?.province) {
      if (page === 'grocery') {
        setFilteredBrands(
          deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, 'grocery'), state.location.province)
        );

        actions.updateOffersFilter({ category: ['grocery'], brands: [], chips: {} });
      } else {
        setFilteredBrands(
          deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, 'ALL'), state.location.province)
        );

        actions.updateOffersFilter({ category: ['ALL'], brands: [], chips: {} });
      }

      formMethods.reset({ category: [], brands: [] });
    }
  }, [state?.location?.province]);

  useEffect(() => {
    // remove 'featured' filter as it is not an actual category
    const categories = deriveOffersFiltersByProvince(customData?.offersCategories, state.location?.province).slice(1);

    if (page === 'loadable') {
      setFilteredCategories(categories.filter((cat) => cat?.code !== 'loadable'));
    } else if (page === 'grocery') {
      setFilteredCategories([]);
      setFilteredBrands(
        deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, 'grocery'), state.location.province)
      );
      return;
    } else {
      setFilteredCategories(categories);
    }

    setFilteredBrands(
      deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, 'ALL'), state.location.province)
    );
  }, []);

  useEffect(() => {
    if (state.offers.filter?.chips?.category) {
      const categories = categoriesString(state?.offers?.filter?.chips?.category);

      if (page === 'grocery') {
        setFilteredBrands(
          deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, 'grocery'), state.location.province)
        );
      } else {
        setFilteredBrands(
          deriveOffersFiltersByProvince(deriveBrandsFiltersByCategory(customData?.brands, categories), state.location.province)
        );
      }
    }
  }, [state.offers?.filter?.chips?.category]);

  if (!customData?.pageContent) {
    return null;
  }

  return (
    <>
      <FilterButton
        handleClick={handleClick}
        filterLabel={filterLabel || 'Filter'} // TODO REMOVE DEFAULT!!!
        isShowing={isShowing}
        active={filterActive}
        disabled={disable}
        page={page}
      />

      <PortalModal isShowing={isShowing} toggle={closeModal} style={{ padding: 0 }} type="filter">
        <FormProvider {...formMethods}>
          <form className="relative p-0">
            <FilterModal
              toggle={closeModal}
              customData={customData}
              type="offers"
              handleSubmit={formMethods.handleSubmit(onSubmit, onErrors)}
              handleFormReset={handleFormReset}
            >
              {(applyFilter, resetFilter) => (
                <OffersFilterBody
                  customData={customData}
                  updateFormFields={updateFormFields}
                  filteredCategories={filteredCategories}
                  filteredBrands={filteredBrands}
                />
              )}
            </FilterModal>
          </form>
        </FormProvider>
      </PortalModal>
    </>
  );
}

OffersFilter.propTypes = {
  customData: PropTypes.object,
  filterLabel: PropTypes.string,
  languageCode: PropTypes.string,
  page: PropTypes.string,
  disable: PropTypes.bool,
};
