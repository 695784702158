import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { StyledButton } from './ButtonStyles';
import { enableScrolling } from '../../../helpers/behavourHelper';

const ButtonSecondary = ({
  type,
  href,
  children,
  className,
  buttonStyle,
  onClick,
  disabled,
  style,
  role,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaExpanded,
  extraSpacingTop,
  form,
}) => {
  const router = useRouter();

  const handleClick = () => {
    enableScrolling();
    if (href) {
      router.push(href);
    }
  };

  return (
    <StyledButton
      type={type}
      className={`w-full inline-block ${className || ''} ${buttonStyle || 'secondary-button'} ${disabled ? 'disabled' : ''}`}
      onClick={onClick || handleClick}
      disabled={disabled}
      aria-disabled={disabled}
      style={style}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      role={role}
      extraSpacingTop={extraSpacingTop}
      form={form}
    >
      <span>{children}</span>
    </StyledButton>
  );
};

ButtonSecondary.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  extraSpacingTop: PropTypes.bool,
  form: PropTypes.object,
};

export default ButtonSecondary;
