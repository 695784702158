import { useEffect, useState } from 'react';

export default function useOptionsList(options) {
  const [optionsList, setOptionsList] = useState(null);
  // breakdown options object to just what we need
  useEffect(() => {
    if (options && !optionsList) {
      setOptionsList(
        options.map((option) => ({
          name: option.fields.name,
          code: option.fields.code,
        }))
      );
    }
  }, []);

  return optionsList;
}
