import PropTypes from 'prop-types';
import { TableHead, Row, TableRowItem } from './TableStyles';

export default function TableHeader({ columns }) {
  return (
    <TableHead role="rowgroup">
      <Row role="row">
        {columns &&
          columns.map((column, index) => (
            <TableRowItem key={index} role="columnheader" aria-sort="none" columns={columns.length}>
              {column}
            </TableRowItem>
          ))}
      </Row>
    </TableHead>
  );
}
TableHead.propTypes = {
  columns: PropTypes.number,
};
