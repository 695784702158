import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Filter } from './FilterListStyles';

/**
 * @component
 *
 * @prop {array} filters array of objects that must have the shape {name: <string>, code:<string>}
 * @prop {function} handleFilterClicked callback handler from parent that will control the side effect of using the filter
 *
 */

export default function SingleFilterSelectable({ filters, handleFilterClicked }) {
  const [selected, setSelected] = useState(null);

  const handleFilterSelected = (e) => {
    e.preventDefault();
    setSelected(e.target.id);
    handleFilterClicked(e.target.id);
  };

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleFilterSelected(e);
    }
  };

  useEffect(() => {
    setSelected(filters[0].code);
  }, []);

  return (
    <ul className="flex flex-wrap list-none pl-0">
      {filters.map((category, index) => (
        <Filter
          key={index}
          id={category.code}
          className={`mx-3 my-2 ${category.code === selected ? 'selected' : ''}`}
          onClick={handleFilterSelected}
          onKeyDown={handleKeyDown}
          role="listitem"
          name={category.name}
        >
          {category.name}
        </Filter>
      ))}
    </ul>
  );
}

SingleFilterSelectable.propTypes = {
  filters: PropTypes.array.isRequired,
  handleFilterClicked: PropTypes.func.isRequired,
};
