/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import Image from 'next/image';
import Button from '../common/Button/Button';
import { StyledDisclaimer } from './DisclaimerStyles';

export default function Disclaimer({ message, backToTopMessage }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledDisclaimer>
      {message && <div className="disclaimer-content" dangerouslySetInnerHTML={{ __html: message }} />}

      {backToTopMessage && (
        <Button type="button" onClick={scrollToTop} ariaLabel={backToTopMessage}>
          <Image src="/assets/chevron-up-white.svg" width={14} height={14} className="inline mr-2" alt="" role="presentation" />
          {backToTopMessage}
        </Button>
      )}
    </StyledDisclaimer>
  );
}

Disclaimer.propTypes = { message: PropTypes.string, backToTopMessage: PropTypes.string };
