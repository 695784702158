import styled from 'styled-components';

export const LogoDescriptionContainer = styled.section`
  background-color: ${(props) => props.theme.uiCharcoal};
  color: ${(props) => props.theme.uiWhite};

  h2 {
    font-size: ${(props) => props.theme.fontBody2};
    margin-bottom: 1.5rem;
    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.headingExtraSmall};
    }
  }
  p {
    color: ${(props) => props.theme.uiWhite};
  }
  a.text-button-primary,
  a.text-button-primary:focus {
    color: ${(props) => props.theme.uiWhite};
    font-size: unset;
  }
  &:last-of-type {
    padding-bottom: 4.5rem;
    @media screen and (max-width: 640px) {
      padding-bottom: 2.5rem;
    }
  }
`;
export const Item = styled.div`
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.uiWhite};
  display: flex;
  align-content: flex-start;
  flex-direction: column;

  p,
  span {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3} !important;
    @media screen and (max-width: 640px) {
      p,
      span {
        font-size: ${(props) => props.theme.fontBody3};
      }
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
  @media screen and (min-width: 641px) {
    flex-direction: row;
    padding: 5rem 0;
  }
  .extra-padding {
    display: flex;
    margin-right: 1rem;
    @media screen and (min-width: 1024px) {
      margin-right: 3.875rem;
    }
  }
`;
export const IconColumn = styled.div`
  a {
    display: block;
  }
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  &.single-logo {
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 5.75rem;
      min-width: 5.75rem;
      min-height: 5.75rem;
      margin-right: 1.875rem;
    }
    @media screen and (min-width: 1024px) {
      width: 10rem;
      min-width: 10rem;
      min-height: 10rem;
      margin-right: 2.875rem;
    }
  }

  &.multiple-logos {
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 5.75rem;
      min-width: 5.75rem;
      min-height: 5.75rem;
      margin-right: 1.875rem;
    }
    @media screen and (min-width: 1024px) {
      width: 7.5rem;
      min-width: 7.5rem;
      min-height: 7.5rem;
      margin-right: 2rem;
    }
  }
`;
