import PropTypes from 'prop-types';

import { StyledBanner, StyledBannerContent, LocationsHeader } from './ComingSoonLocationsStyles';
// Components

import BrandList from '../List/BrandList';

// Helpers
import { isEmpty } from '../../../helpers/utils';
import { generateBrandListFrom } from '../../../helpers/brandsHelpers';

export default function ComingSoonLocations({ fields }) {
  const locationsList = generateBrandListFrom(fields);

  if (!isEmpty(fields) && locationsList) {
    return (
      <div className="global-content-wrapper">
        <StyledBanner>
          <StyledBannerContent>
            {fields.locationsHeader && (
              <LocationsHeader>
                <h3>{fields.locationsHeader}</h3>
              </LocationsHeader>
            )}

            <BrandList brandList={locationsList} comingSoon />
          </StyledBannerContent>
        </StyledBanner>
      </div>
    );
  }
  return null;
}
ComingSoonLocations.propTypes = {
  fields: PropTypes.object,
};
