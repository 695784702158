/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { LoadCouponButtonStyles } from './LoadCouponButtonStyles';
import { LoadOfferButtonStyles } from './LoadOfferButtonStyles';
import { StyledButton } from './ButtonStyles';

const LoadOfferButton = ({
  type,
  href,
  children,
  className,
  buttonLoadingText,
  buttonStyle,
  onClick,
  disabled,
  submitting,
  style,
  role,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaExpanded,
  loaded,
  isLoadable,
  isCouponBased,
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false); // TODO: Check --> loader is never set to true

  const { state } = useStateMachine();

  const handleClick = () => {
    if (href) {
      router.push(href);
    }
  };

  if (isCouponBased) {
    return (
      <LoadCouponButtonStyles
        type={type}
        className={`w-full flex justify-center disabled ${className || ''} ${buttonStyle || 'primary-button'}}`}
        aria-disabled={!!(submitting || loading || disabled)}
        style={{ pointerEvents: 'none' }}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        role={role}
      >
        <span>{children}</span>
      </LoadCouponButtonStyles>
    );
  }

  if (!isLoadable && !isCouponBased) {
    return (
      <LoadOfferButtonStyles
        type={type}
        className={`w-full flex justify-center disabled ${className || ''} ${buttonStyle || 'primary-button'}}`}
        onClick={handleClick}
        disabled
        aria-disabled={!!(submitting || loading || disabled)}
        style={{ pointerEvents: 'none' }}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        role={role}
        loaded={!isLoadable}
      >
        <span>{children}</span>
      </LoadOfferButtonStyles>
    );
  }

  if (loaded && !isCouponBased) {
    return (
      <LoadOfferButtonStyles
        type={type}
        className={`w-full flex justify-center ${className || ''} ${buttonStyle || 'primary-button'} 
        ${submitting || loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={onClick || handleClick}
        disabled={submitting || loading ? submitting : disabled}
        aria-disabled={!!(submitting || loading || disabled)}
        style={style}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        role={role}
        loaded={loaded}
      >
        <span>{submitting || loading ? buttonLoadingText || state.labels.loadingLabel : children}</span>
      </LoadOfferButtonStyles>
    );
  }

  return (
    <StyledButton
      type={type}
      className={`w-full flex text-center ${className || ''} ${buttonStyle || 'primary-button'} 
      ${submitting || loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick || handleClick}
      disabled={submitting || loading ? submitting : disabled}
      aria-disabled={!!(submitting || loading || disabled)}
      style={style}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      role={role}
    >
      <span className="flex content-center items-center">
        {submitting || loading ? buttonLoadingText || state.labels.loadingLabel : children}
      </span>
    </StyledButton>
  );
};

export default LoadOfferButton;

LoadOfferButton.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.array,
  className: PropTypes.string,
  buttonLoadingText: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  style: PropTypes.object,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  loaded: PropTypes.bool,
  isLoadable: PropTypes.bool,
  isCouponBased: PropTypes.bool,
};
