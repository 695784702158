import { useState, useEffect } from 'react';

// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import RegisterStep1FormLayout from '../../layouts/RegisterStep1FormLayout';
import FormPasswordInput from '../fields/FormPasswordInput';
import FormEmailInput from '../fields/FormEmailInput';
import ConfirmInput from '../fields/ConfirmInput';
import FormCheckbox from '../fields/FormCheckbox';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

// Styles
import { AgreeField } from '../FormStyles';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';

const Step1Component = (props) => {
  const {
    confirmPasswordLabel,
    passwordValidationHeader,
    emailLabel,
    passwordLabel,
    passwordRules,
    errorsList,
    termsCheckboxLabel,
    termsCheckboxLinks,
    hideLabel,
    showLabel,
  } = props;

  const { state } = useStateMachine();
  const [showPasswordRules, setShowPasswordRules] = useState(false);

  const onHandleEvents = (e) => {
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      setShowPasswordRules(true);
    } else {
      setShowPasswordRules(false);
    }
  };

  useEffect(() => {
    setShowPasswordRules(false);
  }, [setShowPasswordRules]);

  return (
    <RegisterStep1FormLayout {...props}>
      <FormEmailInput
        onFocus={onHandleEvents}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="email"
        fieldType="email"
        name="email"
        label={emailLabel}
        defaultVal={state.user.email}
        maxLength="128"
        errorMessage={displayFieldErrorMessage(errorsList, 'Contact.Email.Invalid')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Contact.Email.Required'),
            value: true,
          },
        }}
      />

      <FormPasswordInput
        onFocus={onHandleEvents}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="password"
        pwHeader={passwordValidationHeader}
        passwordRules={passwordRules}
        showPasswordRules={showPasswordRules}
        fieldType="password"
        name="password"
        label={passwordLabel}
        hideLabel={hideLabel}
        showLabel={showLabel}
        defaultVal={state.user.password}
        errorMessage={displayFieldErrorMessage(errorsList, 'Customer.Password.Invalid')}
        maxLength="64"
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.Password.Required'),
            value: true,
          },
          minLength: {
            message: passwordRules.filter((item) => item.validationCode === 'IncludeChars').map((item) => item.validationLabel),
            value: 8,
          },
        }}
      />

      <ConfirmInput
        onFocus={onHandleEvents}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="confirmPassword"
        fieldType="password"
        name="confirmPassword"
        label={confirmPasswordLabel}
        hideLabel={hideLabel}
        showLabel={showLabel}
        fieldWatch="password"
        defaultVal={state.user.confirmPassword}
        maxLength="64"
        errorMessage={displayFieldErrorMessage(errorsList, 'Customer.Password.ConfirmNotMatch')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.Password.ConfirmRequired'),
            value: true,
          },
          minLength: {
            message: passwordRules.filter((item) => item.validationCode === 'IncludeChars').map((item) => item.validationLabel),
            value: 8,
          },
        }}
      />

      <AgreeField>
        <FormCheckbox
          id="termsCheckbox"
          fieldType="checkbox"
          label={termsCheckboxLabel}
          extraText={termsCheckboxLinks}
          name="termsCheckbox"
          rules={{
            required: {
              message: displayFieldErrorMessage(errorsList, 'Customer.Terms.Required'),
              value: true,
            },
          }}
        />
        <CaptchaDisclaimer />
      </AgreeField>
    </RegisterStep1FormLayout>
  );
};

export default Step1Component;
