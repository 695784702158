import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import { updateOffers } from '../../store/actions';

// Components
import OfferDetailsModal from '../common/Modals/OfferDetailsModal';
import OffersTabs from '../Offers/OffersTabs';
import OfferTabSelector from '../Offers/OfferTabSelector';

// Helpers
import { deriveOffersFiltersByProvince, deriveOffersFrom, deriveReqBodyFrom } from '../../helpers/offersHelpers';
import { insertParam, updateURI } from '../../helpers/navigationHelpers';

// Hooks
import useOffers from '../hooks/offers/useOffers';

const OffersPage = ({ customData, languageCode, module }) => {
  const { offersCategories } = customData;
  // get module fields
  const { fields } = module;
  const {
    actions,
    state: {
      session: { isLoggedIn },
      offers,
      navigation,
      location,
    },
  } = useStateMachine({ updateOffers });

  useEffect(() => {
    if (offersCategories) {
      actions.updateOffers({ offersCategories });
    }

    if (!window.location.search.includes('category=')) {
      // if route comes with no category we assume featured page
      actions.updateOffers({ selectedFilters: 'featured' });

      if (!window.location.hash) {
        updateURI(insertParam('category', 'featured'));
      }
    }

    if (navigation?.query?.category) {
      const categories = deriveOffersFiltersByProvince(offers?.offersCategories, location?.province).map((cat) => cat.code);
      // capture the category coming from the router // offers?category='whatever'
      if (!categories.includes(navigation?.query?.category)) {
        actions.updateOffers({ selectedFilters: 'featured' });
      } else {
        actions.updateOffers({ selectedFilters: navigation.query.category });
      }
    }
  }, []);

  const defaultSorting = offers?.selectedFilters === 'featured' ? fields?.featuredOffersSort : '';
  const [reqBody, setReqBody] = useState(deriveReqBodyFrom(offers && offers.selectedFilters, defaultSorting, 1, 50, null, null));

  useEffect(() => {
    if (offers?.filter?.category || (offers?.filter?.brands && offers?.selectedFilters)) {
      if (!offers.filter.category.includes('ALL')) {
        setReqBody(
          deriveReqBodyFrom(
            offers && offers.selectedFilters,
            defaultSorting,
            1,
            50,
            offers?.filter?.category || null,
            offers?.filter?.brands || null
          )
        );
      }
    }
  }, [offers?.filter?.category, offers?.filter?.brands, offers?.selectedFilters]);

  const { offers: offersData, isLoading, error } = useOffers(languageCode, reqBody);

  useEffect(() => {
    if (!isLoading && offersData) {
      actions.updateOffers({ ...deriveOffersFrom(offersData, offers.selectedFilters), isLoading });
    }

    actions.updateOffers({ isLoading });
  }, [offersData, isLoading]);

  useEffect(() => {
    if (offers.selectedFilters) {
      setReqBody(deriveReqBodyFrom(offers && offers.selectedFilters, defaultSorting, 1, 50, null, null));
    }
  }, [offers?.selectedFilters]);

  useEffect(() => {
    const categoryParam = navigation?.query?.category || new URLSearchParams(window?.location?.search).get('category');

    if (location?.province && categoryParam) {
      const categories = deriveOffersFiltersByProvince(offers?.offersCategories, location?.province).map((cat) => cat.code);

      // When category currently not in the list of provincial segmented categories, redirect to offers home page
      if (!categories.includes(categoryParam)) {
        actions.updateOffers({ selectedFilters: 'featured' });
        updateURI(insertParam('category', 'featured'));
      }
    }
  }, [location?.province]);
  const bankSlider = {
    disableBNSSLider: fields.disableBNSSlider === 'true',
    title: fields.bankTitle || null,
    message: fields.bankMessage || null,
    disableCardsLink: fields.disableCardsLink === 'true',
    cardLinkLabel: fields.bankCardLinkLabel || null,
    disableCta: fields.disableSliderCTA === 'true',
    cTALink: fields.sliderCTALink || null,
    cTALinkLabel: fields.sliderCTALinkLabel || null,
    cTALinkLabelLoading: fields.sliderCTALinkLabelLoading || null,
    slides: customData.scotiabankcards,
    gaCategory: fields.gACategory || '',
  };
  const partners = {
    partnersTitle: fields.partnersTitle || null,
    partnersMessage: fields.partnersMessage || null,
    partnersPageLink: fields.partnersPageLink || null,
    partners: fields.partners,
  };

  const featuredCards = {
    featuredCardsTitle: fields.featuredCardsTitle || null,
    seeAllCardsLink: fields.seeAllCardsLink || null,
  };
  if (
    isLoggedIn &&
    offers &&
    offers.offersCategories &&
    offers.offersCategories.length &&
    (window.location.search.includes('category=') || window.location.hash)
  ) {
    return (
      <>
        <OfferTabSelector />
        <OffersTabs
          customData={customData}
          languageCode={languageCode}
          partners={partners}
          bankSlider={bankSlider}
          featuredCards={featuredCards}
        />
        <OfferDetailsModal fields={customData.pageContent} languageCode={languageCode} />
      </>
    );
  }
  return null;
};

OffersPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'offerspagecontentv2',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.offersCategories) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersCategories',
        sortIDField: 'offersCategories_SortIdField',
      });
    }

    if (contentItem.fields.offersSort) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersSort',
        sortIDField: 'offersSort_SortIdField',
      });
    }

    if (contentItem.fields.offersTags) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersTags',
      });
    }

    if (contentItem.fields.evergreenOfferIcons) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'evergreenOfferIcons',
      });
    }

    if (contentItem.fields.brandCategories) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'brandCategories',
      });
      contentItem.fields.brandCategories.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    }

    const rawBannerList = await api.getContentList({
      referenceName: 'offersstaticbanners',
      languageCode,
    });
    const rawBankCardList = await api.getContentList({
      referenceName: 'scotiabankcards',
      languageCode,
    });
    rawBankCardList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    const rawFeaturedCardsList = await api.getContentList({
      referenceName: 'featuredcards',
      languageCode,
    });
    rawFeaturedCardsList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      pageContent: contentItem.fields,
      scotiabankcards: rawBankCardList,
      featuredCardsList: rawFeaturedCardsList,
      bannerList:
        rawBannerList.map((m) => ({
          icon: m.fields.iconWeb,
          message: m.fields.message,
          ctaLabel: m.fields.cTALabel,
          ctaLink: m.fields.cTALink,
          category: m.fields.category_TextField,
        })) || null,

      offersCategories:
        contentItem.fields.offersCategories.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
          provinces: m.fields.provinces_TextField || '',
        })) || null,
      offersSort:
        contentItem.fields.offersSort.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      offersTags:
        contentItem.fields.offersTags.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      brands:
        contentItem.fields.brandCategories.map((m) => ({
          code: m.fields.code,
          name: m.fields.title,
          provinces: m.fields.provinces_TextField || '',
          categories: m.fields.brandCategories_TextField || '',
        })) || null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

OffersPage.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  module: PropTypes.object,
};

export default OffersPage;
