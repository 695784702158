import { useStateMachine } from 'little-state-machine';
import styled from 'styled-components';
import PrevSVG from '../../public/assets/left-arrow.svg';
import updateStepAction from '../../store/actions/updateStepAction';

const PrevIcon = styled.button`
  position: absolute;
  left: 0;
  top: 5px;
  text-align: center;
  background-color: transparent;
  padding: 0;
  max-width: 21px;
`;

export default function GoBackButton({ backButton }) {
  const { actions } = useStateMachine({ updateStepAction });

  const handleClick = () => {
    actions.updateStepAction({ step: 1 });
  };

  return (
    <PrevIcon type="button" aria-label={backButton} onClick={handleClick}>
      <PrevSVG height={21} width={20} />
    </PrevIcon>
  );
}
