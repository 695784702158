import styled, { css } from 'styled-components';

export const Filter = styled.button`
  height: 2.813rem;
  width: unset;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;

  border-radius: 0;
  border: 1px solid ${(props) => props.theme.uiGrey04};

  font-size: ${(props) => props.theme.fontBody2};
  text-align: left;

  color: ${(props) => props.theme.uiCharcoal};
  justify-content: center;

  transform: all 0.2s ease-in;
  background-color: transparent;

  span {
    margin-right: 0.5rem;
    font-weight: 400;
    line-height: ${(props) => props.theme.lineHeight3} !important;
    font-size: ${(props) => props.theme.fontBody2};
  }
  &:after {
    display: inline-flex;
    content: '';
    background-image: url('/assets/points-filter-default.svg');
    background-size: 1.5rem 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
  }

  &:active:after {
    background-image: url('/assets/points-filter-filtered.svg');
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.uiPurple} !important;
  }

  &.disabled {
    color: ${(props) => props.theme.lightGrayColor4} !important;
    background-color: ${(props) => props.theme.uiGrey02} !important;

    border: 1px solid ${(props) => props.theme.uiGrey04} !important;
  }
  &.disabled:after {
    background-image: url('/assets/points-filter-disabled.svg');
  }
  ${(props) =>
    props.active &&
    css`
      &:after {
        background-image: url('/assets/points-filter-filtered.svg');
      }
    `};
`;
