import Link from 'next/link';
import ExternalImage from '../../Images/ExternalImage';

import { handleKeyDown } from '../../../../helpers/utils';
import { handleGAEvents, getRewardBrandDetails } from '../../../../helpers/handleGoogleAnalyticsHelper';
import { BrandTileWrap } from './BrandTileStyles';

export default function BrandTile({ id, item, sectionTitle, languageCode, isSlider }) {
  const { code, title, imageSrc, link, linkText } = item;

  const handelOnClick = () => {
    handleGAEvents(linkText, 'Reward', 'Shop To Reward Impression Interaction', 'redeem_home_reward_click');
    getRewardBrandDetails('shoptoredeem_select_item', code, title, sectionTitle, id);
  };

  return (
    <li>
      <Link
        href={link || ''}
        locale={languageCode}
        role="link"
        title={linkText || ''}
        aria-label={`${sectionTitle}: ${title}`}
        onClick={handelOnClick}
        onKeyDown={(e) => handleKeyDown(e, handelOnClick)}
        tabIndex="0"
      >
        <BrandTileWrap isSlider={isSlider}>
          <ExternalImage url={imageSrc} fill className="object-contain object-center" />
        </BrandTileWrap>

        {title && <span className=" mt-3.5">{title}</span>}
      </Link>
    </li>
  );
}
