import { useState } from 'react';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

import { TransactionFilterType } from '../../PointsHistoryStyles';
import DatePickerFieldFrom from './DatePickerFieldFrom';
import DatePickerFieldTo from './DatePickerFieldTo';

export default function PointsDateFilter({ applyFilter, resetFilter, customData, languageCode }) {
  const { dateLabel, fromLabel, toLabel, selectLabel, ariaLabelChooseDate, ariaLabelNextMonth, ariaLabelPreviousMonth } =
    customData;
  const {
    state: {
      points: { fromDate, toDate },
    },
  } = useStateMachine();

  const [shouldCloseFromCalendar, setShouldCloseFromCalendar] = useState(false);
  const [shouldCloseToCalendar, setShouldCloseToCalendar] = useState(false);

  if (!fromDate && !toDate) return null;

  return (
    <TransactionFilterType>
      {dateLabel && <h4>{dateLabel}</h4>}

      <DatePickerFieldFrom
        name="date-filter-from"
        type="fromDate"
        label={fromLabel}
        placeholder={selectLabel}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        languageCode={languageCode}
        ariaLabelChooseDate={ariaLabelChooseDate}
        ariaLabelNextMonth={ariaLabelNextMonth}
        ariaLabelPreviousMonth={ariaLabelPreviousMonth}
        setShouldCloseToCalendar={setShouldCloseToCalendar}
        shouldCloseToCalendar={shouldCloseToCalendar}
        shouldCloseFromCalendar={shouldCloseFromCalendar}
      />

      <DatePickerFieldTo
        name="date-filter-to"
        type="toDate"
        label={toLabel}
        placeholder={selectLabel}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        languageCode={languageCode}
        ariaLabelChooseDate={ariaLabelChooseDate}
        ariaLabelNextMonth={ariaLabelNextMonth}
        ariaLabelPreviousMonth={ariaLabelPreviousMonth}
        setShouldCloseFromCalendar={setShouldCloseFromCalendar}
        setShouldCloseToCalendar={setShouldCloseToCalendar}
        shouldCloseFromCalendar={shouldCloseFromCalendar}
        shouldCloseToCalendar={shouldCloseToCalendar}
      />
    </TransactionFilterType>
  );
}
PointsDateFilter.propTypes = {
  customData: PropTypes.object.isRequired,
  applyFilter: PropTypes.bool,
  resetFilter: PropTypes.bool,
  languageCode: PropTypes.string,
};
