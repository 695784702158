import { useEffect, useRef } from 'react';

import Slider from 'react-slick';

// State
import { useStateMachine } from 'little-state-machine';

// Styles
import { SliderSection } from '../../GiftCards/GiftCardStyles';
import { CarouselWrapper } from './BannerStyles';

// Components
import GeneralGiftCard from '../Cards/GeneralGiftCard';
import { fieldsPropTypes, filteredListPropTypes, itemListNamePropTypes } from '../../PropTypeValues/giftCardPropTypes';

// Helpers
import { handleGaEcommImpressionList } from '../../../helpers/handleGoogleAnalyticsHelper';
import { giftcardSliderSettings } from '../../../helpers/bannersHelper';
import { deriveListFrom } from '../../../helpers/giftCardsHelper';

const GiftCardsSlider = ({ fields, filteredList, itemListName }) => {
  const {
    state: {
      giftCards: { giftCardsList },
    },
  } = useStateMachine();

  // TODO: move this into global and just use the totalItemsCount from the giftcards response instead?
  const maxNum = fields.maximumSlides ? fields.maximumSlides : 12;

  const { list, offersNum } = deriveListFrom(filteredList, giftCardsList, maxNum);
  const settings = giftcardSliderSettings(list.length);

  function usePreviousList(arr) {
    const ref = useRef();
    useEffect(() => {
      ref.current = arr;
    });
    return ref.current;
  }

  const previousList = usePreviousList(list);

  useEffect(() => {
    if (
      list?.length &&
      fields?.isSlider === 'true' &&
      previousList?.length >= 0 &&
      JSON.stringify(previousList) !== JSON.stringify(list)
    ) {
      handleGaEcommImpressionList('gc_view_item_list', list, 'rewards_giftcard_impression', 'Gift Card Impression', itemListName);
    }
  }, [list]);

  if (list && giftCardsList.length > 0) {
    return (
      <SliderSection>
        <CarouselWrapper offersNum={list.length} isGiftCards>
          <Slider {...settings}>
            {list?.map((item, index) => (
              <GeneralGiftCard
                slider
                offersNum={offersNum}
                key={index}
                primaryImage={item.primaryImage}
                brandName={item.brandName}
                minValue={item.minValue}
                maxValue={item.maxValue}
                ptsLabel={fields.ptsLabel}
                brandId={item.brandId}
                link={fields.giftCardDetailsLink}
                list={list}
                id={`cardgrid-${index}`}
              />
            ))}
          </Slider>
        </CarouselWrapper>
      </SliderSection>
    );
  }
  return null;
};

GiftCardsSlider.propTypes = { fields: fieldsPropTypes, filteredList: filteredListPropTypes, itemListName: itemListNamePropTypes };

export default GiftCardsSlider;
