import { useState } from 'react';

// Hooks
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import TabSelector from '../../common/Tabs/TabsSelector';

// Helpers
import { insertParam, updateURI } from '../../../helpers/navigationHelpers';
import { deriveOffersFiltersByProvince } from '../../../helpers/offersHelpers';

function OfferTabSelector(props) {
  const [hideOnScroll, setHideOnScroll] = useState(true);

  const {
    actions,
    state: { offers, location },
  } = useStateMachine({ updateOffers });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
    null,
    false,
    300
  );

  const handleTabClicked = (filters) => {
    // Do not reset the page, if they click on the same tab/load same tab
    if (offers.selectedFilters !== filters) {
      updateURI(insertParam('category', filters));
      actions.updateOffers({ moreOffersList: [], selectedFilters: filters });
    }
  };
  if (offers?.offersCategories.length && location?.province) {
    const categories = deriveOffersFiltersByProvince(offers?.offersCategories, location?.province);
    return <TabSelector tabs={offers && categories} handleTabClicked={handleTabClicked} isSlider />;
  }
  return null;
}

export default OfferTabSelector;
