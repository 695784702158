import { generateGCErrorMessage } from './stringHelpers';

export function generateErrorMessage(messages = [], errorsList = []) {
  const filteredMessages = messages.length > 1 ? messages.filter((m) => m !== 'General.BadRequest') : messages;

  const messageString = filteredMessages.map((code) => {
    const c = code.trim();
    const rawMessage = errorsList.filter((item) => item.code === c).map((item) => item.message)[0];
    const message = rawMessage;

    return message;
  });
  // The Set object lets you store unique values of any type
  const uniqueMessages = [...new Set(messageString)];

  // just output as string
  return uniqueMessages.join('<br/>');
}

export function getValueByKey(object, agilityErrorCode) {
  return object[agilityErrorCode];
}

export function generateGCCombinedErrorMessage(validationErrors, errorsList) {
  if (validationErrors) {
    const validationError = Object.keys(validationErrors).toString();
    const periodInHours = getValueByKey(validationErrors, 'Giftcards.Order.PeriodInHours') || null;
    const allowedQuantity = getValueByKey(validationErrors, 'Giftcards.Order.AllowedQuantity') || null;
    const orderLimit = getValueByKey(validationErrors, 'Giftcards.Order.OrderLimit') || null;
    const allowedPoints = getValueByKey(validationErrors, 'Giftcards.Order.AllowedPoints') || null;

    if (
      validationError !== null &&
      periodInHours &&
      (validationError.includes('Giftcards.Order.MaximumGiftCardQuantityExceeded') ||
        validationError.includes('Giftcards.Order.MaximumOrderLimitExceeded') ||
        validationError.includes('Giftcards.Order.MaximumPointsLimitExceeded'))
    ) {
      let messageAllowedQuantity = '';
      let messageOrderLimit = '';
      let messageAllowedPoints = '';
      if (allowedQuantity && validationError.includes('Giftcards.Order.MaximumGiftCardQuantityExceeded')) {
        // This order exceeds the maximum number of Gift Cards placed within ##PERIOD## hours. Current quantity allowed: ##LIMIT##
        messageAllowedQuantity = generateGCErrorMessage(
          generateErrorMessage(['Giftcards.Order.MaximumGiftCardQuantityExceeded'], errorsList),
          allowedQuantity,
          periodInHours
        );
      }
      if (orderLimit && validationError.includes('Giftcards.Order.MaximumOrderLimitExceeded')) {
        // This order exceeds the maximum number of Points to redeem within ##PERIOD## hours. Current points allowed: ##LIMIT##
        messageOrderLimit = generateGCErrorMessage(
          generateErrorMessage(['Giftcards.Order.MaximumOrderLimitExceeded'], errorsList),
          orderLimit,
          periodInHours
        );
      }
      if (allowedPoints && validationError.includes('Giftcards.Order.MaximumPointsLimitExceeded')) {
        // This order exceeds the maximum number of Points to redeem within ##PERIOD## hours. Current points allowed: ##LIMIT##
        messageAllowedPoints = generateGCErrorMessage(
          generateErrorMessage(['Giftcards.Order.MaximumPointsLimitExceeded'], errorsList),
          allowedPoints,
          periodInHours
        );
      }

      const message = messageAllowedQuantity.concat(
        messageAllowedQuantity === '' ? '' : '<br/>',
        messageAllowedPoints,
        messageAllowedPoints === '' ? '' : '<br/>',
        messageOrderLimit
      );
      return message;
    }
  }
  return generateErrorMessage(['General.BadRequest'], errorsList);
}
