import { checkArrayForItems } from '../../../../helpers/utils';
import { DetailsSection, DetailsTitle } from '../DetailsStyles';

import { formatPoints, calcOrderPointsTotal } from '../../../../helpers/pointsHelpers';

export default function DetailsTotal({ items, totalLabel, ptsLabel, locale }) {
  if (checkArrayForItems(items)) {
    return (
      <DetailsSection>
        {totalLabel && <DetailsTitle className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{totalLabel}</DetailsTitle>}

        <p className="font-bold">
          {formatPoints(calcOrderPointsTotal(items), locale)}
          <span aria-hidden="true"> {ptsLabel} </span>
          <span className="sr-only"> Points. </span>
        </p>
      </DetailsSection>
    );
  }
  return null;
}
