/* eslint-disable react/no-danger */

import { renderHTML } from '@agility/nextjs';
import PropTypes from 'prop-types';

// Components
import Title from '../../common/Headings/Title';
import Loader from '../../common/Loader/Loader';
import PinForm from './PinForm';
import PinPartnerTitle from './PinPartnerTitle';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

// Styles
import { Section, Disclaimer, PinRow } from '../FormStyles';

export default function PinContainer({ fields, customData }) {
  const { termsWeb, title } = fields;
  if (customData && fields) {
    return (
      <Section>
        {title && (
          <Title customStyles="profilePage" className="pt-0 mb-8">
            {title}
          </Title>
        )}
        <PinRow>
          <PinPartnerTitle fields={fields} />

          <PinForm customData={customData} brand={fields.partnerLogo_TextField} />

          <CaptchaDisclaimer />
        </PinRow>

        {termsWeb && <Disclaimer dangerouslySetInnerHTML={renderHTML(termsWeb)} customStyles="pinForm" />}
      </Section>
    );
  }

  return <Loader />;
}

PinContainer.propTypes = { fields: PropTypes.object, customData: PropTypes.object };
