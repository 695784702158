import PropTypes from 'prop-types';
import FilterByDate from '../../../Filter/FilterByDate';

function PeriodFilter({ showFilter, periodLabel }) {
  if (!showFilter) return null;

  return (
    <div className="flex-1 period-column">
      <FilterByDate name="period-filter" label={periodLabel} />
    </div>
  );
}

PeriodFilter.propTypes = { showFilter: PropTypes.bool, periodLabel: PropTypes.string };

export default PeriodFilter;
