import styled, { css } from 'styled-components';

export const OnboardingStyles = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.whiteColor};
  position: absolute;
  z-index: 999;
  top:2.75rem;
  bottom: auto;
  width: 23.75rem;

  &:before {
    content: '';
    position: absolute;
    top: -1.125rem;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.75rem 1.5rem 0.75rem;
    border-color: transparent transparent ${(props) => props.theme.whiteColor} transparent;
  }

  ${(props) =>
    props.onboardingPage === 'home1' &&
    css`
      left: 0;
      &:before {
        left: 2.375rem;
      }
      @media screen and (max-width: 640px) {
        top: 0.5rem;
        width: 100%;
        &:before {
          left: 0.625rem;
        }
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        top: 1.75rem;
      }
    `};

    ${(props) =>
      props.onboardingPage === 'rewards1' &&
      css`
        top: 2.125rem;
        left: 0;
        &:before {
          left: 2.375rem;
        }
        @media screen and (max-width: 640px) {
          top: 1.5rem;
          width: 100%;
        }
      `};

  ${(props) =>
    props.onboardingPage === 'rewards2' &&
    css`
      left: 0;
      top: 2.125rem;
      &:before {
        left: 2.375rem;
      }
      @media screen and (min-width: 641px) and (max-width: 1439px) {
        right: 0;
        left: auto;
        &:before {
          right: 2.375rem;
          left: auto;
        }
      }
      @media screen and (max-width: 640px) {
        top: 0.5rem;
        width: 100%;
        &:before {
          right: 4.5rem;
          left: auto;
        }
      }
    `};

    ${(props) =>
      props.onboardingPage === 'offers1' &&
      css`
        top: 2.125rem;
        left: -1rem;
        &:before {
          left: 2.375rem;
        }
        @media screen and (max-width: 640px) {
          top: 1.5rem;
          left: 0;
          width: 100%;
          &:before {
            left: 1.625rem;
          }
        }
        @media screen and (max-width: 1023px) {
          left: 0;
        }
      `};
  }
`;
export const OnboardingHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem 0.75rem 1rem;
  align-items: flex-start;

  h3 {
    color: ${(props) => props.theme.uiBlack};
    max-width: 90%;
  }
`;
export const OnboardingBody = styled.div`
  padding: 0 1rem 2rem 1rem;

  p {
    margin: 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
  }
  @media screen and (max-width: 640px) {
    padding: 0 1rem 1rem 1rem;
    p {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;
export const OnboardingTitle = styled.h3`
  font-size: ${(props) => props.theme.fontBody1};
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
  line-height: ${(props) => props.theme.lineHeight3};
  margin: 0;
`;
export const OnboardingFooterStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: ${(props) => props.theme.uiPurple};

  span {
    cursor: pointer;
    font-size: ${(props) => props.theme.fontBody3};
    color: ${(props) => props.theme.whiteColor};
    font-weight: bold;
    margin: 0;
  }
`;
