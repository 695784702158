import styled from 'styled-components';

export const RequestCardWrapper = styled.div`
  flex-direction: column;
  padding: 2.25rem;
  color: ${(props) => props.theme.brandBlack};
  background-color: ${(props) => props.theme.whiteColor};
  width: 100%;

  @media screen and (max-width: 640px) {
    padding: 1rem;
  }
  p {
    color: ${(props) => props.theme.brandBlack};
    line-height: ${(props) => props.theme.lineHeight143};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .subheading {
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight143};
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .btn-wrapper {
    padding: 2.25rem 0 0;
  }
  .primary-button1 {
    padding: 0.5rem auto 2rem;
    width: auto !important;
  }
`;
export const FakeInput = styled.div`
  font-size: ${(props) => props.theme.fontBody3};
  line-height: ${(props) => props.theme.lineHeight143};
  background-color: ${(props) => props.theme.uiGrey02};
  padding: 0.75rem 1rem;
  width: 100%;
  overflow-x: auto;
  @media screen and (min-width: 641px) {
    max-width: 25rem;
  }
`;

export const MyCardSection = styled.section`
  @media screen and (max-width: 640px) {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }
`;
export const Divider = styled.div`
  padding: 0 2.25rem;
  @media screen and (max-width: 640px) {
    padding: 0 1rem;
  }
  hr {
    margin: 1.5rem auto;
  }
`;

export const Disclaimer = styled.div`
  p {
    color: ${(props) => props.theme.uiDarkGrey};
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight143};
  }

  padding-bottom: 7.5rem;

  @media screen and (max-width: 640px) {
    padding-bottom: 5rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: ${(props) => props.theme.fontLegal};
  }
`;
export const StyleMyCard = styled.div`
  margin-right: 4.875rem;

  @media screen and (max-width: 520px) {
    margin-right: 0rem;
  }
`;

export const PageTitle = styled.h1`
  line-height: ${(props) => props.theme.lineHeight2};
  font-size: ${(props) => props.theme.headingLarge};
  @media screen and (max-width: 375px) {
    font-size: ${(props) => props.theme.headingMedSmall};
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.headingMedSmall};
    margin-bottom: 1.375rem;
    padding: 0 1rem;
  }
  margin-bottom: 2.5rem;
`;

export const SectionTitle = styled.h2`
  line-height: ${(props) => props.theme.lineHeight2};
  font-size: ${(props) => props.theme.headingExtraSmall};
  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.headingSmall};
  }
  margin-bottom: 1.5rem;
`;

export const BottomMessage = styled.div`
  0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  p{
    line-height: ${(props) => props.theme.lineHeight3};
  font-size: ${(props) => props.theme.fontBody1};
  }

ul li {
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight3};
}
`;
