/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';

// Global State
import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import { generateGCCombinedErrorMessage } from '../../helpers/errorsHelper';

import {
  updateNotification,
  updateGiftCardsRedeem,
  updateGiftCards,
  updatePoints,
  updateTransactions,
  updateUser,
} from '../../store/actions';

// Hooks
import useDynamicHeader from '../hooks/utils/useDynamicHeader';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import GiftCardDetail from '../GiftCards/GiftCardDetail';
import GiftCardTabbedContent from '../GiftCards/GiftCardTabbedContent';
import GiftCardDetailsConfirmation from '../GiftCards/GiftCardDetailsConfirmation';
import ConfirmedRedeemed from '../Points/Shop/ConfirmedRedeemed';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';

// Services
import { saveGiftCardOrder } from '../../pages/api/services';

// Helpers
import { handleGaEcommImpression, handleGaEcommGCViewItemMapping } from '../../helpers/handleGoogleAnalyticsHelper';
import { buildGiftCardOrderBodyFrom, primaryShippingAddress } from '../../helpers/giftCardsHelper';

const GiftCardDetailsPage = ({ customData, languageCode }) => {
  const { errorsList } = customData;
  const {
    actions,
    state: {
      points: { added },
      giftCards: { selectedGiftCard, selectedGiftCardIndex, redeem },
      user: { customer, shippingAddresses, req },
      session: { isLoggedIn, accessToken },
      navigation: { query },
    },
  } = useStateMachine({
    updateNotification,
    updateGiftCardsRedeem,
    updatePoints,
    updateTransactions,
    updateGiftCards,
    updateUser,
  });

  const [submitting, setSubmitting] = useState(false);
  const [orderRequested, setOrderRequested] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!window.location.search.includes('brand')) {
      router.push(`/${languageCode}/rewards/gift-cards`);
    }

    actions.updateUser({ shippingAddresses: {}, selectedShippingAddress: {} });
    actions.updateGiftCardsRedeem({
      step: 1,
      selectedFormat: '',
      quantity: 1,
    });
  }, []);

  useEffect(() => {
    if (query?.brand) {
      if (selectedGiftCard?.brandId && selectedGiftCard?.brandId === query?.brand) {
        // select from GC list
        handleGaEcommImpression(
          'gc_view_item',
          handleGaEcommGCViewItemMapping('gc_view_item', selectedGiftCard, selectedGiftCardIndex, 1)
        );
      }
    } else if (redeem?.giftCardDetails?.brandId && selectedGiftCardIndex === null) {
      // deep link
      handleGaEcommImpression('gc_view_item', handleGaEcommGCViewItemMapping('gc_view_item', redeem.giftCardDetails, null, 1));
      actions.updateGiftCards({ selectedGiftCardIndex: -1 });
    }
  }, [query?.brand]);

  async function createGiftCardOrder() {
    setSubmitting(true);
    setOrderRequested(true);
    try {
      const address = redeem.selectedFormat === 'Physical' ? primaryShippingAddress(shippingAddresses) : null;
      const email = customer?.data?.contacts[0]?.email;
      const { selectedFormat: format } = redeem;
      const giftCard = redeem.giftCardDetails;
      const { quantity } = redeem;

      const headers = {
        'X-Client-UserAgent': req.userAgent,
        'X-Client-IPAddress': req.ip,
      };

      const data = buildGiftCardOrderBodyFrom({
        added,
        format,
        giftCard,
        email,
        quantity,
        address,
      });

      const response = await saveGiftCardOrder({ accessToken, headers, data });

      if (response.data?.data) {
        // update to step 3 and clear the submitting flag
        actions.updateGiftCardsRedeem({ step: 3 });
        actions.updateTransactions({
          orderNumber: response.data.data.orderNumber,
          balance: parseInt(response.data.data.portfolioPointBalance),
        });
      }

      if (response?.validationErrors) {
        const duration = parseInt(customData.pageContent?.errorTimeout) * 1000 || 3000; // timeout from Agility in milliseconds
        actions.updateNotification({
          message: generateGCCombinedErrorMessage(response?.validationErrors, errorsList),
          status: 'error',
          duration,
        });
      }

      setSubmitting(false);
      setOrderRequested(false);
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  }

  function updateGiftCardDetails(giftCardDetails) {
    actions.updateGiftCardsRedeem({ giftCardDetails, step: 1, selectedFormat: '', quantity: 1 });
    actions.updatePoints({ added: [] });
  }

  function deriveBrandID() {
    if (query?.brand) {
      return query?.brand;
    }
    if (window?.location?.search) {
      if (window.location.search.includes('?brand=')) {
        return window.location.search.split('?brand=')[1];
      }
    }
    return redeem?.giftCardDetails?.brandId;
  }
  useDynamicHeader(redeem.step);

  if (isLoggedIn) {
    return (
      <>
        {redeem.step === 1 && (
          <WrapperLayout className="w-full">
            <div className="global-content-wrapper">
              {customData.sitemap && <Breadcrumbs customData={customData.sitemap} title={customData.pageContent.title} />}
            </div>
          </WrapperLayout>
        )}
        <WrapperLayout className="w-full relative global-content-wrapper" isGray={redeem.step !== 1} mobileIsWhite>
          {isLoggedIn && (
            <>
              {redeem.step === 1 && (
                <div className=" md:pt-8 pt-3 lg:pb-16 md:pb-8 pb-10">
                  <GiftCardDetail
                    languageCode={languageCode}
                    brandId={deriveBrandID()}
                    customData={customData.pageContent}
                    updateGiftCardDetails={updateGiftCardDetails}
                  />
                </div>
              )}
              {redeem.step === 2 && (
                <div className="max-w-4xl mx-auto pt-6 md:pt-10 lg:pt-14 pb-20">
                  <GiftCardDetailsConfirmation
                    customData={customData}
                    handleSubmit={createGiftCardOrder}
                    submitting={submitting}
                    orderRequested={orderRequested}
                    languageCode={languageCode}
                  />
                </div>
              )}
              {redeem.step === 3 && !submitting && (
                <div className="py-10">
                  <ConfirmedRedeemed customData={customData} page="giftcard" />
                </div>
              )}
            </>
          )}
        </WrapperLayout>

        {redeem.step === 1 && <GiftCardTabbedContent pageContent={customData.pageContent} />}
      </>
    );
  }

  return null;
};

GiftCardDetailsPage.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  const api = agility;
  try {
    const contentProfile = await api.getContentList({
      referenceName: 'profileform',
      languageCode,
    });

    const contentItemList = await api.getContentList({
      referenceName: 'giftcarddetailscontent',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'Customer', 'Contact', 'Address', 'message', 'Giftcards'];

    // get sitemap...
    const sitemap = await api.getSitemap({
      channelName,
      languageCode,
    });
    const contentItem = contentItemList && contentItemList[0];
    const profileItem = contentProfile && contentProfile[0];

    return {
      pageContent: contentItem.fields,
      profileContent: profileItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      sitemap,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default GiftCardDetailsPage;
