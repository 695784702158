import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { convertToSlug } from '../../../../helpers/utils';

// Components
import FormSave from '../../FormSave';
import EditButton from '../../EditButton';
import Loader from '../../../common/Loader/Loader';

import { Row, Title, StyledInput, FieldContent } from '../../FormStyles';

export default function ProfileField(props) {
  const { fieldName, fieldType, message, disableEdit, saveLabel, cancelLabel, customerData, loading } = props;

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const [isEditing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing(!isEditing);
  };

  // TODO: do we still use this? Dangerous console log
  const onSubmit = (data) => {
    // console.log(data); <-- do nothing
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <Row className="mb-0.5">
        <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="md:flex justify-between content-center items-center">
            <div className="w-52 ProfileFieldWrapper">
              <Title className="mb-1 md:mb-0 font-bold mr-4 flex-initial">{fieldName}</Title>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                  <FieldContent className="md:p-1">
                    {message && <p className="color-black block mb-10">{message}</p>}

                    {disableEdit ? (
                      <p className="mb-0">{formMethods.getValues(fieldName) ? formMethods.getValues(fieldName) : customerData}</p>
                    ) : (
                      <StyledInput
                        type={fieldType}
                        name={fieldName}
                        className="mb-3 md:mb-0 flex-1 w-full rounded"
                        defaultValue={customerData}
                        disabled={disableEdit || !isEditing}
                        {...formMethods.register(fieldName, {
                          required: false,
                        })}
                      />
                    )}
                  </FieldContent>
                </div>

                <EditButton
                  {...props}
                  handleEditClick={handleEditClick}
                  isEditing={isEditing}
                  ariaControls={`profile-${convertToSlug(fieldName)}`}
                />
              </>
            )}
          </div>

          {!isEditing && (
            <FormSave buttonText={saveLabel} cancelLabel={cancelLabel} isEditing={isEditing} updateEditClick={handleEditClick} />
          )}
        </form>
      </Row>
    </FormProvider>
  );
}
