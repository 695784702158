import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';

// Components
import { useRouter } from 'next/router';
import Loader from '../common/Loader/Loader';
import Button from '../common/Button/Button';

// Hooks
import useStorage from '../hooks/navigation/useStorage';

// Helpers
import { b2cSignIn } from '../../b2c/b2cFunctions';
import { handleGAEvents } from '../../helpers/handleGoogleAnalyticsHelper';

export default function GuestSignInButton({ fields, languageCode, loading }) {
  const { instance, accounts, inProgress } = useMsal();
  const { setItem } = useStorage();
  const router = useRouter();

  const handleSignInClick = () => {
    handleGAEvents(fields?.brand_TextField, 'login', 'click', 'partner_login');
    // when a user clicks the login, we just want origin/path without any params since b2c cannot handle them

    // capture the params in session storage for reconstruction on return
    setItem('from_url', router.asPath);
    b2cSignIn(instance, languageCode);
  };

  // restrict showing this btn for anybody who comes from a marketing click which is a special hash ** path#/{ID} ** pattern
  if (inProgress === 'login' || inProgress === 'logout' || window.location.hash.includes('#/')) {
    return (
      <div className="flex justify-center mt-4">
        <Loader width="auto" text={fields.signinLabelLoading} />
      </div>
    );
  }
  if (accounts.length > 0 && !loading) {
    // Add your account choosing logic here
    return null;
  }

  return (
    <Button type="button" onClick={handleSignInClick} ariaLabel={fields.signinLabel}>
      {fields.signinLabel}
    </Button>
  );
}

GuestSignInButton.propTypes = { fields: PropTypes.object, languageCode: PropTypes.string, loading: PropTypes.bool };
