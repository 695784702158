/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

// Components
import Title from '../../common/Headings/Title';
import Loader from '../../common/Loader/Loader';
import ProfileAddressField from './profile-fields/ProfileAddressField';
import ProfileField from './profile-fields/ProfileField';
import ProfilePhone from './profile-fields/ProfilePhone';
import ProfileEmail from './profile-fields/ProfileEmail';
import ProfileGender from './profile-fields/ProfileGender';
import ProfilePassword from './profile-fields/ProfilePassword';

// Hooks
import useCustomer from '../../hooks/account/useCustomer';
import useCustomerAddress from '../../hooks/account/useCustomerAddress';
import useGenders from '../../hooks/account/useGenders';
import useProvinces from '../../hooks/account/useProvinces';

// Styles
import { ProfileSection, Section, SectionTitle } from '../FormStyles';

// Helpers
import { normalizeInput } from '../../../helpers/utils';

const ProfileSettingsForm = (props) => {
  const { languageCode, errorsList, ProfileSettingsForm: profileSettingsForm } = props;
  const {
    title,
    accountHeader,
    settingEmailLabel,
    emailLabel,
    settingPasswordLabel,
    passwordLabel,
    personalDetailsHeader,
    settingNameLabel,
    nameLabel,
    settingGenderLabel,
    genderLabel,
    genderMessage,
    genderUpdateMessage,
    settingPhoneLabel,
    phoneLabel,
    settingAddressesLabel,
    settingsAddressesExpandedLabel,
    addressesMessage,
    profileAddressLabel,
    shippingAddressesLabel,
  } = profileSettingsForm;

  const { actions } = useStateMachine({ updateUser });

  // TODO: Consider moving these into global and up the tree?
  const { isLoading: customerLoading, customer } = useCustomer();
  const { customerAddresses } = useCustomerAddress();
  const { genders } = useGenders(languageCode);
  const { provinces } = useProvinces(languageCode);

  useEffect(() => {
    if (!customerLoading && customer) {
      actions.updateUser({ customer: { data: customer } });
    }
  }, [customerLoading, customer]);

  const loading = !customer || !customerAddresses || !genders || !provinces;

  if (loading) {
    return (
      <Section>
        <Title customStyles="profilePage" className="pt-0 pb-8">
          {title}
        </Title>
        <Loader />
      </Section>
    );
  }

  return (
    <div>
      <Title customStyles="profilePage" className="pt-0 pb-8">
        {title}
      </Title>
      <ProfileSection>
        <SectionTitle profilePage className="mb-4 font-bold">
          {accountHeader}
        </SectionTitle>
        <ProfileEmail
          {...profileSettingsForm}
          {...props}
          customerData={customer && customer?.contacts[0]?.email}
          labelText={emailLabel}
          fieldName={settingEmailLabel}
          fieldType="email"
          loading={loading}
        />

        <ProfilePassword
          {...profileSettingsForm}
          {...props}
          customerData="12345678" // TODO:: REMOVE THIS???
          labelText={passwordLabel}
          fieldName={settingPasswordLabel}
          loading={loading}
        />
      </ProfileSection>
      <ProfileSection>
        <SectionTitle profilePage className="mb-4 font-bold">
          {personalDetailsHeader}
        </SectionTitle>
        <ProfileField
          {...profileSettingsForm}
          customerData={`${customer && customer?.firstName} ${customer && customer?.lastName}`}
          labelText={nameLabel}
          fieldName={settingNameLabel}
          fieldType="text"
          disableEdit
          loading={loading}
        />
        <ProfileGender
          {...profileSettingsForm}
          labelText={genderLabel}
          listOptions={genders}
          fieldName={settingGenderLabel}
          message={genderMessage}
          errorsList={errorsList}
          successMsg={genderUpdateMessage}
          loading={loading}
        />
        <ProfileAddressField
          {...profileSettingsForm}
          shippingAddresses={customerAddresses}
          errorsList={errorsList}
          provinces={provinces}
          languageCode={props.languageCode}
          fieldName={settingAddressesLabel}
          fieldNameExpanded={settingsAddressesExpandedLabel}
          fieldAddressName={profileAddressLabel}
          fieldShippingName={shippingAddressesLabel}
          message={addressesMessage}
          loading={loading}
        />
        <ProfilePhone
          {...profileSettingsForm}
          {...props}
          customerData={normalizeInput(customer && customer?.contacts[0]?.primaryPhoneNumber)}
          labelText={phoneLabel}
          fieldName={settingPhoneLabel}
          fieldType="text"
          loading={loading}
        />
      </ProfileSection>
    </div>
  );
};

export default ProfileSettingsForm;
ProfileSettingsForm.propTypes = {
  ProfileSettingsForm: PropTypes.object,
  languageCode: PropTypes.string,
  errorsList: PropTypes.array,
};
