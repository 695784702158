import PropTypes from 'prop-types';

import BrandLogo from '../Icons/BrandLogo';
import { ImageCircle } from './TitleStyles';

export default function TitleWithLogo({ children, logo }) {
  if (children) {
    return (
      <div className="flex items-center py-10">
        {logo && (
          <ImageCircle className="rounded-full relative overflow-hidden">
            <BrandLogo url={logo} title="" />
          </ImageCircle>
        )}
        <h1>{children}</h1>
      </div>
    );
  }
  return null;
}

TitleWithLogo.propTypes = { children: PropTypes.string, logo: PropTypes.string };
