import { postalSpace } from './utils';

export function formatDynamicLabel(label, searchvalue, newvalue) {
  const formattedString = label.replace(searchvalue, newvalue);

  return formattedString;
}

export function formatMultiplierLabel(label, factor) {
  const formattedString = label && factor ? formatDynamicLabel(label, '##FACTOR##', factor) : null;
  return formattedString;
}

export function generateAddressStringFrom(address) {
  if (address) {
    const { city, provinceCode, addressLine1, addressLine2 } = address;
    const postalCode = postalSpace(address.postalCode);
    const streetAddress =
      addressLine2 && typeof addressLine2 === 'string' ? `${addressLine1}, ${addressLine2}` : `${addressLine1},`;

    return `${streetAddress} ${city} ${provinceCode} ${postalCode}`;
  }
  return '';
}

export function generateProfileAddressStringFrom(address) {
  if (address) {
    const { city, provinceCode, addressLine1, addressLine2 } = address;
    const postalCode = postalSpace(address.postalCode);
    const streetAddress =
      addressLine2 && typeof addressLine2 === 'string' ? `${addressLine1} ${addressLine2}` : `${addressLine1}`;

    return `${streetAddress}, ${city}, ${provinceCode}, <span class='no-wrap'>${postalCode}</span>`;
  }
  return '';
}
export function formatCardString(card) {
  if (typeof card !== 'string') return '';

  return card.match(/.{1,4}/g).join(' ');
}

export function camelCaseToSentence(text) {
  if (text !== null) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return '';
}

export function formatId(text) {
  return text ? text.toLowerCase().replace(' ', '-') : '';
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function generateGCErrorMessage(agilityMessage, limit, period) {
  let retValue = agilityMessage;
  if (!Number.isNaN(limit)) {
    retValue = formatDynamicLabel(retValue, '##LIMIT##', limit);
  }
  if (!Number.isNaN(period)) {
    retValue = formatDynamicLabel(retValue, '##PERIOD##', period);
  }
  if (retValue.indexOf('##') > -1) {
    return '';
  }

  return retValue;
}
