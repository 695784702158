export function findSelectedCard(cardId, cards = []) {
  return cards.find((card) => card.id === cardId);
}

export function generateFilterChipObject(filterDataList, type) {
  return (filter, idx) => (filter ? { type, ...filterDataList[idx], typeIdx: idx } : undefined);
}

export function deriveFilterChipsArrayFrom({ selectedFilters, filterDataList, type }) {
  if (!Array.isArray(selectedFilters) || !Array.isArray(filterDataList)) return [];
  if (!selectedFilters.length && !filterDataList.length) return [];

  // return selectedFilters.map((filter, idx) => (filter ? { type, ...filterDataList[idx], typeIdx: idx } : undefined));
  return selectedFilters.map(generateFilterChipObject(filterDataList, type));
}

export function deriveFilterComparisonArray(arr) {
  if (!Array.isArray(arr) || !arr?.length) return [];

  return arr.map((filter) => (filter ? filter.code : filter));
}

export function updateFilterFormState(filters, code) {
  if (!Array.isArray(filters) && !filters.length) return;

  return filters.map((filter) => {
    // React Hook Form Checkboxes uses undefined for not touched and unchecked, null for touched but unchecked by user
    if (code === filter?.code) {
      return null;
    }

    return filter?.code;
  });
}

export function generateFilterChipArray(chips, formField) {
  return chips?.map((chip) => (formField.includes(chip?.code) > 0 ? chip : undefined));
}
