import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import Intro from '../site-pages/Intro';

const SharedAccordionContentIntroSection = ({ module }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <Intro fields={fields} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedAccordionContentIntroSection.propTypes = { module: PropTypes.object };
export default SharedAccordionContentIntroSection;
