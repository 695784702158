import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';

// Local variables
export const todayDate = moment().format('YYYY-MM-DD');
export const todayDateYearFirst = moment().format('YYYY-MM-DD');
export const currentDate = new Date();
export const currentTime = currentDate.getHours();

export function formatDateDayFirst(date, locale = 'en-ca') {
  moment.locale(locale);
  const dateValue = moment(date && Date.parse(date)).format('ddd, MMM DD, YYYY');

  return dateValue;
}

export function firstDate(date) {
  return moment(date && Date.parse(date.substr(0, date.indexOf('A')))).format('YYYY-MM-DD');
}

export function secondDate(date) {
  return moment(date && Date.parse(date.split('A')[1])).format('YYYY-MM-DD');
}

export function fromDateByNumDay(date, numDay) {
  const fromDate = moment(date && Date.parse(date))
    .subtract(numDay, 'd')
    .format('YYYY-MM-DD');

  return fromDate;
}

export function formatDate(date, format, locale) {
  moment.locale(locale);
  if (format) {
    const formattedDateWithFormat = moment(date && Date.parse(date)).format(format);

    return formattedDateWithFormat;
  }
  const formattedDate = moment(date && Date.parse(date)).format('MMM DD, YYYY');
  return formattedDate;
}

export function formatDatepickerDate(date, format) {
  const formattedDateWithFormat = moment(date).format(format);
  return formattedDateWithFormat;
}

export function isDayDisabled(date, today, selectedFromDate, selectedToDate) {
  const valueDateUTC = new Date(`${date?.year}-${date?.month?.number}-${date?.day}`).toUTCString();
  const todayDateUTC = new Date(`${today?.year}-${today?.month?.number}-${today?.day}`).toUTCString();
  const fromDateUTC = new Date(selectedFromDate).toUTCString();
  const toDateUTC = new Date(selectedToDate).toUTCString();

  const valueDate = new Date(valueDateUTC).getTime();
  const todayDate = new Date(todayDateUTC).getTime();
  const fromDate = new Date(fromDateUTC).getTime();
  const toDate = new Date(toDateUTC).getTime();

  const disabled = valueDate > todayDate || valueDate <= fromDate || valueDate >= toDate;
  return disabled;
}

export function subtractByNumMonthsAgo(date, num, format) {
  return moment(date && Date.parse(date))
    .subtract(num, 'months')
    .format(format);
}

export function getDateElement(date, format) {
  let formattedDate;
  if (moment(date).isValid && format && format !== '') {
    switch (format) {
      case 'DD':
        formattedDate = date.split('-')[2];
        break;
      case 'MM':
        formattedDate = date.split('-')[1];
        break;
      case 'MMMM':
        formattedDate = date.split('-')[1];
        break;
      case 'YYYY':
        formattedDate = date.split('-')[0];
        break;
      default:
        console.error('No format defined, Please select MM, DD or YYYY as format');
    }
  } else {
    console.error('Date should only be in this format:YYYY-MM-DD ');
  }
  return formattedDate;
}

export function timeOfDayGreeting(greetingAfternoonLabel, greetingEveningLabel, greetingMorningLabel, greetingLabel) {
  const morningTime = 6;
  const afternoonTime = 12;
  const eveningTime = 17;

  if (currentTime >= morningTime && currentTime < afternoonTime) {
    // 6am - 11:59am
    return greetingMorningLabel;
  }
  if (currentTime >= afternoonTime && currentTime < eveningTime) {
    // 12:00pm - 16:59pm
    return greetingAfternoonLabel;
  }
  if (currentTime >= eveningTime) {
    // 17:00pm - 5:59am
    return greetingEveningLabel;
  }
  return greetingLabel;
}

export function getTimeZone() {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return `${(offset < 0 ? '+' : '-') + `00${Math.floor(o / 60)}`.slice(-2)}:${`00${o % 60}`.slice(-2)}`;
}

export function getFirstDayOfCurrentYear() {
  return new Date(new Date().getFullYear(), 0, 1);
}

export function convertUnixEpochSecondsToDate(epochTimeInSeconds) {
  const unixEpcohInMiliseconds = epochTimeInSeconds * 1000;
  return new Date(unixEpcohInMiliseconds);
}

export function formatMonth(month) {
  let dobMonth = '';
  if (month.length === 1) {
    dobMonth = `0${month}`;
  } else {
    dobMonth = month;
  }

  return dobMonth;
}

export function formatDay(day) {
  let dobDay = '';
  if (day.length === 1) {
    dobDay = `0${day}`;
  } else {
    dobDay = day;
  }

  return dobDay;
}

export function formatBirthDay(dobYear = '', month = '', day = '') {
  return `${dobYear}-${formatMonth(month)}-${formatDay(day)}`;
}

export function generateMonthOptions(list) {
  return list
    .sort((a, b) => a.code - b.code)
    .map((option) => ({
      value: option.code,
      label: option.name,
    }));
}
