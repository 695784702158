import { useQuery } from 'react-query';
import { getPointHistory } from '../../../../pages/api/services';
import { getTimeZone } from '../../../../helpers/dateHelpers';

export default function usePointHistoryInitialQuery(accessToken, type, category, cardType, fromDate, toDate) {
  const { isLoading, error, data } = useQuery('fetchInitialPointHistory', async () => {
    try {
      if (accessToken) {
        const res = await getPointHistory(
          accessToken,
          type,
          category,
          cardType,
          `${fromDate}T00:00:00${getTimeZone()}`,
          `${toDate}T00:00:00${getTimeZone()}`,
          0
        );

        return res.data.data;
      }
    } catch (error) {
      console.error(error);
    }
  });
  return { isInitialQueryLoading: isLoading, error, initialQueryData: data };
}
