export function sortSpotlightsByPriority(list = []) {
  const sortedList =
    list &&
    list.sort(
      (a, b) =>
        a.displayChannels.find((channel) => channel.priority) -
        b.displayChannels.find((channel) => channel.priority)
    );

  return sortedList;
}

export function checkSpotlightType(list = []) {
  const standaloneSpotlight = list && list.find((item) => item.linkedType === 'Standalone');
  return standaloneSpotlight;
}

export function filterFeaturedSpotlight(list = []) {
  return list && list.filter((spotlight) => spotlight.cmsContent.featured === true);
}

export function findMatchingSpotlightOffer(list = [], linkedId = '') {
  return list && list.find((offer) => offer.linkedCode === linkedId);
}

export function dynamicSpotlightContent(linkedType, content, announcementDetail) {
  if (linkedType === 'Standalone') {
    return content;
  }

  if (linkedType === 'Offer') {
    return 'Offer';
  }

  if (linkedType === 'Announcement') {
    return announcementDetail;
  }

  return content;
}
