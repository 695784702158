import { useStateMachine } from 'little-state-machine';
import { formatPoints } from '../../../helpers/pointsHelpers';

function AddedList({ customData }) {
  const { pts } = customData;
  const {
    state: {
      points: { added },
      language: { locale },
    },
  } = useStateMachine();

  if (added.length > 0) {
    return added.map((item, index) => (
      <div key={index} className="flex justify-between mb-4">
        <p>{item.transactionDescription || item.transactionName}</p>
        <p>{`${formatPoints(item.points, locale)} ${pts || 'pts'}`}</p>
      </div>
    ));
  }

  return null;
}

export default AddedList;
