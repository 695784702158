import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import SendMyCardContainer from '../forms/send-my-card/SendMyCardContainer';
import Sidebar from '../common/Sidebar/Sidebar';
import WrapperLayout from '../layouts/WrapperLayout';

import { SidebarContainer, NavigatedContent } from '../common/Sidebar/SidebarStyles';

const MyCardPage = ({ customData }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" isGray customStyles="topOfPage">
        <SidebarContainer>
          <div className="hidden lg:block">
            <Sidebar sidebarLinks={customData.MyCardForm.profileSidebarLinks} />
          </div>

          <NavigatedContent>
            <SendMyCardContainer customData={customData} />
          </NavigatedContent>
        </SidebarContainer>
      </WrapperLayout>
    );
  }
  return null;
};

MyCardPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'mycardformv2',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['Customer', 'Card', 'General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.profileSidebarLinks) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'profileSidebarLinks',
      });
      contentItem.fields.profileSidebarLinks.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    }
    return {
      MyCardForm: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

MyCardPage.propTypes = { customData: PropTypes.object };

export default MyCardPage;
