/**
 *
 * @param {*} state existing state
 * @param {*object} payload { cards: [...], selectedCard: {...}, balance: <Number>,}
 * @returns
 */
export default function updateTransactionsAction(state, payload) {
  return {
    ...state,
    transactions: {
      ...state.transactions,
      ...payload,
    },
  };
}
