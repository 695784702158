import styled from 'styled-components';

export const Notification = styled.div`
  margin: 1rem auto 0;
  padding: 0.8rem;
  font-size: ${(props) => props.theme.fontLegal};
  font-style: italic;
  background: ${(props) => props.theme.paleGreenColor};
  border-radius: 3px;
  border-left: 5px solid ${(props) => props.theme.leafColor};
`;
export const NotificationBarStyle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1.75rem 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  color: ${(props) => props.theme.brandBlack};

  @media screen and (max-width: 640px) {
    padding: 1rem 0;
  }

  &.error {
    background: ${(props) => props.theme.uiLightPink};

    p:before {
      background-image: url('/assets/error.svg');
    }
  }

  &.success {
    background: ${(props) => props.theme.uiLightGreen};
    p:before {
      background-image: url('/assets/green-check-icon.svg');
    }
  }

  &.info {
    background: ${(props) => props.theme.uiLightBlue};
    p:before {
      background-image: url('/assets/info.svg');
    }
  }

  &.warning {
    background: ${(props) => props.theme.uiLightOrange};
    p:before {
      background-image: url('/assets/warning.svg');
    }
  }

  p {
    margin-bottom: 0;
  }

  button.n-close {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 0;
    margin: 0 0 0 1.25rem;
    text-align: center;
    @media screen and (max-width: 640px) {
      margin: 0 0 0 0.75rem;

      img {
        width: 0.75rem;
        height: 0.75rem;
        min-width: 0.75rem;
        min-height: 0.75rem;
      }
    }
  }
`;
export const NotificationMessage = styled.p`
  position: relative;
  padding-left: 2.75rem;
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight3};
  &:before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/assets/error.svg');
    background-repeat: no-repeat;
    background-size: 1.5rem;
  }

  @media screen and (max-width: 640px) {
    padding-left: 1.875rem;
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
    &:before {
      top: 1px;
      width: 1.125rem;
      height: 1.125rem;
      background-size: 1.125rem;
    }
  }
`;
