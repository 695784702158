import styled, { css } from 'styled-components';

export const CardItem = styled.div`
  flex-direction: column;
  margin-right: 1.875rem;
  @media screen and (max-width: 640px) {
    ${(props) =>
      props.offersNum === 2 &&
      css`
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
  }
`;

export const CardHeader = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 10.625rem;
`;

export const CardImage = styled.div`
  height: 100%;
`;

export const CardBody = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  position: relative;
  flex: 0 0 100%;
  padding: 1rem;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  min-height: 16rem;
`;

export const RewardTitle = styled.h3`
  font-size: ${(props) => props.theme.fontBody1};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 3.5rem;
  line-height: 1.5;

  margin-bottom: 1rem;
  sup {
    font-size: 65% !important;
  }
`;

export const RewardBody = styled.p`
  font-size: ${(props) => props.theme.fontBody2};
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 7.55rem;
`;

export const Brand = styled.p`
  color: ${(props) => props.theme.lightGrayColor4};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontLegal};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
export const CardContents = styled.div`
  width: 17.5rem;
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  overflow: hidden;
  margin: 0 0.25rem;
  ${(props) =>
    props.role === 'link' &&
    css`
      &:hover,
      &:focus {
        outline: none;
        transition: all 0.1s linear;
        box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.15);
      }
    `}
`;
export const CardFooter = styled.div`
  font-weight: bold;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  padding: 0.75rem 0;
  span {
    font-size: ${(props) => props.theme.fontBody2};
    text-decoration: underline;
    line-height: 1.75;
    &:focus,
    &:hover {
      color: #9b43ff;
    }
  }
`;
