import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FocusTrap from 'focus-trap-react';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updateUser } from '../../../../store/actions';

import { updateCustomer } from '../../../../pages/api/services';
import { generateErrorMessage } from '../../../../helpers/errorsHelper';
import { convertToSlug } from '../../../../helpers/utils';

import { Row, Title, FieldContent, SavedValue, DescriptionText } from '../../FormStyles';

// Components
import Loader from '../../../common/Loader/Loader';
import FormSave from '../../FormSave';
import EditButton from '../../EditButton';
import FormSelect from '../../fields/FormSelect';

import { getSavedGender } from '../../../../helpers/gendersHelpers';
import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProfileGender(props) {
  const {
    fieldName,
    message,
    successMsg,
    disableEdit,
    saveLabel,
    cancelLabel,
    saveLabelLoading,
    listOptions,
    errorsList,
    loading,
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const {
    actions,
    state: {
      user: { customer, gender },
      session: { accessToken },
    },
  } = useStateMachine({ updateNotification, updateUser });

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { getValues } = formMethods;
  const selectedGender = getValues('gender') || gender;

  const handleEditClick = () => {
    if (!isEditing) {
      handleGAEvents('Profile - Edit Gender', 'Account', 'Edit Gender Click', 'profile_gender_click');
    }
    setEditing(!isEditing);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    updateCustomer(data, accessToken)
      .then((res) => {
        if (res.data) {
          actions.updateNotification({
            message: successMsg,
            status: 'success',
          });

          actions.updateUser({
            gender: data?.gender,
          });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: generateErrorMessage(
              error?.response?.data?.validationErrors
                ? Object.keys(error.response.data.validationErrors)
                : ['General.BadRequest'],
              errorsList
            ),
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  const genderOptions =
    listOptions &&
    listOptions.map((a) => ({
      value: a.code,
      label: a.name,
    }));

  useEffect(() => {
    actions.updateUser({
      gender: customer.data?.gender,
    });
  }, [customer.data?.gender]);

  return (
    <Row className="mb-0.5">
      <FormProvider {...formMethods}>
        <FocusTrap
          active={isEditing}
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            tabbableOptions: {
              displayCheck: 'none',
            },
          }}
        >
          <form className="p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
            <div className="md:flex justify-between items-baseline content-center relative">
              <div className="w-52 ProfileFieldWrapper">
                <Title className="mb-1 md:mb-0 font-bold mr-4 flex-initial">{fieldName}</Title>
              </div>

              {loading ? (
                <Loader />
              ) : (
                <>
                  <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                    {disableEdit || !isEditing ? (
                      <SavedValue className="md:p-2">{getSavedGender(genderOptions, gender && gender)}</SavedValue>
                    ) : (
                      <FieldContent className="py-2 md:p-2 gender-field  ">
                        {message && <DescriptionText className="block mb-4">{message}</DescriptionText>}
                        <div style={{ maxWidth: '16rem' }}>
                          <FormSelect
                            name="gender"
                            label={fieldName}
                            options={genderOptions}
                            defaultOption={gender || customer.data?.gender}
                          />
                        </div>

                        {!disableEdit && (
                          <FormSave
                            buttonText={saveLabel}
                            submitting={submitting}
                            buttonLoadingText={saveLabelLoading}
                            cancelLabel={cancelLabel}
                            isEditing={isEditing}
                            updateEditClick={handleEditClick}
                            isDisabled={gender === selectedGender}
                          />
                        )}
                      </FieldContent>
                    )}
                  </div>

                  <EditButton
                    {...props}
                    submitting={submitting}
                    handleEditClick={handleEditClick}
                    isEditing={isEditing}
                    ariaControls={`profile-${convertToSlug(fieldName)}`}
                  />
                </>
              )}
            </div>
          </form>
        </FocusTrap>
      </FormProvider>
    </Row>
  );
}
