import styled from 'styled-components';

export const TermsContentStyles = styled.div`
  margin: 0 auto 2rem;
  display: block;

  p {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontLegal};
  }
  h4 {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontBody3};
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .terms-content-inner {
    width: 100%;
    font-size: ${(props) => props.theme.fontLegal};
    border: 2px solid ${(props) => props.theme.uiGrey04};

    .agree-field {
      label {
        position: relative;
        top: -2px;
      }
    }

    div {
      padding: 1rem 1rem 2rem;
      overflow-y: scroll;
      margin: 0 0.5em;
      height: 13.125rem;

      @media screen and (max-width: 767px) {
        height: 9.25rem;
      }

      h3 {
        font-size: ${(props) => props.theme.fontBody3};
        font-weight: bold;
        line-height: ${(props) => props.theme.lineHeight3};
        margin-bottom: 1rem;
        color: ${(props) => props.theme.lightGrayColor4};
      }

      ol,
      ul,
      p {
        font-size: ${(props) => props.theme.fontLegal};
        color: ${(props) => props.theme.lightGrayColor4};
        line-height: 1.8;

        strong {
          display: block;
          color: ${(props) => props.theme.lightGrayColor4};
        }
      }
    }
  }
`;
