// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import AccordionContent from '../common/Accordion/AccordionContent';

const SharedAccordionCookiePrivacySection = ({ module, customData }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <AccordionContent fields={fields} customData={customData} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedAccordionCookiePrivacySection.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'cookiepolicylist',
      languageCode,
    });

    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      accordionList: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default SharedAccordionCookiePrivacySection;
