/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { useStateMachine } from 'little-state-machine';
import { updateOrder } from '../../../store/actions';

import { firstDate, secondDate } from '../../../helpers/dateHelpers';

import Loader from '../../common/Loader/Loader';
import { selectStyles } from '../../forms/fields/selectStyles';

// selectedPeriod
export default function FilterOrdersByDate({ name, label }) {
  const {
    actions,
    state: {
      order: { periods, selectedPeriod },
    },
  } = useStateMachine({ updateOrder });

  const periodDefaultLabel =
    periods && periods.filter((option) => option.name === selectedPeriod.name).map((a) => ({ value: a.code, label: a.name }))[0];

  // the remaining objects in the periods array
  const periodOptions =
    periods && periods.filter((option) => option.name !== selectedPeriod.name).map((a) => ({ value: a.code, label: a.name }));

  const [selectedLabel, setSelectedLabel] = useState(periodDefaultLabel);

  useEffect(() => {
    setSelectedLabel({
      value: selectedPeriod.code,
      label: selectedPeriod.name,
    });
  }, [selectedPeriod]);

  const handleChange = ({ value, label }) => {
    if (value !== selectedLabel.value) {
      setSelectedLabel({
        value,
        label,
      });

      if (selectedPeriod) {
        actions.updateOrder({
          selectedPeriod: {
            value,
            label,
            firstDate: firstDate(value),
            secondDate: secondDate(value),
          },
        });
      }
    }
  };

  return (
    <div>
      {Boolean(label) && (
        <label id={`filtering-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      {periods ? (
        <Select
          name={name}
          aria-labelledby={`filtering-${name}`}
          classNamePrefix="orders-filter-select"
          isSearchable={false}
          options={periodOptions}
          value={selectedPeriod}
          onChange={handleChange}
          styles={selectStyles}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}
FilterOrdersByDate.propTypes = { name: PropTypes.string, label: PropTypes.string };
