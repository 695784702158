import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { useStateMachine } from 'little-state-machine';
import { updateOffersFilter } from '../../../store/actions';

// Components
import Checkboxes from '../../forms/fields/Checkboxes';
import FilterChips from '../../forms/filters/FilterChips';

// Styles
import { FilterListHeading, FilterListSection } from '../../common/Filters/FilterListStyles';

// Helpers

import { arrayEquals, removeEmptyValues } from '../../../helpers/utils';
import { deriveFilterChipsArrayFrom, deriveFilterComparisonArray } from '../../../helpers/filterHelpers';

function OffersFilterBody({ customData, updateFormFields, filteredCategories, filteredBrands }) {
  const { state, actions } = useStateMachine({ updateOffersFilter });

  const formMethods = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { control, watch } = formMethods;

  // Categories
  const categoryOptions = filteredCategories.map((cat) => cat?.code);
  const categoryLabels = filteredCategories.map((cat) => cat?.name);

  // Brands
  const brandOptions = filteredBrands.map((brand) => brand?.code);
  const brandLabels = filteredBrands.map((brand) => brand?.name);

  // Labels
  const filterCategoriesLabel = customData?.pageContent?.filterCategoriesLabel;
  const filterBrandsLabel = customData?.pageContent?.filterBrandsLabel;

  // Chips
  const chips =
    state?.offers?.filter?.chips?.category && state?.offers?.filter?.chips?.brands
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...state?.offers?.filter?.chips?.category, ...state?.offers?.filter?.chips?.brands]
      : [];

  // Removes filter chips and updates form state
  const onFilterChipClick = (e) => {
    e.preventDefault();

    const {
      id,
      dataset: { type },
    } = e.currentTarget;

    updateFormFields(id, type);
  };

  const watchAllFields = watch(['category', 'brands']);

  // TODO: consider moving this into a custom hook ?
  useEffect(() => {
    const formTouched = removeEmptyValues(watchAllFields).length;

    if (formTouched) {
      const [categoryFiled, brandsField] = watchAllFields;

      // Filter Chip Arrays
      const category = categoryFiled
        ? deriveFilterChipsArrayFrom({
            selectedFilters: Array.from(categoryFiled),
            filterDataList: filteredCategories,
            type: 'category',
          })
        : [];

      const brands = brandsField
        ? deriveFilterChipsArrayFrom({
            selectedFilters: Array.from(brandsField),
            filterDataList: filteredBrands,
            type: 'brands',
          })
        : [];

      // Check for changes on user choice vs state
      const categorySameAsState = arrayEquals(
        deriveFilterComparisonArray(state.offers.filter?.chips?.category),
        deriveFilterComparisonArray(category)
      );

      const brandsSameAsState = arrayEquals(
        deriveFilterComparisonArray(state.offers.filter?.chips?.brands),
        deriveFilterComparisonArray(brands)
      );
      if (!categorySameAsState || !brandsSameAsState) {
        if (!categorySameAsState) {
          // Requirement: When category changes, we need to remove any brands that do not apply

          actions.updateOffersFilter({
            chips: {
              category,
              brands: [],
            },
          });

          formMethods.setValue('brands', []);

          return;
        }

        actions.updateOffersFilter({
          chips: {
            category,
            brands,
          },
        });
      }
    }
  }, [watchAllFields, state?.offers?.filter?.chips, filteredCategories]);

  if (!customData) return null;

  return (
    <>
      <FilterListSection>
        <FilterChips chips={chips} handleFilterChipClick={onFilterChipClick} />
      </FilterListSection>

      {categoryOptions?.length ? (
        <FilterListSection>
          <FilterListHeading>{filterCategoriesLabel}</FilterListHeading>
          <Checkboxes options={categoryOptions} control={control} name="category" labels={categoryLabels} skipValidation />
        </FilterListSection>
      ) : (
        ''
      )}

      {brandOptions?.length ? (
        <FilterListSection>
          <FilterListHeading>{filterBrandsLabel}</FilterListHeading>
          <Checkboxes options={brandOptions} control={control} name="brands" labels={brandLabels} skipValidation />
        </FilterListSection>
      ) : (
        ''
      )}
    </>
  );
}

OffersFilterBody.propTypes = {
  customData: PropTypes.object,
  updateFormFields: PropTypes.func,
  filteredCategories: PropTypes.array,
  filteredBrands: PropTypes.array,
};

export default OffersFilterBody;
