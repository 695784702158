/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontBody2};
  margin: 0;
  line-height: ${(props) => props.theme.lineHeight3};
  justify-content: space-between;

  a {
    text-decoration: none;
  }
  @media screen and (max-width: 767px) {
    width: 100vw;
    left: calc(-50vw + 50%);
    position: relative;
  }
`;
export const Row = styled.div`
  display: box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &.row-list {
    width: 100%;
    margin: 2px 0;
    background-color: ${(props) => props.theme.whiteColor};
    margin-bottom: ${(props) => (props.error ? css`` : `2px`)};
    transition: color 0.2s ease-in;
    ${(props) =>
      props.error
        ? css`
            color: ${props.theme.uiErrorRed} !important;

            label {
              color: ${props.theme.uiErrorRed} !important;
            }
          `
        : `color: inherit`};

    ${(props) =>
      props.warning
        ? css`
            color: ${props.theme.uiWarningOrange} !important;

            label {
              color: ${props.theme.uiWarningOrange} !important;
            }
          `
        : `color: inherit`};

    @media screen and (max-width: 767px) {
      .pfc-transac-name {
        color: ${(props) => (props.error ? `${props.theme.uiErrorRed} !important` : 'inherit')};
      }
    }
  }

  .points-checkbox:checked {
    border-color: ${(props) => (props.error ? props.theme.uiErrorRed : props.theme.uiCharcoal)};
    background-color: ${(props) => (props.error ? `${props.theme.uiErrorRed} !important` : props.theme.uiCharcoal)};
  }

  .points-checkbox:disabled {
    cursor: inherit;
    border: none;
    background-color: ${(props) => props.theme.uiGrey02};
  }

  &.points-error-row {
    background-color: ${(props) => props.theme.whiteColor};
    width: 100%;
    justify-content: flex-start;

    .points-error-cell,
    .points-warning-cell {
      max-width: 100%;
    }
  }
`;

export const TableHead = styled.div`
  color: ${(props) => props.theme.whiteColor};
  font-weight: bold;
  background-color: ${(props) => props.theme.uiCharcoal};
  margin-bottom: 4px;
`;

export const TableRowItem = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 1rem;
  word-break: break-word;
  font-size: ${(props) => props.theme.fontBody3};
  align-items: start;
  line-height: 1;
  ${(props) => (props.center ? 'justify-content: center' : '')};
  ${(props) =>
    props.checkboxName &&
    css`
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    `};

  @media screen and (min-width: 768px) {
    align-items: center;
  }

  &.table-checkbox {
    justify-content: flex-start !important;
  }

  &:first-of-type {
    max-width: 27%;
  }
  &:nth-of-type(2) {
    ${(props) => (props.flexGrow ? 'flex-grow: 1' : '')};
    max-width: ${(props) => (props.columns === 3 ? '100%' : props.width45 || props.columns === 4 ? '45%' : '15%')};
  }
  &:nth-of-type(3) {
    ${(props) =>
      props.columns === 3
        ? 'display: flex; justify-content: flex-end;max-width: 35%'
        : props.columns === 4 || props.width20
        ? 'justify-content: center;max-width: 20%'
        : 'max-width: 55%'}
  }
  &:nth-of-type(4) {
    max-width: 15%;
    justify-content: center;
  }
  &:nth-of-type(5) {
    max-width: 15%;
    justify-content: center;
  }

  p {
    margin: 0;
  }

  label {
    ${(props) =>
      props.disable &&
      css`
        pointer-events: none !important;
        cursor: default !important;
      `}
  }
`;
export const TableRowPointsItem = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 1rem;
  word-break: break-word;
  font-size: ${(props) => props.theme.fontBody3};
  align-items: start;
  line-height: 1;
  justify-content: flex-end;
`;
export const CellInline = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 1rem;
    text-align: left;
    flex-grow: 0;
  }
`;
