import styled from 'styled-components';

export const DatePickerInputField = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledDatePickerInput = styled.input`
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.uiBlack};
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight2};
  background-color: transparent;
  z-index: 1;
  border: 1px solid ${(props) => props.theme.uiGrey04};
  padding: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  outline: none;

  &:hover {
    border: 1px solid ${(props) => props.theme.uiPurple};
  }

  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
  }

  @media screen and (min-width: 520px) {
    font-size: ${(props) => props.theme.fontBody3} !important;
  }

  ::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
`;

export const DatePickerIcon = styled.div`
  position: relative;
  left: -35px;
`;
