import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import TabbedComponent from '../common/Tabs/TabbedComponent';

const SharedTabbedContent = ({ module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    const tabsList = [];
    if (fields.termsLabel && fields.termsMessage) {
      tabsList.push({
        tabTitle: fields.termsLabel,
        tabContent: fields.termsMessage,
      });
    }
    if (fields.privacyLabel && fields.privacyMessage) {
      tabsList.push({
        tabTitle: fields.privacyLabel,
        tabContent: fields.privacyMessage,
      });
    }

    if (tabsList.length) {
      return (
        <WrapperLayout className="global-content-wrapper pb-8" isGray>
          <TabbedComponent tabs={tabsList} anchorId={fields.anchorID || 'terms'} isLarge={fields.isLargeFont === 'true'} />
        </WrapperLayout>
      );
    }
  }

  return null;
};

SharedTabbedContent.propTypes = {
  module: PropTypes.object.isRequired,
};
export default SharedTabbedContent;
