import usePoints from '../hooks/points/usePoints';

const GAModule = ({ module }) => {
  // get module fields
  const { fields } = module;
  usePoints(fields.refreshInterval);
  return null;
};

export default GAModule;
