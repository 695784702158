import styled, { css } from 'styled-components';

export const TooltipStyles = styled.div`
  .input-header {
    align-items: center;
  }

  .tooltip-icon {
    background-color: ${(props) => props.theme.whiteColor};
    box-shadow: 0px 2px 10px rgba(0, 34, 91, 0.11);
    opacity: 1 !important;
    cursor: pointer;
    padding: 1rem 2.625rem 1rem 1rem;
  }
  .tooltip-message {
    max-width: 18rem;
  }
  .tooltip-message,
  .tooltip-message p {
    font-weight: normal;
    font-size: 0.875rem;
    color: ${(props) => props.theme.uiCharcoal};
    line-height: ${(props) => props.theme.lineHeight3};
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .close-tooltip {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1.125rem;
    width: 1.125rem;
    max-height: 1.125rem;
    max-width: 1.125rem;
    padding: 0;
    background-color: ${(props) => props.theme.whiteColor};
  }

  .tip-icon-info-black {
    font-weight: normal;
    padding-left: 2rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      width: 1.25rem;
      background: url(/assets/icon-i-black.svg) no-repeat center;
      background-size: 100%;
    }
  }
`;
export const TooltipIconContainer = styled.button`
  padding: 0;
  background-image: url(/assets/info-icon.svg);
  background-color: ${(props) => props.theme.whiteColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:focus {
    background-image: url(/assets/info-icon-focus.svg);
    border: 0;
    outline: 0;
  }
  &:hover {
    background-image: url(/assets/info-icon-hover.svg);
  }

  display: inline-flex;
  align-content: center;
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  // GK register tooltip
  ${(props) =>
    props.iconSmall &&
    css`
      height: 0.875rem;
      width: 0.875rem;

      background-position: bottom;
      background-size: 0.75rem 0.75rem;
    `}
`;
