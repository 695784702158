/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification } from '../../../store/actions';

import ButtonIcon from '../Button/ButtonIcon';

import { NotificationBarStyle, NotificationMessage } from './NotificationBarStyles';

export default function NotificationBar() {
  const [isOpen, setIsOpen] = useState(true);

  const {
    actions,
    state: {
      notification: { message, status, duration },
      labels: { closeLabel },
    },
  } = useStateMachine({ updateNotification });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
      actions.updateNotification({ message: '', status: '' });
    }, duration || 3500);
    return () => clearTimeout(timer);
  }, []);

  const handleCloseClick = () => {
    setIsOpen(!isOpen);
    actions.updateNotification({ message: '', status: '' });
  };

  if (message) {
    return (
      <NotificationBarStyle role="alert" status={status} aria-atomic="true" className={status}>
        <div className="global-content-wrapper">
          <div className="flex justify-between items-start">
            <NotificationMessage dangerouslySetInnerHTML={renderHTML(message)} />

            <ButtonIcon
              className="cursor-pointer n-close"
              onClick={() => handleCloseClick()}
              style={{ border: 0 }}
              ariaLabel={closeLabel}
              role="button"
            >
              <Image src="/assets/close-x.svg" height={20} width={20} title={closeLabel} alt={closeLabel} />
            </ButtonIcon>
          </div>
        </div>
      </NotificationBarStyle>
    );
  }
  return null;
}
