import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Accordion } from 'react-accessible-accordion';
import { useWindowWidth } from '@react-hook/window-size';
import { AccordionStyle } from './AccordionStyles';
import AccordionList from './AccordionList';

import { scrollToElement } from '../../../helpers/behavourHelper';

export default function Content({ accordionList, firstItemExpanded = [], page = '', languageCode }) {
  const router = useRouter();
  const viewPortWidth = useWindowWidth();
  let element;
  const expandArr = [];
  const hashUri = window.location.hash || (router.asPath && router.asPath.includes('#'));

  if (hashUri && hashUri.includes('#cid=')) {
    const selectedSection = parseInt(hashUri.substring(5));
    expandArr.push(selectedSection);
    element = document.querySelector(`section[data-id='${selectedSection}']`);
  }

  useEffect(() => {
    if (element && viewPortWidth) {
      scrollToElement(element, viewPortWidth);
    }
  }, [element]);

  return (
    <AccordionStyle>
      <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={expandArr.length ? expandArr : firstItemExpanded}>
        <AccordionList accordionList={accordionList} page={page} languageCode={languageCode} />
      </Accordion>
    </AccordionStyle>
  );
}

Content.propTypes = {
  accordionList: PropTypes.array,
  firstItemExpanded: PropTypes.array,
  page: PropTypes.string,
  languageCode: PropTypes.string,
};
