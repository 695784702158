import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import Loader from '../Loader/Loader';
import OffersList from '../../Offers/OffersList';

// Hooks
import useOffers from '../../hooks/offers/useOffers';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';

const OffersListWrapper = ({ title, customData, slides, languageCode, tag, sort = '', listName }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
    actions,
  } = useStateMachine({ updateOffers });

  const reqBody = {
    category: ['ALL'],
    tag: (tag && tag.split(',')) || [],
    sort,
    page: 1,
    limit: slides || 8,
  };

  const { offers: offersData, isLoading, error } = useOffers(languageCode, reqBody);
  useEffect(() => {
    if (!isLoading) {
      actions.updateOffers({ isLoading: false });
    }
  }, [isLoading]);
  if (isLoggedIn) {
    if (isLoading) {
      return (
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          <Loader />
        </WrapperLayout>
      );
    }

    if (checkArrayForItems(offersData)) {
      return (
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          <OffersList customData={customData.pageContent} filteredList={offersData} title={title} isSlider listName={listName} />
        </WrapperLayout>
      );
    }

    return null;
  }
  return null;
};

export default OffersListWrapper;

OffersListWrapper.propTypes = {
  title: PropTypes.string,
  customData: PropTypes.object,
  slides: PropTypes.number,
  languageCode: PropTypes.string,
  tag: PropTypes.string,
  listName: PropTypes.string,
};
