import { useState, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';

export default function useInlineMessage() {
  const [extraPadding, setExtraPadding] = useState(false);
  const {
    state: { layout, inlineMessage },
  } = useStateMachine();

  useEffect(() => {
    if (inlineMessage !== undefined && inlineMessage?.isActive) {
      if (!extraPadding) {
        setExtraPadding(true);
      }
    } else if (extraPadding) {
      setExtraPadding(false);
    }
  }, [inlineMessage, layout]);

  return extraPadding;
}
