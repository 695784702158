import PropTypes from 'prop-types';

// Components
import BrandLogo from '../Icons/BrandLogo';
// Styles
import { TileContainer } from '../Sections/EarnRedeemStyles';

function LogoList({ logoList }) {
  if (logoList && logoList.length) {
    return (
      <>
        {logoList.map((logo, index) => (
          <figure key={index}>
            {logo?.fields?.logoTransparentBG?.url && logo?.fields?.title && (
              <TileContainer>
                <BrandLogo url={logo?.fields?.logoSolidBG?.url} title={logo?.fields?.title} />
              </TileContainer>
            )}
          </figure>
        ))}
      </>
    );
  }

  return null;
}

LogoList.propTypes = { logoList: PropTypes.array };

export default LogoList;
