import {
  order,
  points,
  user,
  transactions,
  giftCards,
  layout,
  navigation,
  notification,
  offers,
  announcement,
  faq,
  session,
  inlineMessage,
} from '../states';

/**
 * We use this action to reset the application state back to its initial setting
 * over time we can also add conditionals inside the payload to reset specific
 * piece of state in each store object.
 *
 * over time we can control how and what store state we will clear out through a
 * switch that takes into consideration payload ex: payload.all --> clears app while payload.order --> only clears app order state
 *
 * @arg {string} payload.storeName the keyname of a particular state obj inside lsm see import for ex
 */

export default function clearAction(state, payload) {
  if (!payload) {
    return {
      order,
      points,
      user,
      transactions,
      giftCards,
      layout,
      navigation,
      notification,
      offers,
      announcement,
      faq,
      session,
      inlineMessage,
    };
  }

  return {
    ...state,
    [payload.storeName]: {
      ...state[payload.storeName],
      ...payload,
    },
  };
}
