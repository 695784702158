/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

// Global State
import updatePoints from '../../../store/actions/updatePointsAction';

// Hooks
import usePointHistory from '../../hooks/points/history/usePointHistory';
import usePointHistoryInitialQuery from '../../hooks/points/history/usePointHistoryInitialQuery';

// Utils
import { formatDate, todayDateYearFirst } from '../../../helpers/dateHelpers';
import { handleGAEvents } from '../../../helpers/handleGoogleAnalyticsHelper';

// Components
import EmptyPointsList from './EmptyPointsList';
import Loader from '../../common/Loader/Loader';
import MissingPoints from './MissingPoints/MissingPoints';
import RecentTransactionsHeader from './RecentTransactionsHeader';

// Styles
import { PointsHistoryListWrapper, RecentTransactionsStyle, PointHistoryGroup, PointsHistoryGrid } from './PointsHistoryStyles';
import PointsHistoryRow from './PointsHistoryRow';
import LoadMoreTransactions from './LoadMoreTransactions';

export default function PointsHistoryList({ customData, transactionIconTypes, accountSegments, awardsTypes, languageCode }) {
  // Global State
  const {
    state: {
      session: { accessToken },
      points: { type, category, cardType, fromDate, toDate },
    },
    actions,
  } = useStateMachine({ updatePoints });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = usePointHistory(
    accessToken,
    type || ['ALL'],
    category || ['ALL'],
    cardType || ['ALL'],
    fromDate || formatDate('1900-01-02', `YYYY-MM-DD`),
    toDate || todayDateYearFirst
  );

  const { initialQueryData, isInitialQueryLoading } = usePointHistoryInitialQuery(
    accessToken,
    ['ALL'],
    ['ALL'],
    ['ALL'],
    formatDate('1900-01-02', `YYYY-MM-DD`),
    toDate || todayDateYearFirst
  );

  useEffect(() => {
    // queries the initial data for listing and filter functionality
    if (data && fromDate && toDate) {
      actions.updatePoints({
        history: {
          transactions: data,
          isEmpty: !(initialQueryData?.totalItemCount > 0),
        },
      });
    }

    // queries the initial data for filter button disable functionality
    if (!isInitialQueryLoading && initialQueryData) {
      actions.updatePoints({
        history: {
          isEmpty: Boolean(!(initialQueryData?.totalItemCount > 0)),
        },
      });
    }
  }, [initialQueryData]);

  useEffect(() => {
    actions.updatePoints({
      type: ['ALL'],
      category: ['ALL'],
      cardType: ['ALL'],
      history: {
        transactions: data,
      },
      selectedFromDate: '',
      selectedToDate: '',
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      handleGAEvents({ type, category, cardType }, 'Points Transactions', 'Points Filter Click', 'points_filter_click');
    }
  }, [data]);

  return (
    <>
      <RecentTransactionsHeader customData={customData} languageCode={languageCode} />

      <PointsHistoryGrid>
        <PointsHistoryListWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <RecentTransactionsStyle>
              {data && data?.pages && data?.pages[0]?.pointsHistory?.length && data?.pages?.length ? (
                <PointHistoryGroup>
                  <PointsHistoryRow
                    customData={customData}
                    transactionIconTypes={transactionIconTypes}
                    accountSegments={accountSegments}
                    awardsTypes={awardsTypes}
                    pages={data?.pages}
                    languageCode={languageCode}
                  />
                </PointHistoryGroup>
              ) : (
                <EmptyPointsList customData={customData} />
              )}

              <LoadMoreTransactions
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                pages={data && data?.pages}
                customData={customData}
              />
            </RecentTransactionsStyle>
          )}
        </PointsHistoryListWrapper>

        <div className="hidden lg:block">
          <MissingPoints customData={customData} />
        </div>
      </PointsHistoryGrid>
    </>
  );
}
PointsHistoryList.propTypes = {
  customData: PropTypes.object,
  transactionIconTypes: PropTypes.array,
  accountSegments: PropTypes.array,
  awardsTypes: PropTypes.array,
  languageCode: PropTypes.string,
};
