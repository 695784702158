import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

// Components
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

// Styles
import { selectStyles, customErrorStyles } from './selectStyles';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { mapSelectValuesToObj } from '../../../helpers/formHelpers';

function FormSelect({ name, label, defaultOption, options, rules, placeholder, hideError, gaFlow = '' }) {
  const [inputVal, setInputVal] = useState('');
  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { control, setValue, formState } = formContext;
  const error = formState?.errors[name];

  useEffect(() => {
    if (inputVal || defaultOption) {
      setValue(name, inputVal || defaultOption?.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(name, null);
    }
  }, [inputVal]);

  const generateOptions = (list) => {
    if (!list) return;

    return list.map((a) => ({
      value: a.value,
      label: <div className="select-options">{a.label}</div>,
    }));
  };

  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);
  const selectOptions = useMemo(() => generateOptions(options), [options]);
  const selectValuesMap = useMemo(() => mapSelectValuesToObj(selectOptions), [selectOptions]);

  const handleChange = (value) => {
    if (value) {
      setInputVal(value);
    }
  };

  return (
    <FieldLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <div className="select-wrapper">
        <Controller
          control={control}
          name={name}
          defaultValue={defaultOption || null}
          rules={{
            ...rules,
          }}
          render={({ field: { ref, value }, fieldState: { error } }) => (
            <Select
              inputRef={ref}
              isSearchable={false}
              defaultValue={defaultOption || null}
              aria-invalid={!!error}
              aria-labelledby={`form-${name}`}
              placeholder={placeholder}
              className="form-select-container"
              classNamePrefix={`${
                (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''
              }`}
              options={selectOptions}
              value={selectOptions ? selectOptions.find((c) => c.value === value) : null}
              onChange={(val) => handleChange(val.value)}
              styles={isError ? { ...selectStyles, ...customErrorStyles } : selectStyles}
              onFocus={() => {
                if (gaFlow === 'register' || gaFlow === 'registerGK') {
                  const dataLayer = {
                    event: 'sign_up_form_field',
                    category: 'Registration Events',
                    action: 'Form Field Click',
                    label: name,
                    sign_up_step: `Step 2${gaFlow === 'registerGK' ? ' GK' : ''}`,
                    sign_up_form_field: name,
                  };
                  handleGAEventsBase(dataLayer);
                }
              }}
              onKeyDown={(e) => {
                const pressedKey = e.keyCode || e.which;
                const stringKey = String.fromCharCode(pressedKey);

                if (selectValuesMap[stringKey]) {
                  handleChange(selectValuesMap[stringKey]);
                }
              }}
            />
          )}
        />
      </div>
      {isError && !hideError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultOption: PropTypes.string,
  options: PropTypes.array,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  gaFlow: PropTypes.string,
  hideError: PropTypes.bool,
};

export default FormSelect;
