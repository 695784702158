import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

// Components
import { Section, SectionTitle, GridWrapper, CardCarouselWrapper } from './BannerStyles';

import RewardCard from '../Cards/RewardCard';
import CardModal from '../Modals/CardModal';
import Loader from '../Loader/Loader';

// Hooks
import useModal from '../../hooks/utils/useModal';
import useRewards from '../../hooks/rewards/useRewards';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';
import { handleGaEcommTrandingSliderList } from '../../../helpers/handleGoogleAnalyticsHelper';
import { cardSliderSettings } from '../../../helpers/bannersHelper';

const RewardsSlider = ({ category, brand, languageCode, isSlider, slides, sectionTitle, openDetailsModalLabel, isGrid }) => {
  const [getRewardsList, setRewardsList] = useState([]);
  const offersNum = getRewardsList.length;

  useEffect(() => {
    if (getRewardsList && getRewardsList.length) {
      handleGaEcommTrandingSliderList('rewards_view_item_list', getRewardsList, sectionTitle);
    }
  }, [getRewardsList]);

  // REWARDS
  // TODO: setup custom hook
  const reqBody = {
    filters: {
      category: [category],
      brand,
    },

    pagination: {
      page: 1,
      limit: slides || 50,
    },
  };

  const { rewardsData, isLoading, error } = useRewards(reqBody, languageCode);

  useEffect(() => {
    if (rewardsData) {
      setRewardsList(rewardsData);
    }
  }, [rewardsData]);

  const settings = cardSliderSettings(offersNum);
  const { isShowing, toggle, currentItemId } = useModal();

  if (isLoading) {
    return (
      <Section>
        <div>
          <Loader />
        </div>
      </Section>
    );
  }

  if (offersNum > 0) {
    return (
      <>
        <Section>
          {sectionTitle && (
            <SectionTitle aria-label={`${offersNum} - ${sectionTitle}`} isSlider={isSlider} isGrid={isGrid}>
              {sectionTitle} {isSlider && offersNum > 2 && <span>({offersNum})</span>}
            </SectionTitle>
          )}
          {isSlider && offersNum > 2 ? (
            <CardCarouselWrapper offersNum={offersNum}>
              <Slider {...settings}>
                {offersNum &&
                  checkArrayForItems(getRewardsList) &&
                  getRewardsList.map((item, index) => (
                    <RewardCard
                      id={`card-${index}`}
                      code={item.code}
                      key={index}
                      content={item}
                      offersNum={offersNum}
                      isShowing={isShowing}
                      sectionTitle={sectionTitle}
                      toggle={toggle}
                      openDetailsModalLabel={openDetailsModalLabel}
                    />
                  ))}
              </Slider>
            </CardCarouselWrapper>
          ) : (
            <GridWrapper>
              <div className="grid-row">
                {checkArrayForItems(getRewardsList) &&
                  getRewardsList.map((item, index) => (
                    <div className="grid-item" key={index}>
                      <RewardCard
                        id={`cardgrid-${index}`}
                        code={item.code}
                        content={item}
                        offersNum={offersNum}
                        isShowing={isShowing}
                        toggle={toggle}
                        sectionTitle={sectionTitle}
                        openDetailsModalLabel={openDetailsModalLabel}
                        isGrid
                      />
                    </div>
                  ))}
              </div>
            </GridWrapper>
          )}
        </Section>

        <CardModal
          isShowing={isShowing}
          toggle={toggle}
          currentItemId={currentItemId}
          list={getRewardsList}
          page="rewardsPage"
          card="reward"
          sectionTitle={sectionTitle}
          languageCode={languageCode}
        />
      </>
    );
  }
  return null;
};

export default RewardsSlider;

RewardsSlider.propTypes = {
  category: PropTypes.string,
  brand: PropTypes.string,
  languageCode: PropTypes.string,
  isSlider: PropTypes.bool,
  slides: PropTypes.string,
  sectionTitle: PropTypes.string,
  openDetailsModalLabel: PropTypes.string,
  isGrid: PropTypes.bool,
};
