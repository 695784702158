import Image from 'next/image';
import PropTypes from 'prop-types';

import Link from 'next/link';
import { CardFooter, LoadOfferIcon } from './OfferCardStyles';
import { handleKeyDown } from '../../../../helpers/utils';

export default function LoadOffer({
  status,
  isLoadable,
  isEvergreen,
  offerLoadedLabel,
  loadOfferLabel,
  offerPreloadedLabel,
  handleOfferLoadUnload,
  handleCouponBasedOfferClick,
  unloadingLabel,
  loadingLabel,
  loading,
  handlePreloadedClick,
  isCouponBased,
  getCouponLink,
  getCouponLabel,
}) {
  function setLoadMessage() {
    if (isEvergreen) return offerPreloadedLabel;
    // if not loadable, we can just show a standard label
    if (!isLoadable) return offerLoadedLabel;

    // Manage the labels based on the button state
    if (loading) {
      if (status) {
        return unloadingLabel;
      }
      return loadingLabel;
    }

    if (status) {
      return offerLoadedLabel;
    }

    return loadOfferLabel;
  }

  if (isCouponBased) {
    return (
      <CardFooter>
        <Link
          href={getCouponLink?.href}
          role="button"
          tabIndex="0"
          target={getCouponLink?.target}
          onClick={handleCouponBasedOfferClick}
          onKeyDown={(e) => handleKeyDown(e, handleCouponBasedOfferClick)}
        >
          <span className="load-text" style={{ height: '100%' }}>
            {getCouponLink?.text || getCouponLabel}

            <LoadOfferIcon className="offer-load-icon loadable-offer">
              <Image src="/assets/arrow-right-white-2.svg" alt="" role="presentation" width={14} height={14} />
            </LoadOfferIcon>
          </span>
        </Link>
      </CardFooter>
    );
  }

  if (isLoadable && !isEvergreen) {
    return (
      <CardFooter>
        <span
          className="load-text"
          aria-label={status ? offerLoadedLabel : loadOfferLabel}
          role="button"
          tabIndex="0"
          onClick={handleOfferLoadUnload}
          onKeyDown={(e) => handleKeyDown(e, handleOfferLoadUnload)}
        >
          {setLoadMessage()}

          <LoadOfferIcon className="offer-load-icon loadable-offer" loaded={status}>
            <Image
              src={status ? `/assets/checkmark-sharp.svg` : `/assets/cross-white-icon.svg`}
              alt=""
              role="presentation"
              width={14}
              height={14}
            />
          </LoadOfferIcon>
        </span>
      </CardFooter>
    );
  }

  return (
    <CardFooter>
      <span
        className="load-text"
        aria-label={offerPreloadedLabel}
        role="button"
        tabIndex="0"
        onClick={handlePreloadedClick}
        onKeyDown={(e) => handleKeyDown(e, handlePreloadedClick)}
      >
        {setLoadMessage()}

        <LoadOfferIcon className="offer-load-icon loaded-offer" loaded>
          <Image
            src={status ? `/assets/cross-white-icon.svg` : `/assets/checkmark-sharp.svg`}
            alt=""
            role="presentation"
            width={14}
            height={14}
          />
        </LoadOfferIcon>
      </span>
    </CardFooter>
  );
}

LoadOffer.propTypes = {
  status: PropTypes.bool,
  isLoadable: PropTypes.bool,
  isEvergreen: PropTypes.bool,
  offerLoadedLabel: PropTypes.string,
  loadOfferLabel: PropTypes.string,
  offerPreloadedLabel: PropTypes.string,
  handleOfferLoadUnload: PropTypes.func,
  unloadingLabel: PropTypes.string,
  loadingLabel: PropTypes.string,
  getCouponLabel: PropTypes.string,
  loading: PropTypes.bool,
  handlePreloadedClick: PropTypes.func,
  isCouponBased: PropTypes.bool,
  getCouponLink: PropTypes.object,
};
