export function calcPointsTotal(pointsList = []) {
  return pointsList.length > 0 ? pointsList.map((item) => item.points).reduce((a, b) => a + b) : 0;
}

export function calcTotalDollar(addedList = []) {
  if (addedList.length && (addedList[0]?.transactionAmount || addedList[0]?.dollarValue)) {
    let total = 0;

    for (let i = 0; i < addedList.length; i++) {
      total += parseFloat(addedList[i].transactionAmount || addedList[i].dollarValue);
    }
    return total;
  }
  return 0;
}

export function calcOrderPointsTotal(pointsList = []) {
  return pointsList.length > 0 ? pointsList.map((item) => item.redemptionValue).reduce((a, b) => a + b) : 0;
}

export function calcPointsNeeded(added, balance, quantity = null) {
  if (quantity) {
    return calcPointsTotal(added) * quantity - balance;
  }

  return calcPointsTotal(added) - balance;
}

export const formatNumber = (inputNumber) => {
  let formattedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const splitArray = formattedNumber.split('.');
  if (splitArray.length > 1) {
    formattedNumber = splitArray[0];
  }
  return formattedNumber;
};

export function generateTransactionCards(cards) {
  return cards.map((card) => ({
    id: card.uniqueId,
    cardImage: card.accountSegment.imageUrl,
    cardTitle: `${card.accountSegment.name} - ${card.bns.maskedCardNumber.slice(-4)}`,
    accountSegmentCode: card.accountSegment.code,
    balance: card.pointsBalance,
  }));
}

export function hasAvailableAwards(awards = []) {
  return Boolean(awards.length);
}

export function totalPoints(cards = []) {
  return cards.reduce((sum, card) => sum + card.pointsBalance, 0);
}

export function totalPointsToLocalString(pointsTotal, languageCode) {
  return parseInt(pointsTotal)?.toLocaleString(languageCode);
}

export function formatPoints(points, languageCode) {
  return parseInt(points)?.toLocaleString(languageCode);
}

export function amountSelected(pointsAdded = []) {
  if (pointsAdded.length && pointsAdded[0].dollarValue) {
    return `$${parseInt(pointsAdded[0].dollarValue)}`;
  }
  return '$0';
}

export function byotAmountSelected(pointsAdded = []) {
  if (pointsAdded.length && pointsAdded[0].transactionAmount) {
    let total = 0;
    for (let i = 0; i < pointsAdded.length; i++) {
      total += parseInt(pointsAdded[i].transactionAmount);
    }
    return `$${total}`;
  }
  return '$0';
}

/**
 * POINTS FORM HELPERS
 */

export function validateHasPointsForRewards(added, balance, message) {
  const pointsNeeded = calcPointsNeeded(added, balance);

  if (pointsNeeded > 0) {
    return message;
  }
}
