import styled, { css } from 'styled-components';

export const CardItem = styled.div`
  display: inline-flex;

  width: 100%;
  ${(props) =>
    !props.isGrid &&
    css`
      width: 10.75rem;
      margin-right: 2.25rem;
      @media screen and (max-width: 640px) {
        width: 8.875rem;
        margin-right: 1rem;
      }
      @media screen and (min-width: 641px) and (max-width: 990px) {
        margin-right: 1.875rem;
      }
    `}
  flex-direction: column;

  .card-content-wrapper {
    a {
      text-decoration: none;
      cursor: pointer;
      display: block;
    }
    a:hover {
      text-decoration: underline;
    }
    a:focus {
      outline: 2px solid ${(props) => props.theme.uiPurple};
    }
  }

  ${(props) =>
    props.isGrid &&
    css`
      margin-bottom: 0.625rem;
      @media screen and (max-width: 640px) {
        margin-bottom: 1.5rem;
      }
    `}
`;

export const CardImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.src && props.src});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.5rem;

  overflow: hidden;

  ${(props) =>
    !props.isGrid &&
    css`

    width:10.75rem;
    height:6.875rem;
    @media screen and (max-width: 640px) {
     width:9rem;
     height:5.75rem;
    `}

  ${(props) =>
    props.isGrid &&
    css`
      @media screen and (max-width: 640px) {
        min-height: 6.25rem;
        max-width: 9.75rem;
      }
      @media screen and (min-width: 641px) {
        min-height: 7rem;
      }
      @media screen and (min-width: 990px) {
        min-height: 8.5rem;
      }
      @media screen and (min-width: 1200px) {
        min-height: 10.875rem;
      }
    `}
`;

export const CardBrandName = styled.h2`
  line-height: ${(props) => props.theme.lineHeight3};
  padding-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontBody2};

  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.fontBody3};
  }
`;

export const Points = styled.div`
  margin-bottom: auto;
  line-height: 1;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.uiPurple};
  font-size: ${(props) => props.theme.fontBody3};
  text-decoration: none;
`;

export const CardBody = styled.div`
  margin-top: 1rem;

  ${(props) =>
    props.isGrid &&
    css`
      @media screen and (min-width: 991px) {
        margin-top: 1.5rem;
      }
    `}
`;
