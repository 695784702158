/* eslint-disable no-shadow */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// State
import { useStateMachine } from 'little-state-machine';
import Loader from '../../../common/Loader/Loader';
import { updateTransactionsAwards } from '../../../../store/actions';

// Helpers
import { hasAvailableAwards } from '../../../../helpers/pointsHelpers';
import { viewPointsForCreditEcomm } from '../../../../helpers/handleGoogleAnalyticsHelper';

// Components
import PointsRadioList from '../../Fields/PointsRadioList';

import { PointsGrid } from '../PointsShopStyles';

// Hooks
import useAward from '../../../hooks/points/shop/useAward';

/**
 *
 * We fetcht the BYOT and PFC transactions fore both
 * set loader based on the page viewed
 *
 * @param {*} param
 * @returns  {a list of check box fields based on page and viewport}
 */

export default function TransactionsPFC({ selectAmountLabel, page, customData, languageCode }) {
  const {
    actions,
    state: {
      transactions: {
        cards,
        selectedCard,
        awards: { pfc },
      },
    },
  } = useStateMachine({ updateTransactionsAwards });

  const { data: pfcAward, isLoading, error } = useAward(selectedCard.accountSegmentCode, 'PFC');

  useEffect(() => {
    if (!isLoading && pfcAward && !hasAvailableAwards(pfc)) {
      const sortedPFC = pfcAward.sort((a, b) => a.dollarValue - b.dollarValue);
      actions.updateTransactionsAwards({
        pfc: sortedPFC,
        awardProduct: pfcAward,
      });
    }

    if (!isLoading && hasAvailableAwards(pfc)) {
      viewPointsForCreditEcomm();
    }
  }, [isLoading, selectedCard]);

  if (isLoading && !hasAvailableAwards(pfc)) return <Loader />;

  if (hasAvailableAwards(pfc)) {
    return (
      <>
        {selectAmountLabel && <p className={`mb-2 mt-4${page === 'pfc' ? ' pfc-select-amount' : ''}`}>{selectAmountLabel}</p>}
        {cards.map((card, index) => (
          <div key={index} className="inline-block">
            <PointsGrid
              page={page}
              key={index}
              style={{
                display: card.cardTitle !== selectedCard.cardTitle ? 'none' : '',
              }}
            >
              {pfc.map((transaction, index) => (
                <PointsRadioList
                  key={index}
                  fieldId={`transaction-${transaction.code}`}
                  fieldType="radio"
                  name="mobile-redeem"
                  customData={customData}
                  transaction={transaction}
                  languageCode={languageCode}
                />
              ))}
            </PointsGrid>
          </div>
        ))}
      </>
    );
  }
  return null;
}

TransactionsPFC.propTypes = {
  selectAmountLabel: PropTypes.string,
  page: PropTypes.string,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
