import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { generateMonthOptions } from '../../../helpers/dateHelpers';
import FormSelect from './FormSelect';

function BirthMonthDropdown({ name, label, defaultOption, options, rules, placeholder, gaFlow = '' }) {
  const monthOptions = useMemo(() => generateMonthOptions(options), [options]);

  return (
    <div className="select-wrapper">
      <FormSelect
        gaFlow={gaFlow}
        name={name}
        label={label}
        options={monthOptions}
        defaultOption={defaultOption || null}
        placeholder={placeholder}
        rules={{ ...rules }}
        hideError
      />
    </div>
  );
}

BirthMonthDropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultOption: PropTypes.string,
  options: PropTypes.array,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  gaFlow: PropTypes.string,
};

export default BirthMonthDropdown;
