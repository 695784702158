import styled from 'styled-components';

export const IntroStyle = styled.section`
  padding-top: 2.5rem;
  padding-bottom: 1.75rem;
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 5rem;
    padding-bottom: 3.75rem;
  }

  ul li {
    margin: 0 0 1rem 0;
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
  }

  p {
    font-size: ${(props) => props.theme.fontBody1};
    line-height: ${(props) => props.theme.lineHeight3};
  }
  hr {
    margin: 3rem 0;
    @media screen and (min-width: 1024px) {
      margin: 3.75rem 0;
    }
  }

  h2 {
    font-size: ${(props) => props.theme.headingSmall};
  }

  @media screen and (max-width: 640px) {
    hr {
      margin: 1.75rem 0;
    }

    h2 {
      font-size: ${(props) => props.theme.fontBody1};
    }

    p {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;

export const LastUpdated = styled.div`
  font-size: ${(props) => props.theme.fontBody1};
  font-weight: bold;
  color: ${(props) => props.theme.uiDarkPurple};
  margin-bottom: 0.5rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody3};
  }
`;
export const ContentStyle = styled.div`
  hr {
    margin-bottom: 50px;
  }
`;

export const Divider = styled.hr`
  margin: 2rem 0;
  @media screen and (max-width: 640px) {
    margin: 1.5rem 0;
  }
`;

export const TravelIntro = styled.div`
  margin-top: 5rem;
  margin-bottom: 0.5rem;
  @media screen and (min-width: 641px) and (max-width: 990px) {
    margin-top: 3rem;
  }
  @media screen and (max-width: 640px) {
    margin-top: 2.5rem;
  }
`;
