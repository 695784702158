/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';

import SliderTileContents from './SliderTileContents';

import { BannerItemV2 } from './BannerStyles';

import { getFeatureBannerMapping, handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown } from '../../../helpers/utils';

export default function SliderTile({ customData, content, id, bannersNum, index, sectionTitle, bannerType, pageName, slot }) {
  const { webImage, appImage, description, cTALink, eyebrow, heading, subCopy } = content.fields || content.cmsContent;
  const { learnMoreLabel } = customData;

  const handleClick = (e) => {
    try {
      const ecommerce = getFeatureBannerMapping(
        'feature_banner_click',
        'Feature Banner Click',
        content,
        bannerType,
        pageName,
        id,
        index,
        sectionTitle,
        slot
      );

      const dataLayer = {
        event: 'select_promotion',
        ecommerce,
      };

      handleGAEventsBase(dataLayer);
    } catch {
      //
    }
  };

  useEffect(() => {
    const ecommerce = getFeatureBannerMapping(
      'feature_banner_impression',
      'Feature Banner Impressions',
      content,
      bannerType,
      pageName,
      id,
      index,
      sectionTitle,
      slot
    );

    const dataLayer = {
      event: 'view_promotion',
      ecommerce,
    };

    handleGAEventsBase(dataLayer);
  }, []);

  return (
    <BannerItemV2 bannersNum={bannersNum}>
      <Link
        href={cTALink?.href || cTALink?.href}
        aria-label={`${heading} ${cTALink?.text}`}
        target={`${cTALink?.target ? cTALink?.target : '_self'}`}
        role="link"
        onClick={() => {
          handleClick();
        }}
        tabIndex="0"
        onKeyDown={(e) => handleKeyDown(e, handleClick)}
      >
        <SliderTileContents
          bannersNum={bannersNum}
          webImage={webImage}
          appImage={appImage}
          eyebrow={eyebrow}
          heading={heading}
          subCopy={subCopy || description}
          learnMoreLabel={learnMoreLabel || content.fields.learnMoreLabel}
        />
      </Link>
    </BannerItemV2>
  );
}

SliderTile.propTypes = {
  customData: PropTypes.object,
  content: PropTypes.object,
  id: PropTypes.string,
  bannersNum: PropTypes.number,
  index: PropTypes.string,
  sectionTitle: PropTypes.string,
  bannerType: PropTypes.string,
  pageName: PropTypes.string,
  slot: PropTypes.number,
};
