import PropTypes from 'prop-types';

// Components
import ButtonSecondary from '../../Button/ButtonSecondary';

// Styled Comps
import { ProvinceItemsWrapper } from '../ModalStylesV2';

function ProvinceItemList({ provinces, updateProvince, selectedProvince }) {
  return (
    <ProvinceItemsWrapper>
      {provinces &&
        provinces.length &&
        provinces.map((item, index) => (
          <ButtonSecondary
            key={index}
            onClick={() => updateProvince(item.code)}
            buttonStyle={
              item.code === selectedProvince?.province ? 'secondary-button-outline-purple-dark' : 'secondary-button-black'
            }
          >
            {item.name}
          </ButtonSecondary>
        ))}
    </ProvinceItemsWrapper>
  );
}

ProvinceItemList.propTypes = {
  provinces: PropTypes.array,
  updateProvince: PropTypes.func,
  selectedProvince: PropTypes.object,
};

export default ProvinceItemList;
