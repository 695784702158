import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateLayout } from '../../store/actions';

// Hooks
import useHasMount from '../hooks/utils/useHasMount';

// Components
import HeroBannerPoints from '../common/Banners/HeroBannerPoints';

const HomepageUserBanner = ({ module }) => {
  const {
    actions,
    state: {
      transactions: { balance },
      user: { customer },
      session: { isLoggedIn },
    },
  } = useStateMachine({ updateLayout });

  useEffect(() => {
    if (isLoggedIn) {
      actions.updateLayout({ isDark: true });
    }

    return () => {
      actions.updateLayout({ isDark: false });
    };
  }, [isLoggedIn]);

  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn) {
    return <HeroBannerPoints customData={module.fields} customer={customer} balance={balance} />;
  }
  return null;
};

HomepageUserBanner.propTypes = { module: PropTypes.object };

export default HomepageUserBanner;
