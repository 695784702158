/* eslint-disable jsx-a11y/interactive-supports-focus */

import PropTypes from 'prop-types';
import Link from 'next/link';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import { SectionTitle, SectionSubtitle, OfferPartnersWrapper } from '../OfferStyles';
import PartnerTileList from '../../common/List/PartnerTileList';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown } from '../../../helpers/utils';

const FeaturedOffersPartners = ({ partners, languageCode }) => {
  const handleClick = () => {
    const dataLayer = {
      event: 'offers_see_all_partners_click',
      category: 'Partner Offers',
      action: 'See All Partners Click',
      label: 'See All Partners',
    };

    handleGAEventsBase(dataLayer);
  };

  if (partners) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <OfferPartnersWrapper>
          <div className="partners-message">
            {partners.partnersTitle && <SectionTitle>{partners.partnersTitle}</SectionTitle>}
            {partners.partnersMessage && (
              <SectionSubtitle>
                <p>{partners.partnersMessage}</p>
              </SectionSubtitle>
            )}

            {partners.partnersPageLink && partners.partnersPageLink.text && partners.partnersPageLink.href && (
              <Link
                href={partners.partnersPageLink.href}
                locale={languageCode}
                className="hidden sm:block partners-link"
                role="link"
                onClick={handleClick}
                onKeyDown={(e) => handleKeyDown(e, handleClick)}
              >
                {partners.partnersPageLink.text}
              </Link>
            )}
          </div>
          <PartnerTileList data={partners.partners} />
          {partners.partnersPageLink && partners.partnersPageLink.text && partners.partnersPageLink.href && (
            <Link href={partners.partnersPageLink.href} locale={languageCode} className="block sm:hidden partners-link mt-12">
              {partners.partnersPageLink.text}
            </Link>
          )}
        </OfferPartnersWrapper>
      </WrapperLayout>
    );
  }
  return null;
};

FeaturedOffersPartners.propTypes = { partners: PropTypes.object, languageCode: PropTypes.string };
export default FeaturedOffersPartners;
