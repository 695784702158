/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

import { StyledInput } from '../FormStyles';

function FormEmailInput({ isDisabled, name, label, defaultVal, fieldType, placeholder, rules, errorMessage, onFocus, onClick }) {
  const { actions } = useStateMachine({ updateUser });
  const [inputVal, setInputVal] = useState('');

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, control, setValue, formState, reset, getValues } = formContext;

  useEffect(() => {
    setValue(name, inputVal || defaultVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  return (
    <FieldLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <StyledInput
        onFocus={onFocus}
        onClick={onClick}
        name={name}
        id={name}
        type={fieldType || 'text'}
        aria-invalid={!!error}
        aria-labelledby={`form-${name}`}
        aria-disabled={isDisabled}
        aria-required
        placeholder={placeholder}
        defaultValue={defaultVal}
        disabled={isDisabled}
        {...register(name, {
          ...rules,
          pattern: {
            message: errorMessage,
            // eslint-disable-next-line no-useless-escape
            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
          },
        })}
        className={`${isDisabled ? 'field-is-disabled' : ''}${
          (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? ' field-has-error' : ''
        }`}
        isError={isError}
      />
      {isError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormEmailInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default FormEmailInput;
