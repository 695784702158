import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export default function ClientOnlyPortal({ children, selector }) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, [selector]);

  if (hasMounted && document.querySelector(selector)) {
    return createPortal(children, document.querySelector(selector));
  }
  return null;
}
