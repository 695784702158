import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import { OfferActions } from '../Sections';
import OffersList from '../OffersList';
import NoOffers from '../NoOffers';

// Hooks
import useSortedOffersList from '../../hooks/offers/useSortedOffersList';

// Helpers
import { checkListForItems } from '../../../helpers/offersHelpers';

function LoadableOffersTab({ customData, languageCode, offerOptions }) {
  const {
    state: {
      offers: { offersList, sortBy, isLoading },
    },
  } = useStateMachine();

  const sortedList = useSortedOffersList(offersList, sortBy);
  const hasLoadableOffers = sortedList && sortedList.some((offer) => !offer.isOptedIn);

  return (
    <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby="loadable">
      <WrapperLayout
        className={`global-content-wrapper ${sortedList?.length ? 'min-half-screen' : ''}`}
        isGray
        customStyles="offersGeneralOffers"
      >
        <OfferActions
          page="loadable"
          customData={customData}
          offerOptions={offerOptions}
          loadAllOffers={hasLoadableOffers}
          showFilter
          disable={!sortedList?.length}
        />

        {checkListForItems(sortedList) && !isLoading ? (
          <OffersList
            customData={customData.pageContent}
            languageCode={languageCode}
            filteredList={sortedList}
            loadOfferLabel={customData.pageContent.loadOfferLabel}
            offerLoadedLabel={customData.pageContent.offerLoadedLabel}
            listName="Loadable offers tab"
          />
        ) : (
          <NoOffers customData={customData} />
        )}
      </WrapperLayout>
    </div>
  );
}

LoadableOffersTab.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  offerOptions: PropTypes.array,
};
export default LoadableOffersTab;
