import PropTypes from 'prop-types';

import { Row, TableRowItem } from '../../../../common/Table/TableStyles';
import TransactionWarning from '../../../../common/Errors/TransactionWarning';

export default function PointsRowWarning({ warningLabel, error }) {
  return (
    <Row role="row" className="points-error-row" error={error}>
      <TableRowItem role="cell" />
      <TableRowItem role="cell" className="points-warning-cell">
        <TransactionWarning
          error={{
            message: `${warningLabel}`,
          }}
          simplified
        />
      </TableRowItem>
    </Row>
  );
}

PointsRowWarning.propTypes = {
  warningLabel: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
};
