import styled from 'styled-components';

export const DatePickerNav = styled.button`
  width: auto;
  height: auto;
  line-height: 1;
  background-color: transparent;
  color: ${(props) => props.theme.uiPurple};
  padding: 1rem;
`;
