import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../../store/actions';

// Components
import GuestSettingsEmail from './GuestSettingsEmail';
import Loader from '../../../common/Loader/Loader';

// Hooks
import usePreferencesPublic from '../../../hooks/account/usePreferencesPublic';

import { checkArrayForItems } from '../../../../helpers/utils';

export default function GuestSettings({ customData, errorsList }) {
  const {
    actions,
    state: {
      user: { guestPreferences },
    },
  } = useStateMachine({ updateUser });

  const { isLoading, guestPreferencesData } = usePreferencesPublic();

  useEffect(() => {
    if (!isLoading && guestPreferencesData && !guestPreferences.length) {
      actions.updateUser({
        guestPreferences: guestPreferencesData,
      });
    }
  }, [guestPreferences, isLoading]);

  if (!checkArrayForItems(guestPreferences)) return <Loader />;

  return <GuestSettingsEmail customData={customData} errorsList={errorsList} guestPreferencesData={guestPreferences} />;
}

GuestSettings.propTypes = { customData: PropTypes.object, errorsList: PropTypes.array };
