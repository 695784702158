import Disclaimer from '../../site-pages/Disclaimer';

import Accordion from './Accordion';
import OpenPDFSection from '../Sections/OpenPDFSection';
import { getExpandedFirstItem, isEmpty } from '../../../helpers/utils';

export default function AccordionContent({ fields, customData }) {
  if (!isEmpty(fields) && customData?.accordionList.length) {
    return (
      <>
        <Accordion
          accordionList={customData?.accordionList}
          firstItemExpanded={getExpandedFirstItem(customData?.accordionList[0].contentID, customData?.accordionList[0].contentID)}
        />
        {fields.vIewPDFButtonText && fields.pdfLinkUrl?.href && (
          <OpenPDFSection buttonText={fields.vIewPDFButtonText} urlLink={fields.pdfLinkUrl?.href} />
        )}

        <Disclaimer message={fields.disclaimerMessage} backToTopMessage={fields.backToTopLabel} />
      </>
    );
  }
  return null;
}
