import { BackdropFilter } from '../HeaderStyles';

import { enableScrolling } from '../../../../../helpers/behavourHelper';

export default function Backdrop({ setOpenHumburger, openHumburger }) {
  const handleMenuClose = () => {
    setOpenHumburger(!openHumburger);

    document.body.classList.remove('prevent-mobile-scroll');
    enableScrolling();
  };

  if (openHumburger) {
    return <BackdropFilter onClick={handleMenuClose} />;
  }
  return null;
}
