import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions, updatePoints } from '../../../../../store/actions';

// Components
import Button from '../../../../common/Button/Button';
import ButtonSimple from '../../../../common/Button/ButtonSimple';
import CloseIcon from '../../../../common/Icons/CloseIcon';
// Styles

import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ButtonContainer,
  ModalPopupContainer,
} from '../../../../common/Modals/ModalStylesV2';
// import { findSelectedCard } from '../../../helpers/filterHelpers';

// Styles

export default function PartialModalBody({ customData, toggle }) {
  const { actions } = useStateMachine({ updateTransactions, updatePoints });

  const { partialModalPromptLabel, partialModalMessage, partialModalContinueButtonLabel, partialModalCancelButtonLabel } =
    customData;

  const continueWithPartial = () => {
    actions.updatePoints({ step: 2 });
    toggle(); // closes modal
  };
  return (
    <ModalContainer>
      <CloseIcon toggle={toggle} />
      <ModalPopupContainer>
        <ModalHeader>
          <h2 id="modal-label">{partialModalPromptLabel}</h2>
        </ModalHeader>
        {partialModalMessage && <ModalBody>{partialModalMessage}</ModalBody>}
        {partialModalContinueButtonLabel && (
          <ButtonContainer>
            <Button
              type="button"
              buttonText={partialModalContinueButtonLabel}
              ariaLabelledBy={partialModalContinueButtonLabel}
              onClick={continueWithPartial}
            >
              {partialModalContinueButtonLabel}
            </Button>

            <ButtonSimple
              type="button"
              className="cursor-pointer font-bold mt-4"
              ariaLabelledBy={partialModalCancelButtonLabel}
              onClick={() => toggle()}
            >
              {partialModalCancelButtonLabel}
            </ButtonSimple>
          </ButtonContainer>
        )}
      </ModalPopupContainer>
    </ModalContainer>
  );
}

PartialModalBody.propTypes = { customData: PropTypes.object, toggle: PropTypes.func };
