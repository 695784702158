/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

import Button from '../../../common/Button/Button';
import ButtonSimple from '../../../common/Button/ButtonSimple';

// Styles
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ButtonContainer,
  ModalPopupContainer,
} from '../../../common/Modals/ModalStylesV2';
// Helpers
import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';
import CloseIcon from '../../../common/Icons/CloseIcon';

export default function MissingPointsModalContent({
  customData: { overlayTitle, overlayMessage, cancelLabel, helpDeskLink },
  toggle,
}) {
  return (
    <ModalContainer>
      <CloseIcon toggle={toggle} />
      <ModalPopupContainer>
        <ModalHeader>{overlayTitle && <h2 id="modal-label">{overlayTitle}</h2>}</ModalHeader>
        {overlayMessage && (
          <ModalBody>
            <div dangerouslySetInnerHTML={renderHTML(overlayMessage)} />
          </ModalBody>
        )}
        <ButtonContainer>
          {helpDeskLink && helpDeskLink.text && (
            <Button
              type="button"
              buttonLoadingText={helpDeskLink.text}
              ariaLabel={helpDeskLink.text}
              toggle={toggle}
              target={helpDeskLink.target}
              onClick={() => {
                handleGAEvents(
                  'Points and Transactions Help',
                  'Points Transactions',
                  'Help Icon Click',
                  'points_go_to_help_desk_click'
                );
                window.location.href = helpDeskLink.href;
              }}
            >
              {helpDeskLink.text}
            </Button>
          )}

          {cancelLabel && (
            <div className="missing-pts-cancel-btn">
              <ButtonSimple
                className="cursor-pointer font-bold mb-4 top-spacing"
                ariaLabel={cancelLabel}
                role="button"
                onClick={() => toggle()}
              >
                {cancelLabel}
              </ButtonSimple>
            </div>
          )}
        </ButtonContainer>
      </ModalPopupContainer>
    </ModalContainer>
  );
}

MissingPointsModalContent.propTypes = {
  customData: PropTypes.object.isRequired,
  overlayTitle: PropTypes.string,
  overlayMessage: PropTypes.string,
  cancelLabel: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};
