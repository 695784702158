/* eslint-disable react/no-danger */

import { AccordionItemState } from 'react-accessible-accordion';
import PropTypes from 'prop-types';

function AccordionTitle({ page, title, subtitle, languageCode }) {
  if (page === 'FAQ') {
    let labelQ = 'Q: ';

    if (languageCode === 'fr-ca') {
      labelQ = 'Q : ';
    }

    return (
      <h3 className="faq">
        <AccordionItemState>{({ expanded }) => (expanded ? labelQ : '')}</AccordionItemState>
        {title}
        {subtitle && <p className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
      </h3>
    );
  }

  return (
    <h2>
      {title}
      {subtitle && <p className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </h2>
  );
}

AccordionTitle.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  languageCode: PropTypes.string,
};

export default AccordionTitle;
