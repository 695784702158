import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  border-radius: 4px;
  transform: translateY(-50%);
  width: 100%;
  padding: 24px 30px;
  max-width: 442px;

  @media screen and (max-width: 640px) {
    max-width: 90%;
  }

  p {
    color: ${(props) => props.theme.uiCharcoal} !important;
  }
`;
