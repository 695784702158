/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateLayoutAction(state, payload) {
  return {
    ...state,
    layout: {
      ...state.layout,
      ...payload,
    },
  };
}
