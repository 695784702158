export function getNewTabsArray(tabsArr = []) {
  const newTabArray =
    tabsArr &&
    tabsArr.map((tab) => ({
      tabTitle: tab.tabTitle,
      tabContent: tab.tabContent,
    }));

  return newTabArray;
}
