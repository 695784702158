import PropTypes from 'prop-types';
import ExternalImage from '../Images/ExternalImage';
import { BannerContents } from './BannerStyles';

export default function SliderTileContents({ bannersNum, webImage, appImage, eyebrow, heading, subCopy, learnMoreLabel }) {
  return (
    <BannerContents className="banner-content-wrapper flex items-end" bannersNum={bannersNum}>
      {webImage && (
        <div className="relative banner-image web-image">
          <ExternalImage url={webImage?.url} fill sizes="(max-width: 640px) 100vw, 60vw" className="object-cover object-center" />
        </div>
      )}
      {appImage && (
        <div className="relative banner-image app-image">
          <ExternalImage url={appImage?.url} fill sizes="(max-width: 640px) 100vw, 60vw" className="object-cover object-center" />
        </div>
      )}

      <div className="banner-content">
        <div className="banner-content-inner">
          {eyebrow && <p className="content-eyebrow">{eyebrow}</p>}
          {heading && <h2 className="content-heading">{heading}</h2>}
          {subCopy && <div className="content-subCopy">{subCopy}</div>}
          <span className="banner-learnmore desktop-learnmore">{learnMoreLabel}</span>
        </div>
        <div className="banner-cta-arrow">
          <span className="banner-learnmore mobile-learnmore">{learnMoreLabel}</span>
          <i className="cta-arrow-link" aria-hidden />
        </div>
      </div>
    </BannerContents>
  );
}
SliderTileContents.propTypes = {
  bannersNum: PropTypes.number,
  webImage: PropTypes.object,
  appImage: PropTypes.object,
  eyebrow: PropTypes.string,
  heading: PropTypes.string,
  subCopy: PropTypes.string,
  learnMoreLabel: PropTypes.string,
};
