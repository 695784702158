import PropTypes from 'prop-types';

import { ButtonDay } from './DatePickerDayStyles';
import { isDayDisabled } from '../../../../../helpers/dateHelpers';

export default function DatePickerDay({ date, today, selectedDate, isSameDate, selectedFromDate, selectedToDate }) {
  const isWeekend = [0, 6].includes(date.weekDay.index);

  return (
    <ButtonDay
      type="button"
      tabIndex={0}
      isSameDate={isSameDate(date, selectedDate)}
      isSameToday={isSameDate(date, today)}
      isWeekend={isWeekend}
      disabled={isDayDisabled(date, today, selectedFromDate, selectedToDate)}
    >
      {date.format('D')}
    </ButtonDay>
  );
}

DatePickerDay.propTypes = {
  date: PropTypes.object,
  today: PropTypes.object,
  selectedDate: PropTypes.object,
  isSameDate: PropTypes.func,
  selectedFromDate: PropTypes.string,
  selectedToDate: PropTypes.object,
};
