/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const Filter = styled.li`
  display: inline-block;
  border-radius: 0;
  background: inherit;
  width: auto;
  font-weight: normal;
  color: ${(props) => props.theme.uiCharcoal};
  padding: 0.35rem 1rem;
  background-color: ${(props) => props.theme.uiGrey02};
  border: 1px solid ${(props) => props.theme.borderColor};
  margin: 0 1rem 1rem 0;
  transition: 0.2s all ease-in;
  cursor: pointer;

  &.selected {
    padding-left: 2.5rem;
    background-color: ${(props) => props.theme.uiBlack};
    border: 1px solid ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.whiteColor};
    background-image: url('/assets/white-checkmark.svg');
    background-repeat: no-repeat;
    background-position: 1rem center;
  }

  ${(props) =>
    props.slidingTabs &&
    css`
      background-color: ${(props) => props.theme.whiteColor};
      border: 0;
      padding-bottom: 1rem;
      position: relative;
      margin: 0 auto;
      transition: all 0.25s ease-in;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 4px;
        background-color: ${(props) => props.theme.uiPurple};
        margin: 0 1rem;
        transform-origin: 0 0;
        transition: all 0.1s ease-in;
      }

      &.selected {
        color: ${(props) => props.theme.uiBlack};
        padding: 0.35rem 1rem;
        background-image: none;
        background-color: transparent;
        border: 0;

        &:after {
          content: '';
          width: 100%;
          transform-origin: 0 0;
          transition: all 0.1s ease-in;
        }
      }
    `}
`;
export const TabsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;
export const Tabs = styled.div`
  overflow: hidden;
  display: flex;
  margin-top: 3rem;
  width: 100%;
  position: relative;
  margin: 0 auto;
  height: 2.875rem;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 29px;
    left: 0;
  }
`;
// border-bottom: 1px solid ${(props) => props.theme.uiGrey02};

export const Tab = styled.div`
  width: auto !important;
  min-width: max-content;
  padding: 0.5rem 0.125rem 1rem 0.125rem;
  height: 2.875rem;
  outline: 0;
  cursor: pointer;
  position: relative;
  font-size: ${(props) => props.theme.fontBody2};
  line-height: ${(props) => props.theme.lineHeight3};
  @media screen and (max-width: 1023px) {
    font-size: ${(props) => props.theme.fontBody3};
  }
  font-weight: ${(props) => (props.active ? 'bold' : 400)};
  border-radius: 0;
  background-color: transparent;
  color: ${(props) => props.theme.uiDarkGrey};
  transition: all 0.2s ease-in-out;

  &.selected {
    color: ${(props) => props.theme.uiCharcoal};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.uiPurple};
  }

  &:after {
    ${(props) => (props.active ? 'content: ""' : '')};
    height: 0.25rem;
    width: 100%;
    background-color: ${(props) => props.theme.uiPurple};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  margin: 0 1.75rem 2rem 1.75rem;
  @media screen and (max-width: 640px) {
    margin: 0 1.25rem 2rem 1.25rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1439px) {
    margin: 0 1rem 2rem 1rem;
  }

  &.last {
    margin: 0 0 2rem 1.75rem;
    @media screen and (max-width: 640px) {
      margin: 0 0 2rem 1.25rem;
    }
    @media screen and (min-width: 641px) and (max-width: 1439px) {
      margin: 0 0 2rem 1rem;
    }
  }

  &.first {
    margin: 0 1.75rem 2rem 0;
    @media screen and (max-width: 640px) {
      margin: 0 1.25rem 2rem 0;
    }
    @media screen and (min-width: 641px) and (max-width: 1439px) {
      margin: 0 1rem 2rem 0;
    }
  }

  ${(props) =>
    props.slidingTabs &&
    css`
      background-color: ${(props) => props.theme.whiteColor};
      border: 0;
      padding-bottom: 1rem;
      position: relative;
      margin: 0;
      transition: all 0.25s ease-in;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 0.25rem;
        background-color: ${(props) => props.theme.uiPurple};
        margin: 0 1rem;
        transform-origin: 0 0;
        transition: all 0.1s ease-in;
      }

      &.selected {
        color: ${(props) => props.theme.uiBlack};
        padding: 0.35rem 1rem;
        background-image: none;
        background-color: transparent;
        border: 0;

        &:after {
          content: '';
          width: 100%;
          transform-origin: 0 0;
          transition: all 0.1s ease-in;
        }
      }
    `}
`;
export const BottomShadow = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 6px;
`;
export const TabSliderWrapper = styled.div`
  height: 2.875rem;
  box-sizing: border-box;
  position: relative;

  &.global-content-wrapper {
    padding-right: 0;
  }
  .mobile-gradient {
    display: block;
    width: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 90;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 85%);
  }

  .slick-slider.tabs-slider {
    .slick-list {
      box-sizing: initial;
      height: auto !important;
      .slick-track {
        .slick-slide {
          margin-left: -5px;
        }
      }
    }
  }
`;
