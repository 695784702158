import PropTypes from 'prop-types';
// Global states
import { useStateMachine } from 'little-state-machine';

import { todayDate } from '../../../../../helpers/dateHelpers';
import FilterButton from '../../../../common/Button/FilterButton/FilterButton';

export default function PointsHistoryFilterButton({ isShowing, filterLabel, handleClick }) {
  // Global State
  const {
    state: {
      points: { type, category, cardType, fromDate, toDate, history },
    },
  } = useStateMachine();

  const listFiltered = Boolean(
    type[0] !== 'ALL' || category[0] !== 'ALL' || cardType[0] !== 'ALL' || fromDate !== '1900-01-01' || toDate !== todayDate
  );

  return (
    <FilterButton
      handleClick={handleClick}
      filterLabel={filterLabel}
      isShowing={isShowing}
      active={listFiltered}
      disabled={history?.isEmpty}
    />
  );
}
PointsHistoryFilterButton.propTypes = {
  isShowing: PropTypes.bool,
  filterLabel: PropTypes.string,
  handleClick: PropTypes.func,
};
