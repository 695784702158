function checkDates(startDate, endDate) {
  const d = new Date();
  const dUTC = d.toUTCString();
  const now = new Date(dUTC).getTime();

  const dStartDate = new Date(startDate);
  const dStartDateUTC = dStartDate.toUTCString();
  const start = new Date(dStartDateUTC).getTime();

  const dEndDate = new Date(endDate);
  const dEndDateUTC = dEndDate.toUTCString();
  const end = new Date(dEndDateUTC).getTime();
  const isValid = now >= start && now <= end;
  return isValid;
}

export function deriveActiveMessages(userIM) {
  if (!userIM) return [];
  const activeMsg = [];
  userIM.forEach((item) => {
    if (checkDates(item.startDate, item.endDate)) activeMsg.push(item);
  });

  return activeMsg;
}

export function deriveAuthMsg(userIM, province, inactiveVersions) {
  if (!userIM) return null;
  const activeMsgList = deriveActiveMessages(userIM);
  const authMsg = activeMsgList.filter((item) => item.provinces && item.provinces.indexOf(province) >= 0);
  if (authMsg.length && inactiveVersions && !inactiveVersions.includes(authMsg[0].version)) {
    return authMsg[0];
  }
  return null;
}

export function derivePublicMsg(userIM, inactiveVersions) {
  if (!userIM) return null;
  const activeMsgList = deriveActiveMessages(userIM);
  const msg = activeMsgList.filter((item) => item.pub === 'true');

  if (msg.length && inactiveVersions && !inactiveVersions.includes(msg[0].version)) {
    return msg[0];
  }
  return null;
}
