import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

// helpers
import { parseHashParams } from '../../../helpers/navigationHelpers';

export default function useHashParams() {
  const [params, setParams] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const hashUri = window.location.hash || (router.asPath.includes('#') && router.asPath);
    if (hashUri) {
      // Handle b2c login hash comes /#state=
      // promotion params come with #/param=
      if (hashUri.includes('/#') || hashUri.includes('#/')) {
        setParams(parseHashParams(hashUri.substr(2)));
      }
    }
  }, [router]);

  return params;
}
