import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../b2c/authConfig';

const LoggedContent = () => <div />;
const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <div>
      <button type="button" onClick={() => handleLogin()}>
        Sign in
      </button>
    </div>
  );
};

const SignInSignOutButton = () => <SignInButton />;

const UserStateRichTextArea = (props) => (
  <div className="relative px-8">
    <AuthenticatedTemplate>
      <LoggedContent />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <center>Please sign-in to see your profile information.</center>
      <SignInSignOutButton />
    </UnauthenticatedTemplate>
  </div>
);

UserStateRichTextArea.getCustomInitialProps = async ({ languageCode }) => {
  try {
    return {
      languageCode,
      login: false,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default UserStateRichTextArea;
