import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

import { PlainHeadingStyles } from '../common/Headings/PlainHeadingStyles';

const Heading = ({ module }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <PlainHeadingStyles
        fontColor={fields.titleColor?.fields?.code || null}
        bgColor={fields.backgroundColor?.fields?.code || null}
      >
        {fields.title}
      </PlainHeadingStyles>
    );
  }

  return null;
};
Heading.propTypes = {
  module: PropTypes.object.isRequired,
};
export default Heading;
