/* eslint-disable no-shadow */
import { useState } from 'react';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updateCustomerData } from '../../../../store/actions';

import { getCustomerAddresses, updateAddress, deleteAddress } from '../../../../pages/api/services';

// Components
import CloseIcon from '../../../common/Icons/CloseIcon';
import FormInput from '../../fields/FormInput';
import FormPhoneInput from '../../fields/FormPhoneInput';
import FormCheckbox from '../../fields/FormCheckbox';
import FormSave from '../../FormSave';
import CanadaPostSearch from '../../fields/CanadaPostSearch';
import DeleteAddress from '../DeleteAddress/DeleteAddress';
import { AddressModalHeader } from './EditAddressStyles';

// Helpers
import { enableScrolling } from '../../../../helpers/behavourHelper';
import { displayFieldErrorMessage, regexEnFrChars } from '../../../../helpers/formHelpers';
import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';
import { generateErrorMessage } from '../../../../helpers/errorsHelper';

export default function EditAddressModal({ formMethods, showModal, setShowModal, ...props }) {
  const {
    cancelLabel,
    firstNameLabel,
    lastNameLabel,
    editAddressLabel,
    setPrimaryShippingAddressLabel,
    provinces,
    languageCode,
    errorsList,
    fName,
    lName,
    addressLine1,
    addressLine2,
    city,
    provinceCode,
    postalCode,
    profileFName,
    profileLName,
    disableNameEdit,
    shippingPrimary,
    removeAddressLabel,
    removeAddressModalHeader,
    removeAddressModalMessage,
    yesLabel,
    noLabel,
    closeLabel,
    phoneLabel,
    primaryPhoneNumber,
    addressId,
    saveLabel,
    setAddressList,
    saveLabelLoading,
    addressModal = true,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [enterAddressManually, setEnterAddressManually] = useState(false); // TODO: remove this? value not used anywhere
  const [submitting, setSubmitting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    actions,
    state: {
      session: { accessToken },
    },
  } = useStateMachine({ updateNotification, updateCustomerData });

  const onSubmit = async (data) => {
    setSubmitting(true);
    const reqBody = {
      countryCode: 'CA',
      provinceCode: data.provinceCode.value || data.provinceCode,
      city: data.city,
      postalCode: data.postalCode.split(/\s/).join(''),
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      firstName: fName ? data.firstName : profileFName,
      lastName: lName ? data.lastName : profileLName,
      primaryPhoneNumber: data.primaryPhoneNumber.replace(/-/g, ''),
      primary: data.primary,
      uniqueId: addressId,
    };

    updateAddress(reqBody, accessToken)
      .then(async (res) => {
        if (res.data) {
          // updates item
          await getCustomerAddresses(accessToken)
            .then((res) => {
              const addressData = res.data.data;

              setAddressList([...addressData]);
              if (disableNameEdit) {
                const primaryAddress = addressData.find((addr) => addr?.addressType?.toUpperCase() === 'SCENE');

                if (primaryAddress) {
                  actions.updateCustomerData({ addresses: [primaryAddress] });
                }
              }
              // reset popup
              setEnterAddressManually(false);
              setShowModal(!showModal);
              formMethods.reset();
              enableScrolling();
            })
            .catch((error) => {
              if (error.response !== null && error.response.data !== null) {
                actions.updateNotification({
                  message: generateErrorMessage(
                    error?.response?.data?.validationErrors
                      ? Object.keys(error.response.data.validationErrors)
                      : ['General.BadRequest'],
                    errorsList
                  ),
                  status: 'error',
                });
              }
              console.error(error);
            });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: generateErrorMessage(
              error?.response?.data?.validationErrors
                ? Object.keys(error.response.data.validationErrors)
                : ['General.BadRequest'],
              errorsList
            ),
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  const updatePopupClose = () => {
    setEnterAddressManually(!showModal);
    formMethods.reset();
    setShowModal(!showModal);
    enableScrolling();
  };

  const handleAddressDelete = () => {
    setDeleteModal(!deleteModal);
    handleGAEvents('Profile - Edit Address - Remove Address', 'Account', 'Remove Address Click', 'profile_remove_address_click');
    if (deleteModal) {
      deleteAddress(addressId, accessToken)
        .then(async (res) => {
          if (res) {
            enableScrolling();

            // deletes item
            await setAddressList((prevState) => {
              const newArr = [...prevState];
              const indexOfArr = newArr.findIndex((address) => address.uniqueId === addressId);
              newArr.splice(indexOfArr, 1);
              return newArr;
            });

            // updates primary shipping on delete
            await getCustomerAddresses(accessToken)
              .then((res) => {
                const addressData = res.data.data;

                setAddressList([...addressData]);

                // reset popup
                setEnterAddressManually(false);
                setShowModal(false);
                formMethods.reset();
              })
              .catch((error) => {
                if (error.response !== null && error.response.data !== null) {
                  actions.updateNotification({
                    message: generateErrorMessage(
                      error?.response?.data?.validationErrors
                        ? Object.keys(error.response.data.validationErrors)
                        : ['General.BadRequest'],
                      errorsList
                    ),
                    status: 'error',
                  });
                }
                console.error(error);
              });
          }
        })
        .catch((error) => {
          if (error.response !== null && error.response.data !== null) {
            actions.updateNotification({
              message: generateErrorMessage(
                error?.response?.data?.validationErrors
                  ? Object.keys(error.response.data.validationErrors)
                  : ['General.BadRequest'],
                errorsList
              ),
              status: 'error',
            });
          }
          console.error(error);
        });
    }
  };

  return (
    <>
      {!deleteModal && <span className="overlay" />}
      <div className="form-container p-0 my-24 mt-0 bg-white text-base rounded-lg">
        <form className="p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="modal-popup w-full max-w-xl mx-auto shadow-xl">
            <AddressModalHeader className="relative modal-header flex justify-center">
              {editAddressLabel && <h4 className="text-lg font-bold">{editAddressLabel}</h4>}
              <CloseIcon toggle={updatePopupClose} type="pos-right" />
            </AddressModalHeader>
            <div className="p-8 pt-0">
              <FormInput
                id="firstName"
                fieldType="text"
                name="firstName"
                isReadOnly={disableNameEdit && true}
                label={firstNameLabel}
                placeholder={firstNameLabel}
                defaultVal={fName || profileFName}
                addressModal={addressModal}
                rules={{
                  pattern: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Invalid'),
                    value: regexEnFrChars,
                  },
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Required'),
                    value: true,
                  },
                  minLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
                    value: 2,
                  },
                  maxLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
                    value: 50,
                  },
                }}
              />

              <FormInput
                id="lastName"
                fieldType="text"
                name="lastName"
                isReadOnly={disableNameEdit && true}
                label={lastNameLabel}
                placeholder={lastNameLabel}
                defaultVal={lName || profileLName}
                addressModal={addressModal}
                rules={{
                  pattern: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Invalid'),
                    value: regexEnFrChars,
                  },
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Required'),
                    value: true,
                  },
                  minLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
                    value: 2,
                  },
                  maxLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
                    value: 50,
                  },
                }}
              />

              <CanadaPostSearch
                setEnterAddressManually={setEnterAddressManually}
                {...props}
                provinces={provinces}
                culture={languageCode}
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                provinceCode={provinceCode}
                city={city}
                postalCode={postalCode}
                isEditAddress
                addressModal={addressModal}
              />

              <FormPhoneInput
                id="primaryPhoneNumber"
                name="primaryPhoneNumber"
                label={phoneLabel}
                addressModal={addressModal}
                defaultVal={primaryPhoneNumber}
                errorMessage={displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Invalid')}
                rules={{
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Required'),
                    value: true,
                  },
                }}
              />

              <div className="block mt-4 field-grey-out">
                <FormCheckbox
                  fieldType="checkbox"
                  label={setPrimaryShippingAddressLabel}
                  name="primary"
                  isDisabled={shippingPrimary && shippingPrimary}
                  defaultChecked={shippingPrimary && shippingPrimary}
                  rules={{
                    required: {
                      message: '',
                      value: false,
                    },
                  }}
                />
              </div>

              {!disableNameEdit && (
                <div className="inline-block mt-4 mb-6">
                  <p
                    role="presentation"
                    className="cursor-pointer underline color-black font-bold"
                    onClick={() => setDeleteModal(!deleteModal)}
                  >
                    {removeAddressLabel}
                  </p>
                </div>
              )}

              <FormSave
                layout="fullwidth"
                buttonText={saveLabel}
                cancelLabel={cancelLabel}
                isEditing
                submitting={submitting}
                buttonLoadingText={saveLabelLoading}
                updatePopupClose={updatePopupClose}
              />
            </div>
          </div>

          {!disableNameEdit && (
            <DeleteAddress
              removeAddressModalHeader={removeAddressModalHeader}
              removeAddressModalMessage={removeAddressModalMessage}
              yesLabel={yesLabel}
              noLabel={noLabel}
              closeLabel={closeLabel}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleAddressDelete={handleAddressDelete}
            />
          )}
        </form>
      </div>
    </>
  );
}

EditAddressModal.propTypes = {
  formMethods: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  addLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  addAddressHeader: PropTypes.string,
  setPrimaryShippingAddressLabel: PropTypes.string,
  provinces: PropTypes.array,
  languageCode: PropTypes.string,
  phoneLabel: PropTypes.string,
  errorsList: PropTypes.array,
  setAddressList: PropTypes.func,
  addLabelLoading: PropTypes.string,
  addressModal: PropTypes.bool,
  editAddressLabel: PropTypes.string,
  fName: PropTypes.string,
  lName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  provinceCode: PropTypes.string,
  postalCode: PropTypes.string,
  profileFName: PropTypes.string,
  profileLName: PropTypes.string,
  disableNameEdit: PropTypes.bool,
  shippingPrimary: PropTypes.bool,
  removeAddressModalHeader: PropTypes.string,
  removeAddressModalMessage: PropTypes.string,
  removeAddressLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  primaryPhoneNumber: PropTypes.string,
  addressId: PropTypes.string,
  saveLabel: PropTypes.string,
  saveLabelLoading: PropTypes.string,
};
