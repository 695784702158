import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import FocusTrap from 'focus-trap-react';

import { b2cChangePassword } from '../../../../b2c/b2cFunctions';
import { convertToSlug } from '../../../../helpers/utils';

import { Row, Title, StyledInput, FieldContent, DescriptionText } from '../../FormStyles';

// Components
import EditButton from '../../EditButton';
import EditMSALButtons from './EditMSALButtons';
import Loader from '../../../common/Loader/Loader';

import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProfilePassword(props) {
  const { fieldName, passwordMessage, passwordLabel, cancelLabel, loading, languageCode } = props;

  const [isEditing, setEditing] = useState(false);
  const { instance } = useMsal();

  const handleEditClick = () => {
    if (!isEditing) handleGAEvents('Profile - Change Password', 'Account', 'Change Password Click', 'profile_password_click');
    setEditing(!isEditing);
  };

  const handleB2Ccall = () => {
    b2cChangePassword(instance, languageCode);
  };

  return (
    <Row className="mb-0.5 relative">
      <FocusTrap
        active={isEditing}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none',
          },
          initialFocus: false,
          allowOutsideClick: true,
        }}
      >
        <div className="relative p-0 ">
          <div className="md:flex justify-between items-baseline content-center relative">
            <div className="w-52 ProfileFieldWrapper">
              <Title htmlFor={`profile-${fieldName}`} className=" mb-1 md:mb-0 font-bold mr-4 flex-initial">
                {fieldName}
              </Title>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                  <FieldContent className="md:p-1">
                    <StyledInput
                      passwordInput
                      type="password"
                      id={`profile-${fieldName}`}
                      name={fieldName}
                      aria-labelledby={`profile-${fieldName}`}
                      className="mb-3 md:mb-0 flex-1 w-full rounded profile-password-field"
                      defaultValue="12345678"
                      disabled
                      noStyling
                    />
                  </FieldContent>
                  {isEditing && passwordMessage && <DescriptionText className="md:px-2 mb-4">{passwordMessage}</DescriptionText>}

                  {isEditing && (
                    <EditMSALButtons
                      handleB2Ccall={handleB2Ccall}
                      buttonText={passwordLabel}
                      cancelLabel={cancelLabel}
                      isEditing={isEditing}
                      updateEditClick={handleEditClick}
                    />
                  )}
                </div>
                <EditButton
                  {...props}
                  handleEditClick={handleEditClick}
                  isEditing={isEditing}
                  ariaControls={`profile-${convertToSlug(fieldName)}`}
                />
              </>
            )}
          </div>
        </div>
      </FocusTrap>
    </Row>
  );
}
