import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { formatId } from '../../../helpers/stringHelpers';

const QuantityInputField = styled.div`
  display: flex;
  align-items: center;

  input[type='text'] {
    width: 56px;
    height: 44px;
    font-size: ${(props) => props.theme.fontBody2};
    border: none;
    user-select: none;
    outline: none;
    text-align: center;
  }
`;

const QuantityModifier = styled.button`
  width: 44px;
  height: 44px;
  color: black;
  background: none;
  border: 1px solid #eeeded;
  border-radius: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  ${(props) =>
    props.disabled &&
    css`
      color: #949494;
    `}
`;

function QuantityInput({ handleQuantityChange, customData }) {
  const { quantityLabel, quantityMinusLabel, quantityPlusLabel } = customData;
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    handleQuantityChange(quantity);
  }, [quantity]);

  const increment = () => {
    setQuantity((prevState) => prevState + 1);
  };

  const decrement = () => {
    setQuantity((prevState) => (prevState > 1 ? prevState - 1 : 1));
  };

  return (
    <div className="my-8">
      {quantityLabel && (
        <label
          id={`field-${formatId(quantityLabel)}`}
          htmlFor={quantityLabel.toLowerCase().replace(' ', '-')}
          aria-disabled="true"
        >
          {quantityLabel}
        </label>
      )}
      <QuantityInputField>
        <QuantityModifier
          type="button"
          onClick={decrement}
          aria-label={quantityMinusLabel}
          aria-disabled={quantity === 1}
          aria-controls={`field-${formatId(quantityLabel)}`}
          disabled={quantity === 1}
        >
          &mdash;
        </QuantityModifier>
        <input
          type="text"
          id={formatId(quantityLabel)}
          name={formatId(quantityLabel)}
          value={quantity}
          readOnly
          aria-labelledby={`field-${formatId(quantityLabel)}`}
        />
        <QuantityModifier type="button" onClick={increment} aria-label={quantityPlusLabel} disabled={quantity >= 6}>
          &#xff0b;
        </QuantityModifier>
      </QuantityInputField>
    </div>
  );
}

QuantityInput.propTypes = { handleQuantityChange: PropTypes.func, customData: PropTypes.object };
export default QuantityInput;
