import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../../../Images/ExternalImage';

import { HeaderColumn, HeaderColumnWrapper } from '../HeaderStyles';

export default function SiteHeaderLogo({ siteLogo, sitemapNode }) {
  if (sitemapNode.name === 'home') {
    return (
      <HeaderColumnWrapper className="flex-grow-0 header-logo relative">
        <HeaderColumn>
          {siteLogo && <ExternalImage url={siteLogo.url} title={siteLogo.label} fill className="object-contain object-bottom" />}
        </HeaderColumn>
        <div id="portal-home1" />
      </HeaderColumnWrapper>
    );
  }

  return (
    <HeaderColumnWrapper className="flex-grow-0 header-logo relative">
      <Link href="/">
        <HeaderColumn>
          {siteLogo && <ExternalImage url={siteLogo.url} title={siteLogo.label} fill className="object-contain object-bottom" />}
        </HeaderColumn>
      </Link>
      <div id="portal-home1" />
    </HeaderColumnWrapper>
  );
}

SiteHeaderLogo.propTypes = {
  siteLogo: PropTypes.object,
  sitemapNode: PropTypes.object,
};
