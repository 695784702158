import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import Button from '../common/Button/Button';
import { UnsubscribeButtonStyles } from './UnsubscribeStyles';
import UnsubscribeConfirm from './UnsubscribeConfirm';

export default function UnsubscribeButtons({ toggle, dynamicData, errorsList, emailTopics }) {
  const { confirmLabel, confirmLabelLoading, updateLabel, updateLabelLoading } = dynamicData;

  const router = useRouter();

  return (
    <UnsubscribeButtonStyles>
      <UnsubscribeConfirm
        toggle={toggle}
        confirmLabel={confirmLabel}
        confirmLabelLoading={confirmLabelLoading}
        emailTopics={emailTopics}
        errorsList={errorsList}
      />
      {updateLabel && (
        <Button
          type="button"
          className="unsub-btn update-btn secondary-button"
          ariaLabel={updateLabel}
          buttonLoadingText={updateLabelLoading}
          onClick={() => {
            router.push('/account/settings');
          }}
        >
          {updateLabel}
        </Button>
      )}
    </UnsubscribeButtonStyles>
  );
}

UnsubscribeButtons.propTypes = {
  toggle: PropTypes.func.isRequired,
  dynamicData: PropTypes.object.isRequired,
  errorsList: PropTypes.array.isRequired,
  emailTopics: PropTypes.array.isRequired,
};
