/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

import { BYOTMessageContainer } from '../../PointsShopStyles';

function HowItWorksBYOT({ page, bYOTMessage, howItWorksTitle, howItWorksMessage }) {
  if (page !== 'byot') return null;

  return (
    <BYOTMessageContainer>
      {bYOTMessage && <p>{bYOTMessage}</p>}
      {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
      {howItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(howItWorksMessage)} />}
    </BYOTMessageContainer>
  );
}

HowItWorksBYOT.propTypes = {
  page: PropTypes.string,
  bYOTMessage: PropTypes.string,
  howItWorksTitle: PropTypes.string,
  howItWorksMessage: PropTypes.string,
};

export default HowItWorksBYOT;
