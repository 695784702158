import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Global State
import { useStateMachine } from 'little-state-machine';

// Hooks
import useDynamicHeader from '../../hooks/utils/useDynamicHeader';

// Styles
import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';

// Components
import Button from '../../common/Button/Button';
import ConfirmationSummaryBalance from './ConfirmationSummaryBalance';
// Helpers
import { formatPoints, calcPointsTotal, amountSelected } from '../../../helpers/pointsHelpers';
import { handleGaEcommImpression, getGiftCardOrderMapping } from '../../../helpers/handleGoogleAnalyticsHelper';
import { gaExternalSku } from '../../../helpers/giftCardsHelper';

function ConfirmPointsSummary({
  summaryOrderTotalLabel,
  summaryPointsLabel,
  summaryBalanceLabel,
  checkoutSummaryLabel,
  submitting,
  orderRequested,
  pts,
  confirmButtonLabel,
  confirmButtonLabelLoading,
}) {
  const {
    state: {
      giftCards: { redeem, selectedGiftCardIndex },
      points: { added },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const total = calcPointsTotal(added) * redeem.quantity;
  const router = useRouter();
  const removeFormCart = () => {
    const amount = amountSelected(added);
    const externalSku = gaExternalSku(added, redeem?.selectedFormat, redeem?.giftCardDetails);
    handleGaEcommImpression(
      'gc_remove_from_cart',
      getGiftCardOrderMapping(
        total,
        redeem,
        amount,
        '',
        'reward_giftcard_remove_from_cart',
        'Gift Card Remove From Cart',
        selectedGiftCardIndex,
        amount,
        externalSku
      )
    );
  };
  // capturing the back button for remove_from_cart ga
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        removeFormCart();
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);
  useDynamicHeader(redeem.step);
  // const gaLabel = stringConcatHelper(total, ' | Agree&Confirm Click');

  return (
    <SectionDetails className="points-summary">
      {checkoutSummaryLabel && <SectionTitle>{checkoutSummaryLabel}</SectionTitle>}
      <DetailsInfo>
        <div className="flex justify-between">
          <p>{summaryPointsLabel}</p>
          <p>
            {balance ? formatPoints(balance, locale) : 0} {pts}
          </p>
        </div>
        <div className="flex justify-between">
          {summaryOrderTotalLabel && <p>{summaryOrderTotalLabel}</p>}
          <p>
            {total ? formatPoints(total, locale) : 0} {pts}
          </p>
        </div>
        <ConfirmationSummaryBalance summaryBalanceLabel={summaryBalanceLabel} pts={pts} locale={locale} />
        <Button
          type="submit"
          className="w-full"
          disabled={orderRequested}
          buttonText={confirmButtonLabel}
          ariaLabel={confirmButtonLabel}
          role="button"
          style={{ marginTop: '2rem', maxWidth: '100%', width: '100%' }}
        >
          {submitting ? confirmButtonLabelLoading : confirmButtonLabel}
        </Button>
      </DetailsInfo>
    </SectionDetails>
  );
}

ConfirmPointsSummary.propTypes = {
  checkoutSummaryLabel: PropTypes.string.isRequired,
  summaryOrderTotalLabel: PropTypes.string.isRequired,
  summaryPointsLabel: PropTypes.string.isRequired,
  summaryBalanceLabel: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  orderRequested: PropTypes.bool,
  pts: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  confirmButtonLabelLoading: PropTypes.string.isRequired,
};

export default ConfirmPointsSummary;
