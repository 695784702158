import PropTypes from 'prop-types';

import { Filter } from '../../common/Filters/FilterListStyles';
import { handleKeyDown } from '../../../helpers/utils';

/**
 * @component
 *
 * @prop {array} chips array of objects that has data related to the filter which are selected
 * @prop {function} handleFilterClicked callback handler from parent that will control the side effect of using the filter
 *
 */

export default function FilterChips({ handleFilterChipClick, chips }) {
  if (chips && chips?.length) {
    return (
      <ul className="flex flex-wrap list-none pl-0">
        {chips?.map(
          (chip, index) =>
            chip && (
              <Filter
                key={index}
                id={chip.code}
                data-type={chip.type}
                tabIndex="0"
                aria-label={chip.name}
                data-idx={chip.typeIdx}
                className="mx-3 my-2 selected"
                onClick={handleFilterChipClick}
                onKeyDown={(e) => handleKeyDown(e, handleFilterChipClick)}
                role="button"
                name={chip.name}
                filterChip
              >
                {chip.name}
              </Filter>
            )
        )}
      </ul>
    );
  }

  return null;
}

FilterChips.propTypes = {
  handleFilterChipClick: PropTypes.func.isRequired,
  chips: PropTypes.array,
};
