import PropTypes from 'prop-types';
import Accordion from '../../common/Accordion/Accordion';
import LinkComponent from '../../common/Links/LinkComponent';
import { StyledAccordionSection } from './FAQContentStyles';

export default function FAQSectionContent({ fields, customData, languageCode }) {
  const selectedCategories = fields?.fAQCategories_ValueField.includes(',')
    ? fields?.fAQCategories_ValueField.split(',')
    : [fields?.fAQCategories_ValueField];

  const faqSections = [];

  if (fields && customData?.faqData) {
    customData?.faqData.forEach((sec) => {
      if (selectedCategories.includes(sec.contentID.toString())) {
        faqSections.push(sec);
      }
    });
    return (
      <>
        {faqSections &&
          faqSections.map((section, index) => (
            <div key={`fs-${index}`}>
              {section.accordionList.length && (
                <StyledAccordionSection key={`s-${index}`}>
                  <h2 className="headline-small" id={fields.anchorID || 'faq'}>
                    {section.title}
                  </h2>
                  <Accordion accordionList={section.accordionList} page="FAQ" languageCode={languageCode} />
                </StyledAccordionSection>
              )}
            </div>
          ))}
        <LinkComponent
          href={fields.viewMoreButton.href}
          target={fields.viewMoreButton.target}
          style={{ fontSize: '1rem', maxWidth: 'inherit' }}
          buttonLoadingText={fields.viewMoreButton.text}
          buttonStyle="primary-button"
          ariaLabel={fields.viewMoreButton.text}
          className="mobile-w-full"
          locale={languageCode}
        >
          {fields.viewMoreButton.text}
        </LinkComponent>
      </>
    );
  }
  return null;
}

FAQSectionContent.propTypes = { fields: PropTypes.object, customData: PropTypes.object, languageCode: PropTypes.string };
