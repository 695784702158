import { useInfiniteQuery } from 'react-query';

import { getGiftCards } from '../../../pages/api/services';

export default function useGiftCards(languageCode) {
  const { isLoading, error, data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch } =
    useInfiniteQuery(
      ['getGiftCards', languageCode],
      async ({ pageParam = 1 }) => {
        const reqBody = {
          filters: {
            category: ['all'],
          },

          pagination: {
            page: pageParam,
            limit: 50,
          },
        };

        const res = await getGiftCards(reqBody, languageCode);
        const giftCardData = res.data.data;

        return giftCardData;
      },
      {
        getNextPageParam: (page) => (page && page?.itemsCount > 50 ? null : (page?.pageNumber || 0) + 1),
      }
    );

  return {
    isLoading,
    error,
    giftCards: data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
  };
}
