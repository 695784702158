import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

import TransactionError from '../../common/Errors/TransactionError';
import Button from '../../common/Button/Button';

// Helpers
import { formatDynamicLabel } from '../../../helpers/stringHelpers';
import { formatPoints, calcPointsNeeded, calcPointsTotal, amountSelected } from '../../../helpers/pointsHelpers';
import {
  handleGaEcommImpression,
  handleGAEvents,
  stringConcatHelper,
  getGiftCardOrderMapping,
} from '../../../helpers/handleGoogleAnalyticsHelper';
import { gaExternalSku } from '../../../helpers/giftCardsHelper';

function GiftCardRedeem({ customData }) {
  const { notEnoughPointsLabel, needMorePointsButtonLabel, continueButtonLabel } = customData;
  const {
    state: {
      points: { added },
      giftCards: { redeem, selectedGiftCardIndex },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const total = calcPointsTotal(added) * redeem.quantity;
  const pointsNeeded = calcPointsNeeded(added, balance, redeem.quantity);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;
  const gaLabel = stringConcatHelper(total, ' | Continue Click');
  const externalSku = gaExternalSku(added, redeem?.selectedFormat, redeem?.giftCardDetails);

  return (
    <div className="flex-1 w-full p-0">
      <Button
        type="submit"
        buttonText={continueButtonLabel}
        ariaLabel={continueButtonLabel}
        style={{ width: '100%', maxWidth: '100%' }}
        disabled={notEnoughPoints || !added.length || !redeem.selectedFormat}
        onClick={() => {
          handleGAEvents(gaLabel, 'Redeem', 'Continue Points For Credit Click', 'redeem_credit_continue_click');
          const amount = amountSelected(added);
          handleGaEcommImpression(
            'gc_add_to_cart',
            getGiftCardOrderMapping(
              total,
              redeem,
              amount,
              '',
              'reward_giftcard_add_to_cart',
              'Gift Card Add To Cart',
              selectedGiftCardIndex,
              amount,
              externalSku
            )
          );
        }}
      >
        {`${
          notEnoughPoints
            ? formatDynamicLabel(needMorePointsButtonLabel, '##POINTSVALUE##', formatPoints(pointsNeeded, locale))
            : continueButtonLabel
        }`}
      </Button>

      {notEnoughPoints && <TransactionError error={{ message: notEnoughPointsLabel }} simplified />}
    </div>
  );
}

GiftCardRedeem.propTypes = {
  customData: PropTypes.object,
};

export default GiftCardRedeem;
