import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { expandLinkedList } from '@agility/nextjs/utils';
import { useStateMachine } from 'little-state-machine';
import { Footer, SiteFooterWrapTop, FooterColumnSocial, FooterColumnWrapper } from '../FooterStyles';

import {
  FooterAppStore,
  FooterInfoLinks,
  FooterLogo,
  FooterLogoQuickLinks,
  FooterSecondaryBar,
  FooterSocial,
} from './FooterSections';

const SiteFooter = ({ globalData, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
      layout,
    },
  } = useStateMachine();

  const { footer } = globalData;
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  if (!layout?.hideFooter && layout?.hideFooter !== undefined) {
    return (
      <Footer className="relative w-full" tabIndex="0">
        <SiteFooterWrapTop>
          <div className="global-content-wrapper">
            <FooterLogo footer={footer} isMobile />
            <div className="md:flex md:justify-between">
              <div className="flex md:w-10/12">
                <FooterLogo footer={footer} />
                <FooterColumnWrapper isLoggedIn={isLoggedIn}>
                  <FooterLogoQuickLinks footer={footer} isLoggedIn={isLoggedIn} />

                  <FooterInfoLinks footer={footer} isLoggedIn={isLoggedIn} />
                </FooterColumnWrapper>
              </div>
              <FooterColumnSocial isLoggedIn={isLoggedIn} languageCode={languageCode}>
                <FooterSocial footer={footer} />

                <FooterAppStore footer={footer} isLoggedIn={isLoggedIn} />
              </FooterColumnSocial>
            </div>
          </div>
        </SiteFooterWrapTop>

        <FooterSecondaryBar footer={footer} />
      </Footer>
    );
  }
  return null;
};

SiteFooter.getCustomInitialProps = async function ({ agility, languageCode }) {
  // set up api
  const api = agility;

  // set up content item
  let contentItem = null;

  try {
    // try to fetch our site header

    const footer = await api.getContentList({
      referenceName: 'globalfooter',
      languageCode,
    });

    // if we have a header, set as content item
    if (footer && footer.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      contentItem = footer[0];

      if (contentItem.fields.quickLinks) {
        // resolve the links...
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'quickLinks',
          sortIDField: 'quickLinks_SortIdField',
        });
      }

      if (contentItem.fields.infoLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'infoLinks',
          sortIDField: 'infoLinks_SortIdField',
        });
      }

      if (contentItem.fields.partnerLinks) {
        // resolve the links...
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'partnerLinks',
          sortIDField: 'partnerLinks_SortIdField',
        });
      }

      if (contentItem.fields.footerLinks) {
        // resolve the links...
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'footerLinks',
          sortIDField: 'footerLinks_SortIdField',
        });
      }

      if (contentItem.fields.mediaLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'mediaLinks',
          sortIDField: 'mediaLinks_SortIdField',
        });
      }

      if (contentItem.fields.anonymousLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'anonymousLinks',
          sortIDField: 'anonymousLinks',
        });
      }
      // else return null
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load site header item.', error);
    return null;
  }

  // return clean object...
  return {
    fields: contentItem && contentItem?.fields,
  };
};

SiteFooter.propTypes = {
  globalData: PropTypes.object,
  languageCode: PropTypes.string,
};
export default SiteFooter;
