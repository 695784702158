import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import FocusTrap from 'focus-trap-react';

import { b2cChangeEmail } from '../../../../b2c/b2cFunctions';
import { convertToSlug } from '../../../../helpers/utils';

import { Row, Title, FieldContent, DescriptionText } from '../../FormStyles';

// Components
import EditButton from '../../EditButton';
import EditMSALButtons from './EditMSALButtons';
import Loader from '../../../common/Loader/Loader';

import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProfileEmail(props) {
  const { fieldName, emailMessage, emailLabel, cancelLabel, loading, languageCode, customerData } = props;

  const [isEditing, setEditing] = useState(false);
  const { instance } = useMsal();
  /* const currentAccounts = instance.getAllAccounts();
  const profileEmail = currentAccounts[0]?.idTokenClaims.extension_SCENE_Email; */

  const handleEditClick = () => {
    if (!isEditing) handleGAEvents('Profile - Edit Email', 'Account', 'Change Email Click', 'profile_email_click');
    setEditing(!isEditing);
  };

  const handleB2Ccall = () => {
    b2cChangeEmail(instance, languageCode);
  };

  return (
    <Row className="mb-0.5 relative">
      <FocusTrap
        active={isEditing}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none',
          },
          initialFocus: false,
          allowOutsideClick: true,
        }}
      >
        <div className="relative p-0 ">
          <div className="md:flex justify-between items-baseline content-center relative">
            <div className="w-52 ProfileFieldWrapper">
              <Title className="mb-1 md:mb-0 font-bold mr-4 flex-initial">{fieldName}</Title>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div id={`profile-${convertToSlug(fieldName)}`} className="w-full">
                  <FieldContent className="md:p-1">{customerData && <p className="mb-0">{customerData}</p>}</FieldContent>
                  {isEditing && (
                    <div>
                      {emailMessage && <DescriptionText className="md:px-2 my-4">{emailMessage}</DescriptionText>}

                      <EditMSALButtons
                        handleB2Ccall={handleB2Ccall}
                        buttonText={emailLabel}
                        cancelLabel={cancelLabel}
                        isEditing={isEditing}
                        updateEditClick={handleEditClick}
                      />
                    </div>
                  )}
                </div>

                <EditButton {...props} handleEditClick={handleEditClick} fieldName={fieldName} isEditing={isEditing} />
              </>
            )}
          </div>
        </div>
      </FocusTrap>
    </Row>
  );
}
