import { useState, useEffect } from 'react';
import Select from 'react-select';

import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

import { SortDropdownWrapper } from './SortDropdownStyles';
import { selectStyles } from '../../forms/fields/selectStyles';

export default function SortDropdown({ name, label, options, page }) {
  const {
    actions,
    state: {
      offers: { sortBy },
    },
  } = useStateMachine({ updateOffers });

  const [selectedLabel, setSelectedLabel] = useState(options && options[0]);

  useEffect(() => {
    if (sortBy) {
      setSelectedLabel({
        value: sortBy?.code,
        label: sortBy?.name,
      });
    }
  }, [sortBy]);

  // eslint-disable-next-line no-shadow
  const handleChange = ({ value, label }) => {
    if (value !== selectedLabel.value) {
      setSelectedLabel({
        value,
        label,
      });

      // set the sortBy globally on change here
      if (sortBy) {
        actions.updateOffers({
          sortBy: {
            value,
            label,
          },
        });
      }
    }
  };

  return (
    <SortDropdownWrapper page={page}>
      {Boolean(label) && (
        <label id={`dropdown-${name}`} htmlFor={name}>
          {label}
        </label>
      )}

      <Select
        aria-required
        aria-labelledby={`dropdown-${name}`}
        classNamePrefix="offers-sortby"
        isSearchable={false}
        options={options}
        value={sortBy}
        onChange={handleChange}
        styles={selectStyles}
      />
    </SortDropdownWrapper>
  );
}
