/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

import { Content } from './TabStyles';

export default function TabContent({ tabs, active, isLarge }) {
  if (tabs) {
    return tabs.map((tab, index) => (
      <Content
        isLarge={isLarge}
        key={index}
        id={`tabpanel-${index}`}
        active={active === index}
        role="tabpanel"
        aria-labelledby={`tab-${index}`}
      >
        <div dangerouslySetInnerHTML={renderHTML(tab.tabContent)} />
      </Content>
    ));
  }
  return null;
}

TabContent.propTypes = {
  tabs: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  isLarge: PropTypes.bool,
};
