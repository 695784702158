import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import ContentGrid4Columns from '../common/List/ContentGrid4Columns';
import LinkComponent from '../common/Links/LinkComponent';

import { PageTitle, PageSubtitle } from '../common/List/ContentGridStyles';

const GridSection4Columns = ({ module, customData, languageCode }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <section>
        <WrapperLayout className="global-content-wrapper" customStyles="gridSection">
          {fields.title && <PageTitle>{fields.title}</PageTitle>}
          {fields.message && <PageSubtitle>{fields.message}</PageSubtitle>}

          <ContentGrid4Columns data={customData?.sectionContent?.grid} />
          {fields.cTALink && fields.cTALabel && (
            <LinkComponent
              href={fields.cTALink?.href}
              target={fields.cTALink.target}
              style={{ fontSize: '1rem', maxWidth: 'inherit', minWidth: '18rem', margin: 'auto' }}
              buttonLoadingText={fields.cTALabelLoading || fields.cTALabel}
              buttonStyle="primary-button"
              ariaLabel={fields.cTALink?.text}
              className="mobile-w-full text-center"
              locale={languageCode}
            >
              {fields.cTALabel}
            </LinkComponent>
          )}
        </WrapperLayout>
      </section>
    );
  }
  return null;
};
GridSection4Columns.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;

  try {
    const contentItemList = await api.getContentList({
      referenceName: 'rewardyourselfnoc',
      languageCode,
    });

    let contentItem = contentItemList && contentItemList[0];
    if (contentItem.fields.grid) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'grid',
        sortIDField: 'grid_SortIdField',
      });
    }

    return {
      sectionContent: contentItem.fields,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
export default GridSection4Columns;
