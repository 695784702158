import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components

import HeroBanner from '../common/Banners/HeroBanner';

const SharedHeroBanner = ({ module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return <HeroBanner fields={fields} />;
  }

  return null;
};

export default SharedHeroBanner;

SharedHeroBanner.propTypes = {
  module: PropTypes.object.isRequired,
};
