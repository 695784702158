// Components
import FullWidthWrapperLayout from '../../layouts/FullWidthWrapperLayout';
import GreetingsBalanceTicker from '../Greetings/GreetingsBalanceTicker';
import GreetingTitle from '../Greetings/GreetingTitle';
import Loader from '../Loader/Loader';

// Styles
import { GreetingsGrid } from '../Greetings/GreetingStyles';

export default function HeroBannerPoints({ customData, customer, balance }) {
  const { ptsLabel } = customData;

  return (
    <FullWidthWrapperLayout isBlack hasPatterns bottomNoSpacing>
      {!customer ? (
        <Loader reverse />
      ) : (
        <GreetingsGrid role="banner">
          <GreetingTitle customData={customData} customer={customer} />

          <GreetingsBalanceTicker balance={balance} ptsLabel={ptsLabel} />
        </GreetingsGrid>
      )}
    </FullWidthWrapperLayout>
  );
}
