import styled from 'styled-components';

export const AccordionStyle = styled.div`
  background-color: white;
  border-radius: 0;
  padding-left: 0;
  &:last-of-type {
    margin-bottom: 3.75rem;
  }
  @media screen and (max-width: 640px) {
    &:last-of-type {
      margin-bottom: 2.5rem;
    }
  }
  em {
    text-style: italic;
  }
  .accordion-legal p {
    font-size: ${(props) => props.theme.fontLegal};
  }
  table {
    margin: 2rem 0;
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.uiBlack};
    thead th,
    thead td {
      color: ${(props) => props.theme.uiWhite};
      background-color: ${(props) => props.theme.uiBlack};

      padding: 1rem 1.5rem;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    }

    td {
      padding: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      @media screen and (max-width: 640px) {
        padding: 0.5rem;
      }
    }
  }

  ul li,
  ol li {
    margin: 0 0 1rem 0;
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, '.') '. ';
  }

  p,
  div {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontLegal};
    }
  }
  .grayed-out-p p {
    color: ${(props) => props.theme.lightGrayColor4};
  }

  .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .accordion__button {
    padding: 2rem 0;

    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 640px) {
      padding: 1.5rem 0;
    }

    &:focus {
      outline: 2px solid ${(props) => props.theme.uiPurple};
    }
  }

  .accordion__button img {
    padding: 0 1rem;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .accordion__button:after {
    display: inline-block;
    content: '';
    height: 0.625rem;
    width: 0.625rem;
    margin-right: 0.75rem;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    transform: rotate(-135deg);
  }

  h2,
  h3.faq {
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.headingExtraSmall};
    max-width: 85%;
    margin: 0;
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
  .subtitle {
    font-weight: normal;
    margin-top: 0.5rem;
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
  p.a-label {
    display: inline-block;
  }
  .text-indent {
    margin-left: 1.5rem;
  }

  h3 {
    color: ${(props) => props.theme.uiPurple};
    margin: 1.5rem 0;
    font-weight: bold;
    font-size: ${(props) => props.theme.fontBody2};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
  .primary-button {
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 0;
    border: 0;
    padding: 1rem 2rem;
    text-decoration: none;
    overflow: hidden;

    color: ${(props) => props.theme.whiteColor};
    font-weight: bold;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiDarkPurple};
    }

    &:focus {
      border: 3px solid ${(props) => props.theme.uiPurple};
      background-color: ${(props) => props.theme.uiDarkPurple};
    }
  }
`;
