import PropTypes from 'prop-types';
import { useMemo } from 'react';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import { OfferActions, MoreOffers, FeaturedCards } from '../Sections';
import OffersList from '../OffersList';
import NoOffers from '../NoOffers';
// Hooks
import useSortedOffersList from '../../hooks/offers/useSortedOffersList';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';
import { toTitleCase } from '../../../helpers/stringHelpers';
import { deriveStaticBanner } from '../../../helpers/offersHelpers';

function GeneralOffersTab({ customData, languageCode, offerOptions, featuredCards }) {
  const {
    state: {
      offers: { offersList, moreOffersList, sortBy, selectedFilters },
    },
  } = useStateMachine();

  const staticBanner = useMemo(
    () => deriveStaticBanner(customData.bannerList, selectedFilters),
    [customData.bannerList, selectedFilters]
  );

  const { moreOffersLabel, moreOffersMessage, loadOfferLabel, offerLoadedLabel } = customData.pageContent;

  const sortedList = useSortedOffersList(offersList, sortBy);

  if (checkArrayForItems(sortedList) && checkArrayForItems(moreOffersList)) {
    return (
      <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
        <WrapperLayout isGray customStyles="offersGeneralOffers">
          {offerOptions && (
            <WrapperLayout className="global-content-wrapper">
              <OfferActions
                customData={customData}
                offerOptions={offerOptions}
                showFilter={selectedFilters === 'grocery'}
                page={selectedFilters}
                disable={!sortedList?.length}
                staticBanner={staticBanner}
              />
              <OffersList
                customData={customData.pageContent}
                languageCode={languageCode}
                filteredList={sortedList}
                loadOfferLabel={loadOfferLabel}
                offerLoadedLabel={offerLoadedLabel}
                listName={`${toTitleCase(selectedFilters)} offers tab`}
              />
            </WrapperLayout>
          )}
        </WrapperLayout>
        <WrapperLayout className="global-content-wrapper">
          <FeaturedCards
            featuredCards={featuredCards}
            featuredCardsList={customData.featuredCardsList}
            selectedFilters={selectedFilters}
          />
        </WrapperLayout>
        <WrapperLayout isWhite mobileIsWhite customStyles="offersGeneralMoreOffers">
          <MoreOffers
            title={moreOffersLabel}
            filteredList={moreOffersList}
            message={moreOffersMessage}
            isWhite
            customData={customData.pageContent}
          />
        </WrapperLayout>
      </div>
    );
  }

  if (checkArrayForItems(sortedList) || checkArrayForItems(moreOffersList)) {
    return (
      <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
        <WrapperLayout
          className={`${checkArrayForItems(sortedList) || checkArrayForItems(moreOffersList) ? 'min-half-screen' : ''}`}
          isGray
          customStyles="offersGeneralOffers"
        >
          <WrapperLayout className="global-content-wrapper">
            <OfferActions
              customData={customData}
              offerOptions={offerOptions}
              showFilter={selectedFilters === 'grocery'}
              page={selectedFilters}
              disable={!sortedList?.length}
              staticBanner={staticBanner}
              hideOfferActions={!sortedList?.length}
            />
            {checkArrayForItems(sortedList) && offerOptions && (
              <OffersList
                customData={customData.pageContent}
                languageCode={languageCode}
                filteredList={sortedList}
                loadOfferLabel={loadOfferLabel}
                offerLoadedLabel={offerLoadedLabel}
                listName={`${toTitleCase(selectedFilters)} offers tab`}
              />
            )}
          </WrapperLayout>
          <WrapperLayout className="global-content-wrapper">
            <FeaturedCards
              featuredCards={featuredCards}
              featuredCardsList={customData.featuredCardsList}
              selectedFilters={selectedFilters}
            />
          </WrapperLayout>
          {checkArrayForItems(moreOffersList) && (
            <MoreOffers
              title={moreOffersLabel}
              filteredList={moreOffersList}
              message={moreOffersMessage}
              customData={customData.pageContent}
              isGray
            />
          )}
        </WrapperLayout>
      </div>
    );
  }
  return (
    <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
      <WrapperLayout isGray customStyles="offersGeneralOffers" className="min-half-screen">
        <WrapperLayout className="global-content-wrapper">
          <OfferActions
            customData={customData}
            offerOptions={offerOptions}
            showFilter={selectedFilters === 'grocery'}
            page={selectedFilters}
            disable={!sortedList?.length}
            staticBanner={staticBanner}
          />
          <FeaturedCards
            featuredCards={featuredCards}
            featuredCardsList={customData.featuredCardsList}
            selectedFilters={selectedFilters}
          />
          <NoOffers customData={customData} />
        </WrapperLayout>
      </WrapperLayout>
    </div>
  );
}

GeneralOffersTab.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  offerOptions: PropTypes.array,
};

export default GeneralOffersTab;
