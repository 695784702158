/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../store/actions';
import updateStepAction from '../../store/actions/updateStepAction';
import ExternalImage from '../common/Images/ExternalImage';
// Service
import { validateGKActivation } from '../../pages/api/services';

// Components
import FormFooter from '../forms/registration-gk/FormFooter';
import FormHeader from '../forms/registration-gk/FormHeader';

// Hooks
import useStorage from '../hooks/navigation/useStorage';

// Helpers
import { generateErrorMessage } from '../../helpers/errorsHelper';
import { handleGAEventsBase } from '../../helpers/handleGoogleAnalyticsHelper';

export default function RegisterStepGKActivationFormLayout({ children, ...props }) {
  const { gKActivateImagePreview, stepLabel, gKTitle, gKBackLabel, nextLabel, nextLabelLoading, errorsList, languageCode } =
    props;
  const [submitting, setSubmitting] = useState(false);
  const { getItem } = useStorage();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const { actions } = useStateMachine({
    updateUser,
    updateStepAction,
    updateNotification,
  });

  // useRegisterGAFormEvents(formMethods.formState?.touchedFields, 'Step 1a GK');

  const handleValidActivationResponse = async (res, formData) => {
    if (res.data) {
      actions.updateUser({ ...formData, ...{ step: 3 } });
      actions.updateNotification({
        message: '',
        status: '',
      });
      window.scrollTo(0, 0);
      // handleGAEvents('Next', 'Registration Events', 'Step 1', 'gk_activation_button_next');
      const dataLayer = {
        event: 'sign_up_ghost_kit_pin_next',
        category: 'Registration Events',
        action: 'Step 1a GK',
        label: 'Next',
        sign_up_step: 'Step 1a GK',
      };
      handleGAEventsBase(dataLayer);
    }

    setSubmitting(false);
  };

  const handleErrorResponse = (error) => {
    if (error.response !== null && error.response.data !== null) {
      const validationError =
        error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();

      if (validationError !== null && validationError.includes('GhostKit.Account.VerificationFailed')) {
        actions.updateNotification({
          message: generateErrorMessage(['GhostKit.Account.VerificationFailed'], errorsList),
          status: 'error',
        });
      } else {
        actions.updateNotification({
          message: generateErrorMessage(
            error?.response?.data?.validationErrors ? Object.keys(error.response.data.validationErrors) : ['General.BadRequest'],
            errorsList
          ),
          status: 'error',
        });
      }
      const dataLayer = {
        event: 'sign_up_error_GK_validation',
        category: 'Registration Events',
        action: 'Error',
        label: validationError || 'NotAvailable',
        error_type: validationError || 'General.ValidationErrors.NotAvailable',
      };
      handleGAEventsBase(dataLayer);
    }

    setSubmitting(false);
  };

  const validateForm = (reqBody, formData) => {
    const session = getItem('session');
    // eslint-disable-next-line prettier/prettier
    validateGKActivation(reqBody, { session })
      .then((res) => handleValidActivationResponse(res, formData))
      .catch(handleErrorResponse);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    const reqBody = {
      cardNumber: data.sceneNumber,
      activationCode: data.activationCode,
    };

    validateForm(reqBody, data);
  };

  const onErrors = (errors) => {
    // eslint-disable-next-line no-console
    console.error(errors);
  };

  const currentStep = 1;

  const totalSteps = 3;

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="px-4 sm:py-12 mb-10 md:mb-4 sm:px-14">
          <FormHeader stepLabel={stepLabel} currentStep={currentStep} totalSteps={totalSteps} title={gKTitle} />
          <div className="mt-0 mb-8 m-auto md:h-64 h-48 relative overflow-hidden">
            <ExternalImage url={gKActivateImagePreview.url} fill className="object-contain object-center" />
          </div>

          {children}
        </div>

        <FormFooter
          buttonText={nextLabel}
          buttonLoadingText={nextLabelLoading}
          linkText={gKBackLabel}
          submitting={submitting}
          languageCode={languageCode}
        />
      </form>
    </FormProvider>
  );
}
RegisterStepGKActivationFormLayout.propTypes = {
  gKActivateImagePreview: PropTypes.object,
  stepLabel: PropTypes.string,
  gKTitle: PropTypes.string,
  gKBackLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  nextLabelLoading: PropTypes.string,
  errorsList: PropTypes.array,
  languageCode: PropTypes.string,
  children: PropTypes.array,
};
