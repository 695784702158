import { NextArrow, PrevArrow } from '../components/common/Banners/CarouselArrows';

export function sliderSettings(numSlides, numSlidesForDesktop, numSlidesForTablet, numSlidesForMobile) {
  const settings = {
    className: `slides ${numSlides <= 3 && numSlidesForDesktop === 3.5 ? 'flex-slides' : ''} ${
      numSlides <= 2 && numSlidesForTablet === 2.5 ? 'flex-slides-tablet' : ''
    }`,
    infinite: false,
    dots: false,
    arrows: true,
    slidesToShow: numSlides > numSlidesForDesktop ? numSlidesForDesktop : numSlides,
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 99999,
        settings:
          numSlides <= 3
            ? 'unslick'
            : {
                dots: false,
              },
      },
      {
        breakpoint: 991,
        settings:
          numSlides <= 2
            ? 'unslick'
            : {
                slidesToShow: numSlidesForTablet || 1,
                arrows: false,
              },
      },
      {
        breakpoint: 520,
        settings:
          numSlides <= 2
            ? 'unslick'
            : {
                slidesToShow: numSlidesForMobile || 1,
                arrows: false,
              },
      },
    ],
  };

  return settings;
}
// use for feature banner and spotlight
export function bannerSettings(banners = [], maximumSlides) {
  const bannersNum = banners.length;
  const settings = {
    className: 'slides',
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: bannersNum > 3 ? 1 : bannersNum || maximumSlides,
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 500,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 641,
        settings: {
          centerMode: bannersNum > 2,
          slidesToShow: bannersNum > 2 ? 1 : bannersNum,
        },
      },
      {
        breakpoint: 640,
        settings:
          bannersNum === 2
            ? 'unslick'
            : {
                centerMode: bannersNum > 1,
                slidesToShow: 1,
              },
      },
    ],
  };

  return settings;
}

export function giftcardSliderSettings(numSlides) {
  const settings = {
    className: 'cards-slides',
    infinite: false,
    dots: false,
    arrows: true,
    variableWidth: true,
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 99999,
        settings:
          numSlides <= 5
            ? 'unslick'
            : {
                arrows: true,
              },
      },
      {
        breakpoint: 1200,
        settings:
          numSlides <= 4
            ? 'unslick'
            : {
                arrows: true,
              },
      },
      {
        breakpoint: 991,
        settings:
          numSlides <= 2
            ? 'unslick'
            : {
                arrows: false,
              },
      },
    ],
  };

  return settings;
}

export function tabSelectorSliderSettings() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    cssEase: 'linear',
    className: 'tabs-slider',
    variableWidth: true,
    adaptiveHeight: true,
    swipeToSlide: true,
    slidesToScroll: 1,
  };

  return settings;
}

export function cardSliderSettings(numSlides) {
  const settings = {
    className: 'cards-slides',
    infinite: false,
    dots: false,
    arrows: true,
    variableWidth: true,
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 99999,
        settings:
          numSlides <= 3
            ? 'unslick'
            : {
                arrows: true,
              },
      },
      {
        breakpoint: 991,
        settings:
          numSlides <= 2
            ? 'unslick'
            : {
                arrows: false,
              },
      },
    ],
  };

  return settings;
}

export function getGASlot(zones, contentID) {
  if (zones?.MainContentZone.length && contentID) {
    const index = zones.MainContentZone.findIndex((zone) => zone.item.contentID === contentID);

    return index;
  }
  return 'N/A';
}
