/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

export default function useUserReq(req) {
  const {
    state: { user },
    actions,
  } = useStateMachine({ updateUser });

  useEffect(() => {
    if (req) {
      actions.updateUser({ req });
    }
  }, []);

  useEffect(() => {
    if (req && req?.ip !== user.req?.ip) {
      actions.updateUser({ req });
    }
  }, [req, user.req?.ip]);
}
