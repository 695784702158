import { useEffect, useState } from 'react';
import Select from 'react-select';

import { useStateMachine } from 'little-state-machine';
import PropTypes from 'prop-types';
import { updateUser, updateNotification } from '../../../../store/actions';

import { updateAddress } from '../../../../pages/api/services';

// Helpers
import { generateErrorMessage } from '../../../../helpers/errorsHelper';
import ShippingAddressItem from './ShippingAddressItem';
import { shippingSelectStyles } from './shippingSelectStyles';

export default function ShippingAddressSelect({ shippingList, errorsList, profileContent, setRefetchAddressList }) {
  const {
    actions,
    state: {
      user: { selectedShippingAddress },
      session: { accessToken },
    },
  } = useStateMachine({ updateNotification, updateUser });
  const primary = shippingList.filter((option) => option.primary === true)[0];
  // Setting the default that filters for what is primary key only
  const shippingDefaultOption = {
    primary: primary.uniqueId,
    label: <ShippingAddressItem address={primary} errorsList={errorsList} profileContent={profileContent} />,
    addressData: primary,
  };

  // Making sure to filter out what is selected and not show in the list
  const shippingOptions =
    shippingList?.length &&
    shippingList
      .filter((option) => option.primary === false)
      .map((addressItem) => ({
        value: addressItem.uniqueId,
        label: <ShippingAddressItem address={addressItem} errorsList={errorsList} profileContent={profileContent} />,
        addressData: addressItem,
      }));

  const [selectedLabel, setSelectedLabel] = useState(shippingDefaultOption);

  useEffect(() => {
    if (!selectedShippingAddress?.addressData || selectedShippingAddress?.addressData?.uniqueId !== primary.uniqueId) {
      actions.updateUser({
        selectedShippingAddress: {
          addressData: primary,
        },
      });
    }
  }, [primary]);

  useEffect(() => {
    if (shippingList?.length) {
      if (selectedShippingAddress?.addressData) {
        setSelectedLabel({
          value: selectedShippingAddress?.addressData?.uniqueId,
          label: (
            <ShippingAddressItem
              address={selectedShippingAddress?.addressData}
              errorsList={errorsList}
              profileContent={profileContent}
            />
          ),
          addressData: selectedShippingAddress?.addressData,
        });
      }
    }
  }, [selectedShippingAddress]);

  const handleChange = ({ value, label, addressData }) => {
    if (value !== selectedLabel.value) {
      setSelectedLabel({
        value,
        label,
        addressData,
      });

      // when user switches, we pass data to api
      if (addressData) {
        const reqBody = {
          countryCode: addressData?.countryCode,
          provinceCode: addressData?.provinceCode,
          city: addressData?.city,
          postalCode: addressData?.postalCode.split(/\s/).join(''),
          addressLine1: addressData?.addressLine1,
          addressLine2: addressData?.addressLine2,
          firstName: addressData?.firstName,
          lastName: addressData?.lastName,
          primaryPhoneNumber: addressData?.primaryPhoneNumber.replace(/-/g, ''),
          primary: true,
          uniqueId: addressData?.uniqueId,
        };

        actions.updateUser({
          selectedShippingAddress: {
            addressData: reqBody,
          },
        });

        updateAddress(reqBody, accessToken)
          .then(async (res) => {
            if (res.data) {
              setRefetchAddressList(true);
            }
          })
          .catch((error) => {
            if (error.response !== null && error.response.data !== null) {
              actions.updateNotification({
                message: generateErrorMessage(
                  error?.response?.data?.validationErrors
                    ? Object.keys(error.response.data.validationErrors)
                    : ['General.BadRequest'],
                  errorsList
                ),
                status: 'error',
              });
            }
            console.error(error);
          });
      }
    }
  };
  if (shippingList?.length && selectedLabel?.value) {
    return (
      <div>
        <Select
          name="shipping-address-dropdown"
          aria-required
          isSearchable={false}
          options={shippingOptions}
          value={selectedLabel}
          onChange={handleChange}
          styles={shippingSelectStyles}
        />
      </div>
    );
  }
  return null;
}

ShippingAddressSelect.propTypes = {
  shippingList: PropTypes.array,
  setRefetchAddressList: PropTypes.func,
  errorsList: PropTypes.array,
  profileContent: PropTypes.object,
};
