/**
 *
 * @param {*} state existing state
 * @param {*object} payload { k:v }
 * @returns
 */
export default function updateUserCustomerAction(state, payload) {
  return {
    ...state,
    user: {
      ...state.user,
      customer: { ...state.customer, ...payload },
    },
  };
}
