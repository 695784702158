import { DetailsSection, DetailsTitle } from '../DetailsStyles';

export default function DetailsShippingMethod({ selectedOrder, shippingMethod }) {
  const hasShipMethod =
    selectedOrder && selectedOrder?.items && selectedOrder?.items.find((ship) => ship?.shippingInfo !== ' - ');

  if (hasShipMethod && shippingMethod) {
    return (
      <DetailsSection>
        {shippingMethod && <DetailsTitle className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{shippingMethod}</DetailsTitle>}

        <p>{hasShipMethod.shippingInfo}</p>
      </DetailsSection>
    );
  }
  return null;
}
