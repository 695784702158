import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../../../Images/ExternalImage';

export default function FooterSocial({ footer }) {
  return (
    <div className="social-links">
      {footer.fields.mediaLinks &&
        footer.fields.mediaLinks.map((navitem, index) => (
          <Link
            key={`m-${index}`}
            href={navitem.fields.page.href}
            as={navitem.fields.page.href}
            target={navitem.fields.page.target}
            aria-label={navitem.fields.image.label}
          >
            {navitem.fields.image && (
              <ExternalImage url={navitem.fields.image.url} title={navitem.fields.image.label} width={20} height={20} />
            )}
          </Link>
        ))}
    </div>
  );
}

FooterSocial.propTypes = { footer: PropTypes.object };
