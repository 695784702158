import { useCallback } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useStorage from '../navigation/useStorage';

const useReCaptcha = (action) => {
  const isDebugMode = `${process.env.NEXT_PUBLIC_DEBUG_MODE}` === 'true';
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    state: { user },
  } = useStateMachine();
  const { getItem } = useStorage();

  // Create an event handler so you can call the verification on button click event or form submit
  const getCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Execute recaptcha not yet available');
      return;
    }

    // Make call to reCaptcha API And return the response
    try {
      const session = getItem('session');
      const token = await executeRecaptcha(action);

      return { token, ip: user?.req?.ip, session };
    } catch (error) {
      if (isDebugMode) {
        // eslint-disable-next-line no-console
        console.error(`Captcha Verify failed`, error);
      }
      // fail the captcha challenge if there is error in verify api
      return false;
    }
  }, [executeRecaptcha, action]);

  return { getCaptchaToken };
};

export default useReCaptcha;
