/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const TitleHeading = styled.h1`
  font-size: ${(props) => props.theme.headingLarge};
  line-height: ${(props) => props.theme.lineHeight2};

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    font-size: ${(props) => props.theme.headingMedium};
  }

  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingMedSmall};

    ${(props) =>
      props.customStyles === 'profilePage' &&
      css`
        margin-left: 1.125rem;
      `};

    ${(props) =>
      props.customStyles === 'myCardPage' &&
      css`
        font-size: ${(props) => props.theme.headingMedium};
      `};
  }

  ${(props) =>
    props.customStyles === 'confirmPage' &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      @media screen and (max-width: 1023px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      @media screen and (max-width: 640px) {
        font-size: ${(props) => props.theme.headingExtraSmall};
      }
    `};

  ${(props) =>
    props.customStyles === 'confirmedRedeemed' &&
    css`
      font-size: ${(props) => props.theme.headingMedium};
    `};
`;

export const TitleWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 1023px) {
    padding-top: 1rem;
    padding-bottom: 1rem;

    ${(props) =>
      props.mb1rem &&
      css`
        margin-bottom: 1rem;
      `}
  }

  &#page-header-rewards {
    padding-top: 5rem;
    padding-bottom: 1rem;
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      padding-top: 2.5rem;
      padding-bottom: 1rem;
    }
    @media screen and (max-width: 640px) {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
  }

  &#page-header-travel {
    padding-bottom: 0;
  }
  &#page-header-offers {
    padding-top: 5rem;
    padding-bottom: 3.75rem;
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    @media screen and (max-width: 640px) {
      padding-top: 2rem;
      padding-bottom: 1.5rem;
    }
  }
`;
export const ImageCircle = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 2rem;
  background-color: ${(props) => props.theme.uiWhite};
  border: 1px solid ${(props) => props.theme.uiGrey02};
  @media screen and (max-width: 640px) {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 1.5rem;
  }
`;
