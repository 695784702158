import DetailsTotal from './DetailsTotal';
import DetailsShippingMethod from './DetailsShippingMethod';
import DetailsShippingAddress from './DetailsShippingAddress';
import DetailsAccount from './DetailsAccount';
import DetailsEmail from './DetailsEmail';
import DetailsOrderDate from './DetailsOrderDate';
import DetailsOrderNumber from './DetailsOrderNumber';

export {
  DetailsTotal,
  DetailsShippingMethod,
  DetailsShippingAddress,
  DetailsAccount,
  DetailsEmail,
  DetailsOrderDate,
  DetailsOrderNumber,
};
