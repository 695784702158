import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
// Components
import LogoDescriptionSection from '../common/Sections/LogoDescriptionSection';

const AboutSceneLogoDescriptionSection = ({ module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return <LogoDescriptionSection fields={fields} />;
  }

  return null;
};

AboutSceneLogoDescriptionSection.propTypes = {
  module: PropTypes.object.isRequired,
};
export default AboutSceneLogoDescriptionSection;
