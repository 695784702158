import Title from '../common/Headings/Title';

import useHasMount from '../hooks/utils/useHasMount';
import { FullWidthWrapper, FullWidthInnerWrapper } from './FullWidthWrapperLayoutStyles';

export default function FullWidthWrapperLayout({
  children,
  title,
  className,
  isLightBlack,
  isGray,
  isBlack,
  isWhite,
  hasPatterns,
  bottomNoSpacing,
  noPadding,
}) {
  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  if (children) {
    return (
      <FullWidthWrapper
        className={`w-full ${noPadding ? '' : 'pt-12 sm:pb-8'} ${bottomNoSpacing ? '' : 'mb-8'} sm:mb-0 overflow-hidden${
          isGray ? ' isGray' : ''
        } ${isBlack ? ' isBlack' : ''} ${isWhite ? ' isWhite' : ''} ${isLightBlack ? ' isLightBlack' : ''}`}
        hasPatterns={hasPatterns}
      >
        <FullWidthInnerWrapper className={`global-content-wrapper ${className || ''}`} hasPatterns={hasPatterns}>
          {title && <Title>{title}</Title>}

          {children}
        </FullWidthInnerWrapper>
      </FullWidthWrapper>
    );
  }

  return null;
}
