import PropTypes from 'prop-types';
import Image from 'next/image';

// Components
import ButtonIcon from '../common/Button/ButtonIcon';

import { OnboardingHeaderStyles, OnboardingTitle } from './OnboardingStyles';

export default function OnboardingHeader({ title, closeLabel }) {
  return (
    <OnboardingHeaderStyles>
      {title && <OnboardingTitle id="onboarding-title">{title}</OnboardingTitle>}

      <ButtonIcon title={closeLabel} className="cursor-pointer font-bold" ariaLabel={closeLabel}>
        <Image src="/assets/close-x.svg" title={closeLabel} alt="" height={15} width={15} role="presentation" />
      </ButtonIcon>
    </OnboardingHeaderStyles>
  );
}

OnboardingHeader.propTypes = {
  title: PropTypes.string,
  closeLabel: PropTypes.string,
};
