import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { SidebarWrapper } from './SidebarStyles';

import { handleGAEvents } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function Sidebar({ sidebarLinks }) {
  const router = useRouter();
  const currentUrl = router.asPath;

  const handleMenuItemClick = (menuItem, routerHref) => {
    router.push(routerHref);
    handleGAEvents(menuItem, 'Account', 'Account Nav Item Click', 'account_menu_click');
  };

  return (
    <SidebarWrapper>
      {sidebarLinks &&
        sidebarLinks.map((item) => (
          <button
            key={item.contentID}
            className={`${currentUrl.indexOf(item.fields.page.href) > -1 ? 'active-nav' : ''}`}
            type="button"
            aria-label={item.fields.title}
            onClick={() => {
              handleMenuItemClick(item.fields.title, item.fields.page.href);
            }}
          >
            {item.fields.title}
          </button>
        ))}
    </SidebarWrapper>
  );
}

Sidebar.propTypes = { sidebarLinks: PropTypes.array };
