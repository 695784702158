import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { useStateMachine } from 'little-state-machine';
import frenchLocale from './DatePickerLocales/frenchLocale';

// Global state
import { updatePoints } from '../../../../../store/actions';

// Components
import DatePickerNavigation from '../../../../common/Button/DatePickerNavigation';
import DatePickerDay from './DatePickerDay';

// Helpers
import { formatDatepickerDate } from '../../../../../helpers/dateHelpers';

// Styles
import { DatePickerDateSelectedStyle } from './DatePickerStyles';
import DatePickerInput from './DatePickerInput';

export default function DatePickerFieldTo({
  name,
  label,
  placeholder,
  applyFilter,
  resetFilter,
  type,
  ariaLabelNextMonth,
  ariaLabelPreviousMonth,
  setShouldCloseFromCalendar,
  shouldCloseToCalendar,
  shouldCloseFromCalendar,
}) {
  // Global State
  const {
    actions,
    state: {
      language: { locale },
      points: { selectedFromDate, fromDate, selectedToDate },
    },
  } = useStateMachine({ updatePoints });

  const [value, setValue] = useState('');
  const datePickerValue = formatDatepickerDate(`${value?.year}-${value?.month?.number}-${value?.day}`, 'YYYY-MM-DD');
  const ref = useRef();

  const handleChange = (dateValue) => {
    setValue(dateValue);

    if (dateValue !== 'Invalid date') {
      actions.updatePoints({
        selectedToDate: formatDatepickerDate(`${dateValue?.year}-${dateValue?.month?.number}-${dateValue?.day}`, 'YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    if (applyFilter && datePickerValue !== 'Invalid date') {
      actions.updatePoints({
        [type]: datePickerValue,
      });
    }

    if (selectedToDate && !applyFilter) {
      setValue(selectedToDate);
    }

    if (resetFilter) {
      setValue('');
    }
  }, [resetFilter, applyFilter]);

  useEffect(() => {
    if (shouldCloseToCalendar && ref.current) {
      ref.current.closeCalendar();
    }
    setShouldCloseFromCalendar(false);
  }, [shouldCloseToCalendar]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      ref.current.closeCalendar();
    }
  };

  return (
    <div className="relative mt-4">
      {Boolean(label) && (
        <label id={`filter-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <DatePickerDateSelectedStyle>
        <DatePicker
          ref={ref}
          onOpen={() => setShouldCloseFromCalendar(!shouldCloseFromCalendar)}
          value={value}
          render={<DatePickerInput name={name} placeholder={placeholder} handleKeyDown={handleKeyDown} />}
          renderButton={(direction, handleClick) => (
            <DatePickerNavigation
              direction={direction}
              handleClick={handleClick}
              ariaLabelNextMonth={ariaLabelNextMonth}
              ariaLabelPreviousMonth={ariaLabelPreviousMonth}
            />
          )}
          onChange={(date) => handleChange(new DateObject(date))}
          inputClass={`points-filter-date ${type}-filter`}
          weekDays
          mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => ({
            children: (
              <DatePickerDay
                date={date}
                today={today}
                selectedDate={selectedDate}
                currentMonth={currentMonth}
                isSameDate={isSameDate}
                selectedFromDate={selectedFromDate || fromDate}
              />
            ),
          })}
          format="YYYY/MM/DD"
          arrow={false}
          minDate={selectedFromDate || fromDate}
          maxDate={new Date()}
          locale={locale === 'fr-ca' ? frenchLocale : ''}
          calendarPosition="top-left"
          fixMainPosition
        />
      </DatePickerDateSelectedStyle>
    </div>
  );
}

DatePickerFieldTo.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  applyFilter: PropTypes.bool,
  resetFilter: PropTypes.bool,
  type: PropTypes.string,
  ariaLabelNextMonth: PropTypes.string,
  ariaLabelPreviousMonth: PropTypes.string,
  setShouldCloseFromCalendar: PropTypes.func,
  shouldCloseToCalendar: PropTypes.bool,
  shouldCloseFromCalendar: PropTypes.bool,
};
