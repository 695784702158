import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global states
import { useStateMachine } from 'little-state-machine';
import { updatePoints } from '../../../../../store/actions';

import { FilteringButton } from '../../PointsHistoryStyles';

export default function PointsFilterButtons({
  filterType = '',
  code = '',
  name = '',
  selected,
  setSelected,
  applyFilter,
  idPrefix = '',
}) {
  const {
    actions,
    state: {
      points: { reset },
    },
  } = useStateMachine({ updatePoints });

  const handleFilterSelected = (e) => {
    e.preventDefault();
    setSelected(e.target.getAttribute('data-id'));
  };

  useEffect(() => {
    if (reset) {
      actions.updatePoints({
        [filterType]: ['ALL'],
        reset: true,
      });
    }
  }, []);

  useEffect(() => {
    if (selected && applyFilter) {
      actions.updatePoints({
        [filterType]: [selected],
        reset: false,
      });
    }
    actions.updatePoints({
      reset: false,
    });
  }, [selected, applyFilter]);

  return (
    <FilteringButton
      type="button"
      id={`${idPrefix}-filter-${code}`}
      data-id={code}
      className={`${code === selected ? 'selected' : ''} transaction-filter-btns`}
      onClick={handleFilterSelected}
      aria-label={name}
      // eslint-disable-next-line jsx-a11y/aria-proptypes
      aria-pressed={`${code === selected}`}
    >
      {name}
    </FilteringButton>
  );
}

PointsFilterButtons.propTypes = {
  filterType: PropTypes.string,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  applyFilter: PropTypes.bool,
  idPrefix: PropTypes.string,
};
