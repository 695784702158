/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';
import { renderHTML } from '@agility/nextjs';

// Components
import UnsubscribeContent from '../Unsubscribe/UnsubscribeContent';
import Title from '../common/Headings/Title';
import WrapperLayout from '../layouts/WrapperLayout';

// Styles
import { Disclaimer } from '../forms/FormStyles';

import { deriveErrorMessageList } from '../../helpers/agilityHelpers';

const UnsubscribePage = ({ module, customData: { errorsList, pageContent } }) => {
  const { title, disclaimer, noParamsMessage } = module.fields;

  return (
    <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding" isGray>
      <Title>{title}</Title>

      <div className="max-w-screen-md">
        <UnsubscribeContent
          customData={module.fields}
          errorsList={errorsList}
          pageContent={pageContent}
          noParamsMessage={noParamsMessage}
        />

        {disclaimer && (
          <Disclaimer customStyles="unsubscribe">
            <div className="italic" dangerouslySetInnerHTML={renderHTML(disclaimer)} />
          </Disclaimer>
        )}
      </div>
    </WrapperLayout>
  );
};

UnsubscribePage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'settingsguestform',
      languageCode,
    });
    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];
    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.emailTopics) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'emailTopics',
        sortIDField: 'emailTopics_SortIdField',
      });
    }

    return {
      pageContent: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
export default UnsubscribePage;

UnsubscribePage.propTypes = {
  module: PropTypes.object,
  customData: PropTypes.object,
};
