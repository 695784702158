import PropTypes from 'prop-types';

import OfferCard from '../common/Cards/OfferCard';

// Helpers
import { checkArrayForItems } from '../../helpers/utils';

export default function OffersGrid({ list, toggle, customData, isGrid, listName }) {
  if (list && checkArrayForItems(list)) {
    return (
      <div className="grid-row">
        {list.map((item, index) => (
          <div className="grid-item" key={index}>
            <OfferCard
              id={`card-${index}`}
              code={item.offerCode}
              currentItemId={item.offerId}
              key={`${listName.replace(/\s/g, '')}-${index}`}
              content={item}
              toggle={toggle}
              offer={item}
              customData={customData}
              list
              isGrid={isGrid}
              listName={listName}
            />
          </div>
        ))}
      </div>
    );
  }

  return null;
}

OffersGrid.propTypes = {
  list: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  customData: PropTypes.object.isRequired,
  isGrid: PropTypes.bool,
  listName: PropTypes.string,
};
