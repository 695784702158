import PropTypes from 'prop-types';

// Components
import Button from '../../Button/Button';
import ButtonSimple from '../../Button/ButtonSimple';

function FilterActions({ clearAllLabel, handleApplyFilter, handleClickReset, applyFiltersLabel }) {
  return (
    <>
      <ButtonSimple
        type="button"
        className="cursor-pointer font-bold mb-4 reset-filter-btn order-last md:order-first spacing-sm"
        ariaLabel={clearAllLabel}
        onClick={handleClickReset}
        role="button"
        data-testid="clear-button"
      >
        {clearAllLabel}
      </ButtonSimple>
      <Button
        type="button"
        className="apply-filter-btn order-first md:order-last"
        buttonText={applyFiltersLabel}
        buttonLoadingText="Closing"
        ariaLabel={applyFiltersLabel}
        onClick={handleApplyFilter}
        data-testid="apply-button"
      >
        {applyFiltersLabel}
      </Button>
    </>
  );
}

FilterActions.propTypes = {
  clearAllLabel: PropTypes.string,
  handleApplyFilter: PropTypes.func,
  handleClickReset: PropTypes.func,
  applyFiltersLabel: PropTypes.string,
};

export default FilterActions;
