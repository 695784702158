import { useEffect } from 'react';
import { useRouter } from 'next/router';

import Error404 from './Error404';

export default function ErrorController({ notFound, fields, statusCode }) {
  const router = useRouter();

  useEffect(() => {
    if (notFound) {
      router.push('/page-not-found');
    }
  }, [notFound]);

  if (statusCode === 404) {
    return <Error404 fields={fields} />;
  }

  return null;
}
