/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';

import { CheckboxWrapper, StyledCheckbox, FormCheckboxExtraText } from '../FormStyles';

function FormCheckbox({ className, name, label, fieldType, rules, extraText, isDisabled, defaultChecked, alignStart, onClick }) {
  const { actions, state } = useStateMachine({ updateUser });
  const [checkbox, setCheckbox] = useState(false);

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, setValue, formState, getValues } = formContext;

  useEffect(() => {
    setValue(name, checkbox || defaultChecked, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: checkbox });
  }, [checkbox]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  return (
    <>
      <CheckboxWrapper className={`${isDisabled ? 'isDisabled' : ''}`} alignStart={alignStart}>
        <StyledCheckbox
          name={name}
          id={name}
          type={fieldType}
          onClick={onClick}
          aria-invalid={!!error}
          role="checkbox"
          aria-labelledby={`form-${name}`}
          aria-checked={defaultChecked ? 'true' : 'false'}
          aria-disabled={isDisabled}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          className={getValues(name) ? 'pressed' : ''}
          {...register(name, { ...rules })}
          isError={Boolean(isError)}
        />
        <div>
          <div className="ml-2.5">
            {Boolean(label) && (
              <label id={`form-${name}`} htmlFor={name} className={className}>
                {label}
              </label>
            )}
          </div>
          {extraText && (
            <div className="max-w-full">
              <FormCheckboxExtraText dangerouslySetInnerHTML={renderHTML(extraText)} />
            </div>
          )}
        </div>
      </CheckboxWrapper>
      {isError && <ErrorMessage error={error} />}
    </>
  );
}

FormCheckbox.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  extraText: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  alignStart: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FormCheckbox;
