import PropTypes from 'prop-types';
import { Row } from '../Table/TableStyles';

import { WarningWrapper } from './WarningStyles';

export default function TransactionWarning({ error, simplified }) {
  if (error && simplified) {
    return (
      <Row role="rowgroup" className="row-list">
        <WarningWrapper simplified tableError>
          <p>{error.message}</p>
        </WarningWrapper>
      </Row>
    );
  }

  if (error) {
    return (
      <WarningWrapper simplified tableError>
        <p>{error.message}</p>
      </WarningWrapper>
    );
  }
  return null;
}

TransactionWarning.propTypes = {
  error: PropTypes.object.isRequired,
  simplified: PropTypes.bool,
};
