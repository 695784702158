import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getEvents } from '../../../pages/api/services';

/**
 *
 * @param {*} languageCode
 * @param {*} body
 *
 * "category": [
      ""
  // Dining ,Featured ,all ,Banking ,Entertainment ,Movies, Shopping ,travel
    ],
      "tag": [
        ""
 // ["new", "expiring", "loaded", "loadable", "evergreen", "foryou", "limitedtime"]
      ]
 * @returns
 */

const useEvents = (languageCode, body = { page: 1, limit: 18 }) => {
  const {
    state: {
      session: { isLoggedIn, accessToken },
      location,
    },
  } = useStateMachine();

  const reqBody = {
    filters: {},

    pagination: {
      page: body.pagination.page || 1,
      limit: body.pagination.limit || 18,
    },
  };

  const { isLoading, error, data } = useQuery(['getEvents', reqBody, languageCode, location?.province], async () => {
    if (isLoggedIn && accessToken && location?.province) {
      try {
        // only run get offers if there is a selected category
        const res = await getEvents(reqBody, languageCode, accessToken, location.province);
        // TODO: API is  changing response types and causing failure on front end, remove this once it stabilizes

        try {
          return res.data.data.events;
        } catch (error) {
          console.error('OFFERS IS A JSON OBJECT :', error);

          const offersData = await JSON.parse(res.data.data);
          return offersData.events;
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  return { isLoading, error, events: data };
};

export default useEvents;
