// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import PointsRadioList from '../../Points/Fields/PointsRadioList';
import Loader from '../../common/Loader/Loader';

import { PointsGrid } from '../../Points/Shop/PointsShopStyles';

// Helpers
import { determinePointsOptionsFrom } from '../../../helpers/giftCardsHelper';

function GiftCardAmountToSelect({ page, customData }) {
  const { selectAmountLabel } = customData;
  const {
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  return (
    <div>
      {selectAmountLabel && <p className="mb-4">{selectAmountLabel}</p>}

      <div className="inline-block">
        <PointsGrid page={page}>
          {!redeem.giftCardDetails.brandId && <Loader />}

          {redeem.giftCardDetails.brandId &&
            determinePointsOptionsFrom({
              format: redeem.selectedFormat,
              giftCardDetails: redeem.giftCardDetails,
            }).map((giftCard, index) => (
              <PointsRadioList
                tabIndex="0"
                key={index}
                fieldId={`transaction-${giftCard.code}`}
                fieldType="radio"
                name="mobile-redeem"
                position={index}
                customData={customData}
                transaction={giftCard}
              />
            ))}
        </PointsGrid>
      </div>
    </div>
  );
}

export default GiftCardAmountToSelect;
