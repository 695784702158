import { useStateMachine } from 'little-state-machine';
import ExternalImage from '../common/Images/ExternalImage';

// Global State

// Styles
import { Section, DetailsImage } from './GiftCardStyles';

// Components
import Loader from '../common/Loader/Loader';

// Helpers
import { selectImageFrom } from '../../helpers/utils';

function GiftCardDetailImage() {
  const {
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  function getAssetArray() {
    if (redeem?.giftCardDetails?.brandId) {
      const { digital } = redeem.giftCardDetails;
      const { physical } = redeem.giftCardDetails;

      if (digital.length && digital[0]?.giftCards?.length) {
        return digital[0]?.giftCards[0]?.primaryImage?.assets;
      }

      if (physical.length && physical[0]?.giftCards?.length) {
        return physical[0]?.giftCards[0]?.primaryImage?.assets;
      }
    }

    return [];
  }

  const image = selectImageFrom({
    imagesArray: getAssetArray(),
    type: 'large',
  });

  if (!redeem?.giftCardDetails?.brandId) {
    return <Loader />;
  }
  return (
    <Section className="md:w-full">
      <DetailsImage>
        <ExternalImage url={image.url} title={redeem.giftCardDetails.brandName} fill className="object-cover object-center" />
      </DetailsImage>
    </Section>
  );
}

export default GiftCardDetailImage;
