import PropTypes from 'prop-types';

import { Row, TableRowItem } from '../../common/Table/TableStyles';

import TransactionError from '../../common/Errors/TransactionError';

import { formatDynamicLabel } from '../../../helpers/stringHelpers';

export default function PointsRowError({ notEnoughPointsLabel, needMorePointsButtonLabel, pointsNeeded, error }) {
  return (
    <Row role="row" className="points-error-row" error={error}>
      <TableRowItem role="cell" />
      <TableRowItem role="cell" className="points-error-cell">
        <TransactionError
          error={{
            message: `${notEnoughPointsLabel} ${formatDynamicLabel(needMorePointsButtonLabel, '##POINTSVALUE##', pointsNeeded)}`,
          }}
          simplified
        />
      </TableRowItem>
    </Row>
  );
}

PointsRowError.propTypes = {
  notEnoughPointsLabel: PropTypes.string.isRequired,
  needMorePointsButtonLabel: PropTypes.string.isRequired,
  pointsNeeded: PropTypes.number.isRequired,
  error: PropTypes.object.isRequired,
};
