// Global State
import { useStateMachine } from 'little-state-machine';
import GuestSignInComponent from '../signin/GuestSignInComponent';

const SignIn = ({ module, ...props }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return null;
  }

  return <GuestSignInComponent fields={module.fields} languageCode={props.languageCode} />;
};

export default SignIn;
