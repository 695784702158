/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { Controller, useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';
import { normalizeInput } from '../../../helpers/utils';

import { StyledInput, PhoneNumberLabel } from '../FormStyles';

function FormPhoneInput({
  isDisabled,
  isReadOnly,
  name,
  label,
  defaultVal,
  placeholder,
  rules,
  errorMessage,
  addressModal,
  onFocus,
}) {
  const { state, actions } = useStateMachine({ updateUser });
  const [inputVal, setInputVal] = useState(defaultVal || '');

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { control, setValue, getValues, formState } = formContext;

  // Component Mount -> set the default label once only
  useEffect(() => {
    if (defaultVal) {
      setValue(name, normalizeInput(defaultVal));
    }
  }, []);

  useEffect(() => {
    // Every state update after mount, we need to capture the user input and
    // update the form to use the new values
    setValue(name, normalizeInput(inputVal || defaultVal));
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const handleChange = (e) => {
    setInputVal(normalizeInput(e.target.value));
    setValue(normalizeInput(e.target.value));

    return normalizeInput(getValues(name));
  };

  return (
    <FieldLayout addressModal={addressModal}>
      {Boolean(label) && (
        <PhoneNumberLabel addressModal={addressModal} id={`form-${name}`} htmlFor={name}>
          {label}
        </PhoneNumberLabel>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={normalizeInput(defaultVal)}
        rules={{
          ...rules,
          minLength: {
            message: errorMessage,
            value: 12,
          },
          validate: async (value) => {
            // return error if first numbers are 0
            if (/^0/.test(value)) {
              return errorMessage;
            }
          },
        }}
        render={({ field: { onBlur, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
          <StyledInput
            onFocus={onFocus}
            id={name}
            name={name}
            type="tel"
            aria-invalid={!!error}
            aria-labelledby={`form-${name}`}
            aria-disabled={isDisabled}
            aria-readonly={isReadOnly}
            placeholder={placeholder || label}
            inputRef={ref}
            disabled={isDisabled}
            onBlur={onBlur}
            value={normalizeInput(inputVal)}
            className={`${isDisabled ? 'field-is-disabled' : ''}${
              (error && isTouched) || (error && formState.isSubmitted) ? ' field-has-error' : ''
            }`}
            onChange={handleChange}
            isError={Boolean(error)}
          />
        )}
      />
      {isError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormPhoneInput.defaultProps = {
  addressModal: false,
};

FormPhoneInput.propTypes = {
  label: PropTypes.string,
  addressModal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  defaultVal: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  isReadOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
};
export default FormPhoneInput;
