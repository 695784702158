import PropTypes from 'prop-types';

import { splitDigitsIntoArray } from '../../../helpers/greetingsHelpers';

import { PointsCounter, BalanceTicker, PointsLabel } from './GreetingStyles';

export default function GreetingsBalanceTicker({ balance, ptsLabel }) {
  const numberKeys = splitDigitsIntoArray(balance);

  if (numberKeys && numberKeys.length > 0) {
    return (
      <PointsCounter>
        <BalanceTicker>
          <div className="counter" aria-hidden>
            {balance < 0 && (
              <div className="number">
                <span className="primary points-negative">-</span>
              </div>
            )}
            {numberKeys.map((digit, index) => (
              <div key={index} className="number">
                <span className="primary">{digit}</span>
              </div>
            ))}
          </div>
        </BalanceTicker>
        <PointsLabel>
          <span className="sr-only">{balance}</span>
          {ptsLabel}
        </PointsLabel>
      </PointsCounter>
    );
  }

  return null;
}

GreetingsBalanceTicker.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ptsLabel: PropTypes.string.isRequired,
};
