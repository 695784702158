import styled from 'styled-components';

export const PlainHeadingStyles = styled.h1`
  background-color: ${(props) => props.bgColor || props.theme.uiBlue};
  color: ${(props) => props.fontColor || props.theme.uiWhite};
  padding: 6.25rem 1.5rem;
  text-align: center;
  font-family: 'Bebas Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  line-height: 0.86;
  text-transform: uppercase;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.11);

  @media screen and (min-width: 768px) {
    font-size: 8.75rem;
    padding: 5rem 1.5rem;
  }
`;
