import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import { useStateMachine } from 'little-state-machine';
import { updatePoints, updateTransactions } from '../../../../../store/actions';

// Styles
import { TitleWrapper } from '../../../../common/Headings/TitleStyles';

// Components
import Title from '../../../../common/Headings/Title';
import HowItWorksBYOT from '../byot/HowItWorksBYOT';
import HowItWorksPFC from '../pfc/HowItWorksPFC';
import PointsTransactionsForm from './PointsTransactionsForm';
import ConfirmPartialModal from '../byot/ConfirmPartialModal';

// Hooks
import useModal from '../../../../hooks/utils/useModal';

export default function PointsTransactions({ page, customData, step, languageCode }) {
  const { title, bYOTMessage, howItWorksTitle, howItWorksMessage, image, partialRedemptionLabel } = customData;
  const {
    actions,
    state: {
      transactions: { isLoading, selectedCard },
    },
  } = useStateMachine({ updatePoints, updateTransactions });

  const { isShowing, toggle, currentItemId } = useModal();

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = async (data, error) => {
    actions.updatePoints({ step: 2 });
  };

  const onErrors = (errors, e) => {
    const errorMsgs = Object.values(errors).map((err) => err.message);

    if (errorMsgs.some((msg) => msg === partialRedemptionLabel)) {
      toggle();

      // actions.updatePoints({ step: 2 });
    } else {
      console.info(errors);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <>
        {title && (
          <TitleWrapper id={`page-header-${page}`} mb1rem>
            <Title>{title}</Title>
          </TitleWrapper>
        )}

        <HowItWorksBYOT page={page} bYOTMessage={bYOTMessage} />
      </>

      <div>
        <div
          className={`inner-columns-wrapper page-bottom-spacing ${page === 'pfc' ? ' sm:flex justify-between md:pt-8 pt-4' : ''}`}
        >
          <HowItWorksPFC
            page={page}
            howItWorksMessage={howItWorksMessage}
            howItWorksTitle={howItWorksTitle}
            image={image}
            step={step}
          />

          <PointsTransactionsForm
            isLoading={isLoading}
            selectedCard={selectedCard}
            page={page}
            onSubmit={onSubmit}
            onErrors={onErrors}
            formMethods={formMethods}
            customData={customData}
            languageCode={languageCode}
          />
        </div>
      </div>

      {isShowing && <span className="overlay" />}
      {isShowing && <ConfirmPartialModal customData={customData} currentItemId={currentItemId} toggle={toggle} />}
    </FormProvider>
  );
}

PointsTransactions.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
  step: PropTypes.number,
  languageCode: PropTypes.string,
};
