import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../store/actions';

// Components
import OnboardingContent from '../Onboarding/OnboardingContent';
import OnboardingContentMobile from '../Onboarding/OnboardingContentMobile';

// Hooks
import useStorage from '../hooks/navigation/useStorage';

// Helpers
import { disableScrolling, enableScrolling } from '../../helpers/behavourHelper';
import { findPageName } from '../../helpers/onboardingHelper';

const Onboarding = ({ module: { fields } }) => {
  const { gotItLabel, closeLabel, code } = fields;

  const {
    actions,
    state: {
      navigation,
      session: { isLoggedIn, signInProgress },
    },
  } = useStateMachine({ updateUser });

  const { getItem, setItem } = useStorage();

  const onBoardedHome = getItem('onBoardedHome', 'local');
  const onBoardedRewards1 = getItem('onBoardedRewards1', 'local');
  const onBoardedRewards2 = getItem('onBoardedRewards2', 'local');
  const onBoardedOffers = getItem('onBoardedOffers', 'local');

  // TODO: if we expand the # of tooltips we will need to expand this list
  const [firstTooltip, secondTooltip] = findPageName(code);

  const showOnboarding =
    isLoggedIn &&
    // pages we current have tooltips on
    ((!onBoardedHome && firstTooltip === 'home1') ||
      (!onBoardedRewards1 && firstTooltip === 'rewards1') ||
      (!onBoardedRewards2 && secondTooltip === 'rewards2') ||
      (!onBoardedOffers && firstTooltip === 'offers1')) &&
    (!navigation?.pageLoading || !signInProgress); // dont show during page transitions)

  useEffect(() => {
    if (showOnboarding) {
      disableScrolling();
      actions.updateUser({ onBoardMessageActive: true });
    }

    return () => {
      actions.updateUser({ onBoardMessageActive: false });
      enableScrolling();
    };
  }, [isLoggedIn, onBoardedHome, onBoardedRewards1, onBoardedRewards2, onBoardedOffers]);

  const disableTooltip = (e) => {
    e.preventDefault();

    if (firstTooltip === 'home1') {
      setItem('onBoardedHome', true, 'local');
    }
    if (firstTooltip === 'rewards1') {
      setItem('onBoardedRewards1', true, 'local');
    }

    if (firstTooltip === 'offers1') {
      setItem('onBoardedOffers', true, 'local');
    }

    if (secondTooltip === 'rewards2' && onBoardedRewards1) {
      setItem('onBoardedRewards2', true, 'local');
    }

    enableScrolling();
    actions.updateUser({ onBoardMessageActive: false });

    e.stopPropagation();
  };

  if (code && showOnboarding) {
    return (
      <>
        <OnboardingContent
          contents={code}
          handleClick={disableTooltip}
          gotItLabel={gotItLabel}
          closeLabel={closeLabel}
          isRewards1Onboarded={onBoardedRewards1}
        />

        <OnboardingContentMobile
          contents={code}
          handleClick={disableTooltip}
          gotItLabel={gotItLabel}
          closeLabel={closeLabel}
          isRewards1Onboarded={onBoardedRewards1}
          isMobile
        />
      </>
    );
  }

  return null;
};

Onboarding.propTypes = { module: PropTypes.object };

export default Onboarding;
