/* eslint-disable camelcase */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useMsal } from '@azure/msal-react';
import { useRouter } from 'next/router';
import useStorage from '../hooks/navigation/useStorage';

// Components
import Loader from '../common/Loader/Loader';

// Helpers
import { b2cSso } from '../../b2c/b2cFunctions';

const SsoLoading = ({ languageCode }) => {
  const { instance } = useMsal();
  const router = useRouter();
  const { redirect_uri, domain_hint } = router.query;
  const { setItem } = useStorage();

  useEffect(() => {
    async function b2cCall() {
      const rootURI = process.env.NEXT_PUBLIC_REDIRECT_URI;
      const parsedB2cParamRedirectURI = decodeURIComponent(redirect_uri);
      const redirectUri = new URL(parsedB2cParamRedirectURI)?.pathname?.replace(`/${languageCode}`, '');

      // save the sso redirect uri page in session store
      setItem('from_url', redirectUri);

      // continue to b2c with homepage as default redirect
      b2cSso(instance, languageCode, rootURI, domain_hint);
    }

    if (domain_hint && redirect_uri) {
      b2cCall();
    }
  }, [redirect_uri, domain_hint]);

  return <Loader />;
};

export default SsoLoading;

SsoLoading.propTypes = {
  languageCode: PropTypes.string,
};
