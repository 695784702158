import styled from 'styled-components';

export const StyledBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.lightGrayColor};
  p {
    font-size: ${(props) => props.theme.fontBody1};
    line-height: 1.75rem;
    margin: 1.5rem auto 2rem;
  }

  @media screen and (max-width: 1439px) {
    height: 17.75rem;
    p {
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
      margin: 0.75rem auto 2rem;
    }
  }

  @media screen and (min-width: 1440px) {
    height: 24rem;
  }

  .download-logo {
    position: relative;
  }
`;

export const StyledBannerContent = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-basis: 50%;
  }
  @media screen and (min-width: 1024px) {
    flex-basis: 60%;
  }
  width: 100%;
  padding: 6.625rem 0;
  .image-wrapper {
    position: relative;
    height: 2.5rem;
    max-width: 8.5rem;
    a {
      display: block;
      height: 2.5rem;
      width: inherit;
    }
    img {
      height: 2.5rem;
      width: 100%;
    }
    &:first-of-type {
      margin-right: 1.25rem;
    }
  }
`;
