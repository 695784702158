/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { renderHTML } from '@agility/nextjs';
// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers, updateNotification } from '../../../store/actions';

// Components
import LinkComponent from '../Links/LinkComponent';
import TermsComponent from '../Terms/TermsComponent';
import LoadOfferButton from '../Button/LoadOfferButton';
import ExternalImage from '../Images/ExternalImage';

// Helpers
import { formatDate } from '../../../helpers/dateHelpers';
import { updateOptedInStatusFrom } from '../../../helpers/offersHelpers';
import { handleGAEventsBase, getOfferCardMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown, checkArrayForItems } from '../../../helpers/utils';
import { formatMultiplierLabel } from '../../../helpers/stringHelpers';
// Services
import { loadOffers } from '../../../pages/api/services';

// Styles
import { ModalPopupContainer, ModalPopupHeader, ModalPopupBody } from './ModalStylesV2';
import { ModalLoadOfferIcon, MultiplierDetails, MultiplierIcon } from '../Cards/CardStyles';

export default function OffersCardModal({ offer, customData, languageCode, listName, id, isSpotlight }) {
  const [loading, setLoading] = useState(false);

  const {
    loadingLabel,
    loadOfferLabel,
    messageOfferLoaded,
    messageOfferNotLoaded,
    messageOfferUnloaded,
    unloadingLabel,
    offerLoadedLabel,
    offerPreloadedLabel,
    getCouponLabel,
    applyNowButtonLabel,
    applyNowCTA,
    multiplierDetailsLabel,
    multiplierIcon,
    multiplierDetailsMessage,
  } = customData;

  const {
    brand,
    title,
    details,
    cta,
    termsAndConditions,
    isCouponBased,
    getCouponLink,
    isBNSLinkEnabled,
    linkBNS,
    acceleratedSegments,
  } = offer?.cmsContent || {};

  const {
    actions,
    state: {
      session: { accessToken },
      offers: {
        offersList,
        selectedFilters,
        limitedTimeOffersList,
        offersForYouList,
        moreOffersList,
        moreWaysToEarn,
        featuredOffer,
      },
      labels,
      location,
    },
  } = useStateMachine({ updateOffers, updateNotification });

  const status = offer && offer.isOptedIn;
  const isLoadable = offer?.tags && offer.tags.includes('loadable');
  const isEvergreen = offer?.tags && offer.tags.includes('evergreen');

  // GA props
  const eventCategory = 'Partner Offers';
  const list_name = listName;
  const tile_position = parseInt(id?.split('-')[1]) + 1;
  const prov_segment = location?.province;
  let item_category3 = '';

  if (isLoadable) {
    item_category3 = `Loadable${status ? ' - loaded' : ''}`;
  }

  if (isCouponBased) {
    item_category3 = 'Coupons';
  }

  const gaAddToCart = () => {
    const event = 'offer_add_to_cart';
    const alt_event = 'offers_load_on_tile';
    const eventAction = 'Offer Loaded';
    const dataLayer = getOfferCardMappedObject(
      offer,
      event,
      alt_event,
      eventCategory,
      eventAction,
      list_name,
      tile_position,
      prov_segment,
      item_category3
    );

    handleGAEventsBase(dataLayer);
  };

  useEffect(() => {
    if (!isSpotlight) {
      const event = 'offer_view_item';
      const alt_event = 'offers_detail_view';
      const eventAction = 'Offers Detail View';

      const dataLayer = getOfferCardMappedObject(
        offer,
        event,
        alt_event,
        eventCategory,
        eventAction,
        list_name,
        tile_position,
        prov_segment,
        item_category3
      );

      handleGAEventsBase(dataLayer);
    }
  }, []);

  const handleOfferLoadUnload = async (e) => {
    e.persist();
    try {
      if (status) {
        const event = 'offer_remove_from_cart';
        const alt_event = 'offers_unload_offer_tile';
        const eventAction = 'Offer unloaded';

        const dataLayer = getOfferCardMappedObject(
          offer,
          event,
          alt_event,
          eventCategory,
          eventAction,
          list_name,
          tile_position,
          prov_segment,
          item_category3
        );
        handleGAEventsBase(dataLayer);
      } else {
        gaAddToCart();
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(true);
    const reqBody = [{ offerId: offer.offerId, optIn: !offer.isOptedIn }];

    const response = await loadOffers(reqBody, accessToken);
    if (response?.data?.data === true) {
      const offerClicked = { ...offer };
      // find all instances where the loaded offer would be located.
      if (selectedFilters === 'featured') {
        // TODO: optimize this with a conditional helper that will cherry pick the arrays based on the tags/categories of the offer (See derive offers from helper)
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
          offersForYouList: updateOptedInStatusFrom(offersForYouList, offerClicked),
          moreOffersList: updateOptedInStatusFrom(moreOffersList, offerClicked),
          moreWaysToEarn: updateOptedInStatusFrom(moreWaysToEarn, offerClicked),
          featuredOffer: updateOptedInStatusFrom(featuredOffer, offerClicked),
        });
      } else {
        actions.updateOffers({ offersList: updateOptedInStatusFrom(offersList, offerClicked) });
      }

      actions.updateNotification({
        message: offer.isOptedIn ? messageOfferLoaded : messageOfferUnloaded,
        status: offer.isOptedIn ? 'success' : 'warning',
      });
    } else {
      actions.updateNotification({
        message: messageOfferNotLoaded,
        status: 'error',
      });
      const dataLayer = {
        event: 'offers_loaded_fail',
        category: 'Partner Offers',
        action: 'Offer Loaded Fail',
        label: `${offer.cmsContent.brand?.title} | ${title}`,
      };

      handleGAEventsBase(dataLayer);
    }
    setLoading(false);
  };
  const handleCouponBasedOfferClick = (e) => {
    gaAddToCart();
  };

  function setLoadMessage() {
    if (isEvergreen) return offerPreloadedLabel;
    // if not loadable, we can just show a standard label
    if (!isLoadable) return offerLoadedLabel;

    // Manage the labels based on the button state
    if (loading) {
      if (status) {
        return unloadingLabel;
      }
      return loadingLabel;
    }

    if (status) {
      return offerLoadedLabel;
    }

    return loadOfferLabel;
  }

  if (labels) {
    return (
      <ModalPopupContainer>
        {checkArrayForItems(acceleratedSegments) && (
          <MultiplierDetails>
            <MultiplierIcon>
              {multiplierIcon?.url && <ExternalImage url={multiplierIcon?.url} fill className="object-contain object-left" />}
            </MultiplierIcon>
            <div>
              {acceleratedSegments[0].link ? (
                <Link
                  href={acceleratedSegments[0].link?.href}
                  tabIndex="0"
                  role="link"
                  target={acceleratedSegments[0].link?.target || '_blank'}
                >
                  {formatMultiplierLabel(multiplierDetailsLabel, acceleratedSegments[0].factor)}
                </Link>
              ) : (
                <p className="font-bold">{formatMultiplierLabel(multiplierDetailsLabel, acceleratedSegments[0].factor)}</p>
              )}
              <p>{multiplierDetailsMessage}</p>
            </div>
          </MultiplierDetails>
        )}
        <ModalPopupHeader>
          {brand && (
            <h1 className="brand-title" id="modal-label">
              {brand.title}
            </h1>
          )}
          {title && <h2 className="card-title">{title}</h2>}
        </ModalPopupHeader>
        <ModalPopupBody>
          {details && (
            <p>
              <strong>{labels?.detailsLabel} </strong> {details}
            </p>
          )}

          {offer.endDate && (
            <p className="expiry-date">
              {labels?.expiresLabel} {formatDate(offer.endDate, 'MMMM DD, YYYY', languageCode)}
            </p>
          )}

          {!isCouponBased && (
            <LoadOfferButton
              type="submit"
              buttonLoadingText={loadingLabel}
              aria-label={status ? offerLoadedLabel : loadOfferLabel}
              className="my-8"
              onClick={handleOfferLoadUnload}
              isLoadable={isLoadable}
              loaded={status}
            >
              {setLoadMessage()}

              <ModalLoadOfferIcon className="offer-load-icon loadable-offer" loaded={status || isEvergreen}>
                <Image
                  src={status || !isLoadable || isEvergreen ? `/assets/checkmark-sharp.svg` : `/assets/cross-white-icon.svg`}
                  alt=""
                  width={18}
                  height={18}
                  role="presentation"
                />
              </ModalLoadOfferIcon>
            </LoadOfferButton>
          )}

          {isCouponBased && (
            <Link
              href={getCouponLink?.href}
              tabIndex="0"
              role="link"
              style={{ textDecoration: 'none' }}
              target={getCouponLink?.target}
              onClick={handleCouponBasedOfferClick}
              onKeyDown={(e) => handleKeyDown(e, handleCouponBasedOfferClick)}
            >
              <LoadOfferButton
                type="link"
                buttonLoadingText={loadingLabel}
                aria-label={getCouponLabel}
                className="my-8"
                isCouponBased={isCouponBased}
              >
                {getCouponLink?.text || getCouponLabel}

                <ModalLoadOfferIcon className="offer-load-icon loadable-offer" loaded={status || isEvergreen}>
                  <Image src="/assets/arrow-right-white.svg" alt="" width={18} height={18} role="presentation" />
                </ModalLoadOfferIcon>
              </LoadOfferButton>
            </Link>
          )}

          {isBNSLinkEnabled && brand?.code === 'bns' && linkBNS.href && (
            <>
              {applyNowCTA && <div className="text-center text-sm" dangerouslySetInnerHTML={renderHTML(applyNowCTA)} />}
              {linkBNS?.href && (
                <div className="mb-8 mt-4">
                  <LinkComponent
                    aria-label={linkBNS.text || applyNowButtonLabel}
                    href={linkBNS.href}
                    target={linkBNS.target || '_blank'}
                    buttonStyle="secondary-button-red"
                  >
                    {linkBNS.text || applyNowButtonLabel}
                  </LinkComponent>
                </div>
              )}
            </>
          )}

          {cta?.href && cta?.text && (
            <div className="my-8">
              <LinkComponent
                href={cta.href}
                target={cta.target || '_blank'}
                className="color-black font-bold mb-4"
                style={{ fontSize: '0.875rem', maxWidth: 'inherit' }}
                buttonLoadingText={loadingLabel}
                locale={languageCode}
              >
                {cta.text}
              </LinkComponent>
            </div>
          )}
        </ModalPopupBody>
        {termsAndConditions && (
          <TermsComponent termsBlob={termsAndConditions} scrollable termsAndConditionsLabel={labels?.termsAndConditionsLabel} />
        )}
      </ModalPopupContainer>
    );
  }
  return null;
}

OffersCardModal.propTypes = {
  offer: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  listName: PropTypes.string,
  id: PropTypes.string,
  isSpotlight: PropTypes.object,
};
