/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

// Components
import WrapperLayout from '../../layouts/WrapperLayout.js';
import Step1 from './Step1';
import Step2 from './Step2';
import Result from './Result';

const CreateAccountForm = (props) => {
  const { createAccountForm, passwordRules, errorsList, languageCode, monthList } = props;

  const {
    state: {
      user: { step },
    },
    actions,
  } = useStateMachine({ updateUser });
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);

    if (step === 3) {
      actions.updateUser({ step: 1 });
    }
  }, []);

  if (!hasMounted) {
    return null;
  }

  const step1 = (
    <Step1 {...createAccountForm} passwordRules={passwordRules} errorsList={errorsList} languageCode={languageCode} />
  );

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return step1;
      case 2:
        return <Step2 {...createAccountForm} months={monthList} errorsList={errorsList} languageCode={languageCode} />;
      case 3:
        return <Result languageCode={languageCode} {...createAccountForm} />;
      default:
        return step1;
    }
  };

  return (
    <WrapperLayout
      className="form-container p-0 bg-white text-base relative shadow-xl w-full sm:max-w-xl mx-auto"
      customStyles="registerPage"
    >
      {renderSwitch(step)}
    </WrapperLayout>
  );
};

export default CreateAccountForm;
