/**
 *
 * @param {*} state existing state
 * @param {*object} payload
 * @returns
 */
export default function updateNotificationAction(state, payload) {
  return {
    ...state,
    notification: {
      ...state.message,
      ...payload,
    },
  };
}
