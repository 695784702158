import styled from 'styled-components';

export const ContentStyle = styled.div`
  ul li,
  ol li {
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-top: 1.125rem;
  }

  ol.roman {
    list-style-type: lower-roman;
    padding-left: 0.5rem;
    li {
      margin-top: 1.125rem;
    }
  }
`;
