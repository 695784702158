import styled from 'styled-components';

const StepBlock = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 1.25rem;

  p {
    background-color: ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.fontLegal};
    padding: 0.5rem 1rem;
    margin: 0 auto;
  }
`;

export default function Step({ step }) {
  return (
    <StepBlock>
      <p>{step}</p>
    </StepBlock>
  );
}
