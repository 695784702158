import styled from 'styled-components';

export const UnsubscribeContentContainer = styled.div`
  padding: 1.5rem;
  margin-top: 2.5rem;
  background-color: ${(props) => props.theme.whiteColor};
  @media screen and (max-width: 640px) {
    margin-top: 1.52rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-top: 2rem;
  }
`;

export const UnsubscribeButtonStyles = styled.div`
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;

  @media screen and (min-width: 641px) {
    flex-direction: row;

    .unsub-btn {
      margin-bottom: 0;
    }
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-top: 1rem;
  }
`;

export const ModalIcon = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 auto;

  @media screen and (min-width: 641px) {
    width: 80px;
    height: 80px;
  }
`;

export const UnsubscribeSuccessModalBody = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 768px) {
    max-width: 75%;
  }
`;

export const UnsubscribeSuccessModalHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  .close-icon {
    position: absolute;
    right: 1rem;
  }
`;

export const UnsubscribeSuccessModalContainer = styled.div`
  padding: 1rem;
`;

export const UnsubscribeSuccessButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem auto;
`;
