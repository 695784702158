/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ExternalImage from '../Images/ExternalImage';
// Styles
import { HeroBannerContainer, HeroDetails } from './HeroBannerStyles';
import Button from '../Button/Button';
import { isEmpty } from '../../../helpers/utils';

export default function HeroBanner({ fields }) {
  const router = useRouter();

  if (!isEmpty(fields)) {
    const { title, description, webImage, appImage, cTALink, cTALabelLoading, scaleImageDown } = fields;
    const imageClassname =
      scaleImageDown && scaleImageDown === 'true'
        ? 'object-scale-down object-center width-auto'
        : 'object-cover object-center width-auto';

    if (description && cTALink?.text && cTALink?.href) {
      return (
        <div className="bg-black w-full h-full overflow-hidden">
          <HeroBannerContainer className="relative flex items-center justify-start ">
            {webImage?.url && (
              <figure className="absolute banner-image gumbo-image hide-on-mobile">
                <ExternalImage url={webImage?.url} fill className={imageClassname} sizes="50vw" quality={85} />
              </figure>
            )}

            {appImage?.url && (
              <figure className="h-56 w-full relative  hide-on-desktop">
                <ExternalImage url={appImage?.url} fill className={imageClassname} />
              </figure>
            )}

            <HeroDetails className="gumbo">
              {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
              {description && <p>{description}</p>}
              {cTALink?.text && cTALink?.href && (
                <Button
                  type="button"
                  className="hero-cta"
                  onClick={() => router.push(cTALink?.href)}
                  buttonLoadingText={cTALabelLoading}
                  ariaLabel={cTALink?.text}
                >
                  {cTALink?.text}
                </Button>
              )}
            </HeroDetails>
          </HeroBannerContainer>
        </div>
      );
    }
    return (
      <div className="bg-black w-full h-full overflow-hidden">
        <HeroBannerContainer className="relative flex items-center justify-start">
          {webImage?.url && (
            <figure className="absolute banner-image hide-on-mobile">
              <ExternalImage url={webImage?.url} fill className={imageClassname} sizes="50vw" />
            </figure>
          )}

          {appImage && appImage?.url && (
            <figure className="h-40 w-full relative hide-on-desktop">
              <ExternalImage url={appImage?.url} fill className={imageClassname} />
            </figure>
          )}

          <HeroDetails>{title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}</HeroDetails>
        </HeroBannerContainer>
      </div>
    );
  }
  return null;
}

HeroBanner.propTypes = { fields: PropTypes.object };
