import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: ${(props) => props.theme.fontBody2};
    margin: 0 1rem 0 0;
    ${(props) => (props.reverse ? `color: ${props.theme.lightGrayColor2}` : '')};
  }
`;
export const LoadingRing = styled.div`
  display: flex;
  position: relative;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  overflow: hidden;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 2px solid ${(props) => (props.reverse ? props.theme.lightGrayColor2 : '#333333')};
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => (props.reverse ? props.theme.lightGrayColor2 : '#333333')} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes loading-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
