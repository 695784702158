import PropTypes from 'prop-types';
// Styles
import { FilterModalPopupBody, FilterModalBodyWrapper } from '../ModalStyles';

function FilterModalBody({ children }) {
  return (
    <FilterModalBodyWrapper className="filter-modal-body">
      <FilterModalPopupBody>{children}</FilterModalPopupBody>
    </FilterModalBodyWrapper>
  );
}

FilterModalBody.propTypes = { children: PropTypes.element };

export default FilterModalBody;
