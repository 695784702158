import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import AccordionContent from '../common/Accordion/AccordionContent';

const SharedAccordionPartnerTermsAndConditionsSection = ({ module, customData }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <AccordionContent fields={fields} customData={customData} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedAccordionPartnerTermsAndConditionsSection.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'partnerstermsconditionslist',
      languageCode,
    });
    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      accordionList: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

SharedAccordionPartnerTermsAndConditionsSection.propTypes = {
  module: PropTypes.object,
  customData: PropTypes.object,
};

export default SharedAccordionPartnerTermsAndConditionsSection;
