import { useState } from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../../../store/actions';

// Components
import GuestSettingsEmailFieldContent from './GuestSettingsEmailFieldContent';
import ButtonSimple from '../../../common/Button/ButtonSimple';
import Button from '../../../common/Button/Button';

// Services
import { getSavedPreferencesPublic, savePreferencesPublic } from '../../../../pages/api/services';

// Utils
import { savingEmailSettingsValues } from '../../../../helpers/settingsHelper';
import { getUrlEncodedQueryParam } from '../../../../helpers/navigationHelpers';

// Styles
import { GuestSettingsButtons, Row, Title } from '../../FormStyles';

export default function GuestSettingsEmail({ customData, errorsList, guestPreferencesData }) {
  const {
    emailExpandedMessage,
    emailPreferencesMessage,
    emailTopics,
    emailPreferencesLabel,
    emailOptInLabel,
    saveLabel,
    saveLabelLoading,
    cancelLabel,
    emailSuccessMessage,
  } = customData;

  const [submitting, setSubmitting] = useState(false);
  const router = useRouter();

  const {
    actions,
    state: { navigation },
  } = useStateMachine({ updateUser, updateNotification });
  const navigationStateQuery = encodeURIComponent(router?.query?.q || navigation?.query?.q);
  const urlQuery = getUrlEncodedQueryParam('q');

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = (data) => {
    setSubmitting(true);
    savePreferencesPublic(savingEmailSettingsValues(data), navigationStateQuery || urlQuery)
      .then(async (res) => {
        if (res.data) {
          const response = await getSavedPreferencesPublic(encodeURIComponent(router?.query?.q || navigation?.query?.q));
          const preferencesData = response.data.data;

          actions.updateUser({
            guestPreferences: preferencesData,
          });

          actions.updateNotification({
            message: emailSuccessMessage,
            status: 'success',
          });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: errorsList && errorsList[0].message,
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <Row className="mb-1">
        <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="md:flex justify-between content-center items-start">
            <div className="w-52">
              <Title className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{emailPreferencesLabel}</Title>
            </div>
            <div className="w-full">
              <GuestSettingsEmailFieldContent
                emailExpandedMessage={emailExpandedMessage}
                emailPreferencesMessage={emailPreferencesMessage}
                emailTopics={emailTopics}
                emailOptInLabel={emailOptInLabel}
                guestPreferencesData={guestPreferencesData}
              />

              <GuestSettingsButtons>
                <Button
                  type="submit"
                  className="mr-8"
                  buttonLoadingText={saveLabelLoading}
                  submitting={submitting}
                  ariaLabel={saveLabel}
                >
                  {submitting ? saveLabelLoading : saveLabel}
                </Button>

                <ButtonSimple
                  type="button"
                  className="cursor-pointer font-bold mt-4"
                  ariaLabel={cancelLabel}
                  role="button"
                  onClick={() => router.push(`/unsubscribe?q=${encodeURIComponent(router?.query?.q || navigation?.query?.q)}`)}
                >
                  {cancelLabel}
                </ButtonSimple>
              </GuestSettingsButtons>
            </div>
          </div>
        </form>
      </Row>
    </FormProvider>
  );
}
