import PropTypes from 'prop-types';

import ModalLayout from '../../../layouts/ModalLayout';
import MissingPointsModalContent from './MissingPointsModalContent';
import Portal from '../../../common/Portal/Portal';

export default function MissingPointsModal({ isShowing, toggle, customData }) {
  if (isShowing) {
    return (
      <Portal>
        {isShowing && <span className="overlay" />}
        <ModalLayout toggle={toggle} hideCloseIcon type="modal-confirmation">
          <MissingPointsModalContent customData={customData} toggle={toggle} />
        </ModalLayout>
      </Portal>
    );
  }
  return null;
}

MissingPointsModal.propTypes = {
  isShowing: PropTypes.bool,
  toggle: PropTypes.func,
  customData: PropTypes.object,
};
