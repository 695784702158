import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';
import BirthMonthDropdown from './BirthMonthDropdown';
import BirthDayInput from './BirthDayInput';
import BirthYearInput from './BirthYearInput';

function DateOfBirthField({
  monthLabel,
  months,
  monthDefaultOption,
  dayLabel,
  dayPlaceholder,
  yearLabel,
  yearPlaceholder,
  errorsList,
  gaFlow,
}) {
  const { state } = useStateMachine();

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { formState } = formContext;

  // error messages
  const errorMonth = formState?.errors?.dobMonth;
  const errorDay = formState?.errors?.dobDay;
  const errorYear = formState?.errors?.dobYear;

  const isErrorMonth = Boolean(errorMonth && (formState.touchedFields?.dobMonth || formState.isSubmitted));
  const isErrorDay = Boolean(errorDay && (formState.touchedFields?.dobDay || formState.isSubmitted));
  const isErrorYear = Boolean(errorYear && (formState.touchedFields?.dobYear || formState.isSubmitted));

  return (
    <FieldLayout>
      <div className="flex">
        <div className="w-3/4 mr-4">
          <BirthMonthDropdown
            gaFlow={gaFlow}
            id="dobMonth"
            name="dobMonth"
            label={monthLabel}
            options={months}
            defaultOption={monthDefaultOption}
            placeholder={monthDefaultOption}
            errorsList={errorsList}
            errorMessage={errorsList.filter((item) => item.code === 'Customer.BirthDate.Invalid').map((item) => item.message)[0]}
            rules={{
              required: {
                message: errorsList
                  .filter((item) => item.code === 'Customer.BirthDate.RequiredMonth')
                  .map((item) => item.message)[0],
                value: true,
              },
            }}
          />
        </div>
        <div className="flex">
          <div className="flex-none w-20 mr-4">
            <BirthDayInput
              gaFlow={gaFlow}
              id="dobDay"
              fieldType="number"
              name="dobDay"
              label={dayLabel}
              maxLength={2}
              placeholder={dayPlaceholder}
              defaultVal={state.user.dobDay}
              errorsList={errorsList}
              errorMessage={
                errorsList.filter((item) => item.code === 'Customer.BirthDate.Invalid').map((item) => item.message)[0]
              }
              rules={{
                required: {
                  message: errorsList
                    .filter((item) => item.code === 'Customer.BirthDate.RequiredDay')
                    .map((item) => item.message)[0],
                  value: true,
                },
              }}
            />
          </div>
          <div className="flex-1">
            <BirthYearInput
              gaFlow={gaFlow}
              id="dobYear"
              fieldType="number"
              name="dobYear"
              label={yearLabel}
              minLength={4}
              maxLength={4}
              placeholder={yearPlaceholder}
              defaultVal={state.user.dobYear}
              errorsList={errorsList}
              errorMessage={
                errorsList.filter((item) => item.code === 'Customer.BirthDate.Invalid').map((item) => item.message)[0]
              }
              rules={{
                required: {
                  message: errorsList
                    .filter((item) => item.code === 'Customer.BirthDate.RequiredYear')
                    .map((item) => item.message)[0],
                  value: true,
                },
              }}
            />
          </div>
        </div>
      </div>

      {isErrorMonth && <ErrorMessage error={errorMonth} />}
      {isErrorDay && <ErrorMessage error={errorDay} />}
      {isErrorYear && <ErrorMessage error={errorYear} />}
    </FieldLayout>
  );
}

DateOfBirthField.defaultProps = {
  gaFlow: '',
};

DateOfBirthField.propTypes = {
  monthLabel: PropTypes.string,
  months: PropTypes.array,
  monthDefaultOption: PropTypes.string,
  dayLabel: PropTypes.string,
  dayPlaceholder: PropTypes.string,
  yearLabel: PropTypes.string,
  yearPlaceholder: PropTypes.string,
  errorsList: PropTypes.array,
  gaFlow: PropTypes.string,
};

export default DateOfBirthField;
