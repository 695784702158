/* eslint-disable default-param-last */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateGiftCards } from '../../../store/actions';

export default function useGiftCardCategories(categories = [], languageCode) {
  const {
    actions,
    state: {
      giftCards: { giftcardCategories },
    },
  } = useStateMachine({ updateGiftCards });

  useEffect(() => {
    if (!giftcardCategories && categories) {
      actions.updateGiftCards({
        giftcardCategories: categories,
      });
    }
  }, [giftcardCategories]);

  useEffect(() => {
    if (languageCode && giftcardCategories) {
      actions.updateGiftCards({
        giftcardCategories: categories,
      });
    }
  }, [languageCode]);
}
