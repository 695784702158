import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import LinkComponent from '../Links/LinkComponent';
import TermsComponent from '../Terms/TermsComponent';

import { ModalPopupContainer, ModalPopupHeader, ModalPopupBody } from './ModalStylesV2';

export default function SpotlightModalContents({ detailsLabel, termsAndConditionsLabel, content }) {
  const {
    state: {
      labels: { loadingLabel },
    },
  } = useStateMachine();

  if (content) {
    const { title, brand, termsAndConditions, cta, cTALink, ctaLink, description, details, subTitle } = content?.cmsContent || {};

    return (
      <ModalPopupContainer>
        <ModalPopupHeader>
          {brand && (
            <h1 className="brand-title" id="modal-label">
              {brand?.title}
            </h1>
          )}
          {title && <h2 className="card-title">{title}</h2>}
          {subTitle && <p className="offer-sub font-bold">{subTitle}</p>}
        </ModalPopupHeader>
        <ModalPopupBody>
          {(description || details) && detailsLabel && (
            <p>
              <strong>{detailsLabel} </strong>
              {description || details}
            </p>
          )}

          {(cta || cTALink || ctaLink) && (
            <LinkComponent
              href={cta?.href || cTALink?.href || ctaLink?.href}
              target={cta?.target || cTALink?.target || ctaLink?.target}
              style={{ fontSize: '16px', maxWidth: 'inherit' }}
              buttonLoadingText={loadingLabel && loadingLabel}
              buttonStyle="primary-button"
            >
              {cta?.text || cTALink?.text || ctaLink?.text}
            </LinkComponent>
          )}
        </ModalPopupBody>
        {termsAndConditions && (
          <TermsComponent termsBlob={termsAndConditions} scrollable termsAndConditionsLabel={termsAndConditionsLabel} />
        )}
      </ModalPopupContainer>
    );
  }
  return null;
}

SpotlightModalContents.propTypes = {
  detailsLabel: PropTypes.string,
  termsAndConditionsLabel: PropTypes.string,
  content: PropTypes.object,
};
