import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { validateParamsPresent } from '../../../helpers/navigationHelpers';

export default function useCustomRedirect({ params, redirectURI }) {
  const [show, setShow] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const url = router.asPath;

    const hasProperParams = validateParamsPresent({ params: params || [], url });

    if (hasProperParams) {
      setShow(true);
    } else {
      router.push(redirectURI || '/');
    }
  }, [router]);

  return show;
}
