import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import FAQContent from '../site-pages/faq/FAQContent';

const SharedFAQSections = ({ module, customData, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const showFAQ = (isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true');

  if (showFAQ) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <FAQContent customData={customData} fields={fields} languageCode={languageCode} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedFAQSections.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'faqpagecontent',
      languageCode,
    });

    let contentItem = contentItemList && contentItemList[0];
    contentItem = await expandLinkedList({
      agility,
      contentItem,
      languageCode,
      fieldName: 'faqCategories',
      sortIDField: 'faqCategories_ValueField',
    });

    const faqData = [];
    const selectedCategories = contentItem?.fields?.faqCategories_ValueField.includes(',')
      ? contentItem?.fields?.faqCategories_ValueField.split(',')
      : [contentItem?.fields?.faqCategories_ValueField];

    for (const category of contentItem.fields.faqCategories) {
      if (selectedCategories.includes(category.contentID.toString())) {
        const faqItems = await api.getContentList({
          referenceName: category.fields.categoryQAList.referencename,
          languageCode,
        });

        faqItems.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

        const section = {
          title: category.fields.title,
          accordionList: faqItems.map((value) => ({
            contentID: value.contentID,
            fields: {
              title: value.fields.question,
              content: value.fields.answer,
            },
          })),
        };

        faqData.push(section);
      }
    }

    return { faqData };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default SharedFAQSections;

SharedFAQSections.propTypes = { module: PropTypes.object, customData: PropTypes.object, languageCode: PropTypes.string };
