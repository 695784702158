import { useRouter } from 'next/router';

// Resolves query or returns null
export default function useQuery() {
  const router = useRouter();
  const hasQueryParams = /\[.+\]/.test(router.route) || /\?./.test(router.asPath);
  const hasOnlySlugParam = Object.keys(router.query).filter((param) => param !== 'slug').length === 0;
  const ready = !hasQueryParams || !hasOnlySlugParam;

  if (!ready) return null;

  return router.query;
}
