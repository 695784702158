/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import { selectStyles, customErrorStyles } from './selectStyles';
import { FormInputLabel } from '../FormStyles';
import FieldLayout from '../../layouts/FieldLayout';
// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';

function FormSelectProvince({
  name,
  label,
  defaultOption,
  options,
  rules,
  placeholder,
  hideError,
  addressModal = false,
  gaFlow = '',
}) {
  const [inputVal, setInputVal] = useState('');
  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { control, setValue, formState } = formContext;
  const error = formState?.errors[name];

  useEffect(() => {
    if (inputVal || defaultOption) {
      setValue(name, inputVal || defaultOption.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(name, null);
    }
  }, [inputVal]);

  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const selectOptions =
    options &&
    options.map((a) => ({
      value: a.value,
      label: <div className="select-options">{a.label}</div>,
    }));

  const handleChange = (value) => {
    if (value) {
      setInputVal(value);
    }
  };

  /** TODO: Figure out bug with aria-labelledby replacing label value while label referencing same id
   * <label id={`form-${name}`} htmlFor={name}>{label}</label>
   */

  return (
    <FieldLayout addressModal={addressModal}>
      {Boolean(label) && (
        <FormInputLabel addressModal={addressModal} htmlFor={name}>
          {label}
        </FormInputLabel>
      )}
      <div className="select-wrapper">
        <Controller
          control={control}
          name={name}
          defaultValue={defaultOption || null}
          rules={{
            ...rules,
          }}
          render={({
            field: { onChange, onBlur, name, ref, value },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Select
              inputRef={ref}
              isSearchable={false}
              defaultValue={defaultOption || null}
              aria-invalid={!!error}
              aria-labelledby={`form-${name}`}
              placeholder={placeholder}
              className="form-select-container"
              classNamePrefix={`${
                (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''
              }`}
              options={selectOptions}
              value={selectOptions ? selectOptions.find((c) => c.value === value) : null}
              onChange={(val) => handleChange(val.value)}
              styles={isError ? { ...selectStyles, ...customErrorStyles } : selectStyles}
              onFocus={() => {
                if (gaFlow === 'register' || gaFlow === 'registerGK') {
                  const dataLayer = {
                    event: 'sign_up_form_field',
                    category: 'Registration Events',
                    action: 'Form Field Click',
                    label: camelCaseToSentence(name),
                    sign_up_step: `Step 2${gaFlow === 'registerGK' ? ' GK' : ''}`,
                    sign_up_form_field: camelCaseToSentence(name),
                  };
                  handleGAEventsBase(dataLayer);
                }
              }}
            />
          )}
        />
      </div>
      {isError && !hideError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormSelectProvince.defaultProps = {
  addressModal: false,
  gaFlow: '',
};

FormSelectProvince.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultOption: PropTypes.object,
  options: PropTypes.array,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  hideError: PropTypes.bool,
  addressModal: PropTypes.bool,
  gaFlow: PropTypes.string,
};

export default FormSelectProvince;
