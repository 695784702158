import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';
import { CustomStyledButton } from './ButtonStyles';
import { enableScrolling } from '../../../helpers/behavourHelper';

const RegisterGKButton = ({
  type,
  href,
  children,
  className,
  buttonLoadingText,
  buttonStyle,
  onClick,
  disabled,
  submitting,
  style,
  role,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaExpanded,
  extraSpacingTop,
}) => {
  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const { state } = useStateMachine();

  // Default behaviour, can be overloaded by OnClick prop
  const handleClick = () => {
    // setLoading(!loading)
    enableScrolling();
    if (href) {
      router.push(href);
    }
  };

  return (
    <CustomStyledButton
      type={type}
      className={`w-full inline-block ${className || ''} ${buttonStyle || 'primary-button'} 
      ${submitting || loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick || handleClick}
      disabled={submitting || loading ? submitting : disabled}
      aria-disabled={!!(submitting || loading || disabled)}
      style={style}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      role={role}
      extraSpacingTop={extraSpacingTop}
    >
      <span>{submitting || loading ? buttonLoadingText || state.labels.loadingLabel : children}</span>
    </CustomStyledButton>
  );
};

RegisterGKButton.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  buttonLoadingText: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  style: PropTypes.object,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  extraSpacingTop: PropTypes.bool,
};
export default RegisterGKButton;
