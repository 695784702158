import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../Images/ExternalImage';

import { CardAvatarWrapper, CardAvatar, CardApplyItem, NoCardContents, InnerColumnBox } from '../../Points/Shop/PointsShopStyles';

export default function CardApplyItemList({ bankCards, className }) {
  if (bankCards && bankCards.length) {
    return (
      <div className={className}>
        {bankCards.map((card, index) => (
          <CardApplyItem key={index} className="flex-1">
            <InnerColumnBox className="flex flex-1 md:items-center ">
              <CardAvatarWrapper>
                <CardAvatar>
                  {card?.cardImage?.url && (
                    <ExternalImage url={card?.cardImage?.url} title={card.cardTitle} width={73} height={73} />
                  )}
                </CardAvatar>
              </CardAvatarWrapper>
              <NoCardContents>
                {card.cardTitle && <h2 className="font-bold text-sm mb-2">{card.cardTitle}</h2>}
                {card.cardMessage && <p className="text-sm mb-2">{card.cardMessage}</p>}
                <Link href={card.cardLink.href} title={card.cardLinkLabel} target={card.cardLink.target}>
                  {card.cardLink.text}
                </Link>
              </NoCardContents>
            </InnerColumnBox>
          </CardApplyItem>
        ))}
      </div>
    );
  }
  return null;
}
CardApplyItemList.propTypes = {
  bankCards: PropTypes.array.isRequired,
};
