import PropTypes from 'prop-types';

// State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';
import Title from '../common/Headings/Title';
import { TitleWrapper } from '../common/Headings/TitleStyles';
import OnboardingMobileAnchorElement from '../Onboarding/OnboardingMobileAnchorElement';

const PageHeader = ({ module, customData, page }) => {
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout
        className={fields.isShadow ? 'bottom-shadow' : ''}
        isBlack={fields.headerBackgroundColor === 'isBlack'}
        isGray={fields.headerBackgroundColor === 'isGray'}
        mobileIsWhite={fields.headerBackgroundColor === 'isGray'}
      >
        <div className="global-content-wrapper">
          {customData?.sitemap && <Breadcrumbs customData={customData?.sitemap} />}

          {fields.title && (
            <TitleWrapper id={`page-header-${page.name}`} className="relative">
              <Title>{fields.title}</Title>

              <OnboardingMobileAnchorElement />
            </TitleWrapper>
          )}
        </div>
      </WrapperLayout>
    );
  }

  return null;
};

PageHeader.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  // set up api
  const api = agility;

  try {
    // get sitemap...
    const sitemap = await api.getSitemap({
      channelName,
      languageCode,
    });

    return {
      sitemap,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

PageHeader.propTypes = { module: PropTypes.object, customData: PropTypes.object, page: PropTypes.object };

export default PageHeader;
