import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 999999;
  transform: translateY(-50%);
  overflow-y: auto;
  max-height: 95vh;
  max-width: 36.75rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.11);

  ${(props) =>
    props.type === 'modal-info' &&
    css`
      padding: 0.75rem 0.75rem 4.5rem;
      text-align: left;
      @media screen and (max-width: 640px) {
        padding: 1.125rem;
        transform: none;
        border-radius: 1.5rem 1.5rem 0 0;
        width: 100%;
        top: 5rem;
        bottom: 0;
      }
      @media screen and (min-width: 641px) {
        border-radius: 0.5rem;
      }
    `};

  ${(props) =>
    props.type === 'modal-confirmation' &&
    css`
      padding: 0px;
      button.primary-button {
        width: 100%;
        max-width: 100%;
      }
      @media screen and (max-width: 640px) {
        border-radius: 1.5rem 1.5rem 0 0;
        transform: none;
        width: 100%;
        top: 5rem;
        bottom: 0;
      }
      @media screen and (min-width: 641px) {
        border-radius: 0.5rem;
      }
    `};

  ${(props) =>
    props.type === 'filter' &&
    css`
      padding: 0px;
      height: 90vh;
      margin-top: 0;
      max-height: 95vh;
    `};

  ${(props) =>
    props.type === 'modal-province' &&
    css`
      padding: 0px;
      max-width: 75rem;
      @media screen and (max-width: 640px) {
        padding: 1.125rem;
        transform: none;
        border-radius: 1.5rem 1.5rem 0 0;
        width: 100%;
        top: 5rem;
        bottom: 0;
      }
      @media screen and (min-width: 641px) {
        border-radius: 0.5rem;
      }
      @media screen and (min-width: 641px) and (max-width: 1439px) {
        right: 2.125rem;
        left: 2.125rem;
      }
    `};
`;
