import styled from 'styled-components';

export const GreetingHeading = styled.h1`
  font-weight: bold;
  flex: 0 0 45%;
  margin-bottom: 1.5rem;
  font-size: ${(props) => props.theme.headingExtraSmall};
  line-height: ${(props) => props.theme.lineHeight1};
  z-index: 1;
  span {
    font-weight: normal;
  }

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-bottom: 0;
    font-size: ${(props) => props.theme.headingMedium};
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
    font-size: ${(props) => props.theme.headingLarge};
  }
`;
export const PointsCounter = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
`;
export const GreetingsGrid = styled.div`
  @media screen and (min-width: 641px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const PointsLabel = styled.div`
  font-size: ${(props) => props.theme.fontLegal};
  border-radius: 2px;
  padding: 0.35rem;
  text-transform: uppercase;
  margin-left: 0.25rem;
  font-weight: bold;
  background-color: ${(props) => props.theme.uiPurple};
  vertical-align: middle;
`;
export const BalanceTicker = styled.div`
  position: relative;
  font-size: 2.5rem;
  margin-right: 1rem;
  font-weight: bold;

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    font-size: 2.25rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 4.5rem;
  }

  .counter {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .number {
    position: relative;
    width: 0.9em;
    height: 1.25em;

    span {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      text-align: center;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      color: ${(props) => props.theme.whiteColor};
      perspective: 7.14em;
      border: 1px solid ${(props) => props.theme.whiteColor};

      &:before,
      &:after {
        content: '';
        width: 100%;
        display: block;
        height: 0.625em;
        overflow: hidden;
        background-color: ${(props) => props.theme.uiBlack};
      }

      &:before {
        line-height: ${(props) => props.theme.lineHeight2};
      }

      &:after {
        line-height: 0.04;
      }
    }
  }

  .points-negative {
    border: 0 !important;
  }

  .flip {
    .primary {
      &:before {
        content: '';
        animation: animate-top 250ms;
        -moz-animation-fill-mode: forwards;
        -webkit-animation: animate-top 250ms;
        -webkit-animation-fill-mode: forwards;
      }

      &:after {
        content: '';
        animation: animate-bottom 250ms;
        -moz-animation-fill-mode: forwards;
        animation-delay: 250ms;
        -webkit-animation: animate-bottom 250ms;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-delay: 250ms;
      }
    }
  }

  .primary {
    z-index: 2;

    &:after {
      content: '';
      opacity: 0;
      transform: translateX(0) translateY(-0.28em) translateZ(0.35em) rotateX(91deg) rotateY(0deg) rotateZ(0deg);
      -webkit-transform: translateX(0) translateY(-0.28em) translateZ(0.35em) rotateX(91deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  .secondary {
    z-index: 1;
  }

  /* Number 0 */

  [data-number='0'] span.primary:before {
    content: '0';
  }

  [data-number='0'] span.secondary:after {
    content: '0';
  }

  [data-number='0'] span.secondary:before {
    content: '1';
  }

  [data-number='0'] span.primary:after {
    content: '1';
  }

  /* Number 9 */

  [data-number='9'] span.primary:before {
    content: '9';
  }

  [data-number='9'] span.secondary:after {
    content: '9';
  }

  [data-number='9'] span.secondary:before {
    content: '0';
  }

  [data-number='9'] span.primary:after {
    content: '0';
  }

  /* Number 8 */

  [data-number='8'] span.primary:before {
    content: '8';
  }

  [data-number='8'] span.secondary:after {
    content: '8';
  }

  [data-number='8'] span.secondary:before {
    content: '9';
  }

  [data-number='8'] span.primary:after {
    content: '9';
  }

  /* Number 7 */

  [data-number='7'] span.primary:before {
    content: '7';
  }

  [data-number='7'] span.secondary:after {
    content: '7';
  }

  [data-number='7'] span.secondary:before {
    content: '8';
  }

  [data-number='7'] span.primary:after {
    content: '8';
  }

  /* Number 6 */

  [data-number='6'] span.primary:before {
    content: '6';
  }

  [data-number='6'] span.secondary:after {
    content: '6';
  }

  [data-number='6'] span.secondary:before {
    content: '7';
  }

  [data-number='6'] span.primary:after {
    content: '7';
  }

  /* Number 5 */

  [data-number='5'] span.primary:before {
    content: '5';
  }

  [data-number='5'] span.secondary:after {
    content: '5';
  }

  [data-number='5'] span.secondary:before {
    content: '6';
  }

  [data-number='5'] span.primary:after {
    content: '6';
  }

  /* Number 4 */

  [data-number='4'] span.primary:before {
    content: '4';
  }

  [data-number='4'] span.secondary:after {
    content: '4';
  }

  [data-number='4'] span.secondary:before {
    content: '5';
  }

  [data-number='4'] span.primary:after {
    content: '5';
  }

  /* Number 3 */

  [data-number='3'] span.primary:before {
    content: '3';
  }

  [data-number='3'] span.secondary:after {
    content: '3';
  }

  [data-number='3'] span.secondary:before {
    content: '4';
  }

  [data-number='3'] span.primary:after {
    content: '4';
  }

  /* Number 2 */

  [data-number='2'] span.primary:before {
    content: '2';
  }

  [data-number='2'] span.secondary:after {
    content: '2';
  }

  [data-number='2'] span.secondary:before {
    content: '3';
  }

  [data-number='2'] span.primary:after {
    content: '3';
  }

  /* Number 1 */

  [data-number='1'] span.primary:before {
    content: '1';
  }

  [data-number='1'] span.secondary:after {
    content: '1';
  }

  [data-number='1'] span.secondary:before {
    content: '2';
  }

  [data-number='1'] span.primary:after {
    content: '2';
  }

  @keyframes animate-top {
    0% {
      background: #3e3e3e; /* Old browsers */
      background: -moz-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* FF3.6+ */
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #3e3e3e),
        color-stop(100%, #2b2b2b)
      ); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* IE10+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3e3e', endColorstr='#2b2b2b',GradientType=0 );
    }

    100% {
      color: #474747;
      -webkit-transform: translateX(0px) translateY(0.35em) translateZ(5px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
      transform: translateX(0px) translateY(0.35em) translateZ(5px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes animate-bottom {
    0% {
      color: #474747;
      opacity: 1;
    }

    100% {
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      transform: translateX(0) translateY(0) translateZ(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);

      opacity: 1;
    }
  }

  @-webkit-keyframes animate-top {
    0% {
      background: #3e3e3e; /* Old browsers */
      background: -moz-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* FF3.6+ */
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #3e3e3e),
        color-stop(100%, #2b2b2b)
      ); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* IE10+ */
      background: transparent; /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3e3e', endColorstr='#2b2b2b',GradientType=0 );
    }

    100% {
      color: #474747;

      -webkit-transform: translateX(0px) translateY(0.35em) translateZ(5px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @-webkit-keyframes animate-bottom {
    0% {
      color: #474747;
      opacity: 1;
    }

    100% {
      -webkit-transform: translateX(0) translateY(0) translateZ(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);

      opacity: 1;
    }
  }
`;
