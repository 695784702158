/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import { useWindowWidth } from '@react-hook/window-size';

// Components
import OfferItem from './OfferItem';
import Button from '../../common/Button/Button';
import WrapperLayout from '../../layouts/WrapperLayout';

import { SectionTitle, SectionSubtitle, MoreOffersWrapper } from '../OfferStyles';
import OffersModal from '../OffersModal';

// Hooks
import useModal from '../../hooks/utils/useModal';
import { handleKeyDown } from '../../../helpers/utils';

export default function MoreOffers({ customData, title, message, filteredList, languageCode, isGray, customStyles }) {
  const [showMoreCount, setShowMoreCount] = useState(0);
  const [visibleOffers, setVisibleOffers] = useState([]);

  const { isShowing, toggle, currentItemId } = useModal();
  const viewPortWidth = useWindowWidth();

  const { viewMoreLabel, evergreenOfferIcons } = customData;

  // BreakPoints
  const tablet = viewPortWidth < 769 && viewPortWidth > 640;
  const mobile = viewPortWidth < 641;
  const desktop = viewPortWidth > 769;
  // View more button
  const showMore = viewPortWidth < 769 && visibleOffers.length !== filteredList?.length;
  const mobileIncrement = 3;
  const tabletIncrement = 6;

  function offersCount() {
    let offersNum = 0;

    if (mobile) {
      offersNum += mobileIncrement;
    } else if (tablet) {
      offersNum += tabletIncrement;
    }

    if (showMoreCount && (mobile || tablet)) {
      offersNum += showMoreCount;
    }

    return offersNum;
  }

  function updateVisibleOffersList() {
    const limit = offersCount();

    if (mobile) {
      setVisibleOffers(filteredList.slice(0, limit));
    } else if (tablet) {
      setVisibleOffers(filteredList.slice(0, limit));
    } else if (desktop) {
      setVisibleOffers(filteredList);
    }
  }

  const handleViewMore = (e) => {
    e.preventDefault();
    const allOffersVisible = visibleOffers.length === filteredList.length;
    // desktop by default shows all offers
    if (allOffersVisible) return;
    if (mobile) {
      setShowMoreCount(showMoreCount + mobileIncrement);
    } else {
      setShowMoreCount(showMoreCount + tabletIncrement);
    }
  };

  useEffect(() => {
    if (filteredList && filteredList.length) {
      const offersNum = offersCount();

      if (!visibleOffers.length) {
        updateVisibleOffersList();
      } else if (visibleOffers.length && visibleOffers.length !== offersNum) {
        updateVisibleOffersList();
      }
    }
  }, [filteredList, viewPortWidth]);

  useEffect(() => {
    const notMaxedOut = !(showMoreCount >= filteredList?.length);
    if (showMoreCount && notMaxedOut) {
      updateVisibleOffersList();
    }
  }, [showMoreCount]);

  return (
    <WrapperLayout className="global-content-wrapper" isGray={isGray} customStyles={customStyles}>
      <>
        {title && <SectionTitle>{title}</SectionTitle>}
        <SectionSubtitle dangerouslySetInnerHTML={renderHTML(message)} />

        <MoreOffersWrapper>
          {visibleOffers.length &&
            visibleOffers.map((offer, index) => (
              <OfferItem
                className="offer-link"
                toggle={toggle}
                key={`more-offers-${index}`}
                content={offer}
                icons={evergreenOfferIcons}
                listName="More offers"
                id={`card-${index}`}
              />
            ))}
        </MoreOffersWrapper>

        {showMore && (
          <div className="flex content-center mt-6 w-full sm:max-w-md mx-auto">
            <Button
              type="button"
              ariaLabel={viewMoreLabel}
              className="w-full mx-auto view-more button-large"
              buttonStyle="secondary-button-outline-purple"
              style={{ width: '100%' }}
              onClick={handleViewMore}
              onKeyDown={(e) => handleKeyDown(e, handleViewMore)}
            >
              {viewMoreLabel}
            </Button>
          </div>
        )}
      </>

      <OffersModal
        isShowing={isShowing}
        toggle={toggle}
        currentItemId={currentItemId}
        offersList={filteredList}
        customData={customData}
        languageCode={languageCode}
        listName="More offers"
      />
    </WrapperLayout>
  );
}
MoreOffers.propTypes = {
  customData: PropTypes.object,
  title: PropTypes.string,
  languageCode: PropTypes.string,
  message: PropTypes.string,
  filteredList: PropTypes.array,
  isGray: PropTypes.bool,
  customStyles: PropTypes.string,
};
