/* eslint-disable react/no-danger */

import { ContentStyle } from './TravelServiceContentStyles';

export default function TravelServiceContent({ data }) {
  if (data) {
    return data.map((item, index) => (
      <section key={index} id={item.fields.anchorLink ? item.fields.anchorLink : item.contentID}>
        <h3 className="inner-header-purple" dangerouslySetInnerHTML={{ __html: item.fields.title }} />
        <ContentStyle className="mt-5" dangerouslySetInnerHTML={{ __html: item.fields.content }} />
      </section>
    ));
  }
  return null;
}
