import styled from 'styled-components';

export const StyledLink = styled.div`
  margin-bottom: 2px;
  ${(props) => (props.isHome ? 'margin-top: 0  !important' : '')};
  ${(props) => (props.bannersNum === 2 ? 'display: none !important' : '')};

  &.color-black {
    a {
      color: ${(props) => props.theme.uiCharcoal} !important;

      span {
        &:after {
          border-bottom: 1px solid ${(props) => props.theme.uiCharcoal} !important;
        }
      }
    }
  }
  &.mobile-w-full {
    a.primary-button {
      @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-left: 0;
      }
    }
  }
  a {
    display: inline-block;
    width: auto;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    color: ${(props) => props.theme.whiteColor};
    transition: all 0.6s ease-in;

    span {
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid ${(props) => props.theme.whiteColor};
        transition: width 0.3s;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;

      span {
        &:after {
          width: 0;
        }
      }
    }
  }

  .primary-button {
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 0;
    border: 0;
    padding: 1rem 2rem;
    text-decoration: none;
    overflow: hidden;

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.DarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in 0s;
    }
    span {
      color: ${(props) => props.theme.whiteColor};
      font-weight: bold;
      position: relative;
      z-index: 1;
      &:after {
        border: 0 !important;
      }
    }

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiDarkPurple};
    }

    &:focus {
      outline: 3px solid ${(props) => props.theme.uiPurple};
      background-color: ${(props) => props.theme.uiDarkPurple};
      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.disabled {
      color: ${(props) => props.theme.uiGrey04};
      background-color: ${(props) => props.theme.lightGrayColor3};

      &:hover {
        &:after {
          width: 0px;
          height: 0px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding: 1rem 2rem;
    }
  }

  &.secondary-button {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiPurple};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiPurple};

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiDarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in;
    }

    &:hover {
      color: ${(props) => props.theme.uiDarkPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      color: ${(props) => props.theme.whiteColor};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }
  }

  .secondary-button-red {
    background-color: ${(props) => props.theme.uiRed};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 0;
    border: 0;
    padding: 1rem 2rem;
    text-decoration: none;
    overflow: hidden;

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiErrorRed};
      transform: translateY(-50%);
      transition: all 0.6s ease-in 0s;
    }
    span {
      color: ${(props) => props.theme.whiteColor};
      font-weight: bold;
      position: relative;
      z-index: 1;
      &:after {
        border: 0 !important;
      }
    }

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiErrorRed};
    }

    &:focus {
      outline: 3px solid ${(props) => props.theme.uiPurple};
      background-color: ${(props) => props.theme.uiErrorRed};
      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
`;
export const StyledBannerLink = styled.div`
  ${(props) => (props.bannersNum === 2 ? 'display: block !important' : '')};
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;

  @media screen and (min-width: 768px) {
    display: none;
  }

  a {
    display: inline-block;
    width: auto;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.fontLegal};
    padding: 10px 15px;

    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.fontBody3};
    }

    span {
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid ${(props) => props.theme.whiteColor};
        transition: width 0.3s;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;

      span {
        &:after {
          width: 0;
        }
      }
    }
  }
`;

export const StyledPrimaryLink = styled.div`
  margin-bottom: 4rem;

  a {
    display: inline-block;
    width: auto;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: ${(props) => props.theme.uiPurple};
    text-decoration: none;
    text-align: center;
    color: ${(props) => props.theme.whiteColor};
    padding: 1rem 2rem;
    font-weight: bold;
    border: 1px solid ${(props) => props.theme.uiPurple};
    outline: none;
    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiDarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiDarkPurple};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: none;
    }

    &:focus {
      background-color: ${(props) => props.theme.uiDarkPurple};
      outline: 3px solid ${(props) => props.theme.uiPurple};
      border: 1px solid ${(props) => props.theme.uiPurple};
      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
    a {
      display: block;
    }
  }
`;
