import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import useIntersectionObserver from '../../hooks/utils/useIntersectionObserver';

import { updateOrder } from '../../../store/actions';
import useOrders from '../../hooks/orders/useOrders';

import Table from '../../common/Table/Table';
import EmptyOrder from '../EmptyOrder';
import OrderItems from './OrderItems';
import OrderRows from './OrderRows';
import Loader from '../../common/Loader/Loader';
import { MobileOrderItem, OrdersListWrapper } from '../OrderStyles';

export default function OrdersList({ customData, languageCode }) {
  const { awardsTypes, ordersContent } = customData;
  const tableHeader = [
    ordersContent?.orderDateLabel,
    ordersContent?.typeTableHeader,
    ordersContent?.orderNumberLabel,
    ordersContent?.itemsTableHeader,
  ];

  const {
    actions,
    state: {
      order: {
        selectedPeriod: { firstDate, secondDate },
      },
      session: { accessToken },
    },
  } = useStateMachine({ updateOrder });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useOrders(
    accessToken,
    firstDate,
    secondDate,
    10
  );

  useEffect(() => {
    if (data) {
      actions.updateOrder({
        orders: data,
      });
      refetch();
    }
  }, [data]);

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (isLoading) return <Loader />;

  return (
    <OrdersListWrapper>
      <MobileOrderItem className="mobile-orders block md:hidden">
        {data && data?.pages && data?.pages[0]?.items?.length && data?.pages?.length ? (
          data?.pages.map((page, index) => (
            <OrderItems
              key={index}
              page={page}
              awardsTypes={awardsTypes}
              ordersContent={ordersContent}
              languageCode={languageCode}
              sectionTitle={ordersContent?.ordersPageHeader}
            />
          ))
        ) : (
          <EmptyOrder ordersContent={ordersContent} showButton />
        )}
      </MobileOrderItem>

      <div className="orders-list hidden md:block">
        {data && data?.pages && data?.pages[0]?.items?.length && data?.pages?.length ? (
          <Table columns={tableHeader}>
            {data &&
              data?.pages &&
              data?.pages.map(
                (page, index) =>
                  page?.items.length > 0 && (
                    <OrderRows
                      key={index}
                      page={page}
                      awardsTypes={awardsTypes}
                      ordersContent={ordersContent}
                      isLoading={isLoading}
                      tableHeader={tableHeader}
                      languageCode={languageCode}
                    />
                  )
              )}
          </Table>
        ) : (
          <EmptyOrder ordersContent={ordersContent} showButton />
        )}
      </div>

      {hasNextPage && <div ref={loadMoreRef}>{isFetchingNextPage ? <Loader /> : ''}</div>}
    </OrdersListWrapper>
  );
}
OrdersList.propTypes = {
  languageCode: PropTypes.string,
  customData: PropTypes.object,
};
