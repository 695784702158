import PropTypes from 'prop-types';

import Link from 'next/link';
import { useStateMachine } from 'little-state-machine';
import useDragDetection from '../../hooks/utils/useDragDetection';

// Global State
import { updateGiftCards } from '../../../store/actions';

import { handleGaEcommImpression } from '../../../helpers/handleGoogleAnalyticsHelper';
import { formatPoints } from '../../../helpers/pointsHelpers';
import { findSelectedGiftCard } from '../../../helpers/giftCardsHelper';

import { CardItem, CardImage, CardBrandName, CardBody, Points } from './GeneralGiftCardStyles';

export default function GeneralGiftCard({
  primaryImage,
  brandName,
  minValue,
  maxValue,
  ptsLabel,
  gcList,
  slider,
  brandId,
  link,
  isGrid,
  isCategoryPage,
  itemListName,
  id,
}) {
  const {
    actions,
    state: {
      giftCards: { giftCardsList, redeem },
      language: { locale },
    },
  } = useStateMachine({ updateGiftCards });

  const { handleMouseDown, dragging } = useDragDetection();

  // eslint-disable-next-line no-param-reassign
  if (!isCategoryPage) itemListName = 'Gift Cards';

  const handleGiftCardSelected = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    const selectedGiftCard = findSelectedGiftCard(giftCardsList, brandId);
    const selectedGiftCardIndex = parseInt(id?.split('-')[1]) + 1;
    actions.updateGiftCards({
      selectedGiftCard,
      selectedGiftCardIndex,
    });

    handleGaEcommImpression('gc_select_item', {
      alt_event: 'redeem_giftcard_interaction',
      eventCategory: 'Gift Cards',
      eventAction: 'Gift Card Detail View',
      eventLabel: `${selectedGiftCard?.brandName} GC`,
      partner: selectedGiftCard?.brandName,
      reward_name: `${selectedGiftCard?.brandName} GC`,
      category: 'Gift Card',
      list_name: itemListName,
      tile_position: selectedGiftCardIndex,
      items: [
        {
          item_list_name: itemListName,
          item_id: selectedGiftCard?.brandId,
          item_name: selectedGiftCard?.brandName,
          affiliation: selectedGiftCard?.brandName,
          currency: 'CAD',
          discount: 0,
          index: selectedGiftCardIndex,
          item_brand: selectedGiftCard?.brandName,
          item_category: 'Gift Cards',
          item_category2: selectedGiftCard?.cmsContent?.category?.name,
          quantity: redeem?.quantity,
        },
      ],
    });
  };

  return (
    <Link
      href={{
        pathname: link.href,
        query: { brand: brandId },
      }}
      legacyBehavior
    >
      <CardItem
        offersNum={gcList && gcList.length}
        isGrid={isGrid}
        slider={slider}
        onMouseDownCapture={handleMouseDown}
        onClick={handleGiftCardSelected}
      >
        <div className="card-content-wrapper">
          <a
            aria-label={`${link.text} - ${brandName}: ${formatPoints(minValue, locale)}, ${formatPoints(
              maxValue,
              locale
            )} points`}
          >
            <CardImage
              offersNum={gcList && gcList.length}
              isGrid={isGrid}
              isCategoryPage={isCategoryPage}
              src={primaryImage.assets[2].url}
              alt={brandName}
            />
            <CardBody isGrid={isGrid}>
              {brandName && <CardBrandName className="font-bold">{brandName}</CardBrandName>}
              <Points className="text-xs m-0 font-bold">
                {formatPoints(minValue, locale)}
                {minValue !== maxValue ? ` - ${formatPoints(maxValue, locale)} ${ptsLabel}` : ` ${ptsLabel}`}
              </Points>
            </CardBody>
          </a>
        </div>
      </CardItem>
    </Link>
  );
}

GeneralGiftCard.propTypes = {
  primaryImage: PropTypes.object,
  slider: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  brandName: PropTypes.string,
  ptsLabel: PropTypes.string,
  gcList: PropTypes.array,
  brandId: PropTypes.string,
  link: PropTypes.object,
  isGrid: PropTypes.bool,
  isCategoryPage: PropTypes.bool,
  itemListName: PropTypes.string,
  id: PropTypes.string,
};
