import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 0;
`;

export default function MultipleFieldsLayout({ style, children }) {
  return <Wrapper style={style}>{children}</Wrapper>;
}

MultipleFieldsLayout.propTypes = { children: PropTypes.node, style: PropTypes.object };
