import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions } from '../../../../store/actions';

import Loader from '../../../common/Loader/Loader';

import { TransactionsPFC, TransactionsModals } from '../Transactions';
import TransactionsBYOT from './byot/TransactionsBYOT'; // V2

export default function TransactionList({ page, customData, isShowing, toggle, currentItemId, languageCode }) {
  const { selectAmountLabel } = customData;

  const {
    state: {
      transactions: { cards, selectedCard },
    },
  } = useStateMachine({ updateTransactions });

  if (cards && selectedCard?.id) {
    if (page === 'byot') {
      return (
        <div>
          <TransactionsBYOT customData={customData} languageCode={languageCode} />

          {isShowing && cards.length > 0 && (
            <TransactionsModals customData={customData} currentItemId={currentItemId} toggle={toggle} isShowing={isShowing} />
          )}
        </div>
      );
    }

    if (page === 'pfc') {
      return (
        <div>
          <div>
            <TransactionsPFC
              selectAmountLabel={selectAmountLabel}
              page={page}
              customData={customData}
              languageCode={languageCode}
            />
          </div>

          {isShowing && cards.length > 0 && (
            <TransactionsModals customData={customData} currentItemId={currentItemId} toggle={toggle} isShowing={isShowing} />
          )}
        </div>
      );
    }

    return null;
  }

  return <Loader />;
}

TransactionList.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
  isShowing: PropTypes.bool,
  toggle: PropTypes.func,
  currentItemId: PropTypes.string,
  languageCode: PropTypes.string,
};
