import PropTypes from 'prop-types';

import ModalLayout from '../../layouts/ModalLayout';
import Portal from '../Portal/Portal';

export default function PortalModal({ isShowing, toggle, children, style, hideCloseIcon, type }) {
  if (isShowing) {
    return (
      <Portal>
        {isShowing && <span className="overlay" />}
        <ModalLayout toggle={toggle} className="p-0 bg-white" hideCloseIcon={hideCloseIcon} style={style} type={type}>
          {children}
        </ModalLayout>
      </Portal>
    );
  }
  return null;
}

PortalModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.object,
  hideCloseIcon: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
};
PortalModal.defaultProps = {
  hideCloseIcon: true,
};
