/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

// LSM
import { useStateMachine } from 'little-state-machine';
import updatePoints from '../../../../../store/actions/updatePointsAction';

// Components
import PointsRowWarning from './PointsRowWarning'; // V2

// Helpers
import { formatDate } from '../../../../../helpers/dateHelpers';
import { formatToCurrency } from '../../../../../helpers/utils';
import { handleCreditRedeemEvent } from '../../../../../helpers/handleGoogleAnalyticsHelper';
import { calcPointsNeeded, calcPointsTotal, formatPoints } from '../../../../../helpers/pointsHelpers';

// Styles
import { Row, TableRowItem } from '../../../../common/Table/TableStyles';
import { Wrapper } from '../../PointsShopStyles';

export default function PointsListRow({
  name,
  fieldType,
  rules,
  isDisabled,
  transaction,
  customData,
  languageCode,
  warning,
  ignoreError,
}) {
  const { partialRedemptionLabel } = customData || {};
  const {
    state: {
      points: { added, lastAdded },
      transactions: { balance },
    },
  } = useStateMachine();

  const { id, pointDate, transactionDescription, transactionAmount, points } = transaction || {};
  const { control, setValue } = useFormContext();

  const { actions } = useStateMachine({ updatePoints });
  const [checkbox, setCheckbox] = useState(false);

  const total = calcPointsTotal(added);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;

  const checkboxData = {
    id,
    name,
    transactionDescription,
    transactionAmount,
    points,
  };

  useEffect(() => {
    setValue(name, checkbox, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox]);

  const disableInput = (checked) => {
    const pointsNeeded = calcPointsNeeded(added, balance);
    const partialRedemption = balance - pointsNeeded < balance;

    if (isDisabled || balance === 0 || (partialRedemption && !checked) || (balance - total === 0 && !checked)) return true;

    return false;
  };

  const handleChange = (e, error) => {
    setCheckbox(e.target.checked);
    if (!error) {
      setValue(name, e.target.checked);
    } else {
      setValue(name, false);
    }

    if (e.target.checked) {
      actions.updatePoints({ added: [...added, checkboxData], lastAdded: id });

      handleCreditRedeemEvent(
        `${transactionDescription} ${transactionAmount}$ | ${points}pts`,
        'Points for Travel',
        'Points For Travel Select Amount',
        'select_content',
        id,
        'Points for Travel'
      );
    } else {
      let hardCopy = [...added];
      hardCopy = hardCopy.filter((cartItem) => cartItem.name !== name);

      actions.updatePoints({ added: hardCopy });
    }
  };

  return (
    <Wrapper role="rowgroup">
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: async (value) => {
            if (notEnoughPoints && value) {
              return partialRedemptionLabel;
            }
          },
        }}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { onBlur, name, ref, value }, fieldState: { error } }) => (
          <>
            <Row
              id={`transaction-row-${id}`}
              role="row"
              className="row-list"
              error={!ignoreError && !warning && error}
              warning={!ignoreError && warning && error}
            >
              <TableRowItem checkboxName center role="cell" className="table-checkbox">
                <input
                  name={name}
                  className="points-checkbox"
                  id={`transaction-checkbox-${id}`}
                  type={fieldType}
                  aria-invalid={!!error}
                  role="checkbox"
                  aria-checked={!!value}
                  aria-labelledby={`transaction-${id}-option-${name}`}
                  aria-disabled={disableInput(!!value)}
                  disabled={disableInput(!!value)}
                  onBlur={onBlur}
                  checked={!!value}
                  onChange={(e) => handleChange(e, error)}
                />
              </TableRowItem>
              <TableRowItem role="cell">{formatDate(pointDate, 'MMM, DD', languageCode)}</TableRowItem>
              <TableRowItem role="cell" className="font-bold" disable={disableInput(!!value)}>
                <label
                  id={`transaction-${id}-option-${name}`}
                  htmlFor={name}
                  style={{ margin: 0 }}
                  aria-disabled={disableInput(!!value)}
                >
                  {transactionDescription}
                </label>
              </TableRowItem>
              <TableRowItem role="cell">{formatToCurrency(transactionAmount, languageCode)}</TableRowItem>
              <TableRowItem role="cell">{formatPoints(points, languageCode)}</TableRowItem>
            </Row>

            {error && id === lastAdded && <PointsRowWarning warningLabel={partialRedemptionLabel} error={error} />}
          </>
        )}
      />
    </Wrapper>
  );
}

PointsListRow.propTypes = {
  name: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  isDisabled: PropTypes.bool,
  transaction: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  warning: PropTypes.bool,
  ignoreError: PropTypes.bool,
};
