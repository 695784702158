import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';

import { parseHashParams } from '../../../helpers/navigationHelpers';

export default function useGuestRedirect() {
  const router = useRouter();
  const {
    state: { user, navigation },
  } = useStateMachine();

  function handleHashURI(page, hashUri) {
    switch (page) {
      case 'offers':
        if (!user.isLoggedIn) {
          router.push({
            pathname: `/home`,
            query: parseHashParams(hashUri.substr(2)),
          });
          break;
        }
        break;

      default:
        // Do nothing on any other hash params page (ie: b2c etc)
        break;
    }
  }

  useEffect(() => {
    const url = router.asPath;
    const [page] = router.query?.slug || [];

    if (navigation.isReady && url.includes('#/')) {
      const hashUri = window.location.hash;

      handleHashURI(page, hashUri);
    }
  }, [router, navigation.isReady]);
}
