import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { updateLayout } from '../../../store/actions';

export default function useDynamicHeader(step = '') {
  // Global State
  const { actions } = useStateMachine({ updateLayout });

  useEffect(() => {
    // We update header layout conditionally here
    if (step && step > 1) {
      actions.updateLayout({ fullNav: false, isDark: false, hideFooter: true });
    }
    if (!step || step === undefined) {
      actions.updateLayout({ fullNav: true, isDark: false, hideFooter: false });
    }

    // When component will unmount, we set full nav back in
    return () => {
      actions.updateLayout({ fullNav: true, isDark: false, hideFooter: false });
    };
  }, [step]);
}
