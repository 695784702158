import { useState } from 'react';
import { disableScrolling, enableScrolling } from '../../../helpers/behavourHelper';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [currentItemId, setCurrentItemId] = useState();

  function toggle(id) {
    setIsShowing(!isShowing);
    if (id) {
      setCurrentItemId(id);
    }

    if (isShowing) {
      enableScrolling();
    } else {
      disableScrolling();
    }
  }

  return {
    isShowing,
    toggle,
    currentItemId,
  };
};

export default useModal;
