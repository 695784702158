/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { updateLanguageCode } from '../../../store/actions';

export default function useLanguageCode(languageCode) {
  const {
    actions,
    state: { language },
  } = useStateMachine({ updateLanguageCode });

  useEffect(() => {
    if (!language && languageCode) {
      actions.updateLanguageCode({ locale: languageCode });
    }
  }, [language]);

  useEffect(() => {
    if (languageCode && language && language?.locale !== languageCode) {
      actions.updateLanguageCode({ locale: languageCode });
    }
  }, [languageCode]);
}
