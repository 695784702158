/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export default function useIntersectionObserverGa({ enabled = true, onIntersect, target, scrolling, threshold = [1] }) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold,
  };
  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (scrolling) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        onIntersect(entry.isIntersecting);
      });
    }, options);
    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled, scrolling]);
}
