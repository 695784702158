/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import Title from '../../common/Headings/Title';
import Loader from '../../common/Loader/Loader';
import SettingsEmail from './Fields/SettingsEmail';
import SettingsSMS from './Fields/SettingsSMS';

// Styles
import { Section, SectionTitle, Disclaimer } from '../FormStyles';

export default function Settings({ customData, refetch }) {
  const { settingsPageTitle, preferencesHeader, settingsTermsMessage } = customData.ProfileSettingsForm;

  const {
    state: {
      user: { preferencesData },
    },
  } = useStateMachine();

  if (!preferencesData) return <Loader />;

  if (customData) {
    return (
      <Section>
        {settingsPageTitle && (
          <Title customStyles="profilePage" className="pt-0 mb-8">
            {settingsPageTitle}
          </Title>
        )}
        {preferencesHeader && (
          <SectionTitle profilePage className="mb-4 font-bold">
            {preferencesHeader}
          </SectionTitle>
        )}

        <SettingsEmail customData={customData.ProfileSettingsForm} errorsList={customData.errorsList} />
        <SettingsSMS customData={customData.ProfileSettingsForm} errorsList={customData.errorsList} refetch={refetch} />

        {settingsTermsMessage && (
          <Disclaimer
            className="px-4 sm:px-0 mt-8 "
            isSetting
            mobileSetting
            dangerouslySetInnerHTML={renderHTML(settingsTermsMessage)}
          />
        )}
      </Section>
    );
  }

  return <Loader />;
}

Settings.propTypes = { customData: PropTypes.object, refetch: PropTypes.func };
