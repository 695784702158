export function splitDigitsIntoArray(number) {
  if (isNaN(number)) {
    return null;
  }

  // always return a positive number without isNaN which can occur when number is split and is below 0
  // Avoids isNaN error on frontend view

  const absoluteNum = Math.abs(number);

  return absoluteNum
    .toString(10)
    .split('')
    .map(function (num) {
      return parseInt(num);
    });
}
