import { isInArray } from './utils';

const TAGS = ['new', 'expiring', 'loaded', 'loadable', 'evergreen', 'foryou', 'limitedtime'];

export function deriveReqBodyFrom(filter = null, sort = '', page = 1, limit = 50, category = [], brand = []) {
  const validCategoryFilter = (cat) => {
    if (cat === 'loadable') {
      return false;
    }

    if (cat === 'featured') {
      return false;
    }

    return true;
  };

  const reqBody = {
    category: [],
    tag: [],
    brand,
    sort,
    page,
    limit,
  };

  if (!filter) {
    reqBody.category.push('ALL');
    reqBody.tag.push('');
    return reqBody;
  }

  if (filter === 'grocery' && category && !category?.length) {
    reqBody.category.push('grocery');
    reqBody.tag.push('');
    return reqBody;
  }

  if (filter === 'featured') {
    reqBody.category.push('ALL');
    reqBody.tag.push('');

    return reqBody;
  }

  if (TAGS.includes(filter)) {
    reqBody.tag.push(filter);
  } else {
    reqBody.tag.push('');
  }

  // Category is only an array when  the offers filter has been interacted with.
  // there are 2 categories coming from agility which are actually tags "loadable" & 'featured',
  // using those tags as categories yields no results from api.
  if (Array.isArray(category)) {
    if (category?.length) {
      // setup category array
      const onlyValidCats = category.filter(validCategoryFilter);

      if (onlyValidCats.length) {
        reqBody.category = [...onlyValidCats];
      } else {
        reqBody.category.push('ALL');
      }

      // setup loadable cat selection in tags
      if (category.includes('loadable') && !reqBody.tag.includes('loadable')) {
        reqBody.tag.push('loadable');
      }
    } else {
      reqBody.category.push('ALL');
    }
  } else if (Array.isArray(filter)) {
    reqBody.category = filter;
  } else if (filter !== 'loadable') {
    reqBody.category.push(filter);
  }

  return reqBody;
}

export function restructureOffersCategories(categories) {
  if (categories) {
    return categories.map((category) => ({
      code: category.fields.code,
      name: category.fields.name,
    }));
  }
  return null;
}

export function checkListForItems(array) {
  return array && array.length > 0;
}

export function deriveOffersFrom(offersList, selectedFilters) {
  const offersObj = {
    offersList: [],
    limitedTimeOffersList: [],
    offersForYouList: [],
    moreOffersList: [],
    moreWaysToEarn: [],
    featuredOffer: [],
  };

  if (selectedFilters !== 'featured') {
    offersObj.offersList = offersList.filter((offer) => !offer.tags.includes('evergreen'));
  }

  offersList.forEach((offer) => {
    if (!isInArray(offer.tags, 'evergreen')) {
      if (isInArray(offer.category, 'featured')) {
        offersObj.featuredOffer.push(offer);
      }

      if (isInArray(offer.tags, 'foryou')) {
        offersObj.offersForYouList.push(offer);
      }

      if (isInArray(offer.tags, 'limitedtime')) {
        offersObj.limitedTimeOffersList.push(offer);
      }
    } else {
      offersObj.moreOffersList.push(offer);
    }
  });

  return offersObj;
}

// Sorting Helpers
export function sortByEndDate(a, b) {
  return new Date(a.endDate) - new Date(b.endDate);
}

export function sortByStartDate(a, b) {
  return new Date(b.startDate) - new Date(a.startDate);
}

export function sortByPriority(a, b) {
  if (a.priority > b.priority) return 1;
  if (a.priority < b.priority) return -1;
}

export function deriveSortMethodFrom(sortBy) {
  if (sortBy === 'expiring-soon') {
    return sortByEndDate;
  }

  if (sortBy === 'newest') {
    return sortByStartDate;
  }

  if (sortBy === 'loaded') {
    return sortByPriority;
  }

  if (sortBy === 'limited-featured') {
    return sortByPriority;
  }
}

export function sortedOffersList(offersList = [], sortBy = '') {
  if (sortBy === 'loaded') {
    const loadedOffers = offersList.filter((offer) => offer.isOptedIn); // double check that this is actually loaded
    const unloadedOffers = offersList.filter((offer) => !offer.isOptedIn);

    return [...loadedOffers.sort(deriveSortMethodFrom(sortBy)), ...unloadedOffers.sort(deriveSortMethodFrom(sortBy))];
  }
  return offersList.sort(deriveSortMethodFrom(sortBy));
}

export function updateOptedInStatusFrom(offers, offer) {
  return offers.map((item) => {
    if (offer.offerId === item.offerId) {
      item.isOptedIn = !offer.isOptedIn;
      return item;
    }

    return item;
  });
}

export function deriveOffersFiltersByProvince(filters, code) {
  if (!code) return [];
  return filters.filter((filter) => filter.provinces && filter.provinces.indexOf(code) >= 0);
}

export function categoryFilterReducer(prev, curr) {
  if (!prev && curr?.code) {
    return curr.code;
  }

  if (curr?.code && prev.indexOf(curr?.code) === -1) {
    return `${prev}, ${curr?.code}`;
  }

  return prev;
}

export function categoriesString(filters) {
  return filters.reduce(categoryFilterReducer, '');
}

export function brandFilter(category) {
  return (brand) => brand.categories && category.indexOf(brand.categories) >= 0;
}

export function deriveBrandsFiltersByCategory(brands, category) {
  if (category === 'ALL' || category === '') return brands;
  if (!category) return [];

  const filteredBrands = brands.filter(brandFilter(category));

  return filteredBrands;
}

// Filter static banner by category on Offers General tab
export function deriveStaticBanner(list, filter) {
  const filteredBanner = list.filter((banner) => banner.category === filter);
  return checkListForItems(filteredBanner) ? filteredBanner[0] : null;
}
