import styled, { css } from 'styled-components';

// Gift Card List
export const SliderSection = styled.div`
  ol,
  ul {
    padding-left: 1rem;

    li {
      font-size: ${(props) => props.theme.headingMedium};
      line-height: 1.35;
    }
  }
`;

export const SectionTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  span {
    color: ${(props) => props.theme.brandBlack};
    font-weight: normal;
  }
`;

export const ResultCount = styled.div`
  font-size: ${(props) => props.theme.fontBody3};
  line-height: ${(props) => props.theme.lineHeight3};
  color: ${(props) => props.theme.lightGrayColor4};
  margin-bottom: 2.5rem;
  font-weight: bold;
  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
`;

export const Section = styled.section`
  display: flex;

  @media screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const DetailsImage = styled.div`
  background-color: transparent;
  position: relative;
  width: 9.75rem;
  height: 6.25rem;
  @media screen and (min-width: 768px) and (max-width: 990px) {
    width: 18.375rem;
    height: 11.75rem;
  }
  @media screen and (min-width: 991px) {
    width: 22.875rem;
    height: 15.5rem;
    box-shadow: 0px 10px 12px -5px rgba(0, 0, 0, 0.51);
  }
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.51);

  // fix for poor image rounded corners
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const FormatWrapper = styled.div`
  p.gift-card-format-label {
    margin-top: 2rem;
  }
`;

// Format
export const Format = styled.li`
  padding: 0.5rem 2rem;
  display: inline-block;
  border-radius: 0;
  background: inherit;
  width: auto;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontBody3};
  line-height: ${(props) => props.theme.lineHeight3};
  color: ${(props) => props.theme.uiCharcoal};

  background-color: ${(props) => props.theme.uiGrey02};
  border: 1px solid ${(props) => props.theme.borderColor};
  margin: 0 1rem 1rem 0;
  transition: 0.2s all ease-in;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.uiPurple};
  }

  &.selected {
    background-color: ${(props) => props.theme.uiBlack};
    border: 1px solid ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.whiteColor};
    background-repeat: no-repeat;
    background-position: 1rem center;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #949494;
      border: none;
      pointer-events: none;
      cursor: pointer !important;
    `}
`;
