import styled from 'styled-components';

export const Section = styled.div`
  margin-bottom: 1.5rem;
  h2,
  p,
  div,
  li {
    font-size: ${(props) => props.theme.fontBody2};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody32};
    }
  }

  ol,
  ul {
    padding-left: 2rem;
    li {
      line-height: ${(props) => props.theme.lineHeight3};
    }
  }
`;
