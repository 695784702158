// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import RewardsSlider from '../common/Banners/RewardsSlider';

const RedeemTrending = ({ module, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" isGray customStyles="trendingRewards">
        <RewardsSlider
          category="ALL"
          brand="ALL"
          slides={module.fields.maximumSlides}
          languageCode={languageCode}
          sectionTitle={module.fields.title}
          openDetailsModalLabel={module.fields.openDetailsModalLabel}
          isSlider
        />
      </WrapperLayout>
    );
  }
  return <div className="flex justify-center">{/* No account for now */}</div>;
};

export default RedeemTrending;
