import PropTypes from 'prop-types';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import OffersList from '../OffersList';

function OffersForYou({ customData, offersForYouList, offersForYouLabel, isSlider, customStyles, listName }) {
  return (
    <WrapperLayout className="global-content-wrapper" customStyles={customStyles}>
      <OffersList
        customData={customData}
        filteredList={offersForYouList.slice(0, 8)}
        title={offersForYouLabel}
        isSlider={isSlider}
        listName={listName}
      />
    </WrapperLayout>
  );
}

OffersForYou.propTypes = {
  customData: PropTypes.object,
  offersForYouList: PropTypes.array,
  offersForYouLabel: PropTypes.string,
  isSlider: PropTypes.bool,
  customStyles: PropTypes.string,
  listName: PropTypes.string,
};

export default OffersForYou;
