import PropTypes from 'prop-types';

// Components

import { useStateMachine } from 'little-state-machine';
import { NoOffersWrapper } from './OfferStyles';

export default function NoOffers({ customData }) {
  const { state } = useStateMachine();

  if (!state.offers.isLoading) {
    return (
      <NoOffersWrapper>
        <p>
          <strong>{customData.pageContent.noOffersTitle}</strong>
        </p>
        <p>{customData.pageContent.noOffersMessage}</p>
      </NoOffersWrapper>
    );
  }

  return null;
}

NoOffers.propTypes = {
  customData: PropTypes.object.isRequired,
};
