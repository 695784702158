import styled from 'styled-components';

export const Introduction = styled.p`
  color: ${(props) => props.theme.uiWhite};
  font-size: ${(props) => props.theme.headingMedium};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight2};
  margin-bottom: 6.25rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingSmall};
    margin-bottom: 4.75rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-bottom: 5rem;
  }
`;

export const Section = styled.section`
  background-color: ${(props) => props.theme.uiBlack};

  background-image: url('/assets/cross-circles.svg'), url('/assets/cross-circles-small.svg'), url('/assets/cross-circles.svg'),
    url('/assets/cross-circles.svg'), url('/assets/cross-circles-small.svg'), url('/assets/cross-circles.svg');
  background-repeat: no-repeat;
  background-position: -1% 25%, 15% 1%, 101% 5%, 4% 95%, 45% 60%, 102% 98%;
  background-size: 220px, 50px, 220px, 180px, 50px, 220px;
  padding: 6.25rem 0;

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding: 5rem 0;
    background-position: -5% 5%, 95% -1%, 101% 35%, 4% 104%, 45% 60%, 102% 82%;
  }
  @media screen and (max-width: 640px) {
    padding: 3.75rem 0;
    background-size: 120px, 50px, 120px, 120px, 50px, 120px;
    background-position: -20% 3%, 98% -1%, 122% 20%, -2% 99%, 45% 60%, 102% 82%;
  }
`;
export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 2.5rem;
    flex-direction: row;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  @media screen and (max-width: 1023px) {
    &.mobileHide {
      display: none;
    }
    &.mobileShow {
      display: block;
    }
  }
  @media screen and (min-width: 1024px) {
    &.mobileHide {
      display: block;
    }
    &.mobileShow {
      display: none;
    }
  }
  @media screen and (max-width: 640px) {
    height: 6.25rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    height: 12.5rem;
  }
`;
export const Box = styled.div`
  padding: 1.5rem;
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding: 2.5rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    max-width: 70%;
    padding: 3.75rem;
  }
  @media screen and (min-width: 1440px) {
    max-width: 49.625rem;
    padding: 3.75rem;
  }
  h2 {
    font-family: 'Bebas Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5rem;
    line-height: 0.96;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 640px) {
      font-size: 2.5rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }
  p,
  span {
    line-height: ${(props) => props.theme.lineHeight3};
    font-size: ${(props) => props.theme.fontBody1};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
  &.yellow {
    background-color: ${(props) => props.theme.uiYellow};
    color: ${(props) => props.theme.uiCharcoal};

    h2 {
      border-bottom: 1px solid ${(props) => props.theme.uiBlack};
    }
  }
  &.purple {
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.uiWhite};
    h2 {
      border-bottom: 1px solid ${(props) => props.theme.uiWhite};
    }
    p,
    strong,
    span {
      color: ${(props) => props.theme.uiWhite};
    }

    @media screen and (min-width: 1024px) {
      margin-left: auto;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;
