/* eslint-disable react/destructuring-assignment */

import PropTypes from 'prop-types';
import { ContentZone } from '@agility/nextjs';
import { getModule } from '../agility-pageModules';

import MainContentPadding from '../common/Layouts/MainContent/MainContentPadding';
import StickHead from '../common/Head/StickHead';
import HeaderWithLogoOnly from '../common/Layouts/Header/HeaderWithLogoOnly';

// Styles
import { SkipToContent } from '../common/Layouts/Header/HeaderStyles';

const BlankTemplate = (props) => (
  <>
    <SkipToContent href="#mainContent">
      <span>{props.globalData?.header?.fields?.skipToContentLabel}</span>
    </SkipToContent>
    <StickHead
      req={props.req}
      languageCode={props.languageCode}
      globalLabels={props.globalData?.header?.fields?.globalLabels}
      inlineMsgList={props.globalData.header.inlineMsgList}
      closeLabel={props.globalData.header?.fields?.globalLabels[0]?.fields?.closeLabel}
    >
      <HeaderWithLogoOnly
        image={props.globalData?.header?.fields?.siteLogo?.url}
        title={props.globalData?.header?.fields?.siteLogo?.label}
      />
    </StickHead>
    <MainContentPadding />
    <main id="mainContent" className="relative focus:outline-none flex-grow pattern-bg">
      <div className="h-full overflow-hidden">
        <ContentZone name="ContentZone" {...props} getModule={getModule} />
      </div>
    </main>
  </>
);

BlankTemplate.propTypes = {
  req: PropTypes.object,
  languageCode: PropTypes.string,
  globalData: PropTypes.object,
};

export default BlankTemplate;
