import PropTypes from 'prop-types';
import Script from 'next/script';

export default function JuicerFeed({ fields }) {
  if (fields) {
    return (
      <section className="section-content-wrapper">
        <div className="global-content-wrapper">
          {/* TODO - use NEXT SCRIPT!!!!!! */}
          <Script src="https://assets.juicer.io/embed.js" type="text/javascript" />

          <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />
          <h2 className="headline-medium block mb-3 md:mb-6">{fields.title}</h2>

          <ul
            className="juicer-feed"
            data-columns={fields.numberOfColumns}
            data-feed-id={fields.feedId}
            data-pages="1"
            data-per={fields.numberOfPosts}
            data-gutter={fields.spaceBetweenPosts}
          />
        </div>
      </section>
    );
  }
  return null;
}

JuicerFeed.propTypes = { fields: PropTypes.object };
