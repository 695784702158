/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'next/router';
import { useMsal } from '@azure/msal-react';

// Global State
import { useStateMachine } from 'little-state-machine';
import ExternalImage from '../common/Images/ExternalImage';
import { clear } from '../../store/actions';

// B2C
import { b2cSignIn, b2cSignOut } from '../../b2c/b2cFunctions';

// Components
import Loader from '../common/Loader/Loader';
import ButtonIcon from '../common/Button/ButtonIcon';
import { Section } from '../common/Layouts/Header/HeaderStyles';

// Hooks
import useStorage from '../hooks/navigation/useStorage';

// Helpers
import { handleGAEvents } from '../../helpers/handleGoogleAnalyticsHelper';

export default function SignInHeaderLink({
  languageCode,
  signOutLabel,
  signInLabel,
  signInIcon,
  loadingLabel,
  linkStyle,
  setOpenAccount,
}) {
  const {
    actions,
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine({ clear });
  const router = useRouter();
  const { instance, inProgress } = useMsal();
  const { setItem, removeItem } = useStorage();

  // TODO: Revisit this? potential re-rendering when not needed (ie: flashing screen)
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload(); // reload page if it has been loaded from cache
      }
    };
  }, [window]);

  const handleLogin = async () => {
    setItem('from_url', router.asPath);
    b2cSignIn(instance, languageCode);
  };

  const handleLogOut = async () => {
    handleGAEvents('Log out', 'User Login', 'Account Logout Click', 'logout');
    actions.clear();
    removeItem('from_url');
    removeItem('session');
    removeItem('province');

    await b2cSignOut(instance, languageCode);
  };

  const hide = () => setOpenAccount && setOpenAccount(false);

  const show = () => setOpenAccount && setOpenAccount(true);

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      hide();
    }
    if (e.keyCode === 13) {
      handleLogOut();
    }
  };

  if (isLoggedIn) {
    return (
      <ButtonIcon
        type="button"
        ariaLabel={signOutLabel}
        className={`${linkStyle ? `${linkStyle} ` : 'header-link'}`}
        onClick={handleLogOut}
        onFocus={show}
        onBlur={hide}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {signOutLabel}
      </ButtonIcon>
    );
  }

  if (inProgress === 'login' || inProgress === 'logout') {
    return (
      <div className="justify-center mt-4 flex">
        <Loader width="auto" reverse text={loadingLabel} />
      </div>
    );
  }

  return (
    <Section isLoggedIn={isLoggedIn}>
      <ButtonIcon
        className={`${linkStyle ? `${linkStyle} ` : 'header-link'}`}
        ariaLabel={signInLabel}
        type="button"
        onClick={handleLogin}
        tabIndex={0}
      >
        <span className="mr-2">{signInLabel}</span>
        {signInIcon && <ExternalImage url={signInIcon} width={18} height={18} />}
      </ButtonIcon>
    </Section>
  );
}

SignInHeaderLink.propTypes = {
  languageCode: PropTypes.string,
  signOutLabel: PropTypes.string,
  signInLabel: PropTypes.string,
  signInIcon: PropTypes.string,
  loadingLabel: PropTypes.string,
  linkStyle: PropTypes.string,
  setOpenAccount: PropTypes.func,
};
