/* eslint-disable no-shadow */
import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveDateRangeList } from '../../helpers/agilityHelpers';
import usePeriod from '../hooks/points/usePeriod';
import WrapperLayout from '../layouts/WrapperLayout';
import OrdersHub from '../Orders/OrdersHub';

const Orders = ({ customData, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  const dateRange = useMemo(
    () => deriveDateRangeList(customData.rawDateRange, customData.rawMonths),
    [customData.rawDateRange, customData.rawMonths]
  );
  usePeriod(dateRange, 'orders');

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper min-half-screen" isGray mobileIsWhite>
        <OrdersHub customData={customData} languageCode={languageCode} />
      </WrapperLayout>
    );
  }

  return null;
};

Orders.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const rawAwardsTypes = await api.getContentList({ referenceName: 'awardtypes', languageCode });

    const awardsTypes =
      rawAwardsTypes &&
      rawAwardsTypes.map((a) => ({
        code: a?.fields?.code || null,
        image: a?.fields?.image || null,
        name: a?.fields?.name || null,
      }));

    const contentItemList = await api.getContentList({
      referenceName: 'orderscontent',
      languageCode,
    });

    // get Months...
    const rawMonths = await api.getContentList({ referenceName: 'months', languageCode });
    // get DateRange...
    const rawDateRange = await api.getContentList({ referenceName: 'daterange', languageCode });

    const contentItem = contentItemList && contentItemList[0];

    return {
      awardsTypes: awardsTypes || null,
      ordersContent: contentItem.fields,
      rawDateRange,
      rawMonths,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

Orders.propTypes = { customData: PropTypes.object, languageCode: PropTypes.string };

export default Orders;
