/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const Filter = styled.li`
  display: inline-block;
  border-radius: 0;
  background: inherit;
  width: auto;
  font-weight: normal;
  color: ${(props) => props.theme.uiCharcoal};
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.uiGrey02};
  border: 1px solid ${(props) => props.theme.borderColor};
  margin: 0 1rem 1rem 0;
  transition: 0.2s all ease-in;
  cursor: pointer;
  min-height: 37px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.selected {
    padding-left: 2.5rem;
    border: 1px solid ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.whiteColor};

    background-color: ${(props) => props.theme.uiBlack};
    background-image: url('/assets/white-checkmark.svg');
    background-repeat: no-repeat;
    background-position: 1rem center;

    ${(props) =>
      props.filterChip &&
      css`
        background-image: url('/assets/close-x-white.svg');

        &:focus {
          outline: 2px solid ${(props) => props.theme.uiPurple};
          outline-offset: 0;
          border-color: ${(props) => (props.isError ? props.theme.uiErrorRed : props.theme.uiPurple)};
        }
      `}
  }
`;

export const FilterListSection = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.75rem;
  }
`;

export const FilterListHeading = styled.h3`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody2};
  margin-bottom: 1rem;
`;
