/* eslint-disable camelcase */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import ExternalImage from '../../Images/ExternalImage';

import { updateOffers, updateNotification } from '../../../../store/actions';

// Services
import { loadOffers } from '../../../../pages/api/services';

// Components
import BrandLogo from '../../Icons/BrandLogo';
import LoadOffer from './LoadOffer';

// Hooks
import useDragDetection from '../../../hooks/utils/useDragDetection';
import useScrollDetection from '../../../hooks/utils/useScrollDetection';
import useIntersectionObserverGa from '../../../hooks/utils/useIntersectionObserverGa';

// Helpers
import { insertParam, updateURI } from '../../../../helpers/navigationHelpers';
import { formatDate } from '../../../../helpers/dateHelpers';
import { updateOptedInStatusFrom } from '../../../../helpers/offersHelpers';
import { handleGAEventsBase, getOfferCardMappedObject } from '../../../../helpers/handleGoogleAnalyticsHelper';
import { guidGenerator } from '../../../../helpers/guidHelper';
import { formatMultiplierLabel } from '../../../../helpers/stringHelpers';
import { checkArrayForItems } from '../../../../helpers/utils';
// Styles
import {
  CardItem,
  Brand,
  CardHeader,
  CardImage,
  LogoBadge,
  CardBody,
  CardContents,
  CardTitle,
  CardSubitle,
  CouponBanner,
  Multiplier,
  MultiplierIcon,
} from '../CardStyles';

export default function OfferCard({ currentItemId, content, offersNum, toggle, offer, customData, isGrid, id, listName }) {
  const [loading, setLoading] = useState(false);

  const { title, subTitle, brand, webImage, isCouponBased, couponBasedBannerContent, getCouponLink, acceleratedSegments } =
    content.cmsContent;

  const [isIntersecting, setIsIntersecting] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const threshold = [0.8];

  const {
    messageOfferLoaded,
    messageOfferNotLoaded,
    messageOfferUnloaded,
    loadingLabel,
    unloadingLabel,
    loadOfferLabel,
    offerLoadedLabel,
    offerPreloadedLabel,
    expireLabel,
    getCouponLabel,
    multiplierLabel,
    multiplierIcon,
  } = customData;

  const {
    actions,
    state: {
      session: { accessToken },
      language: { locale },
      offers: {
        offersList,
        selectedFilters,
        limitedTimeOffersList,
        offersForYouList,
        moreOffersList,
        moreWaysToEarn,
        featuredOffer,
      },
      navigation: { page },
      location,
    },
  } = useStateMachine({ updateOffers, updateNotification });

  const { handleMouseDown, dragging } = useDragDetection();
  const { scrolling } = useScrollDetection();
  const status = offer && offer.isOptedIn;
  const isLoadable = offer?.tags && offer.tags.includes('loadable');
  const isEvergreen = offer?.tags && offer.tags.includes('evergreen');

  // GA props
  const eventCategory = 'Partner Offers';
  const list_name = listName;
  const tile_position = parseInt(id?.split('-')[1]) + 1;
  const prov_segment = location?.province;
  let item_category3 = '';
  if (isLoadable) {
    item_category3 = `Loadable${status ? ' - loaded' : ''}`;
  }
  if (isCouponBased) {
    item_category3 = 'Coupons';
  }

  const gaAddToCart = () => {
    const event = 'offer_add_to_cart';
    const alt_event = 'offers_load_on_tile';
    const eventAction = 'Offer Loaded';
    const dataLayer = getOfferCardMappedObject(
      offer,
      event,
      alt_event,
      eventCategory,
      eventAction,
      list_name,
      tile_position,
      prov_segment,
      item_category3
    );

    handleGAEventsBase(dataLayer);
  };
  useEffect(() => {
    if (isIntersecting && enabled && Object.keys(offer).length && content) {
      const event = 'offer_view_item_list';
      const alt_event = 'offers_impression';
      const eventAction = 'Offer Impressions';

      const dataLayer = getOfferCardMappedObject(
        offer,
        event,
        alt_event,
        eventCategory,
        eventAction,
        list_name,
        tile_position,
        prov_segment,
        item_category3
      );

      handleGAEventsBase(dataLayer);

      setEnabled(false);
      setIsIntersecting(false);
    }
  }, [isIntersecting]);
  useEffect(() => {
    if (!enabled) setEnabled(true);
  }, [currentItemId]);
  const handleTileClick = () => {
    const event = 'offer_select_item';
    const alt_event = 'offers_tile_click';
    const eventAction = 'Offer Click';
    const dataLayer = getOfferCardMappedObject(
      offer,
      event,
      alt_event,
      eventCategory,
      eventAction,
      list_name,
      tile_position,
      prov_segment,
      item_category3
    );

    handleGAEventsBase(dataLayer);
  };

  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }

    updateURI(insertParam('offer', offer.offerCode));
    handleTileClick();
    toggle(currentItemId);
  };
  const handleCouponBasedOfferClick = (e) => {
    gaAddToCart();
  };
  const handleOfferLoadUnload = async (e) => {
    e.persist();
    if (isLoadable && !status) {
      gaAddToCart();
    }
    if (isLoadable && status) {
      const event = 'offer_remove_from_cart';
      const alt_event = 'offers_unload_offer_tile';
      const eventAction = 'Offer unloaded';
      const dataLayer = getOfferCardMappedObject(
        offer,
        event,
        alt_event,
        eventCategory,
        eventAction,
        list_name,
        tile_position,
        prov_segment,
        item_category3
      );

      handleGAEventsBase(dataLayer);
    }
    setLoading(true);
    const reqBody = [{ offerId: offer.offerId, optIn: !offer.isOptedIn }];

    const response = await loadOffers(reqBody, accessToken);
    if (response?.data?.data === true) {
      const offerClicked = { ...offer };

      // find all instances where the loaded offer would be located.
      if (page === '/' || page === '/offers/limited-time') {
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
        });
      } else if (selectedFilters === 'featured') {
        // TODO: optimize this with a conditional helper that will cherry pick the arrays based on the tags/categories of the offer (See derive offers from helper)
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
          offersForYouList: updateOptedInStatusFrom(offersForYouList, offerClicked),
          moreOffersList: updateOptedInStatusFrom(moreOffersList, offerClicked),
          moreWaysToEarn: updateOptedInStatusFrom(moreWaysToEarn, offerClicked),
          featuredOffer: updateOptedInStatusFrom(featuredOffer, offerClicked),
          offersList: updateOptedInStatusFrom(offersList, offerClicked),
        });
      } else {
        actions.updateOffers({ offersList: updateOptedInStatusFrom(offersList, offerClicked) });
      }

      actions.updateNotification({
        message: offer.isOptedIn ? messageOfferLoaded : messageOfferUnloaded,
        status: offer.isOptedIn ? 'success' : 'warning',
      });
    } else {
      actions.updateNotification({
        message: messageOfferNotLoaded,
        status: 'error',
      });
      const dataLayer = {
        event: 'offers_loaded_fail',
        category: 'Partner Offers',
        action: 'Offer Loaded Fail',
        label: `${offer.cmsContent.brand?.title.trim()} | ${title.trim()}`,
      };

      handleGAEventsBase(dataLayer);
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      updateURI(insertParam('offer', offer.offerCode));
      toggle(currentItemId);
    }
  };

  function onIntersect(val) {
    if (isIntersecting !== val) setIsIntersecting(val);
  }

  const targetRef = useRef(null);
  useIntersectionObserverGa({
    target: targetRef,
    onIntersect,
    scrolling,
    enabled,
    threshold,
  });

  return (
    <CardItem
      id={guidGenerator()}
      offersNum={offersNum}
      className="flex card-item card-content-wrapper"
      isGrid={isGrid}
      ref={targetRef}
    >
      <CardContents className="card-contents" offersNum={offersNum}>
        <div
          tabIndex="0"
          className="offer-link"
          role="link"
          onMouseDownCapture={handleMouseDown}
          onClickCapture={handleChildClick}
          onKeyDown={handleKeyDown}
          style={{ cursor: 'pointer' }}
        >
          <CardHeader offersNum={offersNum} className="w-full">
            {brand && brand?.logoTransparentBG?.url && (
              <LogoBadge className="rounded-full overflow-hidden">
                <BrandLogo url={brand?.logoTransparentBG?.url} title={brand?.title} />
              </LogoBadge>
            )}

            {webImage && (
              <CardImage className="relative w-full h-full" aria-hidden="true">
                <ExternalImage
                  url={webImage?.url}
                  fill
                  sizes="(max-width: 640px) 100vw, (max-width: 990px) 50vw, 33vw"
                  className="object-cover object-center"
                />

                {isCouponBased && couponBasedBannerContent && (
                  <CouponBanner className="coupon-banner">{couponBasedBannerContent}</CouponBanner>
                )}
              </CardImage>
            )}
          </CardHeader>

          <CardBody>
            {brand && <Brand className="font-bold brand">{brand?.title}</Brand>}

            {title && <CardTitle className="font-bold">{title}</CardTitle>}

            {subTitle && <CardSubitle>{subTitle}</CardSubitle>}
            {content.endDate && (
              <p className="text-xs m-0 card-details">
                {expireLabel} {formatDate(content.endDate, 'MMM DD', locale)}
              </p>
            )}
          </CardBody>
        </div>

        <LoadOffer
          status={status}
          isLoadable={isLoadable}
          isEvergreen={isEvergreen}
          isCouponBased={isCouponBased}
          offerLoadedLabel={offerLoadedLabel}
          loadOfferLabel={loadOfferLabel}
          offerPreloadedLabel={offerPreloadedLabel}
          handleOfferLoadUnload={handleOfferLoadUnload}
          handlePreloadedClick={handleChildClick}
          handleCouponBasedOfferClick={handleCouponBasedOfferClick}
          unloadingLabel={unloadingLabel}
          loadingLabel={loadingLabel}
          loading={loading}
          getCouponLink={getCouponLink}
          getCouponLabel={getCouponLabel}
        />
        {checkArrayForItems(acceleratedSegments) && (
          <Multiplier>
            <MultiplierIcon>
              {multiplierIcon?.url && <ExternalImage url={multiplierIcon?.url} fill className="object-contain object-left" />}
            </MultiplierIcon>
            <p>{formatMultiplierLabel(multiplierLabel, acceleratedSegments[0].factor)}</p>
          </Multiplier>
        )}
      </CardContents>
    </CardItem>
  );
}

OfferCard.propTypes = {
  currentItemId: PropTypes.string,
  content: PropTypes.object,
  offersNum: PropTypes.number,
  toggle: PropTypes.func,
  offer: PropTypes.object,
  customData: PropTypes.object,
  isGrid: PropTypes.bool,
  listName: PropTypes.string,
  id: PropTypes.string,
};
