/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const AllOrdersWrapper = styled.div`
  .all-orders-heading {
    margin-bottom: 1.5rem;

    @media screen and (min-width: 641px) {
      margin-bottom: 2rem;
    }
  }
`;
export const RecentOrdersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.25rem;
  @media screen and (max-width: 640px) {
    margin-bottom: 1.375rem;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
    font-size: ${(props) => props.theme.fontBody2};

    @media screen and (max-width: 1023px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;
export const RecentOrdersContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.uiGrey02};
  padding-top: 3rem;
  padding-bottom: 9.5rem;
`;
export const RecentOrdersListStyle = styled.div`
  display: grid;

  grid-template-columns: 1fr;

  @media screen and (min-width: 641px) {
    grid-template-columns: 1fr 1fr;
  }

  a {
    text-decoration: none;
  }
`;
export const OrdersListWrapper = styled.div`
  padding-bottom: 3rem;
  @media screen and (min-width: 1024px) {
    padding-bottom: 7.5rem;
  }
  @media screen and (min-width: 768px) {
    max-width: 48rem;
    flex: 0 0 70%;
  }
`;
export const MobileOrderItem = styled.div`
  a {
    text-decoration: none;
  }
`;
export const OrderWrapper = styled.div`
  height: 106px;
  padding: 16px 16px 16px 20px;
  display: flex;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  background-color: ${(props) => props.theme.whiteColor};
  ${(props) => (props.hideBottomSpacing ? '' : 'margin-bottom: 1rem')};
`;
export const MerchantHeading = styled.h3`
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody2};
`;
export const EmptyOrderWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  background-color: ${(props) => props.theme.whiteColor};
  margin-bottom: 0rem;
  align-items: flex-start;
  width: 100%;

  @media screen and (min-width: 768px) {
    ${(props) =>
      props.recentOrders &&
      css`
        max-width: 492px;
      `};
  }

  ${(props) =>
    props.showButton &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 461px;
      align-self: center;

      @media screen and (max-width: 767px) {
        align-items: end;
        height: 316px;
        max-width: 700px;
        width: 100%;
      }
      @media screen and (max-width: 640px) {
        align-items: end;
        height: 316px;
        max-width: 700px;
        width: 100%;
      }
    `}
`;
export const IconContainer = styled.div`
  margin-right: 1rem;
  object-position: center;

  ${(props) =>
    props.showButton &&
    css`
      align-self: center;
      margin-right: 0px;
      margin-bottom: 1.5rem;
    `}
`;
export const IconContainerRewards = styled.div`
  margin-right: 1rem;
  min-width: 1.5rem;
  ${(props) =>
    props.showButton &&
    css`
      align-self: center;
      margin-right: 0px;
    `}
`;
export const OrderContents = styled.div`
  width: 100%;

  ${(props) =>
    props.showButton &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  .empty-orders-heading {
    ${(props) =>
      props.recentOrders &&
      css`
        margin-top: 0;
        font-weight: bold;
      `}
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  p {
    margin: 0.25rem 0 0.25rem;

    &:last-of-type {
      margin: 0;
    }
  }
`;
export const InnerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-align: left;
    color: ${(props) => props.theme.uiCharcoal};
  }

  p {
    color: ${(props) => props.theme.lightGrayColor4};
    text-align: left;
  }
`;
export const StyledOrderFilter = styled.select`
  border: 1px solid ${(props) => props.theme.uiGrey04};
  width: 100%;
  padding: 12px;
  font-size: ${(props) => props.theme.fontBody3};
  line-height: ${(props) => props.theme.lineHeight3};
`;
export const SelectOrderWrapper = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    margin: 1rem 0;
    margin-bottom: 2rem;
    max-width: 30%;
  }
`;
export const OrderButton = styled.button`
  background: none;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  p {
    line-height: ${(props) => props.theme.lineHeight3};
  }
  border: 1px solid ${(props) => props.theme.uiGrey02};
  ${(props) =>
    props.role === 'row' &&
    css`
      > div {
        @media screen and (min-width: 641px) and (max-width: 1023px) {
          padding: 0.5rem 0;
        }
      }
      p {
        font-size: ${(props) => props.theme.fontBody2};
      }
    `}

  ${(props) =>
    props.role !== 'row' &&
    props.isRecentOrdersList &&
    css`
      width: calc(100% - 1rem);
      margin-bottom: 1.5rem;
      &:nth-child(even) {
        margin-left: 1rem;
      }
    `}
    ${(props) =>
    !props.isRecentOrdersList &&
    css`
      @media screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }
    `}

    @media screen and (max-width: 640px) {
    width: 100%;
    &:nth-child(even) {
      margin-left: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 641px) {
    border: none;
  }
`;
