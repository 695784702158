import styled from 'styled-components';

export const StyledAccordionSection = styled.div`
  margin-bottom: 6.25rem;
  &:last-of-type {
    margin-bottom: 3.75rem;
  }
  h2 {
    margin-bottom: 2.5rem;
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 3.75rem;
    &:last-of-type {
      margin-bottom: 2.5rem;
    }
    h2 {
      margin-bottom: 1.75rem;
    }
  }
`;

export const QuickSearchStyles = styled.div`
  @media screen and (min-width: 1024px) {
    margin: 5rem 0 3.75rem 0;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin: 3rem 0 3.75rem 0;
  }
  @media screen and (max-width: 640px) {
    margin: 2.5rem 0;
  }

  form {
    padding: 0;
  }
  label {
    margin: 0;
  }
  h2 {
    font-size: ${(props) => props.theme.headingSmall};
    margin-bottom: 2.5rem;
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody1};
      margin-bottom: 1rem;
    }
  }

  .input-section {
    position: relative;
    width: 50%;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  h4 {
    margin-bottom: 2rem;
  }

  .input-section {
    display: flex;
  }

  .input-section input {
    width: 100%;
    padding-right: 3rem;
  }

  .input-section .image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3rem;

    cursor: pointer;
    display: flex;
    img {
      padding: 0 0.5rem;
      &:focus {
        outline: 2px solid ${(props) => props.theme.uiPurple};
      }
    }
  }
`;

export const LastUpdated = styled.div`
  font-size: ${(props) => props.theme.fontBody1};
  font-weight: bold;
  color: ${(props) => props.theme.uiDarkPurple};

  margin-bottom: 2.5rem;
  @media screen and (min-width: 641px) {
    margin-bottom: 3.75rem;
  }

  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody3};
  }
`;
