import PropTypes from 'prop-types';

import styled from 'styled-components';

// TODO: Use global state to drive the value of qty

const Quantity = styled.div`
  line-height: 24px;
  margin-top: 0.3rem;
  color: ${(props) => props.theme.lightGrayColor4};
`;

function ConfirmationQuantity({ qtyLabel, quantity }) {
  return (
    <Quantity aria-label={`${qtyLabel}: ${quantity}.`}>
      {qtyLabel} {quantity}
    </Quantity>
  );
}

ConfirmationQuantity.propTypes = { qtyLabel: PropTypes.string.isRequired, quantity: PropTypes.number.isRequired };

export default ConfirmationQuantity;
