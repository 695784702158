import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

import useCards from '../hooks/points/useCards';

import Details from '../common/Details/Details';
import WrapperLayout from '../layouts/WrapperLayout';

const OrderDetails = ({ customData }) => {
  const {
    state: {
      session: { isLoggedIn },
      order: { selectedOrder },
    },
  } = useStateMachine();

  useCards();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn && selectedOrder && selectedOrder?.items) {
    return (
      <WrapperLayout className="global-content-wrapper overflow-hidden" isGray>
        {customData && customData.ordersContent && customData.sitemap && (
          <Details customData={customData.ordersContent} sitemap={customData.sitemap} awardsTypes={customData.awardsTypes} />
        )}
      </WrapperLayout>
    );
  }

  return <div className="flex justify-center">{/* No account for now */}</div>;
};

OrderDetails.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  // set up api
  const api = agility;
  try {
    // get sitemap...
    const sitemap = await api.getSitemap({
      channelName,
      languageCode,
    });

    const rawAwardsTypes = await api.getContentList({
      referenceName: 'awardtypes',
      languageCode,
    });

    const awardsTypes =
      rawAwardsTypes &&
      rawAwardsTypes.map((a) => ({
        code: a?.fields?.code || null,
        image: a?.fields?.image || null,
        name: a?.fields?.name || null,
      }));

    const contentItemList = await api.getContentList({
      referenceName: 'orderscontent',
      languageCode,
    });

    const contentItem = contentItemList && contentItemList[0];
    return {
      awardsTypes: awardsTypes || null,
      ordersContent: contentItem.fields,
      sitemap,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

OrderDetails.propTypes = {
  customData: PropTypes.object,
};

export default OrderDetails;
