import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import { updateUser } from '../../store/actions';

// Components
import Sidebar from '../common/Sidebar/Sidebar';
import WrapperLayout from '../layouts/WrapperLayout';
import { NavigatedContent, SidebarContainer } from '../common/Sidebar/SidebarStyles';
import Settings from '../forms/settings/Settings';

// Hooks
import usePreferences from '../hooks/account/usePreferences';
import useHasMount from '../hooks/utils/useHasMount';

const SettingsPage = ({ customData }) => {
  const {
    actions,
    state: {
      session: { accessToken, isLoggedIn },
    },
  } = useStateMachine({ updateUser });

  const { preferencesAPIData, isLoading, error, refetch } = usePreferences(accessToken);

  useEffect(() => {
    if (preferencesAPIData) {
      actions.updateUser({
        preferences: [],
        preferencesData: preferencesAPIData,
      });
    }
  }, [preferencesAPIData]);

  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" isGray customStyles="topOfPage">
        <SidebarContainer>
          <div className="hidden lg:block">
            <Sidebar sidebarLinks={customData.ProfileSettingsForm.profileSidebarLinks} />
          </div>

          <NavigatedContent>
            <Settings customData={customData} isLoading={isLoading} refetch={refetch} />
          </NavigatedContent>
        </SidebarContainer>
      </WrapperLayout>
    );
  }
  return null;
};

SettingsPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'profileform',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];
    let contentItem = contentItemList && contentItemList[0];

    // sidebarLinks
    if (contentItem.fields.profileSidebarLinks) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'profileSidebarLinks',
      });
      contentItem.fields.profileSidebarLinks.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    }

    if (contentItem.fields.emailTopics) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'emailTopics',
        sortIDField: 'emailTopics_SortIdField',
      });
    }

    return {
      ProfileSettingsForm: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

SettingsPage.propTypes = {
  customData: PropTypes.object,
};

export default SettingsPage;
