import { SliderSection } from './GiftCardStyles';

import Loader from '../common/Loader/Loader';

export default function GiftCardsLoader() {
  return (
    <SliderSection>
      <div>
        <Loader />
      </div>
    </SliderSection>
  );
}
