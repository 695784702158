import PropTypes from 'prop-types';

import { DetailsSection, DetailsTitle } from '../DetailsStyles';

// Utils
import { formatDate } from '../../../../helpers/dateHelpers';

export default function DetailsOrderDate({ orderDateLabel, orderDate, locale }) {
  if (orderDateLabel && orderDate) {
    return (
      <DetailsSection>
        {orderDateLabel && <DetailsTitle className="mb-3 md:mb-0 font-bold mr-4 flex-initial">{orderDateLabel}</DetailsTitle>}

        {orderDate && <p>{formatDate(orderDate, 'll', locale)}</p>}
      </DetailsSection>
    );
  }
  return null;
}

DetailsOrderDate.propTypes = {
  orderDateLabel: PropTypes.string,
  orderDate: PropTypes.string,
  locale: PropTypes.string,
};
