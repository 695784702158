import PropTypes from 'prop-types';

import PrimaryAddress from '../PrimaryAddress';
import AddressDetails from '../Address/AddressDetails';

import { AddressSwitcherItem } from './ShippingAddressSwitcherStyles';

export default function ShippingAddressItem({ address, profileContent }) {
  const { primaryShippingAddressLabel, profileAddressLabel, shippingAddressLabel } = profileContent;
  if (address) {
    return (
      <AddressSwitcherItem className="flex w-full py-4 px-6 justify-between items-start content-start">
        <PrimaryAddress
          address={address}
          primaryShippingAddressLabel={primaryShippingAddressLabel}
          profileAddressLabel={profileAddressLabel}
          shippingAddressLabel={shippingAddressLabel}
        />
        <AddressDetails address={address} switcher />
      </AddressSwitcherItem>
    );
  }
  return null;
}

ShippingAddressItem.propTypes = {
  address: PropTypes.object.isRequired,
  profileContent: PropTypes.object,
};
