import Slider from 'react-slick';
import PropTypes from 'prop-types';
// Components
import { Section, CardCarouselWrapper } from './BannerStyles';
import BankCard from '../Cards/BankCard';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';
import { cardSliderSettings } from '../../../helpers/bannersHelper';

const BankCardsSlider = ({ slides, disableCardsLink, cardLinkLabel, gaCategory }) => {
  const offersNum = slides.length;

  const settings = cardSliderSettings(offersNum);

  if (checkArrayForItems(slides)) {
    return (
      <Section>
        <CardCarouselWrapper offersNum={offersNum}>
          <Slider {...settings}>
            {slides.map((item, index) => (
              <BankCard
                id={`card-${index}`}
                key={index}
                content={item.fields}
                offersNum={offersNum}
                disableCardsLink={disableCardsLink}
                cardLinkLabel={cardLinkLabel}
                gaCategory={gaCategory}
              />
            ))}
          </Slider>
        </CardCarouselWrapper>
      </Section>
    );
  }
  return null;
};

BankCardsSlider.propTypes = {
  slides: PropTypes.array,
  disableCardsLink: PropTypes.bool,
  cardLinkLabel: PropTypes.string,
};

export default BankCardsSlider;
