/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Button from '../../Button/Button';
import ButtonSimple from '../../Button/ButtonSimple';
import FormSelect from '../../../forms/fields/FormSelect';

// Styled Comps
import { ProvinceDropDownWrapper, ProvinceMobileButtonContainer, ProvinceMobileLabelWrapper } from '../ModalStylesV2';

function ProvinceItemListMobile({
  provinces,
  selectedProvince,
  updateProvince,
  toggle,
  fields,
  currentlySetToLabel,
  showSelectedProvince,
}) {
  const { cancelLabel, saveLabel, selectProvimceLabel } = fields;
  const provincesOptions =
    provinces &&
    provinces.map((p) => ({
      value: p.code,
      label: p.name,
    }));

  const onSubmit = (code) => {
    updateProvince(code.province);
  };
  const onErrors = (errors) => {
    console.error(errors);
  };
  const province = provincesOptions ? provincesOptions.find((p) => selectedProvince.province === p.value) : null;

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  return (
    <>
      <ProvinceMobileLabelWrapper>
        <p>
          {currentlySetToLabel}
          <strong>{showSelectedProvince}</strong>
        </p>
      </ProvinceMobileLabelWrapper>
      <ProvinceDropDownWrapper>
        <FormProvider {...formMethods}>
          <form className="p-0" style={{ width: '100%' }} onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
            <div>
              <FormSelect
                name="province"
                label={selectProvimceLabel}
                options={provincesOptions}
                defaultOption={province || selectedProvince}
              />
            </div>

            <ProvinceMobileButtonContainer>
              <Button type="submit" buttonText={saveLabel} ariaLabel={saveLabel}>
                {saveLabel}
              </Button>

              {cancelLabel && (
                <ButtonSimple
                  className="cursor-pointer font-bold cancel-button"
                  ariaLabel={cancelLabel}
                  role="button"
                  onClick={() => toggle()}
                >
                  {cancelLabel}
                </ButtonSimple>
              )}
            </ProvinceMobileButtonContainer>
          </form>
        </FormProvider>
      </ProvinceDropDownWrapper>
    </>
  );
}

ProvinceItemListMobile.propTypes = {
  provinces: PropTypes.array,
  selectedProvince: PropTypes.object,
  onSubmit: PropTypes.func,
  onErrors: PropTypes.func,
  toggle: PropTypes.func,
  fields: PropTypes.object,
};

export default ProvinceItemListMobile;
