/* eslint-disable camelcase */
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Services
import { loadOffers } from '../../../pages/api/services';

// Components
import Button from '../../common/Button/Button';

// Styles
import { LoadAllOffersWrapper } from './LoadAllOffersStyles';

// Helpers
import { handleGAEventsBase, getOfferCardMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function LoadAllOffers({ customData, page }) {
  const { loadAllOffersHeader, loadAllOffersMessage, loadAllOffersLabel } = customData;

  const {
    actions,
    state: {
      session: { accessToken },
      offers: { offersList },
      location,
    },
  } = useStateMachine({ updateOffers });
  // GA props
  const eventCategory = 'Partner Offers';
  const list_name = 'Loadable offers tab';
  const prov_segment = location?.province;
  const item_category3 = 'Loadable - loaded';

  const handleClick = async (e) => {
    const dataLayer1 = {
      event: 'offers_load_all_offers',
      category: 'Partner Offers',
      action: 'Load All Offers',
      label: 'Partner Offers',
    };
    handleGAEventsBase(dataLayer1);
    // dont allow user to load offers unless we actually have a list of offers available
    if (offersList) {
      // pluck all the loadable offers
      const loadableOffers = offersList.filter((offer) => !offer.isOptedIn);

      // dont fire request if there are no offers to load
      if (loadableOffers.length) {
        const reqBody = loadableOffers.map((offer) => ({ offerId: offer.offerId, optIn: true }));
        const response = await loadOffers(reqBody, accessToken);

        // TODO: Response from API should indicate which offers were loaded so we can update the Front End accordingly, right now it just sends { data: true }
        if (response.data.data === true) {
          const listOfIds = reqBody.map((offer) => offer.offerId);
          const newOFferList = offersList.map((offer) => {
            if (listOfIds.includes(offer.offerId)) {
              offer.isOptedIn = true;
            }
            return offer;
          });

          actions.updateOffers({ offersList: newOFferList });
        }

        // TODO: implement the error response as per figma
      }
      Object.keys(loadableOffers).forEach((key) => {
        const event = 'offer_add_to_cart';
        const alt_event = 'offers_load_on_tile';
        const eventAction = 'Offer Loaded';
        const tile_position = parseInt(key) + 1;
        const dataLayer = getOfferCardMappedObject(
          loadableOffers[key],
          event,
          alt_event,
          eventCategory,
          eventAction,
          list_name,
          tile_position,
          prov_segment,
          item_category3
        );

        handleGAEventsBase(dataLayer);
      });
    }
  };

  if (customData) {
    return (
      <LoadAllOffersWrapper page={page}>
        <div className="mr-2">
          <p className="load-all-heading">
            <strong>{loadAllOffersHeader}</strong>
          </p>
          <p className="load-all-subheading">{loadAllOffersMessage}</p>
        </div>

        <Button type="button" onClick={handleClick} ariaLabel={loadAllOffersLabel}>
          {loadAllOffersLabel}
        </Button>
      </LoadAllOffersWrapper>
    );
  }
  return null;
}
LoadAllOffers.propTypes = {
  customData: PropTypes.object.isRequired,
  page: PropTypes.string,
};
