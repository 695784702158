/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import ExternalImage from '../Images/ExternalImage';

import { Introduction, Section, BoxWrapper, ImageContainer, Box } from './AboutStyles';

export default function About({ fields }) {
  if (fields) {
    return (
      <Section>
        <div className="global-content-wrapper">
          <Introduction dangerouslySetInnerHTML={renderHTML(fields.introduction)} />
          <BoxWrapper>
            <ImageContainer className="mobileShow">
              <ExternalImage url={fields.appImage1.url} fill className="object-cover object-center" />
            </ImageContainer>
            <Box className="yellow">
              <h2>{fields.header1}</h2>

              <div dangerouslySetInnerHTML={renderHTML(fields.message1)} />
            </Box>
            <ImageContainer className="mobileHide">
              <ExternalImage url={fields.webImage1.url} fill sizes="50vw" className="object-cover object-center" />
            </ImageContainer>
          </BoxWrapper>
          <BoxWrapper>
            <ImageContainer className="mobileHide">
              <ExternalImage url={fields.webImage2.url} fill sizes="50vw" className="object-cover object-center" />
            </ImageContainer>
            <ImageContainer className="mobileShow">
              <ExternalImage url={fields.appImage2.url} fill className="object-cover object-center" />
            </ImageContainer>
            <Box className="purple">
              <h2>{fields.header2}</h2>

              <div dangerouslySetInnerHTML={renderHTML(fields.message2)} />
            </Box>
          </BoxWrapper>
        </div>
      </Section>
    );
  }

  return null;
}

About.propTypes = { fields: PropTypes.object };
