/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: ${(props) => props.theme.lineHeight3};
  flex-wrap: nowrap;
  flex-direction: column;

  p {
    margin-bottom: 0 !important;
    font-size: ${(props) => props.theme.fontBody3};
  }

  &.isDisabled {
    opacity: 0.6;
  }
`;
export const PointButton = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.uiGrey02};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  .amount-points {
    text-align: center;
    width: 100%;
    height: 59px;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in;

    label {
      margin: 0;
    }
  }

  .amount {
    margin-top: 8px;
  }
  .points {
    font-size: 0.6rem;

    @media screen and (min-width: 420px) {
      font-size: ${(props) => props.theme.fontLegal};
    }
  }
`;
export const BYOTMessageContainer = styled.div`
  margin-top: -1.5rem !important;
  margin-bottom: 3.5rem !important;
  line-height: ${(props) => props.theme.lineHeight2};
  @media screen and (min-width: 375px) {
    margin-top: 1rem !important;
  }
`;
export const StyledRadio = styled.input`
  cursor: pointer;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto !important;
  border: 1px solid ${(props) => props.theme.uiGrey02};

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.uiPurple};
  }

  &:focus {
    outline: 0;
    border-width: 3px;
  }

  &:checked {
    border: 1px solid ${(props) => props.theme.uiBlack};
    background-image: none !important;
  }
  &:checked + .amount-points {
    background-color: ${(props) => props.theme.uiBlack};

    p,
    label {
      color: ${(props) => props.theme.whiteColor};
    }
  }
`;
export const ContentCol = styled.div`
  @media screen and (min-width: 641px) {
    max-width: 40%;
    flex-basis: 40%;
    z-index: 1;
    &.first-col {
      margin-right: 6rem;
    }
    &.cards-col {
      max-width: 36.7rem;
      flex-basis: 60%;
    }
  }
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody3};
    line-height: 1.25rem;
  }

  h2 {
    font-size: ${(props) => props.theme.fontBody1};
    font-weight: bold;
    margin-bottom: 1rem;
    @media screen and (max-width: 640px) {
      margin-bottom: 0.125rem;
    }

    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.uiPurple};
    }

    &:focus {
      outline: 0;
      border-width: 3px;
    }

    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.introduction};
    }
  }
`;
export const InnerColumnBox = styled.div`
  h3 {
    margin: 1.5rem;
    font-size: ${(props) => props.theme.fontBody2};
  }

  a {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontBody3};
  }
`;
export const NoCardContents = styled.div`
  flex: 1 1 72%;
  @media screen and (min-width: 768px) {
    padding-right: 15%;
  }
`;
export const StyledSelect = styled.select`
  border-color: ${(props) => props.theme.borderColor};
  border-width: 1px;
  ${(props) =>
    props.hasImage &&
    css`
      padding-left: 60px;
    `};

  appearance: none;
  background-color: transparent;
`;
export const StyledOption = styled.option`
  position: relative;
  ${(props) =>
    props.hasImage &&
    css`
      padding-left: 60px;
    `};
  background-image: url(${(props) => (props.cardImage ? props.cardImage : '')});
  background-repeat: no-repeat;
  background-size: 39px 25px;
  background-position: 5px center;
  padding: 8px 8px 8px 50px;
  cursor: pointer;
`;
export const DropdownContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  ${(props) =>
    !props.showCards
      ? css`
          height: 35px;
        `
      : ``};
  ${(props) =>
    !props.showCards
      ? css`
          overflow: hidden;
        `
      : ``};
  padding: 2px;
  border: 1px solid ${(props) => props.theme.borderColor};
  font-size: ${(props) => props.theme.fontBody3};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 100%;
    background: url(/assets/chevron-down-dropdown.svg);
    background-repeat: no-repeat;
    background-position: 15px 5px;
    z-index: 1;
  }
`;
export const CardOption = styled.div`
  position: relative;
  ${(props) =>
    props.hasImage &&
    css`
      padding-left: 60px;
    `};
  background-color: ${(props) => props.theme.whiteColor};
  background-image: url(${(props) => (props.cardImage ? props.cardImage : '')});
  background-repeat: no-repeat;
  background-size: 32px 21px;
  background-position: 5px center;
  padding: 8px 8px 8px 50px;
  font-size: ${(props) => props.theme.fontBody3};
  border-top: 1px solid ${(props) => props.theme.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-of-type {
    border: 0;
  }

  ${(props) =>
    props.confirmPage &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`;
export const CardIcon = styled.div`
  position: relative;
  padding-left: 3.25rem;

  &:before {
    content: '';
    width: 2.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${(props) => (props.cardImage ? props.cardImage : '')});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 1.5rem;
  }

  p {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;
export const PointsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  width: auto;
  margin-right: auto;
  @media screen and (min-width: 641px) {
    ${(props) =>
      props.page === 'pfc' &&
      css`
        padding: 0 1rem;
      `};
  }
`;
export const SummaryWrapper = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 2rem 1rem;

  @media screen and (min-width: 1024px) {
    padding: ${(props) => (props.page === 'giftcard' ? '0px' : '1rem')};
    ${(props) => (props.page === 'pfc' ? 'background-color: transparent' : '')};
    max-width: ${(props) => (props.page === 'pfc' || props.page === 'giftcard' ? '100%' : '30%')};
  }

  @media screen and (max-width: 640px) {
    box-shadow: 0px 2px 10px rgba(0, 34, 91, 0.11);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  @media screen and (max-width: 1023px) {
    ${(props) =>
      props.page === 'byot' &&
      !props.hasBYOTTransactions &&
      css`
        display: none;
      `}
  }

  h4 {
    margin-top: 10px;
  }
`;
export const SummaryHeader = styled.div`
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    width: 100%;
    ${(props) =>
      props.cartHasItems === 0
        ? `display: none;`
        : `
      display: flex;
      justify-content: space-between;
      `}
  }
`;

export const SummaryHeading = styled.h2`
  display: block;
  margin-top: 0.7rem;
  margin-left: 0;
  margin-bottom: 1.5rem;
  margin-right: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody2};
`;

export const AddedItems = styled.div`
  p {
    margin: 0;

    &.selected-transactions {
      margin-bottom: 1rem;
    }
  }
`;
export const TotalBalance = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid ${(props) => props.theme.uiGrey02};
  }

  ${(props) =>
    props.cartHasItems > 0 &&
    `
      @media screen and (min-width: 641px) {
        border-top: 2px solid ${(props) => props.theme.lightGrayColor};
        margin-top: 1rem;
        padding-top: 1.5rem;
      }
  `}

  p {
    margin: 0;
  }
`;
export const SummaryInner = styled.div`
  ${(props) =>
    props.cartHasItems === 0
      ? `
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        margin: 0 auto;
        max-width: 333px;
      }
    `
      : `
      width: 100%;

      button {
        width: 100%;
        @media screen and (min-width: 641px) and (max-width: 1023px) {
          width: auto !important;
        }
      }
      `}
`;
export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  margin-top: 0.5rem;

  .error-red {
    color: ${(props) => props.theme.uiErrorRed} !important;
  }

  .points-total {
    font-weight: bold;

    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.headingMedium2};
    }

    @media screen and (min-width: 768px) {
      font-weight: inherit;
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;
export const MobileTransactionsList = styled.div`
  width: 100%;

  .transaction-cell {
    display: block;
    max-width: 100% !important;
    flex-grow: 1;

    .pfc-transac-name {
      margin-bottom: 0.5rem !important;
    }
  }

  .mobile-tranac-amount {
    max-width: 30%;
  }
`;
export const StyledTransactions = styled.div`
  flex-grow: 1;

  @media screen and (min-width: 1024px) {
    ${(props) => (props.page === 'byot' ? 'margin-right: 30px' : '')};
  }
`;
export const GroupedTransaction = styled.div`
  .mobile-transaction-date {
    border-bottom: 1px solid ${(props) => props.theme.uiGrey02};
    padding-bottom: 0.5rem;
  }
`;
export const DetailsWrapper = styled.div`
  padding: 3rem 3.5rem 3.5rem 3.5rem;
  background-color: ${(props) => props.theme.whiteColor};
  margin-bottom: 8rem;

  @media screen and (max-width: 640px) {
    padding: 0 0 10rem 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding: 1.5rem 3rem 3rem 3rem;
    margin-bottom: 0;
  }
`;
export const SectionDetails = styled.div`
  display: block;
  align-items: flex-start;
  padding: 1rem 1rem 0;

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  ${(props) =>
    props.items &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.email &&
    css`
      margin-top: 1.5rem;
      border-top: 2px solid ${(props) => props.theme.lightGrayColor5};
      padding-bottom: 2rem;
    `}

    @media screen and (max-width: 767px) {
    ${(props) =>
      props.account &&
      css`
        margin-top: 1rem;
        margin-bottom: 1rem;
      `}
  }

  ${(props) =>
    props.termsAndConditions &&
    css`
      padding-bottom: 5rem;
    `}

  &.border-bottom {
    border-bottom: 2px solid ${(props) => props.theme.lightGrayColor5};
    padding-bottom: 2rem;
  }

  @media screen and (min-width: 641px) {
    display: flex;
    padding: 1.5rem 1.5rem 0;
  }

  &.points-summary {
    @media screen and (min-width: 641px) {
      border-top: 2px solid ${(props) => props.theme.lightGrayColor5};
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }

    @media screen and (max-width: 640px) {
      box-shadow: 0px 2px 10px rgba(0, 34, 91, 0.11);
      background-color: ${(props) => props.theme.whiteColor};
      padding: 2rem 1rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
`;
export const SectionTitle = styled.h2`
  flex: 1;
  color: ${(props) => props.theme.lightGrayColor4};
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontBody3};
  font-weight: bold;
  margin-bottom: 1rem;

  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.fontBody2};
  }

  &.selected-transactions-heading {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
`;
export const DetailsInfo = styled.div`
  flex: 1;

  p {
    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody2};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .transaction-name {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;

    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody2};
      font-weight: normal;
    }
  }

  .amount-points {
    color: ${(props) => props.theme.lightGrayColor4};

    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }

  .credit-points {
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.lightGrayColor4};
    padding: 24px 14px;
  }

  @media screen and (max-width: 767px) {
    .primary-button {
      font-size: ${(props) => props.theme.fontBody2};
      width: 100%;
      max-width: 100%;
      margin-top: 1rem !important;
    }
    ${(props) =>
      props.items &&
      css`
        flex: 3;
      `}
  }
`;
export const EmptyTransactions = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  padding: 4rem 0 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  h4 {
    font-size: ${(props) => props.theme.fontBody3};
  }

  .no-transactions-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 767px) {
    padding-right: 1.125rem !important;
    padding-left: 1.125rem !important;
    padding-bottom: 0;
  }
`;

export const EmptyTransactionHeading = styled.h2`
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontBody2};
`;

export const EmptyTransactionsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 768px) {
    max-width: 60%;
  }

  h3 {
    font-size: ${(props) => props.theme.fontBody2};
    margin-bottom: 0;
  }
  p {
    font-size: ${(props) => props.theme.fontBody2};
  }
`;
export const StyledAwardsList = styled.div`
  position: relative;
  z-index: 1;

  &.pfc-awards-list {
    background-color: ${(props) => props.theme.whiteColor};
    padding: 1rem;
    max-width: 30.25rem;
    @media screen and (max-width: 640px) {
      padding: 1rem 0;
    }

    @media screen and (min-width: 641px) {
      flex: 0 0 60%;
    }
  }

  &.byot-transactions {
    padding-bottom: 1px !important;
    z-index: 1;

    @media screen and (min-width: 641px) {
      flex: 0 0 50%;
    }
  }
`;
export const SectionHeader = styled.h3`
  font-size: ${(props) => props.theme.fontBody1};
`;
export const BanksList = styled.div`
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
`;
export const NoCardSectionHeader = styled.h3`
  font-size: ${(props) => props.theme.fontBody3};
`;
export const CardAvatar = styled.div`
  position: relative;
  width: 73px;
  height: 73px;
  margin-right: 28px;

  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const CardAvatarWrapper = styled.div`
  flex: 1 1 10%;
  align-self: flex-start;
`;
export const BackgroundHero = styled.div`
position: relative;
background-color: ${(props) => props.theme.uiGrey01};
min-height: 24rem;
div {
  img {
    height: 60% !important;
    width: inherit !important;
    min-height: 60% !important;
    max-height inherit !important;
    min-width: inherit !important;
    inset: initial !important;
    top: 2rem !important;
    bottom: 5rem !important;

    @media screen and (max-width: 1024px) {
      height: 40% !important;
      min-height: 40% !important;
    }

  }
}
`;

export const BackgroundHeroPFC = styled.div`
  height: 18rem;
  .img-container {
    position: absolute;
    height: 14.375rem;
    width: 21.25rem;
    bottom: 0 !important;
    img {
      height: 14.375rem;
      width: 21.25rem;
    }
  }
`;

export const SuccessWrapper = styled.div`
  padding: 5rem 0;
  background-color: ${(props) => props.theme.whiteColor};
`;
export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: relative;
`;
export const SuccessBannerAvatar = styled.div`
  position: relative;
  width: 140px;
  height: 170px;
  margin: 0 auto;
`;
export const SuccessContent = styled.div`
  text-align: center;
  color: ${(props) => props.theme.uiBlack};
  margin: 1rem auto 0;

  @media screen and (min-width: 641px) {
    max-width: 28rem;
  }

  h2 {
    color: ${(props) => props.theme.uiCharcoal};
  }

  p {
    margin-bottom: 0;
    line-height: ${(props) => props.theme.lineHeight3};
    font-size: ${(props) => props.theme.fontBody3};
  }

  .secondary-button {
    padding: 1rem 2rem;
  }
`;
export const SuccessHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  .success-hero {
    width: 100%;
    height: 250px;
  }

  .mobile-success-hero {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 1rem auto 0;
  }
`;
export const CardApplyItem = styled.div`
  padding: 1.5rem 0;
  background-color: ${(props) => props.theme.whiteColor};
  @media screen and (min-width: 641px) {
    flex: 0 0 48%;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
`;

export const SectionHeaderBYOT = styled.h2`
  font-size: ${(props) => props.theme.fontBody3};
  font-weight: bold;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
`;
