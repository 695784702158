import { useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import OffersSliderTile from './OffersSliderTile';
import SpotlightModal from '../Modals/SpotlightModal';
import WrapperLayout from '../../layouts/WrapperLayout';

// Styles
import { FeatureCarouselWrapper, SectionTitle } from './BannerStyles';

// Hooks
import useSpotlights from '../../hooks/announcements/useSpotlights';
import useModal from '../../hooks/utils/useModal';

// Helpers
import { bannerSettings } from '../../../helpers/bannersHelper';
import { sortSpotlightsByPriority } from '../../../helpers/spotlightHelper';
import { checkArrayForItems } from '../../../helpers/utils';

export default function SpotlightAnnouncementBanner({ customData, languageCode }) {
  const {
    actions,
    state: { offers },
  } = useStateMachine({ updateOffers });

  const { title, page } = customData;
  const { spotlights, isLoading, error } = useSpotlights(languageCode);

  useEffect(() => {
    if (!isLoading && spotlights) {
      actions.updateOffers(spotlights, isLoading);
    }

    actions.updateOffers({ isLoading });
  }, [spotlights, isLoading]);

  const { isShowing, toggle, currentItemId } = useModal();
  let banners;
  let isLightBlack;
  let maximumSlides;
  const spotlightPage = page.fields.name || null;
  const spotlightList =
    offers &&
    offers.spotlights &&
    spotlightPage &&
    offers.spotlights.filter((spotlight) => spotlight.cmsContent.page === spotlightPage);

  switch (spotlightPage) {
    case 'Home':
      banners = sortSpotlightsByPriority(spotlightList).slice(0, 2);
      isLightBlack = true;
      maximumSlides = 2;
      break;
    case 'BNS Home':
      banners = sortSpotlightsByPriority(spotlightList).slice(0, 1);
      isLightBlack = false;
      maximumSlides = 1;
      break;

    default:
      break;
  }

  const settings = bannerSettings(banners, maximumSlides);

  if (checkArrayForItems(banners)) {
    return (
      <WrapperLayout className="global-content-wrapper" isLightBlack={isLightBlack} customStyles="spotlights">
        <FeatureCarouselWrapper bannersNum={banners.length}>
          {title && <SectionTitle isLightBlack={isLightBlack}>{title}</SectionTitle>}

          <Slider {...settings}>
            {banners.map((item, index) => (
              <OffersSliderTile
                id={item.spotlightId}
                index={`spotlight-${index}`}
                content={item}
                key={index}
                bannersNum={banners.length}
                languageCode={languageCode}
                customData={customData}
                isShowing={isShowing}
                toggle={toggle}
                isLightBlack={isLightBlack}
                sectionTitle={title}
                bannerType="Spotlights"
              />
            ))}
          </Slider>

          <SpotlightModal
            isShowing={isShowing}
            toggle={toggle}
            currentItemId={currentItemId}
            list={banners}
            customData={customData}
            languageCode={languageCode}
            sectionTitle={title}
          />
        </FeatureCarouselWrapper>
      </WrapperLayout>
    );
  }
  return null;
}

SpotlightAnnouncementBanner.propTypes = {
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
