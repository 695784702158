import { useState, useEffect } from 'react';
import Slider from 'react-slick';

// Components

import { EventSection, EventSectionTitle, CardCarouselWrapper, GridWrapper } from './BannerStyles';
import EventCard from '../Cards/EventCard';
import CardModal from '../Modals/CardModal';
import Loader from '../Loader/Loader';

// Hooks
import useModal from '../../hooks/utils/useModal';
import useEvents from '../../hooks/events/useEvents';

// Helpers
import { handleGaEcommWhatIsMore } from '../../../helpers/handleGoogleAnalyticsHelper';
import { cardSliderSettings } from '../../../helpers/bannersHelper';
import { checkArrayForItems } from '../../../helpers/utils';

const EventsSlider = ({ languageCode, slides, isSlider, sectionTitle, openDetailsModalLabel }) => {
  const [events, setEvents] = useState([]);

  const offersNum = events.length;
  const reqBody = {
    filters: {},
    pagination: {
      page: 1,
      limit: parseInt(slides),
    },
  };
  useEffect(() => {
    if (events && events.length) {
      handleGaEcommWhatIsMore('events_view_item_list', sectionTitle, events);
    }
  }, [events]);

  const { events: eventsData, isLoading, error } = useEvents(languageCode, reqBody);

  useEffect(() => {
    if (eventsData) {
      setEvents(eventsData.slice(0, slides));
    }
  }, [eventsData]);

  const settings = cardSliderSettings(offersNum);
  const { isShowing, toggle, currentItemId } = useModal();

  if (isLoading) {
    return (
      <EventSection>
        <div>
          <Loader />
        </div>
      </EventSection>
    );
  }

  if (offersNum > 0) {
    return (
      <>
        <EventSection>
          {sectionTitle && (
            <EventSectionTitle customStyles="Events">
              {sectionTitle}

              {isSlider && offersNum > 2 && <span>({offersNum})</span>}
            </EventSectionTitle>
          )}
          {isSlider && offersNum > 2 ? (
            <CardCarouselWrapper offersNum={offersNum}>
              <Slider {...settings}>
                {offersNum &&
                  checkArrayForItems(events) &&
                  events.map((item, index) => (
                    <EventCard
                      id={`card-${index}`}
                      key={index}
                      content={item}
                      offersNum={offersNum}
                      toggle={toggle}
                      openDetailsModalLabel={openDetailsModalLabel}
                      languageCode={languageCode}
                      sectionTitle={sectionTitle}
                    />
                  ))}
              </Slider>
            </CardCarouselWrapper>
          ) : (
            <GridWrapper>
              <div className="grid-row">
                {checkArrayForItems(events) &&
                  events.map((item, index) => (
                    <div className="grid-item" key={index}>
                      <EventCard
                        id={`cardgrid-${index}`}
                        content={item}
                        offersNum={offersNum}
                        toggle={toggle}
                        openDetailsModalLabel={openDetailsModalLabel}
                        languageCode={languageCode}
                        sectionTitle={sectionTitle}
                        isGrid
                      />
                    </div>
                  ))}
              </div>
            </GridWrapper>
          )}
        </EventSection>

        <CardModal
          isShowing={isShowing}
          toggle={toggle}
          currentItemId={currentItemId}
          list={events}
          languageCode={languageCode}
          sectionTitle={sectionTitle}
          card="event"
        />
      </>
    );
  }
  return null;
};

export default EventsSlider;
