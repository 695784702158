import { isEmpty } from './utils';

export const pasrseAccessTokenResponse = (accessTokenResponse = {}) => {
  if (isEmpty(accessTokenResponse)) return;

  const { accessToken, expiresOn } = accessTokenResponse;

  return {
    accessToken,
    expiresOn: Date.parse(expiresOn),
  };
};

/**
 *
 * @param {object} config
 * @default {shape} { isLoggedIn: false, accessToken: null, signInProgress: false }
 *
 * @param function updateSession coming from LSM actions
 *
 */
export const setSessionManager = (accessTokenResponse, updateSession) => {
  if (isEmpty(accessTokenResponse)) return;

  const sessionConfig = pasrseAccessTokenResponse(accessTokenResponse);

  updateSession(sessionConfig);
};
