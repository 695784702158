/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { Controller, useFormContext } from 'react-hook-form';

import updatePoints from '../../../store/actions/updatePointsAction';

// Helpers
import { calcPointsNeeded, calcPointsTotal, formatPoints } from '../../../helpers/pointsHelpers';
import { formatToCurrency } from '../../../helpers/utils';

import { Row, TableRowItem, TableRowPointsItem } from '../../common/Table/TableStyles';
import { Wrapper, MobileTransactionsList } from '../Shop/PointsShopStyles';

import PointsRowError from './PointsRowError';

function MobilePointsListRow({
  name,
  fieldType,
  rules,
  isDisabled,
  transaction,
  languageCode,
  customData: { notEnoughPointsLabel, needMorePointsButtonLabel },
}) {
  const {
    state: {
      points: { added },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const { id, transactionDescription, transactionAmount, points } = transaction;
  const transactionName = transaction.name;
  const { actions } = useStateMachine({ updatePoints });
  const [checkbox, setCheckbox] = useState(false);

  const total = calcPointsTotal(added);
  const pointsNeeded = calcPointsNeeded(added, balance);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;

  const formContext = useFormContext();
  const { control, setValue } = formContext;

  useEffect(() => {
    setValue(name, checkbox, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox]);

  const checkboxData = {
    id,
    name,
    transactionName,
    transactionAmount,
    points,
  };

  const handleChange = (e) => {
    setCheckbox(e.target.checked);
    setValue(name, e.target.checked);

    if (e.target.checked) {
      actions.updatePoints({ added: [...added, checkboxData] });
    } else {
      let hardCopy = [...added];
      hardCopy = hardCopy.filter((cartItem) => cartItem.name !== name);

      actions.updatePoints({ added: hardCopy });
    }
  };

  return (
    <Wrapper role="rowgroup">
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: async (value) => {
            if (notEnoughPoints && value) {
              return notEnoughPointsLabel;
            }
          },
        }}
        render={({ field: { onBlur, name, value }, fieldState: { error } }) => (
          <MobileTransactionsList>
            <Row id={`transaction-${id}`} role="row" className="row-list" error={error}>
              <TableRowItem center role="cell" className="table-checkbox">
                <input
                  name={name}
                  className="points-checkbox"
                  id={name}
                  type={fieldType}
                  aria-invalid={!!error}
                  role="checkbox"
                  aria-checked={!!value}
                  aria-labelledby={`option-${name}`}
                  aria-disabled={isDisabled}
                  disabled={isDisabled}
                  onBlur={onBlur}
                  checked={!!value}
                  onChange={(e) => handleChange(e)}
                />
              </TableRowItem>
              <TableRowItem className="transaction-cell" role="cell">
                <label id={`option-${name}`} htmlFor={name} style={{ margin: 0 }} className="pfc-transac-name font-bold block">
                  {transactionDescription}
                </label>
                <p>{formatToCurrency(transactionAmount, languageCode)}</p>
              </TableRowItem>
              <TableRowPointsItem role="cell" className="mobile-tranac-amount">
                {formatPoints(points, locale)}
              </TableRowPointsItem>
            </Row>

            {error && (
              <PointsRowError
                notEnoughPointsLabel={notEnoughPointsLabel}
                needMorePointsButtonLabel={needMorePointsButtonLabel}
                pointsNeeded={pointsNeeded}
                error={error}
              />
            )}
          </MobileTransactionsList>
        )}
      />
    </Wrapper>
  );
}

MobilePointsListRow.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  fieldType: PropTypes.string,
  transaction: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default MobilePointsListRow;
