import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Global states
import { useStateMachine } from 'little-state-machine';

import PointsFilterButtons from './PointsFilterButtons/PointsFilterButtons';

// Styles
import { FilterListSection, FilterListHeading } from '../../../common/Filters/FilterListStyles';

export default function PointsFilterList({ applyFilter, list, title, selectedFilter, referenceCode }) {
  const {
    state: {
      points: { reset },
    },
  } = useStateMachine();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (reset) {
      setSelected('ALL');
    }
  }, [reset]);

  useEffect(() => {
    if (selectedFilter) {
      setSelected(selectedFilter[0]);
    }
  }, []);

  if (list) {
    return (
      <FilterListSection>
        {title && <FilterListHeading>{title}</FilterListHeading>}

        {list.map((item) => (
          <PointsFilterButtons
            key={item.code}
            selected={selected}
            setSelected={setSelected}
            code={item.code}
            name={item.name}
            filterType={referenceCode}
            applyFilter={applyFilter}
            idPrefix={title.toLowerCase().replace(/ /g, '-')}
          />
        ))}
      </FilterListSection>
    );
  }
  return null;
}

PointsFilterList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  applyFilter: PropTypes.bool,
  selectedFilter: PropTypes.array,
  referenceCode: PropTypes.string,
};
