/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import Title from '../common/Headings/Title';
import WrapperLayout from '../layouts/WrapperLayout';
import GuestSettings from '../forms/settings/Fields/GuestSettings';

// Styles
import { Disclaimer, SectionTitle } from '../forms/FormStyles';

const SettingsGuestPage = ({ customData }) => {
  const {
    errorsList,
    pageContent: { settingsPageTitle, preferencesHeader, settingsTermsMessage },
  } = customData;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (!isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding" isGray>
        {settingsPageTitle && <Title className="mb-4">{settingsPageTitle}</Title>}
        {preferencesHeader && <SectionTitle className="mb-4 font-bold">{preferencesHeader}</SectionTitle>}

        <div className="max-w-screen-md">
          <GuestSettings customData={customData.pageContent} errorsList={errorsList} />

          {settingsTermsMessage && (
            <Disclaimer className="px-4 sm:px-0 mt-8">
              <div dangerouslySetInnerHTML={renderHTML(settingsTermsMessage)} />
            </Disclaimer>
          )}
        </div>
      </WrapperLayout>
    );
  }
  return null;
};

SettingsGuestPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'settingsguestform',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.emailTopics) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'emailTopics',
        sortIDField: 'emailTopics_SortIdField',
      });
    }

    return {
      pageContent: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

SettingsGuestPage.propTypes = {
  customData: PropTypes.object,
};
export default SettingsGuestPage;
