import PropTypes from 'prop-types';

import { Tabs, Tab } from './TabStyles';

export default function TabTitle({ tabs, handleClick, handleKeyDown, active, isLarge }) {
  if (tabs) {
    return (
      <Tabs role="tablist">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            id={`tab-${index}`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            active={active === index}
            role="tab"
            aria-selected={active === index}
            aria-controls={`tabpanel-${index}`}
            value={index}
            aria-label={tab.tabTitle}
            isLarge={isLarge}
          >
            {tab.tabTitle}
          </Tab>
        ))}
      </Tabs>
    );
  }
  return null;
}

TabTitle.propTypes = {
  tabs: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  isLarge: PropTypes.bool,
};
