import PropTypes from 'prop-types';
import ExternalImage from '../../../common/Images/ExternalImage';

// Styles
import { EmptyTransactions, EmptyTransactionsContent, EmptyTransactionHeading } from '../PointsShopStyles';

export default function NoTransactions({ customData }) {
  const { nTMessage, nTTitle, nTImage } = customData;

  return (
    <EmptyTransactions>
      <div className="no-transactions-image">{nTImage && <ExternalImage url={nTImage?.url} width={200} height={135} />}</div>
      <EmptyTransactionsContent>
        {nTTitle && <EmptyTransactionHeading className="font-bold mt-2 mb-3">{nTTitle}</EmptyTransactionHeading>}
        {nTMessage && <p>{nTMessage}</p>}
      </EmptyTransactionsContent>
    </EmptyTransactions>
  );
}

NoTransactions.propTypes = {
  customData: PropTypes.object,
};
