import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Components
import SliderTileContents from './SliderTileContents';
// Styles
import { BannerItemV2 } from './BannerStyles';

// Helper
import { handleKeyDown } from '../../../helpers/utils';
import { formatDate } from '../../../helpers/dateHelpers';
import {
  getSpotlightMappingOffer,
  getSpotlightStandaloneMappingOffer,
  getSpotlightDetailsMappingOffer,
} from '../../../helpers/handleGoogleAnalyticsHelper';

export default function OffersSliderTile({
  customData,
  content,
  bannersNum,
  languageCode,
  toggle,
  sectionTitle,
  index,
  bannerType,
}) {
  const { webImage, appImage, ctaLink, eyebrow, title, description, subTitle } = content.cmsContent;
  const { linkedType, endDate } = content;
  const { learnMoreLabel, expireLabel } = customData;
  const isStandalone = linkedType === 'Standalone';

  useEffect(() => {
    try {
      if (content && content.linkedType) {
        getSpotlightMappingOffer('view_promotion', content, sectionTitle, index, bannerType);
      }
    } catch {
      //
    }
  }, []);

  function handleClick(e) {
    if (isStandalone) {
      getSpotlightStandaloneMappingOffer('select_promotion', content, sectionTitle, index);
    } else {
      toggle(content?.spotlightId);
      e.preventDefault();
      getSpotlightDetailsMappingOffer('select_promotion', content, sectionTitle, index);
    }
  }

  return (
    <BannerItemV2 bannersNum={bannersNum}>
      <Link
        href={`${isStandalone ? ctaLink && ctaLink?.href : '#'}`}
        aria-label={`${content?.cmsContent?.title} ${ctaLink?.text}`}
        target={`${isStandalone ? ctaLink?.target : '_self'}`}
        role="link"
        onClick={() => {
          handleClick();
        }}
        tabIndex="0"
        onKeyDown={(e) => handleKeyDown(e, handleClick)}
      >
        <SliderTileContents
          bannersNum={bannersNum}
          webImage={webImage}
          appImage={appImage}
          eyebrow={eyebrow || (endDate && `${expireLabel} ${formatDate(endDate, 'MMM DD', languageCode)}`)}
          heading={title}
          subCopy={subTitle || description}
          learnMoreLabel={isStandalone && ctaLink ? ctaLink?.text : learnMoreLabel}
        />
      </Link>
    </BannerItemV2>
  );
}

OffersSliderTile.propTypes = {
  customData: PropTypes.object,
  content: PropTypes.object,
  bannersNum: PropTypes.number,
  languageCode: PropTypes.string,
  toggle: PropTypes.func,
  sectionTitle: PropTypes.string,
  index: PropTypes.string,
  bannerType: PropTypes.string,
};
