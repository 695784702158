import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import ExternalImage from '../Images/ExternalImage';
import { b2cSignIn } from '../../../b2c/b2cFunctions';
// Styles

import { ModalContainer, ModalIcon, ModalHeader, ModalBody, ButtonContainer, ModalPopupContainer } from './ModalStylesV2';

import Button from '../Button/Button';
import ButtonSimple from '../Button/ButtonSimple';
import CloseIcon from '../Icons/CloseIcon';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function OfferNotFoundModal({ customData, toggle, languageCode }) {
  const { instance } = useMsal();
  const { offerNotFoundTitle, offerNotFoundMessage, offerNotFoundImage, buttonSignIn, buttonBack } = customData;
  const handleSignInClick = () => {
    const dataLayer = {
      event: 'sign_in_from_offernotfound',
      category: 'Homepage guest',
      action: 'Sign In Click',
      label: 'Offer not found',
    };
    handleGAEventsBase(dataLayer);
    b2cSignIn(instance, languageCode);
  };

  if (customData) {
    return (
      <ModalContainer>
        <CloseIcon toggle={toggle} />
        <ModalPopupContainer>
          <ModalHeader>
            {offerNotFoundImage?.url && (
              <ModalIcon>
                <ExternalImage url={offerNotFoundImage?.url} height={80} width={80} />
              </ModalIcon>
            )}
            {offerNotFoundTitle && <h2 id="modal-label">{offerNotFoundTitle}</h2>}
          </ModalHeader>
          {offerNotFoundMessage && <ModalBody>{offerNotFoundMessage}</ModalBody>}

          <ButtonContainer>
            {buttonSignIn && (
              <Button type="button" buttonText={buttonSignIn} ariaLabelledBy={buttonSignIn} onClick={handleSignInClick}>
                {buttonSignIn}
              </Button>
            )}
            {buttonBack && (
              <ButtonSimple
                type="button"
                className="cursor-pointer font-bold mt-4"
                ariaLabelledBy={buttonBack}
                onClick={() => toggle()}
              >
                {buttonBack}
              </ButtonSimple>
            )}
          </ButtonContainer>
        </ModalPopupContainer>
      </ModalContainer>
    );
  }
  return null;
}

OfferNotFoundModal.propTypes = {
  customData: PropTypes.object,
  toggle: PropTypes.func,
  languageCode: PropTypes.string,
};
