import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import EmpireBannersGrid from '../common/List/EmpireBannersGrid';
import { SectionTitle } from '../common/List/EmpireBannersGridStyles';

const SharedAboutEmpireSection = ({ customData, module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles="gridSection">
        {fields.sectionTitle && <SectionTitle>{fields.sectionTitle}</SectionTitle>}
        <EmpireBannersGrid customData={customData} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedAboutEmpireSection.propTypes = {
  module: PropTypes.object.isRequired,
};

SharedAboutEmpireSection.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'nocempirelist',
      languageCode,
    });

    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      empireList: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
export default SharedAboutEmpireSection;
