import { useQuery } from 'react-query';
import { getPreferences } from '../../../pages/api/services';

export default function usePreferences(accessToken) {
  const { isLoading, error, data, refetch } = useQuery('fetchPreferences', async () => {
    try {
      const res = await getPreferences(accessToken);
      const preferencesData = res.data.data;

      return preferencesData;
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, preferencesAPIData: data, refetch };
}
