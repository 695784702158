import { useQuery } from 'react-query';
import { getGiftCardDetails } from '../../../pages/api/services';

export default function useGiftCardDetails(brandId, languageCode) {
  const { isLoading, error, data } = useQuery('getGiftCardDetails', async () => {
    try {
      if (brandId && languageCode) {
        const res = await getGiftCardDetails({ brandId, languageCode });
        const giftCardData = res.data.data;

        return giftCardData;
      }
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, giftCardDetails: data };
}
