import PropTypes from 'prop-types';

// Components
import SiteHeader from './SiteHeader';
import StickHead from '../../Head/StickHead';
// Hooks
import useLocation from '../../../hooks/location/useLocation';
import useAuthentication from '../../../hooks/auth/useAuthentication';
import useSessionTimeout from '../../../hooks/auth/useSessionTimeout';
import useDynamicHeader from '../../../hooks/utils/useDynamicHeader';
import useHasMount from '../../../hooks/utils/useHasMount';
import useStorageRedirect from '../../../hooks/navigation/useStorageRedirect';

export default function MastHead(props) {
  useDynamicHeader();
  useLocation();

  const { languageCode, globalData, req } = props;

  useAuthentication(languageCode);
  useSessionTimeout(languageCode);
  useStorageRedirect(globalData.header?.sitemap);

  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  return (
    <StickHead
      req={req}
      languageCode={languageCode}
      globalLabels={globalData.header?.fields?.globalLabels}
      inlineMsgList={globalData.header.inlineMsgList}
      closeLabel={globalData.header?.fields?.globalLabels[0]?.fields?.closeLabel}
    >
      <SiteHeader {...props} />
    </StickHead>
  );
}

MastHead.propTypes = { languageCode: PropTypes.string, globalData: PropTypes.object, req: PropTypes.object };
