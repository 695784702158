import Link from 'next/link';

import { SiteFooterWrapBottom } from '../../FooterStyles';
import { formatDynamicLabel } from '../../../../../helpers/stringHelpers';

export default function FooterSecondaryBar({ footer }) {
  const { copyrightLabel, footerLinks } = footer.fields;
  const copyright = formatDynamicLabel(copyrightLabel, '##YEAR##', new Date().getFullYear());

  return (
    <SiteFooterWrapBottom>
      <div className="global-content-wrapper">
        <div className="flex flex-col items-center md:items-center lg:h-20 md:h-14 h-auto md:flex-row md:justify-between">
          <div className="footer-bottom-inner-column" />
          <div className="footer-bottom-inner-column bottom-links">
            {footerLinks &&
              footerLinks.map((navitem, index) => (
                <div key={index} className="footer-secondary-links">
                  <Link key={`f-${index}`} href={navitem.fields.page.href} as={navitem.fields.page.href} className="">
                    {navitem.fields.title}
                  </Link>
                </div>
              ))}
            <span className="copyright-desktop">{copyright}</span>
          </div>
        </div>
        <span className="copyright-mobile">{copyright}</span>
      </div>
    </SiteFooterWrapBottom>
  );
}
