import styled from 'styled-components';

export const StyledBreadcrumb = styled.li`
  list-style: none;
  display: inline-block;
  padding-right: 1rem;
  margin-right: 0.5rem;
  margin-top: 1.438rem;
  margin-bottom: 1.438rem;
  font-size: ${(props) => props.theme.fontBody3};
  position: relative;

  a {
    text-decoration: none;
    padding: 0 0.2rem;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 2px solid ${(props) => props.theme.uiPurple};
    }
    &:active {
      color: ${(props) => props.theme.lightGrayColor4};
    }
  }

  &:after {
    content: url(/assets/breadcrumb-chevron.svg);
    position: absolute;
    right: 0;
    top: 0;
  }

  &:last-of-type {
    font-weight: bold;

    &:after {
      content: '';
    }
  }
  &.mobile {
    display: none;
  }
  @media screen and (max-width: 1023px) {
    display: none;
    &.mobile {
      display: inline-block;
      padding-left: 1rem;
      a:before {
        content: url(/assets/breadcrumb-chevron.svg);
        transform: rotate(180deg);
        position: absolute;
        left: 0;
        top: 0;
      }
      a:after {
        content: '';
      }
      a:last-of-type {
        font-weight: normal;
      }
    }
  }
`;
