/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable @next/next/no-img-element */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Disclaimer from '../Disclaimer';
import FAQSectionList from './FAQSectionList';
import { searchAccordionStrings } from '../../../helpers/faqHelpers';
import { isEmpty } from '../../../helpers/utils';

import { QuickSearchStyles, LastUpdated } from './FAQContentStyles';

export default function FAQContent({ fields, customData, languageCode }) {
  const [faqSections, setFaqSections] = useState(null);
  const [searchText, setSearchTxt] = useState('');

  const inputEl = useRef();

  useEffect(() => {
    setFaqSections(customData?.faqData);
    return () => {
      setFaqSections(null);
    };
  }, []);

  const updateSearchText = (e) => {
    setSearchTxt(e.target.value);
  };

  useEffect(() => {
    inputEl.current.focus();
  }, [searchText]);

  const handleChange = () => {
    // check if input item is empty, show original accordion
    if (inputEl.current.value.trim().length === 0) return setFaqSections(customData?.faqData);

    setFaqSections(Object.values(searchAccordionStrings(customData?.faqData, inputEl.current.value.trim())));
  };
  const noFormSubmit = (e) => {
    e.preventDefault();
    handleChange();
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleChange();
    }
  };

  if (!isEmpty(fields)) {
    return (
      <>
        <QuickSearchStyles>
          {fields.lastupdatedheader && <LastUpdated>{fields.lastupdatedheader}</LastUpdated>}

          <form onSubmit={noFormSubmit}>
            <label htmlFor="quickSearch">
              <h2 className="headline-small">{fields.quickSearchlabel}</h2>
            </label>
            <div className="input-section">
              <input
                ref={inputEl}
                className="shadow appearance-none border w-50 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="quickSearch"
                type="text"
                placeholder={fields.searchInputPlaceHolder}
                value={searchText}
                onChange={updateSearchText}
                onKeyDown={handleKeyDown}
              />
              <div className="image-wrapper">
                <Image
                  alt={fields.searchInputPlaceHolder}
                  role="button"
                  src="/assets/magnifying-icon.svg"
                  tabIndex="0"
                  onClick={handleChange}
                  onKeyDown={handleKeyDown}
                  height={20}
                  width={46}
                  style={{
                    maxWidth: '46px',
                  }}
                />
              </div>
            </div>
          </form>
        </QuickSearchStyles>
        <FAQSectionList faqSections={faqSections} languageCode={languageCode} />

        <Disclaimer message={fields.disclaimerMessage} backToTopMessage={fields.backToTop} />
      </>
    );
  }
  return null;
}
FAQContent.propTypes = { fields: PropTypes.object, customData: PropTypes.object, languageCode: PropTypes.string };
