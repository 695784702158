import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';
import { getSavedPreferencesPublic } from '../../../pages/api/services';

export default function usePreferencesPublic() {
  const {
    state: { navigation },
  } = useStateMachine();
  const router = useRouter();

  const { isLoading, error, data, refetch } = useQuery(
    ['fetchPreferencesPublic', router?.query?.q || navigation?.query?.q],
    async () => {
      try {
        const res = await getSavedPreferencesPublic(encodeURIComponent(router?.query?.q || navigation?.query?.q));
        const preferencesData = res.data.data;

        return preferencesData;
      } catch (error) {
        console.error(error);
      }
    }
  );

  return { isLoading, error, guestPreferencesData: data, refetch };
}
