/**
 *
 * @param {*} state existing state
 * @param {*object} payload { k:v }
 * @returns
 */
export default function updateUserCustomerDataAction(state, payload) {
  return {
    ...state,
    user: {
      ...state.user,
      customer: {
        data: {
          ...state.user.customer.data,
          ...payload,
        },
      },
    },
  };
}
