import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

// Components
import Button from '../../../common/Button/Button';

const duration = 300;

const defaultStyle = {
  maxHeight: 0,
  transition: `max-height ${duration}ms ease-in`,
  overflow: 'hidden',
};

const transitionStyles = {
  entering: {
    display: 'block',
    maxHeight: '700px',
    height: 'auto',
  },
  entered: {
    display: 'block',
    maxHeight: '700px',
    height: 'auto',
  },
  exiting: {
    maxHeight: '0',
    transition: `max-height 120ms ease-in`,
  },
  exited: { maxHeight: '0' },
};

export default function EditMSALButtons(props) {
  const { handleB2Ccall, buttonText, isEditing } = props;

  return (
    <Transition
      in={isEditing}
      timeout={{
        appear: 500,
        enter: 300,
        exit: 300,
      }}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className="flex md:px-2 mt-4 pb-4">
            <div>
              <Button type="submit" onClick={handleB2Ccall} ariaLabel={buttonText} role="button">
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
}

EditMSALButtons.propTypes = { handleB2Ccall: PropTypes.func, buttonText: PropTypes.string, isEditing: PropTypes.bool };
