import PropTypes from 'prop-types';
import InnerCardModal from './InnerCardModal';
import ModalLayout from '../../layouts/ModalLayout';
import Portal from '../Portal/Portal';

export default function CardModal({ isShowing, currentItemId, list, toggle, page, card, sectionTitle, languageCode }) {
  if (isShowing && list) {
    return (
      <Portal>
        <span className="overlay" />
        {list.map(
          (item, index) =>
            currentItemId === item.code && (
              <ModalLayout key={index} toggle={toggle} type="modal-info">
                <InnerCardModal
                  page={page}
                  card={card}
                  item={item}
                  languageCode={languageCode}
                  sectionTitle={sectionTitle}
                  index={index}
                />
              </ModalLayout>
            )
        )}
      </Portal>
    );
  }
  return null;
}

CardModal.propTypes = {
  isShowing: PropTypes.bool,
  currentItemId: PropTypes.any,
  list: PropTypes.array,
  toggle: PropTypes.func,
  page: PropTypes.string,
  card: PropTypes.string,
  sectionTitle: PropTypes.string,
  languageCode: PropTypes.string,
};
