// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import EarnRewards from '../common/Sections/EarnRewards';

const GuestEarnRewardsSection = ({ module }) => {
  // get module fields
  const { fields } = module;
  const { state } = useStateMachine();
  const {
    session: { isLoggedIn },
  } = state;

  if (isLoggedIn) {
    return null;
  }

  return <EarnRewards fields={fields} />;
};

export default GuestEarnRewardsSection;
