import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import SettingsTopics from './SettingsTopics';
import SettingsEmailCommunicationCheckbox from './SettingsEmailCommunicationCheckbox';

// Utils
import { disableFieldPending } from '../../../../helpers/settingsHelper';

// Styles
import { DescriptionText, FieldContent } from '../../FormStyles';

export default function SettingsEmailFieldContent({
  isEditing,
  emailExpandedMessage,
  emailPreferencesMessage,
  emailTopics,
  emailOptInLabel,
}) {
  const {
    state: {
      user: { preferencesData, emailOptInChecked },
    },
  } = useStateMachine();

  return (
    <FieldContent>
      <DescriptionText settings className="block mb-0">
        {isEditing ? emailExpandedMessage : emailPreferencesMessage}
      </DescriptionText>

      {isEditing && (
        <div>
          <SettingsTopics emailTopics={emailTopics} disableOptIn={disableFieldPending(preferencesData && preferencesData)} />

          <SettingsEmailCommunicationCheckbox
            fieldType="checkbox"
            label={emailOptInLabel}
            name="EmailOptin"
            alignStart
            defaultChecked={emailOptInChecked}
            isDisabled={disableFieldPending(preferencesData && preferencesData)}
            preferencesData={preferencesData}
          />
        </div>
      )}
    </FieldContent>
  );
}

SettingsEmailFieldContent.propTypes = {
  isEditing: PropTypes.bool,
  emailExpandedMessage: PropTypes.string,
  emailPreferencesMessage: PropTypes.string,
  emailTopics: PropTypes.array,
  emailOptInLabel: PropTypes.string,
};
