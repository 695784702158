import PropTypes from 'prop-types';

// Components
import PointsItem from './PointsItem';

// Utils
import { formatDate, formatDateDayFirst } from '../../../helpers/dateHelpers';

// Styles
import { PointsItemsStyle, PointsDate } from './PointsHistoryStyles';

export default function PointsItems({
  page,
  customData,
  transactionIconTypes,
  accountSegments,
  awardsTypes,
  languageCode,
  ...pointItem
}) {
  return (
    <div>
      <PointsDate>{formatDate(pointItem.pointDate, 'ddd, MMM DD, YYYY', languageCode)}</PointsDate>

      <PointsItemsStyle>
        {page.pointsHistory
          .filter((filterDate) => formatDateDayFirst(filterDate.pointDate) === formatDateDayFirst(pointItem.pointDate))
          .map((item) => (
            <PointsItem
              key={item.pointId}
              customData={customData}
              transactionIconTypes={transactionIconTypes}
              accountSegments={accountSegments}
              awardsTypes={awardsTypes}
              languageCode={languageCode}
              {...item}
            />
          ))}
      </PointsItemsStyle>
    </div>
  );
}

PointsItems.propTypes = {
  page: PropTypes.object.isRequired,
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  transactionIconTypes: PropTypes.array,
  accountSegments: PropTypes.array,
  awardsTypes: PropTypes.array,
};
