/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

// Components
import FeaturedCard from '../Cards/FeaturedCard';
import { ListWrapper, TileWrapper } from './FeaturedCardsListStyles';
// Helpers
import { checkArrayForItems } from '../../../helpers/utils';

function FeaturedCardsList({ featuredCardsList }) {
  if (checkArrayForItems(featuredCardsList)) {
    return (
      <ListWrapper>
        {featuredCardsList.map((item, index) => (
          <TileWrapper key={index}>
            <FeaturedCard item={item} id={index} />
          </TileWrapper>
        ))}
      </ListWrapper>
    );
  }

  return null;
}

FeaturedCardsList.propTypes = { featuredCardsList: PropTypes.array };

export default FeaturedCardsList;
