import styled from 'styled-components';

export const StyledList = styled.ul`
  max-width: 62.25rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem;
  @media screen and (min-width: 641px) and (max-width: 990px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.875rem;
  }
  @media screen and (min-width: 991px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  li {
    color: ${(props) => props.theme.uiCharcoal};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media screen and (min-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      color: ${(props) => props.theme.uiCharcoal};
      font-size: ${(props) => props.theme.fontBody3};
      line-height: ${(props) => props.theme.lineHeight143};
      margin-bottom: 0.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .text-container {
      @media screen and (min-width: 991px) {
        text-align: center;
      }
    }
  }
`;

export const SectionTitle = styled.h2`
  font-size: ${(props) => props.theme.headingExtraSmall};
  text-align: center;
  max-width: 50rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  @media screen and (min-width: 640px) and (max-width: 990px) {
    font-size: ${(props) => props.theme.headingSmall};
    margin-bottom: 2.25rem;
  }
  @media screen and (min-width: 991px) {
    font-size: ${(props) => props.theme.headingMedium};
    margin-bottom: 3.125rem;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 5.625rem;
  min-width: 5.625rem;
  height: 5.625rem;
  transition: border 0.2s ease-in;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 990px) {
    margin-right: 1.5rem;
  }
`;
