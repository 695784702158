import { reverseGoogleGeocode } from '../pages/api/services';

/**
 *
 * @param {String} lat - coords
 * @param {String} lng - coords
 * @param {String} type - a post search filter we can pass to google reverse geocode for returning a specific part of the address
 * @returns {String} url
 */
export function deriveRequestURL(lat, lng, type) {
  let url = `/api/location/?lat=${lat}&lng=${lng}`;

  switch (type) {
    case 'province':
      url = `${url}&resultType=administrative_area_level_1`;
      break;

    default:
      break;
  }

  return url;
}

export async function getAddressFromGeocode({ lat, lng, language, type, accessToken }) {
  if (!lng || !lat || !accessToken) return {};
  /**
   * using lat and long pull from google geocode api
   * parse the province and set that to the current Province
   *
   */
  if (accessToken) {
    const url = deriveRequestURL(lat, lng, type);
    const res = await reverseGoogleGeocode(url, accessToken);

    return res.data;
  }
}

export function getShortAddressObject(object) {
  const address = {};
  // eslint-disable-next-line camelcase
  const { address_components } = object[0];

  address_components.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });

  return address;
}

export function getLongAddressObject(object) {
  const address = {};
  // eslint-disable-next-line camelcase
  const { address_components } = object[0];

  address_components.forEach((element) => {
    address[element.types[0]] = element.long_name;
  });

  return address;
}
