import { useEffect } from 'react';
import { registerGaEvents } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function useRegisterGAFormEvents(touchedFields) {
  useEffect(
    () => () => {
      if (touchedFields) {
        registerGaEvents(touchedFields);
      }
    },
    [touchedFields]
  );
}
