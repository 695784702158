import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getCustomer } from '../../../pages/api/services';

export default function useCustomer() {
  const {
    state: {
      session: { accessToken },
    },
  } = useStateMachine();

  const { isLoading, error, data } = useQuery('fetchCustomer', async () => {
    try {
      if (accessToken) {
        const res = await getCustomer(accessToken);

        return res.data.data.customer;
      }
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, customer: data };
}
