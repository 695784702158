import Slider from 'react-slick';

import { StyledList, SliderSection } from './BrandTileStyles';
import BrandTile from './BrandTile';

import { CarouselWrapper } from '../../Banners/BannerStyles';
// Helpers
import { checkArrayForItems } from '../../../../helpers/utils';
import { sliderSettings } from '../../../../helpers/bannersHelper';

export default function BrandTileList({ data, languageCode, sectionTitle, isSlider }) {
  if (checkArrayForItems(data)) {
    if (isSlider) {
      const settings = sliderSettings(data.length, 6, 4, 2.5);

      return (
        <SliderSection className={isSlider ? 'block' : 'hidden'}>
          <ul>
            <CarouselWrapper offersNum={data.length}>
              <Slider {...settings}>
                {data.map((item, index) => (
                  <BrandTile
                    key={index}
                    id={`brand-${index}`}
                    item={item}
                    languageCode={languageCode}
                    sectionTitle={sectionTitle}
                    isSlider
                  />
                ))}
              </Slider>
            </CarouselWrapper>
          </ul>
        </SliderSection>
      );
    }

    return (
      <div className={isSlider ? 'hidden' : 'block'}>
        <StyledList>
          {data &&
            data.map((item, index) => (
              <BrandTile key={index} id={`brand-${index}`} item={item} languageCode={languageCode} sectionTitle={sectionTitle} />
            ))}
        </StyledList>
      </div>
    );
  }
  return null;
}
