/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import ExternalImage from '../common/Images/ExternalImage';

import WrapperLayout from '../layouts/WrapperLayout';
import Title from '../common/Headings/Title';
import CardApplyItemList from '../common/List/CardApplyItemList';
import { ContentCol, InnerColumnBox, BackgroundHeroPFC } from './Shop/PointsShopStyles';

export default function NoCardApply({ customData }) {
  const {
    noCardTitle,
    image,
    sectionHeader,
    debitCardTitle,
    debitCardMessage,
    debitCardLinkLabelSR,
    debitCardLink,
    creditCardTitle,
    creditCardMessage,
    creditCardLinkSR,
    creditCardLink,
    debitCardImage,
    creditCardImage,
    howItWorksTitle,
    noCardHowItWorksMessage,
  } = customData;

  const bankCards = [
    {
      cardTitle: creditCardTitle,
      cardMessage: creditCardMessage,
      cardLinkLabel: creditCardLinkSR,
      cardLink: creditCardLink,
      cardImage: creditCardImage,
    },
    {
      cardTitle: debitCardTitle,
      cardMessage: debitCardMessage,
      cardLinkLabel: debitCardLinkLabelSR,
      cardLink: debitCardLink,
      cardImage: debitCardImage,
    },
  ];

  return (
    <WrapperLayout className="w-full relative page-bottom-spacing" isGray mobileIsWhite>
      <div className="lg:pt-4 global-content-wrapper">
        <div>
          <Title className="mb-8 lg:mb-12">{noCardTitle}</Title>
        </div>

        <div className="sm:flex justify-between">
          <ContentCol className="sm:pr-8 relative">
            <div>
              {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
              {noCardHowItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(noCardHowItWorksMessage)} />}
            </div>

            <BackgroundHeroPFC className="hidden sm:block w-full">
              <div className="img-container">
                <ExternalImage url={image?.url} fill className="object-cover object-center" />
              </div>
            </BackgroundHeroPFC>
          </ContentCol>
          <ContentCol className="cards-col bg-white">
            <InnerColumnBox>
              {sectionHeader && <h3 className="font-bold text-md mt-8 mb-8">{sectionHeader}</h3>}
              {bankCards && bankCards.length && (
                <CardApplyItemList bankCards={bankCards} className="flex justify-center flex-col" />
              )}
            </InnerColumnBox>
          </ContentCol>
        </div>
      </div>
    </WrapperLayout>
  );
}
NoCardApply.propTypes = { customData: PropTypes.object };
