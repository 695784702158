import styled from 'styled-components';

export const LoadCouponButtonStyles = styled.button`
  position: relative;
  overflow: hidden;
  max-width: 250px;
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0;
  color: ${(props) => (props.loaded ? props.theme.uiCharcoal : props.theme.whiteColor)};
  background-color: ${(props) => (props.loaded ? props.theme.whiteColor : props.theme.uiPurple)};
  border-width: ${(props) => (props.loaded ? `1px` : 0)};
  border-color: ${(props) => (props.loaded ? props.theme.uiCharcoal : `none`)};
  border-style: solid;
  transition: all 0.6s ease-in;

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &:hover,
  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: ${(props) => props.theme.uiCharcoal};
    background-color: ${(props) => props.theme.whiteColor};
    outline: 1px solid ${(props) => props.theme.uiGrey04};
  }

  &:focus {
    border-color: ${(props) => (props.loaded ? props.theme.whiteColor : `none`)};
    outline: 3px solid ${(props) => props.theme.uiPurple} !important;
  }

  @media screen and (max-width: 641px) {
    max-width: 100%;
  }
`;
