/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import { renderHTML } from '@agility/nextjs';
import { SectionHeaderBYOT } from './Shop/PointsShopStyles';

// Components
import CardApplyItemList from '../common/List/CardApplyItemList';
import Title from '../common/Headings/Title';
import { TitleWrapper } from '../common/Headings/TitleStyles';

export default function CardApply({ customData }) {
  const {
    title,
    noCardMessage,
    sectionHeader,
    debitCardTitle,
    debitCardMessage,
    debitCardLinkLabelSR,
    debitCardLink,
    creditCardTitle,
    creditCardMessage,
    creditCardLinkSR,
    creditCardLink,
    debitCardImage,
    creditCardImage,
  } = customData;

  const bankCards = [
    {
      cardTitle: creditCardTitle,
      cardMessage: creditCardMessage,
      cardLinkLabel: creditCardLinkSR,
      cardLink: creditCardLink,
      cardImage: creditCardImage,
    },
    {
      cardTitle: debitCardTitle,
      cardMessage: debitCardMessage,
      cardLinkLabel: debitCardLinkLabelSR,
      cardLink: debitCardLink,
      cardImage: debitCardImage,
    },
  ];

  return (
    <div className="global-content-wrapper">
      {title && (
        <TitleWrapper id="page-header-byot}">
          <Title>{title}</Title>
        </TitleWrapper>
      )}

      {noCardMessage && <p className="md:w-2/5" dangerouslySetInnerHTML={renderHTML(noCardMessage)} />}
      {bankCards && bankCards.length && (
        <div className="lg:py-20 md:py-16 py-6">
          {sectionHeader && <SectionHeaderBYOT>{sectionHeader}</SectionHeaderBYOT>}

          <CardApplyItemList bankCards={bankCards} className="flex justify-between flex-col lg:flex-row" />
        </div>
      )}
    </div>
  );
}

CardApply.propTypes = {
  customData: PropTypes.object,
};
