/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import ExternalImage from '../../common/Images/ExternalImage';
import { useRouter } from 'next/router';
import { renderHTML } from '@agility/nextjs';

import { useStateMachine } from 'little-state-machine';

import Title from '../../common/Headings/Title';
import Button from '../../common/Button/Button';
import Loader from '../../common/Loader/Loader';

// state-helpers
import { updateTransactions, updateGiftCards, updateGiftCardsRedeem, updateUser } from '../../../store/actions';

// Helpers
import { formatDynamicLabel } from '../../../helpers/stringHelpers';
import { amountSelected, calcPointsTotal, calcTotalDollar } from '../../../helpers/pointsHelpers';
import { gaExternalSku } from '../../../helpers/giftCardsHelper';
import { SuccessWrapper, SuccessContainer, SuccessContent, SuccessHeader, SuccessBannerAvatar } from './PointsShopStyles';
import {
  getConfirmPointsForCreditMapped,
  handleGaEcommImpression,
  getGiftCardOrderMapping,
} from '../../../helpers/handleGoogleAnalyticsHelper';

export default function ConfirmedRedeemed({ customData, page }) {
  // Global State
  const {
    state: {
      user: { customer },
      giftCards: { redeem, selectedGiftCardIndex },
      points: { added },
      transactions: { orderNumber, awards },
    },
    actions,
  } = useStateMachine({ updateTransactions, updateGiftCards, updateGiftCardsRedeem, updateUser });

  const {
    orderDetails,
    emailConfirmation,
    viewOrdersButtonLabel,
    successHeader,
    keepShoppingLink,
    viewOrdersLink,
    successImage,
  } = page === 'giftcard' ? customData.pageContent : customData;

  const total = calcPointsTotal(added);
  const totalAmount = added[0].transactionAmount;
  const totalDollar = calcTotalDollar(added);

  useEffect(
    () => () => {
      if (orderNumber > 0) {
        actions.updateTransactions({
          orderNumber: 0,
        });

        if (page === 'giftcard') {
          actions.updateGiftCards({ selectedGiftCard: {}, selectedGiftCardIndex: null });
          actions.updateGiftCardsRedeem({
            step: 1,
            giftCardDetails: {},
            selectedFormat: '',
            quantity: 1,
          });
          actions.updateUser({
            shippingAddresses: {},
            selectedShippingAddress: {},
          });
        }
      }
    },
    [orderNumber]
  );

  useEffect(() => {
    if (page === 'byot') {
      const awardProductCodeBYOT = awards
        ? awards.awardProduct.filter((award) => award.id === added.id).map((items) => items.code)
        : '';
      if (orderNumber > 0) {
        handleGaEcommImpression(
          'byot_purchase',
          getConfirmPointsForCreditMapped(
            orderNumber,
            'Points For Travel',
            totalAmount,
            awardProductCodeBYOT[0],
            totalAmount,
            total,
            'points_Travel_purchase',
            'Points For Travel Redemption',
            totalDollar
          )
        );
      }
    } else if (page === 'pfc') {
      const awardProductCodePFC = added
        ? added
            .filter((award) => award.id === awards.awardProduct.id)
            .map((items) => items.code)
            .toString()
        : '';
      if (orderNumber > 0) {
        handleGaEcommImpression(
          'p4c_purchase',
          getConfirmPointsForCreditMapped(
            orderNumber,
            'Points For Credit',
            total,
            awardProductCodePFC,
            `${amountSelected(added)}`,
            total,
            'points_credit_purchase',
            'Points For Credit Redemption',
            totalDollar
          )
        );
      }
    } else if (page === 'giftcard') {
      if (orderNumber > 0) {
        let aggregateTotal;

        if (redeem?.quantity > 1 && !Number.isNaN(total * redeem.quantity)) {
          aggregateTotal = total * redeem.quantity;
        } else {
          aggregateTotal = total;
        }
        const amount = amountSelected(added);
        const externalSku = gaExternalSku(added, redeem?.selectedFormat, redeem?.giftCardDetails);
        handleGaEcommImpression(
          'gc_purchase',
          getGiftCardOrderMapping(
            aggregateTotal,
            redeem,
            amount,
            orderNumber,
            'reward_giftcard_purchase',
            'Gift Card Redemption',
            selectedGiftCardIndex,
            amount,
            externalSku
          )
        );
      }
    }
  }, [orderNumber]);

  const router = useRouter();
  const loading = !customer.data;

  return (
    <SuccessWrapper>
      <SuccessContainer className="mt-8">
        <SuccessHeader>
          <div className="success-hero hidden md:block">
            <ExternalImage url={successImage.url} fill className="object-contain object-center" />
          </div>

          <SuccessBannerAvatar className="block md:hidden">
            <ExternalImage url={successImage.url} fill className="object-cover object-center" />
          </SuccessBannerAvatar>
        </SuccessHeader>

        <SuccessContent className="px-4 md:px-2">
          {loading ? (
            <Loader width="auto" className="mx-auto justify-center" />
          ) : (
            <Title customStyles="confirmedRedeemed" className="font-bold my-4">
              {formatDynamicLabel(successHeader, '##NAME##', customer?.data?.firstName)}
            </Title>
          )}
          {orderDetails && <div dangerouslySetInnerHTML={renderHTML(orderDetails)} />}
          {orderNumber && <span className="ord-num font-bold mt-1.5 mb-6 block">{orderNumber}</span>}

          {loading ? (
            <Loader width="auto" className="mx-auto justify-center" />
          ) : (
            <div
              dangerouslySetInnerHTML={renderHTML(
                formatDynamicLabel(emailConfirmation, '##EMAIL##', customer?.data?.contacts[0]?.email)
              )}
            />
          )}

          <Button
            extraSpacingTop
            type="submit"
            buttonText={viewOrdersButtonLabel}
            ariaLabel={viewOrdersButtonLabel}
            style={{ marginLeft: '0rem', marginTop: '2rem', width: '100%', maxWidth: '100%' }}
            onClick={() => router.push(viewOrdersLink.href)}
          >
            {viewOrdersLink.text}
          </Button>
          <Button
            type="submit"
            buttonStyle="secondary-button-outline-purple"
            buttonText={keepShoppingLink.text}
            ariaLabel={keepShoppingLink.text}
            style={{ marginTop: '1rem', width: '100%', maxWidth: '100%' }}
            onClick={() => {
              router.push(keepShoppingLink.href);
            }}
          >
            {keepShoppingLink.text}
          </Button>
        </SuccessContent>
      </SuccessContainer>
    </SuccessWrapper>
  );
}

ConfirmedRedeemed.propTypes = { customData: PropTypes.object, page: PropTypes.string };
