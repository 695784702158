/* eslint-disable camelcase */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import ExternalImage from '../Images/ExternalImage';
// Hooks
import useIntersectionObserverGa from '../../hooks/utils/useIntersectionObserverGa';
import useDragDetection from '../../hooks/utils/useDragDetection';
import useScrollDetection from '../../hooks/utils/useScrollDetection';

// Styles
import {
  CardItem,
  CardHeader,
  CardImage,
  CardBody,
  Brand,
  CardContents,
  RewardTitle,
  RewardBody,
  CardFooter,
} from './BankCardStyles';

// Helpers
import { handleKeyDown } from '../../../helpers/utils';
import { handleGAEventsBase, getBankCardMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function BankCard({ id, content, offersNum, disableCardsLink, cardLinkLabel, gaCategory }) {
  const { handleMouseDown, dragging } = useDragDetection();
  const { scrolling } = useScrollDetection();
  const { pointRewards, image, title, body, link } = content;
  const cardClass = !disableCardsLink && link?.href ? 'card-contents cursor-pointer' : 'card-contents';
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const threshold = [0.8];

  // Used for GA events
  const position = id;
  const card_type = title.trim();
  const category = gaCategory;
  useEffect(() => {
    if (enabled && content && isIntersecting) {
      const event = 'bns_card_impression';
      const action = 'Impression';
      const dataLayer = getBankCardMappedObject(event, action, position, card_type, category);

      handleGAEventsBase(dataLayer);

      setEnabled(false);
    }
  }, [isIntersecting]);

  const handleClick = () => {
    if (content.link?.href) {
      window.open(content.link?.href, content.link?.target || '_blank');
      const event = 'bns_card_click';
      const action = 'Click';
      const dataLayer = getBankCardMappedObject(event, action, position, card_type, category);

      handleGAEventsBase(dataLayer);
    }
  };
  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    if (content.link?.href) {
      handleClick();
    }
  };

  function onIntersect(val) {
    if (isIntersecting !== val) setIsIntersecting(val);
  }

  const targetRef = useRef(null);
  useIntersectionObserverGa({
    target: targetRef,
    onIntersect,
    scrolling,
    enabled,
    threshold,
  });

  return (
    <CardItem id={id} offersNum={offersNum} className="flex card-item card-content-wrapper" ref={targetRef}>
      <CardContents
        className={cardClass}
        {...(!disableCardsLink &&
          link?.href && {
            role: 'link',
            onClickCapture: handleChildClick,
            onMouseDownCapture: handleMouseDown,
            onKeyDown: (e) => handleKeyDown(e, handleClick),
            tabIndex: '0',
            'aria-label': `${title.trim()} ${cardLinkLabel}`,
          })}
      >
        <CardHeader className="w-full">
          <CardImage className="relative w-full h-full">
            {image?.url && (
              <ExternalImage
                url={image?.url}
                fill
                sizes="(max-width: 640px) 100vw, (max-width: 990px) 50vw, 33vw"
                className="object-cover object-center"
              />
            )}
          </CardImage>
        </CardHeader>
        <CardBody>
          <Brand className="mb-2"> {pointRewards}</Brand>

          <RewardTitle dangerouslySetInnerHTML={renderHTML(title.trim())} />
          <RewardBody dangerouslySetInnerHTML={renderHTML(body.trim())} />
          {!disableCardsLink && (
            <CardFooter>
              <span>{link?.href && (link.text || cardLinkLabel)}</span>
            </CardFooter>
          )}
        </CardBody>
      </CardContents>
    </CardItem>
  );
}

BankCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  offersNum: PropTypes.number.isRequired,
  disableCardsLink: PropTypes.bool.isRequired,
  cardLinkLabel: PropTypes.string.isRequired,
};
