import { useState } from 'react';

const TestSSO = (props) => {
  // track the values from input  --> local state
  // 1- add the state to component with useState
  const [postActionBaseUrl, setPostActionBaseUrl] = useState('https://scene-dev-customerweb-p.azurewebsites.net/sso');
  const [domainHint, setDomainHint] = useState('scotia');
  const [pageCode, setPageCode] = useState('PFC');
  const [uiLocale, setUiLocale] = useState('en');

  // upate that onChange of the input
  // where ever you need to use that, refernce the state variable

  const url = `${postActionBaseUrl}?domain_hint=${domainHint}&ui_locale=${uiLocale}&redirect_uri=${pageCode}`;
  const ssoForm = (
    <form name="uploadForm" method="post" action={url}>
      <label htmlFor="post_action_base_url">
        bff base url
        <input
          type="text"
          id="domain_hint"
          name="post_action_base_url"
          value={postActionBaseUrl}
          onChange={(e) => setPostActionBaseUrl(e.target.value)}
        />
      </label>

      <label htmlFor="domain_hint">
        domain hint
        <input
          type="text"
          id="domain_hint"
          name="domain_hint"
          value={domainHint}
          onChange={(e) => setDomainHint(e.target.value)}
        />
      </label>

      <label>
        page code
        <input type="text" id="page_code" name="page_code" value={pageCode} onChange={(e) => setPageCode(e.target.value)} />
      </label>

      <label>
        ui locale
        <input type="text" id="ui_locale" name="ui_locale" value={uiLocale} onChange={(e) => setUiLocale(e.target.value)} />
      </label>

      <button type="submit" name="button">
        SSO into SCENE
      </button>
      <br />
      <br />
      <br />
      <p>Posting to:</p>
      <p>
        <strong>{url}</strong>
      </p>
    </form>
  );

  return (
    <div className="WrapperLayout__Wrapper-app__sc-1pfb3k6-0 form-container p-0 my-24 mt-0 bg-white text-base rounded-lg relative shadow-xl w-full max-w-xl mx-auto">
      {ssoForm}
    </div>
  );
};

export default TestSSO;
