/* eslint-disable react/destructuring-assignment */

import PropTypes from 'prop-types';
import { ContentZone } from '@agility/nextjs';
import { getModule } from '../agility-pageModules';

import MainContentPadding from '../common/Layouts/MainContent/MainContentPadding';
import SiteFooter from '../common/Layouts/Footer/SiteFooter';
import MastHead from '../common/Layouts/Header/MastHead';
// Styles
import { SkipToContent } from '../common/Layouts/Header/HeaderStyles';

const ErrorTemplate = (props) => (
  <>
    <SkipToContent href="#mainContent">
      <span>{props.globalData?.header?.fields?.skipToContentLabel}</span>
    </SkipToContent>

    <MastHead {...props} />
    <MainContentPadding />
    <main id="mainContent" className="relative focus:outline-none">
      <ContentZone name="MainContentZone" {...props} getModule={getModule} />
    </main>
    <div id="portal" />
    <SiteFooter {...props} />
  </>
);
export default ErrorTemplate;

ErrorTemplate.propTypes = {
  globalData: PropTypes.object,
  languageCode: PropTypes.string,
};
