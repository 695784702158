// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import FeatureBanner from '../common/Banners/FeatureBanner';
import WrapperLayout from '../layouts/WrapperLayout';
import { FeatureCarouselTitle } from '../common/Banners/BannerStyles';

// Helpers
import { getGASlot } from '../../helpers/bannersHelper';
import { checkArrayForItems } from '../../helpers/utils';

const FeatureBannersCarousel = ({ module, languageCode, page }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const { banners, title, maximumSlides } = module.fields;

  const slot = getGASlot(page?.zones, module.contentID);

  const maxSlides = maximumSlides ? parseInt(module.fields?.maximumSlides) : 0;
  const displayBanners = banners && banners.slice(0, maxSlides);
  if (isLoggedIn && checkArrayForItems(displayBanners)) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles={title ? 'featureBannerWithTitle' : 'defaultPadding'}>
        {title && <FeatureCarouselTitle>{title}</FeatureCarouselTitle>}

        <FeatureBanner
          banners={displayBanners}
          languageCode={languageCode}
          customData={module.fields}
          bannerType="FeatureBanner"
          pageName={page.name}
          slot={slot}
          sectionTitle={title}
        />
      </WrapperLayout>
    );
  }
  return null;
};

export default FeatureBannersCarousel;
