/* eslint-disable react/no-danger */

import { renderHTML } from '@agility/nextjs';
import PropTypes from 'prop-types';
import PartnersLogoList from './PartnersLogoList';
import { StyledTable, StellarPartnerContainer, ListWrapper, Disclimer } from './PartnersListStyles';

function PartnersList({ list, fields }) {
  if (list && list.length) {
    return (
      <StellarPartnerContainer>
        <StyledTable>
          <thead>
            <tr>
              <th>
                <strong>{fields.captionPartners}</strong>
              </th>
              <th>
                <strong>{fields.captionEarn}</strong>
              </th>
              <th>
                <strong>{fields.captionRedeem}</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td>
                  <span id={`Partners-${item.anchorName}`} />
                  <ListWrapper>
                    <PartnersLogoList logoList={item.logos} />
                  </ListWrapper>
                  <Disclimer dangerouslySetInnerHTML={renderHTML(item.disclaimer)} />
                </td>
                <td dangerouslySetInnerHTML={renderHTML(item.earn)} />
                <td dangerouslySetInnerHTML={renderHTML(item.redeem)} />
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StellarPartnerContainer>
    );
  }

  return null;
}

PartnersList.propTypes = { list: PropTypes.array, fields: PropTypes.object };

export default PartnersList;
