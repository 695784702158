/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

// https://developer.mozilla.org/en-US/docs/web/api/geolocation/getcurrentposition
const defaultOptions = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
  shouldQuery: true,
};

const useBrowserGeolocation = (watch = false, userOptions = {}) => {
  // initialize the options object
  const options = {
    ...defaultOptions,
    ...userOptions,
  };

  const [location, setLocation] = useState({});
  const [error, setError] = useState(null);

  // success object returns a geolocation position { coords, timestamp }
  const onLocationSuccess = ({ coords, timestamp }) => {
    // record the time and coordinates into our local state
    setLocation({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError = (error) => {
    setError(error.message);
  };

  const getCoords = () =>
    new Promise((resolve, reject) => {
      // one time location lookup
      window.navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

  const getUserGeolocation = async () => {
    if (!options.shouldQuery) return;

    if (!window.navigator || !window.navigator.geolocation) {
      setError('Geolocation not available');
      return;
    }

    // register location subscriber and clean up functions when tacking enabled
    if (watch) {
      const locationSubscriber = window.navigator.geolocation.watchPosition(onLocationSuccess, onError, options);
      return () => window.navigator.geolocation.clearWatch(locationSubscriber);
    }

    try {
      const coords = await getCoords();

      onLocationSuccess(coords);
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    getUserGeolocation();
  }, [options.enableHighAccuracy, options.timeout, options.maximumAge, options.shouldQuery]);

  return { ...location, error };
};

export default useBrowserGeolocation;
