import PropTypes from 'prop-types';
import { TitleHeading } from './TitleStyles';

export default function Title({ className, children, customStyles }) {
  if (children) {
    return (
      <div className={`${className || ''}`}>
        <TitleHeading customStyles={customStyles}>{children}</TitleHeading>
      </div>
    );
  }
  return null;
}

Title.propTypes = { className: PropTypes.string, children: PropTypes.node, customStyles: PropTypes.string };
Title.defaultProps = { customStyles: '' };
