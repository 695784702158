import { useEffect, useState } from 'react';

import Link from 'next/link';

// Styles
import { AccountPointsWrapper, DividerVertical } from '../HeaderStyles';
import { totalPointsToLocalString } from '../../../../../helpers/pointsHelpers';

// Helpers
import { handleGAEvents } from '../../../../../helpers/handleGoogleAnalyticsHelper';

export default function AccountPoints({ pointsPageLink, balance, ptsLabel, languageCode, isDark }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleScrollPosition() {
      const scroll = window.scrollY;
      const scrollPositionToUpdate = 150;
      if (scroll > scrollPositionToUpdate) {
        setShow(true);
      } else {
        setShow(false);
      }
    }

    window.addEventListener('scroll', handleScrollPosition);
    return () => window.removeEventListener('scroll', handleScrollPosition);
  }, []);

  if (!isDark) {
    return (
      <AccountPointsWrapper>
        <Link
          href={pointsPageLink.href}
          locale={languageCode}
          className="block mr-0 relative"
          onClick={() => {
            handleGAEvents('See Points Details', 'Home', 'See Points Details Click', 'home_points_detail_click');
          }}
        >
          <>
            {balance && totalPointsToLocalString(balance, languageCode)}
            <span className="pts" aria-hidden="true">
              {ptsLabel}
            </span>
            <span className="sr-only">points</span>
            <div id="portal-rewards2" />
          </>
        </Link>
        <DividerVertical role="separator" className="tabletHide">
          |
        </DividerVertical>
      </AccountPointsWrapper>
    );
  }

  if (isDark && show) {
    return (
      <AccountPointsWrapper status={show}>
        <Link href={pointsPageLink.href} locale={languageCode} className="block mr-0">
          <>
            {balance && totalPointsToLocalString(balance, languageCode)}
            <span className="pts" aria-hidden="true">
              {ptsLabel}
            </span>
            <span className="sr-only">points</span>
          </>
        </Link>
        <DividerVertical role="separator" className="tabletHide">
          |
        </DividerVertical>
      </AccountPointsWrapper>
    );
  }

  return null;
}
