import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMsal } from '@azure/msal-react';

// Global state
import { useStateMachine } from 'little-state-machine';
import updateStepAction from '../../../store/actions/updateStepAction';
import { updateUser } from '../../../store/actions';
import clearAction from '../../../store/actions/clearAction';

// Components
import Button from '../../common/Button/Button';

// Styles
import { ResultContainer, TitleWithIcon, FormRegisterTitle } from '../FormStyles';

// Helpers
import { b2cSignIn } from '../../../b2c/b2cFunctions';

export default function Result({ finishHeader, finishSubheader, signInLabel, languageCode }) {
  const { actions } = useStateMachine({
    updateUser,
    updateStepAction,
    clearAction,
  });
  const router = useRouter();

  const { instance } = useMsal();

  useEffect(() => {
    if (router) {
      const handleRouteComplete = () => {
        actions.updateUser({ data: '' });
        actions.clearAction({ data: '' });
        actions.updateStepAction({ step: 1 });
      };

      router.events.on('routeChangeComplete', handleRouteComplete);

      return () => {
        router.events.off('routeChangeComplete', handleRouteComplete);
      };
    }
  }, [router]);

  return (
    <ResultContainer>
      <div className="w-full px-4 py-6 sm:py-12 sm:px-14">
        <TitleWithIcon>
          {finishHeader && <FormRegisterTitle className="mr-2">{finishHeader}</FormRegisterTitle>}
          <Image src="/assets/checkmark-circle.svg" alt="" height={33} width={33} role="presentation" />
        </TitleWithIcon>
        {finishSubheader && <p className="mb-12 mt-8">{finishSubheader}</p>}

        {signInLabel && (
          <div className="sm:max-w-xs mx-auto">
            <Button onClick={() => b2cSignIn(instance, languageCode)} style={{ width: '100%' }}>
              {signInLabel}
            </Button>
          </div>
        )}
      </div>
    </ResultContainer>
  );
}
Result.propTypes = {
  finishHeader: PropTypes.string,
  finishSubheader: PropTypes.string,
  signInLabel: PropTypes.string,
  languageCode: PropTypes.string,
};
