import { useInfiniteQuery } from 'react-query';
import { getPointHistory } from '../../../../pages/api/services';
import { getTimeZone } from '../../../../helpers/dateHelpers';

export default function usePointHistory(accessToken, type, category, cardType, fromDate, toDate, limit) {
  const { data, isSuccess, fetchNextPage, isFetching, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
    ['fetchPointHistory', accessToken, type, category, cardType, fromDate, toDate, limit],
    async ({ pageParam = 1 }) => {
      const res = await getPointHistory(
        accessToken,
        type,
        category,
        cardType,
        `${fromDate}T00:00:00${getTimeZone()}`,
        `${toDate}T00:00:00${getTimeZone()}`,
        pageParam,
        limit
      );

      return res.data.data;
    },
    {
      getNextPageParam: (page) => {
        if (page && page?.itemsCount && page?.itemsCount > 18) {
          return null;
        }
        if (page && page?.itemsCount && page?.itemsCount < 18) {
          return false;
        }
        if (page && page?.itemsCount && page?.itemsCount === 0) {
          return false;
        }
        return page && page?.pageNumber && (page?.pageNumber || 0) + 1;
      },
    }
  );
  return {
    data,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
}
