import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updateUser } from '../../../store/actions';

// Components
import Button from '../../common/Button/Button';
import Tooltip from '../../common/Tooltip/Tooltip';
import ErrorMessage from '../../common/Errors/ErrorMessage';

// Services
import { emailMyCard } from '../../../pages/api/services';

// Styles
import { StyledInput } from '../FormStyles';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';
import { generateErrorMessage } from '../../../helpers/errorsHelper';

// Hooks
import useReCaptcha from '../../hooks/services/useReCaptcha';

export default function SendMyCardGuestForm({ customData, fields, toggle }) {
  const { errorsList } = customData;
  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);
  const { emailAddressLabel, sendCardButtonLabelLoading, sendCardButtonLabel, postalCodeLabel, postalCodeTooltip } = fields;
  const { actions } = useStateMachine({ updateNotification, updateUser });
  const { getCaptchaToken } = useReCaptcha('send_my_card');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let result;
    setSubmitting(true);

    if (process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
      result = await getCaptchaToken();
    }

    if (result?.token || process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'true') {
      const res = await emailMyCard(data, { token: result?.token, ip: result?.ip, session: result?.session });

      if (res.data) {
        toggle();
        actions.updateUser({ guestEmail: data?.email });
      } else {
        actions.updateNotification({
          message: generateErrorMessage(
            data?.validationErrors && data?.validationErrors !== null
              ? Object.keys(data?.validationErrors)
              : ['General.BadRequest'],
            errorsList
          ),
          status: 'error',
        });
      }

      setSubmitting(false);
    } else {
      // TODO: right now do nothing if captcha fails
      setSubmitting(false);
      console.error('ReCaptcha failed');
    }
  };

  return (
    <form className="p-0" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <label className="inputLabel" htmlFor="email">
        {emailAddressLabel}
      </label>

      <StyledInput
        name="email"
        id="email"
        aria-label="email-address"
        type="text"
        aria-required
        className={`sm:max-w-sm mb-4 block ${errors?.email ? ' field-has-error' : ''}`}
        {...register('email', {
          required: {
            message: displayFieldErrorMessage(errorsList, 'Contact.Email.Required'),
            value: true,
          },
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Contact.Email.Invalid'),
            // eslint-disable-next-line no-useless-escape
            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
          },
        })}
      />
      {errors && <ErrorMessage error={errors?.email} />}
      <Tooltip dataForId="postal-code" title={postalCodeLabel} message={postalCodeTooltip} placement="inputLabel" />

      <StyledInput
        name="postal-code"
        id="postal-code"
        aria-label="postal-code"
        type="text"
        minLength={6}
        maxLength={7}
        aria-required
        className={`sm:max-w-sm mb-4 block ${errors?.postalCode ? ' field-has-error' : ''}`}
        {...register('postalCode', {
          minLength: {
            message: errorsList.filter((item) => item.code === 'Address.PostalCode.Length').map((item) => item.message)[0],
            value: 6,
          },
          maxLength: {
            message: errorsList.filter((item) => item.code === 'Address.PostalCode.Length').map((item) => item.message)[0],
            value: 7,
          },
          required: {
            message: displayFieldErrorMessage(errorsList, 'Address.PostalCode.Required'),
            value: true,
          },
          pattern: {
            message: displayFieldErrorMessage(errorsList, 'Address.PostalCode.Invalid'),
            value: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
          },
        })}
      />
      {errors && <ErrorMessage error={errors?.postalCode} />}
      <div className="py-6">
        <Button
          type="submit"
          buttonLoadingText={sendCardButtonLabelLoading}
          role="button"
          aria-label={sendCardButtonLabel}
          style={{ width: 'auto' }}
        >
          {sendCardButtonLabel}
        </Button>
      </div>
    </form>
  );
}
SendMyCardGuestForm.propTypes = {
  customData: PropTypes.object.isRequired,
  fields: PropTypes.object,
  toggle: PropTypes.func,
};
