/* eslint-disable no-shadow */
import { useEffect, useMemo } from 'react';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveDateRangeList, deriveErrorMessageList } from '../../helpers/agilityHelpers';
import PointsShop from '../Points/Shop/v2/PointsShop';
import CardApply from '../Points/CardApply';
import usePeriod from '../hooks/points/usePeriod';
import WrapperLayout from '../layouts/WrapperLayout';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';
import useCards from '../hooks/points/useCards';
import TabbedComponent from '../common/Tabs/TabbedComponent';

// helper
import { spendPointsForCreditEcomm } from '../../helpers/handleGoogleAnalyticsHelper';

const PartialBYOT = ({ customData, ...props }) => {
  const { termsMessage, termsLabel, howItWorksTitle, howItWorksMessage } = customData.pageContent;
  const {
    state: {
      user: { customer },
      session: { isLoggedIn },
      points: { step },
    },
  } = useStateMachine();

  useCards();
  const dateRange = useMemo(
    () => deriveDateRangeList(customData.rawDateRange, customData.rawMonths),
    [customData.rawDateRange, customData.rawMonths]
  );
  usePeriod(dateRange, 'transactions');

  const cardHolder = customer && customer.data && !customer?.data?.isCreditHolder && !customer?.data?.isDebitHolder;

  const tabsList = [
    { tabTitle: howItWorksTitle, tabContent: howItWorksMessage },
    { tabTitle: termsLabel, tabContent: termsMessage },
  ];

  useEffect(() => {
    spendPointsForCreditEcomm(
      'byot_view_item',
      'Points For Travel',
      0,
      '',
      '',
      'points_Travel_detail_view',
      'Points For Travel Detail View'
    );
  }, []);

  if (isLoggedIn) {
    return (
      <>
        {step === 1 && (
          <WrapperLayout isGray mobileIsWhite>
            <div className="global-content-wrapper">{customData.sitemap && <Breadcrumbs customData={customData.sitemap} />}</div>
          </WrapperLayout>
        )}
        <WrapperLayout isGray mobileIsWhite>
          <div>
            {cardHolder ? (
              <CardApply customData={customData.pageContent} />
            ) : (
              <PointsShop page="byot" customData={customData.pageContent} languageCode={props.languageCode} />
            )}
          </div>

          {cardHolder
            ? null
            : step === 1 && (
                <WrapperLayout className="global-content-wrapper pb-8 md:pb-0" isWhite>
                  <TabbedComponent tabs={tabsList} />
                </WrapperLayout>
              )}
        </WrapperLayout>
      </>
    );
  }
  return <div className="flex justify-center">{/* No account for now */}</div>;
};

PartialBYOT.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  // set up api
  const api = agility;
  try {
    // get sitemap...
    const sitemap = await api.getSitemap({
      channelName,
      languageCode,
    });
    const contentItemList = await api.getContentList({
      referenceName: 'partialbyotcontent',
      languageCode,
    });

    // get Months...
    const rawMonths = await api.getContentList({
      referenceName: 'months',
      languageCode,
    });

    // get DateRange...
    const rawDateRange = await api.getContentList({
      referenceName: 'daterange',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];
    const contentItem = contentItemList && contentItemList[0];

    return {
      pageContent: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      rawDateRange,
      rawMonths,
      sitemap,
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default PartialBYOT;
