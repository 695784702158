// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import TwoColumnContent from '../common/Sections/TwoColumnContent';

const SharedTwoColumnContentSection = ({ module, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout
        className="global-content-wrapper"
        isGray={fields.headerBackgroundColor === 'isGray'}
        customStyles="defaultPadding"
      >
        <TwoColumnContent fields={fields} languageCode={languageCode} />
      </WrapperLayout>
    );
  }

  return null;
};

export default SharedTwoColumnContentSection;
