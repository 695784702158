import PropTypes from 'prop-types';
// Global State
import { useStateMachine } from 'little-state-machine';
// Components
import Loader from '../common/Loader/Loader';
import TabbedComponent from '../common/Tabs/TabbedComponent';
import WrapperLayout from '../layouts/WrapperLayout';

function GiftCardTabbedContent({ pageContent }) {
  const {
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  if (!(redeem.selectedFormat && (redeem.giftCardDetails?.digital || redeem.giftCardDetails?.physical))) {
    return (
      <WrapperLayout className="global-content-wrapper pb-8 pt-4" isGray>
        <Loader />
      </WrapperLayout>
    );
  }

  let aboutMessage = '';
  let termsMessage = '';

  if (redeem.selectedFormat === 'E-Card') {
    aboutMessage = redeem.giftCardDetails?.digital[0]?.giftCards[0]?.description;
    termsMessage = redeem.giftCardDetails?.digital[0]?.giftCards[0]?.terms.value;
  } else if (redeem.selectedFormat === 'Physical') {
    aboutMessage = redeem.giftCardDetails?.physical[0]?.giftCards[0]?.description;
    termsMessage = redeem.giftCardDetails?.physical[0]?.giftCards[0]?.terms.value;
  }
  const tabsList = [
    {
      tabTitle: pageContent.howToUseLabel,
      tabContent: pageContent.howToUseMessage,
    },
    {
      tabTitle: pageContent.aboutLabel,
      tabContent: aboutMessage,
    },
    {
      tabTitle: pageContent.termsLabel,
      tabContent: termsMessage,
    },
  ];

  return (
    <WrapperLayout className="global-content-wrapper pb-8" isGray>
      <TabbedComponent tabs={tabsList} />
    </WrapperLayout>
  );
}

GiftCardTabbedContent.propTypes = {
  pageContent: PropTypes.shape({
    howToUseLabel: PropTypes.string.isRequired,
    howToUseMessage: PropTypes.string.isRequired,
    aboutLabel: PropTypes.string.isRequired,
    aboutMessage: PropTypes.string.isRequired,
    termsLabel: PropTypes.string.isRequired,
    termsMessage: PropTypes.string.isRequired,
  }),
};

export default GiftCardTabbedContent;
