/* eslint-disable react/destructuring-assignment */
import { ContentZone } from '@agility/nextjs';
import { getModule } from '../agility-pageModules';
import SiteFooter from '../common/Layouts/Footer/SiteFooter';
import MastHead from '../common/Layouts/Header/MastHead';
import MainContentPadding from '../common/Layouts/MainContent/MainContentPadding';
// Styles
import { SkipToContent } from '../common/Layouts/Header/HeaderStyles';

const MainTemplate = (props) => (
  <>
    <SkipToContent href="#mainContent">
      <span>{props.globalData?.header?.fields?.skipToContentLabel}</span>
    </SkipToContent>

    <MastHead {...props} />
    <MainContentPadding />
    <main id="mainContent" className="relative h-full focus:outline-none">
      <ContentZone name="MainContentZone" {...props} getModule={getModule} />
    </main>
    <div id="portal" />
    <SiteFooter {...props} />
  </>
);
export default MainTemplate;
