import PropTypes from 'prop-types';

import { Divider } from '../HeaderStyles';
import ProvincialSegment from './ProvincialSegment';

export default function HeaderWelcomeMessage({
  data,
  welcomeLabel,
  locationIcon,
  toggleProvincialPopup,
  isShowingProvincialPopup,
}) {
  const disableProvincialSegmentation = process.env.NEXT_PUBLIC_DISABLE_PROVINCIAL_SEGMENTATION === 'false';
  if (data && welcomeLabel) {
    return (
      <>
        <p className="mb-0">
          {welcomeLabel}
          <br />
          <strong>{data.firstName}</strong>
        </p>
        {disableProvincialSegmentation && (
          <ProvincialSegment
            locationIcon={locationIcon}
            tabletHide={false}
            toggleProvincialPopup={toggleProvincialPopup}
            isShowingProvincialPopup={isShowingProvincialPopup}
            id="province-mobile"
          />
        )}
        <Divider role="separator" />
      </>
    );
  }
  return null;
}

HeaderWelcomeMessage.propTypes = {
  data: PropTypes.object,
  welcomeLabel: PropTypes.string,
  locationIcon: PropTypes.object,
  toggleProvincialPopup: PropTypes.func,
  isShowingProvincialPopup: PropTypes.bool,
};
