import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';

// Components
import Loader from '../common/Loader/Loader';
import useGiftCardDetails from '../hooks/giftCards/useGiftCardDetails';
import GiftCardSelection from './GiftCardSelection';
import GiftCardDetailImage from './GiftCardDetailImage';

function GiftCardDetail({ customData, languageCode, brandId, updateGiftCardDetails }) {
  const router = useRouter();
  const { giftCardDetails, isLoading, error } = useGiftCardDetails(brandId, languageCode);
  const {
    actions,
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  useEffect(() => {
    if (giftCardDetails && !isLoading) {
      if (redeem?.giftCardDetails?.brandId !== giftCardDetails.brandId) {
        updateGiftCardDetails(giftCardDetails);
      }
    }

    if (giftCardDetails === null) {
      router.push(`/${languageCode}/rewards/gift-cards`);
    }
  }, [giftCardDetails]);

  return (
    <div className="inner-columns-wrapper md:flex justify-between">
      {isLoading || !giftCardDetails || (router.query && !(router.query.brand === redeem.giftCardDetails.brandId)) ? (
        <Loader />
      ) : (
        <>
          <GiftCardDetailImage />
          <GiftCardSelection customData={customData} />
        </>
      )}
    </div>
  );
}

GiftCardDetail.propTypes = {
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  brandId: PropTypes.string,
  updateGiftCardDetails: PropTypes.func.isRequired,
};

export default GiftCardDetail;
