import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';

import { StyledBreadcrumb } from './BreadcrumbStyles';
// Helpers
import { deriveBreadcrumbs, derivePathFrom } from '../../../helpers/navigationHelpers';

export default function Breadcrumbs({ customData, title = null }) {
  const { asPath } = useRouter();
  const {
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  const url = derivePathFrom(asPath);
  const parts = url.split('/');
  const { breadcrumbs, breadcrumbPage } = useMemo(
    () =>
      deriveBreadcrumbs({
        url,
        title,
        customData,
        brandName: redeem?.giftCardDetails?.brandName,
        parts,
      }),
    [url, title, customData, redeem?.giftCardDetails?.brandName, parts]
  );

  if (breadcrumbs) {
    return (
      <nav aria-label="Breadcrumb" data-testid="breadcrumb-container">
        <ul className="w-full p-0 my-0" data-testid="desktop-crumb-list">
          {breadcrumbs.map((item, index) => (
            <StyledBreadcrumb key={index} data-testid="desktop-crumb">
              <Link href={item.path} className="w-full h-full cursor-pointer">
                {item.menuText}
              </Link>
            </StyledBreadcrumb>
          ))}
          <StyledBreadcrumb aria-current="page" data-testid="current-desktop-crumb">
            {breadcrumbPage}
          </StyledBreadcrumb>
        </ul>

        <ul className="w-full p-0 my-0" data-testid="mobile-crumb-list">
          <StyledBreadcrumb className="mobile" data-testid="mobile-crumb">
            <Link href={breadcrumbs[breadcrumbs.length - 1].path} className="w-full h-full cursor-pointer">
              {breadcrumbs[breadcrumbs.length - 1].menuText}
            </Link>
          </StyledBreadcrumb>
        </ul>
      </nav>
    );
  }
  // }

  return null;
}
