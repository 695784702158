import styled from 'styled-components';

export const PointsHistoryGrid = styled.div`
  display: flex;
`;
export const PointsBalanceInfoStyles = styled.div`
  @media screen and (min-width: 641px) {
    flex: 0 0 60%;
  }
`;

export const PointsHeading = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.lightGrayColor4};
  font-size: ${(props) => props.theme.headingSmall};

  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
  }

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    font-size: ${(props) => props.theme.fontBody1};
    line-height: ${(props) => props.theme.lineHeight3};
  }
`;
export const PointsAmount = styled.h2`
  font-weight: bold;
  font-size: ${(props) => props.theme.headingMedSmall};
  line-height: ${(props) => props.theme.lineHeight2};
  margin-top: 1rem;
  @media screen and (min-width: 1024px) {
    margin-top: 2rem;
  }
  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.headingLarge};
    line-height: ${(props) => props.theme.lineHeight1};
  }
`;
export const MissingPointsStyle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 6.25rem;

  @media screen and (min-width: 641px) {
    flex: 0 0 30%;
    max-width: 100%;
  }

  .missing-points-btn {
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 1.75rem;
    padding: 0;
    border: 0;
    max-width: 3.125rem;
    min-width: auto;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      padding: 0;
      border: 0;
    }

    &:after {
      background-color: transparent;
      padding: 0;
      border: 0;
    }

    @media screen and (min-width: 641px) {
      max-width: 100%;
      margin-bottom: unset;
    }
  }

  .missing-points-inner {
    @media screen and (min-width: 641px) {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      border: 1px solid ${(props) => props.theme.uiGrey02};
      margin-bottom: 0.5rem;
    }
  }

  .missing-points-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      color: ${(props) => props.theme.uiBlack};
      font-weight: normal;
      margin: 0 0.5rem 0 0;
    }
  }

  .note {
    font-size: ${(props) => props.theme.fontLegal};
  }
`;
export const GridTitle = styled.h2`
  font-size: ${(props) => props.theme.fontBody2};
  color: ${(props) => props.theme.lightGrayColor4};
  margin: 0;

  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.fontBody1};
  }

  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.headingExtraSmall};
  }
`;
export const RecentTransactionsStyle = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;
export const PointsHistoryListWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    max-width: 70%;
    flex: 0 0 70%;
    margin-right: 1.5rem;
  }
`;
export const PointHistoryGroup = styled.div`
  width: 100%;
  border-left: 1px solid ${(props) => props.theme.uiGrey02};
  border-right: 1px solid ${(props) => props.theme.uiGrey02};
  border-bottom: 1px solid ${(props) => props.theme.uiGrey02};
`;
export const PointsItemsStyle = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    border: 0;
  }
`;
export const PointsDate = styled.h3`
  background-color: ${(props) => props.theme.uiGrey02};
  color: ${(props) => props.theme.grayColor4};
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.fontLegal};
`;
export const PointsItems = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1rem;
`;
export const PointsItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
  padding: 0 0 1rem;
  border-bottom: 1px solid ${(props) => props.theme.uiGrey02};

  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .item-points {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    min-width: 6.5rem;
    p {
      margin: 0;
    }
  }

  .point-item-name {
    font-size: ${(props) => props.theme.fontBody3};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-right: 1rem;

    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }

  .point-item-type {
    text-transform: inherit;
    font-size: ${(props) => props.theme.fontLegal};

    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;
export const Extra = styled.span`
  background-color: #f2fbf9;
  padding: 0.125rem 0.25rem;
  color: #00693a;
  font-weight: bold;
  white-space: nowrap;
`;
export const EmptyPointsStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${(props) => props.theme.headingExtraSmall};

  @media screen and (min-width: 641px) {
    padding: 2rem;
    border: 1px solid ${(props) => props.theme.uiGrey02};
  }

  @media screen and (min-width: 768px) {
    margin-right: 1.5rem;
  }

  .empty-heading-text {
    font-size: ${(props) => props.theme.fontBody1};
    margin-bottom: 0.625rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }

  .empty-sub-text {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    color: ${(props) => props.theme.lightGrayColor4};
    margin-bottom: 1.5rem;

    @media screen and (min-width: 641px) {
      margin-bottom: 2.5rem;
    }
  }

  .empty-transac-button {
    width: 100%;
    min-width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 641px) {
      max-width: 458px;
      min-width: inherit;
    }

    @media screen and (min-width: 768px) {
      max-width: 485px;
    }
  }
`;
export const EmptyTransacImage = styled.div`
  position: relative;
  max-width: 140px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 641px) {
    max-width: 200px;
  }
`;
export const TransactionFilterType = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.75rem;
  }

  h3 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontBody2};
    margin-bottom: 1rem;
  }
`;
export const TransactionIcon = styled.div`
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
  min-width: 1.125rem;
  min-height: 1.125rem;
  margin-right: 0.5rem;
`;
export const FilteringButton = styled.button`
  display: inline-block;
  border-radius: 0;
  background: inherit;
  width: auto;
  height: 2.313rem;
  font-weight: normal;
  color: ${(props) => props.theme.uiCharcoal};
  padding: 0.35rem 1rem;
  background-color: ${(props) => props.theme.uiGrey02};
  border: 1px solid ${(props) => props.theme.borderColor};
  margin: 0 1rem 1rem 0;
  transition: 0.2s all ease-in;

  &.selected {
    padding-left: 2.5rem;
    background-color: ${(props) => props.theme.uiBlack};
    border: 1px solid ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.whiteColor};
    background-image: url('/assets/white-checkmark.svg');
    background-repeat: no-repeat;
    background-position: 1rem center;
  }
`;
export const RecentTransactionsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .recent-transactions-bar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 1024px) {
      max-width: 70%;
      flex: 0 0 70%;
      margin-right: 1.5rem;
    }
  }
`;

export const PointsHistoryMobileHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
`;
export const PointsHistoryGoBackWrapper = styled.button`
  display: inline-flex;
  background-color: transparent;
  padding: 0;
  flex: 0 0 10%;
  color: ${(props) => props.theme.lightGrayColor4};
  font-weight: normal;
`;
export const ImageWrapper = styled.div`
  width: 21px;
  margin-right: 0.35rem;
`;
