import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../Images/ExternalImage';

import { StyledBanner, StyledBannerContent } from './DownloadAppStyles';

export default function DownloadApp({ fields }) {
  if (fields) {
    const data = [
      {
        storeImage: fields.appStoreImage,
        storeLink: fields.appStoreLink,
        width: 124,
        height: 42,
      },
      {
        storeImage: fields.googlePlayImage,
        storeLink: fields.googlePlayLink,
        width: 142,
        height: 42,
      },
    ];
    return (
      <section className="section-content-wrapper" id="download-app">
        <StyledBanner>
          <div className="w-full global-content-wrapper relative flex items-stretch h-full overflow-hidden">
            <div className="relative w-full h-full">
              <div className="absolute md:-top-20 lg:-top-24 md:-bottom-24 lg:-bottom-32 md:-left-48 lg:-left-44 right-0 hidden md:block">
                <ExternalImage url={fields.image.url} fill className="object-contain object-left-top" />
              </div>
              <div className="w-full h-full flex items-center justify-end">
                <StyledBannerContent>
                  <h2 className="headline-medium">{fields.title}</h2>
                  <p>{fields.message}</p>

                  <div className="flex">
                    {data &&
                      data.map((item, index) => (
                        <div key={index} className="image-wrapper">
                          <Link href={item.storeLink.href} as={item.storeLink.href} target={item.storeLink.target}>
                            {item.storeImage && (
                              <ExternalImage
                                url={item.storeImage.url}
                                title={item.storeLink.text}
                                width={item.width}
                                height={item.height}
                              />
                            )}
                          </Link>
                        </div>
                      ))}
                  </div>
                </StyledBannerContent>
              </div>
            </div>
          </div>
        </StyledBanner>
      </section>
    );
  }
  return null;
}

DownloadApp.propTypes = { fields: PropTypes.object };
