import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Global State
import { useStateMachine } from 'little-state-machine';
import { useWindowWidth } from '@react-hook/window-size';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import BrandTileList from '../common/List/BrandTiles/BrandTileList';

// Helpers
import { deriveListByProvince } from '../../helpers/utils';
import { handleGaEcommShopToRedeemList } from '../../helpers/handleGoogleAnalyticsHelper';

const BrandStores = ({ module, customData, languageCode }) => {
  const { fields } = module;
  const {
    state: {
      location,
      session: { isLoggedIn },
    },
  } = useStateMachine();
  const { asPath } = useRouter();
  const viewPortWidth = useWindowWidth();
  let bList = [];
  useEffect(() => {
    if (bList.length) {
      handleGaEcommShopToRedeemList('shoptoredeem_view_item_list', bList, fields?.title);
    }
  }, [bList]);
  if (isLoggedIn && fields?.isLoggedIn === 'true' && fields?.brandStoreList.length && location?.province) {
    const rawList = fields?.brandStoreList.map((item) => ({
      title: item.fields.title,
      linkText: item.fields.title,
      provinces: item.fields.provinces_TextField || '',
      imageSrc: item.fields.webLogo?.url,
      link: item.fields.link?.href
        ? item.fields.link?.href
        : `${asPath}/${customData?.brands.filter((b) => b.contentID === item.fields.brand?.contentid)[0].fields.l3PageCode}`,
      code: customData?.brands.filter((b) => b.contentID === item.fields.brand?.contentid)[0].fields.code,
    }));
    bList = deriveListByProvince(rawList, location?.province);
    const showSlider = bList.length > 2 ? 'mobileHide' : null;
    if (bList?.length) {
      return (
        <WrapperLayout className="global-content-wrapper" isGray customStyles="brandStores">
          <section aria-labelledby="BrandStoresList">
            <h2 id="BrandStoresList">{fields?.title}</h2>
            <BrandTileList
              data={bList}
              languageCode={languageCode}
              hideList={showSlider}
              sectionTitle={fields?.title}
              brandStores
              isSlider={bList?.length > 2 && viewPortWidth < 641}
            />
          </section>
        </WrapperLayout>
      );
    }
  }

  return null;
};

BrandStores.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const brands = await api.getContentList({
      referenceName: 'brands',
      languageCode,
    });
    return {
      brands,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

BrandStores.propTypes = {
  module: PropTypes.object.isRequired,
  languageCode: PropTypes.string,
};
export default BrandStores;
