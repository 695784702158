import PropTypes from 'prop-types';
import { StyledButtonIcon } from './ButtonStyles';

const ButtonIcon = ({
  type,
  children,
  onClick,
  disabled,
  style,
  className,
  role,
  ariaLabel,
  ariaLabelledBy,
  ariaControls,
  ariaHaspopup,
  id,
  tabIndex,
  ariaExpanded = null,
  onFocus,
  onBlur,
  onKeyDown,
  title,
}) => (
  <StyledButtonIcon
    type={type}
    className={`${className ? `${className} ` : ''}w-full inline-block`}
    onClick={onClick}
    disabled={disabled}
    style={style}
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    aria-controls={ariaControls}
    aria-haspopup={ariaHaspopup}
    role={role}
    id={id}
    tabIndex={tabIndex}
    aria-expanded={ariaExpanded}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    title={title}
  >
    {children}
  </StyledButtonIcon>
);

ButtonIcon.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaHaspopup: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number,
  ariaExpanded: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

ButtonIcon.defaultProps = {
  ariaExpanded: null,
};
export default ButtonIcon;
