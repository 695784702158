import PropTypes from 'prop-types';

import SingleFilterSelectable from './SingleFilterSelectable';
import MultiFilterSelectable from './MultiFilterSelectable';

/**
 * @component
 *
 * @prop {array} filters array of objects that must have the shape {name: <string>, code:<string>}
 * @prop {function} handleFilterClicked callback handler from parent that will control the side effect of using the filter
 * @prop {boolean} multiSelectable flag that will determine the filter behaviour desired
 */

export default function FilterList({ filters, handleFilterClicked, multiSelectable }) {
  if (multiSelectable) {
    return <MultiFilterSelectable filters={filters} handleFilterClicked={handleFilterClicked} />;
  }

  return <SingleFilterSelectable filters={filters} handleFilterClicked={handleFilterClicked} />;
}

FilterList.propTypes = {
  filters: PropTypes.array.isRequired,
  handleFilterClicked: PropTypes.func.isRequired,
  multiSelectable: PropTypes.bool,
};
