import styled from 'styled-components';

export const StyledList = styled.ul`
  padding: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 0;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }

  @media screen and (min-width: 991px) {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  li {
    color: ${(props) => props.theme.uiWhite};
    background-color: ${(props) => props.theme.uiBlack};
    cursor: pointer;
    &:focus,
    &:hover {
      outline: 0.25rem solid ${(props) => props.theme.uiPurple};
      outline-offset: 0.25rem;
    }
    h3 {
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
      margin-bottom: 0.75rem;

      @media screen and (min-width: 641px) {
        font-size: ${(props) => props.theme.fontBody1};
        margin-bottom: 1rem;
      }
    }

    p {
      color: ${(props) => props.theme.uiWhite};
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
      display: inline;
      @media screen and (max-width: 640px) {
        font-size: ${(props) => props.theme.fontBody3};
      }
    }
    div {
      padding: 1.5rem 1.125rem;
      @media screen and (min-width: 641px) {
        padding: 2.5rem 2rem;
      }
    }
  }
`;

export const ImageContainer = styled.figure`
  position: relative;
  overflow: hidden;
  display: block;
  flex: none;
  width: 13.625rem;
  img {
    position: absolute;
    bottom: 0;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const ImageAppContainer = styled.figure`
  display: none;

  @media screen and (max-width: 640px) {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: block;
    flex: none;
    width: 8rem;
  }
`;
