import PropTypes from 'prop-types';

// Components
import CardFilter from '../../../Filter/CardFilter';
import CardDropdown from '../../../Filter/CardDropdown';
import PeriodFilter from './PeriodFilter';

// V2
import Summary from '../Summary';
import TransactionList from '../TransactionList';

// Hooks
import useModal from '../../../../hooks/utils/useModal';

// Styles
import { StyledTransactions, StyledAwardsList } from '../../PointsShopStyles';

function ShopBYOT({ page, onSubmit, onErrors, formMethods, customData, languageCode, showFilter }) {
  const { cardLabel, selectCardLabel, periodLabel } = customData;
  const { isShowing, toggle, currentItemId } = useModal();

  return (
    <StyledAwardsList className="byot-transactions">
      <form className="relative p-0 lg:w-full" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="lg:flex">
          <StyledTransactions className="flex-1 mb-4 md:mb-0" page={page}>
            <CardFilter page={page}>
              <div className="flex-1 mb-4 md:mb-0 card-column">
                <CardDropdown toggle={toggle} name="card-filter" label={selectCardLabel || cardLabel} />
              </div>

              <PeriodFilter showFilter={showFilter} periodLabel={periodLabel} />
            </CardFilter>

            <TransactionList
              page={page}
              customData={customData}
              isShowing={isShowing}
              toggle={toggle}
              currentItemId={currentItemId}
              languageCode={languageCode}
            />
          </StyledTransactions>

          <Summary page={page} customData={customData} />
        </div>
      </form>
    </StyledAwardsList>
  );
}

ShopBYOT.propTypes = {
  page: PropTypes.string,
  onSubmit: PropTypes.func,
  onErrors: PropTypes.func,
  formMethods: PropTypes.object,
  customData: PropTypes.object,
  showFilter: PropTypes.bool,
  languageCode: PropTypes.string,
};

export default ShopBYOT;
