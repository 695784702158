/* eslint-disable no-shadow */
import { useState } from 'react';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import clearAction from '../../../../store/actions/clearAction';
import { updateNotification, updateUser } from '../../../../store/actions';

import { createAddress, getCustomerAddresses } from '../../../../pages/api/services';

// Components
import CloseIcon from '../../../common/Icons/CloseIcon';
import FormInput from '../../fields/FormInput';
import FormPhoneInput from '../../fields/FormPhoneInput';
import FormCheckbox from '../../fields/FormCheckbox';
import FormSave from '../../FormSave';
import CanadaPostSearch from '../../fields/CanadaPostSearch';

import { enableScrolling } from '../../../../helpers/behavourHelper';
import { displayFieldErrorMessage, regexEnFrChars } from '../../../../helpers/formHelpers';
import { generateErrorMessage } from '../../../../helpers/errorsHelper';

import { AddressModalHeader } from '../EditAddress/EditAddressStyles';

export default function AddAddressModal({ formMethods, setShowModal, showModal, ...props }) {
  const {
    addLabel,
    firstNameLabel,
    lastNameLabel,
    cancelLabel,
    addAddressHeader,
    setPrimaryShippingAddressLabel,
    provinces,
    languageCode,
    phoneLabel,
    errorsList,
    setAddressList,
    addLabelLoading,
    addressModal = true,
    page,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [enterAddressManually, setEnterAddressManually] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    actions,
    state: {
      session: { accessToken },
    },
  } = useStateMachine({ clearAction, updateNotification, updateUser });

  const onSubmit = async (data) => {
    setSubmitting(true);
    const reqBody = {
      countryCode: 'CA',
      provinceCode: data.provinceCode,
      city: data.city,
      postalCode: data.postalCode.split(/\s/).join(''),
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      firstName: data.firstName,
      lastName: data.lastName,
      primaryPhoneNumber: data.phone || data.primaryPhoneNumber.replace(/-/g, ''),
      primary: data.primary,
    };
    actions.updateUser({ data: '', step: 1 });

    createAddress(reqBody, accessToken)
      .then(async (res) => {
        if (res.data) {
          await getCustomerAddresses(accessToken)
            .then((res) => {
              enableScrolling();
              const addressData = res.data.data;

              setAddressList([...addressData]);
              if (page === 'giftcard') {
                // update selected address for address select

                const primary = addressData.filter((option) => option.primary === true)[0];

                actions.updateUser({
                  shippingAddresses: addressData,
                  selectedShippingAddress: {
                    addressData: primary,
                  },
                });
              }

              // reset popup
              setEnterAddressManually(false);
              setShowModal(!showModal);
              formMethods.reset();
            })
            .catch((error) => {
              if (error.response !== null && error.response.data !== null) {
                actions.updateNotification({
                  message: generateErrorMessage(
                    error?.response?.data?.validationErrors
                      ? Object.keys(error.response.data.validationErrors)
                      : ['General.BadRequest'],
                    errorsList
                  ),
                  status: 'error',
                });
              }
              console.error(error);
            });
        }

        if (res?.response?.data?.validationErrors) {
          actions.updateNotification({
            message: generateErrorMessage(
              res.response.data.validationErrors !== null
                ? Object.keys(res.response.data.validationErrors)
                : ['General.BadRequest'],
              errorsList
            ),
            status: 'error',
          });
        }
      })
      .catch((error) => {
        if (error.response !== null && error.response.data !== null) {
          actions.updateNotification({
            message: generateErrorMessage(
              error?.response?.data?.validationErrors
                ? Object.keys(error.response.data.validationErrors)
                : ['General.BadRequest'],
              errorsList
            ),
            status: 'error',
          });
        }
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  const updatePopupClose = () => {
    setShowModal(!showModal);
    setEnterAddressManually(!showModal);
    formMethods.reset();
    actions.updateUser({ data: '', step: 1 });
    enableScrolling();
  };

  return (
    <>
      <span className="overlay" />
      <div className="form-container p-0 my-24 mt-0 bg-white text-base shadow-xl">
        <form className="p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
          <div className="modal-popup w-full max-w-xl mx-auto">
            <AddressModalHeader className="relative modal-header flex justify-center">
              {addAddressHeader && <h4 className="text-lg font-bold">{addAddressHeader}</h4>}
              <CloseIcon toggle={updatePopupClose} type="pos-right" />
            </AddressModalHeader>
            <div className="p-8 pt-0">
              <FormInput
                id="firstName"
                fieldType="text"
                name="firstName"
                label={firstNameLabel}
                addressModal={addressModal}
                defaultVal=""
                maxLength={64}
                rules={{
                  pattern: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Invalid'),
                    value: regexEnFrChars,
                  },
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Required'),
                    value: true,
                  },
                  minLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
                    value: 2,
                  },
                  maxLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.FirstName.Length'),
                    value: 50,
                  },
                }}
              />

              <FormInput
                id="lastName"
                fieldType="text"
                name="lastName"
                label={lastNameLabel}
                defaultVal=""
                aaddressModal={addressModal}
                maxLength={64}
                rules={{
                  pattern: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Invalid'),
                    value: regexEnFrChars,
                  },
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Required'),
                    value: true,
                  },
                  minLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
                    value: 2,
                  },
                  maxLength: {
                    message: displayFieldErrorMessage(errorsList, 'Customer.LastName.Length'),
                    value: 50,
                  },
                }}
              />

              <CanadaPostSearch
                {...props}
                provinces={provinces}
                culture={languageCode}
                isAddAddress
                addressModal={addressModal}
                setEnterAddressManually={setEnterAddressManually}
              />

              <FormPhoneInput
                id="primaryPhoneNumber"
                name="primaryPhoneNumber"
                label={phoneLabel}
                addressModal={addressModal}
                errorMessage={displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Invalid')}
                rules={{
                  required: {
                    message: displayFieldErrorMessage(errorsList, 'Contact.PrimaryPhoneNumber.Required'),
                    value: true,
                  },
                }}
              />

              <div className="block my-6">
                <FormCheckbox
                  fieldType="checkbox"
                  label={setPrimaryShippingAddressLabel}
                  name="primary"
                  rules={{
                    required: {
                      message: '',
                      value: false,
                    },
                  }}
                />
              </div>
              <FormSave
                layout="fullwidth"
                buttonText={addLabel}
                buttonLoadingText={addLabelLoading}
                cancelLabel={cancelLabel}
                isEditing
                submitting={submitting}
                updatePopupClose={updatePopupClose}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

AddAddressModal.propTypes = {
  formMethods: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  addLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  addAddressHeader: PropTypes.string,
  setPrimaryShippingAddressLabel: PropTypes.string,
  provinces: PropTypes.array,
  languageCode: PropTypes.string,
  phoneLabel: PropTypes.string,
  errorsList: PropTypes.array,
  setAddressList: PropTypes.func,
  addLabelLoading: PropTypes.string,
  addressModal: PropTypes.bool,
};
