import PropTypes from 'prop-types';

import { Filter } from './FilterButtonStyles.js';

function FilterButton({ handleClick, filterLabel, isShowing, active, disabled, page }) {
  return (
    <Filter
      type="button"
      onClick={handleClick}
      aria-label={filterLabel}
      aria-expanded={isShowing}
      active={active}
      disabled={!active && disabled}
      className={!active && disabled ? `disabled` : ''}
      page={page}
    >
      {filterLabel && <span>{filterLabel}</span>}
    </Filter>
  );
}

FilterButton.propTypes = {
  handleClick: PropTypes.func,
  filterLabel: PropTypes.string,
  isShowing: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  page: PropTypes.string,
};

export default FilterButton;
