/**
 * FORM HELPERS
 */

export const regexEnFrChars = /^[a-zàâçéèêëîïôûùüÿñæœ '_.-]*$/i;
export const regexEnChars = /^[a-z '_.-]*$/i;

export function validateFieldError(formState, name) {
  const isError =
    (formState?.errors?.[name] && formState.touchedFields?.[name]) ||
    (formState?.errors?.[name] && formState.isSubmitted) ||
    formState.touchedFields?.[name];

  return isError;
}

export function validateValueWithRegex(regex, value) {
  const regexMinChars = regex === 'IncludeChars' ? /^.{8,}$/.test(value) : null;
  const regexNums = regex === 'IncludeNumbers' ? /\d/.test(value) : null;
  const regexUpper = regex === 'IncludeUppercase' ? /[A-Z]/.test(value) : null;
  const regexLower = regex === 'IncludeLowercase' ? /[a-z]/.test(value) : null;
  const regexSpecialChars = regex === 'IncludeSpecialChar' ? /[\W_]/.test(value) : null;

  return regexMinChars || regexNums || regexUpper || regexLower || regexSpecialChars ? 'valid' : 'invalid';
}

export function validateFieldWithRegex(value) {
  const regexNums = /\d/;
  const regexUpper = /[A-Z]/;
  const regexLower = /[a-z]/;
  const regexSpecialChars = /[\W_]/;

  if (!regexNums.test(value) || !regexUpper.test(value) || !regexLower.test(value) || !regexSpecialChars.test(value)) {
    return 'Invalid password';
  }
  return null;
}

export function displayFieldErrorMessage(errorsList, type) {
  const message = errorsList.filter((item) => item.code == type).map((item) => item.message)[0];

  return message;
}

export function validateMaxMonthDays(month, year) {
  const maxDays = new Date(year, month, 0).getDate();

  return maxDays;
}

export function mapSelectValuesToObj(list) {
  if (!list) return {};

  return list.reduce((obj, option) => {
    obj[option.value] = option.value;

    return obj;
  }, {});
}

export function populateAddressFromCanadaPost(address) {
  const { Line1, SubBuilding, BuildingNumber, Street, City, ProvinceCode, ProvinceName, PostalCode, Label } = address;

  const newAddress = {
    addressLine1: SubBuilding === '' || Street === '' ? Line1 : `${BuildingNumber} ${Street}`,
    addressLine2: SubBuilding,
    city: City,
    provinceCode: ProvinceCode,
    postalCode: PostalCode,
  };

  // verify addressLine1 & addressLine2 for address with apt#
  if (SubBuilding) {
    // compare canadapost Line1 is equal to populated addressLine2-addressLine1
    if (Line1.localeCompare(`${newAddress.addressLine2}-${newAddress.addressLine1}`) !== 0) {
      // compare if addressLine2 equal to substring representing apt# in Line1
      if (SubBuilding.localeCompare(Line1.substring(0, Line1.indexOf('-'))) !== 0) {
        newAddress.addressLine1 = Line1;
        newAddress.addressLine2 = '';
      } else {
        newAddress.addressLine1 = Line1.substring(SubBuilding.length + 1);
      }
    }
  }

  // If street is empty and line 1 is also empty then we will parse the street from the label address using the string manipulation based on the city
  if (Line1 === '' && Street === '' && Label.includes(City.toUpperCase())) {
    const line1FromLabel = Label.substring(0, Label.indexOf(City.toUpperCase())).replace('\n', ' ').trimEnd();
    newAddress.addressLine1 = line1FromLabel || '';
    newAddress.addressLine2 = '';
  }

  newAddress.initialAddress = `${newAddress.addressLine1}, ${City}, ${ProvinceName}, ${PostalCode}`;
  return newAddress;
}
