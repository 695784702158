import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification, updateStep } from '../../store/actions';

import { validateEmail } from '../../pages/api/services';
import { generateErrorMessage } from '../../helpers/errorsHelper';

// Components
import FormFooter from '../forms/registration/FormFooter';
import FormHeader from '../forms/registration/FormHeader';

// Hooks
import useReCaptcha from '../hooks/services/useReCaptcha';
import useRegisterGAFormEvents from '../hooks/services/useRegisterGAFormEvents';

// Helpers
import { handleGAEvents, handleGaSignupPageError } from '../../helpers/handleGoogleAnalyticsHelper';

export default function RegisterStep1FormLayout({ children, ...props }) {
  const { title, step1Label, nextLabel, nextLabelLoading, signInLabel, errorsList, languageCode } = props;
  const [submitting, setSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const { actions } = useStateMachine({
    updateUser,
    updateStep,
    updateNotification,
  });

  useEffect(() => {
    actions.updateStep({ step: 1 });
  }, []);

  useRegisterGAFormEvents(formMethods.formState?.touchedFields);

  const { getCaptchaToken } = useReCaptcha('register_user_step_1');

  const handleValidEmailResponse = async (res, formData) => {
    if (res.data) {
      actions.updateUser(formData);
      actions.updateStep({ step: 2 });
      actions.updateNotification({
        message: '',
        status: '',
      });
      window.scrollTo(0, 0);
      handleGAEvents('Next', 'Registration Events', 'Step 2', 'sign_up_button_next');
    }
  };

  const handleErrorResponse = (error) => {
    const duration = 2 * 60 * 1000; // 2 mins in milliseconds

    if (error.response !== null && error.response.data !== null) {
      let statusType = 'error';
      const validationError =
        error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();

      if (validationError.includes('Contact.Email.Unique')) {
        statusType = 'info';
        actions.updateNotification({
          message: generateErrorMessage(['Contact.Email.Unique'], errorsList),
          status: statusType,
          duration,
        });
      } else {
        actions.updateNotification({
          message: generateErrorMessage(
            error?.response?.data?.validationErrors ? Object.keys(error.response.data.validationErrors) : ['General.BadRequest'],
            errorsList
          ),
          status: statusType,
          duration,
        });
      }
    }
  };

  const finishSubmitting = () => {
    setSubmitting(false);
  };

  const validateForm = (reqBody, formData, token, ip, session) => {
    // eslint-disable-next-line prettier/prettier
    validateEmail(reqBody, { token, ip, session})
      .then((res) => handleValidEmailResponse(res, formData))
      .catch(handleErrorResponse)
      .finally(finishSubmitting);
  };

  const onSubmit = async (data) => {
    let result;

    setSubmitting(true);

    if (process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
      result = await getCaptchaToken();
    }

    // only run request if the captcha passes
    if (result?.token || process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'true') {
      const reqBody = {
        email: data.email,
      };

      validateForm(reqBody, data, result?.token, result?.ip, result?.session);
    } else {
      // TODO: right now do nothing if captcha fails
      finishSubmitting();
      console.error('ReCaptcha failed');
    }
  };

  const onErrors = (errors) => {
    console.error(errors);
    handleGaSignupPageError(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="px-4 py-6 sm:py-12 sm:px-14">
          <FormHeader step={step1Label} title={title} />
          {children}
        </div>

        <FormFooter
          buttonText={nextLabel}
          buttonLoadingText={nextLabelLoading}
          linkText={signInLabel}
          submitting={submitting}
          languageCode={languageCode}
        />
      </form>
    </FormProvider>
  );
}

RegisterStep1FormLayout.propTypes = {
  children: PropTypes.array,
  props: PropTypes.object,
  title: PropTypes.string,
  step1Label: PropTypes.string,
  nextLabel: PropTypes.string,
  nextLabelLoading: PropTypes.string,
  signInLabel: PropTypes.string,
  errorsList: PropTypes.array,
  languageCode: PropTypes.string,
};
