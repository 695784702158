import { useEffect } from 'react';
import PropTypes from 'prop-types';

// State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import { OffersForYou, MoreOffers, LimitedTimeOffers, FeaturedOffersPartners } from '../Sections';
import OffersSpotlight from '../Sections/OffersSpotlight';
import BankCardsSliderWrapper from '../../common/Banners/BankCardsSliderWrapper';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';
import { sortedOffersList } from '../../../helpers/offersHelpers';
import { filterFeaturedSpotlight, sortSpotlightsByPriority } from '../../../helpers/spotlightHelper';

function FeaturedOffersTab({ customData, languageCode, partners, bankSlider }) {
  const {
    moreWaystoEarnLabel,
    moreOffersLabel,
    limitedTimeOffersLabel,
    offersForYouLabel,
    moreOffersMessage,
    loadOfferLabel,
    offerLoadedLabel,
    expireLabel,
  } = customData.pageContent;

  const {
    actions,
    state: {
      offers: { moreOffersList, limitedTimeOffersList, offersForYouList, spotlights },
    },
  } = useStateMachine({ updateOffers });

  useEffect(
    () => () => {
      actions.updateOffers({
        offersList: [],
        limitedTimeOffersList: [],
        offersForYouList: [],
        moreOffersList: [],
        spotlights: [],
        isLoading: true,
      });
    },
    []
  );

  const isFeaturedSpotlight = filterFeaturedSpotlight(spotlights && spotlights);
  const sortedSpotlight = sortSpotlightsByPriority(spotlights && spotlights);
  const notFeaturedSpotlight = sortedSpotlight.filter(
    (spotlight) => spotlight.cmsContent.featured === false && spotlight.cmsContent.page === 'Offer'
  );

  return (
    <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby="featured">
      {checkArrayForItems(isFeaturedSpotlight) && (
        <OffersSpotlight
          languageCode={languageCode}
          customData={customData.pageContent}
          expireLabel={expireLabel}
          banners={isFeaturedSpotlight.slice(0, 1)}
          bannerType="Spotlights"
        />
      )}

      {checkArrayForItems(limitedTimeOffersList) && (
        <LimitedTimeOffers
          customData={customData.pageContent}
          languageCode={languageCode}
          limitedTimeOffersList={sortedOffersList(limitedTimeOffersList, 'limited-featured').slice(0, 8)}
          limitedTimeOffersLabel={limitedTimeOffersLabel}
          loadOfferLabel={loadOfferLabel}
          offerLoadedLabel={offerLoadedLabel}
          isSlider
          sectionTitle="Featured"
        />
      )}

      {checkArrayForItems(offersForYouList) && (
        <OffersForYou
          customData={customData.pageContent}
          languageCode={languageCode}
          offersForYouList={offersForYouList}
          offersForYouLabel={offersForYouLabel}
          loadOfferLabel={loadOfferLabel}
          offerLoadedLabel={offerLoadedLabel}
          customStyles="offersPage"
          isSlider
          listName="Offers for you"
          sectionTitle="Featured"
        />
      )}

      {checkArrayForItems(notFeaturedSpotlight) && (
        <OffersSpotlight
          languageCode={languageCode}
          customData={customData.pageContent}
          expireLabel={expireLabel}
          title={moreWaystoEarnLabel}
          isLightBlack
          banners={notFeaturedSpotlight.slice(0, 2)}
          bannerType="Spotlights"
        />
      )}

      {checkArrayForItems(moreOffersList) && (
        <MoreOffers
          title={moreOffersLabel}
          filteredList={moreOffersList}
          message={moreOffersMessage}
          customData={customData.pageContent}
          languageCode={languageCode}
          customStyles="offersPage"
          isGray
        />
      )}

      <FeaturedOffersPartners languageCode={languageCode} partners={partners} />
      {!bankSlider?.disableBNSSLider && (
        <section id="ScotiabankCards">
          <BankCardsSliderWrapper bankSlider={bankSlider} languageCode={languageCode} />
        </section>
      )}
    </div>
  );
}

FeaturedOffersTab.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  partners: PropTypes.object,
};

export default FeaturedOffersTab;
