import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import Loader from '../../common/Loader/Loader';

// Helpers
import { totalPointsToLocalString } from '../../../helpers/pointsHelpers';
import { formatDynamicLabel } from '../../../helpers/stringHelpers';

// Styles
import { PointsBalanceInfoStyles, PointsHeading, PointsAmount } from './PointsHistoryStyles';

export default function PointsBalanceInfo({ customData, languageCode }) {
  const {
    state: {
      transactions: { balance },
      user: {
        customer: { data },
      },
    },
  } = useStateMachine();

  return (
    <PointsBalanceInfoStyles>
      {data ? (
        <>
          <PointsHeading style={{ fontWeight: 'bold', marginBottom: '0' }}>
            {formatDynamicLabel(customData.title, '##FIRSTNAME##', data?.firstName)}
          </PointsHeading>
          <PointsAmount>
            {totalPointsToLocalString(balance, languageCode)}
            <span aria-hidden="true"> {customData.titlePointsLabel} </span>
            <span className="sr-only"> {customData.titlePointsLabel} </span>{' '}
          </PointsAmount>
        </>
      ) : (
        <Loader />
      )}
    </PointsBalanceInfoStyles>
  );
}

PointsBalanceInfo.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
};
