export function savingEmailSettingsValues(data) {
  const keys = data && Object.keys(data);
  const preferences = [];

  keys?.forEach((key) => {
    if (data[key] !== undefined && key !== 'EmailOptin' && key !== 'SMS') {
      preferences.push({
        code: key.toUpperCase(),
        optIn: data[key],
      });
    }
  });

  const apiData = {
    preferences,
  };

  return apiData;
}

export function savingSMSSettingsValues(data) {
  const keys = data && Object.keys(data);
  const preferences = [];

  keys?.forEach((key) => {
    if (data[key] !== undefined && key === 'SMS') {
      preferences.push({
        code: key.toUpperCase(),
        optIn: data[key],
      });
    }
  });

  const apiData = {
    preferences,
  };

  return apiData;
}

export function setDefaultCheckboxSettings(topic = [], savedPreferences = []) {
  const savedPref =
    savedPreferences &&
    savedPreferences.find(
      (preference) =>
        preference.code === topic.fields.code.toUpperCase() && (preference.optIn === true || preference.status === 'OPTED-IN')
    );

  if (savedPref) {
    return true;
  }
  return false;
}

export function disableFieldPending(savedPreferences = []) {
  const fieldNames = savedPreferences
    .filter((pref) => pref.code !== 'SMS' && pref.code !== 'EmailOptin')
    .map((preference) => preference.code);

  const savedPref = savedPreferences.find(
    (preference) => preference.code === fieldNames && preference.status === 'OPT-IN-PENDING'
  );

  if (savedPref) {
    return true;
  }
  return false;
}

export function setDisableSMSField(savedPreferences = []) {
  const savedPref = savedPreferences && savedPreferences.find((preference) => preference.code === 'SMS');

  if (savedPref?.status === 'OPT-IN-PENDING') {
    return true;
  }
  if (savedPref?.status === 'OPT-OUT-PENDING') {
    return true;
  }
  return false;
}

export function setSMSDefaultChecked(savedPreferences = []) {
  const savedPref = savedPreferences && savedPreferences.find((preference) => preference.code === 'SMS');

  if (savedPref?.status === 'OPTED-IN') {
    return true;
  }
  if (savedPref?.status === 'OPT-IN-PENDING') {
    return true;
  }
  return false;
}

export function setSMSValues(savedPreferences = []) {
  const savedPref = savedPreferences && savedPreferences.find((preference) => preference.code === 'SMS');

  return savedPref?.status;
}

export function enableEmailField(savedPreferences = []) {
  const savedPref = savedPreferences.find((preference) => preference.status === 'OPTED-IN');

  if (savedPref) {
    return true;
  }
  return false;
}

export function savePreferencesOptOut(topics = [], optInStatus) {
  const topicsList = topics && topics.map((topic) => topic.fields.code);
  const preferences = [];

  topicsList.forEach((topicItem) => {
    if (topicItem !== 'SMS') {
      preferences.push({
        code: topicItem.toUpperCase(),
        optIn: !!optInStatus,
      });
    }
  });

  const apiData = {
    preferences,
  };

  return apiData;
}

export function getAllKeysFromList(topics = []) {
  const topicsList = topics && topics.filter((topicItem) => topicItem.code !== 'SMS').map((topic) => topic.code);

  return topicsList;
}
