/**
 *
 * @param {*} state existing state
 * @param {*object} payload nested redeem object inside gift cards
 * @returns
 */
export default function updateGiftCardsRedeemAction(state, payload) {
  return {
    ...state,
    giftCards: {
      ...state.giftCards,
      redeem: { ...state.giftCards.redeem, ...payload },
    },
  };
}
