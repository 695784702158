import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';

import Loader from '../../common/Loader/Loader';

function ConfirmationEmail({ emailAddressLabel }) {
  const {
    state: {
      user: { customer },
    },
  } = useStateMachine();

  const isLoading = !customer.data;

  return (
    <SectionDetails email>
      <SectionTitle>{emailAddressLabel}</SectionTitle>
      <DetailsInfo>
        {isLoading && <Loader width="auto" />}
        {!isLoading && <p className="mb-0">{customer?.data?.contacts[0]?.email}</p>}
      </DetailsInfo>
    </SectionDetails>
  );
}

ConfirmationEmail.propTypes = { emailAddressLabel: PropTypes.string.isRequired };

export default ConfirmationEmail;
