/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions } from '../../../store/actions';

import { generateTransactionCards, totalPoints } from '../../../helpers/pointsHelpers';

export default function useCards() {
  // Global State
  const {
    state: { transactions },
    actions,
    state: {
      user: {
        customer: { accounts },
      },
    },
  } = useStateMachine({ updateTransactions });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      actions.updateTransactions({ isLoading });
    }
  }, [isLoading]);

  // TODO:: make new hook? useTransactionsCards or useCards
  useEffect(() => {
    // Setup the selected card based on the customer data
    if (accounts && !transactions.cards.length) {
      const cards = generateTransactionCards(accounts);

      actions.updateTransactions({
        cards,
        selectedCard: cards.length ? cards[0] : {},
        balance: totalPoints(accounts),
      });

      setIsLoading(false);
    } else {
      // we already preloaded the
      setIsLoading(false);
    }
  }, [accounts, isLoading]);

  return { isLoading };
}
