import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import CreateAccountFormGK from '../forms/registration-gk/CreateAccountFormGK';
import CreateAccountForm from '../forms/registration/CreateAccountForm';

const CreateAccount = ({ module, customData, ...props }) => {
  const { enableGhostKit } = module.fields;
  const {
    state: {
      user: { isLoggedIn },
    },
  } = useStateMachine();
  if (!isLoggedIn) {
    if (enableGhostKit === 'true') {
      return <CreateAccountFormGK {...customData} {...props} />;
    }
    return <CreateAccountForm {...customData} {...props} />;
  }

  return null;
};

CreateAccount.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;

  try {
    // get the CreateAccountForm
    const contentItemList = await api.getContentList({
      referenceName: 'createaccountformgk',
      languageCode,
    });

    let contentItem = contentItemList && contentItemList[0];

    contentItem = await expandLinkedList({
      agility,
      contentItem,
      languageCode,
      fieldName: 'rules',
      sortIDField: 'rules_SortIdField',
    });
    const passwordValidationRules =
      contentItem.fields.rules &&
      contentItem.fields.rules.map((validation) => ({
        validationCode: validation.fields.validationCode,
        validationLabel: validation.fields.validationLabel,
      }));

    const rawMonthList = await api.getContentList({ referenceName: 'months', languageCode });
    const mon = rawMonthList && rawMonthList?.filter((mnth) => mnth.properties.definitionName === 'ListItem');
    const mList =
      mon &&
      mon.map((m) => ({
        code: m.fields.code,
        name: m.fields.name,
      }));
    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['Contact', 'Customer', 'Address', 'General', 'message', 'GhostKit'];

    return {
      createAccountForm: contentItem.fields,
      passwordRules: passwordValidationRules,
      monthList: mList,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

CreateAccount.propTypes = {
  customData: PropTypes.object,
  module: PropTypes.object,
};
export default CreateAccount;
