import ModalLayout from '../../layouts/ModalLayout';
import Portal from '../Portal/Portal';
import SpotlightCardModal from './SpotlightCardModal';

export default function SpotlightModal({ isShowing, currentItemId, list, toggle, languageCode, customData, sectionTitle }) {
  if (isShowing && list) {
    return (
      <Portal>
        <span className="overlay" />
        {list.map(
          (item, index) =>
            currentItemId === item.spotlightId && (
              <ModalLayout key={index} toggle={toggle} type="modal-info">
                <SpotlightCardModal
                  item={item}
                  languageCode={languageCode}
                  customData={customData}
                  sectionTitle={sectionTitle}
                  index={`spotlight-${index}`}
                />
              </ModalLayout>
            )
        )}
      </Portal>
    );
  }
  return null;
}
