import PropTypes from 'prop-types';

// Components
import Loader from '../../../../common/Loader/Loader';
import ShopBYOT from '../byot/ShopBYOT';
import ShopPFC from '../pfc/ShopPFC';

function PointsTransactionsForm({ isLoading, selectedCard, page, onSubmit, onErrors, formMethods, customData, languageCode }) {
  if (isLoading && !selectedCard.id) return <Loader />;

  if (page === 'pfc') {
    return (
      <ShopPFC
        page={page}
        onSubmit={onSubmit}
        onErrors={onErrors}
        formMethods={formMethods}
        customData={customData}
        languageCode={languageCode}
      />
    );
  }

  if (page === 'byot') {
    return (
      <ShopBYOT
        page={page}
        onSubmit={onSubmit}
        onErrors={onErrors}
        formMethods={formMethods}
        customData={customData}
        languageCode={languageCode}
        showFilter={!!selectedCard?.id}
      />
    );
  }

  return null;
}

PointsTransactionsForm.propTypes = {
  isLoading: PropTypes.bool,
  selectedCard: PropTypes.object,
  page: PropTypes.string,
  onSubmit: PropTypes.func,
  onErrors: PropTypes.func,
  formMethods: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};

export default PointsTransactionsForm;
