import PropTypes from 'prop-types';

import { TooltipIconContainer } from './ToolTipStyles';

export default function TooltipIcon({ dataForId, iconSmall }) {
  return (
    <TooltipIconContainer
      type="button"
      data-for={dataForId}
      data-tip="React-tooltip"
      data-event="click"
      aria-describedby={dataForId}
      aria-label="click"
      iconSmall={iconSmall}
    />
  );
}

TooltipIcon.propTypes = { dataForId: PropTypes.string, iconSmall: PropTypes.bool };
