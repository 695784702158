import PropTypes from 'prop-types';
import { postalSpace, normalizeInput } from '../../../../helpers/utils';
import { ShippingName, AddressDetailContent } from './AddressStyles';

export default function AddressDetails({ address, switcher }) {
  if (address) {
    return (
      <div className="profile-address-content flex-grow">
        {address?.firstName && address?.lastName && (
          <ShippingName switcher={switcher}>
            {`${address?.firstName} `}
            {address?.lastName}
          </ShippingName>
        )}
        <AddressDetailContent switcher={switcher}>
          {address?.addressLine1 && <p>{address?.addressLine1}</p>}
          {address?.addressLine2 && <p>{address?.addressLine2}</p>}
          <p>
            {`${address?.city} `}
            {`${address?.provinceCode} `}
            {postalSpace(address?.postalCode)}
          </p>
          {(address?.primaryPhoneNumber || address?.phoneLocalNumber) && (
            <p>{normalizeInput(address?.primaryPhoneNumber) || normalizeInput(address?.phoneLocalNumber)}</p>
          )}
        </AddressDetailContent>
      </div>
    );
  }
  return null;
}

AddressDetails.propTypes = {
  address: PropTypes.object.isRequired,
  switcher: PropTypes.bool,
};
