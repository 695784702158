import PropTypes from 'prop-types';

// Styles
import { OnboardingFooterStyles } from './OnboardingStyles';

export default function OnboardingFooter({ gotItLabel }) {
  return (
    <div>
      {gotItLabel && (
        <OnboardingFooterStyles>
          <span id="onboarding-button" title={gotItLabel} tabIndex={0} aria-label={gotItLabel} role="button">
            {gotItLabel}
          </span>
        </OnboardingFooterStyles>
      )}
    </div>
  );
}

OnboardingFooter.propTypes = {
  gotItLabel: PropTypes.string,
};
