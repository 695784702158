import { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';

import OutsideClickHandler from 'react-outside-click-handler';
import CloseIcon from '../common/Icons/CloseIcon';
import { ModalWrapper } from './ModalLayoutStyles';

export default function ModalLayout({ children, toggle, hideCloseIcon, style, className, type }) {
  useEffect(() => {
    document.getElementById('modal-window').focus();
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={toggle}>
      <ModalWrapper
        className={`max-w-6xl mx-auto text-base relative shadow-xl ${className || 'bg-white'}`}
        ariaExpanded="true"
        style={style}
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-label"
        id="modal-window"
        aria-modal="true"
        type={type}
      >
        <FocusTrap
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: 'none',
            },
            initialFocus: false,
          }}
        >
          <div role="document">
            {!hideCloseIcon && <CloseIcon toggle={toggle} className={className} />}

            {children}
          </div>
        </FocusTrap>
      </ModalWrapper>
    </OutsideClickHandler>
  );
}
