import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../store/actions';

// Services
import { getSavedPreferencesPublic, savePreferences, savePreferencesPublic } from '../../pages/api/services';

// Components
import Button from '../common/Button/Button';

// Utils
import { savePreferencesOptOut } from '../../helpers/settingsHelper';

export default function UnsubscribeConfirm({ toggle, confirmLabel, confirmLabelLoading, errorsList, emailTopics }) {
  const [submitting, setSubmitting] = useState(false);
  const router = useRouter();

  const {
    actions,
    state: {
      session: { accessToken, isLoggedIn },
      navigation,
    },
  } = useStateMachine({ updateUser, updateNotification });

  const handleConfirmClick = () => {
    setSubmitting(true);

    // LOGGED IN METHOD TO SAVE SETTINGS
    if (isLoggedIn) {
      savePreferences(accessToken, savePreferencesOptOut(emailTopics, false))
        .then((res) => {
          if (res.data) {
            toggle();

            actions.updateUser({
              preferences: savePreferencesOptOut(emailTopics, false),
            });
          }
        })
        .catch((error) => {
          if (error.response !== null && error.response.data !== null) {
            actions.updateNotification({
              message: errorsList && errorsList[0].message,
              status: 'error',
            });
          }
          // console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }

    // LOGGED OUT METHOD TO SAVE SETTINGS
    if (!isLoggedIn && (navigation?.query?.q || router?.query?.q)) {
      savePreferencesPublic(
        savePreferencesOptOut(emailTopics, false),
        encodeURIComponent(router?.query?.q || navigation?.query?.q)
      )
        .then(async (res) => {
          if (res.data) {
            toggle();

            const response = await getSavedPreferencesPublic(encodeURIComponent(router?.query?.q || navigation?.query?.q));
            const preferencesData = response.data.data;

            actions.updateUser({
              guestPreferences: preferencesData,
            });
          }
        })
        .catch((error) => {
          if (error.response !== null && error.response.data !== null) {
            actions.updateNotification({
              message: errorsList && errorsList[0].message,
              status: 'error',
            });
          }
          console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  if (confirmLabel) {
    return (
      <Button
        type="button"
        className="sm:mr-4 unsub-btn confirm-btn"
        buttonLoadingText={confirmLabelLoading}
        ariaLabel={confirmLabel}
        onClick={handleConfirmClick}
      >
        {submitting ? confirmLabelLoading : confirmLabel}
      </Button>
    );
  }

  return null;
}

UnsubscribeConfirm.propTypes = {
  toggle: PropTypes.func,
  confirmLabel: PropTypes.string,
  confirmLabelLoading: PropTypes.string,
  errorsList: PropTypes.array,
  emailTopics: PropTypes.array,
};
