import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import LinkComponent from '../../Links/LinkComponent';
import ShippingStatus from '../../../Orders/Status/ShippingStatus';

import { InnerDetails, SectionTitle } from '../DetailsStyles';

import { formatPoints } from '../../../../helpers/pointsHelpers';
import { checkArrayForItems } from '../../../../helpers/utils';

export default function DetailListItems({ items, ptsLabel, itemsLabel, itemLabel, viewCardLabel, locale }) {
  const {
    state: {
      labels: { loadingLabel },
    },
  } = useStateMachine();

  const listOfItems = items;

  if (checkArrayForItems(items)) {
    return (
      <>
        <SectionTitle>{`${items?.length} ${items?.length > 1 ? itemsLabel : itemLabel}`}</SectionTitle>

        {listOfItems &&
          listOfItems.map((item, index) => (
            <InnerDetails key={index}>
              <div className="flex justify-between">
                <div className="flex-1">
                  {item?.itemName && <p className="order-name">{item?.itemName}</p>}
                  {item?.awardCode && <p className="order-id">{item?.awardCode}</p>}
                  {item?.shippingStatus && <ShippingStatus status={item?.shippingStatus} />}

                  {viewCardLabel && item?.fulfillmentUrl && (
                    <LinkComponent
                      href={item?.fulfillmentUrl}
                      ariaLabel={viewCardLabel}
                      className="color-black font-bold mt-6"
                      buttonLoadingText={loadingLabel}
                      locale={locale}
                    >
                      {viewCardLabel}
                    </LinkComponent>
                  )}
                </div>
                {item.redemptionValue && ptsLabel && (
                  <div>
                    <p>
                      {`${formatPoints(item.redemptionValue, locale)}`}
                      <span aria-hidden="true"> {ptsLabel} </span>
                      <span className="sr-only"> {ptsLabel} </span>
                    </p>
                  </div>
                )}
              </div>
            </InnerDetails>
          ))}
      </>
    );
  }
  return null;
}
DetailListItems.propTypes = {
  items: PropTypes.array,
  ptsLabel: PropTypes.string,
  itemsLabel: PropTypes.string,
  itemLabel: PropTypes.string,
  viewCardLabel: PropTypes.string,
  locale: PropTypes.string,
};
