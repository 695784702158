/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

export default function useOffersCleanup() {
  const { actions } = useStateMachine({ updateOffers });

  useEffect(
    () => () => {
      actions.updateOffers({
        offersList: [],
        limitedTimeOffersList: [],
        offersForYouList: [],
        moreOffersList: [],
        isLoading: true,
        selectedFilters: '',
        sortBy: {},
      });
    },
    []
  );
}
