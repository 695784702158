import PropTypes from 'prop-types';
// State
import { useStateMachine } from 'little-state-machine';

import BankCardsSliderWrapper from '../common/Banners/BankCardsSliderWrapper';

const ScotiabankCardsCarousel = ({ module, customData, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    const bankSlider = {
      title: fields.title || null,
      message: fields.message || null,
      disableCardsLink: fields.disableCardsLink === 'true',
      cardLinkLabel: fields.cardLinkLabel || null,
      disableCta: fields.disableCta === 'true',
      cTALink: fields.cTALink || null,
      cTALinkLabel: fields.cTALinkLabel || null,
      cTALinkLabelLoading: fields.cTALinkLabelLoading || null,
      slides: customData.scotiabankcards,
      gaCategory: fields.gACategory || '',
    };
    return (
      <section id="ScotiabankCards">
        <BankCardsSliderWrapper bankSlider={bankSlider} languageCode={languageCode} />
      </section>
    );
  }
  return null;
};

ScotiabankCardsCarousel.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'scotiabankcards',
      languageCode,
    });

    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      scotiabankcards: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};
ScotiabankCardsCarousel.propTypes = {
  module: PropTypes.object.isRequired,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
export default ScotiabankCardsCarousel;
