import { useState, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { renderHTML } from '@agility/nextjs';
import useReactGA from '../../hooks/services/useReactGA';
import Button from '../Button/Button';
import ExternalImage from '../Images/ExternalImage';

export default function CookieBanner({
  disclaimer,
  icon,
  acceptButtonLabel,
  rejectButtonLabel,
  rejectCookiesExpiresMaxAge,
  languageCode,
}) {
  const expiresOn = useMemo(() => {
    if (rejectCookiesExpiresMaxAge === 'never' || Number.isNaN(rejectCookiesExpiresMaxAge)) return;

    return Number(rejectCookiesExpiresMaxAge);
  }, [rejectCookiesExpiresMaxAge]);

  const { userChoice, setUserChoice } = useReactGA(expiresOn);
  const [isVisible, setIsVisible] = useState(!userChoice);
  const router = useRouter();

  useEffect(() => {
    if (typeof navigator !== 'undefined' && !navigator.cookieEnabled) {
      if (!window.location.pathname.includes('/scene-unavailable')) {
        router.push(`${languageCode}/scene-unavailable`);
      }
    }
  }, []);

  if (window !== 'undefined' && window.location.pathname.includes('/scene-unavailable')) return null;
  if (!isVisible) return null;

  return (
    <div id="cookie-banner">
      <div id="cookie-banner-content-wrapper">
        <div id="cookie-disclaimer">
          <ExternalImage
            url={icon.url}
            // alt="cookies icon"
            title={icon.label}
            width={50}
            height={50}
            data-testid="cookie-icon"
          />
          <div dangerouslySetInnerHTML={renderHTML(disclaimer)} />
        </div>

        <div className="cookies-action-buttons">
          <Button
            id="accept-button"
            className="primary-button"
            type="button"
            ariaabel={acceptButtonLabel}
            onClick={() => {
              setUserChoice('accept');
              setIsVisible(false);
            }}
          >
            {acceptButtonLabel}
          </Button>
          <Button
            id="reject-button"
            className="secondary-button-outline-purple"
            type="button"
            ariaLabel={rejectButtonLabel}
            onClick={() => {
              setUserChoice('reject');
              setIsVisible(false);
            }}
          >
            {rejectButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}
