import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getCustomerAddresses } from '../../../pages/api/services';

export default function useCustomerAddress() {
  const {
    actions,
    state: {
      session: { accessToken },
    },
  } = useStateMachine();

  const { isLoading, error, data, refetch } = useQuery('fetchCustomerAddress', async () => {
    try {
      if (accessToken) {
        const res = await getCustomerAddresses(accessToken);
        return res.data.data;
      }
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, customerAddresses: data, refetch };
}
