import PropTypes from 'prop-types';

// NextJS
import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';
import Image from 'next/image';

import ExternalImage from '../../common/Images/ExternalImage';

import { formatDate } from '../../../helpers/dateHelpers';

// Global State
import { updateOrder } from '../../../store/actions';

// Styles
import { OrderWrapper, IconContainerRewards, OrderContents, InnerContent, MerchantHeading, OrderButton } from '../OrderStyles';

export default function OrderItem({ award, itemLabel, item, isRecentOrdersList, sectionTitle, orderDetailsLink, languageCode }) {
  const router = useRouter();
  const { merchant, items, orderDate, orderNumber } = item;

  const { actions } = useStateMachine({ updateOrder });

  const handleClick = () => {
    if (item) {
      actions.updateOrder({ selectedOrder: item });
    }

    router.push(orderDetailsLink);
  };

  if (item) {
    return (
      <OrderButton
        type="button"
        onClick={handleClick}
        tabIndex="0"
        isRecentOrdersList={isRecentOrdersList}
        aria-label={`${sectionTitle}: ${merchant}, ${orderNumber}`}
      >
        <OrderWrapper hideBottomSpacing>
          {award && award.length && (
            <IconContainerRewards>
              <ExternalImage url={award[0]?.image.url} height={24} width={24} />
            </IconContainerRewards>
          )}
          <OrderContents>
            <InnerContent>
              <div>
                {award && award.length && award[0].name && <MerchantHeading>{award[0].name}</MerchantHeading>}
                {items && <p>{orderNumber}</p>}
                {items && (
                  <p>
                    {items && items.length && items[0].quantity} {itemLabel} &#8226;{' '}
                    {formatDate(orderDate, 'MMM DD, YYYY', languageCode)}
                  </p>
                )}
              </div>

              {items && items.length && !isRecentOrdersList && (
                <Image src="/assets/arrow-right-gray.svg" alt="" height={18} width={18} role="presentation" />
              )}
            </InnerContent>
          </OrderContents>
        </OrderWrapper>
      </OrderButton>
    );
  }
  return null;
}

OrderItem.propTypes = {
  award: PropTypes.array,
  item: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    orderNumber: PropTypes.number.isRequired,
    orderDate: PropTypes.string.isRequired,
    accountUniqueId: PropTypes.string.isRequired,
    accountSegment: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    totalRedemptionValue: PropTypes.number.isRequired,
    merchant: PropTypes.string.isRequired,
    shippingAddress: PropTypes.object,
  }),
  itemLabel: PropTypes.string,
  isRecentOrdersList: PropTypes.bool,
  sectionTitle: PropTypes.string,
  orderDetailsLink: PropTypes.string,
  languageCode: PropTypes.string,
};
