import { useQuery } from 'react-query';
import { getAward } from '../../../../pages/api/services';

export default function useAward(code, awardTypeCode) {
  const { isLoading, error, data } = useQuery(['fetchAwardPFC', code, awardTypeCode], async () => {
    const res = await getAward(code, awardTypeCode);

    return res.data.data;
  });

  return { isLoading, error, data };
}
