/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import Link from 'next/link';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import TitleWithLogo from '../common/Headings/TitleWithLogo';
import L3HeaderBanner from '../common/Banners/L3HeaderBanner';
import Button from '../common/Button/Button';
import TabbedComponent from '../common/Tabs/TabbedComponent';
import GuestSignInButton from '../signin/GuestSignInButton';
import { StyledPrimaryLink } from '../common/Links/LinkStyles';
import Heading2WithHtmlMessage from '../common/Headings/Heading2WithHtmlMessage';

// Helpers
import { handleGAEvents, getBrandOutboundClick } from '../../helpers/handleGoogleAnalyticsHelper';

const BrandPage = ({ module, customData, languageCode }) => {
  // get module fields
  const { fields } = module;

  const actionSubmit = `${customData.actionLink}&locale=${languageCode}&supplier=${fields.supplier}`;
  const link = `${customData.isProd ? fields.supplierLink?.href : fields.supplierLinkUAT?.href}`;
  const {
    state: {
      user: { customer },
      session: { isLoggedIn, accessToken },
    },
  } = useStateMachine();

  const tabsList = [
    {
      tabTitle: fields.aboutLabel,
      tabContent: fields.webAboutMessage,
    },
    {
      tabTitle: fields.termsLabel,
      tabContent: fields.webTermsMessage,
    },
  ];

  const handleOnSubmit = () => {
    getBrandOutboundClick('shoptoredeem_add_to_cart', fields?.brand?.fields?.code, fields?.brand?.fields?.title);
  };

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <>
        <L3HeaderBanner bgImage={fields.brand.fields.bannerWeb.url} />

        <WrapperLayout className="global-content-wrapper">
          <TitleWithLogo logo={fields?.brand?.fields?.logoSolidBG?.url}>{fields?.brand?.fields?.title}</TitleWithLogo>

          <Heading2WithHtmlMessage heading={fields?.howToOrderLabel} message={fields?.howToOrderMessage} />
          <Heading2WithHtmlMessage heading={fields?.whatHappensNowLabel} message={fields?.webWhatHappensNowMessage} />

          {isLoggedIn ? (
            fields?.supplier === 'Merchandise' || fields?.supplier === 'Apple' ? (
              <form method="post" action={actionSubmit} onSubmit={handleOnSubmit} className="p-0 md:mt-8 mb-12">
                <input type="hidden" name="AuthCode" value={accessToken} />
                <Button
                  type="submit"
                  buttonLoadingText={fields?.cTALabelLoading}
                  onClick={() => {
                    handleGAEvents(fields?.brand?.fields?.title, 'Reward', 'Reward Detail Click', 'reward_detail_click');
                  }}
                >
                  {fields?.cTALabel}
                </Button>
              </form>
            ) : (
              link && (
                <StyledPrimaryLink>
                  <Link href={link} locale={languageCode}>
                    <span>{fields?.cTALabel}</span>
                  </Link>
                </StyledPrimaryLink>
              )
            )
          ) : (
            <div className="md:mt-8 mb-12">
              {fields?.signinLabel && fields?.signinLabelLoading && (
                <GuestSignInButton fields={fields} languageCode={languageCode} loading={customer.data} />
              )}
            </div>
          )}
        </WrapperLayout>

        <WrapperLayout className="global-content-wrapper pb-8" isGray>
          <TabbedComponent tabs={tabsList} />
        </WrapperLayout>
      </>
    );
  }
  return null;
};

BrandPage.getCustomInitialProps = async () => {
  try {
    const actionLink = process.env.NEXT_BRAND_PAGE_URL || null;
    const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'PROD';
    return {
      actionLink,
      isProd,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

BrandPage.propTypes = { module: PropTypes.object, customData: PropTypes.object, languageCode: PropTypes.string };

export default BrandPage;
