import PropTypes from 'prop-types';
import Image from 'next/image';

import { useStateMachine } from 'little-state-machine';
import CustomButtonIcon from '../Button/CustomButtonIcon';
import { CloseIconStyles } from './IconStyles';

export default function CloseIcon({ toggle, type, width, height, className }) {
  const { state } = useStateMachine();
  const imgUrl = className === 'bg-black' ? '/assets/close-x-white.svg' : '/assets/close-x.svg';
  return (
    <CloseIconStyles type={type}>
      <CustomButtonIcon
        className="cursor-pointer font-bold"
        ariaLabel={state.labels?.closeLabel}
        onClick={() => toggle()}
        buttonType="close"
      >
        <Image
          src={imgUrl}
          alt={state.labels?.closeLabel}
          title={state.labels?.closeLabel}
          width={width}
          height={height}
          data-testid="close-icon"
        />
      </CustomButtonIcon>
    </CloseIconStyles>
  );
}

CloseIcon.defaultProps = {
  height: 24,
  width: 24,
};

CloseIcon.propTypes = {
  toggle: PropTypes.func,
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
