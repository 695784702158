// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import OfferDetailsModal from '../common/Modals/OfferDetailsModal';

export default function GuestOfferDetails({ module, languageCode }) {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
      navigation: { isReady },
    },
  } = useStateMachine();

  if (isReady && isLoggedIn) {
    return null;
  }

  const url = window?.location?.href;
  const params = window?.location?.search;
  const path = window?.location?.pathname;

  if (url.includes('#state=') || (!params && path === '/')) {
    return null;
  }

  return <OfferDetailsModal fields={fields} languageCode={languageCode} />;
}
