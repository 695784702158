/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  padding-top: 1rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${(props) => props.theme.uiGrey02};
  }
  @media screen and (max-width: 640px) {
    padding-top: 0.5rem;
  }
`;

export const Tab = styled.button`
  width: auto;
  outline: 0;
  cursor: pointer;
  position: relative;
  margin-right: 2rem;
  ${(props) =>
    props.isLarge &&
    css`
      font-size: ${(props) => props.theme.fontBody2};
      @media screen and (min-width: 991px) {
        font-size: ${(props) => props.theme.fontBody1};
      }
    `}

  ${(props) =>
    !props.isLarge &&
    css`
      font-size: ${(props) => (props.active ? '0.875rem' : '1rem')};
    `}

  font-weight: ${(props) => (props.active ? 'bold' : 500)};
  border-radius: 0;
  padding: 1rem 0;
  background-color: transparent;
  color: ${(props) => props.theme.uiCharcoal};
  transition: all 0.2s ease-in-out;
  z-index: 0;
  &:after {
    ${(props) => (props.active ? 'content: ""' : '')};
    height: 4px;
    width: 100%;
    background-color: ${(props) => props.theme.uiPurple};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:active,
  &:focus {
    outline: 1px solid ${(props) => props.theme.uiPurple};
  }
`;

export const Content = styled.div`
  padding: 1.5rem 0 2rem 0;
  ${(props) => (props.active ? '' : 'display: none')};

  div,
  p,
  li {
    ${(props) =>
      props.isLarge &&
      css`
        font-size: ${(props) => props.theme.fontBody2};
        @media screen and (min-width: 991px) {
          font-size: ${(props) => props.theme.fontBody1};
        }
      `}

    ${(props) =>
      !props.isLarge &&
      css`
        font-size: ${(props) => props.theme.fontLegal};
      `}

    line-height: ${(props) => props.theme.lineHeight3};
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  ol,
  ul {
    margin-bottom: 0;
  }
`;
