import styled from 'styled-components';

// Wrapper
export const FilterModalWrapper = styled.div`
  height: 90vh;
`;

// Header
export const ModalPopupHeader = styled.div``;

export const ProvincialModalHeaderStyle = styled.div`
  padding: 1rem;
  position: relative;

  h2 {
    margin: 0;
    font-size: ${(props) => props.theme.headingMedium};
    line-height: ${(props) => props.theme.lineHeight4};
    font-weight: 700;
  }

  .close-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 768px) {
    padding: 1.5rem 2rem;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
`;

export const FilterModalPopupHeader = styled.div`
  padding: 1rem;
  position: relative;
  height: 5rem;

  border-bottom: 1px solid ${(props) => props.theme.bottomBorderLightGrayOpaque};

  h2 {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: ${(props) => props.theme.lineHeight4};
  }

  .close-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 768px) {
    padding: 1.5rem 2rem;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
`;

// Body
export const ModalPopupBody = styled.div`
  position: relative;
  width: 100%;
  line-height: ${(props) => props.theme.lineHeight2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const FilterModalBodyWrapper = styled.div`
  @media screen and (min-width: 768px) and (min-height: 34.75rem) {
    height: 65vh;
    overflow-y: auto;
  }

  @media screen and (min-width: 768px) and (min-height: 47.75rem) {
    height: 67vh;
    overflow-y: auto;
  }

  @media screen and (min-width: 768px) and (min-height: 55rem) {
    height: 71vh;
  }

  @media screen and (min-width: 768px) and (min-height: 62.5rem) {
    height: 72vh;
  }

  @media screen and (min-width: 768px) and (min-height: 77rem) {
    height: 75vh;
  }

  @media screen and (min-width: 768px) and (min-height: 82rem) {
    height: 77vh;
  }

  @media screen and (min-width: 768px) and (min-height: 100.125rem) {
    height: 79vh;
  }
`;

export const FilterModalPopupBody = styled.div`
  position: relative;
  width: 100%;
  line-height: ${(props) => props.theme.lineHeight2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 2rem 2rem;
  }
`;

export const ProvincialModalBodyStyle = styled.div`
  position: relative;
  width: 100%;
  line-height: ${(props) => props.theme.lineHeight2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 2rem 2rem;
  }
`;

export const ProvincialDropdownOnlyLabelWrapper = styled.div`
@media screen and (min-width: 1024px) {
  display:none;
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
  display:none;
}

@media screen and (max-width: 640px) {
 width:100%
 margin: 0 auto;
}
`;

export const ProvinceDropDownWrapper = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    width: 100%;
  }
`;
export const ProvinceItemsWrapper = styled.div`
  display: grid;
  grid-gap: 1.875rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    + div > button.view-more {
      display: none;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

// Footer
export const ModalPopupFooter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const FilterModalPopupFooter = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.bottomBorderLightGrayOpaque};
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .reset-filter-btn {
    width: auto;
  }

  .apply-filter-btn {
    width: 100%;
    font-size: ${(props) => props.theme.fontBody2};

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`;
