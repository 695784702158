/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';

const SharedRichTextArea = ({ module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <WrapperLayout className="global-content-wrapper">
        <div dangerouslySetInnerHTML={renderHTML(fields.contentBlob)} />
      </WrapperLayout>
    );
  }

  return null;
};

SharedRichTextArea.propTypes = {
  module: PropTypes.object.isRequired,
};
export default SharedRichTextArea;
