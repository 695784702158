import styled from 'styled-components';

export const SidebarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    margin-top: 0;
    grid-gap: 100px;
  }
`;
export const NavigatedContent = styled.div`
  @media screen and (min-width: 768px) {
    flex: 0 0 70%;
  }

  h1,
  h2 {
    @media screen and (min-width: 641px) {
      padding: 0;
    }
  }
`;
export const SidebarWrapper = styled.div`
  flex: 0 0 25%;
  margin-bottom: 1.5rem;

  button {
    background: transparent;
    padding: 1rem 0;
    color: ${(props) => props.theme.uiCharcoal};
    border-bottom: 1px solid ${(props) => props.theme.lightGrayColor3};
    text-align: left;
    border-radius: 0;
    font-weight: normal;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &.active-nav {
      font-weight: bold;
    }
  }
`;
