/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  padding: 2rem 2.5rem 2rem 2rem;
  @media screen and (min-width: 991px) {
    padding: 2rem 3.25rem 2rem 2rem;
  }
  @media screen and (max-width: 640px) {
    padding: 2rem 3.25rem 2rem 0.875rem;
  }
  @media screen and (min-width: 641px) and (max-width: 990px) {
    padding: 2rem 3.25rem 2rem 1.375rem;
  }

  &:focus {
    outline: 2px solid ${(props) => props.theme.uiPurple};
  }
`;
export const ItemIcon = styled.div`
  margin-right: 1rem;
  min-width: 4.5rem;
  ${(props) =>
    props.showButton &&
    css`
      align-self: center;
      margin-right: 0px;
    `}
`;
export const ItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    cursor: pointer;
    text-decoration: none;
  }
  h3 {
    text-align: left;
    color: ${(props) => props.theme.uiCharcoal};
    font-size: ${(props) => props.theme.fontBody2};
    margin-bottom: 0.375rem;
  }

  p {
    color: ${(props) => props.theme.uiCharcoal};
    text-align: left;
    font-size: ${(props) => props.theme.fontLegal};
    line-height: 1.75;
    &:last-of-type {
      margin: 0;
    }
  }
`;
