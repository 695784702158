import PropTypes from 'prop-types';

// Components
import PointsItems from './PointsItems';

// Utils
import { formatDateDayFirst } from '../../../helpers/dateHelpers';

export default function PointsHistoryRow({
  pages,
  customData,
  transactionIconTypes,
  accountSegments,
  awardsTypes,
  languageCode,
}) {
  if (pages) {
    return (
      <>
        {pages.map((page, index) => (
          <div key={index}>
            {[
              ...new Map(page?.pointsHistory && page?.pointsHistory.map((o) => [formatDateDayFirst(o?.pointDate), o])).values(),
            ].map((pointItem) => (
              <PointsItems
                key={pointItem?.pointId}
                page={page}
                customData={customData}
                transactionIconTypes={transactionIconTypes}
                accountSegments={accountSegments}
                awardsTypes={awardsTypes}
                languageCode={languageCode}
                {...pointItem}
              />
            ))}
          </div>
        ))}
      </>
    );
  }
}

PointsHistoryRow.propTypes = {
  pages: PropTypes.array,
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string,
  transactionIconTypes: PropTypes.array,
  accountSegments: PropTypes.array,
  awardsTypes: PropTypes.array,
};
