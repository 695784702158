import { StyledArrow } from './BannerStyles';

export function NextArrow(props) {
  const { className, style, onClick } = props;
  return <StyledArrow className={`${className} next-arrow`} style={{ ...style }} onClick={onClick} />;
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return <StyledArrow className={`${className} prev-arrow`} style={{ ...style }} onClick={onClick} />;
}
