/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export default function useScript(src, options, languageCode = {}) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  const [locale, setLocale] = useState(languageCode);

  function setZendeskLocale() {
    const sLang = document.querySelector(`script[data-lang="${languageCode}"]`);
    if (!sLang) {
      if (languageCode !== locale) {
        const sLocale = document.querySelector(`script[data-lang="${locale}"]`);
        if (sLocale) sLocale.remove();
        setLocale(languageCode);
      }
      // search widget by ID script
      const widget = document.getElementById('ze-snippet');
      if (widget) {
        const scriptLocale = document.createElement('script');
        scriptLocale.setAttribute('data-lang', languageCode);
        scriptLocale.text = `zE("messenger:set", "cookies", false);zE('messenger:set', 'locale','${languageCode}');zE("messenger:set", "cookies", true);zE('messenger', 'show');`;
        widget.after(scriptLocale);
      }
    }
  }

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');

        if (options?.id) {
          script.setAttribute('id', options.id);
        }

        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  useEffect(
    () => {
      if (status && status === 'ready') {
        setZendeskLocale();
      }
    },
    [status, languageCode] // Only re-run effect if status/language changes
  );

  return status;
}
