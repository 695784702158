/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import { useMsal } from '@azure/msal-react';

// Global state
import { useStateMachine } from 'little-state-machine';
import updateStepAction from '../../../store/actions/updateStepAction';
import { updateUser } from '../../../store/actions';
import clearAction from '../../../store/actions/clearAction';

// Components
import Button from '../../common/Button/Button';
import FormHeader from './FormHeader';
import FormReadonlyInput from '../fields/FormReadonlyInput';
// Styles
import { ResultContainer, LineSpacer } from '../FormStyles';

// Helpers
import { b2cSignInWithRedirectUrl } from '../../../b2c/b2cFunctions';
import { formatDynamicLabel } from '../../../helpers/stringHelpers';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function Result({
  finishHeader,
  finishSubheader,
  signInLabel,
  stepLabel,
  languageCode,
  downloadappcontent,
  appStoreLink,
  googlePlayLink,
  finishSceneCardNumberLabel,
  copiedSuccessfulyLabel,
  webDownloadLink,
  copyLabel,
  tag181,
  tag183,
}) {
  const { actions, state } = useStateMachine({
    updateUser,
    updateStepAction,
    clearAction,
  });

  const { instance } = useMsal();

  const totalSteps = state?.user?.isGhostKitActivationEnabled ? 3 : 2;
  const isMobile = state?.user?.req?.deviceDetail?.deviceType === 'mobile';

  let content;

  if (isMobile) {
    if (state?.user?.req?.deviceDetail?.isIphone || state?.user?.req?.deviceDetail?.isAndroid) {
      if (state?.user?.req?.deviceDetail?.isIphone) {
        content = formatDynamicLabel(downloadappcontent, '##APPLINK##', appStoreLink?.href);
      }
      if (state?.user?.req?.deviceDetail?.isAndroid) {
        content = formatDynamicLabel(downloadappcontent, '##APPLINK##', googlePlayLink?.href);
      }
    } else {
      content = formatDynamicLabel(downloadappcontent, '##APPLINK##', webDownloadLink);
    }
  } else {
    content = formatDynamicLabel(downloadappcontent, '##APPLINK##', webDownloadLink);
  }

  const gaEvent = (e) => {
    const dataLayer = {
      event: 'sign_in_click_after_sign_up',
      category: 'Registration Events',
      action: 'Sign In Click',
      label: 'Sign In After Registration',
      sign_up_step: `Step 3${state?.user?.isGhostKitActivationEnabled ? ' GK' : ''}`,
    };
    handleGAEventsBase(dataLayer);
  };

  const signIn = (e) => {
    gaEvent(e);
    b2cSignInWithRedirectUrl(instance, languageCode, `${process.env.NEXT_PUBLIC_REDIRECT_URI}`);
  };

  useEffect(() => {
    if (tag181) {
      const dataLayerTitle = {
        event: 'unified_screen_name',
        page_title: state?.user?.isGhostKitActivationEnabled ? tag181 : tag183,
      };
      handleGAEventsBase(dataLayerTitle);
    }
  }, [tag181]);

  return (
    <ResultContainer>
      <div className="w-full px-4 sm:py-12 mb-10 md:mb-4 sm:px-14">
        <FormHeader
          stepLabel={stepLabel}
          currentStep={totalSteps}
          totalSteps={totalSteps}
          title={finishHeader}
          subtitle={finishSubheader}
        />
        <LineSpacer className="sm:max-w-xs mx-auto" />
        <div className="sm:max-w-xs mx-auto">
          <FormReadonlyInput
            id="scene-card-number"
            fieldType="scene-card-number"
            name="scene-card-number"
            label={finishSceneCardNumberLabel}
            defaultVal={state.user.sceneNumber}
            value={state.user.sceneNumber}
            copiedSuccessfulyLabel={copiedSuccessfulyLabel}
            copyImageLabel={copyLabel}
          />
        </div>
        {signInLabel && (
          <div className="sm:max-w-xs mx-auto">
            <Button className="mt-0" onClick={(e) => signIn(e)} style={{ width: '100%' }}>
              {signInLabel}
            </Button>
          </div>
        )}

        {content && <div className="my-8" dangerouslySetInnerHTML={renderHTML(content)} />}
      </div>
    </ResultContainer>
  );
}

Result.propTypes = {
  finishHeader: PropTypes.string,
  finishSubheader: PropTypes.string,
  signInLabel: PropTypes.string,
  stepLabel: PropTypes.string,
  languageCode: PropTypes.string,
  downloadappcontent: PropTypes.object,
  appStoreLink: PropTypes.object,
  googlePlayLink: PropTypes.object,
  finishSceneCardNumberLabel: PropTypes.string,
  copiedSuccessfulyLabel: PropTypes.string,
  webDownloadLink: PropTypes.object,
  copyLabel: PropTypes.string,
  tag181: PropTypes.string,
  tag183: PropTypes.string,
};
