/* eslint-disable react/no-danger */

import { renderHTML } from '@agility/nextjs';
import PropTypes from 'prop-types';

// Components
import PortalModal from '../common/Modals/PortalModal';
import UnsubscribeSuccessModal from '../common/Modals/UnsubscribeSuccessModal';
import UnsubscribeButtons from './UnsubscribeButtons';

// Hooks
import useModal from '../hooks/utils/useModal';

// Styles
import { UnsubscribeContentContainer } from './UnsubscribeStyles';

export default function UnsubscribeHasParams({ customData, errorsList, pageContent }) {
  const {
    confirmLabel,
    confirmLabelLoading,
    message,
    successDoneLabel,
    successHeader,
    successMessage,
    updateLabel,
    updateLabelLoading,
  } = customData;
  const { emailTopics } = pageContent;
  const { isShowing, toggle } = useModal();

  return (
    <>
      <UnsubscribeContentContainer>
        {message && <div className="mb-4" dangerouslySetInnerHTML={renderHTML(message)} />}

        <UnsubscribeButtons
          toggle={toggle}
          dynamicData={{
            confirmLabel,
            confirmLabelLoading,
            updateLabel,
            updateLabelLoading,
          }}
          errorsList={errorsList}
          emailTopics={emailTopics}
        />
      </UnsubscribeContentContainer>

      <PortalModal isShowing={isShowing} toggle={toggle} type="modal-confirmation">
        <UnsubscribeSuccessModal
          toggle={toggle}
          dynamicData={{
            buttonLabel: successDoneLabel,
            successHeader,
            successMessage,
          }}
        />
      </PortalModal>
    </>
  );
}

UnsubscribeHasParams.propTypes = {
  customData: PropTypes.object,
  errorsList: PropTypes.array,
  pageContent: PropTypes.object,
};
