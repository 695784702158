import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import LottieLoader from '../common/PageLoader/LottieLoader';
import useHasMount from '../hooks/utils/useHasMount';
import { b2cResetPassword } from '../../b2c/b2cFunctions';

const ForgotPassword = ({ languageCode }) => {
  const { instance, inProgress } = useMsal();
  const isMounted = useHasMount();

  useEffect(() => {
    handleB2Ccall();
  }, [isMounted]);

  function handleB2Ccall() {
    try {
      if (inProgress === InteractionStatus.None && isMounted) {
        b2cResetPassword(instance, languageCode || 'en-ca');
      }
    } catch (error) {
      console.error('Script Load Error - 1');
    }
  }

  function derviveLoaderMsg() {
    let loaderMsg = 'Loading...';

    if (languageCode === 'fr-ca') {
      loaderMsg = 'Chargement...';
    }

    return loaderMsg;
  }

  return <LottieLoader loadingLabel={derviveLoaderMsg()} />;
};

export default ForgotPassword;
