import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';
import ExternalImage from '../Images/ExternalImage';
import { StyledList, ImageContainer, ImageAppContainer } from './SpendPointsListStyles';

import { scrollToElement } from '../../../helpers/behavourHelper';

function SpendPointsList({ list }) {
  const viewPortWidth = useWindowWidth();
  const handleClick = (e, anchorName) => {
    e.preventDefault();
    const element = document.getElementById(anchorName);
    scrollToElement(element, viewPortWidth);
  };

  const handleKeyDown = (e, anchorName) => {
    if (e.keyCode === 13) {
      handleClick(e, anchorName);
    }
  };
  if (list && list.length) {
    return (
      <StyledList className="grid grid-cols-1 gap-0">
        {list.map((item, index) => (
          <li
            key={index}
            className="list-none flex text-left items-stretch justify-between"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleClick(e, `Partners-${item.anchorName}`)}
            tabIndex="0"
            onKeyDown={(e) => handleKeyDown(e, `Partners-${item.anchorName}`)}
          >
            <div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>

            <ImageContainer aria-hidden="true">
              <ExternalImage url={item.image.url} className="object-contain object-right-bottom" fill />
            </ImageContainer>
            <ImageAppContainer aria-hidden="true">
              <ExternalImage url={item.appImage.url} className="object-contain object-right-bottom" fill />
            </ImageAppContainer>
          </li>
        ))}
      </StyledList>
    );
  }

  return null;
}

SpendPointsList.propTypes = { list: PropTypes.array };

export default SpendPointsList;
