/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { IntroStyle, LastUpdated } from './IntroStyles';

export default function Intro({ fields }) {
  if (fields) {
    return (
      <IntroStyle>
        {(fields.lastUpdatedLabel || fields.lastUpdatedMessage) && (
          <>
            {fields.lastUpdatedLabel && <LastUpdated>{fields.lastUpdatedLabel}</LastUpdated>}
            {fields.lastUpdatedMessage && (
              <div className="mt-5" dangerouslySetInnerHTML={{ __html: fields.lastUpdatedMessage }} />
            )}

            <hr />
          </>
        )}

        {fields.introductionTitle && <h2>{fields.introductionTitle}</h2>}
        {fields.introductionMessage && <div className="mt-8" dangerouslySetInnerHTML={{ __html: fields.introductionMessage }} />}
      </IntroStyle>
    );
  }

  return null;
}

Intro.propTypes = { fields: PropTypes.object };
