import styled from 'styled-components';

export const StyledList = styled.ul`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: 1024px) {
    display: flex;

    grid-template-columns: none;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    color: ${(props) => props.theme.whiteColor};
    max-width: 7.7rem;

    @media screen and (min-width: 641px) {
      min-width: 6.5rem;
    }

    .category-tile-title {
      font-size: ${(props) => props.theme.fontLegal};
      line-height: ${(props) => props.theme.lineHeight3};
      font-weight: bold;
      padding: 0 0.25rem;
      @media screen and (min-width: 641px) {
        font-size: ${(props) => props.theme.fontBody2};
      }
    }

    a {
      &:hover,
      &:active,
      &:focus {
        .category-circle-tile {
          border: 2px solid ${(props) => props.theme.uiPurple};
        }
      }

      &:focus-visible {
        outline: 0;

        .category-circle-tile {
          border: 2px solid ${(props) => props.theme.uiPurple};
        }
      }
    }
  }
`;
export const ImageCircle = styled.div`
  position: relative;
  overflow: hidden;
  width: 4.375rem;
  height: 4.375rem;
  border: 2px solid ${(props) => props.theme.uiBlack};
  transition: border 0.2s ease-in;
  margin-bottom: 0.5rem;
  @media screen and (min-width: 641px) {
    width: 5.625rem;
    height: 5.625rem;
    margin-bottom: 1rem;
  }
`;
