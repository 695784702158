/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';

import { Section } from './SceneUnavailableStyles';

export default function SceneUnavailableSection({ fields }) {
  if (fields) {
    return (
      <Section>
        <h1>{fields.title}</h1>
        <div dangerouslySetInnerHTML={renderHTML(fields.message)} />
      </Section>
    );
  }

  return null;
}

SceneUnavailableSection.propTypes = { fields: PropTypes.object };
