import { useState, useRef } from 'react';
import { expandLinkedList } from '@agility/nextjs/utils';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import {
  SiteHeaderLogo,
  HeaderNavigation,
  AccountPoints,
  HeaderMoreLink,
  HeaderAccount,
  HeaderLanguage,
  MobileFlyoutMenu,
  OpenMobileMenuButton,
  HeaderLoggedOutRegister,
  Backdrop,
} from './HeaderSections';

import HeaderWithLogoOnly from './HeaderWithLogoOnly';

import ProvincialSegment from './HeaderSections/ProvincialSegment';
import ProvincialModal from '../../Modals/ProvincialSegmentation/ProvincialModal';

// Hooks
import useModal from '../../../hooks/utils/useModal';
import useOutsideClick from '../../../hooks/utils/useOutsideClick';
import useHasMount from '../../../hooks/utils/useHasMount';
import useNavigation from '../../../hooks/navigation/useNavigation';

// Helpers
import { deriveInlineMsgList } from '../../../../helpers/agilityHelpers';

// Styles
import { Header, SiteHeaderWrap, Section, HeaderColumnWrapper, HeaderColumn, MobileSection } from './HeaderStyles';

const SiteHeader = ({ globalData, sitemapNode, languageCode }) => {
  // get header data
  const { header } = globalData;
  const { fields } = header;
  const {
    siteLogo,
    mainLinks,
    anonymousMainLinks,
    anonymousMoreLinks,
    moreLinkLabel,
    moreLinks,
    pointsPageLink,
    ptsLabel,
    accountIcon,
    profileLinks,
    loadingLabel,
    signOutLabel,
    signInIcon,
    signInLabel,
    englishAbbreviatedLabel,
    frenchAbbreviatedLabel,
    englishLabel,
    frenchLabel,
    ariaLabelSelectedLanguage,
    accountLabel,
    locationIcon,
  } = fields;

  const disableProvincialSegmentation = process.env.NEXT_PUBLIC_DISABLE_PROVINCIAL_SEGMENTATION === 'false';
  // open / close account nav
  const [openAccount, setOpenAccount] = useState(false);
  // open / close more nav
  const [openMore, setOpenMore] = useState(false);

  // open / close language nav
  const [openLanguage, setOpenLanguage] = useState(false);

  // open / close language nav
  const [openLanguageMobile, setOpenLanguageMobile] = useState(false);

  // open / close mobile nav
  const [openHumburger, setOpenHumburger] = useState(false);

  const colapseDesktopMenus = () => {
    if (openAccount) {
      setOpenAccount(false);
    }

    if (openMore) {
      setOpenMore(false);
    }

    if (openLanguage) {
      setOpenLanguage(false);
    }
  };

  // Element Refs w/ handlers
  const refMore = useRef();
  useOutsideClick(refMore, () => {
    if (openMore) {
      setOpenMore(false);
    }
  });

  const refAccount = useRef();
  useOutsideClick(refAccount, () => {
    if (openAccount) {
      setOpenAccount(false);
    }
  });

  const refLanguage = useRef();
  useOutsideClick(refLanguage, () => {
    if (openLanguage) {
      setOpenLanguage(false);
    }
  });

  const refLanguageMobile = useRef();
  useOutsideClick(refLanguageMobile, () => {
    if (openLanguageMobile) {
      setOpenLanguageMobile(false);
    }
  });

  const refHumburger = useRef();
  useOutsideClick(refHumburger, () => {
    if (openHumburger) {
      setOpenHumburger(false);
    }
  });

  const {
    state: {
      layout,
      transactions: { balance },
      location,
      user: {
        customer: { data },
      },
      session: { isLoggedIn },
    },
  } = useStateMachine();
  const hasMounted = useHasMount();
  const { isShowing, toggle } = useModal();
  // TODO: Migrate to MastHead? - problem: onStart dep
  useNavigation({ languageCode, onStart: colapseDesktopMenus });
  const toggleProvincialPopup = () => {
    toggle();
  };

  if (!hasMounted) {
    return null;
  }

  if (!header) {
    return null;
  }
  // return for GC, PFC, BYOT confirmation steps
  if (!layout?.fullNav && layout?.fullNav !== undefined) {
    return <HeaderWithLogoOnly image={siteLogo.url} title={siteLogo.label} />;
  }

  return (
    <>
      <Header className="w-full" isDark={layout?.isDark}>
        <SiteHeaderWrap className="relative global-content-wrapper flex items-end z-50" role="navigation">
          <SiteHeaderLogo siteLogo={siteLogo} sitemapNode={sitemapNode} />
          <HeaderColumnWrapper className="flex-grow">
            <HeaderNavigation
              isLoggedIn={isLoggedIn}
              mainLinks={mainLinks}
              sitemapNode={sitemapNode}
              anonymousMainLinks={anonymousMainLinks}
              languageCode={languageCode}
            />

            <HeaderMoreLink
              isLoggedIn={isLoggedIn}
              moreLinkLabel={moreLinkLabel}
              moreLinks={moreLinks}
              anonymousMoreLinks={anonymousMoreLinks}
              useOutsideClick={useOutsideClick}
              openMore={openMore}
              setOpenMore={setOpenMore}
              refMore={refMore}
              languageCode={languageCode}
            />

            {isLoggedIn && (
              <HeaderColumn className="flex-grow-0 headerAccountColumn">
                <AccountPoints
                  pointsPageLink={pointsPageLink}
                  balance={balance}
                  ptsLabel={ptsLabel}
                  languageCode={languageCode}
                  isDark={layout?.isDark}
                />
                {disableProvincialSegmentation && (
                  <ProvincialSegment
                    locationIcon={locationIcon}
                    loadingLabel={loadingLabel}
                    tabletHide
                    toggleProvincialPopup={toggleProvincialPopup}
                    isShowingProvincialPopup={isShowing}
                    id="province-desktop"
                  />
                )}

                <Section className="tabletHide" ref={refAccount}>
                  <HeaderAccount
                    accountIcon={accountIcon}
                    accountLabel={accountLabel}
                    loadingLabel={loadingLabel}
                    openAccount={openAccount}
                    setOpenAccount={setOpenAccount}
                    setOpenLanguage={setOpenLanguage}
                    data={data}
                    signOutLabel={signOutLabel}
                    signInIcon={signInIcon}
                    signInLabel={signInLabel}
                    languageCode={languageCode}
                    accountLinks={profileLinks}
                  />
                </Section>
              </HeaderColumn>
            )}

            <HeaderLoggedOutRegister isLoggedIn={isLoggedIn} header={fields} languageCode={languageCode} />

            <HeaderLanguage
              englishAbbreviatedLabel={englishAbbreviatedLabel}
              frenchAbbreviatedLabel={frenchAbbreviatedLabel}
              englishLabel={englishLabel}
              frenchLabel={frenchLabel}
              languageCode={languageCode}
              refLanguage={refLanguage}
              setOpenAccount={setOpenAccount}
              setOpenLanguage={setOpenLanguage}
              openLanguage={openLanguage}
              ariaLabelSelectedLanguage={ariaLabelSelectedLanguage}
            />
            <Backdrop setOpenHumburger={setOpenHumburger} openHumburger={openHumburger} />
            <MobileSection className="flex-grow-0">
              <Section>
                <OpenMobileMenuButton
                  setOpenLanguageMobile={setOpenLanguageMobile}
                  setOpenHumburger={setOpenHumburger}
                  openHumburger={openHumburger}
                />

                <MobileFlyoutMenu
                  header={fields}
                  isLoggedIn={isLoggedIn}
                  data={data}
                  setOpenHumburger={setOpenHumburger}
                  openHumburger={openHumburger}
                  openLanguageMobile={openLanguageMobile}
                  setOpenLanguageMobile={setOpenLanguageMobile}
                  openLanguage={openLanguage}
                  languageCode={languageCode}
                  locationIcon={locationIcon}
                  location={location}
                  loadingLabel={loadingLabel}
                  refLanguageMobile={refLanguageMobile}
                  ariaLabelSelectedLanguage={ariaLabelSelectedLanguage}
                  toggleProvincialPopup={toggleProvincialPopup}
                  isShowingProvincialPopup={isShowing}
                />
              </Section>
            </MobileSection>
          </HeaderColumnWrapper>
        </SiteHeaderWrap>
        <div id="portal-mobile-home1" />
        <div id="portal-mobile-rewards2" />
      </Header>
      {isLoggedIn && disableProvincialSegmentation && (
        <ProvincialModal fields={fields} isShowing={isShowing} toggle={toggle} languageCode={languageCode} />
      )}
    </>
  );
};

SiteHeader.getCustomInitialProps = async function ({ agility, channelName, languageCode }) {
  // set up api
  const api = agility;

  // set up content item
  let contentItem = null;
  let sitemap = null;

  try {
    // get sitemap...
    const agilitySitemap = await api.getSitemap({
      channelName,
      languageCode,
    });

    sitemap = Object.keys(agilitySitemap);
  } catch (error) {
    console.error('failed to fetch sitemap', error);
  }

  try {
    // try to fetch our site header
    const header = await api.getContentList({
      referenceName: 'globalheader',
      languageCode,
    });

    // if we have a header, set as content item
    if (header && header.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      contentItem = header[0];

      // Main Links
      if (contentItem.fields.mainLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'mainLinks',
          sortIDField: 'mainLinks_SortIdField',
        });
      }

      // More Menu Links
      if (contentItem.fields.moreLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'moreLinks',
          sortIDField: 'moreLinks_SortIdField',
        });
      }

      // Account Links
      if (contentItem.fields.profileLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'profileLinks',
        });
        contentItem.fields.profileLinks.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
      }

      // anonymous links;
      if (contentItem.fields.anonymousMainLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'anonymousMainLinks',
          sortIDField: 'anonymousMainLinks_SortIdField',
        });
      }

      if (contentItem.fields.anonymousMoreLinks) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'anonymousMoreLinks',
          sortIDField: 'anonymousMoreLinks_SortIdField',
        });
      }

      // Global Labels
      if (contentItem.fields.globalLabels) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'globalLabels',
        });
      }

      // inlineMessageList
      if (contentItem?.fields?.inlineMessageList) {
        contentItem = await expandLinkedList({
          agility,
          contentItem,
          languageCode,
          fieldName: 'inlineMessageList',
        });
      }
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load site header item.', error);
    return null;
  }

  // return clean object...
  return {
    sitemap,
    fields: contentItem && contentItem?.fields,
    inlineMsgList: deriveInlineMsgList(contentItem?.fields?.inlineMessageList),
  };
};

export default SiteHeader;

SiteHeader.propTypes = {
  globalData: PropTypes.object,
  sitemapNode: PropTypes.object,
  languageCode: PropTypes.string,
};
