import PropTypes from 'prop-types';
import Link from 'next/link';
import ExternalImage from '../../../Images/ExternalImage';

export default function FooterAppStore({ footer }) {
  const data = [
    {
      storeImage: footer.fields.appStoreImage,
      storeLink: footer.fields.appStoreLink,
      width: 124,
      height: 42,
    },
    {
      storeImage: footer.fields.googlePlayImage,
      storeLink: footer.fields.googlePlayLink,
      width: 142,
      height: 42,
    },
  ];
  return (
    <div className="app-store-links">
      {data &&
        data.map((item, index) => (
          <div key={index} className="image-wrapper">
            <Link href={item.storeLink.href} as={item.storeLink.href} target={item.storeLink.target}>
              {item.storeImage && (
                <ExternalImage url={item.storeImage.url} title={item.storeLink.text} width={item.width} height={item.height} />
              )}
            </Link>
          </div>
        ))}
    </div>
  );
}

FooterAppStore.propTypes = {
  footer: PropTypes.object,
};
