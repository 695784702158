import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ExternalImage from '../common/Images/ExternalImage';

import Button from '../common/Button/Button';

import { EmptyOrderWrapper, IconContainer, OrderContents } from './OrderStyles';

export default function EmptyOrder({ ordersContent, showButton, recentOrders }) {
  const { image, title, message, cTALink } = ordersContent;

  const router = useRouter();

  return (
    <EmptyOrderWrapper showButton={showButton} recentOrders={recentOrders}>
      {image && (
        <IconContainer showButton={showButton}>
          <ExternalImage url={image.url} height={135} width={200} />
        </IconContainer>
      )}
      <OrderContents emptyOrder showButton={showButton} recentOrders={recentOrders}>
        {title && <p className="empty-orders-heading">{title}</p>}
        {message && <p>{message}</p>}

        {showButton && cTALink && (
          <Button
            type="submit"
            buttonText={cTALink.text}
            ariaLabel={cTALink.text}
            style={{ marginTop: '2rem', width: '100%', maxWidth: '480px' }}
            onClick={() => router.push(cTALink.href)}
          >
            {cTALink.text}
          </Button>
        )}
      </OrderContents>
    </EmptyOrderWrapper>
  );
}

EmptyOrder.propTypes = {
  ordersContent: PropTypes.object,
  showButton: PropTypes.bool,
  recentOrders: PropTypes.bool,
};
