import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

import { updateOrder } from '../../store/actions';

import { AllOrdersWrapper, SelectOrderWrapper } from './OrderStyles';

import Title from '../common/Headings/Title';
import FilterOrdersByDate from './Filter/FilterOrdersByDate';
import OrdersList from './OrdersList/OrdersList';

export default function OrdersHub({ customData, languageCode }) {
  const {
    ordersContent: { ordersPageHeader, periodLabel },
  } = customData;

  // Global State
  const { actions } = useStateMachine({ updateOrder });

  useEffect(() => {
    actions.updateOrder({
      selectedOrder: {},
    });
  }, []);

  return (
    <AllOrdersWrapper>
      {ordersPageHeader && (
        <div className="all-orders-heading">
          <Title>{ordersPageHeader}</Title>
        </div>
      )}
      <SelectOrderWrapper>
        <FilterOrdersByDate name="orders-filter" label={periodLabel} />
      </SelectOrderWrapper>
      <OrdersList customData={customData} languageCode={languageCode} />
    </AllOrdersWrapper>
  );
}

OrdersHub.propTypes = {
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
