import PropTypes from 'prop-types';

import { validateValueWithRegex } from '../../helpers/formHelpers';

import { PasswordRulesWrapper, PasswordRulesList } from './FormStyles';

export default function PasswordRules({ name, pwHeader, passwordRules, getValues }) {
  if (passwordRules) {
    return (
      <PasswordRulesWrapper>
        <h2>{pwHeader}</h2>
        <PasswordRulesList>
          {passwordRules &&
            passwordRules.map((rule) => (
              <li
                key={rule.validationCode}
                className={getValues(name) ? validateValueWithRegex(rule.validationCode, getValues(name)) : ''}
              >
                {rule.validationLabel}
              </li>
            ))}
        </PasswordRulesList>
      </PasswordRulesWrapper>
    );
  }
  return null;
}
PasswordRules.propTypes = {
  name: PropTypes.string,
  pwHeader: PropTypes.string,
  passwordRules: PropTypes.array,
  getValues: PropTypes.func,
};
