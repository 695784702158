/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { updateLocation } from '../../../store/actions';

import useBrowserGeolocation from './useBrowserGeolocation';
import useStorage from '../navigation/useStorage';

import { getAddressFromGeocode } from '../../../helpers/locationHelpers';

export default function useLocation() {
  const { state, actions } = useStateMachine({ updateLocation });
  const { setItem, getItem } = useStorage();
  // check if the user is logged in and then set the province from location.
  const { latitude, longitude, error } = useBrowserGeolocation(false, {
    shouldQuery: !!state?.session?.isLoggedIn,
  });

  const setLocation = (province) => {
    setItem('province', province);
    actions.updateLocation({ province });
  };

  const setDefaultProvince = () => {
    const addresses = state?.user?.customer?.data?.addresses;

    if (addresses && addresses?.length) {
      const [primaryAddress] = addresses;

      if (primaryAddress?.provinceCode) {
        setLocation(primaryAddress?.provinceCode);
      } else {
        setLocation('ON');
      }
    } else {
      setLocation('ON');
    }
  };

  const deriveLocation = () => {
    const locationInSession = getItem('province');

    if (state.session.isLoggedIn && !locationInSession && state?.user?.customer?.data) {
      // when we have coordinates, attempt to fetch from google
      if (latitude && longitude) {
        getAddressFromGeocode({
          lat: latitude,
          lng: longitude,
          type: 'province',
          accessToken: state.session.accessToken,
        })
          .then((address) => {
            if (address?.province) {
              setLocation(address.province);
            } else {
              setDefaultProvince();
            }
          })
          .catch((error) => {
            // do nothing
            setDefaultProvince();
          });

        return;
      }

      if (error) {
        setDefaultProvince();
      }
      return;
    }

    if (state.session.isLoggedIn && locationInSession && locationInSession !== state?.location?.province) {
      actions.updateLocation({ province: locationInSession });
    }
  };

  useEffect(deriveLocation, [latitude, longitude, error, state?.session?.isLoggedIn, state?.user?.customer.data]);
}
