import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import { setDefaultCheckboxSettings } from '../../../../helpers/settingsHelper';
import SettingsEmailCheckbox from './SettingsEmailCheckbox';

import { TopicListStyles } from '../../FormStyles';

export default function SettingsTopics({ emailTopics, disableOptIn }) {
  const {
    state: {
      user: { preferences, preferencesData },
    },
  } = useStateMachine();

  if (preferencesData) {
    return (
      <TopicListStyles>
        {emailTopics &&
          emailTopics.map((topic) => (
            <SettingsEmailCheckbox
              key={topic.contentID}
              className="mb-4"
              fieldType="checkbox"
              label={topic.fields.name}
              name={topic.fields.code.toUpperCase()}
              defaultChecked={setDefaultCheckboxSettings(topic, preferences?.preferences || preferencesData)}
              disableOptIn={disableOptIn}
            />
          ))}
      </TopicListStyles>
    );
  }
  return null;
}

SettingsTopics.propTypes = { emailTopics: PropTypes.array, disableOptIn: PropTypes.bool };
