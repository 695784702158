import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

// Styles
import Loader from '../common/Loader/Loader';
import UnsubscribeHasParams from './UnsubscribeHasParams';

// Hooks
import useCustomRedirect from '../hooks/navigation/useCustomRedirect';

export default function UnsubscribeContent({ customData, errorsList, pageContent }) {
  const {
    state: {
      session: { isLoggedIn },
      navigation,
    },
  } = useStateMachine();

  const showUnsubScribe = useCustomRedirect({
    params: !isLoggedIn && !navigation?.query?.q && ['q'],
  });

  if (!showUnsubScribe && !isLoggedIn && !navigation?.query?.q) {
    return null;
  }

  if (!navigation?.isReady) return <Loader />;

  // show this if we want to render no params messaging, however it evaluates to mount first because navigation query is undefined from the start
  // if (!isLoggedIn && !navigation?.query?.q) {
  //   return (
  //     <UnsubscribeContentContainer>
  //       <div dangerouslySetInnerHTML={renderHTML(noParamsMessage)} />
  //     </UnsubscribeContentContainer>
  //   );
  // }

  return <UnsubscribeHasParams customData={customData} errorsList={errorsList} pageContent={pageContent} />;
}

UnsubscribeContent.propTypes = {
  customData: PropTypes.object,
  errorsList: PropTypes.array,
  pageContent: PropTypes.object,
};
