import { useMemo } from 'react';
import Link from 'next/link';
import { useWindowWidth } from '@react-hook/window-size';
import { FooterColumnBlock } from '../../FooterStyles';

export default function FooterInfoLinks({ footer, isLoggedIn, isTablet }) {
  const { infoHeader, infoLinks } = footer.fields;
  const viewPortWidth = useWindowWidth();
  const showFooter = useMemo(
    () =>
      (isTablet === 'isTablet' && viewPortWidth > 767 && viewPortWidth < 1024) ||
      (isTablet !== 'isTablet' && (viewPortWidth < 768 || viewPortWidth > 1023)),
    [isTablet, viewPortWidth]
  );

  if (showFooter) {
    return (
      <FooterColumnBlock
        className={`${isLoggedIn ? '' : 'bottom-divider'} ${isTablet ? 'isTablet' : ''} `}
        data-testid="footer-col"
      >
        {infoHeader && <h2>{infoHeader}</h2>}
        {infoLinks &&
          infoLinks.map((navitem, index) => (
            <div key={index} className="footer-link">
              <Link
                href={navitem.fields.page.href}
                as={navitem.fields.page.href}
                className="block text-base leading-6 font-medium text-secondary-500 hover:text-primary-500 border-transparent border-b-2 hover:border-primary-500 hover:border-b-primary hover:border-b-2 focus:text-primary-500 transition duration-300"
                data-testid="footer-link"
              >
                {navitem.fields.title}
              </Link>
            </div>
          ))}
      </FooterColumnBlock>
    );
  }

  return null;
}
