import styled from 'styled-components';

export const StyledList = styled.ul`
  padding: 0;
  margin-bottom: 0;
  @media screen and (max-width: 640px) {
    margin-bottom: 0.5rem;
    flex-direction: column;
  }
  li {
    color: ${(props) => props.theme.uiCharcoal};
    margin-bottom: 3.75rem;
    @media screen and (min-width: 641px) and (max-width: 990px) {
      margin-bottom: 2.5rem;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 1rem;
      display: inline-block;
      width: 50%;
    }
    p {
      color: ${(props) => props.theme.lightGrayColor4};
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
      margin: 0;
      display: block;
      @media screen and (max-width: 640px) {
        display: none;
      }
      @media screen and (min-width: 991px) {
        font-size: ${(props) => props.theme.fontBody1};
      }
    }
  }
`;

export const RewardTypeTitle = styled.h3`
  margin-bottom: 1.5rem;
  @media screen and (max-width: 640px) {
    margin-bottom: 1rem;
  }
`;

export const ImageCircle = styled.div`
  position: relative;
  overflow: hidden;
  width: 5.625rem;
  height: 5.625rem;
  transition: border 0.2s ease-in;
  margin-bottom: 2rem;
  @media screen and (max-width: 640px) {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 1.25rem;
  }
`;

export const ImageCircleNOC = styled.div`
  position: relative;
  overflow: hidden;
  width: 5.625rem;
  height: 5.625rem;
  transition: border 0.2s ease-in;
  margin-bottom: 2rem;
  @media screen and (max-width: 640px) {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 1.25rem;
  }
`;
export const PageTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.uiBlack};
  font-size: ${(props) => props.theme.headingMedium};
  margin-bottom: 1.5rem;
  @media screen and (max-width: 640px) {
    font-size: ${(props) => props.theme.headingExtraSmall};
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 640px) and (max-width: 991px) {
    font-size: ${(props) => props.theme.headingSmall};
    margin-bottom: 0.75rem;
  }
`;

export const PageSubtitle = styled.p`
  color: ${(props) => props.theme.uiDarkGrey};
  font-size: ${(props) => props.theme.fontBody2};
  text-align: center;
  margin-bottom: 0;
  @media screen and (min-width: 640px) and (max-width: 991px) {
    font-size: ${(props) => props.theme.fontBody1};
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 990px) {
    font-size: ${(props) => props.theme.introduction};
  }
`;

export const StyledListNOC = styled.ul`
  padding: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  text-align: center;

  li {
    color: ${(props) => props.theme.uiCharcoal};
    display: inline-block;
    vertical-align: top;
    margin-top: 2rem;
    @media screen and (min-width: 991px) {
      margin-top: 5rem;
    }
    width: 25%;
    @media screen and (min-width: 641px) and (max-width: 990px) {
      width: 33%;
    }
    @media screen and (max-width: 640px) {
      width: 50%;
    }
  }
`;

export const StyledListHome = styled.ul`
  padding: 0;
  margin-bottom: 0.5rem;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  @media screen and (max-width: 640px) {
    margin-top: 2rem;
  }
  @media screen and (min-width: 641px) and (max-width: 990px) {
    margin-top: 2.5rem;
  }
  @media screen and (min-width: 991px) {
    margin-bottom: 1rem;
  }
  li {
    color: ${(props) => props.theme.uiCharcoal};
    display: inline-block;
    vertical-align: top;
    padding: 0 1rem 3.75rem;
    @media screen and (max-width: 640px) {
      width: 50%;
      padding: 0 0 1rem;
    }
    @media screen and (min-width: 641px) {
      width: 33%;
      padding: 0 1rem 2.75rem;
    }

    p {
      color: ${(props) => props.theme.lightGrayColor4};
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
      margin: 0;
      display: block;
      @media screen and (max-width: 640px) {
        display: none;
      }
      @media screen and (min-width: 991px) {
        font-size: ${(props) => props.theme.fontBody1};
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 3.5rem;
  @media screen and (max-width: 640px) {
    margin-bottom: 1.75rem;
  }
  @media screen and (min-width: 641px) and (max-width: 990px) {
    margin-bottom: 2.5rem;
  }
`;
