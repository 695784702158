import styled, { css } from 'styled-components';

export const FullWidthWrapper = styled.div`
  ${(props) =>
    props.hasPatterns &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`;
export const FullWidthInnerWrapper = styled.div`
  ${(props) =>
    props.hasPatterns &&
    css`
      padding-top: 3.5rem;
      padding-bottom: 5.5rem;
      margin: 0 auto;
      position: relative;
      @media screen and (max-width: 640px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -10%;
        right: -10%;
        bottom: 0;
        background-image: url(/assets/cross-circles-small.svg), url(/assets/cross-circles.svg);
        background-repeat: no-repeat;
        background-position: 10% 5%, 96% 70%;
        background-size: 4rem, 9.5rem;
        @media screen and (max-width: 640px) {
          background-position: 75% 0%, 99% 70%;
          background-size: 1.5rem, 6.25rem;
        }

        @media screen and (min-width: 641px) and (max-width: 1023px) {
          background-size: 2.5rem, 9.5rem;
          background-position: 10% 5%, 99% 30%;
        }
      }
    `}
`;
