import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updateTransactions } from '../../../store/actions';

import Loader from '../../common/Loader/Loader';

import { TransactionsPFC, TransactionsBYOT, TransactionsModals } from './Transactions';

export default function TransactionList({ page, customData, isShowing, toggle, currentItemId, languageCode }) {
  const { selectAmountLabel } = customData;

  const {
    state: {
      transactions: { cards, selectedCard },
    },
  } = useStateMachine({ updateTransactions });

  if (cards && selectedCard?.id) {
    return (
      <div>
        {page === 'byot' && <TransactionsBYOT customData={customData} languageCode={languageCode} />}

        {page === 'pfc' && (
          <div>
            <TransactionsPFC
              selectAmountLabel={selectAmountLabel}
              page={page}
              customData={customData}
              languageCode={languageCode}
            />
          </div>
        )}

        {isShowing && <span className="overlay" />}
        {isShowing && cards.length > 0 && (
          <TransactionsModals customData={customData} currentItemId={currentItemId} toggle={toggle} />
        )}
      </div>
    );
  }
  return <Loader />;
}

TransactionList.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
  isShowing: PropTypes.bool,
  toggle: PropTypes.func,
  currentItemId: PropTypes.string,
  languageCode: PropTypes.string,
};
