// Global State
import { useStateMachine } from 'little-state-machine';
import EventsSlider from '../common/Banners/EventsSlider';
import WrapperLayout from '../layouts/WrapperLayout';

const Events = ({ module, languageCode }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  if (isLoggedIn && module?.field?.maximumSlides !== '0') {
    return (
      <WrapperLayout className="global-content-wrapper">
        <EventsSlider
          slides={module.fields.maximumSlides}
          languageCode={languageCode}
          sectionTitle={module.fields.title}
          openDetailsModalLabel={module.fields.openDetailsModalLabel || ''}
          isSlider
        />
      </WrapperLayout>
    );
  }
  return null;
};

export default Events;
