import PropTypes from 'prop-types';
import { StyledAccordionSection } from './FAQContentStyles';
import Accordion from '../../common/Accordion/Accordion';
import { getExpandedFirstItem } from '../../../helpers/utils';

function FAQSectionList({ faqSections, languageCode }) {
  if (faqSections && faqSections.length) {
    return faqSections.map((section, index) => (
      <StyledAccordionSection key={index}>
        <h2 className="headline-small">{section.title}</h2>
        {section.accordionList.length && (
          <Accordion
            accordionList={section.accordionList}
            firstItemExpanded={getExpandedFirstItem(
              faqSections[0].accordionList[0].contentID,
              section.accordionList[0].contentID
            )}
            page="FAQ"
            languageCode={languageCode}
          />
        )}
      </StyledAccordionSection>
    ));
  }
  return null;
}
FAQSectionList.propTypes = { faqSections: PropTypes.array };

export default FAQSectionList;
