import styled, { css } from 'styled-components';

export const StyledBanner = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;

  @media screen and (min-width: 641px) and (max-width: 990px) {
    padding: 5rem 0;
  }
  @media screen and (min-width: 991px) {
    padding: 6.25rem 0;
  }
`;
export const StyledBannerContent = styled.div`
  text-align: center;
  max-width: 50rem;
  h4 {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    @media screen and (min-width: 641px) {
      font-size: ${(props) => props.theme.fontBody1};
    }
    span {
      font-weight: normal;
    }
  }
`;

export const LocationsHeader = styled.div`
  h3 {
    font-size: ${(props) => props.theme.fontBody1};
    @media screen and (min-width: 641px) and (max-width: 990px) {
      font-size: ${(props) => props.theme.headingExtraSmall};
    }
    @media screen and (min-width: 991px) {
      font-size: ${(props) => props.theme.headingSmall};
    }
  }
`;

export const BrandListWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 50rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  h4 {
    margin-bottom: 2rem;
    @media screen and (max-width: 640px) {
      margin-bottom: 1.5rem;
    }
  }

  ${(props) =>
    props.comingSoon &&
    css`
      h4 {
        margin-top: 5rem;
        @media screen and (max-width: 640px) {
          margin-top: 4rem;
        }
        @media screen and (min-width: 641px) and (max-width: 990px) {
          margin-top: 2.75rem;
        }
      }
    `}

  ${(props) =>
    props.comingSoon &&
    props.firstItem &&
    css`
      h4 {
        margin-top: 1.5rem;
      }
    `}
  figure {
    display: inline-block;
  }
`;

export const TileContainer = styled.div`
  position: relative;
  margin: 0.4rem;
  overflow: hidden;
  border-radius: 100%;
  display: inline-flex;
  background-color: ${(props) => props.theme.uiBlack};
  width: 5.25rem;
  height: 5.25rem;
  @media screen and (max-width: 640px) {
    width: 3.75rem;
    height: 3.75rem;
  }
`;
