import { useStateMachine } from 'little-state-machine';

import Portal from '../../../common/Portal/Portal';
import TransactionModal from '../../../common/Modals/TransactionModal';
import ModalLayout from '../../../layouts/ModalLayout';

export default function TransactionsModals({ customData, currentItemId, toggle, isShowing }) {
  const {
    state: {
      transactions: { cards },
    },
  } = useStateMachine();

  return cards.map((item, index) => (
    <Portal key={index}>
      {currentItemId === item.id && (
        <>
          {isShowing && <span className="overlay" />}
          <ModalLayout toggle={toggle} type="modal-confirmation" hideCloseIcon>
            <TransactionModal customData={customData} toggle={toggle} currentItemId={currentItemId} />
          </ModalLayout>
        </>
      )}
    </Portal>
  ));
}
