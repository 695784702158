import styled from 'styled-components';

export const StellarPartnerContainer = styled.div`
  display: block;
  overflow-x: auto;
  margin-bottom: 1.5rem;
`;

export const StyledTable = styled.table`
  min-width: 58rem;

  margin-bottom: 1.5rem;
  th,
  td {
    padding: 0.875rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: collapse;
    vertical-align: top;
    text-align: left;
    ul {
      margin-bottom: 0;
    }
    li {
      color: ${(props) => props.theme.brandBlack};
      font-size: ${(props) => props.theme.fontBody2};
      line-height: ${(props) => props.theme.lineHeight3};
    }
  }
  tr th:nth-child(1),
  tr td:nth-child(1) {
    min-width: 15.5rem;
  }
  @media screen and (max-width: 1023px) {
    ul {
      padding-left: 1.5rem;
    }
  }
`;

export const ListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 15.5rem;

  figure {
    display: inline-block;
  }
`;

export const TileContainer = styled.div`
  position: relative;
  margin: 0.375rem;
  overflow: hidden;
  border-radius: 100%;
  display: inline-flex;
  width: 3rem;
  height: 3rem;
`;

export const Disclimer = styled.div`
  p {
    color: ${(props) => props.theme.uiDarkGrey};
    font-size: ${(props) => props.theme.fontLegal};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
  }
`;
