import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

// Styles
import { useRouter } from 'next/router';
import { Format, FormatWrapper } from './GiftCardStyles';

// Helpers
import { isFormatDisabled, deriveFormatFrom } from '../../helpers/giftCardsHelper';

/**
 * TODO: Client feedback needed re: default selection, right now we assume whichever is not disabled (E-Card)
 *
 * need logic to default select either physical if available or e-card if no physical available
 *
 *
 */

function GiftCardFormat({ handleFormatClicked, formatLabel, digitalButtonLabel, physicalButtonLabel }) {
  const [selected, setSelected] = useState(null);
  const router = useRouter();
  const {
    state: {
      giftCards: { redeem },
    },
  } = useStateMachine();

  function updateFormat(format) {
    setSelected(format);
    handleFormatClicked(format);
  }

  const handleFormatSelected = (e) => {
    e.preventDefault();
    updateFormat(e.target.id);
  };

  function getGiftcardFormat() {
    if (redeem?.giftCardDetails?.brandId) {
      const { digital } = redeem.giftCardDetails;
      const { physical } = redeem.giftCardDetails;

      if (digital.length) {
        return redeem.formats[0];
      }

      if (physical.length) {
        return redeem.formats[1];
      }
    }

    return '';
  }

  useEffect(() => {
    if (router.query && router.query.brand === redeem.giftCardDetails.brandId) {
      if (selected === null || selected === '') {
        const format = getGiftcardFormat();
        updateFormat(format);
      }
    }
  }, [redeem.giftCardDetails, selected, router.query]);

  return (
    <FormatWrapper>
      <p className="gift-card-format-label">{formatLabel}</p>
      <ul className="flex flex-wrap list-none pl-0">
        {redeem.formats &&
          redeem.formats.map((format, index) => (
            <Format
              tabIndex="0"
              key={index}
              id={format}
              className={`mx-3 my-2 ${format === selected ? 'selected' : ''}`}
              onClick={handleFormatSelected}
              role="button"
              name={format}
              aria-disabled={isFormatDisabled({
                format,
                giftCardDetails: redeem.giftCardDetails,
              })}
              disabled={isFormatDisabled({ format, giftCardDetails: redeem.giftCardDetails })}
            >
              {deriveFormatFrom(digitalButtonLabel, physicalButtonLabel, format)}
            </Format>
          ))}
      </ul>
    </FormatWrapper>
  );
}

export default GiftCardFormat;
GiftCardFormat.propTypes = {
  handleFormatClicked: PropTypes.func,
  formatLabel: PropTypes.string,
  digitalButtonLabel: PropTypes.string,
  physicalButtonLabel: PropTypes.string,
};
