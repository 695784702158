import { useStateMachine } from 'little-state-machine';
import { useQuery } from 'react-query';
import { getOffers } from '../../../pages/api/services';

/**
 *
 * @param {*} languageCode
 * @param {*} body
 *
 * "category": [
      ""
  // Dining ,Featured ,all ,Banking ,Entertainment ,Movies, Shopping ,travel, grocery
    ],
      "tag": [
        ""
 // ["new", "expiring", "loaded", "loadable", "evergreen", "foryou", "limitedtime"]
      ]
 * @returns
 */

export default function useOffers(
  languageCode,
  body = { category: ['ALL'], brand: [], tag: [''], sort: '', page: 1, limit: 50 }
) {
  const {
    state: {
      session: { isLoggedIn, accessToken },
      location,
    },
  } = useStateMachine();

  const reqBody = {
    filters: {
      category: body.category,
      tag: body.tag,
      brand: body.brand,
    },
    sort: body.sort,
    pagination: {
      page: body.page || 1,
      limit: body.limit || 50,
    },
  };

  const { isLoading, error, data } = useQuery(['getOffers', reqBody, languageCode, location?.province], async () => {
    if (isLoggedIn && accessToken && location?.province) {
      try {
        // only run get offers if there is a selected category
        const res = await getOffers(reqBody, languageCode, accessToken, location.province);
        // TODO: API is  changing response types and causing failure on front end, remove this once it stabilizes
        try {
          return res.data.data.offers;
        } catch (error) {
          const offersData = await JSON.parse(res.data.data);
          return offersData.offers;
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  return { isLoading, error, offers: data };
}
