import ExternalImage from '../../../Images/ExternalImage';

export default function FooterLogo({ isMobile, footer }) {
  const { footerLogo } = footer.fields;

  if (footerLogo) {
    return (
      <div className={`footer-site-logo ${isMobile ? 'mobile-footer-logo' : 'tablet-desktop'}`}>
        <ExternalImage url={footerLogo.url} title={footerLogo.label} width={48} height={48} />
      </div>
    );
  }
  return null;
}
