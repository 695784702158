import PropTypes from 'prop-types';
import { expandLinkedList } from '@agility/nextjs/utils';
// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import ProfileSettingsForm from '../forms/profile/ProfileSettingsForm';
import Sidebar from '../common/Sidebar/Sidebar';
import WrapperLayout from '../layouts/WrapperLayout';
import { SidebarContainer, NavigatedContent } from '../common/Sidebar/SidebarStyles';
import useHasMount from '../hooks/utils/useHasMount';

const ProfilePage = ({ customData, ...props }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  const hasMounted = useHasMount();
  if (!hasMounted) {
    return null;
  }

  if (isLoggedIn) {
    return (
      <WrapperLayout className="global-content-wrapper" isGray customStyles="topOfPage">
        <SidebarContainer>
          <div className="hidden lg:block">
            <Sidebar sidebarLinks={customData.ProfileSettingsForm.profileSidebarLinks} />
          </div>

          <NavigatedContent>
            {customData && customData.ProfileSettingsForm && <ProfileSettingsForm {...customData} {...props} />}
          </NavigatedContent>
        </SidebarContainer>
      </WrapperLayout>
    );
  }
  return null;
};

ProfilePage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'profileform',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });
    const errorCodes = ['Contact', 'Customer', 'Address', 'General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    // sidebarLinks
    if (contentItem.fields.profileSidebarLinks) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'profileSidebarLinks',
      });
      contentItem.fields.profileSidebarLinks.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    }

    return {
      ProfileSettingsForm: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

ProfilePage.propTypes = { customData: PropTypes.object };
export default ProfilePage;
