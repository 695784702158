import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
// Global States
import { useStateMachine } from 'little-state-machine';
import { updateLocation } from '../../../../store/actions';

// Components
import ProvinceModalHeader from './ProvincialModalHeader';
import ProvinceItemListMobile from './ProvinceItemListMobile';
import ProvinceItemList from './ProvinceItemList';
import PortalModal from '../PortalModal';

// Hooks
import useProvinces from '../../../hooks/account/useProvinces';

import { ProvinceModalContainer, ProvincialModalBodyContainer } from '../ModalStylesV2';
import CloseIcon from '../../Icons/CloseIcon';
import useStorage from '../../../hooks/navigation/useStorage';

// Helpers
import { handleGAEventsBase } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProvincialModal({ fields, languageCode, toggle, isShowing }) {
  const router = useRouter();
  const { actions, state } = useStateMachine({ updateLocation });
  const { currentlySetToLabel } = fields.globalLabels[0].fields;
  const { provinces } = useProvinces(languageCode);
  const { setItem } = useStorage();
  const setInitialRoute = () => {
    if (router) {
      if (router.asPath.includes('/rewards')) {
        window.location.href = `/${languageCode}/rewards`;
      }

      if (router.asPath.includes('/offers')) {
        window.location.href = `/${languageCode}/offers?category=featured`;
      }
    }
  };

  const gaEvent = (code) => {
    const dataLayer = {
      event: 'location_save_click',
      eventCategory: 'Account',
      eventAction: 'Save Location Change Click',
      eventLabel: 'Save Your Location',
      province: code,
    };

    handleGAEventsBase(dataLayer);
  };
  const updateProvince = (code) => {
    if (code) {
      actions.updateLocation({ province: code });
      setItem('province', code);
      setInitialRoute();
      gaEvent(`${provinces?.find((p) => p?.code === code).name}`);
      toggle();
    }
  };

  const selectedProvince = state?.location;
  const showSelectedProvince = provinces?.find((p) => selectedProvince?.province === p?.code);

  return (
    <PortalModal isShowing={isShowing} toggle={toggle} type="modal-province">
      <ProvinceModalContainer id="provincialPopup">
        <CloseIcon toggle={toggle} />

        <ProvinceModalHeader title={state?.labels?.changeLocationLabel} titleModal={state?.labels?.locationLabel} />
        <ProvincialModalBodyContainer>
          <>
            <ProvinceItemListMobile
              updateProvince={updateProvince}
              provinces={provinces}
              selectedProvince={selectedProvince}
              toggle={toggle}
              fields={fields.globalLabels[0].fields}
              currentlySetToLabel={currentlySetToLabel}
              showSelectedProvince={showSelectedProvince?.name}
            />

            <ProvinceItemList provinces={provinces} updateProvince={updateProvince} selectedProvince={selectedProvince} />
          </>
        </ProvincialModalBodyContainer>
      </ProvinceModalContainer>
    </PortalModal>
  );
}

ProvincialModal.propTypes = {
  languageCode: PropTypes.string,
  toggle: PropTypes.func,
  fields: PropTypes.object,
  isShowing: PropTypes.bool,
};
