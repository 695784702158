import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 98;

  p {
    color: ${(props) => props.theme.whiteColor};
    font-weight: bold;
    font-size: ${(props) => props.theme.headingExtraSmall};
  }

  #lottie {
    height: 100%;
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    display: block;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    text-align: center;
    opacity: 1;
  }

  @media screen and (min-width: 641px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
